/* eslint-disable quote-props */
export const locales = {
    ru: {
        filterTitle: 'Ингредиенты',
        '1515': 'Без мяса и птицы',
        '1516': 'Без рыбы',
        '1517': 'Без свинины',
        '1218': 'Вег',
        '1219': 'Веган',
        '1524': 'Без лука',
        '1521': 'Без грибов',
    },
    en: {
        filterTitle: 'Ingredients',
        '1515': 'No meat',
        '1516': 'No fish',
        '1517': 'No pork',
        '1218': 'Veg',
        '1219': 'Vegan',
        '1524': 'No onion',
        '1521': 'No mushrooms',
    },
};
