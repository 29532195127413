/* eslint-disable max-len */

export class CityAdsAnalyticsClient {
    constructor() {
        this.service = {
            push: (...args) => {
                const [event, params] = args;
                if (event === 'CityAds_Dish_Details_Viewed') {
                    const { id } = params;

                    const script = document.createElement('script');
                    script.id = 'xcntmyAsync';
                    script.textContent = `
                        var xcnt_product_id = '${id}'; (function(d){ var xscr = d.createElement( 'script' ); xscr.async = 1; xscr.src = '//x.cnt.my/async/track/?r=' + Math.random(); var x = d.getElementById( 'xcntmyAsync' ); x.parentNode.insertBefore( xscr, x ); })(document);
                    `;
                    document.body.appendChild(script);
                }

                if (event === 'CityAds_Basket_Navigate') {
                    const { sections } = params;

                    const dishesData = sections.map((section) => {
                        const sectionDishes = section.items.map((item) => {
                            const dishData = {
                                id: item.dish_id,
                                portions: item.portions,
                            };
                            return dishData;
                        });
                        return sectionDishes;
                    }).flat();

                    const ids = dishesData.map((d) => d.id).join(',');
                    const counts = dishesData.map((d) => d.portions).join(',');

                    const script = document.createElement('script');
                    script.id = 'xcntmyAsync';
                    script.textContent = `
                        var xcnt_basket_products = '${ids}'; var xcnt_basket_quantity = '${counts}';
                        (function(d){
                            var xscr = d.createElement( 'script' ); xscr.async = 1; xscr.src = '//x.cnt.my/async/track/?r=' + Math.random(); var x = d.getElementById( 'xcntmyAsync' ); x.parentNode.insertBefore( xscr, x );
                        })(document);
                    `;
                    document.body.appendChild(script);
                }

                if (event === 'CityAds_Purchase') {
                    const {
                        invoiceData: { uid, price },
                        dishes,
                    } = params;

                    const counts = dishes.map((d) => d.id).join(',');
                    const currency = 'RUB';

                    const script = document.createElement('script');
                    script.id = 'xcntmyAsync';
                    script.textContent = `
                        var xcnt_order_products = '${counts}'; var xcnt_order_id = '${uid}';var xcnt_order_total = '${price}'; var xcnt_order_currency = '${currency}';(function(d){ var xscr = d.createElement( 'script' ); xscr.async = 1; xscr.src = '//x.cnt.my/async/track/?r=' + Math.random(); var x = d.getElementById( 'xcntmyAsync' ); x.parentNode.insertBefore( xscr, x ); })(document);
                    `;
                    document.body.appendChild(script);
                }
            },
        };
        this.clientName = 'CityAdsAnalytics';
    }

    emit(data) {
        const { event, ...params } = data;
        // console.warn(`emit CityAdsAnalytics event: ${event}`, params);
        this.service.push(
            event,
            params,
        );
    }
}

