import React from 'react';

import Button from 'app/components/ui/Button/Button';

import CloseIcon from 'assets/svg/closeIcon.component.svg';
import css from './DialogStatic.module.scss';

const DialogStaticDesktop = (props) => {
    const {
        onConfirm,
        onConfirmOne,
        onConfirmAll,
        onReject,

        confirmText,
        confirmOneText,
        confirmAllText,
        rejectText,

        notifyOnly,
        isCloseConfirmAction,

        children,
    } = props;

    const handleReject = notifyOnly ? onConfirm : onReject;

    const mainButtonText = confirmAllText || confirmText;
    const mainButtonHandler = onConfirmAll || onConfirm;

    const extraButtonText = confirmOneText || rejectText;
    const extraButtonHandler = onConfirmOne || handleReject;

    return (
        <div className={css.dialogDesktopStaticRoot}>
            <div
                className={css.closeIconWrapper}
                onClick={isCloseConfirmAction ? onConfirm : onReject}
                role="none"
            >
                <CloseIcon />
            </div>

            {children}

            <div className={css.dialogDesktopStaticButtonsWrapper}>
                {!notifyOnly && (
                    <Button colorType="secondary" onClick={extraButtonHandler}>
                        {extraButtonText}
                    </Button>
                )}
                <Button colorType="primary" onClick={mainButtonHandler}>
                    {mainButtonText}
                </Button>
            </div>
        </div>
    );
};

export { DialogStaticDesktop };
