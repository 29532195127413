/**
 * @description вычесляет статут "Доставлено" у всех заказав
 * @return Bool
 */
export const computeIsNewbie = (client) => {
    const deliveries = client.readField('deliveries');
    const successDeliveriesCount = client.readField('successDeliveriesCount');
    return !deliveries?.length && (successDeliveriesCount === 0);
};

/**
 * @desctiption Приводит к строке данные из customizationTags
 * для консистентности с __typeName: tag
 */
export const castToStringTags = (tagsArr = []) => tagsArr.map((e) => String(e));

/* USER REACTIVE VARS */

export const userReactiveVars = {
    isNewbie: {
        read(_, client) {
            return computeIsNewbie(client);
        },
    },
    customizationTags: {
        read(value = []) {
            return castToStringTags(value || []);
        },
    },
    filterTags: {
        read(value) {
            return castToStringTags(value);
        },
    },
};
