import React from 'react';

import classNames from 'classnames';
import css from './combinedShield.module.scss';

// Мстители
import m0s from './avengers/movie-0-s.png';
import m0m from './avengers/movie-0-m.png';
import m0l from './avengers/movie-0-l.png';

// Теория большого взрыва
import m1s from './theory/movie-1-s.png';
import m1m from './theory/movie-1-m.png';
import m1l from './theory/movie-1-l.png';

// От моники
import m2s from './monica/movie-2-s.png';
import m2m from './monica/movie-2-m.png';
import m2l from './monica/movie-2-l.png';

// Криминальное чтиво
import m3s from './pulp/movie-3-s.png';
import m3m from './pulp/movie-3-m.png';
import m3l from './pulp/movie-3-l.png';

// Тед Лассо
import m4s from './lasso/movie-4-s.png';
import m4m from './lasso/movie-4-m.png';
import m4l from './lasso/movie-4-l.png';

// от мамы имсс мейзел
import m5s from './maisel/movie-5-s.png';
import m5m from './maisel/movie-5-m.png';
import m5l from './maisel/movie-5-l.png';

// от Амели
import m6s from './ameli/movie-6-s.png';
import m6m from './ameli/movie-6-m.png';
import m6l from './ameli/movie-6-l.png';

// Один дома
import m7s from './alone/movie-7-s.png';
import m7m from './alone/movie-7-m.png';
import m7l from './alone/movie-7-l.png';

// ешь молись люби
import m8s from './pray/movie-8-s.png';
import m8m from './pray/movie-8-m.png';
import m8l from './pray/movie-8-l.png';

// Barbie
import m9s from './barbie/movie-9-s.png';
import m9m from './barbie/movie-9-m.png';
import m9l from './barbie/movie-9-l.png';

// Ahmad
import m10s from './ahmad/ahmad-s.png';
import m10m from './ahmad/ahmad-m.png';
import m10l from './ahmad/ahmad-l.png';

// От Оли Мерзляк
import m11s from './olya/olya-s.png';
import m11m from './olya/olya-m.png';
import m11l from './olya/olya-l.png';

// Сейлор Мун
import m12s from './moon/movie-12-s.png';
import m12m from './moon/movie-12-m.png';
import m12l from './moon/movie-12-l.png';

// Карточный домик
import m13s from './cards/movie-10-s.png';
import m13m from './cards/movie-10-m.png';
import m13l from './cards/movie-10-l.png';

// Отчаянные домохозяйки
import m14s from './housewives/movie-11-s.png';
import m14m from './housewives/movie-11-m.png';
import m14l from './housewives/movie-11-l.png';

// Твин Пикс
import m15s from './peaks/movie-14-s.png';
import m15m from './peaks/movie-14-m.png';
import m15l from './peaks/movie-14-l.png';

// Сумерки
import m16s from './twilight/movie-13-s.png';
import m16m from './twilight/movie-13-m.png';
import m16l from './twilight/movie-13-l.png';

export type TShieldCombinedName = 'theory' | 'monica' | 'pulp' | 'lasso' | 'maisel' | 'ameli' |
    'alone' | 'pray' | 'avengers' | 'barbie' | 'ahmad' | 'olya' | 'cards' | 'housewives' | 'moon' | 'peaks' | 'twilight'

interface ICombinedShieldProps {
    name: TShieldCombinedName,
}

const defaultPath = {
    s: '', m: '', l: '', type: '',
};


const shieldMap = new Map<TShieldCombinedName, typeof defaultPath>([
    ['avengers', {
        s: m0s, m: m0m, l: m0l, type: 'movie',
    }],
    ['theory', {
        s: m1s, m: m1m, l: m1l, type: 'movie',
    }],
    ['monica', {
        s: m2s, m: m2m, l: m2l, type: 'movie',
    }],
    ['pulp', {
        s: m3s, m: m3m, l: m3l, type: 'movie',
    }],
    ['lasso', {
        s: m4s, m: m4m, l: m4l, type: 'movie',
    }],
    ['maisel', {
        s: m5s, m: m5m, l: m5l, type: 'movie',
    }],
    ['ameli', {
        s: m6s, m: m6m, l: m6l, type: 'movie',
    }],
    ['alone', {
        s: m7s, m: m7m, l: m7l, type: 'movie',
    }],
    ['pray', {
        s: m8s, m: m8m, l: m8l, type: 'movie',
    }],
    ['barbie', {
        s: m9s, m: m9m, l: m9l, type: 'movie',
    }],
    ['ahmad', {
        s: m10s, m: m10m, l: m10l, type: 'movie',
    }],
    ['olya', {
        s: m11s, m: m11m, l: m11l, type: 'chef',
    }],
    ['cards', {
        s: m13s, m: m13m, l: m13l, type: 'movie',
    }],
    ['housewives', {
        s: m14s, m: m14m, l: m14l, type: 'movie',
    }],
    ['moon', {
        s: m12s, m: m12m, l: m12l, type: 'movie',
    }],
    ['peaks', {
        s: m15s, m: m15m, l: m15l, type: 'movie',
    }],
    ['twilight', {
        s: m16s, m: m16m, l: m16l, type: 'movie',
    }],
]);

export const CombinedShield = (props: ICombinedShieldProps) => {
    const { name } = props;

    const path = shieldMap.get(name) || defaultPath;

    if (!path) {
        return null;
    }

    const combinedShieldImgClasses = classNames({
        [css.combinedShieldImg]: true,
        [css[path.type]]: true,
    });

    const combinedShieldRootClasses = classNames({
        [css.combinedShieldRoot]: true,
        [css[path.type]]: true,
    });

    return (
        <div
            className={combinedShieldRootClasses}
        >
            <div className={css.s}>
                <img className={combinedShieldImgClasses} src={path.s} alt={name} />
            </div>
            <div className={css.m}>
                <img className={combinedShieldImgClasses} src={path.m} alt={name} />
            </div>
            <div className={css.l}>
                <img className={combinedShieldImgClasses} src={path.l} alt={name} />
            </div>
        </div>
    );
};
