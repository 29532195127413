/**
 * @description определяет что данная OS - iOS или Mac OS.
 * @note более надежный способ через userAgent
 * @doc http://stackoverflow.com/a/9039885/177710
 * @returns bool
 */
export function isIOS() {
    /*  Deprecated; #смотри доку
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    */
    let platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
    let mobileDeviseList = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ];
    return mobileDeviseList.includes(platform) || (platform.includes("Mac") && "ontouchend" in document)
}

/**
 * @description определяет что данная OS - Android
 * @doc https://stackoverflow.com/questions/6031412/detect-android-phone-via-javascript-jquery
 * @returns bool
 */
export function isAndroid() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const ua =  userAgent.toLowerCase();
    return ua.indexOf("android") > -1
}

export const isIOS11 = () => isIOS && /OS 11_0|OS 11_1|OS 11_2/.test(navigator.userAgent);

export const getMobileOperatingSystem = () => {
    const os = {
        android: false,
        ios: false,
    };

    if (sessionStorage.getItem('testPush')) {
        os.android = true;
        return os;
    }

    switch (true) {
        case isIOS():
            sessionStorage.setItem('__device_os', 'ios');
            os.ios = true;
        break;
        case isAndroid():
            sessionStorage.setItem('__device_os', 'android');
            os.android = true;
        break;
        default:
            sessionStorage.setItem('__device_os', 'other');
        break;
    }
    return os;
};

// =============================================================================
export const isStandaloneApp = () => {
    // return true;
    const isApp = sessionStorage.getItem('isStandaloneApp');
    return JSON.parse(isApp);
};

/*
* В sessionStorage это значение записывается в файле gtm-script.html
*/
export const getAppType = () => {
    const appType = sessionStorage.getItem('appType');
    return appType;
};

/*
    https://stackoverflow.com/a/13382873/2358252
 */
export function getScrollbarWidth() {
    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
}
