import React, { useCallback, useContext, useMemo } from 'react';

import { UIParagraph } from 'app/components/ui';
import { OverlayContextDispatch } from 'app/containers/contexts/overlay.context';
import { BonusesPopup } from 'app/components/Bonuses';
import BonusesIcon from 'app/components/Bonuses/components/BonusesNotifier/img/bonuses.component.svg';
import css from '../../../../../components/Bonuses/components/BonusesNotifier/BonusesNotifier.module.scss';


import './basket-check-rows.scss';


export const PayByBonuses = (props) => {
    const { basket } = props;

    const { pushOverlay } = useContext(OverlayContextDispatch);
    const openBonusPopup = useCallback(() => {
        // @ts-ignore
        pushOverlay({
            id: 'bonuses_tooltip',
            template: 'dialog',
            overlayProps: { zIndex: 31000 },
            children: (
                <BonusesPopup />
            ),
        });
    }, [pushOverlay]);

    const cashback = useMemo(
        // eslint-disable-next-line react/prop-types
        () => basket?.totals?.referral_amount,
        // eslint-disable-next-line react/prop-types
        [basket?.totals?.referral_amount],
    );

    return (
        <button
            type="button"
            styleName="basket-check__row"
        >
            <div>
                <UIParagraph level="4.2" styleName="basket-check__row-text">
                    <span>
                        Оплата
                        {' '}
                        <span
                            styleName="link"
                            onClick={openBonusPopup}
                            role="none"
                        >
                            бонусами
                        </span>
                    </span>
                </UIParagraph>
            </div>
            <div>
                <div className={css.bonusesAmountCont}>
                    <span className={css.bonusesAmount}>
                        {` -${cashback}`}
                    </span>
                    <BonusesIcon className={css.bonusesIconSmall} />
                </div>
            </div>
        </button>
    );
};
