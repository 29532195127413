import React from 'react';
import PropTypes from 'prop-types';

import XBigIcon from 'assets/svg/x_big.component.svg';

import Link from 'app/components/Link';

import './bonduelle-desktop.scss';

import img1 from './bonduelle_2.jpg';
import img2 from './bonduelle_4.jpg';


const periodConfig = {
    '2021-03-01': {
        img: img1,
        link: '/menu/dish/2950/',
    },
    '2021-03-08': {
        img: img2,
        link: '/menu/dish/2963/',
    },
};


export default function BonduelleDesktop(props) {
    const {
        period,
        onClickClose,
        onClick,
    } = props;

    const data = periodConfig[period];

    if (!data) return null;

    return (
        <div styleName="ny-banner-wrap">
            <Link
                styleName="ny-banner"
                href={`${data.link}${window.location.search}`}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(data.link);
                }}
            >
                <div styleName="ny-banner__img-wrap">
                    <img src={data.img} alt="Готовьте с Bonduelle" styleName="ny-banner__img" />
                </div>
                <div styleName="ny-banner__content">
                    <p styleName="ny-banner__title">
                        Готовьте с Bonduelle
                    </p>
                    <p styleName="ny-banner__text">
                        на всех языка мира!
                    </p>
                    <p styleName="ny-banner__button">
                        Попробовать
                    </p>
                </div>
            </Link>
            <button
                type="button"
                onClick={onClickClose}
                styleName="ny-banner__close"
            >
                <XBigIcon />
            </button>
        </div>
    );
}

BonduelleDesktop.propTypes = {
    period: PropTypes.string.isRequired,
    onClickClose: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
};
