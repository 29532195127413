import React from 'react';
import PropTypes from 'prop-types';

import { UIParagraph } from 'app/components/ui';

import { BasketCheckPrice } from './BasketCheckPrice';
import { locales } from './basket-check.locales';

import './basket-check-rows.scss';


export const DishesPrice = (props) => {
    const {
        basket: { totals: { total_price: dishesPrice } },
        locale,
    } = props;

    return (
        <div styleName="basket-check__row">
            <div>
                <UIParagraph level="4.2" styleName="basket-check__row-text">
                    {locales[locale].dishesPrice}
                </UIParagraph>
            </div>
            <div>
                <BasketCheckPrice price={dishesPrice} />
            </div>
        </div>
    );
};

DishesPrice.propTypes = {
    basket: PropTypes.shape({
        totals: PropTypes.shape({
            total_price: PropTypes.number.isRequired,
            delivery_price: PropTypes.number.isRequired,
            referral_amount: PropTypes.number.isRequired,
            total_common_price: PropTypes.number.isRequired,
            discount_amount: PropTypes.number.isRequired,
        }).isRequired,
    }).isRequired,
    locale: PropTypes.string.isRequired,
};
