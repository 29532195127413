
import LinkedListNode from './classes/LinkedListNode';

export function convertArrayIntoLinkedList(array) {
    if (array.length === 0) return null;

    const nodes = array.map((item, i) => new LinkedListNode(item, i));

    for (let i = 0; i < nodes.length; i += 1) {
        if (i > 0) {
            nodes[i].prev = nodes[i - 1];
            nodes[i - 1].next = nodes[i];
        }
    }

    return nodes;
}
