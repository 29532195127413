import React from 'react';

// import plate1 from './img/plate-1.png';
// import plate2 from './img/plate-2.png';
import './plate.scss';


const Plate = () => (
    <div styleName="plate">
        <div styleName="plate-img" />
    </div>
);

export default Plate;
