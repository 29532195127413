import React, {
    useEffect, useContext, useCallback, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';

import { formatParsedIntervals } from 'app/utils/date';

import { LocaleContext } from 'app/containers/LocaleProvider';
import { OverlayContextDispatch } from 'app/containers/contexts/overlay.context';
import { locales } from 'app/components/DeliveryDate/deliveryDate.locales';


const overlayId = 'mobile_time_intervals_select';


const MobileTimeSelectContainer = (props) => {
    const {
        isSelectRequired,
        isDelivery,
        onClose,
        info,
    } = props;

    const { onSelectTime, selectTime } = info;

    const { pushSelectMobileOverlay, closeCertainOverlay } = useContext(OverlayContextDispatch);
    const { locale } = useContext(LocaleContext);

    const [infoData, setInfoData] = useState(null);

    const handleDone = useCallback(() => {
        closeCertainOverlay({ id: overlayId });
        setInfoData(null);
        onClose();
    }, [onClose, closeCertainOverlay]);

    const handleSelectCheckoutTime = useCallback((e) => {
        const { currentTarget: { dataset: { timeperiod, timeindex } } } = e;
        onSelectTime(timeperiod, Number(timeindex));
        handleDone();
    }, [handleDone, onSelectTime]);

    const handeSelectDeliveryTime = useCallback(async (e) => {
        const { currentTarget: { dataset: { timeperiod, timeindex } } } = e;

        const {
            onSelectTimeInterval,
            deliveryId,
            deliveriesData,
        } = infoData;

        const { intervals } = deliveriesData.find((date) => date.id === deliveryId);

        const interval = intervals[timeindex];

        await onSelectTimeInterval(deliveryId, interval);
        handleDone();
    });

    const handleClickSelectItem = useMemo(
        () => (isDelivery ? handeSelectDeliveryTime : handleSelectCheckoutTime),
        [handeSelectDeliveryTime, handleSelectCheckoutTime, isDelivery],
    );

    useEffect(() => {
        if (isSelectRequired && !isDelivery && !infoData) {
            setInfoData(info);
        }
        if (isSelectRequired && isDelivery && !infoData) {
            const {
                deliveryId,
                deliveriesData,
                onSelectTimeInterval,
            } = info;

            let deliveryData = deliveriesData.find((date) => date.id === deliveryId);

            if (!deliveryData || !deliveryData.intervals) {
                deliveryData = {
                    intervals: [],
                };
            }

            const {
                addressData,
                intervals,
                selectedInterval,
            } = deliveryData;

            const deliveryInfo = {
                timeIntervals: formatParsedIntervals(intervals),
                selectTime: {
                    timePeriod: 'inside_mkad',
                    timeIndex: intervals.findIndex((item) => item.hourFrom === selectedInterval.hourFrom
                        && item.hourTo === selectedInterval.hourTo),
                },
                isShownDeliveryZoneText: addressData
                    ? addressData.beltwayDistance > 0 && addressData.beltwayDistance < 12
                    : false,
                onSelectTimeInterval,
                deliveryId,
                deliveriesData,
            };

            setInfoData(deliveryInfo);
        }
    }, [handeSelectDeliveryTime, info, infoData, isDelivery, isSelectRequired]);


    useEffect(() => {
        if (isSelectRequired && infoData) {
            const l = locales[locale];
            const headerText = l.time;

            const {
                timeIntervals,
            } = infoData;

            const finalTimeIntervals = timeIntervals;

            const timeIntervalsItems = finalTimeIntervals.map((item, index) => {
                const isSelected = index === infoData.selectTime.timeIndex;

                return {
                    id: item.start + item.end + index,
                    selected: isSelected,
                    buttonProps: {
                        onClick: handleClickSelectItem,
                        'data-timeindex': index,
                        'data-timeperiod': 'inside_mkad', // TODO BR-995
                        disabled: !item.enabled,
                    },
                    TextCmp: (
                        <>
                            <span className="num">{item.start}</span>
                            {' '}
                            &mdash;
                            {' '}
                            <span className="num">{item.end}</span>
                        </>
                    ),
                };
            });

            const selectData = {
                headerText,
                sections: [{
                    id: 'mobileTimeIntervalsSection',
                    items: timeIntervalsItems,
                }],
                onClose: handleDone,
            };

            pushSelectMobileOverlay(overlayId, selectData, { onClick: handleDone });
        }
    }, [
        handleClickSelectItem,
        selectTime,
        handleDone,
        handleSelectCheckoutTime,
        infoData,
        isDelivery,
        isSelectRequired,
        locale,
        pushSelectMobileOverlay,
    ]);

    return null;
};

export default MobileTimeSelectContainer;


MobileTimeSelectContainer.propTypes = {
    info: PropTypes.shape({
        timeIntervals: PropTypes.arrayOf(PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string,
        })),
        // mkad: PropTypes.oneOf(['inside', 'outside']),
        selectTime: PropTypes.shape({
            timePeriod: PropTypes.string,
            timeIndex: PropTypes.number,
        }),
        onSelectTime: PropTypes.func,
        isShownDeliveryZoneText: PropTypes.bool,
    }),

    onClose: PropTypes.func.isRequired,
};
