import React from 'react';

export const getAgeGateLocales = () => ({
    ru: {
        dialog: {
            success: {
                strongText: <>
                    18+
                </>,
                regularText: (
                    <>
                        для доступа необходимо подтвердить совершеннолетний возраст
                    </>
                ),
                confirmText: 'Да, мне исполнилось 18',
                confirmTextMobile: 'Да, мне исполнилось 18',
                rejectText: 'Нет, мне меньше 18',
                rejectTextMobile: 'Нет, мне меньше 18',
            },
            error: {
                strongText: 'Произошла ошибка',
                regularText: 'Попробуйте повторить позже',
                confirmText: 'Хорошо',
            },
        },
    },
    en: {
        dialog: {
            success: {
                regularText: (
                    <>
                    </>
                ),
                confirmText: 'Ok, leave it without any changes',
                rejectText: 'Go back and choose others tags',
            },
            error: {
                strongText: 'Error',
                regularText: 'Please try again later',
                confirmText: 'OK',
            },
        },
    },
});
