import { sessionCommentCustomizationFactory } from './factory/sessionCommentCustomizationFactory';
import { subscriptionCommentCustomizationFactory } from './factory/subscriptionCommentCustomizationFactory';
import { userFilterTagsCustomizationFactory } from './factory/userFilterTagsCustomizationFactory';
import { accountCustomizationTagsFactory } from './factory/accountCustomizationTagsFactory';

import { CustomizationSourceData } from './customizationSource.types';


const customizationSourcesOrder = [
    sessionCommentCustomizationFactory,
    subscriptionCommentCustomizationFactory,
    userFilterTagsCustomizationFactory,
    accountCustomizationTagsFactory,
];


export const getCustomizationSource = (
    customizationSourceData: CustomizationSourceData,
    isTagsCustomizationKeep: boolean,
) => {
    const сustomization = customizationSourcesOrder
        .map((factory) => factory(customizationSourceData, isTagsCustomizationKeep))
        .find((item) => item.isThisSource);

    return сustomization;
};
