/* eslint-disable no-underscore-dangle */
import { isEmpty, isEqual } from 'lodash';
import differenceInDays from 'date-fns/difference_in_days';
import isSameWeek from 'date-fns/is_same_week';


/**
 * @description проверка на то что минимальные данные от юзера получены
 */
export const checkisRequiredDataReceived = ({ user, deliveryAddress }) => {
    /**
    * Считает адрес введенным, если в нем есть номер дома
    */
    // const isAddressReady = Boolean(deliveryAddress?.address?.street && deliveryAddress?.address?.house);
    const isAddressReady = Boolean(deliveryAddress?.address?.house);

    if (!user) {
        return true;
    }

    if (!isAddressReady) {
        return true;
    }

    return false;
};


const _getCityАddendum = (locationKey) => {
    const addendum = locationKey === 'MSK' ? 0 : 1;
    return addendum;
};


const msk16hours = 'T13:00:00Z';
const x3 = 3;

export const checkIsX3InCurrentPeriod = ({ selectedPeriod, menuDates, cart }, locationKey) => {
    const today = new Date();
    const cityAddendum = _getCityАddendum(locationKey);

    const datesByType = {
        regular: 'eeAvailableDates',
        everyday: 'everydayAvailableDates',
        '5x2': 'trialAvailableDates',
        premium: 'premiumAvailableDates',
        top: 'topAvailableDates',
        family: 'zapasAvailableDates',
    };
    const datesKey = datesByType[cart.typeOfSet];

    const mappedMenuDatas = menuDates.dates
        .filter((uddPeriod) => uddPeriod.startDate === selectedPeriod)
        .map((uddPeriod) => ({
            startDate: uddPeriod.startDate,
            xDays: uddPeriod[datesKey].map((date) => {
                const d = new Date(`${date}${msk16hours}`);
                const diff = differenceInDays(d, today);
                return diff;
            }),
        }));

    const currentUddPeriod = mappedMenuDatas[0];

    if (!currentUddPeriod) return true;

    const isIncludeMoreThanThree = currentUddPeriod.xDays.filter((xDay) => xDay >= x3 + cityAddendum);

    return !isEmpty(isIncludeMoreThanThree);
};


/**
 * @description делает cart плоским. Убирает поле section
 */
export const getFlatCart = ({ cart }) => {
    if (!cart) {
        return [];
    }

    const { sections } = cart;

    const result = sections.reduce((acc, section) => {
        const { items } = section;

        /**
         * @description убирает ненужные данные из items.
         * @note нужен только объект dish
         */
        const normalizedDish = items.map((item) => ({
            ...item.dish,
            tags: item.dish.tags.map((tag) => tag.id),
        }));

        return [...acc, ...normalizedDish];
    }, []);
    return result;
};

/**
 * @description проверка на то, что корзина собрана в соответствии с тегами из user.customizationTags
 */
export const checkIsTagsCustomizationKeep = ({ user }, flatCart) => {
    if (!user) {
        return false;
    }

    const { customizationTagsDetailed } = user;

    const noticeСustomizationTagsDetailed = customizationTagsDetailed.map((tag) => tag.id);

    const result = flatCart
        .map((dish) => {
            const dishCustomizationTags = dish.tags.filter((tag) => noticeСustomizationTagsDetailed.includes(tag));
            return {
                ...dish,
                isCustomizationKeep: isEqual(dishCustomizationTags, noticeСustomizationTagsDetailed),
            };
        },
        )
        .filter((dish) => !dish.isCustomizationKeep,
        );

    return isEmpty(result);
};

export const checkDeliveryDatesByCurrentPeriod = (data) => {
    const { selectedPeriod, deliveryDates } = data;
    const periodDate = new Date(selectedPeriod);

    const firstDateInSelectedPeriod = deliveryDates.available_dates.find((d) => {
        const dd = new Date(d);
        return isSameWeek(periodDate, dd, { weekStartsOn: 1 });
    });

    return !firstDateInSelectedPeriod;
};
