import React, { useMemo } from 'react';
import { checkIsX3FromSelectedDate } from 'app/views/Basket/utils/checkIsX3FromSelectedDate';
import { checkoutDeliveryDateVar, selectedPeriodVar } from 'app/apollo/reaction';
import { useFragment } from '@apollo/client';
import TYPE_OF_SET_FRAGMENT from 'app/views/Hooks/usePaperRecipe/typeOfSetFragment.graphql';

export const usePaperRecipe = () => {
    const deliveryDate = checkoutDeliveryDateVar();

    const { data: { typeOfSet } } = useFragment({
        fragment: TYPE_OF_SET_FRAGMENT,
        from: {
            __ref: `cart:new:${selectedPeriodVar()}`,
        },
    });

    const hasX3Dates = useMemo(
        () => checkIsX3FromSelectedDate(deliveryDate),
        [deliveryDate],
    );

    // const paperRecipeDisabled = useMemo(
    //     () => typeOfSet === 'everyday' && !hasX3Dates,
    //     [typeOfSet, hasX3Dates],
    // );
    const paperRecipeDisabled = useMemo(
        () => typeOfSet === 'everyday',
        [typeOfSet],
    );

    return { paperRecipeDisabled };
};
