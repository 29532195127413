import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    VERSION_LEGACY,
    VERSION_UIKIT,
    VERSION_UIKIT_RESPONSIVE,
} from '../../checkbox-consts';

import Checkmark from './checkmark.svg';
import CheckmarkUI from './checkmark.ui.component.svg';

import './checkbox-mark.scss';


const checkmarks = {
    [VERSION_LEGACY]: Checkmark,
    [VERSION_UIKIT]: CheckmarkUI,
    [VERSION_UIKIT_RESPONSIVE]: CheckmarkUI,
};


export default function CheckboxMark({
    checked,
    disabled = false,
    version,
}) {
    const markClasses = classNames({
        checkmark: true,
        checked,
        disabled,
        [version]: true,
    });

    const Mark = checkmarks[version];

    return (
        <span styleName={markClasses}>
            {checked && <Mark />}
        </span>
    );
}

CheckboxMark.propTypes = {
    version: PropTypes.oneOf([VERSION_LEGACY, VERSION_UIKIT, VERSION_UIKIT_RESPONSIVE]).isRequired,
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
};
