export const dateAndTimeInitialState = {
    timeIndex: 0,
    selectedDeliveryDate: '',
    isNeedToShowDialog: false,
    isNeedToShowAddressDialog: false,
    regionId: '',
    isNeedToIgnoreDatesFilter: false,
    isDateTimePopupOpenned: false,
};

export function dateAndTimeReducer(state, action) {
    switch (action.type) {
        /* State выбора времени доставки */
        case 'SELECT_TIME_INTERVAL_ITEM':
            return {
                ...state,
                timeIndex: action.payload.timeIndex,
            };
        /** @case ставит флаг который отображает диалог с неправленой датой доставки */
        case 'SET_REGION_ID':
            return {
                ...state,
                regionId: action.payload.regionId,
            };
        /* State выбора даты доставки */
        /** @case ставит дату из запроса */
        case 'SELECT_INITIAL_ENABLED_DELIVERY_DATE':
            return {
                ...state,
                selectedDeliveryDate: action.payload.day,
            };
        /** @case ставит дату из handler'а в checkout.js @see handleSelectDay */
        case 'SELECT_DELIVERY_DATE':
            return {
                ...state,
                selectedDeliveryDate: action.payload.day,
            };
        /** @case ставит флаг который отображает диалог с неправленой датой доставки */
        case 'SET_DATE_DIALOG_FLAG':
            return {
                ...state,
                isNeedToShowDialog: action.payload.isNeedToShowDialog,
            };
        /** */
        case 'SET_ADDRESS_DIALOG_FLAG':
            return {
                ...state,
                isNeedToShowAddressDialog: action.payload.isNeedToShowDialog,
            };
        case 'SET_IGNORE_DATES_FILTER':
            return {
                ...state,
                isNeedToIgnoreDatesFilter: action.payload.isNeedToIgnoreDatesFilter,
            };
        case 'SET_IS_DATE_TIME_POPUP_OPENNED':
            return {
                ...state,
                isDateTimePopupOpenned: action.payload.isDateTimePopupOpenned,
            };
        default:
            return { ...state };
    }
}
