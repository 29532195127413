import cookie from 'js-cookie';

const PUSH_POPUP_COOKIE_NAME = 'is-push-popup-closed';
const PUSH_POPUP_COOKIE_VALUE = '1';
const PUSH_POPUP_COOKIE_CLOSE_EXPIRES_DAYS = 3;

export const isPushPopupIsClosedByUser = () => cookie.get(PUSH_POPUP_COOKIE_NAME);

export const setPushPopupClosedCookie = () => {
    const expires = new Date();
    expires.setDate(expires.getDate() + PUSH_POPUP_COOKIE_CLOSE_EXPIRES_DAYS);

    cookie.set(
        PUSH_POPUP_COOKIE_NAME,
        PUSH_POPUP_COOKIE_VALUE,
        { expires },
    );
};
