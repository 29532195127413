/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
import {
    CATEGORIES_URL_VALUES,
    CATEGORIES_URL_VALUES_IN_CIRILIKE,
} from 'app/const/categories';

import { isSubscription } from 'app/const/subscription';

import {
    metaAkaFacebookEventsRecord,
    firebaseSpecificEventsRecord,
    GASpecificEventsRecord,
    stepByStepCheckoutEventsRecord,
    flocktorySpecificEventsRecord,
    cityAdsSpecificEventsRecord,
    BR1104EventsRecord,
    BR1125EventsRecord,
    V2ProfileEventsRecord,
    BR1131EventsRecord,
} from './specificRecords';

import {
    prepareLocationName, getFullTitle,
    getPurchaseEventParams, getBonusInvoceData,
    getFlatCheckoutParamApp, getFlatCheckoutParam,
    getEventLabel, getMobileEventLable,
} from '../events.utils';


const jointEventsRecord = [
    /**
     * @event 'Burger_Menu_Close'
     * @note только для firebase
     */
    {
        eventName: 'Burger_Menu_Close',
        description: 'Событие закрытия бурер меню в мобильный версии',
        meta: ['click'],
        scope: ['index', 'landing', 'spa'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },
    /**
     * @event 'Burger_Menu_Open'
     * @note только для firebase
     */
    {
        eventName: 'Burger_Menu_Open',
        description: 'События открытия бурер меню в мобильный версии',
        meta: ['click'],
        scope: ['index', 'menu'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },
    /**
     * @event Lead_Sent
     */
    {
        eventName: 'Lead_Sent',
        description: 'Событие отправки лида',
        meta: ['state'],
        scope: ['landing', 'menu', '5x2'],
        check() {
            return true;
        },
        prepare(data) {
            const {
                phone, email, isNewbie, lead_type, id,
            } = data;
            return {
                event: data.name === 'GoogleAnalytics' ? 'submit_form' : 'Lead_Sent',
                phone,
                email,
                source_name: prepareLocationName(),
                lead_id: id,
                lead_type,
                user_type: isNewbie ? 'new' : 'old',
            };
        },
    },

    /**
     * @event Lead_Sent_First
     */
    {
        eventName: 'Lead_Sent_First',
        description: 'События отправки первого лида для пользователя',
        meta: ['state'],
        scope: ['landing', 'menu', '5x2'],
        check() {
            return true;
        },
        prepare(data) {
            const {
                phone, email, isNewbie, lead_type, id, eventName,
            } = data;

            return {
                event: eventName,
                phone,
                email,
                source_name: prepareLocationName(),
                lead_id: id,
                lead_type,
                user_type: isNewbie ? 'new' : 'old',
            };
        },
    },

    /**
     * @event 'Burger_Menu_Click'
     */
    {
        eventName: 'Burger_Menu_Click',
        description: 'Событиe клика по ссылкам внутри бургер меню',
        meta: ['click'],
        scope: ['index', 'menu'],
        check(data) {
            const hasButtonName = Boolean(data.button_name);
            return hasButtonName;
        },
        prepare(data) {
            const { eventName, button_name } = data;
            return {
                event: eventName,
                link_name: button_name,
            };
        },
    },

    /**
     * @event Trial_Start
     * @scope Лендинг 5x2
     */
    {
        eventName: 'Trial_Start',
        description: 'Событие передает значение А/В теста на странице 5x2',
        meta: ['viewport'],
        scope: ['5x2'],
        check() {
            return true;
        },
        prepare(data) {
            const { result, user_type } = data;
            return {
                event: 'Trial_Start',
                result,
                user_type,
            };
        },
    },
    /**
     * @event Trial_Scroll
     * @scope Лендинг 5x2
     */
    {
        eventName: 'Trial_Scroll',
        description: 'Событие отслеживает блоки которые попали во вьюпорт на странице 5x2',
        meta: ['viewport'],
        scope: ['5x2'],
        check() {
            return true;
        },
        prepare(data) {
            const { header_name } = data;
            return {
                event: 'Trial_Scroll',
                header_name,
            };
        },
    },
    /**
     * @event Submit_Phone_Number
     */
    {
        eventName: 'Submit_Phone_Number',
        description: 'Событие заполнения номера телефона. Вызывается во всех формах с номером телeфона',
        meta: ['submit'],
        scope: ['5x2', 'auth', 'checkout'],
        check() {
            return true;
        },
        prepare(data) {
            const { source, userType } = data;

            return {
                event: 'Submit_Phone_Number',
                source_name: source,
                user_type: userType,
            };
        },
    },
    /**
     * @event 'add_to_cart'
     */
    {
        eventName: 'add_to_cart',
        description: 'Событие Добавления блюда в корзину',
        meta: ['state'],
        scope: ['menu', 'basket', 'landing'],
        check() {
            return true;
        },
        prepare(data) {
            const {
                dishes, subscriptionType, source, typeOfSet = null,
            } = data;
            const products = dishes.map((dish) => {
                const {
                    title, caption, id, price, portion, categoryId,
                } = dish;

                return {
                    item_name: getFullTitle({ title, caption }),
                    item_id: id,
                    item_category: CATEGORIES_URL_VALUES[categoryId] || categoryId,
                    quantity: portion,
                    price: isSubscription(subscriptionType) ? Math.ceil(price * 0.9) : price,
                };
            });
            const prepSetName = typeOfSet === 'regular' ? null : typeOfSet;

            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name):
                    return {
                        event: 'add_to_cart',
                        typeOrder: typeOfSet,
                        currencyCode: 'RUB',
                        currency: 'RUB',
                        value: products.reduce((acc, p) => acc + (p.price * p.quantity), 0),
                        items: products,
                        set_name: prepSetName,
                        source_name: source,
                    };
                case ['GoogleAnalytics'].includes(data.name):
                    return {
                        event: 'addToCart',
                        typeOrder: typeOfSet,
                        source_name: source,
                        set_name: prepSetName,
                        ecommerce: {
                            currencyCode: 'RUB',
                            add: { products },
                        },
                    };
                default:
                    return null;
            }
        },
    },
    /**
     * @event 'Collection_Click'
     */
    {
        eventName: 'Collection_Click',
        description: 'Событие клика по баннерам с подборками',
        meta: ['click'],
        scope: ['menu', 'main'],
        check() {
            return true;
        },
        prepare(data) {
            const { banner_name, source_name } = data;
            return {
                event: 'Collection_Click',
                banner_name,
                source_name,
            };
        },
    },
    /**
     * @event 'Product_Impression'
     */
    {
        eventName: 'Product_Impression',
        description: 'Событие отслеживает блюда которые попали в вьюпорт',
        meta: ['viewport'],
        scope: ['menu', 'basket'],
        check() {
            return true;
        },
        prepare(data) {
            const { dish, subscriptionType, source_name } = data;

            const eventDish = dish.map((item) => {
                const category = CATEGORIES_URL_VALUES[item.categoryId] || item.categoryId;

                return {
                    name: getFullTitle(item),
                    id: item.id,
                    price: isSubscription(subscriptionType) ? Math.ceil(item.price * 0.9) : item.price,
                    category,
                };
            });

            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name):
                    return {
                        event: 'Product_Impression',
                        source_name,
                        ...eventDish[0],
                    };
                case ['GoogleAnalytics'].includes(data.name):
                    return {
                        event: 'productImpression',
                        source_name,
                        ecommerce: {
                            currencyCode: 'RUB',
                            impressions: eventDish,
                        },
                    };
                default:
                    return null;
            }
        },
    },
    /**
     * @event 'Main_Banner_Click'
     */
    {
        eventName: 'Main_Banner_Click',
        description: 'Событие клика по банерам на главной',
        meta: ['click'],
        scope: ['index'],
        check() {
            return true;
        },
        prepare(data) {
            const { banner_name } = data;
            return {
                event: 'Main_Banner_Click',
                banner_name,
            };
        },
    },
    /**
     * @event 'Header_Click'
     */
    {
        eventName: 'Header_Click',
        description: "События клика по ссылкам и кнопкам в header'e. Выбор города, лого, итд",
        meta: ['click'],
        scope: ['landing', 'menu', '5x2'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName, button_name } = data;
            return {
                event: eventName,
                button_name,
            };
        },
    },
    /**
     * @event 'Select_Region'
     */
    {
        eventName: 'Select_Region',
        description: "События клика по выбору региона в header'e. Уходит при инициализации приложения",
        meta: ['click'],
        scope: ['landing', 'index'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName, source_name, result } = data;
            return {
                event: eventName,
                source_name,
                result,
            };
        },
    },
    /**
     * @event 'Checkout_Close_Address_Popup'
     */
    {
        eventName: 'Checkout_Close_Address_Popup',
        description: 'Событие закрытия окна ввода адресса',
        meta: ['click'],
        scope: ['landing', 'basket'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventLocation, result } = data;
            return {
                event: 'Checkout_Close_Address_Popup',
                source_name: eventLocation,
                result,
            };
        },
    },
    /**
     * @event 'Checkout_Sent'
     * @node GTM Only
     * @scope Checkout
     * @description Отправка формы заказа (переход к оплате)
     */
    {
        eventName: 'Checkout_Sent',
        description: 'Событие отправки формы заказа (переход к оплате)',
        meta: ['state'],
        scope: ['checkout'],
        check() {
            return true;
        },
        prepare(data) {
            const {
                external_subscription_id, invoiceData, dishes, typeOfSet, user_id,
            } = data;

            const eventParams = getPurchaseEventParams(invoiceData, dishes);

            const { ecommerce: { purchase }, userType } = eventParams;
            const { revenue, ...otherParams } = purchase;

            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name):
                    return {
                        ...otherParams,
                        value: revenue,
                        user_type: userType,
                        typeOrder: typeOfSet,
                        event: 'Checkout_Sent',
                        external_subscription_id,
                        user_id,
                    };
                case ['GoogleAnalytics'].includes(data.name):
                    return {
                        ...eventParams,
                        typeOrder: typeOfSet,
                        external_subscription_id,
                        event: 'Checkout_Sent',
                        user_id,
                    };
                default:
                    return null;
            }
        },
    },
    /**
     * @event 'Checkout_Payment_Radiobuttons'
     */
    {
        eventName: 'Checkout_Payment_Radiobuttons',
        description: 'Событие клика по радокнопки выбора способа оплаты',
        meta: ['click'],
        scope: ['checkout'],
        check() {
            return true;
        },
        prepare(data) {
            const { button_name } = data;

            return {
                event: 'Checkout_Payment_Radiobuttons',
                button_name,
            };
        },
    },
    /**
     * @event 'setUserId'
     * TODO: setUserId вызывается три раза по мере продвижения пользователя от
     * menu к checkout, 2 раза в AuthContauner и 1 раз в Checkout.
     * теперь мы можем контролировать вызов если нежно?
     */
    {
        eventName: 'setUserId',
        description: 'Событие инициализации пользователя',
        meta: ['state'],
        scope: ['menu', 'landing'],
        check() {
            return true;
        },
        prepare(data) {
            const { userId } = data;
            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name):
                    return {
                        event: 'setUserId',
                        userId,
                    };
                case ['GoogleAnalytics'].includes(data.name):
                    return {
                        userid: userId,
                        event: 'setUserId',
                    };
                default:
                    return null;
            }
        },
    },
    /**
     * @event 'Track_Login'
     */
    {
        eventName: 'Track_Login',
        description: 'Событие успешного логина пользователя',
        meta: ['state'],
        scope: ['menu', 'landing'],
        check() {
            return true;
        },
        prepare(data) {
            const { isFirstAuth } = data;
            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name):
                    return {
                        event: isFirstAuth ? 'Form_First_Login' : 'Form_Login',
                    };
                case ['GoogleAnalytics'].includes(data.name):
                    return {
                        event: 'Form',
                        eventCategory: 'Form',
                        eventAction: isFirstAuth ? 'First Login' : 'Login',
                    };
                default:
                    return null;
            }
        },
    },
    /**
     * @event 'Copy_To_Clipboard'
     */
    {
        eventName: 'Copy_To_Clipboard',
        description: 'Событие трекает клик по кнопкe с ссылкой для реферальной программы',
        meta: ['click'],
        scope: ['profile'],
        check() {
            return true;
        },
        prepare(data) {
            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name):
                    return {
                        event: 'Copy_Link',
                    };
                case ['GoogleAnalytics'].includes(data.name):
                    return {
                        event: 'CopyLink',
                    };
                default:
                    return null;
            }
        },
    },
    /**
     * @event 'Use_Bonus_Invoce'
     * Уходит как Bonus в GA и FIrebase как Bonus_Use, Bonus_Withdraw
     */
    {
        eventName: 'Use_Bonus_Invoce',
        description: 'Событие трекает клик по кнопкe применить бонусы для оплаты текущего заказа',
        meta: ['click'],
        scope: ['profile'],
        check() {
            return true;
        },
        prepare(data) {
            const {
                eventAction,
                eventValue,
                eventLabel,
            } = data;
            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name):
                    return getBonusInvoceData(data);
                case ['GoogleAnalytics'].includes(data.name):
                    return {
                        event: 'Bonus',
                        eventCategory: 'Bonus',
                        eventLabel,
                        eventAction,
                        eventValue,
                    };
                default:
                    return null;
            }
        },
    },
    /**
     * @event 'Check_Send_Promo'
     */
    {
        eventName: 'Check_Send_Promo',
        description: 'Трекает валидность введеного промокода',
        meta: ['submit'],
        scope: ['5x2', 'basket', 'checkout'],
        check(data) {
            return Boolean(data.promo);
        },
        prepare(data) {
            const {
                isPromoCodeExists,
                isPromocodeCombineWithDiscount,
                isSubscriptionActivated,
                promo,
                sourceName,
            } = data;
            /**
             * @description Проверка возможности применения промокода
             * @case Любой промокод может быть активирован если у пользователя нет подписки
             * @case Промокод нельзя считать активированным если он не совместим с подпиской
            */
            const isRecurrentBlockPromo = !isSubscriptionActivated || isPromocodeCombineWithDiscount;
            const isPromoEntirelyExists = isPromoCodeExists && isRecurrentBlockPromo;
            const result = isPromoEntirelyExists ? 'success' : 'failure';
            return {
                event: 'Check_Send_Promo',
                promo,
                result,
                source_name: sourceName,
            };
        },
    },

    /**
     * @event 'Check_Get_Discount'
     */
    {
        eventName: 'Check_Get_Discount',
        description: 'Трекает клик по ссылки для получение промо на 5x2',
        meta: ['click'],
        scope: ['5x2'],
        check() {
            return true;
        },
        prepare(data) {
            const {
                sourceName,
            } = data;
            return {
                event: 'Check_Get_Discount',
                source_name: sourceName,
            };
        },
    },

    /**
     * @event 'Check_Info_About_Delivery'
     */
    {
        eventName: 'Check_Info_About_Delivery',
        description: 'Трекает клик по кнопкe открытия модального окна с информацией о доствке',
        meta: ['click'],
        scope: ['basket', 'checkout'],
        check() {
            return true;
        },
        prepare() {
            return {
                event: 'Check_Info_About_Delivery',
            };
        },
    },
    /**
     * @event 'Checkout_Click_Address'
     */
    {
        eventName: 'Checkout_Click_Address',
        description: "Трекает кликл по input'у ввода адреса доставки",
        meta: ['click'],
        scope: ['basket', 'landing', 'checkout'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventLocation } = data;
            return {
                event: 'Checkout_Click_Address',
                source_name: eventLocation,
            };
        },
    },
    /**
     * @event 'Check_Subscription_Toggle'
     * Уходит в GA как Check_Unselect_Subscription, Check_Select_Subscription
     * в зависимости от состояния.
     */
    {
        eventName: 'Check_Subscription_Toggle',
        description: 'Трекает клик по кнопкe переключения подписки',
        meta: ['click'],
        scope: ['basket', 'checkout'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventValue } = data;
            return {
                event: eventValue,
            };
        },
    },
    /**
     * @event 'Form_Wish'
     */
    {
        eventName: 'Form_Wish',
        description: 'Трекает отправку формы с комментарием костамизации',
        meta: ['submit'],
        scope: ['basket', 'checkout', 'profile'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventLocation } = data;
            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name):
                    return {
                        event: 'Form_Wish',
                        source_name: eventLocation,
                    };
                case ['GoogleAnalytics'].includes(data.name):
                    return {
                        event: 'Form_Wish',
                        source_name: eventLocation,
                        eventCategory: 'Form',
                        eventAction: 'Wish',
                    };
                default:
                    return null;
            }
        },
    },
    /**
    /**
     * @event 'Check_Info_About_Subscription'
     */
    {
        eventName: 'Check_Info_About_Subscription',
        description: 'Трекает клик кнопке открываюшей делаку с информацией о достаке',
        meta: ['click'],
        scope: ['checkout', 'basket'],
        check() {
            return true;
        },
        prepare() {
            return {
                event: 'Check_Info_About_Subscription',
            };
        },
    },
    /**
     * @event 'Open_Pop_Over'
     */
    {
        eventName: 'Open_Pop_Over',
        description: 'Трекает клик по блюдам который открывал детаклу с описанием из A/B/C теста',
        meta: ['click'],
        scope: ['basket'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventLabel } = data;
            return {
                event: 'exposure',
                eventCategory: 'exposure',
                eventAction: 'pop_over',
                eventLabel,
            };
        },
    },
    /**
     * @event 'Observe_Tags_In_Viewport'
     */
    {
        eventName: 'Observe_Tags_In_Viewport',
        description: 'Отслеживает теги фильтров в мобильной версии которые попали по viewport',
        meta: ['viewport'],
        scope: ['menu'],
        check() {
            return true;
        },
        prepare(data) {
            const { nextResult } = data;
            const [eventparams] = nextResult;
            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name):
                    return {
                        event: 'Menu_Scroll',
                        eventParams: eventparams,
                    };
                case ['GoogleAnalytics'].includes(data.name):
                    return {
                        event: 'Menu',
                        eventCategory: 'Menu',
                        eventAction: 'Scroll',
                        eventLabel: eventparams,
                    };
                default:
                    return null;
            }
        },
    },
    /**
     * @event 'Payment_Button_Click'
     */
    {
        eventName: 'Payment_Button_Click',
        description: "Трекает клик по кнопке 'прейти к оплате'",
        meta: ['click'],
        scope: ['menu'],
        check() {
            return true;
        },
        prepare(data) {
            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name):
                    return {
                        event: 'PaymentButtonClickApp',
                    };
                case ['GoogleAnalytics'].includes(data.name):
                    return {
                        event: 'PaymentButtonClick',
                        eventCategory: 'PaymentButtonClick',
                        eventAction: 'PaymentButtonClick',
                    };
                default:
                    return null;
            }
        },
    },
    /**
     * @event 'Dish_Details_Viewed'
     * Уходит как Product_Details для Firebase и productDetail для GA
     */
    {
        eventName: 'Dish_Details_Viewed',
        description: 'Трекает клик по блюду. Октрые модального окна с информациец о блюде',
        meta: ['click'],
        scope: ['menu', 'landing'],
        check() {
            return true;
        },
        prepare(data) {
            const dish = data;
            const { source, subscriptionType } = dish;
            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name):
                    return {
                        event: 'Product_Details',
                        item_name: getFullTitle(dish),
                        item_id: dish.id,
                        item_category: CATEGORIES_URL_VALUES[dish.categoryId] || dish.categoryId,
                        source,
                        item_price: isSubscription(subscriptionType) ? Math.ceil(dish.price * 0.9) : dish.price,
                        price: isSubscription(subscriptionType) ? Math.ceil(dish.price * 0.9) : dish.price,
                    };
                case ['GoogleAnalytics'].includes(data.name):
                    return {
                        event: 'productDetail',
                        source,
                        ecommerce: {
                            detail: {
                                products: [{
                                    name: getFullTitle(dish),
                                    id: dish.id,
                                    price: isSubscription(subscriptionType) ? Math.ceil(dish.price * 0.9) : dish.price,
                                    category: CATEGORIES_URL_VALUES[dish.categoryId] || dish.categoryId,
                                }],
                            },
                        },
                    };
                default:
                    return null;
            }
        },
    },
    /**
     * @event 'Payment_Button_Click_Error'
     */
    {
        eventName: 'Payment_Button_Click_Error',
        description: "Трекает состоние, если клик по кнопкe 'оплаты' вызвал ошибку",
        meta: ['click'],
        scope: ['checkout'],
        check() {
            return true;
        },
        prepare(data) {
            const event = 'Payment_Button_Click_Error';
            const eventParams = {
                event,
                eventCategory: event,
                eventAction: event,
            };
            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name):
                    return {
                        event: `${event}App`,
                    };
                case ['GoogleAnalytics'].includes(data.name):
                    return {
                        ...eventParams,
                    };
                default:
                    return null;
            }
        },
    },
    /**
     * @event 'Track_Purchase'
     */
    {
        eventName: 'Track_Purchase',
        description: 'Трекает упешный заказ',
        meta: ['state'],
        scope: ['checkout'],
        check() {
            return true;
        },
        prepare(data) {
            const {
                invoiceData,
                dishes,
                typeOfSet,
                external_subscription_id,
            } = data;

            const eventParams = getPurchaseEventParams(invoiceData, dishes);

            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name):
                    const { ecommerce: { purchase } } = eventParams;
                    const { revenue, ...otherParams } = purchase;

                    const params = {
                        ...otherParams,
                        event: 'purchase',
                        value: revenue,
                        user_type: eventParams.userType,
                        typeOrder: typeOfSet,
                        external_subscription_id,
                    };

                    return params;
                case ['GoogleAnalytics'].includes(data.name):
                    return {
                        ...eventParams,
                        typeOrder: typeOfSet,
                        external_subscription_id,
                    };
                default:
                    return null;
            }
        },
    },
    /**
     * @event 'Track_Click_Best_Set'
     */
    {
        eventName: 'Track_Click_Best_Set',
        description: "Трекает клик по кнопке с подборками в header'e spa",
        meta: ['state'],
        scope: ['menu'],
        check() {
            return true;
        },
        prepare(data) {
            const { setName } = data;
            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name):
                    return {
                        event: 'Menu_Set_Click',
                        eventAction: setName,
                        eventActionLegacy: `Menu_${setName}`,
                        banner_name: setName,
                    };
                case ['GoogleAnalytics'].includes(data.name):
                    return {
                        event: 'Menu',
                        eventCategory: 'Menu',
                        eventAction: setName,
                        setName,
                        banner_name: setName,
                    };
                default:
                    return null;
            }
        },
    },
    /**
     * @event 'NY_click'
     */
    {
        eventName: 'NY_click',
        description: 'Трекает клик по кнопкам навигации на новогоднем лендинге',
        meta: ['state'],
        scope: ['landing'],
        check() {
            return true;
        },
        prepare(data) {
            return {
                event: 'NY_click',
                banner_name: data.bannerName,
            };
        },
    },
    /**
     * @event 'NY_Achievements_Open'
     */
    {
        eventName: 'NY_Achievements_Open',
        description: 'Трекает открытие достижений',
        meta: ['state'],
        scope: ['landing', 'profile'],
        check() {
            return true;
        },
        prepare(data) {
            return {
                event: 'NY_Achievements_Open',
                user_id: data.user_id,
            };
        },
    },
    /**
     * @event 'NY_Achievements_Click_Share'
     */
    {
        eventName: 'NY_Achievements_Click_Share',
        description: 'Трекает открытие шаринга достижений',
        meta: ['state'],
        scope: ['landing', 'profile'],
        check() {
            return true;
        },
        prepare(data) {
            return {
                event: 'NY_Achievements_Click_Share',
                user_id: data.user_id,
            };
        },
    },
    /**
     * @event 'NY_Achievements_Click_Land'
     */
    {
        eventName: 'NY_Achievements_Click_Land',
        description: 'Трекает открытие NY лэндинга из достижений',
        meta: ['state'],
        scope: ['landing', 'profile'],
        check() {
            return true;
        },
        prepare(data) {
            return {
                event: 'NY_Achievements_Click_Land',
                user_id: data.user_id,
            };
        },
    },
    /**
     * @event 'track_Filtration_Steps'
     */
    {
        eventName: 'track_Filtration_Steps',
        description: 'Трекает подтверждение применения фильтров',
        meta: ['submit'],
        scope: ['menu'],
        check() {
            return true;
        },
        prepare(data) {
            const { name, steps } = data;

            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(name):
                    const eventparams = steps.map((e) => `${e.action}_${e.tagTitle}`).join(';');
                    return {
                        event: 'Filter_Tags',
                        eventParams: eventparams,
                    };
                case ['GoogleAnalytics'].includes(name):
                    const eventLabel = steps.map((e) => `${e.itemKey}-${e.action}-${e.tagTitle}`).join(';');
                    return {
                        event: 'Filter',
                        eventCategory: 'Filter',
                        eventAction: 'Change',
                        eventLabel,
                    };
                default:
                    return null;
            }
        },
    },

    /**
     * @event 'Change_Filter_Item'
     */
    {
        eventName: 'Change_Filter_Item',
        description: 'Трекает когда пользователь включает и выключает фильтры',
        meta: ['stete'],
        scope: ['menu'],
        check() {
            return true;
        },
        prepare(data) {
            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name):
                    const { eventName, eventParams } = getMobileEventLable(data);
                    return {
                        event: eventName,
                        eventParams,
                    };
                case ['GoogleAnalytics'].includes(data.name):
                    const eventLabel = getEventLabel(data);
                    return {
                        event: 'Filter',
                        eventCategory: 'Filter',
                        ...eventLabel,
                    };
                default:
                    return null;
            }
        },
    },

    /**
     * @event 'Track_Navigate'
     * @description Шаги оформления заказа
     * Step: 1 - Корзина открыта
     * Step: 2 - Маунт чекаут формы
     * Step: 3 - Когда введен номер телефона
     * Step: 4 - все поля введины
     */
    {
        eventName: 'Track_Navigate',
        description: 'Трекает шаги офтомления заказа: 1 - Корзина открыта, 2 - Маунт чекаут формы, Step: 3 - Когда введен номер телефона Step: 4 - все поля введины',
        meta: ['stete'],
        scope: ['basket', 'checkout'],
        check(data) {
            const isFirebaseService = ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name);
            const isStepsCorrect = [1, 4].includes(data.step);
            if (isFirebaseService && !isStepsCorrect) {
                return false;
            }
            return true;
        },
        prepare(data) {
            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name):
                    const { eventName, eventParams } = getFlatCheckoutParamApp(data);
                    return {
                        ...eventParams,
                        event: eventName,
                        typeOrder: data.typeOfSet,
                    };
                case ['GoogleAnalytics'].includes(data.name):
                    const result = getFlatCheckoutParam(data);
                    return {
                        ...result,
                        typeOrder: data.typeOfSet,
                    };
                default:
                    return null;
            }
        },
    },

    /**
     * @event 'Categories_In_Viewport'
     */
    {
        eventName: 'Categories_In_Viewport',
        description: 'Трекает когда категория попала в viewport',
        meta: ['viewport'],
        scope: ['menu'],
        check() {
            return true;
        },
        prepare(data) {
            const { categoryId } = data;
            return {
                event: 'Menu_Scroll',
                eventParams: CATEGORIES_URL_VALUES_IN_CIRILIKE[categoryId],
            };
        },
    },

    /**
     * @event 'Menu_Side'
     */
    {
        eventName: 'Menu_Side',
        check() {
            return true;
        },
        prepare(data) {
            const { text } = data;
            const d = {
                event: 'Menu_Side',
                eventParams: text,
            };
            return d;
        },
    },

    /**
     * @event 'Change_Category'
     */
    {
        eventName: 'Change_Category',
        description: "Трекает когда пользователь менает категорию из header'a",
        meta: ['click'],
        scope: ['menu'],
        check() {
            return true;
        },
        prepare(data) {
            const { categoryId } = data;
            return {
                event: 'menu',
                eventParams: CATEGORIES_URL_VALUES[categoryId],
            };
        },
    },

    /**
     * @event 'Trial_Click'
     * @description Лендинг 5x2 - Клики по кнопкам
     */
    {
        eventName: 'Trial_Click',
        description: '5x2 - Клики по кнопкам',
        meta: ['click'],
        scope: ['5x2'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName, button_name } = data;
            return {
                event: eventName,
                button_name,
            };
        },
    },

    /**
     * @event 'Checkout_Close_Onepager'
     * @description Закрытие чекаута
     */
    {
        eventName: 'Checkout_Close_Onepager',
        description: 'Закрытие чекаута',
        meta: ['click'],
        scope: ['checkout'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Custom_Tag_Toggle'
     * @description Клик по тегам кастомизации, которые потом отправляются в коммент
     */
    {
        eventName: 'Custom_Tag_Toggle',
        description: 'Клик по тегам кастомизации, которые потом отправляются в коммент',
        meta: ['click'],
        scope: ['basket'],
        check() {
            return true;
        },
        prepare(data) {
            const { tagName, sourceName, state } = data;
            const eventName = state === 'on' ? 'Custom_Tag_On' : 'Custom_Tag_Off';
            return {
                event: eventName,
                tag_name: tagName,
                source_name: sourceName,
            };
        },
    },
    /**
     * @event 'Custom_Apply'
     * @description Сабмит тегов кастомизации, которые потом отправляются в коммент
     */
    {
        eventName: 'Custom_Apply',
        description: 'Сабмит тегов кастомизации, которые потом отправляются в коммент',
        meta: ['click'],
        scope: ['basket'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName, sourceName } = data;
            return {
                event: eventName,
                source_name: sourceName,
            };
        },
    },
    /**
     * @event 'Ornament_Click'
     * @description клик по шарику
     * @note NY2023
     */
    {
        eventName: 'Ornament_Click',
        description: 'клик по шарику NY2023',
        meta: ['click'],
        scope: ['index', 'trial'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName, source_name = 'main' } = data;
            return {
                event: eventName,
                source_name,
            };
        },
    },
    /**
     * @event 'Checkout_Popup_Menu_Will_Change'
     */
    {
        eventName: 'Checkout_Popup_Menu_Will_Change',
        description: 'Всплывающее окно "Будет новое меню"',
        meta: ['mount'],
        scope: ['checkout', 'spa'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },
    /**
     * @event 'Checkout_Click_Popup_Menu_Will_Change'
     */
    {
        eventName: 'Checkout_Click_Popup_Menu_Will_Change',
        description: 'Клик на всплывающее окно "Будет новое меню"',
        meta: ['click'],
        scope: ['checkout', 'spa'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName, eventAction } = data;
            return {
                event: eventName,
                eventAction,
            };
        },
    },
    /**
     * @event 'Checkout_Close_Popup_Menu_Will_Change'
     */
    {
        eventName: 'Checkout_Close_Popup_Menu_Will_Change',
        description: 'Закрытие окна(на крестик или клик по экрану',
        meta: ['click'],
        scope: ['checkout', 'spa'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'default'
     */
    {
        eventName: 'default',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare() {
            return {
                event: 'default',
            };
        },
    },

    ...stepByStepCheckoutEventsRecord,
    ...BR1104EventsRecord,
    ...BR1131EventsRecord,
    ...V2ProfileEventsRecord,
    ...BR1125EventsRecord,
];

export const eventsRecord = {
    FirebaseAnalyticsAndroid: [...firebaseSpecificEventsRecord, ...jointEventsRecord],
    FirebaseAnalyticsIOS: [...firebaseSpecificEventsRecord, ...jointEventsRecord],
    GoogleAnalytics: [...GASpecificEventsRecord, ...jointEventsRecord],
    FacebookAnalytics: metaAkaFacebookEventsRecord,
    FlocktoryAnalytics: flocktorySpecificEventsRecord,
    CityAdsAnalytics: cityAdsSpecificEventsRecord,
    default: [],
};
