import { OverlayContextDispatch } from 'app/containers/contexts/overlay.context';
// eslint-disable-next-line no-unused-vars
import Raact, { useContext } from 'react';


const dialogTexts = {
    ru: {
        strongText: 'Нужно освободить корзину,',
        regularText: 'чтобы добавить туда набор',
        confirmText: 'Хорошо',
        rejectText: 'Нет, не надо',
    },
    en: {
        strongText: 'Empty your basket',
        regularText: 'to add this kit',
        confirmText: 'OK',
        rejectText: 'No, go back',
    },
};

export const useClearBasketPopup = (props: any) => {
    const { handleFillBasketBy } = props;

    const {
        pushDialogOverlay,
        closeLastOverlays,
    } = useContext(OverlayContextDispatch);

    const handleConfirmDialog = (setKey: any) => {
        handleFillBasketBy(setKey);
        const overlaysToClose = ['basket_preview', 'clear_basket_dialog_with_preview'];
        // @ts-ignore
        closeLastOverlays(overlaysToClose);
    };

    const openClearBasketDaalog = (setKey: any) => {
        // @ts-ignore
        pushDialogOverlay('clear_basket_dialog_with_preview', {
            ...dialogTexts.ru,
            onConfirm: () => handleConfirmDialog(setKey),
            onReject: () => closeLastOverlays(),
            notifyOnly: false,
            oneRowButtons: true,
        });
    };

    return openClearBasketDaalog;
};
