/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './toggleSwitch.scss';
import PropTypes from 'prop-types';

const ToggleSwitch = ({
    isChecked = false,
    isDisabled = false,
    id = 'initial-toggle',
    onChanegHandler = () => {},
}) => (
    <div styleName="toggle-switch-root">
        <input
            type="checkbox"
            styleName="toggle-switch-checkbox"
            // name="toggleSwitch"
            checked={isChecked}
            disabled={isDisabled}
            onChange={onChanegHandler}
            id={id}
        />
        <label
            styleName="toggle-switch-label"
            htmlFor={id}
        >
            <span styleName="toggle-switch-background" />
            <span styleName="toggle-switch-handler" />
        </label>
    </div>
);

ToggleSwitch.propTypes = {
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChanegHandler: PropTypes.func,
    id: PropTypes.string,
};

export default ToggleSwitch;
