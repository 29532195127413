import React from 'react';
import css from './burgerMenuFrame.module.scss';

interface IThisProps {
    children: React.ReactNode
}

export const BurgerMenuCardFrame = ({ children }: IThisProps) => (
    <div className={css.burgerMenuCardFrame}>
        {children}
    </div>
);
