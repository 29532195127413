import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import ScrollLock from 'react-scrolllock';
import { analyticService } from 'global/services';

import { selectDishes } from 'app/utils/dish';

import { LocaleContext } from 'app/containers/LocaleProvider';
import MenuDishesProvider from 'app/containers/MenuDishesProvider';
import { MenuDispatchContext, CATEGORY } from 'app/containers/contexts/menu.context';
import { FiltersContext } from 'app/containers/contexts/filters.context/filters.context';
import { useSelectedFilters } from 'app/connect/connectToSelectedFilters';

import { getCategoryCount } from 'app/utils/filters';

import {
    CATEGORIES_ORDER,
    CATEGORY_MOST_INTRESTING,
    CATEGORY_MOST_INTRESTING_TAG_ID,
    CATEGORY_TITLES,
} from 'app/const/categories';

import { UIHeading } from 'app/components/ui';
import ClosePopup from 'app/components/ui/ClosePopup';
import './mobile-categories-select.scss';
import { pickCategoryReaction } from '../../../../apollo/reactions/filters';


function MobileCategoriesSelect(props) {
    const {
        transitionState,
        onClickClose,
        setDishesUpdatingState,
        basketQuery,
    } = props;

    const {
        filterQuery: { menuFilter },
        selectedFilters: { selectedPeriod, selectedCategories, selectedTags },
    } = useContext(FiltersContext);
    const { periods } = menuFilter;

    const { locale } = useContext(LocaleContext);

    const menuDispatch = useContext(MenuDispatchContext);

    const { categories } = periods.find((p) => p.start === selectedPeriod);

    const filteredCategories = categories
        .filter((c) => selectedCategories.includes(c.id));

    //  Handlers ===============================================================
    const handleClickItem = async (categoryId) => {
        /*
            1. анимация закрытия селекта с ожиданием в 400ms
            2. затем первый setDishesUpdatingState скрывает блюда за 300ms
            3. затем происходит выбор категоригории в фильтрах и выбор категории для скролла
            4. запускается анимация появлиения блюд
        */

        menuDispatch({
            type: 'scrollTo',
            payload: { target: CATEGORY, value: categoryId },
        });

        analyticService.push({ eventName: 'Change_Category', categoryId });
        onClickClose();

        const closingSelectAnimationTimeout = 400;
        const hidingDishesAnimationTimeout = 300;

        setTimeout(() => {
            setDishesUpdatingState(
                true,
                () => {
                    setTimeout(async () => {
                        pickCategoryReaction({ categoryId });

                        setDishesUpdatingState(false);
                    }, hidingDishesAnimationTimeout);
                },
            );
        }, closingSelectAnimationTimeout);
    };

    //  Styles =====================================================================
    const isSelectOpen = transitionState !== 'exited';

    const wrapClasses = classNames({
        'mobile-categories-select-wrap': true,
        [transitionState]: transitionState,
    });

    const selectClasses = classNames({
        'mobile-categories-select': true,
        [transitionState]: transitionState,
    });

    return (
        <MenuDishesProvider
            menuFilter={menuFilter}
            basketQuery={basketQuery}
            render={({ menuDishesQuery: { basketDishes, allDishes } }) => {
                const mostIntrestingCategory = {
                    id: CATEGORY_MOST_INTRESTING,
                    title: CATEGORY_TITLES[locale][CATEGORY_MOST_INTRESTING],
                    count: selectDishes(
                        allDishes,
                        null,
                        [CATEGORY_MOST_INTRESTING_TAG_ID, ...selectedTags],
                        selectedCategories,
                    ).length,
                    isSelected: selectedCategories.includes(CATEGORY_MOST_INTRESTING),
                };

                const mostIntrestingHasDishes = selectedTags.length > 0 ? true : mostIntrestingCategory.count > 0;
                const isMostIntrestingCategoryVisible = CATEGORIES_ORDER.includes(CATEGORY_MOST_INTRESTING)
                    && mostIntrestingHasDishes;

                return (
                    <div styleName={wrapClasses}>
                        {isSelectOpen && (<ScrollLock />)}
                        <button
                            type="button"
                            aria-label="Закрыть"
                            onClick={onClickClose}
                            styleName="mobile-categories-select__close-overlay"
                        />

                        <div styleName={selectClasses}>
                            <div styleName="mobile-categories-select__close-row">
                                <div styleName="mobile-categories-select__close-container">
                                    <ClosePopup onClick={onClickClose} />
                                </div>
                            </div>

                            <ul styleName="mobile-categories-list">
                                {isMostIntrestingCategoryVisible && (
                                    <li key={CATEGORY_MOST_INTRESTING} styleName="mobile-categories-list-item">
                                        <button
                                            styleName="mobile-categories-list-item__button"
                                            type="button"
                                            disabled={mostIntrestingCategory.count === 0}
                                            onClick={() => handleClickItem(CATEGORY_MOST_INTRESTING)}
                                        >
                                            <UIHeading level="3.2" inheritColors>
                                                {mostIntrestingCategory.title}
                                            </UIHeading>
                                        </button>
                                    </li>
                                )}

                                {filteredCategories.map((c) => {
                                    const count = getCategoryCount({
                                        id: c.id,
                                        menuDishes: allDishes,
                                        selectedTagIds: selectedTags,
                                        basketDishes,
                                    });
                                    if (selectedTags.length === 0 && count === 0) {
                                        return null;
                                    }
                                    return (
                                        <li key={c.id} styleName="mobile-categories-list-item">
                                            <button
                                                styleName="mobile-categories-list-item__button"
                                                type="button"
                                                disabled={count === 0}
                                                onClick={() => handleClickItem(c.id)}
                                            >
                                                <UIHeading level="3.2" inheritColors>
                                                    {CATEGORY_TITLES[locale][c.id]}
                                                </UIHeading>
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                );
            }}
        />
    );
}


export default MobileCategoriesSelect;

MobileCategoriesSelect.propTypes = {
    transitionState: PropTypes.string.isRequired,
    onClickClose: PropTypes.func.isRequired,
    setDishesUpdatingState: PropTypes.func.isRequired,
    basketQuery: PropTypes.shape({}).isRequired,
};
