import React, { useContext } from 'react';
import { useFragment } from '@apollo/client';

import { LocationContext } from 'app/containers/LocationProvider';
import { OverlayContextDispatch } from 'app/containers/contexts/overlay.context';
import { ADDRESS_SUBDIVISION_FRAGMENT } from 'app/graphql/address/subdivision.fragment.graphql';

/**
 * @description Диалог отображает уведомление о том что на выбранную дату нет доступных интервалов доставки
 * @see fetchTimeIntervalWithDateData
 */
export const useCitySelectMenuDialog = () => {
    const {
        closeLastOverlays, pushSelectMobileOverlay,
    } = useContext(OverlayContextDispatch);

    const {
        getCityTitile, mutateAddreessHandler, isLocationDataLoading,
    } = useContext(LocationContext);

    const { data: sessionAddressData } = useFragment({
        fragment: ADDRESS_SUBDIVISION_FRAGMENT,
        from: {
            __ref: 'address:session_address',
        },
    });

    const {
        isMsk, isSpb, isNiz, isKzn,
    } = getCityTitile({ deliveryAddress: { address: { ...sessionAddressData } } });


    const mskDetailFields = {
        id: 'mobile-city-select-msk',
        buttonProps: {
            onClick: () => {
                mutateAddreessHandler(
                    { address: 'г Москва' },
                );
                closeLastOverlays();
            },
            name: 'btn-msk',
            disabled: isLocationDataLoading || isMsk,
        },
        TextCmp: <>Москва и область</>,
        selected: isMsk,
    };
    const spbDetailFields = {
        id: 'mobile-city-select-spb',
        buttonProps: {
            onClick: () => {
                mutateAddreessHandler(
                    { address: 'г Санкт-Петербург' },
                );
                closeLastOverlays();
            },
            name: 'btn-spb',
            disabled: isLocationDataLoading || isSpb,
        },
        TextCmp: <>С.Петербург и область</>,
        selected: isSpb,
    };

    const nizDetailFields = {
        id: 'mobile-city-select-niz',
        buttonProps: {
            onClick: () => {
                mutateAddreessHandler(
                    { address: 'г Нижний Новгород' },
                );
                closeLastOverlays();
            },
            name: 'btn-spb',
            disabled: isLocationDataLoading || isNiz,
        },
        TextCmp: <>Н.Новгород и область</>,
        selected: isNiz,
    };

    const kznDetailFields = {
        id: 'mobile-city-select-kzn',
        buttonProps: {
            onClick: () => {
                mutateAddreessHandler(
                    { address: 'г Казань' },
                );
                closeLastOverlays();
            },
            name: 'btn-spb',
            disabled: isLocationDataLoading || isKzn,
        },
        TextCmp: <>Казань</>,
        selected: isKzn,
    };

    const selectData = {
        headerText: 'Выбор города',
        sections: [{ items: [mskDetailFields, spbDetailFields, nizDetailFields, kznDetailFields], id: 'city-select' }],
    };


    const pushCitySelectDialog = () => {
        pushSelectMobileOverlay(
            'city_select',
            selectData,
            { onClick: () => closeLastOverlays() },
        );
    };
    return { pushCitySelectDialog };
};
