import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import UILoading from '../Loading';

import './buttonV2.scss';

const noop = () => { };

const UIButtonV2 = React.memo((props) => {
    const {
        elType = 'button',
        colorType = 'primary',
        sizeType = 'large',
        applyNYStyles = false,
        disabledColor = null,
        loading = false,

        // МОДИФИКАТОРЫ
        disabled,
        // HADLER
        onClick,
        // NODE
        children,
        // ATR
        centerContent,
        ...attributes
    } = props;

    const ButtonElement = `${elType}`;

    /** STYLES  */
    const uiButtonClasses = cn({
        'ui-button-v2': true,
        [sizeType]: true,
        [colorType]: true,
        [disabledColor]: Boolean(disabledColor),
    });

    return (
        <ButtonElement
            styleName={uiButtonClasses}
            onClick={onClick}
            disabled={disabled}
            {...attributes}
            id="ui-button-v2"
        >
            {children}
        </ButtonElement>
    );
});

UIButtonV2.propTypes = {
    colorType: PropTypes.string,
    elType: PropTypes.string,
    sizeType: PropTypes.string,
    children: PropTypes.shape({}).isRequired,
    disabled: PropTypes.bool.isRequired,
    disabledColor: PropTypes.oneOf([null, 'primary-melon', 'primary-grey-salt']),
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    applyNYStyles: PropTypes.bool,
};

const UIButtonContent = React.memo((props) => {
    const {
        colorType = 'primary',
        sizeType = 'large',
        disabledColor = null,

        // МОДИФИКАТОРЫ
        disabled,
        // NODE
        children,
        id,
    } = props;

    const uiButtonContentClasses = cn({
        'ui-button-content-v2': true,
        disabled,
        [disabledColor]: Boolean(disabledColor),
        [sizeType]: true,
        [colorType]: true,
    });

    return (
        <div
            styleName="ui-button-content-conrainer"
            id={id}
        >
            <div
                styleName={uiButtonContentClasses}
            >
                {children}
            </div>
        </div>
    );
});

UIButtonContent.propTypes = {
    colorType: PropTypes.string,
    sizeType: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.oneOf([null]),
    ]).isRequired,
    disabled: PropTypes.bool.isRequired,
    disabledColor: PropTypes.oneOf([null, 'primary-melon', 'primary-grey-salt']),
};

const MainUiButtonV2 = (props) => {
    const {
        disabled = false,
        loading = false,
        onClick = noop,
        centerContent = null,
        applyNYStyles = false,
        className = null,
        disabledColor = null,
        href = null,
        children = null,
    } = props;

    const childeNode = useMemo(
        () => (centerContent || children), [centerContent, children],
    );

    const onClickHandler = useCallback((event) => {
        if (disabled || loading) {
            noop();
        } else {
            onClick(event);
        }
    }, [disabled, loading, onClick]);

    return (
        <UIButtonV2
            {...props}
            href={href}
            disabled={disabled}
            className={className}
            applyNYStyles={applyNYStyles}
            disabledColor={disabledColor}
            onClick={onClickHandler}
        >
            <UIButtonContent
                {...props}
                disabledColor={disabledColor}
                disabled={disabled}
            >
                {loading ? <UILoading disabled={disabled} /> : childeNode}
            </UIButtonContent>
        </UIButtonV2>
    );
};

MainUiButtonV2.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    disabledColor: PropTypes.oneOf([null, 'primary-melon', 'primary-grey-salt']),
    applyNYStyles: PropTypes.bool,
    href: PropTypes.string,
    onClick: PropTypes.func,
    loading: PropTypes.bool,

    centerContent: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.oneOf([null]),
    ]),
};

export default React.memo(MainUiButtonV2);
