/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';

import XBigIcon from 'assets/svg/x_big.component.svg';
import { Overlay } from 'app/components/BlurredOverlay';
import { CustomizationCommentForm } from '../CustomizationCommentForm';

import './customization-comment-sidebar.scss';


const CustomizationCommentSidebar = (props) => {
    const {
        isNeedBlur = true,
        isOpen,
        onClose,
        locale,
    } = props;

    return (
        <Overlay
            overlayOpen={isOpen}
            onClick={onClose}
            animateEnter
            animateExit
            isNeedBlur={isNeedBlur}
            render={() => (
                <div styleName="customization-comment__sidebar">
                    <button
                        styleName="sidebar__close"
                        type="button"
                        onClick={onClose}
                        aria-label="Закрыть комментарий"
                    >
                        <XBigIcon />
                    </button>

                    <CustomizationCommentForm {...props} locale={locale} />
                </div>
            )}
        />
    );
};


CustomizationCommentSidebar.propTypes = {
    isNeedBlur: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
};

export default CustomizationCommentSidebar;
