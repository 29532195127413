import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './cards.ui.scss';

const UICard = (props) => {
    // REACT NODE
    const { children } = props;
    // DATA
    const { cardSize = 'xs' } = props;

    const rootWrapperClasses = classNames({
        'ui-card-small': cardSize === 'xs',
        'ui-card': true,
    });

    return (
        <div styleName={rootWrapperClasses}>
            {children}
        </div>
    );
};

UICard.propTypes = {
    children: PropTypes.node.isRequired,
    cardSize: PropTypes.string,
};

export default UICard;
