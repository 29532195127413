import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { OverlayContextDispatch } from 'app/containers/contexts/overlay.context';


const dialogTexts = {
    strongText: 'Изменен адрес доставки',
    regularText: 'Применить ко всей подписке?',
    confirmAllText: 'Да',
    confirmOneText: 'Нет, не надо',
};

const extraText = 'Мы не применяем это изменение к другим вашим подпискам';


/**
 * Условия отображения диалога заданы в Main.
 * Компонент ничего не рендерит, только пушит оверлей диалога по условию.
 */
const AddressConfirmDialogContainer = React.memo((props) => {
    const {
        isDialogRequired = false,
        hasExtraText = false,
        onConfirmOne = () => {},
        onConfirmAll = () => {},
        onReject,
    } = props;

    const { pushDialogOverlay, closeLastOverlays } = useContext(OverlayContextDispatch);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dialogData = {
        ...dialogTexts,
        extraText: hasExtraText ? extraText : '',
        oneRowButtons: true,
        onConfirmOne,
        onConfirmAll,
        onReject,
    };

    useEffect(() => {
        if (isDialogRequired) {
            pushDialogOverlay(
                'address_confirm_dialog',
                dialogData,
                { onClick: onReject },
            );
        }
    }, [isDialogRequired, dialogData, pushDialogOverlay, onReject]);

    useEffect(() => {
        if (!isDialogRequired) {
            closeLastOverlays();
        }
    }, [isDialogRequired, closeLastOverlays]);

    return null;
});


export default AddressConfirmDialogContainer;


AddressConfirmDialogContainer.propTypes = {
    isDialogRequired: PropTypes.bool,
    hasExtraText: PropTypes.bool,
    onConfirmOne: PropTypes.func,
    onConfirmAll: PropTypes.func,
    onReject: PropTypes.func.isRequired,
};

