import React from 'react';
import cn from 'classnames';

import { IUIKitInputContainer } from './UiKItUiInput.types';
import css from './newUiinput.module.scss';


export function UiKitInputContainer(props: IUIKitInputContainer) {
    const {
        children,
        componentSize,
        inputUiState,
        htmlFor,
    } = props;

    const uiKitInputContainerClasses = cn({
        [css.uiKitInputContainer]: true,
        [inputUiState]: true,
        [componentSize]: true,
    });

    return (
        <label
            className={uiKitInputContainerClasses}
            htmlFor={htmlFor}
        >
            {children}
        </label>
    );
}
