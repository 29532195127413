import { gql, useFragment, useReactiveVar } from '@apollo/client';
import { allDishesListVar } from 'app/apollo/reaction';
import { useMemo } from 'react';

// eslint-disable-next-line no-underscore-dangle
const __getIngredientsList = (parentId: number, allDishesList: any[]) => {
    const dishesWithSameParentId = allDishesList.filter(
        (item) => item.parentId === parentId && item.secondDishPart,
    );
    const clearDishesList = dishesWithSameParentId.map(
        (item) => ({
            id: item.id,
            replaceableIngredient: item.replaceableIngredient,
            secondDishPart: item.secondDishPart,
        }),
    );
    return clearDishesList;
};

/**
 * @description хук опеределяет есть ли у dish'a кастомы по secondDishPart,
 * parentId, replaceableIngredient,
 * @return ingredientsList, isDishCardWithCustomSelect
 */
export function useDishCustoms(dishId: string = '') {
    const allDishesList = useReactiveVar(allDishesListVar);

    const { data: { parentId, secondDishPart } } = useFragment({
        fragment: gql`
            fragment DISH_CUSTOMS_PART_FRAGMENT on menuDish {
                parentId
                secondDishPart
            }
        `,
        from: {
            __ref: `menuDish:${dishId}`,
        },
    }) || { data: {} };

    const ingredientsList = useMemo(
        // @ts-ignore
        () => __getIngredientsList(parentId, allDishesList),
        [parentId, allDishesList],
    );

    const isDishCardWithCustomSelect = useMemo(
        () => Boolean(secondDishPart) && ingredientsList.length > 1,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [secondDishPart, ingredientsList],
    );
    return { ingredientsList, isDishCardWithCustomSelect };
}
