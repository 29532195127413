import React, { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';

import {
    isSelectedDefaultFiltersVar,
    isUserChangesTagsVar,
    prevSelectedPeriodsVar,
    selectedCategoriesVar,
    selectedCategoryIdVar,
    selectedPeriodVar,
    selectedTagsVar,
} from 'app/apollo/reaction';


export function useSelectedFilters() {
    const selectedPeriod = useReactiveVar(selectedPeriodVar);
    const prevSelectedPeriods = useReactiveVar(prevSelectedPeriodsVar);
    const selectedCategories = useReactiveVar(selectedCategoriesVar);
    const selectedCategoryId = useReactiveVar(selectedCategoryIdVar);
    const selectedTags = useReactiveVar(selectedTagsVar);
    const isUserChangesTags = useReactiveVar(isUserChangesTagsVar);

    const isSelectedDefaultFilters = useReactiveVar(isSelectedDefaultFiltersVar);

    const selectedFilters = useMemo(() => {
        const filters = {
            selectedPeriod,
            prevSelectedPeriods,
            selectedCategories,
            selectedCategoryId,
            selectedTags,
            isUserChangesTags,
            isSelectedDefaultFilters,
        };
        return filters;
    }, [
        selectedPeriod,
        prevSelectedPeriods,
        selectedCategories,
        selectedCategoryId,
        selectedTags,
        isUserChangesTags,
        isSelectedDefaultFilters,
    ]);

    return selectedFilters;
}


function SelectedFiltersAdapter(props) {
    const { children } = props;
    const selectedFilters = useSelectedFilters();

    return children({
        selectedFilters,
    });
}


export function withSelectedFilters(Child) {
    return (props) => (
        <SelectedFiltersAdapter {...props}>
            {({ selectedFilters }) => (
                <Child {...props} selectedFilters={selectedFilters} />
            )}
        </SelectedFiltersAdapter>
    );
}
