import React, { FC } from 'react';
import classNames from 'classnames';
import css from './MobileDishGiftIngredient.module.scss';

import GiftIcon from './img/gift.component.svg';

interface IProps {
    text: string,
    oldPrice: number
}

export const MobileDishGiftIngredient: FC<IProps> = ({ text, oldPrice }: IProps) => {
    const oldPriceClasses = classNames({
        [css.giftIngredientTitle]: true,
        [css.small]: true,
        [css.crossed]: true,
        [css.light]: true,
    });
    const currentPriceClasses = classNames({
        [css.giftIngredientTitle]: true,
        [css.compressed]: true,
    });
    return (
        <div className={css.giftIngredientRoot}>
            <div className={css.giftIngredientLeftSide}>
                <div className={css.giftIcon}>
                    <GiftIcon />
                </div>
                <div className={css.giftIngredientTitle}>{text}</div>
            </div>
            <div className={css.giftIngredientPricesContainer}>
                <div className={currentPriceClasses}>0 ₽</div>
                <div className={oldPriceClasses}>{`${oldPrice}₽`}</div>
            </div>
        </div>
    );
};
