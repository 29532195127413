export const getTypeErrorMessage = (__typename) => {
    const errorMsg = `

    type field not passed for typename: ${__typename}.
    Add it to graphql query.
    ──────────────────────────────
    не передано поле type для typename ${__typename}.
    Добавьте его в graphql запрос.
    ──────────────────────────────
    `;

    return errorMsg;
};

export const getPeriodsErrorMessage = (__typename) => {
    const errorMsg = `

    поля start и end имеют разное знание: ${__typename}.
    Add it to graphql query.
    ──────────────────────────────

    start and end fields have different values ${__typename}.
    Добавьте его в graphql запрос.
    ──────────────────────────────
    `;

    return errorMsg;
};
