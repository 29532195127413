import { gql } from '@apollo/client';

export const UPDATE_DELIVERY_DATE_TIME = gql`
    mutation updateDeliveryDateTime(
            $deliveryId: ID!,
            $date: String!,
            $time: timeInterval!,
            $massUpdate: Boolean!,
        ) {
            updateDeliveryDateTime(
            deliveryId: $deliveryId,
            date: $date,
            time: $time,
            massUpdate: $massUpdate,
        ) {
            success
            isShiftingEnabled
            isTimeEditEnabled
        }
    }
`;

export const UPDATE_DELIVERY_ADDRESS = gql`
    mutation updateDeliveryAddress(
            $deliveryId: ID!,
            $address: AddressInput!,
            $phone: String,
            $name: String,
            $massUpdate: Boolean!,
        ) {
            updateDeliveryAddress(
            deliveryId: $deliveryId,
            address: $address,
            phone: $phone,
            name: $name,
            mass_update: $massUpdate,
        ) {
            success
            isAddressEditEnabled
        }
    }
`;

export const UPDATE_DELIVERY_CONTACTS_AND_DATE_TIME = gql`
    mutation updateDeliveryContactsAndDateTime(
        $deliveryId: ID!,
        $massUpdate: Boolean!,

        $date: String!,
        $time: timeInterval!,

        $address: AddressInput!,
        $phone: String,
        $name: String,
    ) {
        updateDeliveryDateTime(
            deliveryId: $deliveryId,
            date: $date,
            time: $time,
            massUpdate: $massUpdate,
        ) {
            success
            isShiftingEnabled
            isTimeEditEnabled
        }

        updateDeliveryAddress(
            deliveryId: $deliveryId,
            address: $address,
            phone: $phone,
            name: $name,
            mass_update: $massUpdate,
        ) {
            success
            isAddressEditEnabled
        }
    }
`;

export const UPDATE_DELIVERY_DISHES = gql`
    mutation updateDeliveryDishes(
            $deliveryId: ID!,
        ) {
            updateDeliveryDishes(
            deliveryId: $deliveryId,
        ) {
            success
            isDishesEditEnabled
        }
    }
`;

export const USE_BONUS_FOR_DELIVERY = gql`
    mutation useBonusForDelivery(
        $deliveryId: ID!
    ) {
        useBonusForDelivery(
            deliveryId: $deliveryId
        ) {
            success
        }
    }
`;
