function factorial(n) {
    if (n <= 1) return 1;
    return n * factorial(n - 1);
}

export function piecewiseBezier(t, points) {
    const n = points.length - 1;
    let sum = 0;
    for (let i = 0; i <= n; i += 1) {
        const binomial = factorial(n) / (factorial(i) * factorial(n - i));
        sum += binomial * (1 - t) ** (n - i) * t ** i * points[i];
    }
    return sum;
}
