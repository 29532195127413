import React, {
    useState,
    // useContext,
} from 'react';
import PropTypes from 'prop-types';

import { formatPeriodMobile, getDateData } from 'app/utils/date';
import { MobilePeriodSelectContainer } from 'app/components/Select';

import ArrowIcon2 from './filter_week.svg';

import FilterItem from '../FilterItem';

import './mobile-period.scss';

const locales = {
    ru: {
        periodTitle: 'Дата доставки',
        desription: <>Каждую неделю у&nbsp;нас новое меню&nbsp;&mdash; выберите неделю, чтобы видеть блюда на&nbsp;нужную дату доставки</>,
    },
    en: {
        periodTitle: 'Delivery date',
        desription: <>New menu every week! Please choose a delivery week to see all dishes available on your delivery date</>,
    },
};

/**
 * TODO-875
 * Ипсравить формат даты. Сейчас дата приходить как фильтрах в меню "авг, сен, итд"
 * Завалидировать пропсы
 */

const PeriodTitle = (props) => {
    const {
        period,
        menuDatesFilterPeriod,
        selectedDate,
        locale,
    } = props;

    const { start, end } = formatPeriodMobile(period);

    if (period.title) {
        return (
            <div styleName="select-period">
                <div styleName="select-month">{period.title[locale]}</div>
            </div>
        );
    }

    if (menuDatesFilterPeriod === period.start) {
        const dateData = getDateData(selectedDate);
        const { date, monthShortNameForDate } = dateData;

        return (
            <div styleName="select-period">
                <div styleName="select-day">{date}</div>
                <div styleName="select-month">{monthShortNameForDate}</div>
            </div>
        );
    }

    return (
        <div styleName="select-period">
            <div styleName="select-day">{start.day}</div>
            <div styleName="select-month">{start.month}</div>
            <div styleName="select-separator">-</div>
            <div styleName="select-day">{end.day}</div>
            <div styleName="select-month">{end.month}</div>
        </div>
    );
};


const MobilePeriod = (props) => {
    const {
        selectedDate,
        menuDatesFilterPeriod,
        period,
        onSelectPeriod,
        onClickOpenSelect,
        locale,
    } = props;

    const [isSelectOpened, setSelectOpened] = useState(false);

    const l = locales[locale];
    const title = l.periodTitle;

    const handleOpenPeriodSelect = () => {
        onClickOpenSelect();
        setSelectOpened(true);
    };
    return (
        <FilterItem
            title={title}
            text={l.desription}
            className="period-container"
        >
            <div styleName="select">
                <button
                    type="button"
                    styleName="select-button"
                    onClick={handleOpenPeriodSelect}
                >
                    <div styleName="select-value">
                        <PeriodTitle
                            selectedDate={selectedDate}
                            menuDatesFilterPeriod={menuDatesFilterPeriod}
                            period={period}
                            locale={locale}
                        />
                    </div>
                    <div styleName="select-toggle">
                        <div styleName="select-toggle-icon"><ArrowIcon2 /></div>
                    </div>
                </button>

                <MobilePeriodSelectContainer
                    isSelectOpened={isSelectOpened}
                    onClickPeriod={onSelectPeriod}
                    onClickClose={() => setSelectOpened(false)}
                />
            </div>
        </FilterItem>
    );
};

export default MobilePeriod;

MobilePeriod.propTypes = {
    locale: PropTypes.string.isRequired,
    selectedDate: PropTypes.string.isRequired,
    menuDatesFilterPeriod: PropTypes.string.isRequired,
    period: PropTypes.shape({
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
    }).isRequired,
    onSelectPeriod: PropTypes.func.isRequired,
    onClickOpenSelect: PropTypes.func.isRequired,
};
