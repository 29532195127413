import React, { useContext, useMemo, useState } from 'react';
import { Portal } from 'react-portal';
import { analyticService } from 'global/services';
// TODO!!!! Перенесести его в отдельный компонент
import { NextOverlay } from 'app/views/Menu/Menu/components/CustomIngredientsSelectDishRow/Mobile/overlay/nextOverlay';
import { LocationContext } from 'app/containers/LocationProvider';
import { useFragment } from '@apollo/client';
import { ADDRESS_SUBDIVISION_FRAGMENT } from 'app/graphql/address/subdivision.fragment.graphql';
import { MobileHeaderV2 } from './components';
import { MobileCategoriesSlider } from './components/MobileCategoriesSlider';
import MobileDeliveryDateSelectPopup from './components/MobileDeliveryDateSelectPopup/mobileDeliveryDateSelectPopup';


export function BR1125MobileHeader(props: any) {
    const {
        onClickMobileNavigationButton,
        onClickFilters,
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const {
        getCityTitile,
        mutateAddreessHandler,
        // isLocationDataLoading,
        // locationKey,
    } = useContext(LocationContext);

    const { data: sessionAddressData } = useFragment({
        fragment: ADDRESS_SUBDIVISION_FRAGMENT,
        from: {
            __ref: 'address:session_address',
        },
    });

    const { title } = useMemo<any>(
        // @ts-ignore
        () => getCityTitile({ deliveryAddress: { address: { ...sessionAddressData } } }),
        [sessionAddressData, getCityTitile],
    );

    const handleOpenPopup = () => {
        setIsOpen(true);
        analyticService
            .push({ eventName: 'Filter_Open_City' })
            .push({ eventName: 'Filter_Open_Week' });
    };

    return (
        <>
            <MobileHeaderV2
                onClickMobileNavigationButton={onClickMobileNavigationButton}
                handleOpenPopup={handleOpenPopup}
                title={title}
                onClickFilters={onClickFilters}
            />
            <MobileCategoriesSlider />
            {/* POPUP */}
            <Portal>
                <NextOverlay
                    isOpen={isOpen}
                    handleClose={() => { setIsOpen(false); }}
                >
                    <MobileDeliveryDateSelectPopup
                        setIsOpen={setIsOpen}
                        mutateAddreessHandler={mutateAddreessHandler}
                    />
                </NextOverlay>
            </Portal>
        </>
    );
}
