import { RetryLink } from '@apollo/client/link/retry';

const retryLinkOptions = {
    delay: {
        initial: 700,
        max: Infinity,
        jitter: true,
    },

    attempts: {
        max: 4,
        retryIf: (error) => !!error,
    },
};

/**
 * @description Повторяет запрос если сервер отвечает с 5xx статус кодом
 * @doc https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
 */
export const retryLink = new RetryLink(retryLinkOptions);
