/* eslint-disable no-underscore-dangle */

export class FlocktoryAnalyticsClient {
    constructor() {
        this.service = {
            push: (...args) => {
                const [event, params] = args;
                if (event === 'Flocktory_Login') {
                    const { name, email } = params;
                    const markup = name
                        ? `<div class="i-flocktory" data-fl-user-email="${email}" data-fl-user-name="${name}"></div>`
                        : `<div class="i-flocktory" data-fl-user-email="${email}"></div>`;
                    document.body.insertAdjacentHTML('beforeend', markup);
                }

                if (event === 'postcheckout') {
                    const { name, email } = params;
                    // const flocktoryNode = document.querySelector(".i-flocktory");
                    // // .getElementsByClassName("i-flocktory");
                    // console.log(flocktoryNode);
                    // flocktoryNode.setAttribute(
                    //     data-fl-action="exchange"
                    // );

                    const markup = name
                    ? `<div class="i-flocktory" data-fl-user-email="${email}" data-fl-user-name="${name}"   data-fl-action="exchange"></div>`
                    : `<div class="i-flocktory" data-fl-user-email="${email}" data-fl-action="exchange"></div>`;
                    document.body.insertAdjacentHTML('beforeend', markup);
                    // console.log(params);
                    // const markup = name
                    //     ? `<div class="i-flocktory" data-fl-user-email="${email}" data-fl-user-name="${name}"></div>`
                    //     : `<div class="i-flocktory" data-fl-user-email="${email}"></div>`;
                    // document.body.insertAdjacentHTML('beforeend', markup);

                }

                window.flocktory.push([event, params]);
            },
        };
        this.clientName = 'FlocktoryAnalytics';
        this._init();
    }

    // eslint-disable-next-line class-methods-use-this
    _init() {
        window.flocktory = window.flocktory || [];
    }

    emit(data) {
        const { event, ...params } = data;
        // console.warn(`emit FlocktoryAnalytics event: ${event}`, params);
        this.service.push(
            event,
            params,
        );
    }
}

