import { getTypeErrorMessage } from './idResolvers.utils';

export const prepareViewCustomizationCommentTypeId = (responseObject) => {
    const { type, id, __typename } = responseObject;

    if (!type) {
        throw new Error(getTypeErrorMessage(__typename));
    }

    if (id) {
        return `viewCustomizationCommentType:${id}`;
    }

    return `viewCustomizationCommentType:${type}`;
};
