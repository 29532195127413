/* eslint-disable react-hooks/exhaustive-deps */

import React, {
    useMemo,
    useState,
    useEffect,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { analyticService } from 'global/services';
import { isSubscription } from 'app/const/subscription';

import { UIParagraph, UIToggleSwitch } from 'app/components/ui';
import { BasketCheckPrice } from './BasketCheckPrice';

import { locales } from './basket-check.locales';

import './basket-check-rows.scss';


const noop = () => { };


const hidingSwitchRenderContexts = [
    'stepByStepCheckout',
    'delivery-basket',
];


export const Subscription = ({
    renderContext = '',
    locale,
    // DATA
    basket: {
        totals: {
            discount_amount: discountAmount,
        },
    },
    subscriptionType,
    // ACTIONS
    onToggleSubscription = noop,
    onClickSubscriptionInfoOpen,
}) => {
    const subscriptionDiscountAmount = useMemo(
        () => (isSubscription(subscriptionType) ? discountAmount : 0), [subscriptionType, discountAmount],
    );


    const [lockToggle, setLockToggle] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(isSubscription(subscriptionType));
    }, []);

    const toggleSubscriptionType = useCallback((event) => {
        if (!lockToggle) {
            setLockToggle(true);
            event.stopPropagation();
            setIsChecked(event.target.checked);
            onToggleSubscription();
            setTimeout(() => { setLockToggle(false); }, 800);
        }
    }, [lockToggle]);

    const l = locales[locale];

    const openSubsriptionInfoHadler = useCallback(() => {
        analyticService.push({ eventName: 'Check_Info_About_Subscription' });
        onClickSubscriptionInfoOpen();
    }, []);

    return (
        <div styleName="basket-check__row">
            <div
                styleName="basket-check-sub-wrapper"
                key="check-row-1"
            >
                {!hidingSwitchRenderContexts.includes(renderContext) && (
                    <div styleName="basket-check-sub__toggle">
                        <UIToggleSwitch
                            onChanegHandler={toggleSubscriptionType}
                            isChecked={isChecked}
                        />
                    </div>
                )}
                <button
                    onClick={openSubsriptionInfoHadler}
                    type="button"
                >
                    <UIParagraph level="4.2" styleName="basket-check__row-text">
                        <span styleName="text-0">
                            <span styleName="link">
                                {l.subscriptionSmall}
                            </span>
                        </span>
                        <span styleName="text-375">
                            {l.subscriptionBigPref}
                            &nbsp;
                            <span styleName="link">
                                {l.subscriptionBigLink}
                            </span>
                        </span>
                    </UIParagraph>
                </button>
            </div>
            <div
                key="check-row-2"
            >
                <BasketCheckPrice price={subscriptionDiscountAmount} sign="-" />
            </div>
        </div>
    );
};

Subscription.propTypes = {
    renderContext: PropTypes.string,
    basket: PropTypes.shape({
        totals: PropTypes.shape({
            total_price: PropTypes.number.isRequired,
            delivery_price: PropTypes.number.isRequired,
            referral_amount: PropTypes.number.isRequired,
            total_common_price: PropTypes.number.isRequired,
            discount_amount: PropTypes.number.isRequired,
        }).isRequired,
    }).isRequired,
    locale: PropTypes.string.isRequired,
    subscriptionType: PropTypes.string.isRequired,
    onToggleSubscription: PropTypes.func,
    onClickSubscriptionInfoOpen: PropTypes.func.isRequired,
};
