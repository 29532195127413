const ru = {
    date: 'Дата доставки',
    time: 'Время доставки',

    timeChangedText: 'Обратите внимание! Изменилось время доставки.',
    disabled: 'Чтобы мы смогли предложить вам корректные дату и время доставки, введите адрес в поле выше',
    deliveryZone: 'В выходные доступны другие временные интервалы.',
    delimiter: 'С этой даты - другое меню!',
    deliveryTimeOneInterval: 'Доступен только один интервал доставки',
    deliveryTimeSelect: 'Выберите доступный интервал доставки',
    deliveryTimeChangedOutside: 'Обратите внимание: время доставки за МКАД изменилось',
};

const en = {
    date: 'Delivery date',
    time: 'Delivery time',

    timeChangedText: 'Delivery time has changed!',
    disabled: 'Please insert address in the field above to choose available delivery date',
    deliveryZone: 'Other time slots are availble for the weekend',
    delimiter: 'New menu starting this delivery date',
    deliveryTimeOneInterval: 'Only one delivery time slot available',
    deliveryTimeSelect: 'Please pick available delivery time slot',
    deliveryTimeChangedOutside: 'Delivery time slots for outside MKAD deliveries have changed',
};

export const locales = {
    ru,
    en,
};
