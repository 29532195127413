/* eslint-disable arrow-body-style */
import isEmpty from 'lodash/isEmpty';

import { errorService } from 'global/services';
import { addQueryToUrl } from 'app/utils/url';


export const getAvailablePeriods = (periods) => periods.filter(({ isVisibleInFilter }) => isVisibleInFilter !== 'disabled');

const getFirstSpecificPeriodStart = ({ enabledDatesData, datesType }) => {
    const onlyEnabledDatesData = enabledDatesData.filter((e) => !isEmpty(e[datesType]));
    const [firstPeriod] = onlyEnabledDatesData;

    if (!firstPeriod) {
        return '';
    }

    return firstPeriod.startDate;
};

/**
 * @description Ищет первый доступный период для специальных дат доставки в enabledDatesData.
 * Специфичные даты доставки - это everydayAvailableDate, trialAvailableDate, ...
 */
export const getFirstSpecificPeriod = ({ availablePeriods, enabledDatesData, datesType }) => {
    const result = availablePeriods.find((period) => {
        const firstAvailableSpecificPeriodStart = getFirstSpecificPeriodStart({ enabledDatesData, datesType });
        return period.start === firstAvailableSpecificPeriodStart;
    });
    return result;
};

/**
 * @description Добавляет первый доступный период в урл для ссылок из специальных дат доставки.
 * Специфичные даты доставки - это everydayAvailableDate, trialAvailableDate, ...
 * @param {Object} data
 * @param {Object} data.link - specificLink. Cпецифичная ссылка для отображения в конкретном месте.
 */
export const createLinkWithPeriod = ({
    link,
    allAvailableLinks,
    enabledDatesData,
    availablePeriods,
}) => {
    const { id, data } = link;

    const ddd = allAvailableLinks[id];

    if (!ddd) return link;

    const { datesType } = allAvailableLinks[id];

    if (!datesType) return link;

    const firstAvailableSpecificPeriod = getFirstSpecificPeriod({ availablePeriods, enabledDatesData, datesType });

    if (!firstAvailableSpecificPeriod) return link;

    const newHref = addQueryToUrl(data.href, { period: firstAvailableSpecificPeriod.start });

    return { ...link, data: { ...link.data, href: newHref } };
};

/**
 * @description Возвращает только те специфичные ссылки, которые доступны для текущего пользователя.
*/
export const getAvailableLinks = ({
    specificLinks,
    allAvailableLinks,
}) => {
    const allLinkIds = Object.keys(allAvailableLinks);

    const filteredLinks = specificLinks.filter((link) => {
        if (link.isStatic) return true;
        return allLinkIds.includes(link.id);
    });

    /*
        Проверка специальных условий отображения ссылок:
        - У specificLinks может быть свой check для проверки особых условий.
        Если он есть, он будет выполнен.
        - На данный момент все необходимые данные в этом check доступны из замыкания,
        но можно передавать их и через аргументы здесь.
        - Пример такой проверки: в getTopLevelLinks в linkOptions.js пункт НГ в бургер-меню
        может находиться на разных уровнях в зависимости от условий.
    */
    const specificCheckedLinks = filteredLinks.filter(({ check }) => (check ? check() : true));

    return specificCheckedLinks;
};

/**
 * @description Функция вычисляет все возможные для отображения специфичные ссылки для текущего пользователя.
 *
 * @param {Object} data
 * @param {Array} data.enabledDatesData - все специфичные даты доставки.
 * @param {Array} data.availablePeriods - периоды, открытые (не отключенные) для заказов.
 * @param {Object} data.allAvailableLinks - все возможные для пользователя ссылки;
 * @param {Array} data.specificLinks - все специфичные ссылки для отображения в конкретном месте;
 * (например, это все возможные ссылки для BestSetsMobileSlider, получаемые при вызове getStaticLinkOptions()).
 *
 * Attention! Для корректной работы, getAvailableLinksWithUrl требует обязательного наличия следующих полей для каждой specificLink:
 *      @param {Object} specificLink
 *      @param {string} specificLink.id - id должен совпарадать с одним из id из объекта LINKS ('app/const/links');
 *      @param {Object} specificLink.data.href - урл. Именно href, без дополнительных уточнений (не hrefEn, hrefRu, ...);
 */
export const getAvailableLinksWithUrl = ({
    specificLinks,
    allAvailableLinks,
    availablePeriods,
    enabledDatesData = [],
}) => {
    try {
        if (isEmpty(allAvailableLinks)) return [];

        const availableLinks = getAvailableLinks({ specificLinks, allAvailableLinks });

        const linksWithUrls = availableLinks.map((link) => createLinkWithPeriod({
            link,
            allAvailableLinks,
            enabledDatesData,
            availablePeriods,
        }));

        return linksWithUrls;
    } catch (e) {
        errorService.log({
            source: 'client',
            text: 'getAvailableLinksWithUrl error',
            scope: 'getAvailableLinksWithUrl',
            err: e,
        });
        return [];
    }
};

/**
 * @description Предикат. Проверяет доступность ссылки для пользователя.
 *
 * @param {Object} data
 * @param {string} data.id - id ссылки.
 * @param {Object} data.user - объект user.
 * @param {Array} data.subdivisions - массив возможных городских локаций для ссылки.
 * @param {string} data.currentSubdivision - текущая городская локация пользователя.
 * @param {string} data.datesType - тип специфичной даты доставки (everydayAvailableDate, trialAvailableDate, ...).
 * @param {Array} data.enabledDatesData - все специфичные даты доставки.
 * @param {Array} data.availablePeriods - периоды, открытые (не отключенные) для заказов.
 * @param {Array} data.availablePeriods - периоды, открытые (не отключенные) для заказов.
 * @param {function} data.check - функция-предикат: принимает произвольное количество параметров в виде объекта для проведения проверок.
*/
export const checkLinkAvailability = (data) => {
    try {
        const {
            check,
            subdivisions,
            currentSubdivision,
            datesType,
            availablePeriods,
            enabledDatesData,
        } = data;

        if (subdivisions && currentSubdivision && !subdivisions.includes(currentSubdivision)) return false;

        if (check && !check(data)) return false;

        const firstSpecificPeriod = datesType && getFirstSpecificPeriod({
            availablePeriods, enabledDatesData, datesType,
        });

        if (datesType && !firstSpecificPeriod) return false;

        return true;
    } catch (e) {
        errorService.log({
            source: 'client',
            text: 'checkLinkAvailability error',
            scope: 'checkLinkAvailability',
            err: e,
        });
        return false;
    }
};
