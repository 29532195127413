export const CATEGORY_MOST_INTRESTING = 'intresting';
export const CATEGORY_MOST_INTRESTING_TAG_ID = '2148';

export const CATEGORY_MAIN = '3';
export const CATEGORY_SOUPS = '2';
export const CATEGORY_SALADS = '1';
export const CATEGORY_BREAKFASTS = '6';
export const CATEGORY_DESSERT = '5';
export const CATEGORY_UPSELL = '7';
export const CATEGORY_SNACKS = '8'; // Запкуски
export const CATEGORY_VITAMINS = '88';
export const CATEGORY_FARM_PRODUCTS = '9';


export const DEFAULT_CATEGORIES = [
    /* custom categories */
    CATEGORY_MOST_INTRESTING,
    /* regular categories */
    CATEGORY_MAIN,
    CATEGORY_SOUPS,
    CATEGORY_SALADS,
    CATEGORY_SNACKS,
    CATEGORY_BREAKFASTS,
    CATEGORY_DESSERT,
    CATEGORY_UPSELL,
    CATEGORY_FARM_PRODUCTS,
    CATEGORY_VITAMINS,
];

export const CATEGORIES_URL_VALUES = {
    [CATEGORY_SALADS]: 'salads', // салаты
    [CATEGORY_SOUPS]: 'soups', // супы
    [CATEGORY_MAIN]: 'main_dishes', // основные блюда
    [CATEGORY_DESSERT]: 'snacks', // десерты
    [CATEGORY_SNACKS]: 'appetizer', // закуски
    [CATEGORY_BREAKFASTS]: 'breakfasts', // завтраки
    [CATEGORY_UPSELL]: 'fruit', // приятные мелочи
    [CATEGORY_VITAMINS]: 'health',
    [CATEGORY_MOST_INTRESTING]: 'intresting',
    [CATEGORY_FARM_PRODUCTS]: 'farm',
};

export const CATEGORIES_URL_VALUES_IN_CIRILIKE = {
    [CATEGORY_SALADS]: 'салаты', // 'salads'
    [CATEGORY_SOUPS]: 'супы', // 'soups,
    [CATEGORY_MAIN]: 'основные блюда', // 'main_dishes',
    [CATEGORY_DESSERT]: 'десерты', // 'snacks',
    [CATEGORY_SNACKS]: 'закуски', // 'appetizer'
    [CATEGORY_BREAKFASTS]: 'завтраки', // 'breakfasts',
    [CATEGORY_UPSELL]: 'приятные мелочи', // 'fruit',
    [CATEGORY_VITAMINS]: 'для здоровья',
    [CATEGORY_MOST_INTRESTING]: 'самое интересное',
    [CATEGORY_FARM_PRODUCTS]: 'фермерские продукты', // 'farm'
};

export const CATEGORIES_ICONS = {
    [CATEGORY_SALADS]: 'salads',
    [CATEGORY_SOUPS]: 'soups',
    [CATEGORY_MAIN]: 'main',
    [CATEGORY_DESSERT]: 'desserts',
    [CATEGORY_SNACKS]: 'snaks',
    [CATEGORY_BREAKFASTS]: 'breakfast',
    [CATEGORY_UPSELL]: 'upsell',
    [CATEGORY_FARM_PRODUCTS]: 'farm_products',
};

/*
    Параметры используются для подбора меню по схеме и для управления раскрытыми категориями в корзине
*/
export const CATEGORIES_SCHEMA_IDS = {
    cl: 1,
    cs: 2,
    cm: 3,
    cn: 5,
    cb: 6,
    cf: 7,
    ca: 8,
};

export const CATEGORY_URL_PARAM_IDS = {
    salads: CATEGORY_SALADS,
    soups: CATEGORY_SOUPS,
    main_dishes: CATEGORY_MAIN,
    snacks: CATEGORY_DESSERT,
    breakfasts: CATEGORY_BREAKFASTS,
    fruit: CATEGORY_UPSELL,
    health: CATEGORY_VITAMINS,
    intresting: CATEGORY_MOST_INTRESTING,
    appetizer: CATEGORY_SNACKS,
    farm: CATEGORY_FARM_PRODUCTS,
};

export const CATEGORIES_ORDER = [
    /* custom categories */
    CATEGORY_MOST_INTRESTING,

    /* regular categories */
    CATEGORY_MAIN,
    CATEGORY_SOUPS,
    CATEGORY_SALADS,
    CATEGORY_SNACKS,
    CATEGORY_BREAKFASTS,
    CATEGORY_DESSERT,
    CATEGORY_UPSELL,
    CATEGORY_FARM_PRODUCTS,
    CATEGORY_VITAMINS,
];


export const CATEGORY_TITLES = {
    ru: {
        [CATEGORY_MAIN]: 'Основные блюда',
        [CATEGORY_SOUPS]: 'Супы',
        [CATEGORY_SALADS]: 'Салаты',
        [CATEGORY_SNACKS]: 'Закуски',
        [CATEGORY_BREAKFASTS]: 'Завтраки',
        [CATEGORY_DESSERT]: 'Десерты',
        [CATEGORY_UPSELL]: 'Приятные мелочи',
        [CATEGORY_VITAMINS]: 'Для здоровья',
        [CATEGORY_MOST_INTRESTING]: 'Самое интересное',
        [CATEGORY_FARM_PRODUCTS]: 'Фермерские продукты',
        all: 'В корзине',
    },
    en: {
        [CATEGORY_MAIN]: 'Dinners',
        [CATEGORY_SOUPS]: 'Soups',
        [CATEGORY_SALADS]: 'Salads',
        [CATEGORY_SNACKS]: 'Snacks',
        [CATEGORY_BREAKFASTS]: 'Breakfasts',
        [CATEGORY_DESSERT]: 'Desserts',
        [CATEGORY_UPSELL]: 'Pleasant trifles',
        [CATEGORY_VITAMINS]: 'For health',
        [CATEGORY_MOST_INTRESTING]: 'Our recommendation',
        [CATEGORY_FARM_PRODUCTS]: 'Farm products',
        all: 'Your order',
    },
};

export const CATEGORY_ANALYTICS_TITLES = {
    [CATEGORY_MAIN]: 'main_dishes',
    [CATEGORY_SOUPS]: 'soups',
    [CATEGORY_SALADS]: 'salads',
    [CATEGORY_SNACKS]: 'snacks',
    [CATEGORY_BREAKFASTS]: 'breakfasts',
    [CATEGORY_DESSERT]: 'desserts',
    [CATEGORY_UPSELL]: 'upsell',
    [CATEGORY_VITAMINS]: 'vitamins',
    [CATEGORY_MOST_INTRESTING]: 'most_interesting',
    [CATEGORY_FARM_PRODUCTS]: 'farm_products',
};


export const ADDITIONAL_DISHES_CATEGORIES = [CATEGORY_DESSERT, CATEGORY_UPSELL, CATEGORY_VITAMINS];

export const CATEGORIES_WITH_PORTION_ALIAS = [
    CATEGORY_MOST_INTRESTING,
    CATEGORY_MAIN,
    CATEGORY_SALADS,
    CATEGORY_VITAMINS,
    CATEGORY_DESSERT,
    CATEGORY_UPSELL,
    CATEGORY_FARM_PRODUCTS,
];

export const CATEGORIES_WITH_CUSTOMIZATION = [
    CATEGORY_MAIN, CATEGORY_SOUPS,
];

// BR-1125
export const mostInterestingCategoryTitleForABTest = {
    ru: {
        intresting: 'Новое',
    },
    en: {
        intresting: 'Best',
    },
};
