import React, {
    useEffect, useRef, useState, useCallback,
} from 'react';
import Textarea from 'react-textarea-autosize';
import cn from 'classnames';

import { isDesktop } from 'app/utils/resolution';

import { useInputNavigation } from './carrierCommentRow.hooks';


import css from './carrier-comment-text-area.module.scss';


type TCarrierCommentTextAreaProps = {
    initialValue?: string,
    isCommentDataLoading?: boolean,
    disabled?: boolean,
    responsive?: boolean,
    symbolsAmountLimit?: number | null,
    minRows?: number | null,
    isStaticHeight: boolean
    onClick?: () => void,
    onBlur: (nextValue: string) => void, // eslint-disable-line no-unused-vars
};


export const CarrierCommentTextArea = ({
    initialValue = '',
    isCommentDataLoading = false,
    disabled = false,
    responsive = false,
    isStaticHeight,
    onClick = () => { },
    symbolsAmountLimit = null,
    minRows = null,
    onBlur,
}: TCarrierCommentTextAreaProps) => {
    const [value, setValue] = useState(initialValue);

    const rootTextAreaRef = useRef(null);
    const [inputRef, setRootNode] = useInputNavigation();
    const [isInputUnactive, setIsInputUnactive] = useState(true);

    /* UI */
    const [uiState, setUiState] = useState('default');
    const [transitionState, setTransitionState] = useState('trasitionDefault');
    const [animationState, setAnimationState] = useState<'animation-active' | 'animation-inactive'>('animation-inactive');
    const isInputDisabled = isCommentDataLoading || disabled;

    useEffect(() => {
        setValue(initialValue);

        if (initialValue) {
            setTransitionState('trasitionActive');
        }

        if (isDesktop() && isStaticHeight) {
            return;
        }

        if (initialValue) {
            setTransitionState('trasitionActive');
            // @ts-ignore
            setRootNode(rootTextAreaRef);
            // @ts-ignore
            inputRef.setHeight('init', initialValue);
        }
        // didMount
        // eslint-disable-next-line
    }, [initialValue]);

    const handleInputChange: React.ChangeEventHandler<HTMLTextAreaElement> = useCallback((e) => {
        const { currentTarget: { value: currentValue } } = e;
        const formattedValue = currentValue.replace(/\n/g, '');

        if (symbolsAmountLimit !== null) {
            if (formattedValue.length <= symbolsAmountLimit) {
                setValue(formattedValue);
            } else {
                setValue(formattedValue.slice(0, symbolsAmountLimit));
            }
            return;
        }
        setValue(currentValue);
    }, [symbolsAmountLimit]);

    const submitCommentData = useCallback(() => {
        if (value !== initialValue) {
            onBlur(value);
        }
    }, [onBlur, initialValue, value]);

    const handleInputBlur = useCallback(() => {
        if (!value) {
            setTransitionState('trasitionDefault');
        }
        setUiState('default');
        // @ts-ignore
        inputRef.setHeight('close');
        setIsInputUnactive(true);
        submitCommentData();
    }, [inputRef, submitCommentData, value]);

    const inputClickHandler = useCallback(() => {
        setAnimationState('animation-active');
        if (isInputUnactive) {
            setUiState('active');
            setIsInputUnactive(false);
            // @ts-ignore
            inputRef.setHeight('open');
            // @ts-ignore
            inputRef.focus();
            setTransitionState('trasitionActive');

            onClick();
        }
    }, [inputRef, isInputUnactive, onClick]);

    const handleInputClick = isInputDisabled ? undefined : inputClickHandler;
    const onLabelClick = isInputDisabled ? undefined : inputClickHandler;

    /* CLASSES */
    const carrierCommentRootStyles = cn({
        [css.carrierCommentRoot]: true,
        [css[uiState]]: true,
        [css[transitionState]]: true,
        [css.disabled]: isInputDisabled,
        [css.responsive]: responsive,
        // @ts-ignore
        [css.showShadow]: inputRef.collapsedState === 'collapsed',
    });

    const carrierCommentLabelStyles = cn({
        [css.carrierCommentLabel]: true,
        [css[uiState]]: true,
        [css[transitionState]]: true,
        [css.disabled]: isInputDisabled,
        [css.responsive]: responsive,
        [animationState]: animationState,
    });

    const carrierCommentTextStyles = cn({
        [css.carrierCommentText]: true,
        [css[uiState]]: true,
        [css[transitionState]]: true,
        // @ts-ignore
        [css.hideScrollbar]: !value.length || inputRef.maxRow <= 3,
        [css.disabled]: isInputDisabled,
        [css.responsive]: responsive,
    });

    // @ts-ignore
    const maxRows = isDesktop() && isStaticHeight ? 4 : inputRef.maxRow;

    return (
        <div
            ref={rootTextAreaRef}
            className={carrierCommentRootStyles}
        >
            <div
                className={carrierCommentLabelStyles}
                onClick={onLabelClick}
                role="button"
                tabIndex={0}
                onKeyUp={() => { }}
            >
                Комментарий курьеру
            </div>
            <Textarea
                className={carrierCommentTextStyles}
                onBlur={handleInputBlur}
                onHeightChange={() => { }}
                onChange={handleInputChange}
                onClick={handleInputClick}
                value={value}
                minRows={minRows !== null ? minRows : undefined}
                maxRows={maxRows}
                disabled={isInputDisabled}
            />
        </div>
    );
};
