import React, { useMemo } from 'react';
import { useFragment } from '@apollo/client';
import { UIHeading } from 'app/components/ui';
import { DELIVERY_ZONE_SUBDIVISION } from './graphql/DeliveryZoneSubdivisionFragment.graphql';


import css from './deliveryZoneTerms.module.scss';

const MSKTermsText = () => (
    <div className={css.deliveryZoneTermsDescription}>
        <div className={css.deliveryZoneTermsDescriptionRow}>
            По&nbsp;Москве и&nbsp;МО до&nbsp;60&nbsp;км от&nbsp;МКАД
        </div>
        <div className={css.deliveryZoneTermsDescriptionRow}>
            Курьер пришлёт СМС перед тем, как выезжать к&nbsp;вам
        </div>
        <div className={css.deliveryZoneTermsDescriptionRow}>
            У вас будет 15 минут на проверку заказа
        </div>
    </div>
);

const SPBTermsText = () => (
    <div className={css.deliveryZoneTermsDescription}>
        <div className={css.deliveryZoneTermsDescriptionRow}>
            По&nbsp;Санкт-Петербургу и&nbsp;ЛО
            до&nbsp;50&nbsp;км от&nbsp;КАД
        </div>
        <div className={css.deliveryZoneTermsDescriptionRow}>
            Курьер пришлёт СМС перед тем, как выезжать к&nbsp;вам
        </div>
        <div className={css.deliveryZoneTermsDescriptionRow}>
            У вас будет 15 минут на проверку заказа
        </div>
    </div>
);
const NIZTermsText = () => (
    <div className={css.deliveryZoneTermsDescription}>
        <div className={css.deliveryZoneTermsDescriptionRow}>
            Курьер пришлёт СМС перед тем, как выезжать к&nbsp;вам
        </div>
    </div>
);

const KZNTermsText = () => (
    <div className={css.deliveryZoneTermsDescription}>
        <div className={css.deliveryZoneTermsDescriptionRow}>
            Курьер пришлёт СМС перед тем, как выезжать к&nbsp;вам
        </div>
    </div>
);

function DeliveryZoneTermsContent() {
    // Тип сгенерировался не в camelCase
    // eslint-disable-next-line camelcase
    const { data } = useFragment<any>({
        fragment: DELIVERY_ZONE_SUBDIVISION,
        from: {
            __ref: 'address:session_address',
        },
    });

    if (!data) {
        return null;
    }


    const locationKeyC = useMemo(() => {
        // @ts-ignore
        if (['RU-NIZ', 'RU-NIO'].includes(data.country_subdivision)) {
            return 'NIZ';
        }

        // @ts-ignore
        if (['RU-SPE', 'RU-LEN'].includes(data.country_subdivision)) {
            return 'SPB';
        }

        // @ts-ignore
        if (['RU-TA', 'RU-TAO'].includes(data.country_subdivision)) {
            return 'KZN';
        }

        return 'MSK';
    }, [data.country_subdivision]);

    return (
        <div className={css.deliveryZoneTermsRoot}>
            <div className={css.deliveryZoneTermsTitle}>
                <UIHeading level="3.2">
                    Условия доставки
                </UIHeading>
            </div>
            {locationKeyC === 'MSK' && <MSKTermsText />}
            {locationKeyC === 'SPB' && <SPBTermsText />}
            {locationKeyC === 'NIZ' && <NIZTermsText />}
            {locationKeyC === 'KZN' && <KZNTermsText />}
        </div>
    );
}

function DeliveryZoneTermsDAC() {
    return <DeliveryZoneTermsContent />;
}

export function DeliveryZoneTerms() {
    return <DeliveryZoneTermsDAC />;
}
