import { LOCATION_SPB, LOCATION_MSK, LOCATION_NIZ } from 'app/const/location';
import { createUrl } from 'app/utils/url';
import { defaultSetLink } from 'app/const/links/const';


// ***** EVERYDAY (DINNERS_FOR_FAMILY | Набор от шефа | 5 ужинов на семью | Семейное меню | Доставка завтра) *****
// searchParams:
const BASKET_SCHEMA = [
    ['cm', '10'],
    ['menu', 'everyday'],
];

const COMMON_SEARCH_PARAMS = [
    ...BASKET_SCHEMA,
    ['payment', 'recurrent'],
];


const HREF = createUrl({
    path: 'basket',
    params: [
        ...COMMON_SEARCH_PARAMS,
    ],
});

export const EVERYDAY_SET = {
    ...defaultSetLink,

    id: 'EVERYDAY_SET',

    originalPrice: '3 500',
    discountPrice: '3 150',
    discountPercent: '10',
    discountSum: '350',

    href: HREF,

    basketSchema: BASKET_SCHEMA,
    subdivisions: [LOCATION_MSK, LOCATION_SPB, LOCATION_NIZ],
    datesType: 'everydayAvailableDates',
    setType: 'tomorrow',
};
