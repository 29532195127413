import { createUrl } from 'app/utils/url';
import { defaultSetLink } from 'app/const/links/const';

import { PROMO_SECOND_ORDER_PRICE } from 'app/const/promo';

// ***** FEEDBACK *****
// searchParams:
// const PROMO = '1990DINNER';
const PROMO = '2250DINNER'; // новый промо после подъема цен

const BASKET_SCHEMA = [
    ['cm', '10'],
];

// hrefs:
const DISCOUNT_BASKET_LINK = createUrl({
    path: 'basket',
    params: [
        ...BASKET_SCHEMA,
        ['promo_code', PROMO],
        ['hide', 'payment_button_recurrent'],
        ['menu', 'everyday'],
        ['payment', 'online'],
    ],
});

const PAGE_HREF = '/feedback/';


const FEEDBACK_SET = {
    ...defaultSetLink,

    id: 'FEEDBACK_SET',

    originalPrice: '3 020',
    discountPrice: PROMO_SECOND_ORDER_PRICE,
    // discountPercent: '28', // неофициально посчитанная примерная скидка
    discountSum: '770',

    hrefBasket: DISCOUNT_BASKET_LINK,
    hrefStaticPage: PAGE_HREF,

    basketSchema: BASKET_SCHEMA,
};

export { FEEDBACK_SET };
