import { defaultLink } from 'app/const/links/const';


const SOCIETY = {
    ...defaultLink,
    id: 'SOCIETY',

    hrefInstagram: 'https://www.instagram.com/elementaree.ru/',
    hrefVk: 'https://vk.me/elementaree',
    hrefMessenger: 'https://www.messenger.com/t/elementaree',
    hrefZen: 'https://zen.yandex.ru/elementaree',

    telegramHref: 'https://t.me/elementaree_bot',
};

const APPS = {
    ...defaultLink,
    id: 'APPS',

    hrefOnelink: 'http://onelink.to/elementaree',
    hrefIOS: 'https://apps.apple.com/ru/app/elementaree/id1480217900',
    hrefAndroid: 'https://play.google.com/store/apps/details?id=ru.elementareeee',
};

const PHONE = {
    ...defaultLink,
    id: 'PHONE',

    href: 'tel:+78005504612',
};

/*
    ссылки с zoom используются в десктопной версии,
    ссылки без zoom используются в мобильной версии
*/
const DELIVERY_MAPS = {
    ...defaultLink,
    id: 'MAPS',
    hrefMsk: 'https://yandex.ru/maps/?um=constructor%3Ab8fa446154006166906e79d9d810ea0519586e4a83c26eb8b091a993d8c3591e&source=constructorLink',
    hrefMskZoom: 'https://yandex.ru/maps/?ll=37.622128%2C55.700064&mode=usermaps&source=constructorLink&um=constructor%3Ab8fa446154006166906e79d9d810ea0519586e4a83c26eb8b091a993d8c3591e&z=9',
    hrefMskTrial: 'https://yandex.ru/maps/?um=constructor%3Aaa7d46a38e7670fb802dccb08f8a7227a4c8810c68f5820b0e24dfc36310fe48&source=constructorLink',
    hrefMskTrialZoom: 'https://yandex.ru/maps/213/moscow/?ll=37.562515%2C55.713195&mode=usermaps&source=constructorLink&um=constructor%3Aaa7d46a38e7670fb802dccb08f8a7227a4c8810c68f5820b0e24dfc36310fe48&z=9',
    hrefSPB: 'https://yandex.ru/maps/?um=constructor%3A6e5bd95becc0e621f965b7bc02fad63236d4f201fa79f6ac834a49171c2ef947&source=constructorLink',
    hrefSPBZoom: 'https://yandex.ru/maps/2/saint-petersburg/?ll=30.381620%2C59.917779&mode=usermaps&source=constructorLink&um=constructor%3A6e5bd95becc0e621f965b7bc02fad63236d4f201fa79f6ac834a49171c2ef947&z=10',
    hrefSPBTrial: 'https://yandex.ru/maps/?um=constructor%3A8e0c8e646cbfe6ee35c08029cd20ec4ab4d959a87c98d19dabc2528a1a483599&source=constructorLink',
    hrefSPBTrialZoom: 'https://yandex.ru/maps/2/saint-petersburg/?ll=30.361579%2C59.933089&mode=usermaps&source=constructorLink&um=constructor%3A8e0c8e646cbfe6ee35c08029cd20ec4ab4d959a87c98d19dabc2528a1a483599&z=10',
    hrefNIZTrial: 'https://yandex.ru/maps/47/nizhny-novgorod/?ll=43.888984%2C56.301861&mode=usermaps&source=constructorLink&um=constructor%3Af4f81d85921af661c36fabd9a52b1d8e4712e3e634613b903230da5871999c60&z=9',
    hrefNIZTrialZoom: 'https://yandex.ru/maps/47/nizhny-novgorod/?ll=43.805213%2C56.312667&mode=usermaps&source=constructorLink&um=constructor%3A1e85ff2894569a0b1283f4883f3c4612ce7117d723feb5933c7a949cebe71a50&z=11',
    hrefKZNTrial: 'https://yandex.ru/maps/?um=constructor%3A8f6bf70a23e83d958ec3eac35d6cc0a88a709cd579a4096a863720b262656c48&source=constructorLink',
    hrefKZNTrialZoom: 'https://yandex.ru/maps/?um=constructor%3A8f6bf70a23e83d958ec3eac35d6cc0a88a709cd579a4096a863720b262656c48&source=constructorLink',
};


export default {
    SOCIETY,
    APPS,
    PHONE,
    DELIVERY_MAPS,
};
