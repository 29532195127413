import { ApolloLink } from '@apollo/client';
import { userSessionService } from 'global/services';

function operationMapLinkHandler(operation, forward) {
    const { operationName } = operation;
    userSessionService.setFetchMap(operationName);
    return forward(operation);
}

/**
 * @description Мапает список операций для удобного чтения логов в sentry
 */
export const operationMapLink = new ApolloLink(operationMapLinkHandler);

