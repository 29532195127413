export const PAYMENT_PERIOD_SINGLE = 'single';
export const PAYMENT_PERIOD_RECURRENT = 'recurrent';
export const PAYMENT_PERIOD_MONTH = 'month'; // all prepaid deliveries

export const PAID = 'paid';
export const NOT_PAID = 'not paid';
export const PARTIALLY_PAID = 'partially paid';

export const isPaid = notPaidAmount => notPaidAmount === 0;

export const normalizePaymentStatus = (numericPaymentStatus, notPaidAmount) => {
    if (isPaid(notPaidAmount)) return PAID;
    if (numericPaymentStatus === 0) return NOT_PAID;
    if (numericPaymentStatus === 1) return PARTIALLY_PAID;
    if (numericPaymentStatus === 9) return PAID;

    return 'wrong payment status';
};
