import queryString from 'query-string';
import cookie from 'js-cookie';

import { REFERRRER_CODE_KEY } from 'app/const/referrer';

/*
    Сохранение кода в localStorage сделано для того, чтобы при навигации по сайту и перезагрузках
    пользователь не потерял его из url
 */
export const getReferrerCodeFromLocation = (location) => {
    const query = queryString.parse(location.search);
    return query[REFERRRER_CODE_KEY];
};

export const saveReferrerCode = code => localStorage.setItem(REFERRRER_CODE_KEY, code);

export const getReferrerCode = () => (
    localStorage.getItem(REFERRRER_CODE_KEY)
    || cookie.get(REFERRRER_CODE_KEY)
    || ''
);

export const removeReferrerCode = () => localStorage.removeItem(REFERRRER_CODE_KEY);
