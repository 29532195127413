import React, { useState } from 'react';
import cn from 'classnames';
import ArrowIcon from './img/arrow.component.svg';
import css from './desktopCustomIngredientsSelect.module.scss';
import { ICustomIngredientsSelectProps } from '../CustomIngredientsSelectDishRow.types';


export function DesktopCustomIngredientsSelect(props: ICustomIngredientsSelectProps) {
    const {
        selectedDishId,
        ingredientsList,
        checkIsSelected,
        onNextIdSelected,
        ingredientTypeText,
        replaceableIngredient,
    } = props;
    const [isOpen, setIsOpen] = useState(false);

    const checkIsOpen = () => (isOpen ? 'open' : '');

    return (
        <div className={cn(css.selectIngredientsRoot, checkIsOpen())}>
            <button
                className={css.selectIngredientsButton}
                type="button"
                onClick={() => { setIsOpen(!isOpen); }}
            >
                <div className={css.selectIngredientsTextRow}>
                    <div className={css.ingredientType}>
                        {ingredientTypeText}
                        &nbsp;
                    </div>
                    <div className={css.ingredientValue}>
                        {replaceableIngredient}
                    </div>
                    {!isOpen && (
                        <div className={css.ingredientIcon}>
                            <ArrowIcon />
                        </div>
                    )}
                </div>
            </button>
            {isOpen && (
                <div className={css.selectIngredientsList}>
                    {ingredientsList.map((item) => (
                        <button
                            // @ts-ignore
                            className={cn(css.selectIngredientsListButton, checkIsSelected(item.id, selectedDishId))}
                            type="button"
                            onClick={() => {
                                onNextIdSelected(item.id);
                                setIsOpen(false);
                            }}
                            id={item.id}
                            key={item.id}
                        >
                            <div className={css.selectIngredientsListItemText}>
                                {item.replaceableIngredient}
                            </div>
                        </button>

                    ))}
                </div>
            )}
        </div>
    );
}
