import sortBy from 'lodash/sortBy';

import { errorService } from 'global/services';

// import { lifePay } from './paymentProviderLifePay';
import { cloudPayments } from './paymentProviderCloudPayment';
// import { sber } from './paymentProviderSber';


const STATE_LOADING = 'loading';
const STATE_LOADED = 'loaded';
const STATE_ERROR = 'error';


const paymentProvidersPriority = [
    // lifePay,
    cloudPayments,
    // sber,
];

/*
 TODO: вынести подключение скриптов и состояние их загрузки в отдельный модуль
 (создание нового инстанса не должно подключать скрипты во второй раз)

 TODO: добавить возможность менять приоритет в провайдерах оплаты на лету
*/
export class PaymentService {
    configure({ preferredProvider } = {}) {
        const sortedProviders = sortBy(paymentProvidersPriority, [
            (p) => p.id !== preferredProvider,
        ]);

        this.paymentProviders = sortedProviders.map((p) => {
            this.appendScript(p);
            return {
                ...p,
                state: STATE_LOADING,
            };
        });
    }

    appendScript(provider) {
        const s = document.createElement('script');

        s.src = provider.src;

        s.addEventListener('load', () => {
            this.handleScriptLoaded(provider.id, STATE_LOADED);
        });

        s.addEventListener('error', (event) => {
            this.handleScriptLoaded(provider.id, STATE_ERROR);

            errorService.log({
                text: `PaymentService: loading error for ${provider.id}`,
                source: 'client',
                extra: {
                    event,
                },
            });
        });

        document.body.appendChild(s);
    }

    handleScriptLoaded(providerId, loadingState) {
        const provider = this.paymentProviders.find((p) => p.id === providerId);
        provider.state = loadingState;
    }

    findProvider(options) {
        const {
            paymentMethod,
            paymentProviderId,
        } = options;

        if (paymentProviderId) {
            const paymentProvider = this.paymentProviders.find((p) => {
                const isProviderAcitve = p.state === STATE_LOADED;
                return p.id === paymentProviderId && isProviderAcitve;
            });
            return paymentProvider;
        }

        const paymentProvider = this.paymentProviders.find((p) => {
            const isProviderAcitve = p.state === STATE_LOADED;
            const isProviderSupportsPaymentMethod = p.paymentMethods.includes(paymentMethod);
            return isProviderAcitve && isProviderSupportsPaymentMethod;
        });

        return paymentProvider;
    }

    // TODO: добавить обработку ошибок
    showWidget(options) {
        const paymentProvider = this.findProvider(options);
        paymentProvider.showWidget(options);
    }
}


export const paymentService = new PaymentService();
