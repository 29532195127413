import React from 'react';


export const getDialogTextIfWeKnow = (text: string) => (
    <>
        Ранее вы указывали предпочтения
        {' '}
        <strong>
            «
            {text}
            »
        </strong>
        <br />
        <br />
        Мы можем не успеть учесть их в этой доставке. Пожалуйста, самостоятельно выберите
        подходящие вам блюда, если все хорошо - продолжайте оформление заказа.
    </>
);

export const getDialogTextIfWeDont = () => (
    <>
        Ранее вы указывали комментарий к меню.
        <br />
        <br />
        Мы можем не успеть учесть их в этой доставке. Если вам подходит это меню - продолжайте оформление заказа
    </>
);
