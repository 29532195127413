import React, { useContext } from 'react';

import { useFragment } from '@apollo/client';
import { ADDRESS_SUBDIVISION_FRAGMENT } from 'app/graphql/address/subdivision.fragment.graphql';
import { LocationContext } from 'app/containers/LocationProvider';
import Icon from './Frame-1.svg';

import FilterItem from '../FilterItem';
import CityList from './CityList';
import { locales } from './period.locales';


class CityFilter extends React.PureComponent {
    state = {
        isExpanded: false,
    };

    handleToggle = (isExpanded) => {
        this.setState({
            isExpanded,
        });
    };

    onSelect = () => { };

    render() {
        const {
            title, locale, mutateAddreessHandler, isSpb, isMsk, isNiz, isKzn,
        } = this.props;
        const { isExpanded } = this.state;

        // Locale content data
        const { regionTitle } = locales[locale];

        return (
            <FilterItem
                title={regionTitle}
                subtitle={() => title}
                Icon={Icon}
                isExpanded={isExpanded}
                onToggle={this.handleToggle}
            >
                <CityList
                    isExpanded={isExpanded}
                    onSelect={this.onSelect}
                    locale={locale}
                    isSpb={isSpb}
                    isMsk={isMsk}
                    isNiz={isNiz}
                    isKzn={isKzn}
                    mutateAddreessHandler={mutateAddreessHandler}
                />
            </FilterItem>
        );
    }
}

const CityFilterDataAccessContainer = (props) => {
    // тип сгенерировался не в camelCase
    // eslint-disable-next-line camelcase
    const { data: sessionAddressData } = useFragment({
        fragment: ADDRESS_SUBDIVISION_FRAGMENT,
        from: {
            __ref: 'address:session_address',
        },
    });
    const { getCityTitile, mutateAddreessHandler } = useContext(LocationContext);
    const {
        longTitle, isMsk, isSpb, isNiz, isKzn,
    } = getCityTitile({ deliveryAddress: { address: { ...sessionAddressData } } });

    return (
        <CityFilter
            {...props}
            title={longTitle}
            isSpb={isSpb}
            isMsk={isMsk}
            isNiz={isNiz}
            isKzn={isKzn}
            mutateAddreessHandler={mutateAddreessHandler}
        />
    );
};

export default React.memo(CityFilterDataAccessContainer);
