import React, {
    useState, useContext, useCallback, useMemo,
} from 'react';
import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import Raven from 'raven-js';

import GET_AB_TEST_VALUE from 'app/graphql/network/abTests';

import { LocaleContext } from 'app/containers/LocaleProvider';


const INITIAL_STATE = 'unfetched';
export const FETCHED_STATE = 'fetched';
export const ERROR_STATE = 'error';
const BY_HAND_STATE = 'byHand';


export const AB_MENU = 'menu';

// export const AB_DISH_SETTINGS_IN_BASKET = 'dish_settings_in_basket';
export const WELCOME_DISCOUNT_SIZE = 'welcome_discount_size';
export const WELCOME_DISCOUNT_SIZE_15 = 'discount15';
export const WELCOME_DISCOUNT_SIZE_25 = 'discount25';

export const DEFAULT_DISCOUNT = 'default_discount';
export const DEFAULT_DISCOUNT_TEST = 'discount';
export const DEFAULT_DISCOUNT_CONTROL = 'control';

export const AB_BASKET_CUSTOMIZATION_TAGS = 'basket_custom_with_tags';
export const AB_BASKET_CUSTOMIZATION_TAGS_TEST = 'custom_with_tags';
export const AB_BASKET_CUSTOMIZATION_TAGS_CONTROL = 'control';


export const BASKET_WITH_GIFT = 'basket_with_gifts';
export const BASKET_WITH_GIFT_CONTROL = 'control';
export const BASKET_WITH_GIFT_TEST = 'with gift';

// BR-1101
export const FILTERS_DATE_AND_CITY = 'filters_date_and_city';
export const FILTERS_DATE_AND_CONTROL = 'control';
export const FILTERS_DATE_AND_TEST = 'hidden_filters';

// BR-1104 TODO: Удалить, тест все
export const CUSTOMIZATION_IN_MENU = 'customization_in_menu';
export const CUSTOMIZATION_IN_MENU_CONTROL = 'control';
export const CUSTOMIZATION_IN_MENU_TEST = 'with_custom';

// BR-1113
export const REPLACE_POPULAR_CUSTOM = 'replace_popular_custom';
export const REPLACE_POPULAR_CUSTOM_CONRTOL = 'control';
export const REPLACE_POPULAR_CUSTOM_TEST = 'test';

// BR-1125
export const COLORFUL_CATEGORIES_IN_MENU = 'colorful_categories_in_menu';
export const COLORFUL_CATEGORIES_IN_MENU_CONTROL = 'control';
export const COLORFUL_CATEGORIES_IN_MENU_TEST = 'new_categories';

// BR-1147
export const NEW_TRIAL_UPPER_BLOCKS = 'new_trial_upper_blocks';
export const NEW_TRIAL_UPPDER_BLOCKS_TEST = 'test';
export const NEW_TRIAL_UPPDER_BLOCKS_CONTROL = 'control';

const initialState = {
    /**
     * Перечислены все используемые ab-тесты и их дефолтные значения.
     * Очень желательно указывать дефолтное значение и все возможные группы здесь же.
     */
    [AB_MENU]: 'new',
    [CUSTOMIZATION_IN_MENU]: 'with_custom',
    [WELCOME_DISCOUNT_SIZE]: WELCOME_DISCOUNT_SIZE_15,
    // [FILTERS_DATE_AND_CITY]: FILTERS_DATE_AND_TEST,
    [FILTERS_DATE_AND_CITY]: 'hidden_filters',
    [REPLACE_POPULAR_CUSTOM]: 'control',
    [COLORFUL_CATEGORIES_IN_MENU_TEST]: 'control',
    [NEW_TRIAL_UPPER_BLOCKS]: 'control',

    // SERVICE DATA
    /**
     * @description объект fetchingState хранит, каким образом было установлено состояние конкретного ab-теста.
     * Помогает в отладке и предотвращении лишних запросов по сети и изменений стейта.
     * Нет необходимости добавлять все ab-тесты в этот объект. При изменении значений теста флаг будет добавлен автоматически.
     * Возможные состояния: INITIAL_STATE, FETCHED_STATE, BY_HAND_STATE, ERROR_STATE.
     * INITIAL_STATE - исходное состояние. Важно определять его при добавлении нового ab-теста.
     * FETCHED_STATE - получено по сети.
     * ERROR_STATE - ошибка при получении по сети.
     * BY_HAND_STATE - значение установлено вручную.
    */
    fetchingState: {
        // [AB_BASKET_PREVIEW_IN_MENU]: INITIAL_STATE, // for example
        // [AB_DISH_SETTINGS_IN_BASKET]: INITIAL_STATE, // for example
    },
    /**
     * @description в error хранится объект ошибки при ошибке получении значения ab-теста по сети
     */
    error: null,
};

/**
 * объект, который позволяет мокать тесты
 * (запросы на такие тесты не будут отправляться в fetchABTestValue)
 */
const mockedTests = {
    // [AB_DISH_SETTINGS_IN_BASKET]: 'with_settings', // null 'with_settings'
    // [AB_BASKET_CUSTOMIZATION_TAGS]: AB_BASKET_CUSTOMIZATION_TAGS_TEST,
    // [AB_SINGLE_STEP_CHECKOUT]: AB_SINGLE_STEP_CHECKOUT_TEST,
    // [WELCOME_DISCOUNT_SIZE]: WELCOME_DISCOUNT_SIZE_25,
    // [DEFAULT_DISCOUNT]: DEFAULT_DISCOUNT_TEST,
};


export const abTestDataContext = React.createContext(initialState);

export const ABTestDataProvider = ({ children }) => {
    const [abState, setABState] = useState(initialState);

    const { locale } = useContext(LocaleContext);

    const client = useApolloClient();

    /**
     * @description Асинхронный метод для получения и установки аб-группы у аб-теста через запрос к бэку.
     * Выполняется только один раз для конкретного теста, после чего не меняет стейт и не выполняет лишних запросов.
     * Поведение:
     * - После получения значения сохраняет значение аб-группы в стейт и
     * устанавливает флаг FETCHED_STATE для этого аб-теста, чтобы предотвратить повторные запросы.
     * - Если произошла ошибка при получении данных, то устанавливается флаг ERROR_STATE для этого ab-теста и
     * возвращается дефолтное значение.
     * - Для английской версии ab-тесты не используются, возвращается исходное значение аб-группы.
     *
     * @returns {string} Возвращает значение ab-группы для использования сразу в месте вызова (только в async-функциях).
     *
     * @param {string} abTest - имя аб-теста, для которой нужно получить значение аб-группы.
    */
    const fetchABTestValue = useCallback(async (abTest) => {
        const { fetchingState } = abState;

        if (locale === 'en') return abState[abTest];

        const isError = fetchingState[abTest] === ERROR_STATE;
        if (isError) return abState[abTest];

        const isAlreadyFetched = fetchingState[abTest] === FETCHED_STATE;
        if (isAlreadyFetched) return abState[abTest];

        if (fetchingState[abTest] === 'fetching') {
            return '';
        }

        try {
            setABState((prevState) => ({
                ...prevState,
                fetchingState: { ...fetchingState, [abTest]: 'fetching' },
            }));
            let value;

            if (mockedTests[abTest] && window.location.host !== 'elementaree.ru') {
                value = mockedTests[abTest];
                console.warn(`abTest '${value}' is mocked`);
            } else {
                const { data } = await client.query({
                    query: GET_AB_TEST_VALUE,
                    variables: { abGroup: abTest },
                });

                value = data?.getABTestValue?.value;
            }

            setABState((prevState) => ({
                ...prevState,
                [abTest]: value,
                fetchingState: { ...fetchingState, [abTest]: FETCHED_STATE },
            }));

            return value;
        } catch (error) {
            setABState((prevState) => ({
                ...prevState,
                fetchingState: { ...fetchingState, [abTest]: ERROR_STATE },
                error,
            }));

            Raven.captureException(error);

            return abState[abTest];
        }
    }, [client, locale, abState]);

    /**
     * @description метод для установки значения аб-теста вручную.
     * - Сохраняет значение в стейт и устанавливает флаг BY_HAND_STATE для этого ab-теста.
     * - При совпадении переданного значения с текущим не изменяет стейт.
     *
     * @returns {string} Возвращает значение ab-группы для использования сразу в месте вызова.
     *
     * @param {string} abTest - имя аб-теста, для которого устанавливается значение аб-группы.
     * @param {string} value - значение аб-группы.
    */
    const setABTestValue = useCallback((abTest, value) => {
        const { fetchingState, [abTest]: currentValue } = abState;

        if (value === currentValue) return currentValue;

        setABState((prevState) => ({
            ...prevState,
            [abTest]: value,
            fetchingState: { ...fetchingState, [abTest]: BY_HAND_STATE },
        }));

        return value;
    }, [abState]);


    const contextValue = useMemo(() => ({
        ...abState,
        fetchABTestValue,
        setABTestValue,
    }), [abState, fetchABTestValue, setABTestValue]);

    return (
        <abTestDataContext.Provider value={contextValue}>
            {children}
        </abTestDataContext.Provider>
    );
};

const withABTestData = (WrappedComponent) => (forwardedProps) => {
    const abTestData = useContext(abTestDataContext);

    return (
        <WrappedComponent
            abTestDataContext={abTestData}
            {...forwardedProps}
        />
    );
};

export default withABTestData;


ABTestDataProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
