import React, { ReactNode } from 'react';
import cn from 'classnames';

import XBigIcon from 'assets/svg/x_medium.component.svg';
import Button from 'app/components/ui/Button/ButtonV2';
import Paragraph from 'app/components/ui/Paragraph';
import Heading from 'app/components/ui/Heading';
import { Mobile, Desktop } from 'app/components/Responsive';

import css from './dialogResponisve.module.scss';


export interface DialogProps {
    strongText?: ReactNode,
    regularText?: ReactNode,
    extraText?: ReactNode,
    confirmText?: ReactNode,
    rejectText?: ReactNode,
    confirmOneText?: ReactNode,
    confirmAllText?: ReactNode,

    notifyOnly?: boolean,
    rejectDisabled?: boolean,
    isConfirmLoading?: boolean,
    oneRowButtons?: boolean,
    buttonsReversed?: boolean,
    isProfileLayout?: boolean,
    isCloseConfirmAction?: boolean,
    textAlign?: 'center' | 'start',
    extraButtonPosition?: 'last' | 'first',

    onConfirm: () => void,
    onReject?: () => void,
    rejectSpecificHandler?: () => void,
    onConfirmOne?: () => void,
    onConfirmAll?: () => void,
}


export function DialogResponsive({
    onConfirm,
    onConfirmOne = undefined,
    onConfirmAll = undefined,
    onReject = undefined,
    rejectSpecificHandler = undefined,
    isConfirmLoading = false,
    isCloseConfirmAction = false,

    strongText = '',
    regularText = '',
    extraText = '',
    confirmText = '',
    confirmOneText = '',
    confirmAllText = '',
    rejectText = '',

    notifyOnly = false,
    rejectDisabled = false,
    oneRowButtons = false,
    buttonsReversed = false,
    isProfileLayout = false,
    // eslint-disable-next-line react/require-default-props
    textAlign = 'center',
    extraButtonPosition = 'last',
}: DialogProps) {
    const handleReject = notifyOnly ? onConfirm : onReject;

    const mainButtonText = confirmAllText || confirmText;
    const mainButtonHandler = onConfirmAll || onConfirm;

    const extraButtonText = confirmOneText || rejectText;
    const extraButtonHandler = onConfirmOne || handleReject;

    const buttonsContainerClasses = cn({
        [css.dialogButtonsContainer]: true,
        [css.oneRow]: oneRowButtons,
        [css.twoRows]: !oneRowButtons,
        [css.reversed]: buttonsReversed,
        [css.dialogButtonContainerProfile]: isProfileLayout,
    });

    const strongTextClasses = cn({
        [css.dialogStrongText]: true,
        [css[textAlign]]: true,
        [css.profileStrongText]: isProfileLayout,
    });

    const regularTextClasses = cn({
        'fixed-lineheight': !isProfileLayout,
        [css.dialogRegularText]: true,
        [css[textAlign]]: true,
        [css.dialogRegularTexts]: isProfileLayout,
    });

    const regularTextContainerClasses = cn({
        [css.dialogRegularTextContainer]: true,
        [css.profileModal]: isProfileLayout,
    });

    const dialogTitleContainerClasses = cn({
        [css.dialogTitleContainer]: true,
        [css.dialogTitleContainerProfile]: isProfileLayout,
    });

    return (
        <div className={css.dialogRoot}>
            <Mobile>
                <div className={css.dialogHeader}>
                    <button
                        className={css.dialogCloseButton}
                        onClick={isCloseConfirmAction ? onConfirm : handleReject}
                        type="button"
                        disabled={rejectDisabled}
                    >
                        <XBigIcon />
                    </button>
                </div>
            </Mobile>

            <div className={dialogTitleContainerClasses}>
                <div className={css.dialogHeadingContainer}>
                    <Heading id="css-test" level="3.2" className={strongTextClasses}>
                        {/* @ts-ignore */}
                        {strongText}
                    </Heading>
                    <Desktop>
                        {/* @ts-ignore */}
                        <button
                            className={css.dialogCloseButton}
                            onClick={isCloseConfirmAction ? onConfirm : handleReject}
                            type="button"
                            disabled={rejectDisabled}
                        >
                            <XBigIcon />
                        </button>
                    </Desktop>
                </div>
                <div className={regularTextContainerClasses}>
                    {regularText && (
                        <Paragraph level="2.2" className={regularTextClasses}>
                            {regularText}
                        </Paragraph>
                    )}
                </div>
            </div>

            <div className={buttonsContainerClasses}>
                {extraButtonPosition === 'first' && (
                    !notifyOnly && extraButtonText && (
                        // @ts-ignore
                        <Button colorType="secondary" onClick={extraButtonHandler}>
                            {extraButtonText}
                        </Button>
                    )
                )}
                {mainButtonHandler && (
                    // @ts-ignore
                    <Button
                        onClick={mainButtonHandler}
                        loading={isConfirmLoading}
                        className={css.dialogButton}
                    >
                        {mainButtonText}
                    </Button>
                )}
                {extraButtonPosition === 'last' && (
                    !notifyOnly && (
                        <Button
                            // @ts-ignore
                            colorType="secondary"
                            onClick={() => {
                                if (rejectSpecificHandler) {
                                    rejectSpecificHandler();
                                    return;
                                }
                                if (extraButtonHandler) {
                                    extraButtonHandler();
                                }
                            }}
                        >
                            {extraButtonText}
                        </Button>
                    )
                )}
            </div>

            {extraText && (
                <div className={css.dialogExtraTextContainer}>
                    <Paragraph level="4.2" className={css.dialogExtraText} inheritColors>
                        {extraText}
                    </Paragraph>
                </div>
            )}
        </div>
    );
}
