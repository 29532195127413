import React, { useContext } from 'react';
import { OverlayContextDispatch } from 'app/containers/contexts/overlay.context';


const IntervalDialogContent = () => (
    <>
        Для&nbsp;выбранной даты нет&nbsp;доступных интервалов&nbsp;доставки
    </>
);

/**
 * @description Диалог отображает уведомление о том что на выбранную дату нет доступных интервалов доставки
 * @see fetchTimeIntervalWithDateData
 */
export const useNoIntervalDialog = () => {
    const { pushDialogOverlay, closeLastOverlays } = useContext(OverlayContextDispatch);

    const pushNoIntervalDateDialog = () => {
        const dialogData = {
            notifyOnly: true,
            strongText: <IntervalDialogContent />,
            confirmText: 'Выбрать другую дату',
            onConfirm: () => {
                closeLastOverlays();
            },
        };

        pushDialogOverlay('interval_confirm_dialog_checkout', dialogData);
    };
    return { pushNoIntervalDateDialog };
};
