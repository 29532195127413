
import React, { useContext } from 'react';
import BasketPreview from 'app/components/BasketPreview/BasketPreview';
import {
    OVERLAY_ANIMATION_TIME,
    OverlayContextDispatch,
} from 'app/containers/contexts/overlay.context';
import { selectedPeriodVar } from 'app/apollo/reaction';
import { analyticService } from 'global/services';
import { ISetsItem, TCardKey } from '../sets.record';
import { useClearBasketPopup } from './useClearBasketPopup';
// import { useFirstAvailableSpecificPeriod } from './useFirstAvailableSpecificPeriod';


export const useBasketPreviewAside = (props: any) => {
    const {
        openBasket,
        handleFillBasketBy,
        basketQuery,
        isBasketEmpty,
    } = props;

    const selectedPeriod = selectedPeriodVar();

    /* DA */
    const {
        pushOverlay,
        closeLastOverlays,
    } = useContext(OverlayContextDispatch);

    /* HANDLERS */
    const openClearBasketDaalog = useClearBasketPopup({ handleFillBasketBy });

    // Теперь берем не первый доступный период, а выбранный
    // const getFirstAvailableSpecificPeriod = useFirstAvailableSpecificPeriod();

    const handlePlaceOrder = (e: MouseEvent) => {
        console.log('click');
        e.preventDefault();

        closeLastOverlays();
        setTimeout(openBasket, OVERLAY_ANIMATION_TIME);
    };

    const handleChooseSet = (e: MouseEvent, cardKey: TCardKey) => {
        e.preventDefault();

        if (isBasketEmpty) {
            closeLastOverlays();
            handleFillBasketBy(cardKey);
        } else {
            openClearBasketDaalog(cardKey);
        }
    };

    const openBasketPreviewAsidePanel = (e: MouseEvent, data: ISetsItem) => {
        e.preventDefault();

        analyticService.push({
            setName: data.trackId,
            eventName: 'Track_Click_Best_Set',
        });

        // const firstAvailableSpecificPeriod = getFirstAvailableSpecificPeriod(data.udd);

        // @ts-ignore
        pushOverlay({
            id: 'basket_preview',
            template: 'right',
            overlayProps: {
                isNeedWhiteLayer: true,
            },
            children: (<BasketPreview
                // ЭТО id из линков
                setId={data.cardKey} // ??
                onClickBack={closeLastOverlays} // DONE
                onChooseSet={(set) => handleChooseSet(set, data.cardKey)}
                basketQuery={basketQuery}
                setType={data.setTypeVar}
                period={selectedPeriod}
                // period={firstAvailableSpecificPeriod.start}
                // @ts-ignore
                onPlaceOrder={handlePlaceOrder}
            />
            ),
        });
    };

    return openBasketPreviewAsidePanel;
};
