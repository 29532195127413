/* eslint-disable react/jsx-props-no-spreading */
import React, {
    createContext,
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';

import { createTranslate } from 'app/utils/locale';


export const LOCALE_RU = 'ru';
export const LOCALE_EN = 'en';

export const LocaleContext = createContext({
    locale: LOCALE_RU,
    translate: (key) => key,
});


export const withLocaleContext = (Component) => (props) => (
    <LocaleContext.Consumer>
        {(localeContext) => <Component {...props} localeContext={localeContext} />}
    </LocaleContext.Consumer>
);


export default function LocaleProvider(props) {
    const {
        locale,
        children,
        locales,
    } = props;

    const [contextState, setContextState] = useState({
        locale,
        translate: createTranslate({ locales, locale }),
    });

    useEffect(() => {
        setContextState({
            locale,
            translate: createTranslate({ locales, locale }),
        });
    }, [locale, locales]);

    return (
        <LocaleContext.Provider value={contextState}>
            {children}
        </LocaleContext.Provider>
    );
}


LocaleProvider.propTypes = {
    locale: PropTypes.oneOf([LOCALE_RU, LOCALE_EN]).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    locales: PropTypes.shape({
        ru: PropTypes.shape({}).isRequired,
        en: PropTypes.shape({}).isRequired,
    }).isRequired,
};
