import sortBy from 'lodash/sortBy';

import { selectDishes } from './dish';


export const PROTEINS_ID = '1';
export const STYLE_ID = '2';
export const INGREDIENTS_ID = '3';

// TODO: вынести id тегов в константы

export const DELIVERY_DATE_TAG = 'deliveryDateTag'; // Тег для вывода селекта даты доставки в мобильном хедере

export const SECRET_FILTER_ID = '1573';
export const STANDARD_FILTER_ID = '1229';
export const NY_DISHES_HIDDEN = '2096';

export const IS_PREMIUM_FILTER_ENABLED = true;
export const PREMIUM_FILTER_TITLE = 'Премиум';
export const PREMIUM_FILTER_ID = '1765';

export const NY_DISHES = '1826';
export const NY_DESSERT = '1840';
export const NY_DESSERT_TITLE = 'Новогодние десерты';

export const HOLYDAY_FILTER_ID = '2110';

export const NO_FAT_FILTER = '1220';
export const NO_FAT_FILTER_TITLE = 'Без жареного/жирного';

export const FOR_CHILDREN_FILTER = '1519';
export const FOR_CHILDREN_FILTER_TITLE = 'Для детей';


export const BEST_TAG_ID = '2026';

export const IN_15_MINS = '1809';
export const VEGAN = '1219';

const ZAPAS_TAG_ID = '2028';
const CHERNOMOR_TAG_ID = '2024';
export const NO_MEAT_AND_FOWL = '1515';
export const NO_FISH = '1516';
export const NO_PORK = '1517';
export const NO_LACTOSE = '1518';
export const VEG = '1218';
const NO_SPICY = '1520';
const NO_FAT = '1220';
const IN_5_MINS = '1222';
const IN_ENGLISH = '1810';
const NO_ONION = '1524';
export const NO_MUSHROOMS = '1521';

export const NO_GARLIС = 'NO_GARLIС';

const TAGS_ORDER = [
    DELIVERY_DATE_TAG,
    NY_DISHES,
    NY_DESSERT,
    NO_FAT,
    IN_5_MINS,
    IN_15_MINS,
    PREMIUM_FILTER_ID,
    FOR_CHILDREN_FILTER,
    CHERNOMOR_TAG_ID,
    ZAPAS_TAG_ID,
    VEG,
    VEGAN,
    IN_ENGLISH,
    NO_MEAT_AND_FOWL,
    NO_PORK,
    NO_FISH,
    NO_SPICY,
    NO_ONION,
    NO_MUSHROOMS,
    NO_LACTOSE,
    BEST_TAG_ID,
];


const filtersSorters = TAGS_ORDER.reverse().map((tagId) => {
    const sorter = (tag) => tag.id === tagId;
    return sorter;
});

export const sortTags = (tags) => sortBy(tags, filtersSorters);


export const getCategoryCount = ({
    id, menuDishes, selectedTagIds, basketDishes = [],
}) => {
    const selectedDishes = selectDishes(menuDishes, id, selectedTagIds, null, basketDishes);
    return selectedDishes.length;
};


export const extendTagByCount = (tag, menuDishes, selectedCategoryIds, selectedTagIds, basketDishes = []) => {
    const tagIds = selectedTagIds.concat([tag.id]);
    const selectedDishes = selectDishes(menuDishes, null, tagIds, selectedCategoryIds, basketDishes);
    const count = selectedDishes.length;

    return { ...tag, count };
};


export const filterlocales = {
    ru: {
        [VEG]: 'Вег',
        [VEGAN]: 'Веган',
        [NO_MEAT_AND_FOWL]: 'Без мяса и птицы',
        [NO_PORK]: 'Без свинины',
        [NO_FISH]: 'Без рыбы',
        [NO_MUSHROOMS]: 'Без грибов',
        [NO_GARLIС]: 'Без чеснока',
        [NO_LACTOSE]: 'Без лактозы',
    },
    en: {
        [VEG]: 'Veg',
        [VEGAN]: 'Vegan',
        [NO_MEAT_AND_FOWL]: 'No meat',
        [NO_PORK]: 'No pork',
        [NO_FISH]: 'No fish',
        [NO_MUSHROOMS]: 'No mushrooms',
        [NO_GARLIС]: '  No garlic',
        [NO_LACTOSE]: 'Без лактозы',
    },
};
