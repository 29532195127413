import { getTypeErrorMessage } from './idResolvers.utils';

export const prepareAddressId = (responseObject) => {
    const { type, id, __typename } = responseObject;

    if (!type) {
        throw new Error(getTypeErrorMessage(__typename));
    }

    if (id) {
        return `address:${id}`;
    }

    return `address:${type}`;
};

