import React, { FC, useContext } from 'react';
import {
    CATEGORY_MAIN,
    CATEGORY_SOUPS,
    CATEGORY_SALADS,
    CATEGORY_SNACKS,
    CATEGORY_BREAKFASTS,
    CATEGORY_DESSERT,
    CATEGORY_UPSELL,
    CATEGORY_FARM_PRODUCTS,
    CATEGORY_TITLES,
    CATEGORIES_ICONS,
} from 'app/const/categories';
import { LocaleContext } from 'app/containers/LocaleProvider';
import css from './BasketCategoriesList.module.scss';
import { ICategory } from '../../types';
import BasketCategoryCard from '../BasketCategoryCard';

type LocaleType = 'ru' | 'en'
interface ICategoryTitles {
    [key: string]: string;
}
interface ICategoryIcons extends ICategoryTitles { }

interface ICategoryTitlesCollection {
    ru: ICategoryTitles;
    en: ICategoryTitles;
}

const BasketCategoriesList: FC = () => {
    const { locale } = useContext(LocaleContext);
    const getCategoriesCollection = (): ICategory[] => {
        const categoryIds: string[] = [
            CATEGORY_MAIN,
            CATEGORY_SOUPS,
            CATEGORY_SALADS,
            CATEGORY_SNACKS,
            CATEGORY_BREAKFASTS,
            CATEGORY_DESSERT,
            CATEGORY_UPSELL,
            CATEGORY_FARM_PRODUCTS,
        ];
        const TYPED_CATEGORY_TITLES = CATEGORY_TITLES as ICategoryTitlesCollection;
        const TYPED_CATEGORY_ICONS = CATEGORIES_ICONS as ICategoryIcons;
        const CategoryTitleCollection = TYPED_CATEGORY_TITLES[locale as LocaleType] as ICategoryTitles;

        const categories: ICategory[] = categoryIds.map((categoryId) => ({
            id: categoryId,
            title: CategoryTitleCollection[categoryId] || 'Unknown Category',
            iconPath: TYPED_CATEGORY_ICONS[categoryId],
        }));
        return categories;
    };

    const categories: ICategory[] = getCategoriesCollection();

    return (
        <div className={css.basketCategoriesListRoot}>
            {categories.map((category) => (
                <BasketCategoryCard
                    key={category.id}
                    id={category.id}
                    title={category.title}
                    iconPath={category.iconPath}
                />
            ))}
        </div>
    );
};

export default BasketCategoriesList;
