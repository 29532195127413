import React from 'react';
import Typograf from 'typograf';

import { locales } from '../../checkout.locales';
import AddressNotificationIcon from './address-notification-icon.component.svg';
import './step-address.scss';

const tp = new Typograf({ locale: ['ru', 'en-US'] });


export function StepAddressNotifications(props) {
    const {
        locale,
        deliveryPrice,
        hasError,
        street,
        haveDishesFromTrial,
        isAddresIncompleteNotificationOpen,
    } = props;

    const streetIsValid = !!street.data.house && street.value.trim() !== '' && !hasError;
    const l = locales[locale];

    return (
        <div>
            {isAddresIncompleteNotificationOpen ? (
                <div styleName="step-address-notification">
                    <div styleName="step-address-notification">
                        <AddressNotificationIcon />
                        {tp.execute(l.notificationAddressIncomplete)}
                    </div>
                </div>
            ) : (
                <>
                    {deliveryPrice > 0 && streetIsValid && (
                        <div styleName="step-address-notifcations-container">
                            <div styleName="step-address-notification">
                                <AddressNotificationIcon />
                                {l.notificationDeliveryCost}
                                {' '}
                                {deliveryPrice}
                                &nbsp;
                                ₽
                                {tp.execute(l.notificationDeliveryCostAddress)}
                            </div>
                        </div>
                    )}
                    {haveDishesFromTrial && streetIsValid && deliveryPrice === 0 && (
                        <div styleName="step-address-notifcations-container">
                            <div styleName="step-address-notification">
                                <AddressNotificationIcon />
                                {l.notificationDeliveryIsFree}
                                .
                                {' '}
                                {/* <br /> */}
                                {tp.execute(l.notificationDeliveryFree)}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
