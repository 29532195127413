/* LIBS */
import React, {
    useContext, useEffect, useMemo, useState, useRef, useLayoutEffect,
} from 'react';
import { useQuery } from '@apollo/client';
import { Map, Polygon } from '@pbe/react-yandex-maps'; // Placemark

/* COMPONENTS */
import { LocationContext } from 'app/containers/LocationProvider';

/* GEO JSON */
import { selectedPeriodVar } from 'app/apollo/reaction';
import mskGeoJson from './geojson/map-export-msk-client.json';
import spbGeoJson from './geojson/map-export-spb-client.json';
import nizGeoJson from './geojson/map-export-niz-client.json';
import kznGeoJson from './geojson/map-export-kzn-client.json';

/* STYLES */
import css from './deliveryZoneMap.module.scss';

/* IMG PATH */
import pickIconPath from './img/pin.img.svg';
import buttonIconPath from './img/geoButton.img.svg';

/* REQ */
import { DELIVERY_ZONE_ADDRESS_COORDINATES } from './graphql/DeliveryZoneMap.graphql';

const geoJsonRecord = {
    MSK: mskGeoJson,
    SPB: spbGeoJson,
    NIZ: nizGeoJson,
    KZN: kznGeoJson,
};

const defaultCoordinates = {
    MSK: [37.61768, 55.75069],
    SPB: [30.30678, 59.93992],
    NIZ: [44.00657, 56.32465],
    KZN: [55.79572, 49.10759],
};

// // var query = { lat: 55.878, lon: 37.653 };
// const mockQuery = { lat: 55.878, lon: 37.653 };

const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address';

const fetchAddress = (cords) => (
    fetch(url, {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Token ${process.env.DADATA_TOKEN}`,
        },
        body: JSON.stringify(cords),
    })
);

const MapDeliveryArea = (props) => {
    const {
        geo, coordinates, zoom, mapRef,
    } = props;

    if (!coordinates) {
        return null;
    }

    return (
        <Map
            // defaultState={{ center: coordinates, zoom }}
            state={{
                center: coordinates,
                zoom,
            }}
            className={css.deliveryZoneMap}
            instanceRef={(map) => {
                if (!map) return;

                mapRef.current = map;
                map.events.add('boundschange', (event) => {
                    console.log('event', event);
                });
            }}
        >
            {geo.map(({ geometry, name }) => {
                const options = {
                    fillColor: '#FFDB19',
                    strokeColor: '#FFDB19',
                    opacity: 0.3,
                    strokeWidth: 1,
                    strokeStyle: 'shortdash',
                };
                return (
                    <Polygon
                        geometry={geometry}
                        options={options}
                        key={name}
                    />
                );
            })}
        </Map>
    );
};


function CheckoutYandexMap(props) {
    const {
        mutateDZoneSessionAddress,
        setUIStateForEmptyAddress,
    } = props;

    const { locationKey } = useContext(LocationContext);

    const [coordinates, setСoordinates] = useState();
    const [zoom, setZoom] = useState(9);
    const period = selectedPeriodVar();

    const hadleGetCurrentPostion = () => {
        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(async ({ coords }) => {
                const { latitude, longitude } = coords;
                const res = await fetchAddress({ lat: latitude, lon: longitude });
                const { suggestions } = await res.json() || [];
                const [firstMatchedAddress] = suggestions;

                setСoordinates([longitude, latitude]);
                setZoom(9);

                if (firstMatchedAddress) {
                    mutateDZoneSessionAddress({
                        variables: {
                            period,
                            address: {
                                address: firstMatchedAddress.value,
                            },
                        },
                    });
                } else {
                    setUIStateForEmptyAddress('Адрес не распознан');
                }
            }, () => {
                setUIStateForEmptyAddress('Ошибка при получении координат');
            });
        } else {
            setUIStateForEmptyAddress('Ошибка при получении координат');
        }
    };

    const { data } = useQuery(DELIVERY_ZONE_ADDRESS_COORDINATES, {
        fetchPolicy: 'cache-only',
    });

    useEffect(() => {
        if (locationKey === 'SPB') {
            setСoordinates(defaultCoordinates.SPB);
        } else if (locationKey === 'NIZ') {
            setСoordinates(defaultCoordinates.NIZ);
        } else {
            setСoordinates(defaultCoordinates.MSK);
        }
    }, [locationKey]);

    useEffect(() => {
        if (
            data?.deliveryAddress?.address?.coordinates
            // TODO DidMount Ростовкий косталь
            && ['RU-MOS', 'RU-MOW', 'RU-LEN', 'RU-SPE', 'RU-NIZ', 'RU-NIO', 'RU-TA', 'RU-TAO'].includes(
                data?.deliveryAddress.address?.country_subdivision,
            )

        ) {
            const {
                deliveryAddress: {
                    address: {
                        coordinates: {
                            latitude,
                            longitude,
                        },
                    },
                },
            } = data;
            setСoordinates([longitude, latitude]);
        }
    }, [data]);

    const geometries = useMemo(
        () => {
            const getJson = geoJsonRecord[locationKey] || [];
            const result = getJson.features.map((f) => {
                const geometry = f.geometry.coordinates;
                return {
                    name: f.name,
                    geometry,
                };
            });

            return result;
        },
        [locationKey],
    );

    const mapRef = useRef(null);

    return (
        <div className={css.deliveryZoneMapRoot}>
            <MapDeliveryArea
                zoom={zoom}
                coordinates={coordinates}
                mapRef={mapRef}
                geo={geometries}
                key={locationKey}
            />
            <div className={css.deliveryZoneMapPin}>
                <img src={pickIconPath} alt="pin" />
            </div>
            <button
                className={css.deliveryZoneMapGeoButton}
                type="button"
                onClick={hadleGetCurrentPostion}
            >
                <img
                    src={buttonIconPath}
                    alt="btn"
                    className={css.deliveryZoneMapGeoButtonIcon}
                />
            </button>
        </div>
    );
}

export { CheckoutYandexMap };
