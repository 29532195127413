import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'assets/svg/spinner.svg';
import Success from 'assets/svg/thanks_check.svg';

import './spinner.scss';


const Spinner = ({ isShown, isLoading, isSuccessed }) => {
    if (!isShown) return null;

    return (
        <div styleName="spinner-container">
            {isLoading && <Loader styleName="spinner" />}
            {isSuccessed && <Success styleName="success" />}
        </div>
    );
};

export default Spinner;


Spinner.propTypes = {
    isShown: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isSuccessed: PropTypes.bool.isRequired,
};
