import { isUserChangesTagsVar, selectedCategoriesVar, selectedTagsVar } from 'app/apollo/reaction';
import { _updateIsSelectedDefaultFiltersReaction } from 'app/apollo/reactions/filters';
import { DEFAULT_CATEGORIES } from 'app/const/categories';

/**
 * @param {*} param
 */
export const appendTagReaction = ({ id, isUserChangesTags = false }) => {
    const selectedTags = selectedTagsVar();
    selectedTagsVar([...new Set([...selectedTags, id])]);
    isUserChangesTagsVar(isUserChangesTags);
    _updateIsSelectedDefaultFiltersReaction();
};

/**
 *
 */
export const deleteTagReaction = ({ tagId, isUserChangesTags = false }) => {
    const selectedTags = selectedTagsVar();
    selectedTagsVar(selectedTags.filter((id) => id !== tagId));
    isUserChangesTagsVar(isUserChangesTags);
    _updateIsSelectedDefaultFiltersReaction();
};

/**
 *
*/
export const establishTagsReaction = ({ tags, isUserChangesTags }) => {
    selectedTagsVar(tags);
    isUserChangesTagsVar(isUserChangesTags);
    _updateIsSelectedDefaultFiltersReaction();
};

/*
 *
 */
export const resetSelectedFiltersReaction = () => {
    selectedCategoriesVar(DEFAULT_CATEGORIES);
    selectedTagsVar([]);
    isUserChangesTagsVar(true);
    _updateIsSelectedDefaultFiltersReaction();
};
