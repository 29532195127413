/* eslint-disable max-classes-per-file */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
import { delay } from 'app/utils/common';


const isReactRouterCorrectPathRegExp = /^\/.*\/$/;

const _checkIsReactRouterCorrectPath = (path) => isReactRouterCorrectPathRegExp.test(path);

const _pathReplacer = (path) => {
    const options = [];
    const tail = path.length - 1;

    _checkIsReactRouterCorrectPath(path) ? options.push(1, tail) : options.push(1);

    const normalizedPath = path.split('').slice(...options).join('');
    return normalizedPath;
};

class LocationService {
    constructor() {
        this._prevLocation = '';
        this._currentLocation = '';
        this._roadMap = [];
        this._isToIndexLcation = ['/', '/profile/', '/profile/invite/', '/app/delivery/'];
    }

    getLocationMap() {
        return this._roadMap;
    }

    getLocationState() {
        const preparidPrevPath = this._prevLocation ? `/${this._prevLocation}/` : '/';
        return {
            prevLocation: preparidPrevPath,
            currentLocation: `/${this._currentLocation}/`,
        };
    }

    _setCurrentLocation(_path) {
        this._currentLocation = _path;
    }

    _setPrevLocation(_path) {
        if (this._currentLocation && this._currentLocation !== _path) {
            this._prevLocation = this._currentLocation;
        }
    }

    setLocationState(path) {
        const normalizedPath = _pathReplacer(path);
        this._roadMap.push(normalizedPath);
        this._setPrevLocation(normalizedPath);
        this._setCurrentLocation(normalizedPath);
    }

    goBack(history) {
        const { prevLocation } = this.getLocationState();
        if (this._isToIndexLcation.includes(prevLocation)) {
            delay(700).then(() => { window.location = '/'; });
        } else {
            delay(700).then(() => history.goBack());
        }
    }
}

const locationService = new LocationService();

export { locationService };

