/* eslint-disable no-underscore-dangle */
import {
    InMemoryCache, ApolloClient, from, defaultDataIdFromObject,
} from '@apollo/client';


import { rootReactiveVars, selectedPeriodVar } from './reaction';
// import { linksChain } from './apollo.links';
import { linksAndInterceptorsChain } from './links';

/* POLICIES */
import { eeDatesPolicies } from './policies/eeDates';
import { addressPolicies } from './policies/address';
import { userPolicies } from './policies/user';
import { pastDeliveriesPolicies } from './policies/pastDeliveries/pastDeliveries';
import { deliveryPolicies } from './policies/delivery/delivery';
import { cartPolicies } from './policies/cart';
import { menuDishPolicies } from './policies/menuDish';
import { discountConditionsPolicies } from './policies/discountConditions';
import { intervalsPolicies } from './policies/intervals';
import { menuFilterPolicies } from './policies/menuFilter';

/* ID RESOLVERS FOR dataIdFromObject */
import { prepareAddressId } from './idResolvers/address.id';
import { prepareViewCustomizationCommentTypeId } from './idResolvers/viewCustomizationCommentType.id';
import { prepareIntervalasId } from './idResolvers/intervals.id';
import { prepareDeliveryCalendarId } from './idResolvers/deliveryCalendar.id';
import { preparePeriodsId } from './idResolvers/periods.id';
import { deliveryCalendarPolicies } from './policies/deliveryCalendar';
import { prepareMenuSidebarId } from './idResolvers/menuSidebar.id';
import { prepareCartId } from './idResolvers/cart.id';
import { fragmentRegistry } from './fragmentRegistry';
import { prepareSectionId } from './idResolvers/cartSection.id';


const prepareDiscountConditionsId = () => {
    const period = selectedPeriodVar();
    return `DiscountConditions:${period}`;
};


/**
 * @description CACHE INSTANCE
 */
const appInMemoryCache = new InMemoryCache({
    fragments: fragmentRegistry,
    typePolicies: {
        Query: {
            fields: {
                ...rootReactiveVars,
                menuFilter: menuFilterPolicies,
                cart: {
                    keyArgs: ['period'],
                },
                menuDishes: {
                    keyArgs: ['period'],
                },
                deliveryDates: {
                    keyArgs: ['period'],
                },
                previewCartForSet: {
                    keyArgs: ['period', 'set_type'],
                },
            },
        },

        cart: {
            /**
            * Баг
            * Если в корзине использовать в качестве keyFields сочетание period и typeOfSet,
            * то при создании сначала кастомной корзины, а затем эвридей, в кеше начинают храниться 2 корзины
            * А query при этом возвращает первую, кастомную.
            */
            // keyFields: ['period'],
            fields: cartPolicies,
        },

        user: {
            fields: userPolicies,
        },


        // periods: {
        //     // keyFields: ['start', 'end'],
        // },

        interval: {
            keyFields: ['time_from', 'time_to'],
        },
        intervals: {
            fields: intervalsPolicies,
        },
        deliveryCalendar: {
            fields: deliveryCalendarPolicies,
        },
        DiscountConditions: {
            fields: discountConditionsPolicies,
        },
        address: {
            fields: addressPolicies,
        },
        eeDates: {
            fields: eeDatesPolicies,
        },
        menuDish: {
            fields: menuDishPolicies,
        },
        pastDeliveries: {
            fields: pastDeliveriesPolicies,
        },
        delivery: {
            fields: deliveryPolicies,
        },
        period_borders: {
            keyFields: ['start'],
        },
        cartTotal: {
            keyFields: false,
        },
        DishAssessmentType: {
            keyFields: false,
        },
        checkData: {
            keyFields: ['delivery'],
        },
        addressCoordinates: {
            keyFields: ['latitude', 'longitude'],
        },
        /**
         * TODO:
         * Внутри доставки объекты already_paid и not_paid имеют одинаковый тип delivery_payment_data
         * и одинаковые id от доставки.
        */
        delivery_payment_data: {
            keyFields: ['amount', 'id', 'payment_method'],
        },
        // personalPromo: {
        //     fields: {
        //         promoCode: {
        //             read() {
        //                 return 'test';
        //             },
        //         },
        //         campaignId: {
        //             read() {
        //                 return 30931;
        //             },
        //         },
        //         linkParams: {
        //             read() {
        //                 return '/basket';
        //             },
        //         },
        //     },
        // },
    },

    dataIdFromObject(responseObject) {
        const { __typename } = responseObject;

        switch (__typename) {
            case 'intervals':
                return prepareIntervalasId(responseObject);
            case 'cart':
                return prepareCartId(responseObject);
            case 'cartSection':
                return prepareSectionId(responseObject);
            case 'menuCalendar':
                return 'menuCalendar:app-condition';
            case 'periods':
                return preparePeriodsId(responseObject);
            case 'DiscountConditions':
                return prepareDiscountConditionsId();
            case 'cartItem':
                return `cartItem:${responseObject.dish_id}`;
            case 'deliveryCalendar':
                return prepareDeliveryCalendarId(responseObject);
            case 'eeDates':
                return `eeDates:${responseObject.startDate}`;
            case 'address':
                return prepareAddressId(responseObject);
            case 'viewCustomizationCommentType':
                return prepareViewCustomizationCommentTypeId(responseObject);
            case 'menuSidebar':
                return prepareMenuSidebarId(responseObject);
            default:
                return defaultDataIdFromObject(responseObject);
        }
    },
});

/**
 * @description CLIENT
 * @return Apollo client
 */
export const createClient = () => {
    const customClient = new ApolloClient({
        connectToDevTools: true,
        link: from(linksAndInterceptorsChain),
        cache: appInMemoryCache,
    });

    return customClient;
};

const defaultClient = createClient();

export default defaultClient;
