import React, { useEffect, useMemo } from 'react';
import { useCustomContradictionCheck } from './useCustomContradictionCheck';

export const useCustomContradictionDefine = (props) => {
    const { userQuery, cart } = props;
    const customizationTags = useMemo(
        () => userQuery?.data?.user?.customizationTags || [],
        [userQuery?.data?.user?.customizationTags]);

    const {
        isCustomizationDialogShown,
        setIsCustomizationDialogShown,
        dialogIngridient,
        checkForCustomizationContradiction,
    } = useCustomContradictionCheck(cart || {}, customizationTags);


    const isCustomisationCheckedInBasket = useMemo(
        () => sessionStorage.getItem('with_customization') !== null,
        []);

    const isCustomizationEdited = useMemo(
        () => sessionStorage.getItem('is_customization_edited') !== null,
        [],
    );

    useEffect(
        () => {
            if (userQuery.loading) return;
            if (isCustomisationCheckedInBasket) return;
            if (isCustomizationEdited) return;
            checkForCustomizationContradiction();
        },
        [
            checkForCustomizationContradiction,
            customizationTags,
            userQuery.loading,
            isCustomisationCheckedInBasket,
            isCustomizationEdited,
        ]);

    return {
        isCustomizationDialogShown,
        setIsCustomizationDialogShown,
        dialogIngridient,
    };
};
