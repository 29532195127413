import React from 'react';

import XBigIcon from 'assets/svg/x_medium.component.svg';

import './dialog.scss';


const BR1137Dialog = React.memo(({
    handleClose,
    rejectDisabled = false,
    children,
}) => (
    <div styleName="dialog__root custom_dialog__root">
        <div styleName="dialog__header custom_dialog__header">
            <button
                styleName="dialog__close-button city_select_dialog__close-button"
                onClick={handleClose}
                type="button"
                disabled={rejectDisabled}
            >
                <XBigIcon />
            </button>
        </div>
        <div styleName="custom_dialog_root__flex-wrapper">
            {children}
        </div>
    </div>
),
);

export { BR1137Dialog };


