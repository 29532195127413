export const dishes = [
    '4663',
    '4662',
    '4664',
    '4658',
];

export const periods = [
    '2024-10-14',
    '2024-10-21',
    '2024-10-28',
    '2024-10-28',
    '2024-11-03',
];
