/* eslint-disable no-underscore-dangle */
import { locationService } from 'app/utils/locationService';
import Raven from 'raven-js';
import { userSessionService } from '../sessionService';


class ErrorService {
    constructor() {
        this.errorBadge = {
            server: '[Server]:',
            network: '[Network]:',
            client: '[Client]:',
            unknown: '[Unknown]:',
        };
    }

    _getErrorBadge(source) {
        const errorSourcePrefix = this.errorBadge[source];
        return errorSourcePrefix || `[${source}]:`;
    }

    log({ source = 'unknown', text, ...payload }) {
        const errorSourcePrefix = this._getErrorBadge(source);
        const locationMap = locationService.getLocationMap();
        const errorMessage = `${errorSourcePrefix} ${text}`;

        Raven.captureMessage(errorMessage, {
            extra: {
                ...payload,
                href: window.location.href,
                locationMap,
                userSession: userSessionService.getData(),
            },
            tags: {
                source,
            },
        });
    }
}

export const errorService = new ErrorService();

