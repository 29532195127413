import React from 'react';
import PropTypes from 'prop-types';

import FilterIcon from './filter.svg';

import './filters-button.scss';


export default function FiltersButton(props) {
    const {
        onClick,
        disabled = false,
    } = props;

    return (
        <button
            type="button"
            styleName="filter-button"
            onClick={onClick}
            disabled={disabled}
        >
            <div styleName="filter-button__icon-container">
                <FilterIcon />
            </div>
        </button>
    );
}


FiltersButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};
