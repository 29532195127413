/* eslint-disable camelcase */
/* eslint-disable import/order */
import React, {
    useEffect, useRef, useState, useContext, useMemo, useCallback,
} from 'react';
import { useFragment } from '@apollo/client';

/* CONSTS */
import { citiesLocales as cities } from 'app/const/location';
import { LocationContext } from 'app/containers/LocationProvider';

/* SERVICES */
import { analyticService } from 'global/services';

/* HOOKS */
import { useResize } from 'app/utils/hooks/useResize';

/* COMPONETS */
import { AddressSearch, TSuggestion } from 'app/components/Form/AddressSearch/AddressSearch';
import { MobileCitySelectModal } from 'global/header/components/MobileCitySelectModal/MobileCitySelectModal';

/* TYPES */
import { TButtonUiState, TInputUiState } from 'app/components/ui/input/UiKItUiInput.types';
import { IDeliveryZonePageCommonProps } from '../../../DeliveryZonePage.type';
import { TypeCities } from 'global/const/types/location';

/* REQUESTS */
import { selectedPeriodVar } from 'app/apollo/reaction';
import { DELIVERY_ZONE_SESSION_ADDRESS } from '../../../graphql/deliveryZone.graphql';

/* STYLE */
import css from './deliveryZoneStreetForm.module.scss';
import { SelectDropdown } from 'app/components/ui/SelectDropdown';
import { getAddressForQuery } from 'app/utils/address';

export function DeliveryZoneStreetForm(props: IDeliveryZonePageCommonProps) {
    const {
        externalInputUiState,
        externalButtonUiState,
        externalInputLabel,
        mutateDZoneSessionAddress,
    } = props;

    const period = selectedPeriodVar();

    /* STATE ---------------------------------------------------------------- */

    const {
        locationKey,
        mutateAddreessHandler,
        country_subdivision,
    } = useContext(LocationContext);

    const { isDesktop } = useResize();

    const [isMobileSelectOpenned, setIsMobileSelectOpenned] = useState<boolean>(false);

    const [inputValue, setInputValue] = useState('');
    const [inputLabel, setInputLabel] = useState('Укажите адрес доставки');
    const [inputUiState, setInputUiState] = useState<TInputUiState>('default');
    const [buttonUiState, setButtonUiState] = useState<TButtonUiState>('empty');
    const externalInputRef = useRef(null);
    const [selectedSubdivision, setSelectedSubdivision] = useState(country_subdivision);

    const options: Record<string, string>[] = useMemo(
        () => cities.map((e) => ({ ...e, title: e.value })) || {},
        [],
    );
    const [selectedOption, setSelectedOption] = useState(options.find((e) => e.id === locationKey) || {});

    /* DAC ------------------------------------------------------------------ */
    useEffect(
        () => {
            setInputLabel(externalInputLabel);
            setInputUiState(externalInputUiState);
            setButtonUiState(externalButtonUiState);
        },
        [
            externalInputUiState,
            externalButtonUiState,
            externalInputLabel,
        ],
    );

    const { data: address } = useFragment<any>({
        fragment: DELIVERY_ZONE_SESSION_ADDRESS,
        from: {
            __ref: 'address:session_address',
        },
    });
    const { addressValue } = address;

    useEffect(() => {
        const { house, street } = address;

        const addressValueWithoutCity = getAddressForQuery({ house, street });

        if (address && address.country_subdivision
            && [
                'RU-MOS',
                'RU-LEN',
                'RU-SPE',
                'RU-MOW',
                'RU-NIZ',
                'RU-NIO',
                'RU-TA',
                'RU-TAO',
            ].includes(address?.country_subdivision)) {
            setInputValue(addressValueWithoutCity || '');
        } else {
            setInputValue('');
        }
        // TODO: Ростовский костыль
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressValue]);

    /* HANDLERS ------------------------------------------------------------- */

    const handleSelectedSubdivision = (payload: TypeCities) => {
        setInputUiState('default');
        setButtonUiState('empty');
        setInputLabel('Укажите адрес доставки');
        setInputValue('');
        switch (payload) {
            case 'MSK':
                setSelectedSubdivision('RU-MOS');
                break;
            case 'SPB':
                setSelectedSubdivision('RU-LEN');
                break;
            case 'NIZ':
                setSelectedSubdivision('RU-NIZ');
                break;
            case 'KZN':
                setSelectedSubdivision('RU-TA');
                break;
            default:
                setSelectedSubdivision('RU-MOS');
        }
    };

    const onSelectClickHandler = () => {
        if (isDesktop) return;
        setIsMobileSelectOpenned((prev) => !prev);
    };
    const chooseCity = (id: string) => {
        const targetCity = options.find((e) => e.id === id);
        if (targetCity) {
            setSelectedOption(targetCity);
        }
    };

    // @note DESKTOP ONLY
    const handleCityClick = useCallback((event: React.MouseEvent, id: TypeCities) => {
        event.preventDefault();
        event.stopPropagation();

        handleSelectedSubdivision(id);

        if (id !== selectedOption?.id) {
            const addressString = {
                MSK: 'г Москва',
                SPB: 'г Санкт-Петербург',
                NIZ: 'г Нижний Новгород',
                KZN: 'г Казань',
            };
            const targetOption = options.find((e) => e.id === id);

            // @ts-ignore
            mutateAddreessHandler({ address: addressString[id] }, () => setSelectedOption(targetOption));

            analyticService.push({
                eventName: 'Select_Region',
                result: id,
                source_name: 'selected_by_user',
            });
        }
    }, [selectedOption, mutateAddreessHandler]);

    const handleSubmetNextAddressValue = (daDataRes: TSuggestion) => {
        const {
            data: {
                region_with_type,
                city_with_type,
                area_with_type,
            }, value,
        } = daDataRes;

        const addressValueWithoutCityAndRegion = value
            .replace(`${region_with_type}, `, '')
            .replace(`${city_with_type}, `, '')
            .replace(`${area_with_type}, `, '');

        setInputValue(addressValueWithoutCityAndRegion);
        mutateDZoneSessionAddress({
            variables: {
                period,
                address: {
                    address: daDataRes.value,
                },
            },
        });
    };

    const inputOnChangeHanlder = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { currentTarget: { value } } = event;
        if (value && buttonUiState !== 'clear') {
            setButtonUiState('clear');
        }
        if (!value) {
            setButtonUiState('empty');
        }
        setInputValue(value);
    };

    const inputOnClickHanlder = () => {
        // console.log('click');
    };

    const inputOnFocusHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        const { relatedTarget } = event;

        if (relatedTarget?.id === 'clear') {
            setButtonUiState('empty');
        }

        if (inputValue && !relatedTarget) {
            setInputUiState('default');
            setButtonUiState('clear');
            setInputLabel('Укажите адрес доставки');
        }
    };

    const inputOnBlurHanlder = (event: React.FocusEvent<HTMLInputElement>) => {
        const { relatedTarget, target: { value: targetValue } } = event;
        const value = event.currentTarget?.value;
        const isAddressNotEqual = value !== addressValue;

        if (!relatedTarget && !value && !targetValue) {
            setButtonUiState('empty');
            setInputUiState('default');
        }

        if (relatedTarget?.id === 'suggestion') {
            setButtonUiState('clear');
            setInputUiState('default');
        }

        if (!relatedTarget && !isAddressNotEqual && value && targetValue) {
            setButtonUiState('confirmed');
        }

        if (!relatedTarget && isAddressNotEqual && (value || targetValue)) {
            setInputLabel('Некорректный адрес доставки');
            setButtonUiState('error');
            setInputUiState('error');
        }
    };


    return (
        <div className={css.deliveryZoneStreetFormRoot}>
            <div
                onClick={onSelectClickHandler}
                className={css.deliveryZoneStreetFormSelectWrapper}
            >
                {/* DESKTOP */}
                <SelectDropdown<TypeCities>
                    options={options}
                    callback={(e: React.MouseEvent, id: TypeCities) => handleCityClick(e, id)}
                    selectedOptionProp={selectedOption}
                />
                {/* MOBILE */}
                <MobileCitySelectModal
                    isOpen={isMobileSelectOpenned}
                    setIsOpen={setIsMobileSelectOpenned}
                    setCityCallback={chooseCity}
                    cities={options}
                    handleSelectedSubdivision={handleSelectedSubdivision}
                />
            </div>
            <div className={css.deliveryZoneStreetFormInputWrapper}>
                <AddressSearch
                    inputValue={inputValue}
                    inputLabel={inputLabel}
                    inputOnChangeHanlder={inputOnChangeHanlder}
                    inputOnFocusHandler={inputOnFocusHandler}
                    inputOnBlurHanlder={inputOnBlurHanlder}
                    onSubmit={handleSubmetNextAddressValue}
                    inputUiState={inputUiState}
                    buttonUiState={buttonUiState}
                    componentSize="large"
                    externalInputRef={externalInputRef}
                    inputOnClickHanlder={inputOnClickHanlder}
                    inputType="text"
                    subdivision={selectedSubdivision}
                />
            </div>
        </div>
    );
}

