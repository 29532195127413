import { getDialogTextIfWeKnow } from '../CustomizationSourceContent';
import { CustomizationSourceData } from '../customizationSource.types';

export const subscriptionCommentCustomizationFactory = ({ user }: CustomizationSourceData) => {
    if (!user) {
        return {
            text: '',
            isThisSource: false,
            case: 'subscription customization',
        };
    }

    const { lastCustomizationComment = '' } = user;
    return {
        text: getDialogTextIfWeKnow(lastCustomizationComment),
        isThisSource: Boolean(lastCustomizationComment),
        case: 'subscription customization',
    };
};
