import React, { useState, useMemo, useEffect } from 'react';
import { Portal } from 'react-portal';
import { gql, useQuery } from '@apollo/client';
import { string, func } from 'prop-types';

import { DeletedAccountDialog } from 'app/components/Authorization/DeletedAccountDialog';

/**
 * @description Диалог удаления аккаунта. Вызывается только в SPA
*/
export const DeletedAccountDialogWithAppContext = React.memo((props) => {
    const { pageContext, onUserQuit } = props;
    const [userErrorType, setUserErrorType] = useState(null);
    const isAppsWithDialod = useMemo(() => ['menu-app'].includes(pageContext), [pageContext]);
    const { error: userError } = useQuery(gql`
        query DELETED_USER {
            user {
                id
            }
        }
    `, {
        context: {
            message: 'app:DeletedAccountDialogWithAppContext',
        },
    });

    useEffect(() => {
        if (userError && userError.message) {
            const deletedErrorMessage = 'Account has been removed';
            if (userError.message.includes(deletedErrorMessage)) {
                setUserErrorType('ACCOUNT_DELETED');
                return;
            }
        }
        setUserErrorType(null);
    }, [userError]);

    if (!isAppsWithDialod) {
        return null;
    }

    return (
        <Portal>
            <DeletedAccountDialog
                isOpen={userErrorType === 'ACCOUNT_DELETED'}
                onUserQuit={onUserQuit}
            />
        </Portal>
    );
});

DeletedAccountDialogWithAppContext.propTypes = {
    pageContext: string.isRequired,
    onUserQuit: func.isRequired,
};
