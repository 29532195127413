import React from 'react';
import PropTypes from 'prop-types';

import ClampLines from 'app/components/ClampLines';

import { locales } from './customizationCommentRoleButton.locales';

import './customization-comment-role-button.scss';


export default function CustomizationCommentRoleButton(props) {
    const {
        clampDelay = null,
        commentValue = null,
        onClick,
        locale,
    } = props;

    const textButtonStyle = {
        animationDelay: `${clampDelay}ms`,
    };

    // Locale content data
    const { buttonText, buttonDesriptionTwo } = locales[locale];

    return (
        <div>
            {commentValue ? (
                <button
                    styleName="button-text"
                    style={textButtonStyle}
                    onClick={onClick}
                    type="button"
                >
                    <span styleName="decorations">
                        <ClampLines
                            text={commentValue}
                            linesCount={2}
                            delay={clampDelay}
                        />
                    </span>
                </button>
            ) : (
                <>
                    <button
                        styleName="empty-comment-button"
                        type="button"
                        onClick={onClick}
                    >
                        {buttonText}
                    </button>
                    <p styleName="empty-comment-description">
                        {buttonDesriptionTwo}
                    </p>
                </>
            )}
        </div>
    );
}


CustomizationCommentRoleButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    commentValue: PropTypes.string,
    clampDelay: PropTypes.number,
};

