/* LIBS */
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

/* COMPONENTS */
import { BackButton } from 'app/components/ui/BackButton/BackButton';
import { CloseButton } from 'app/components/ui/CloseButton/CloseButton';
import { UIButton, UIButtonBottomArea } from 'app/components/ui';

/* CONTEXTS */
import { CheckoutStoreContext } from '../../store/checkoutStore.context';

/* UTILS */
import { checkIsButtonDesabledOnStepAddress } from './StepLayout.utils';

/* STYLES */
import './stepLayout.scss';


const StepLayoutWrapper = (props) => {
    const {
        handleCloseCurrentStep,
        handleCloseAllSteps,
        handleOpenNextStep,
        title,
        currentStepNumber,
        children,
        nextStepButtonContent,
        isNextStepButtonVisible,
        isNextStepButtonDisabled,
        fillContent,
        isNextStepLoading,
    } = props;

    const {
        checkoutState: {
            checkoutTypeState,
            dateAndTimeState,
        },
    } = useContext(CheckoutStoreContext);

    /* STYLES --------------------------------------------------------------- */
    const contentClasses = cn({
        'step-layout__content': true,
        'step-layout__content__fill-content': fillContent,
    });
    const titleClasses = cn({
        'step-layout__title': true,
        'step-layout__title-single': !currentStepNumber,
    });

    /* HANDLERS ------------------------------------------------------------- */
    const isCloseCurrentStepVisible = handleCloseCurrentStep;

    /* SELECTROS ------------------------------------------------------------ */
    const isBackButtonDisabled = useMemo(() => {
        const { isDateTimePopupOpenned } = dateAndTimeState;
        if (isDateTimePopupOpenned) {
            return false;
        }
        if (checkIsButtonDesabledOnStepAddress(checkoutTypeState)) {
            return false;
        }

        return isNextStepButtonDisabled;
    }, [checkoutTypeState, isNextStepButtonDisabled, dateAndTimeState]);

    /* RENDER --------------------------------------------------------------- */
    return (
        <div styleName="step-layout__container">
            <div styleName="step-layout__header">
                <div styleName="step-layout__back-button-container">
                    {isCloseCurrentStepVisible && (
                        <BackButton
                            onClick={handleCloseCurrentStep}
                            disabled={isBackButtonDisabled}
                        />
                    )}
                </div>
                <div styleName="step-layout__title-container">
                    <div styleName={titleClasses}>
                        {title}
                    </div>
                    {currentStepNumber && (
                        <div styleName="step-layout__subtitle">
                            шаг&nbsp;
                            {currentStepNumber}
                        </div>
                    )}
                </div>
                <div styleName="step-layout__back-button-container">
                    {handleCloseAllSteps && (
                        <CloseButton
                            onClick={handleCloseAllSteps}
                        />
                    )}
                </div>
            </div>
            <div styleName={contentClasses}>
                {children}
            </div>

            <div styleName="step-layout__footer">
                {isNextStepButtonVisible && (
                    <UIButtonBottomArea>
                        <UIButton
                            onClick={handleOpenNextStep}
                            disabled={isNextStepButtonDisabled}
                            disabledColor="primary-melon"
                            loading={isNextStepLoading}
                        >
                            {nextStepButtonContent || 'Далее'}
                        </UIButton>
                    </UIButtonBottomArea>
                )}
            </div>
        </div>
    );
};

export const StepLayout = (props) => {
    const {
        handleCloseCurrentStep = null,
        handleCloseAllSteps = null,
        handleOpenNextStep = () => { },
        isNextStepButtonVisible = false,
        isNextStepButtonDisabled = false,
        fillContent = true,
        isNextStepLoading = false,
        nextStepButtonContent = null,
        currentStepNumber = null,
    } = props;
    return (
        <StepLayoutWrapper
            {...props}
            handleCloseCurrentStep={handleCloseCurrentStep}
            handleCloseAllSteps={handleCloseAllSteps}
            handleOpenNextStep={handleOpenNextStep}
            isNextStepButtonVisible={isNextStepButtonVisible}
            isNextStepButtonDisabled={isNextStepButtonDisabled}
            fillContent={fillContent}
            isNextStepLoading={isNextStepLoading}
            nextStepButtonContent={nextStepButtonContent}
            currentStepNumber={currentStepNumber}
        />
    );
};


StepLayout.propTypes = {
    handleCloseCurrentStep: PropTypes.func,
    handleCloseAllSteps: PropTypes.func,
    handleOpenNextStep: PropTypes.func,

    isNextStepButtonVisible: PropTypes.bool,
    isNextStepButtonDisabled: PropTypes.bool,
    title: PropTypes.string.isRequired,
    currentStepNumber: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    nextStepButtonContent: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    fillContent: PropTypes.bool,
    isNextStepLoading: PropTypes.bool,
};
