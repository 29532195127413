import { gql } from '@apollo/client';

export const GET_SUBSCRIPTION_TYPE = gql`
    query getGubscriptionType @client {
        subscriptionType
    }
`;

// export const SET_SUBSCRIPTION_TYPE = gql`
//     mutation setSubscriptionType_DONE($type: String!) {
//         setSubscriptionType_DONE(type: $type) @client
//     }
// `;
