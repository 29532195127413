/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

export const locales = {
    ru: {
        // Render FC
        text: <>при подписке <br /> на все блюда в меню</>,
        info: 'Что такое подписка?',

    },
    en: {
        // Render FC
        text: <>For all dishes in menu <br /> with a weekly subscription</>,
        info: 'What is a subscription',
    },
};
