import React from 'react';
import cn from 'classnames';

import css from './ui-radio-icon-satatic.module.scss';


type UIRadioIconProps = {
    checked?: boolean,
}

export function UIRadioIconStatic({ checked = false }: UIRadioIconProps) {
    const classes = cn({
        [css.uiRadioIconStatic]: true,
        [css.checked]: checked,
    });

    return (
        <div id="ui-radion-button-static" className={classes} />
    );
}
