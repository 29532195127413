import React from 'react';
import PropTypes from 'prop-types';

import { UIParagraph } from 'app/components/ui';

import { BasketCheckPrice } from './BasketCheckPrice';
import { locales } from './basket-check.locales';

import './basket-check-rows.scss';


export function AppliedDiscountAmount(props) {
    const {
        basket: { totals: { applied_discount_amount: discountAmount } },
        locale,
    } = props;

    return (
        <div styleName="basket-check__row">
            <div>
                <UIParagraph level="4.2" styleName="basket-check__row-text">
                    {locales[locale].appliedDiscount}
                </UIParagraph>
            </div>
            <div>
                <BasketCheckPrice price={discountAmount} />
            </div>
        </div>
    );
}

AppliedDiscountAmount.propTypes = {
    basket: PropTypes.shape({
        totals: PropTypes.shape({
            applied_discount_amount: PropTypes.number.isRequired,
        }).isRequired,
    }).isRequired,
    locale: PropTypes.string.isRequired,
};


export function SubscriptionAppliedDiscountRow(props) {
    const {
        basket: { totals: { discount_amount: discountAmount } },
        locale,
    } = props;

    return (
        <div styleName="basket-check__row">
            <div>
                <UIParagraph level="4.2" styleName="basket-check__row-text">
                    {locales[locale].subscriptionDiscount}
                </UIParagraph>
            </div>
            <div>
                <BasketCheckPrice price={discountAmount} />
            </div>
        </div>
    );
}

SubscriptionAppliedDiscountRow.propTypes = {
    basket: PropTypes.shape({
        totals: PropTypes.shape({
            discount_amount: PropTypes.number.isRequired,
        }).isRequired,
    }).isRequired,
    locale: PropTypes.string.isRequired,
};

export function PromocodeAppliedDiscountRow(props) {
    const {
        basket: { totals: { promocode_amount: promocodeAmount } },
        locale,
    } = props;

    return (
        <div styleName="basket-check__row">
            <div>
                <UIParagraph level="4.2" styleName="basket-check__row-text">
                    {locales[locale].promocodeDiscount}
                </UIParagraph>
            </div>
            <div>
                <BasketCheckPrice price={promocodeAmount > 0 ? `-${promocodeAmount}` : 0} />
            </div>
        </div>
    );
}

PromocodeAppliedDiscountRow.propTypes = {
    basket: PropTypes.shape({
        totals: PropTypes.shape({
            promocode_amount: PropTypes.number.isRequired,
        }).isRequired,
    }).isRequired,
    locale: PropTypes.string.isRequired,
};
