import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import pluralize from 'app/utils/pluralize';
import { isDesktop } from 'app/utils/resolution';

import { withLocaleContext } from 'app/containers/LocaleProvider';

const pluralizeMobile = {
    pluralizeMinutesRu: (minutes) => pluralize(minutes, ['мин', 'мин', 'мин']),
    pluralizeMinutesEn: (minutes) => pluralize(minutes, ['min', 'minu', 'min']),
};

const pluralizeDesktop = {
    pluralizeMinutesRu: (minutes) => pluralize(minutes, ['минута', 'минуты', 'минут']),
    pluralizeMinutesEn: (minutes) => pluralize(minutes, ['minute', 'minutes', 'minutes']),
};


const getMinutesText = (seconds, locale) => {
    const minutes = Math.ceil(seconds / 60);
    const pluralizeRecord = isDesktop() ? pluralizeDesktop : pluralizeMobile;
    const pluralizeMinutes = locale === 'en' ? pluralizeRecord.pluralizeMinutesEn : pluralizeRecord.pluralizeMinutesRu;
    return `${minutes} ${pluralizeMinutes(minutes)}`;
};


const Timer = ({
    localeContext,
    timerSeconds,
    onTimerElapsed = () => { },
    onTimerTick,
}) => {
    const { locale } = localeContext;
    const timerText = timerSeconds > 60 ? getMinutesText(timerSeconds, locale) : timerSeconds;
    const currentTimerText = isDesktop() ? `(${timerText})` : `/ ${timerText}`;

    const tick = useCallback(
        (interval) => {
            if (timerSeconds === 0) {
                clearInterval(interval);
                onTimerElapsed();
            } else {
                onTimerTick();
            }
        }, [timerSeconds, onTimerElapsed, onTimerTick]);

    useEffect(() => {
        const interval = setInterval(() => {
            tick(interval);
        }, 1000);

        return () => clearInterval(interval);
    }, [tick]);

    return (
        <span>
            {currentTimerText}
        </span>
    );
};

export default withLocaleContext(Timer);

Timer.propTypes = {
    localeContext: PropTypes.shape({
        locale: PropTypes.string,
    }).isRequired,

    timerSeconds: PropTypes.number.isRequired,
    onTimerElapsed: PropTypes.func,
    onTimerTick: PropTypes.func.isRequired,
};

Timer.propTypes = {
    localeContext: PropTypes.shape({
        locale: PropTypes.string,
    }).isRequired,

    timerSeconds: PropTypes.number.isRequired,
    onTimerElapsed: PropTypes.func,
    onTimerTick: PropTypes.func.isRequired,
};
