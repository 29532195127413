import { gql } from '@apollo/client';
import { Tag } from './fragments/tag.fragment';

export default gql`
   query MenuFilter {
        menuFilter {
            periods {
                start
                end
                isVisibleInFilter
                categories {
                    id
                    title
                }
                tags {
                    ...tagFragment
                    categoryId: category_id
                    categoryName: category_name
                }
            }
        }
    }
    ${Tag}
`;
