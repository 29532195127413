/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'lodash/flowRight';

import { analyticService } from 'global/services';

import { withLocaleContext } from 'app/containers/LocaleProvider';


import { CATEGORIES_WITH_PORTION_ALIAS } from 'app/const/categories';

import DishItemSmall from 'app/components/DishCardBasket/DishItemSmall';
import DishItemTrial from 'app/components/DishCardBasket/DishItemTrial';

import { isDesktop } from 'app/utils/resolution';
import './dishes.scss';


// TODO: Убрать вызов в десктопе
const getDishItemComponentByType = (basketStyleModifier) => {
    switch (true) {
        case ([
            'PREVIEW_SET',
        ].includes(basketStyleModifier.toUpperCase())):
            return DishItemTrial;
        default:
            return DishItemSmall;
    }
};


class Dishes extends React.PureComponent {
    state = {
        portionSelectIsExpanded: false,
    };

    listRef = React.createRef();

    /* Handlers ============================================================= */
    handleTogglePortionSelect = (isExpanded) => {
        this.setState({ portionSelectIsExpanded: isExpanded });
    };

    // Render ==================================================================

    renderDish(dishItem, isLastItem) {
        // FRNT-2386 хардкод для запрета редактирования черного бургера в промо
        const isBlackBurger = dishItem.dish_id === '1814';
        const isEditable = !(isBlackBurger
            && (dishItem.promoDiscountPrice !== dishItem.price)
            && (dishItem.promoDiscountPrice === 0));

        const {
            onClickMobilePortions,
            isSubscriptionActivated,
            isEditEnabled,
            onClickMobileDishDetails,
            onRemoveClick,
            scrollbarsRef,
            onChangePortion,
            openDishDetails,
            sectionId,
            isOneItemInCategory,
            localeContext: { locale },
            basketDishObserver,
            preparedBasketAreas: { basketStyleModifier },
            showPaperRecipeRow,
            showTableSetting,
            isUserInBR1131ABTest,
        } = this.props;


        const { dish: { categoryId, umAlias: umAliasProp }, basketUiState } = dishItem;

        const umAlias = umAliasProp || ['г', 'г', 'г', 'г'];

        const useCustomPortionAlias = umAlias[0] !== 'г';
        const isDishWithPortionAlias = CATEGORIES_WITH_PORTION_ALIAS.includes(String(categoryId));
        const portionAlias = useCustomPortionAlias && isDishWithPortionAlias ? umAlias : null;

        const commonProps = {
            onClickMobilePortions,
            portionAlias,
            dishItem,
            isEditEnabled,
            isEditable,
            isSubscriptionActivated,
            onRemoveClick,
            onChangePortion,
            onTogglePortionSelect: this.handleTogglePortionSelect,
            onClickMobileDishDetails,
            openDishDetails,
            scrollbarsRef,
            isLastItem,
            sectionId,
            isOneItemInCategory,
            locale,
            basketDishObserver,
            basketStyleModifier,
            showPaperRecipeRow,
            showTableSetting,
            isUserInBR1131ABTest,
        };

        const dishItemWrapClasses = classNames({
            'basket-dish-item__wrap': true,
            [basketUiState]: true,
        });

        const DishComponent = getDishItemComponentByType(basketStyleModifier);

        return (
            <div styleName={dishItemWrapClasses} data-test-id="basket-dish-item__wrap">
                <DishComponent {...commonProps} />
            </div>
        );
    }

    renderDishes() {
        const { items } = this.props;
        return items.map((item, index) => {
            const isLastItem = items.length - 1 === index;

            return (
                <React.Fragment key={item.dish.id}>
                    {this.renderDish(item, isLastItem)}
                </React.Fragment>
            );
        });
    }

    render() {
        const {
            showAddMoreButton,
            preparedBasketAreas: {
                basketStyleModifier,
                list: {
                    titleType,
                },
            },
        } = this.props;

        // TODO
        const { portionSelectIsExpanded } = this.state;

        const containerClasses = classNames({
            'basket__dishes-type-list-container': true,
            'is-on-front': portionSelectIsExpanded,
            [basketStyleModifier]: basketStyleModifier,
        });
        const listStyles = classNames({
            'basket__dishes-type-list': true,
            'has-add-more-button': showAddMoreButton,
            'small-top-indent': titleType === 'set',
        });

        return (
            <div
                ref={this.listRef}
                styleName={containerClasses}
            >
                {/* animations on mobile are into DishItem component for responsiveness */}
                <ul styleName={listStyles}>
                    {this.renderDishes()}
                </ul>
            </div>
        );
    }
}

Dishes.propTypes = {
    isMobileResolution: PropTypes.bool,
    preparedBasketAreas: PropTypes.shape({
        basketStyleModifier: PropTypes.string.isRequired,
        list: PropTypes.shape({
            titleType: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,

    items: PropTypes.arrayOf(PropTypes.shape({
        price: PropTypes.number.isRequired,
        portions: PropTypes.number.isRequired,
        dish: PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            caption: PropTypes.string,
            weight: PropTypes.number.isRequired,
            portions: PropTypes.arrayOf(PropTypes.number).isRequired,
            previewImage: PropTypes.string.isRequired,
        }).isRequired,
    })).isRequired,
    sectionId: PropTypes.string.isRequired,
    isEditEnabled: PropTypes.bool,
    isSubscriptionActivated: PropTypes.bool.isRequired,

    scrollbarsRef: PropTypes.shape({
        current: PropTypes.shape({}),
    }).isRequired,

    showAddMoreButton: PropTypes.bool,

    openDishDetails: PropTypes.func,
    onRemoveClick: PropTypes.func.isRequired,
    onChangePortion: PropTypes.func.isRequired,
    onClickMobileDishDetails: PropTypes.func,

    isOneItemInCategory: PropTypes.bool,

    basketDishObserver: PropTypes.shape({ observe: PropTypes.func }).isRequired,

    onClickMobilePortions: PropTypes.func.isRequired,
    localeContext: PropTypes.shape({
        locale: PropTypes.string,
    }).isRequired,
    showPaperRecipeRow: PropTypes.bool,
};

Dishes.defaultProps = {
    isMobileResolution: false,
    isEditEnabled: true,
    showAddMoreButton: false,
    onClickMobileDishDetails: () => { },
    openDishDetails: () => { },
    isOneItemInCategory: false,
    showPaperRecipeRow: false,
};


const options = {
    rootMargin: '0px',
    threshold: 0.50,
};

const BasketDishesWithObsContainer = (props) => {
    const {
        items: basketDishes,
        subscriptionType,

        isEditEnabled = true,
        showAddMoreButton = false,
        onClickMobileDishDetails =() => { },
        openDishDetails = () => { },
        isOneItemInCategory = false,
    } = props;

    const isMobileResolution = !isDesktop();

    const basketDishObserver = new IntersectionObserver((entries) => {
        // const uri = queryString.parse(location.search);
        // const source = (location.pathname === '/menu/') && Boolean(uri.st) ? 'collections' : 'menu';
        entries.forEach((e) => {
            if (e.isIntersecting) {
                const intersectingDish = basketDishes
                    .map((e) => e.dish)
                    .filter((dish) => dish.id === e.target.dataset.id);

                analyticService.push({
                    eventName: 'Product_Impression',
                    dish: intersectingDish,
                    subscriptionType,
                    source_name: 'basket',
                });
            }
        });
    }, options);
    return (
        <Dishes
            {...props}
            basketDishObserver={basketDishObserver}
            isMobileResolution={isMobileResolution}
            isEditEnabled={isEditEnabled}
            showAddMoreButton={showAddMoreButton}
            onClickMobileDishDetails={onClickMobileDishDetails}
            openDishDetails={openDishDetails}
            isOneItemInCategory={isOneItemInCategory}
        />
    );
};

export default compose(withLocaleContext)(BasketDishesWithObsContainer);
