/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable global-require */
import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import compose from 'lodash/flowRight';
import { UIRub, UIBadge } from 'app/components/ui';
import { withLocaleContext } from 'app/containers/LocaleProvider';
import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import { sliderCardContentMap } from './V1CardsContent.record';
import './best-sets-mobile-slider.scss';

// NOTE Для совместимости
export { getStaticLinkOptions } from './V1CardsContent.record';

const V1SliderCard = (props) => {
    const {
        id,
        handleClick,
        locale,
    } = props;

    const { data } = useMemo(() => sliderCardContentMap.get(id), [id]);

    return (
        <li
            styleName="best-sets-slider__list-item"
        >
            <button
                type="button"
                styleName="best-sets-slider__item-button"
                onClick={(e) => handleClick(e, id)}
            >
                <div styleName="best-sets-slider__item-root">
                    <div styleName="best-sets-slider__item-image-container">
                        <img
                            styleName="best-sets-slider__item-image"
                            src={data.imageSrc}
                            alt=""
                        />

                        <div styleName="best-sets-slider__item-badge-container">
                            {data.badge && <UIBadge {...data.badge} />}
                        </div>
                    </div>

                    <div styleName="best-sets-slider__item-desc-container">
                        <p styleName="best-sets-slider__item-title">
                            {data.title[locale]}
                        </p>
                        <p styleName="best-sets-slider__item-description">
                            {data.subtitle[locale]}
                        </p>

                        {!!data.discountPrice && (
                            <p styleName="best-sets-slider__item-price">
                                <span styleName="num">{data.discountPrice}</span>
                                <UIRub marginLeft="2" fontWeight="700" />
                            </p>
                        )}
                        {!!data.linkText && (
                            <p styleName="best-sets-slider__item-link-text">{data.linkText[locale]}</p>
                        )}
                    </div>
                </div>
            </button>
        </li>
    );
};

function BestSetsMobileSlider({
    localeContext: { locale },
    cardsChain = [],
    handleClickToSliderCard,
    isMenuEmpty = false,
}) {
    const sliderContainerStyles = classNames({
        'best-sets-slider__list-container': true,
        'best-sets-slider--padding-on-empty-dishes': isMenuEmpty,
    });

    return (
        <div styleName="best-sets-slider__root">
            <div styleName={sliderContainerStyles}>
                <ul styleName="best-sets-slider__list">
                    {cardsChain.map((id) => (
                        <V1SliderCard
                            id={id}
                            handleClick={handleClickToSliderCard}
                            key={id}
                            locale={locale}
                        />
                    ))
                    }
                </ul>
            </div>
        </div>
    );
}

export default compose(
    withLocaleContext,
)(BestSetsMobileSlider);

BestSetsMobileSlider.propTypes = {
    localeContext: PropTypes.shape({
        locale: PropTypes.string,
    }).isRequired,
    handleClickToSliderCard: PropTypes.func.isRequired,
    isMenuEmpty: PropTypes.bool,
    // cardsChain: PropTypes.arrayOf,
};
