import React from 'react';
import cn from 'classnames';
import css from './UpperTextShield.module.scss';

// NEW YEAR 2025
// 10 минут и готово
import tenminutesS from './NY/tenminutes/tenMinutes-s.img.svg';
import tenminutesM from './NY/tenminutes/tenMinutes-m.img.svg';
import tenminutesL from './NY/tenminutes/tenMinutes-l.img.svg';

// 15 минут и готово
import fifteenminutesS from './NY/fifteenminutes/fifteenMinutes-s.img.svg';
import fifteenminutesM from './NY/fifteenminutes/fifteenMinutes-m.img.svg';
import fifteenminutesL from './NY/fifteenminutes/fifteenMinutes-l.img.svg';

// 20 минут и готово
import twentyminutesS from './NY/twentyminutes/twentyMinutes-s.img.svg';
import twentyminutesM from './NY/twentyminutes/twentyMinutes-m.img.svg';
import twentyminutesL from './NY/twentyminutes/twentyMinutes-l.img.svg';

// Ужин
import dinnerS from './NY/dinner/dinner-s.img.svg';
import dinnerM from './NY/dinner/dinner-m.img.svg';
import dinnerL from './NY/dinner/dinner-l.img.svg';

// Быстро
import quickS from './NY/quick/quick-s.img.svg';
import quickM from './NY/quick/quick-m.img.svg';
import quickL from './NY/quick/quick-l.img.svg';

// Любимое
import favoriteS from './NY/favorite/favorite-s.img.svg';
import favoriteM from './NY/favorite/favorite-m.img.svg';
import favoriteL from './NY/favorite/favorite-l.img.svg';

// Новинка
import newS from './NY/new/new-s.img.svg';
import newM from './NY/new/new-m.img.svg';
import newL from './NY/new/new-l.img.svg';

// Можно в подарок
import giftS from './NY/gift/gift-s.img.svg';
import giftM from './NY/gift/gift-m.img.svg';
import giftL from './NY/gift/gift-l.img.svg';

export type TShieldUpperName = 'cusserole' | 'cutlet' | 'pasta' | 'pie' | 'pizza' | 'puree'
    | 'kozel' | 'new' | 'favorite' | 'tenminutes' | 'fifteenminutes' | 'twentyminutes' | 'dinner' | 'quick' | 'gift'

const defaultPath = {
    s: '', m: '', l: '',
};


const shieldMap = new Map<TShieldUpperName, typeof defaultPath>([
    // NY 2025
    ['tenminutes', { s: tenminutesS, m: tenminutesM, l: tenminutesL }],
    ['fifteenminutes', { s: fifteenminutesS, m: fifteenminutesM, l: fifteenminutesL }],
    ['twentyminutes', { s: twentyminutesS, m: twentyminutesM, l: twentyminutesL }],
    ['new', { s: newS, m: newM, l: newL }],
    ['favorite', { s: favoriteS, m: favoriteM, l: favoriteL }],
    ['dinner', { s: dinnerS, m: dinnerM, l: dinnerL }],
    ['quick', { s: quickS, m: quickM, l: quickL }],
    ['gift', { s: giftS, m: giftM, l: giftL }],
]);

interface IUpperShieldProps {
    name: TShieldUpperName,
}

export const UpperTextShield = (props: IUpperShieldProps) => {
    const { name } = props;

    const path = shieldMap.get(name) || defaultPath;

    if (!path) {
        return null;
    }

    return (
        <div
            className={cn(css.upperTextShieldRoot, css[name])}
        >
            <div className={css.s}>
                <img className={css.upperTextShieldImg} src={path.s} alt={name} />
            </div>
            <div className={css.m}>
                <img className={css.upperTextShieldImg} src={path.m} alt={name} />
            </div>
            <div className={css.l}>
                <img className={css.upperTextShieldImg} src={path.l} alt={name} />
            </div>
        </div>
    );
};
