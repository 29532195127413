/* eslint-disable react/prop-types */
import React, { useMemo, useReducer } from 'react';
import { combineReducers } from 'app/utils/store.utils';
import { dateAndTimeReducer, dateAndTimeInitialState } from './slices/dateAndTime.slice';
import { checkoutTypeReducer, checkoutTypeInitialState } from './slices/checkoutType.slice';

/* CONTEXT */

const CheckoutStoreContext = React.createContext({
    state: {},
    dispatch: () => {},
});

/* STORE */

const initialState = {
    dateAndTimeState: dateAndTimeInitialState,
    checkoutTypeState: checkoutTypeInitialState,
};

const rootReducer = combineReducers({
    dateAndTimeState: dateAndTimeReducer,
    checkoutTypeState: checkoutTypeReducer,
});

/* COMPONENT */

const CheckoutStoreContainer = React.memo((props) => {
    const { children } = props;
    const [checkoutState, dispatch] = useReducer(rootReducer, initialState);

    const contextValue = useMemo(
        () => ({ checkoutState, dispatch }),
        [checkoutState, dispatch],
    );

    return (
        <CheckoutStoreContext.Provider value={contextValue}>
            {children}
        </CheckoutStoreContext.Provider>
    );
});

export {
    CheckoutStoreContainer,
    CheckoutStoreContext,
};
