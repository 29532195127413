import { onError } from '@apollo/client/link/error';
import { errorService } from 'global/services';

function errorLinkHandler({
    graphQLErrors, networkError, operation, forward,
}) {
    const { operationName } = operation;

    const errorMessage = `
    Error during operation: ${operationName}
    ──────────────────────────────
    Ошибка во время операции: ${operationName}
    ──────────────────────────────
    `;

    if (graphQLErrors) {
        // graphQLErrors.forEach(element => )
        graphQLErrors.forEach(
            ({ message = '' }) => {
                console.error(errorMessage, new Error(message));
            },
        );
        return forward(operation);
    }

    if (networkError) {
        errorService.log({
            text: `${networkError} ${operationName}`,
            source: 'network',
            Query: operationName,
        });

        return forward(operation);
    }

    return forward(operation);
}

/**
 * @description Отлавливает серверерные о ошибки запросов
 * @doc https://www.apollographql.com/docs/react/api/link/apollo-link-error/
 */
export const errorLink = onError(errorLinkHandler);
