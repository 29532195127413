import React from 'react';
import PropTypes from 'prop-types';
import { analyticService } from 'global/services';

import {
    STEP_PHONE,
    STEP_ADDRESS,
    STEP_DATETIME,
    STEP_PAYMENT,
    EVENTS,
} from '../../../stepByStepCheckoutConst';

import arrowImg from './row-arrow.img.svg';
import '../../single-step-delivery-info-area.scss';



export function SingleStepDeliveryInfoRow(props) {
    const {
        step,
        children,
        handleOpenStep,
    } = props;

    return (
        <li styleName="single-step-checkout__row">
            <button
                styleName="single-step-checkout__row-edit"
                type="button"
                data-step={step}
                onClick={() => {
                    handleOpenStep(step);
                    analyticService.push({
                        eventName: EVENTS[step].open,
                    });
                }}
            >
                <div styleName="single-step-checkout__row--edit-content">
                    {children}
                </div>
                <div styleName="single-step-checkout__row--edit-arrow">
                    <img src={arrowImg} alt="arrow" />
                </div>
            </button>
        </li>
    );
}

SingleStepDeliveryInfoRow.propTypes = {
    step: PropTypes.oneOf([
        STEP_PHONE,
        STEP_ADDRESS,
        STEP_DATETIME,
        STEP_PAYMENT,
    ]).isRequired,
    children: PropTypes.shape({}).isRequired,
    handleOpenStep: PropTypes.func.isRequired,
};
