import React from 'react';
import TextMask from 'react-text-mask';
import cn from 'classnames';

import ConfirmedIcon from '../input/buttonIcons/ConfirmedIcon.component.svg';
import ErrorIcon from '../input/buttonIcons/ErrorIcon.component.svg';

import css from './inputPhone.module.scss';
import inputCss from '../input/newUiinput.module.scss';


export const DEFAULT_PHONE_MASK = ['+', '7', ' ', /[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
const LONG_PHONE_MASK = [...DEFAULT_PHONE_MASK, /\d/];


type UIInputPhoneProps = {
    value: string,
    placeholder?: string,
    uiState?: string,
    isPhoneConfirmed?: boolean,
    autoFocus?: boolean,
    disabled?: boolean,
    isPhoneError?: boolean,
    responsive?: boolean,
    showMask?: boolean,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    onBlur?: () => void,
    onClick?: () => void,
    onFocus?: () => void,
}


export function UIInputPhone({
    value,
    placeholder = '',
    uiState = '',
    isPhoneConfirmed = false,
    autoFocus = false,
    onChange,
    disabled = false,
    isPhoneError = false,
    responsive = false,
    showMask = false,
    /**
     * Если передавать в TextMask пустые функции вместо undefined, то в нем ломается
     * удаление символов-разделителей (например, пробел или дефис) при нажатии на backspace
    */
    onFocus = undefined,
    onBlur = undefined,
    onClick = undefined,
}: UIInputPhoneProps) {
    const phoneLength = 12; // +7(XXX)XXX-XX-XX
    const inputMask = value.length < phoneLength ? LONG_PHONE_MASK : DEFAULT_PHONE_MASK;

    const rootClasses = cn({
        [css.phoneInputContainer]: true,
        [css.focusWithin]: uiState === 'focus-within',
        [css.inputError]: uiState === 'input-error',
        [css.responsive]: responsive,
    });

    const inputClasses = cn({
        [css.phoneInput]: true,
        [css.mask]: true,
        [css.withPlaceholder]: Boolean(placeholder),
    });

    const iconClasses = cn(
        inputCss.uiKitInputButtonIconClasses, 'large', css.uiKitInputButtonIcon,
    );

    return (
        <div>
            <label htmlFor="telephone" className={rootClasses}>
                {placeholder && (
                    <div className={css.phoneInputPlaceholder}>
                        {placeholder}
                    </div>
                )}
                <TextMask
                    type="tel"
                    className={inputClasses}
                    id="telephone"
                    name="telephone"
                    mask={inputMask}
                    showMask={showMask}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    onClick={onClick}
                />
                {isPhoneConfirmed && (
                    <div className={css.phoneInputIconContainer}>
                        <ConfirmedIcon className={iconClasses} />
                    </div>
                )}
                {isPhoneError && (
                    <div className={css.phoneInputIconContainer}>
                        <ErrorIcon className={iconClasses} />
                    </div>
                )}
            </label>
        </div>
    );
}
