import React, { useCallback, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
// @ts-ignore
import cookies from 'js-cookie';

import { STORE_ENV_DATA } from 'app/graphql/network/envDataMutation';
import {
    abTestDataContext,
    NEW_TRIAL_UPPER_BLOCKS,
} from 'app/containers/contexts/abTest.context';
import { selectedPeriodVar } from 'app/apollo/reaction';

const mainBannerRootNode: HTMLElement | null = document.querySelector('#trialMainBlockRoot');
const templateABTestValue = mainBannerRootNode?.dataset.ab_test_value;
const newTrialABTestValueFromCookie = cookies.get('NEW_TRIAL_UPPER_BLOCKS');

// ХУК ВЫПОЛНЯЕТ ОДИН ИЗ ДВУХ ХЭНДЛЕРОВ В ЗАВИСИМОСТИ ОТ ТОГО, НА КАКУЮ СТРАНИЦУ ЗАШЕЛ ПОЛЬЗОВАТЕЛЬ

export const useTrialLPDefine = (realm: 'trial' | 'rest') => {
    const { fetchABTestValue, setABTestValue } = useContext<any>(abTestDataContext);
    const [storeEnvData] = useMutation(STORE_ENV_DATA);
    const period = selectedPeriodVar();

    const handleCheckAndSetTest = useCallback(
        () => {
            if (!setABTestValue) return;
            const isABTestValueFromTemplateToSet = newTrialABTestValueFromCookie === undefined
                && templateABTestValue !== undefined;
            if (isABTestValueFromTemplateToSet) {
                const variables = {
                    abGroup: `${NEW_TRIAL_UPPER_BLOCKS}__${templateABTestValue}`,
                    period,
                };
                storeEnvData({ variables }).then(
                    () => {
                        setABTestValue(NEW_TRIAL_UPPER_BLOCKS, templateABTestValue);
                        cookies.set('NEW_TRIAL_UPPER_BLOCKS', templateABTestValue);
                    },
                );
            }
        },
        [setABTestValue, storeEnvData, period],
    );

    const handleCheckAndTestValue = useCallback(
        () => {
            if (!fetchABTestValue) return;
            const isABTestNotFetched = newTrialABTestValueFromCookie === undefined;
            if (isABTestNotFetched) {
                fetchABTestValue(NEW_TRIAL_UPPER_BLOCKS).then((testValue: string) => {
                    cookies.set('NEW_TRIAL_UPPER_BLOCKS', testValue);
                });
            }
        },
        [fetchABTestValue],
    );

    const getActionToExecute = useCallback(
        () => {
            const actionsByRealm = {
                trial: handleCheckAndSetTest,
                rest: handleCheckAndTestValue,
            };
            return actionsByRealm[realm];
        },
        [handleCheckAndSetTest, handleCheckAndTestValue, realm],
    );


    useEffect(
        () => {
            const trialAbTestHandler = getActionToExecute();
            trialAbTestHandler();
        },
        [getActionToExecute],
    );
};
