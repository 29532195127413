import React from 'react';
import PropTypes from 'prop-types';
import './loading-basket.scss';

const LoadingBasket = (props) => {
    const { children } = props;
    return (
        <div styleName="loading-basket">
            <div styleName="loading-basket-title">{children}</div>
        </div>
    );
};

LoadingBasket.propTypes = {
    children: PropTypes.node.isRequired,
};

export default LoadingBasket;
