import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { isDesktop } from 'app/utils/resolution';

import { UIHeading } from 'app/components/ui';
import { SelectionsMobileSlider } from 'app/components/banners/MenuSelectionsMobileSlider/MenuSelectionsMobileSlider';
import { BasketCustomizationTags } from './components/BasketCustomizationTags';
import BasketNewUpsell from './BR1131/components/BasketNewUpsell';

import './basket.scss';


const BasketMenuSelectionMoblieSlider = (props) => {
    const {
        preparedBasketAreas: {
            basketStyleModifier,
            promo: { header, backgroundType },
        },
    } = props;

    const rootClasses = cn({
        [basketStyleModifier]: true,
        'basket-menu-selections-root': true,
        'basket-menu-selections-root--white': backgroundType === 'white',
    });

    return (
        <div styleName={rootClasses}>
            {header !== 'off' && (
                <UIHeading level="3.2">
                    <span styleName="basket-menu-selections-title">
                        Добавить другие блюда?
                    </span>
                </UIHeading>
            )}
            <div styleName="basket-menu-selections-slider__list-container">
                <SelectionsMobileSlider realm="basket_realm" {...props} />
            </div>
        </div>
    );
};

BasketMenuSelectionMoblieSlider.propTypes = {
    closeHandler: PropTypes.func.isRequired,
    preparedBasketAreas: PropTypes.shape({
        basketStyleModifier: PropTypes.string.isRequired,
        promo: PropTypes.shape({
            header: PropTypes.oneOf([null, 'off']),
            backgroundType: PropTypes.oneOf([null, 'white']),
            isMenuSelectionsMobileSlider: PropTypes.bool.isRequired,
        }).isRequired,
    }).isRequired,
};

const BasketPromoArea = (props) => {
    const {
        closeHandler,
        preparedBasketAreas: {
            promo: {
                isCustomizationTags,
                isMenuSelectionsMobileSlider,
            },
        },
        preparedBasketAreas,
        handleSetIsCustomizationEdited,
    } = props;

    return (
        <>
            {/* DEPRECATED */}
            {/* {isMenuSelectionsMobileSlider && (
                <BasketMenuSelectionMoblieSlider
                    closeHandler={closeHandler}
                    preparedBasketAreas={preparedBasketAreas}
                />
            )} */}
            <BasketNewUpsell />
            {isCustomizationTags && (
                <BasketCustomizationTags
                    handleSetIsCustomizationEdited={handleSetIsCustomizationEdited}
                />
            )}
        </>
    );
};

BasketPromoArea.propTypes = {
    closeHandler: PropTypes.func.isRequired,
    preparedBasketAreas: PropTypes.shape({
        promo: PropTypes.shape({
            header: PropTypes.oneOf([null, 'off']),
            backgroundType: PropTypes.oneOf([null, 'white']),
            // isMenuSelectionsMobileSlider: PropTypes.bool.isRequired,
            isCustomizationTags: PropTypes.bool,
        }).isRequired,
    }).isRequired,
    handleSetIsCustomizationEdited: PropTypes.func.isRequired,
};


export default React.memo(BasketPromoArea);
