export const menuFilterField = {
    read(existing) {
        if (!existing || !existing.periods) {
            return existing;
        }

        // const filteredPeriods = existing.periods.filter((periodRef) => {
        //     const start = client.readField('start', periodRef);
        //     return start !== nyPeriod;
        // });

        const filteredPeriods = existing.periods;

        return {
            ___typename: 'menuSidebar',
            periods: filteredPeriods,
        };
    },
};
