/* eslint-disable camelcase */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import PropTypes from 'prop-types';
import ScrollLock from 'react-scrolllock';
import compose from 'lodash/flowRight';
import { withLocaleContext } from 'app/containers/LocaleProvider';
import { UIHeading, UIParagraph } from 'app/components/ui';
import cn from 'classnames';
import XBigIcon from './cross.svg';

import './address-info-popup.scss';

const localeRu = {
    main_title: <>Как оформить доставку?</>,
    rows: [
        {
            row_id: 1,
            row_title: <>
                Для оформления доставки&nbsp;заполните, пожалуйста,&nbsp;данные Вашего адреса.
            </>,
        },
    ],
};
const localeEn = {
    main_title: <>To create an order, please fill in the delivery address details.</>,
    rows: [
        {
            row_id: 1,
            row_title: <>
                To arrange delivery&nbsp;, please fill in your&nbsp address details.
            </>,
        },
    ],
};


const locales = {
    ru: localeRu,
    en: localeEn,
};

const AddressInfoPopup = React.memo((props) => {
    const {
        onClose,
        localeContext: { locale },
        transitionState,
        popUpUiState,
    } = props;

    const scrollableContentRef = React.createRef();

    const { main_title, rows } = locales[locale];

    const subinfoPopupRootClasses = cn({
        'address-info-popup': true,
        [popUpUiState]: true,
    });

    const popupHeaderClasses = cn({
        'popup-header': true,
        [popUpUiState]: true,
    });

    return (
        <div styleName={subinfoPopupRootClasses}>
            <div styleName={popupHeaderClasses}>
                <button
                    styleName="popup-header__close"
                    type="button"
                    onClick={onClose}
                    aria-label="Закрыть уведомление о форме адреса доставки"
                >
                    <XBigIcon />
                </button>
            </div>
            <div styleName="popup-content" ref={scrollableContentRef}>
                {/* MAIN TITLE */}
                <UIHeading
                    level="3.2"
                    styleName="popup-content__main-title"
                >
                    {main_title}
                </UIHeading>
                {/* ROWS  */}
                {rows.map((item) => (
                    <div styleName="popup-content-message" key={item.row_id}>
                        <h4 styleName="popup-content-message__title">
                            {item.row_title}
                        </h4>
                        <UIParagraph level="4.1" styleName="popup-content-message__text">
                            {item.row_text}
                        </UIParagraph>
                    </div>
                ))}
            </div>
            {transitionState === 'entered' && (
                <ScrollLock touchScrollTarget={scrollableContentRef.current} />
            )}
        </div>
    );
});

export default compose(withLocaleContext)(AddressInfoPopup);

AddressInfoPopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    localeContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,
    transitionState: PropTypes.string.isRequired,
    popUpUiState: PropTypes.string.isRequired,
};
