import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { UIButton, UIHeading } from 'app/components/ui';
import Responsive, { Desktop } from 'app/components/Responsive';

import { Transition } from 'react-transition-group';


import LINKS from 'app/const/links';
import CheckIcon2 from 'assets/svg/check-green2.component.svg';

import Link from 'app/components/Link';
import TelephoneInput from 'app/components/Form/Telephone';
import LoadingIcon from 'app/components/LoadingIcon';
import Plate from 'app/components/Plate';
import { UIInputPhone } from 'app/components/ui/InputPhone/InputPhone';


import '../authorization.scss';
import './enterPhoneMobile.scss';


const { OFFER_PAGE } = LINKS;


function EnterPhoneDesktop({
    title,
    disabled,
    telephone = null,
    telephoneValidation = null,
    animation = null,
    onSubmit,
    onEnterPhone,
    onChangePhone,
    onBlurPhone = () => {},
    needDisplayPlate = false,
    errorText = '',
    hasError = false,
}) {
    const mainContainer = classNames({
        'mobile-auth__content-container': true,
        'phone-input-screen': true,
        'need-display-plate': needDisplayPlate,
        blur: animation === 'to pincode',
    });

    const termsContainer = classNames({
        'mobile-auth__terms-conditions-text': true,
        blur: animation === 'to pincode',
    });

    /*
        TODO: попробовать объединить onEnterPhone и onSubmit
        исправить пропсы telephoneValidation и animation
    */

    return (
        <div styleName="mobile-auth__root-container">
            <div styleName={mainContainer}>
                <div styleName="mobile-auth__plate-container">
                    <Desktop>
                        <div styleName="thanks-plate">
                            <Plate size="compact" />
                        </div>
                    </Desktop>
                </div>

                <div styleName="mobile-auth__form-container">
                    <div styleName="mobile-auth__title-wrap">
                        <h2 styleName="mobile-auth__title">{title}</h2>
                    </div>

                    <div styleName="mobile-auth__telephone-input">
                        <form onSubmit={onSubmit}>
                            <TelephoneInput
                                value={telephone}
                                onEnter={onEnterPhone}
                                onChange={onChangePhone}
                                onBlur={onBlurPhone}
                                errorText={errorText}
                                hasError={hasError}
                                big
                                v303style
                            />
                            <div styleName="mobile-auth__input-icons">
                                {telephoneValidation === 'loading' && (
                                    <div styleName="mobile-auth__input-loading-icon">
                                        <LoadingIcon />
                                    </div>
                                )}
                                {telephoneValidation === 'correct' && (
                                    <div styleName="mobile-auth__input-correct-icon">
                                        <CheckIcon2 />
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>

                    <div styleName="mobile-auth__button--container phone-input-screen">
                        <UIButton
                            disabled={disabled || animation === 'to pincode'}
                            centerContent="Получить код"
                            onClick={onEnterPhone}
                            aria-label="Получить код"
                        />
                    </div>
                </div>
            </div>

            <div styleName={termsContainer}>
                Нажимая «Получить код», вы даете согласие на
                <br />
                <Link
                    href={OFFER_PAGE.hrefRu}
                    styleName="mobile-auth__text-link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    обработку своих персональных данных и принимаете условия пользовательского соглашения
                </Link>
            </div>
        </div>
    );
}


EnterPhoneDesktop.propTypes = {
    title: PropTypes.string.isRequired,
    telephone: PropTypes.string,
    telephoneValidation: PropTypes.string,
    animation: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    needDisplayPlate: PropTypes.bool,
    hasError: PropTypes.bool,
    errorText: PropTypes.string,

    onSubmit: PropTypes.func.isRequired,
    onEnterPhone: PropTypes.func.isRequired,
    onChangePhone: PropTypes.func.isRequired,
    onBlurPhone: PropTypes.func,
};

const EnterPhoneMobile = ({
    renderContext = 'default',
    title,
    onChangePhone,
    telephone = null,
    onSubmit,
    disabled,
    isPhoneConfirmed = false,
    autoFocus = true,
    buttonDisabledColor = null,
}) => {
    const [isUnmount, setIsUnmount] = useState(false);

    const handleSubmit = (event) => {
        setIsUnmount(true);
        onSubmit(event);
    };

    return (
        <Transition
            in={isUnmount}
            timeout={200}
        >
            {(transitionState) => {
                const termsContainer = classNames({
                    'auth-enter-phone': true,
                    [transitionState]: true,
                });

                return (
                    <div>
                        <div styleName={termsContainer}>
                            <form
                                styleName={`auth-enter-phone__form ${renderContext}`}
                                onSubmit={handleSubmit}
                            >
                                {renderContext === 'single-step-checkout'
                                    ? null
                                    : (
                                        <div styleName="auth-enter-phone-header">
                                            <UIHeading level="3.2">
                                                {title}
                                            </UIHeading>
                                        </div>
                                    )}
                                <div styleName="auth-enter-phone-input">
                                    <UIInputPhone
                                        value={telephone}
                                        onChange={onChangePhone}
                                        isPhoneConfirmed={isPhoneConfirmed}
                                        autoFocus={autoFocus}
                                    />
                                </div>
                                {!isPhoneConfirmed && (
                                    <div styleName="auth-enter-phone-button">
                                        <UIButton
                                            sizeType="responsive"
                                            onClick={handleSubmit}
                                            disabled={disabled}
                                            disabledColor={buttonDisabledColor}
                                            type="submit"
                                        >
                                            <div styleName="auth-enter-phone-button__text">
                                                Далее
                                            </div>
                                        </UIButton>
                                    </div>
                                )}
                            </form>
                            {!isPhoneConfirmed && (
                                <div styleName="auth-enter-phone-policy">
                                    Продолжая, вы даёте согласие
                                    <Link
                                        href={OFFER_PAGE.hrefRu}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <span styleName="auth-enter-phone-policy__link">
                                        &nbsp;на сбор, обработку и хранение персональных&nbsp;данных
                                        </span>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                );
            }}
        </Transition>
    );
};

EnterPhoneMobile.propTypes = {
    renderContext: PropTypes.oneOf([
        'single-step-checkout',
        'step-by-step-checkout',
        'default',
    ]),
    title: PropTypes.string.isRequired,
    telephone: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    isPhoneConfirmed: PropTypes.bool,
    autoFocus: PropTypes.bool,
    buttonDisabledColor: PropTypes.string,

    onSubmit: PropTypes.func.isRequired,
    onChangePhone: PropTypes.func.isRequired,
};


const forceMobileRenderContexts = [
    'step-by-step-checkout',
    'single-step-checkout',
];


// eslint-disable-next-line react/require-default-props
const EnterPhone = (props) => {
    const {
        renderContext = 'default',
    } = props;

    if (forceMobileRenderContexts.includes(renderContext)) {
        return <EnterPhoneMobile {...props} />;
    }

    return (
        <Responsive
            mobile={(<EnterPhoneMobile {...props} />)}
            desktop={(<EnterPhoneDesktop {...props} />)}
        />
    );
};

EnterPhone.propTypes = {
    renderContext: PropTypes.oneOf([
        'single-step-checkout',
        'step-by-step-checkout',
        'default',
    ]),
};

export default EnterPhone;
