import React from 'react';

import pluralize from 'app/utils/pluralize';
import colors from 'app/styles/variables/ui-colors';
import {
    locales as umLocales,
    PORTION_UM_ALIAS_TYPE,
} from 'app/const/umAlias';



const portionLocales = {
    ru: {
        questionText: 'Сколько',
    },
    en: {
        questionText: 'How many',
    },
};

const locales = {
    ru: {
        ...portionLocales.ru,
        ...umLocales.ru,
    },
    en: {
        ...portionLocales.en,
        ...umLocales.en,
    },
};


export const mapInfotoSelect = ({
    locale,
    info,
    handleChangePortion,
    onClose,
}) => {
    const {
        portionAlias,
        portions,
        portion,
        weight,
    } = info;

    const { questionText } = portionLocales[locale];

    const [UM_ALIAS_TYPE] = portionAlias || [PORTION_UM_ALIAS_TYPE];
    const portionAliases = locales[locale][UM_ALIAS_TYPE];
    const questionAlias = portionAliases ? portionAliases.slice(-1)[0] : 'порций';

    const headerText = `${questionText} ${questionAlias}?`;

    const weightStyle = { color: colors.hering };

    const portionItems = portions.map((item, index) => {
        const isSelected = item === portion;

        const portionText = portionAlias
            ? pluralize(item, portionAliases)
            : pluralize(item, locales[locale][PORTION_UM_ALIAS_TYPE]);

        return {
            id: item,
            selected: isSelected,
            buttonProps: {
                onClick: () => handleChangePortion(index),
            },
            TextCmp: (
                <>
                    <span className="num">{item}</span>
                    &nbsp;
                    {portionText}
                    &nbsp;
                    <span style={weightStyle}>
                        /
                        &nbsp;
                        <span className="num">{weight * item}</span>
                        &nbsp;
                        г
                    </span>
                </>
            ),
        };
    });

    const selectData = {
        headerText,
        sections: [{
            id: 'mobilePortionsSection',
            items: portionItems,
        }],
        onClose,
    };

    return selectData;
};
