import queryString from 'query-string';

// TODO: Есть много копипаста работы с utm
// TODO: Прокинуть эту функцию в analytics.html
const POSSIBLE_UTM_QUERY_PARAMS = [
    'utm_source',
    'utm_campaign',
    'utm_medium',
    'utm_term',
    'utm_content',
];

export function getUTMParams() {
    const query = queryString.parse(window.location.search);
    const params = POSSIBLE_UTM_QUERY_PARAMS
        .filter((e) => query[e])
        .reduce((acc, item) => {
            if (Array.isArray(query[item])) {
                return {
                    ...acc,
                    [item]: query[item].join(),
                };
            }
            return {
                ...acc,
                [item]: query[item],
            };
        }, {});
    return params;
}

export default {
    getUTMParams,
};
