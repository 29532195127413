import isWeekend from 'date-fns/is_weekend';

import { BELTWAY_DISTANCE_CORRECTION_RATIO } from 'app/const/address';

const DADATA_ENDPOINT = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';

const DEFAULT_LOCATION = '77';
const MOSCOW_KLADR_ID = '77';
const MOSCOW_REGION_KLADR_ID = '50';
const PETERSBURG_KLADR_ID = '78';
const PETERSBURG_REGION_KLADR_ID = '47';
const N_NOVGOROD_REGION_KLADR_ID = '52';
const KAZAN_KLADR_ID = '16';


/* Адреса находятся внутри КАДа, но считаются закадными службой доставки */
const SPB_OUT_KAD_SETTLEMENTS = ['Лисий Нос', 'Куапилово'];

const petersburgLocations = [
    { kladr_id: PETERSBURG_KLADR_ID },
    { kladr_id: PETERSBURG_REGION_KLADR_ID },
];
const moscowLocations = [
    { kladr_id: MOSCOW_KLADR_ID },
    { kladr_id: MOSCOW_REGION_KLADR_ID },
];
const nNovgorodLocations = [
    { kladr_id: N_NOVGOROD_REGION_KLADR_ID },
];
const kazanLocations = [
    { kladr_id: KAZAN_KLADR_ID },
];
// 52
const getLocation = (subdivision) => {
    if (['RU-SPE', 'RU-LEN'].includes(subdivision)) return petersburgLocations;
    if (['RU-MOS', 'RU-MOW'].includes(subdivision)) return moscowLocations;
    if (['RU-NIZ', 'RU-NIO'].includes(subdivision)) return nNovgorodLocations;
    if (['RU-TA', 'RU-TAO'].includes(subdivision)) return kazanLocations;
    return [...moscowLocations, ...petersburgLocations, ...nNovgorodLocations, ...kazanLocations];
};

const getQueryByValue = ({
    query,
    count = null,
    language = 'ru',
}, subdivision) => ({
    locations_boost: [{ kladr_id: DEFAULT_LOCATION }],
    locations: getLocation(subdivision),
    from_bound: { value: 'street' },
    restrict_value: true,
    to_bound: { value: 'house' },
    query,
    count,
    language,
});

const parseSuggestions = (resp) => {
    const r = resp.json();
    return r.then((respData) => ({
        suggestions: respData.suggestions.map((suggest) => {
            const { data } = suggest;
            const {
                beltway_hit: hit,
                beltway_distance: distance,
            } = data;
            if (hit === 'OUT_KAD' || hit === 'OUT_MKAD') {
                data.beltway_distance = distance * BELTWAY_DISTANCE_CORRECTION_RATIO;
            }
            return suggest;
        }),
    }));
};

const fetchSuggestions = (options, subdivision) => (
    fetch(DADATA_ENDPOINT, {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Token ${process.env.DADATA_TOKEN}`,
        },
        body: JSON.stringify(getQueryByValue(options, subdivision)),
    }).then(parseSuggestions)
);


export const requestSuggestions = (options, subdivision) => {
    const response = fetchSuggestions(options, subdivision);
    return response;
};


export const checkSuggestion = async (options, subdivision) => {
    const defaultOptions = { count: 1 };
    const queryOptions = {
        ...defaultOptions,
        ...options,
    };

    let response = await fetchSuggestions(queryOptions, subdivision);

    const { suggestions: [addressData] } = response;
    if (addressData && !addressData.data.beltway_hit) {
        const nextAddress = options.query.replace('Московская обл, ', '');
        const optionsWithUnrestrictedValue = {
            ...queryOptions,
            query: nextAddress,
        };

        response = await fetchSuggestions(optionsWithUnrestrictedValue);
    }

    return response;
};

export const resolveMkadBySuggestions = (suggestions, day) => {
    const [{
        data: {
            beltway_distance: beltwayDistance,
            beltway_hit: beltwayHit,
            settlement,
        },
    }] = suggestions;
    if (beltwayHit === 'IN_KAD' && SPB_OUT_KAD_SETTLEMENTS.includes(settlement)) {
        return 'outside';
    }
    if (beltwayHit === 'OUT_KAD') {
        return 'outside';
    }
    if (beltwayHit === 'OUT_MKAD') {
        if (Number(beltwayDistance) < 12 && isWeekend(new Date(day))) return 'inside';
        return 'outside';
    }
    return 'inside';
};

export const parseBeltwayData = (suggestions) => {
    const [{ data: { beltway_distance: beltwayDistance, beltway_hit: hit } }] = suggestions;
    const beltwayHit = (hit === 'OUT_KAD' || hit === 'OUT_MKAD') ? 'outside' : 'inside';
    return {
        beltwayHit,
        beltwayDistance,
        beltwayHitValue: hit,
    };
};
