/* eslint-disable react/jsx-props-no-spreading */
import React, { useReducer, useContext } from 'react';
import PropTypes from 'prop-types';


export const TOP = 'top';
export const CATEGORY = 'category';
export const FIRST_CATEGORY = 'firstCategory';
export const STORED_POSITION = 'storedPosition';
export const DISH = 'dish';
export const DISH_FROM_DETAILS = 'dishFromDetails';


const initialState = {
    scrolling: {
        target: null, // 'top', 'firstCategory', 'category', 'dish', 'dishFromDetails'
        value: null, // 'categoryId', 'dishId', 'position in px', 'null'/'undefined'
        smooth: null,
        categoryId: null,
        lastActionType: null,
    },
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'scrollTo': {
            const { target, value, smooth } = action.payload;

            return {
                ...state,
                scrolling: {
                    target, value, smooth, lastActionType: action.type,
                },
            };
        }
        case 'resetScroll': {
            return {
                ...state,
                scrolling: initialState.scrolling,
            };
        }
        case 'intersectCategory': {
            const { categoryId } = action.payload;
            return {
                ...state,
                scrolling: { ...state.scrolling, categoryId, lastActionType: action.type },
            };
        }
        default: return state;
    }
};


export const MenuDataContext = React.createContext(initialState);
export const MenuDispatchContext = React.createContext(null);

export const MenuProvider = ({ children }) => {
    const [data, dispatch] = useReducer(reducer, initialState);

    return (
        <MenuDispatchContext.Provider value={dispatch}>
            <MenuDataContext.Provider value={data}>
                {children}
            </MenuDataContext.Provider>
        </MenuDispatchContext.Provider>
    );
};

export const withMenuData = (WrappedComponent) => (forwardedProps) => {
    const data = useContext(MenuDataContext);

    return (
        <WrappedComponent
            menuDataContext={data}
            {...forwardedProps}
        />
    );
};
export const withMenuDispatch = (WrappedComponent) => (forwardedProps) => {
    const dispatch = useContext(MenuDispatchContext);

    return (
        <WrappedComponent
            menuDispatchContext={dispatch}
            {...forwardedProps}
        />
    );
};


MenuProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
