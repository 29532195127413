import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import CheckboxMark from './components/CheckboxMark';
import RadioMark from './components/RadioMark';

import {
    TYPE_CHECKBOX,
    TYPE_RADIO,
    VERSION_LEGACY,
    VERSION_UIKIT,
    VERSION_UIKIT_RESPONSIVE,
} from './checkbox-consts';

import './checkbox-2.scss';

/**
* @param {HTMLChangeEvent} event
*/
const noop = (event) => {}; // eslint-disable-line no-unused-vars


export default function Checkbox({
    version = VERSION_LEGACY,
    type = TYPE_CHECKBOX,
    name,
    id,
    checked = false,
    tabIndex = undefined,
    disabled = false,
    onChange = noop,
}) {
    const isRadio = type === TYPE_RADIO;

    const classes = cn({
        checkbox: true,
        [version]: true,
    });

    return (
        <label htmlFor={id} styleName={classes}>
            {isRadio
                ? <RadioMark checked={checked} version={version} />
                : <CheckboxMark checked={checked} version={version} disabled={disabled} />
            }
            <input
                styleName="checkbox-input"
                id={id}
                name={name}
                checked={checked}
                onChange={onChange}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                type={type}
                tabIndex={tabIndex}
                disabled={disabled}
            />
        </label>
    );
}

Checkbox.propTypes = {
    version: PropTypes.oneOf([VERSION_LEGACY, VERSION_UIKIT, VERSION_UIKIT_RESPONSIVE]),
    type: PropTypes.oneOf([TYPE_CHECKBOX, TYPE_RADIO]),
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    tabIndex: PropTypes.string,
    onChange: PropTypes.func,
};
