import { isDesktop } from 'app/utils/resolution';
import {
    isTrialBasket,
    isPreassembledBasket,
    isBasketEditable,
} from '../checkers';

/**
 * @description Определение мобильной корзины
 */
export const checkMobileSign = (props, _set, _get) => {
    const {
        needDeepCheck, preparedAreas,
    } = _get();

    switch (true) {
        case (!needDeepCheck):
            break;
        case (isTrialBasket(props) || !isBasketEditable()):
            preparedAreas.list.titleType = 'set';
            _set({ type: 'control_small_basket_without_promo', needDeepCheck: false, preparedAreas });
            break;
        case (isPreassembledBasket(props) && !isDesktop()):
            preparedAreas.list.titleType = 'set';
            _set({
                type: 'custom_basket_with_tags',
                needDeepCheck: false,
                preparedAreas,
            });
            break;
        case (isPreassembledBasket(props) && isDesktop()):
            _set({
                type: 'add_collections_small_with_promo',
                needDeepCheck: false,
                preparedAreas,
            });
            break;
        case (!isDesktop()):
            _set({ type: 'new_upsell', needDeepCheck: false, preparedAreas });
            break;
        case (isDesktop()):
            _set({ type: 'old_upsell', needDeepCheck: false, preparedAreas });
            break;
        default:
            _set({ type: 'control_small_basket_with_promo', needDeepCheck: false, preparedAreas });
            break;
    }
};
