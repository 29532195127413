const textOrder = [
    'city',
    'street',
    'house',
    'flat',
    'entrance',
    'floor',
];

const queryOrder = [
    'city',
    'street',
    'house',
];

export default (address) => textOrder.reduce((acc, key) => {
    if (address[key] && key === 'house') {
        return [...acc, `д ${address[key]}`];
    }
    if (address[key] && key === 'flat') {
        return [...acc, `кв. ${address[key]}`];
    }
    if (address[key] && key === 'entrance') {
        return [...acc, `п. ${address[key]}`];
    }
    if (address[key] && key === 'floor') {
        return [...acc, `эт. ${address[key]}`];
    }
    if (address[key]) {
        return [...acc, address[key]];
    }
    return acc;
}, []).join(', ');

// Зеленоградские адреаса без имени
const checkAddressWithoutStreetName = (address) => {
    const { city, street, house } = address;

    if (!house) {
        return false;
    }

    const digitalHouseNumber = house.replace(/\D+/g, '');
    const isDigitalOnlyHouseNumber = digitalHouseNumber.length === house.length;
    const isZelik = city === 'г Зеленоград';
    return isZelik && isDigitalOnlyHouseNumber && !street;
};

export const getAddressForQuery = (address) => queryOrder.reduce((acc, key) => {
    const houseChar = checkAddressWithoutStreetName(address) ? 'к' : 'д';

    if (address[key] && key === 'house') {
        return [...acc, `${houseChar} ${address[key]}`];
    }
    if (address[key]) {
        return [...acc, address[key]];
    }
    return acc;
}, []).join(', ');

export const getHouseValue = (address) => {
    const {
        house, house_type: houseType, block, block_type: blockType,
    } = address.data;
    const houseValue = `${houseType} ${house}`;
    const blockValue = `${blockType} ${block}`;
    if (block) {
        return `${houseValue} ${blockValue}`;
    }
    return houseValue;
};

export const isAdressWithHouseSelected = (addressData) => {
    const { data: { fias_level: fiasLevel } } = addressData;
    /*
        Адреса, у которых fiasLevel < 8 не содержат номер дома.
        Нам нужно показывать подсказки адресов до тех пор, пока пользователь не выберет адрес с домом
    */
    return fiasLevel >= 8;
};
