import { gql, useQuery } from '@apollo/client';
import FloatFooterDiscountCounter from 'app/components/FloatFooterDiscountCounter';
import { UIButton, UIButtonBottomArea } from 'app/components/ui';
import React, { useContext, useMemo } from 'react';
import formatPrice from 'app/utils/price';
import { LocaleContext } from 'app/containers/LocaleProvider';
import { locales } from '../../basket.locales';

import './basketFooterButton.scss';


const BASKET_FOOTER_BUTTON_QUERY = gql`
    query BASKET_FOOTER_BUTTON(
    $period: String!
) {
    selectedPeriod @client @export(as: "period")

    cart(
        period: $period
    ) {
        period
        isSubscriptionActivated
        isSectionsEmpty @client
        # sections {
        #     id
        #     title
        #     gift_category
        #     items {
        #         ...ROOT_CART_DISH_ITEM
        #         dish {
        #             ...ROOT_MENU_DISH_ITEM
        #         }
        #     }
        # }
        totals {
            ...ROOT_CART_TOTAL
        }
        discount_conditions {
            ...ROOT_CART_DISCOUNT_CONDITIONS
        }
    }
}`;

export const BasketFooterButton = React.memo((props) => {
    const {
        closeBasket,
        handleToCheckout,
        isOnlyGiftInBasket,
        isCartUpdating,
    } = props;
    const { locale } = useContext(LocaleContext);
    const { data } = useQuery(BASKET_FOOTER_BUTTON_QUERY);

    const isSectionsEmpty = useMemo(
        () => data?.cart?.isSectionsEmpty,
        [data?.cart?.isSectionsEmpty],
    );

    const discountConditions = useMemo(
        () => data?.cart?.discount_conditions || {},
        [data?.cart?.discount_conditions],
    );

    const totals = useMemo(
        () => data?.cart?.totals,
        [data?.cart?.totals],
    );

    const handler = useMemo(
        () => {
            const isGoBack = isOnlyGiftInBasket || isSectionsEmpty;
            return (isGoBack ? closeBasket : handleToCheckout);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isSectionsEmpty, isOnlyGiftInBasket],
    );

    if (!data) {
        return null;
    }

    const { emptyButtonText, fullButtonText } = locales[locale];
    /* SELECTORS */


    const originalPrice = isSectionsEmpty ? null : totals.total_price + totals.delivery_price;
    const price = isSectionsEmpty ? null : totals.total_common_price;
    const title = isSectionsEmpty ? emptyButtonText : fullButtonText;

    return (
        <UIButtonBottomArea zIndex="10" position="static">
            <FloatFooterDiscountCounter
                promocodeConditions={discountConditions}
            />
            <UIButton
                onClick={handler}
                aria-label="Оформить заказ"
                loading={isCartUpdating}
                disabled={isCartUpdating}
            >
                <span className="title">{title}</span>
                {price && (
                    <span className="price">
                        <span className="num">{formatPrice(price)}</span>
                        <span className="rub">₽</span>
                    </span>
                )}
                {originalPrice && originalPrice !== price && (
                    <span className="original-price">
                        <span className="num">{formatPrice(originalPrice)}</span>
                        {/* <span className="num">3 000</span> */}
                        <span className="rub">₽</span>
                    </span>
                )}
            </UIButton>
        </UIButtonBottomArea>
    );
});
