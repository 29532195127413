import { HttpLink } from '@apollo/client';

const httpLinkOptions = {
    uri: process.env.GRAPHQL_SERVER,
};

/**
 * @description Устанавливает endpoint сервера
 * @doc https://www.apollographql.com/docs/react/api/link/apollo-link-http/
 */
export const httpLink = new HttpLink(httpLinkOptions);
