/* eslint-disable arrow-body-style */
import { CATEGORIES_URL_VALUES } from 'app/const/categories';


/* CONST */

const LOCATION_MENU = 'Menu';
const LOCATION_BASKET = 'Basket';
const LOCATION_CHECKOUT = 'Checkout';

const FILTER_KEYS = {
    period: 'Week',
    day: 'Day',
    category: 'Category',
    tag: 'Tags',
    headerTag: 'Tags',
};


/* HANDLERS */

export const prepareLocationName = () => {
    const uri = window.location.pathname;
    if (!uri) {
        return '/';
    }
    const lastPath = uri
        .split('/')
        .filter((e) => e)
        .filter((e, i) => i === 0)
        .map((e) => (e === 'app' ? 'profile' : e))
        .join('');
    return lastPath;
};

export const getFullTitle = (dish) => `${dish.title} ${dish.caption}`;


export const getPurchaseEventParams = (invoiceData, dishes) => {
    const items = dishes.map((dish) => ({
        item_name: getFullTitle(dish),
        item_id: dish.id,
        item_brand: 'Elementaree',
        item_category: dish.sectionTitle,

        item_quantity: dish.portion,
        quantity: dish.portion,

        item_price: dish.price,
        price: dish.price,
    }));

    const params = {
        event: 'purchase',
        userType: invoiceData.userType,
        ecommerce: {
            purchase: {
                transaction_id: invoiceData.uid,
                affiliation: 'Elementaree',
                revenue: invoiceData.price,
                shipping: invoiceData.shipping,
                coupon: invoiceData.promocode,
                currency: 'RUB',
                items,
            },
        },
    };

    const { trialScenario } = invoiceData;

    if (trialScenario) {
        if (trialScenario.includes('5x2')) {
            params.dinner5x2 = 'true';
        }
        if (trialScenario.includes('3x2')) {
            params.dinner3x2 = 'true';
        }
    }

    return params;
};

export const getBonusInvoceData = (data) => {
    const { eventAction, eventLabel, eventValue } = data;
    if (eventAction === 'Use') {
        return {
            event: 'Bonus_Use',
            eventParams: eventLabel,
            eventValue,
        };
    }

    if (eventAction === 'Withdraw') {
        return {
            event: 'Bonus_Withdraw',
            eventValue,
        };
    }
};


export const getLocationValue = (location) => {
    const { pathname } = location;
    if (pathname.match('/basket')) return LOCATION_BASKET;
    if (pathname.match('/checkout')) return LOCATION_CHECKOUT;
    return LOCATION_MENU;
};


/* Track_Navigate */
const eventNameRecord = {
    1: 'begin_checkout',
    4: 'add_shipping_info',
};

const checkoutOrderFromAppFactory = (sections) => {
    return sections.map((section) => section.items.map((dish) => ({
        item_name: getFullTitle({ title: dish.dish.title, caption: dish.dish.caption }),
        item_id: dish.dish_id,
        item_category: CATEGORIES_URL_VALUES[section.id] || section.id,

        quantity: dish.portions,
        item_quantity: dish.portions,

        price: dish.price,
        item_price: dish.price,
    }))).flat();
};

/* Track_Navigate */
export const getFlatCheckoutParamApp = (payload) => {
    // eslint-disable-next-line camelcase
    const { sections, step, totals: { total_common_price, delivery_price } } = payload;
    const products = checkoutOrderFromAppFactory(sections);
    const eventName = eventNameRecord[step];

    const eventParams = {
        revenue: total_common_price,
        shipping: delivery_price,
        items: products,
    };
    return { eventName, eventParams };
};

/* Track_Navigate */
export const getFlatCheckoutParam = (payload) => {
    const { step, sections } = payload;
    const products = sections.map((section) => section.items.map((dish) => ({
        name: getFullTitle({ title: dish.dish.title, caption: dish.dish.caption }),
        id: dish.dish_id,
        price: dish.price,
        quantity: dish.portions,
        category: CATEGORIES_URL_VALUES[section.id],
    }))).flat();

    return {
        event: 'checkout',
        ecommerce: {
            checkout: {
                actionField: { step },
                products,
            },
        },
    };
};


export const getDishContent = (dish) => ({ id: dish.id, quantity: dish.portion });


export const getEventLabel = (payload) => {
    const { itemKey } = payload;
    const value = FILTER_KEYS[itemKey];


    if (itemKey === 'period') {
        const { periodIndex, action } = payload;
        return {
            eventAction: action || 'Change',
            eventLabel: `${value}-${periodIndex + 1}`,
        };
    }
    if (itemKey === 'day') {
        const { index, action } = payload;
        return {
            eventAction: action || 'Change',
            eventLabel: `${value}-${index + 1}`,
        };
    }
    if (itemKey === 'category') {
        const { action, categoryId } = payload;
        const categoryKey = CATEGORIES_URL_VALUES[categoryId];
        return {
            eventAction: 'Change',
            eventLable: `${value}-${action}-${categoryKey}`,
        };
    }
    if (itemKey === 'tag') {
        const { action, tagTitle } = payload;
        return {
            eventAction: 'Change',
            eventLabel: `${value}-${action}-${tagTitle}`,
        };
    }
    if (itemKey === 'headerTag') {
        const { action, tagTitle } = payload;
        return {
            eventAction: 'Button',
            eventLabel: `${value}-${action}-${tagTitle}`,
        };
    }
    if (itemKey === 'clear') {
        return {
            eventAction: 'Clear',
        };
    }
    if (itemKey === 'open') {
        return {
            eventAction: 'Open',
        };
    }
    if (itemKey === 'openWeek') {
        return {
            eventAction: 'Open',
            eventLabel: 'Week',
        };
    }
    return null;
};

export const getMobileEventLable = (payload) => {
    const { itemKey } = payload;
    const value = FILTER_KEYS[itemKey];
    if (itemKey === 'open') {
        return {
            eventName: 'Filter_Open',
        };
    }
    if (itemKey === 'openWeek') {
        return {
            eventName: 'Filter_Open',
            eventParams: 'Week',
        };
    }
    if (itemKey === 'clear') {
        return {
            eventName: 'Filter_Clear',
        };
    }
    if (itemKey === 'period') {
        const { periodIndex, action } = payload;
        return {
            eventName: action ? `Filter_${action}` : 'Filter_Change',
            eventParams: `Week-${periodIndex + 1}`,
        };
    }
    if (itemKey === 'day') {
        const { index, action } = payload;
        return {
            eventName: action ? `Filter_${action}` : 'Filter_Change',
            eventParams: `Day-${index + 1}`,
        };
    }
    if (itemKey === 'tag') {
        const { action, tagTitle } = payload;
        return {
            eventName: 'Filter_Change',
            eventParams: `${value}-${action}-${tagTitle}`,
        };
    }
    if (itemKey === 'headerTag') {
        const { action, tagTitle } = payload;
        return {
            eventName: 'Filter_Button',
            eventParams: `${value}-${action}-${tagTitle}`,
        };
    }
    return null;
};
