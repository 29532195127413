export const /* char */ PORTION_UM_ALIAS_TYPE = 'порция';

const ML_UM_ALIAS_TYPE = 'мл';
const SM_UM_ALIAS_TYPE = 'см';
const KG_UM_ALIAS_TYPE = 'кг';
const SET_UM_ALIAS_TYPE = 'набор';
const ECLAIR_UM_ALIAS_TYPE = 'эклер';
const COOKIES_UM_ALIAS_TYPE = 'печенье';
const BAR_UM_ALIAS_TYPE = 'батончик';
const LOAF_UM_ALIAS_TYPE = 'буханка';
const KEKS_UM_ALIAS_TYPE = 'кекс';

const BOTTLE_UM_ALIAS_TYPE = 'бутылка';
const PCS_UM_ALIAS_TYPE = 'шт';
const PACKAGE_UM_ALIAS_TYPE = 'упаковка';
const PIROZHOK_UM_ALIAS_TYPE = 'пирожок';
const PIE_UM_ALIAS_TYPE = 'пирог';
const CAKE_UM_ALIAS_TYPE = 'пирожное';
const CHEESECAKE_UM_ALIAS_TYPE = 'чизкейк';
const JAR_UM_ALIAS_TYPE = 'банка';

export const locales = {
    ru: {
        grammChar: 'г',
        portionUnitText: 'блюда',
        [BOTTLE_UM_ALIAS_TYPE]: ['бутылка', 'бутылку', 'бутылки', 'бутылок'],
        [PCS_UM_ALIAS_TYPE]: ['шт', 'шт', 'шт', 'шт'],
        [PORTION_UM_ALIAS_TYPE]: ['порция', 'порцию', 'порции', 'порций'],
        [PACKAGE_UM_ALIAS_TYPE]: ['упаковка', 'упаковку', 'упаковки', 'упаковок'],

        [PIROZHOK_UM_ALIAS_TYPE]: ['пирожок', 'пирожок', 'пирожка', 'пирожков'],
        [PIE_UM_ALIAS_TYPE]: ['пирог', 'пирог', 'пирога', 'пирогов'],
        [CAKE_UM_ALIAS_TYPE]: ['пирожное', 'пирожное', 'пирожных', 'пирожных'],
        [CHEESECAKE_UM_ALIAS_TYPE]: ['чизкейк', 'чизкейк', 'чизкейка', 'чизкейков'],
        [JAR_UM_ALIAS_TYPE]: ['банка', 'банку', 'банки', 'банок'],

        [ML_UM_ALIAS_TYPE]: ['мл', 'мл', 'мл', 'мл'],
        [SM_UM_ALIAS_TYPE]: ['см', 'см', 'см', 'см'],
        [KG_UM_ALIAS_TYPE]: ['кг', 'кг', 'кг', 'кг'],
        [SET_UM_ALIAS_TYPE]: ['набор', 'набор', 'набора', 'наборов'],
        [ECLAIR_UM_ALIAS_TYPE]: ['эклер', 'эклер', 'эклера', 'эклеров'],
        [COOKIES_UM_ALIAS_TYPE]: ['печенье', 'печенье', 'печенья', 'печений'],
        [BAR_UM_ALIAS_TYPE]: ['батончик', 'батончик', 'батончика', 'батончиков'],
        [KEKS_UM_ALIAS_TYPE]: ['кекс', 'кекс', 'кекса', 'кексов'],
        [LOAF_UM_ALIAS_TYPE]: ['буханка', 'буханку', 'буханки', 'буханок'],
    },
    en: {
        grammChar: 'g',
        portionUnitText: 'dishes',
        [BOTTLE_UM_ALIAS_TYPE]: ['bottle', 'bottle', 'bottles', 'bottles'],
        [PCS_UM_ALIAS_TYPE]: ['pc', 'pc', 'pcs', 'pcs'],
        [PORTION_UM_ALIAS_TYPE]: ['serving', 'servings', 'servings', 'servings'],
        [PACKAGE_UM_ALIAS_TYPE]: ['package', 'package', 'packages', 'packages'],

        [PIROZHOK_UM_ALIAS_TYPE]: ['pirozhok', 'pirozhok', 'pirozhoks', 'pirozhoks'],
        [PIE_UM_ALIAS_TYPE]: ['pie', 'pie', 'pies', 'pies'],
        [CAKE_UM_ALIAS_TYPE]: ['cake', 'cake', 'cakes', 'cakes'],
        [CHEESECAKE_UM_ALIAS_TYPE]: ['cheesecake', 'cheesecake', 'cheesecakes', 'cheesecakes'],
        [JAR_UM_ALIAS_TYPE]: ['jar', 'jar', 'jars', 'jars'],

        [ML_UM_ALIAS_TYPE]: ['ml', 'ml', 'ml', 'ml'],
        [SM_UM_ALIAS_TYPE]: ['cm', 'cm', 'cm', 'cm'],
        [KG_UM_ALIAS_TYPE]: ['kg', 'kg', 'kg', 'kg'],
        [SET_UM_ALIAS_TYPE]: ['set', 'set', 'set', 'sets'],
        [ECLAIR_UM_ALIAS_TYPE]: ['eclair', 'eclair', 'eclair', 'eclair'],
        [COOKIES_UM_ALIAS_TYPE]: ['cookies', 'cookies', 'cookies', 'cookies'],
        [BAR_UM_ALIAS_TYPE]: ['bar', 'bar', 'bar', 'bars'],
        [KEKS_UM_ALIAS_TYPE]: ['cupcake', 'cupcake', 'cupcake', 'cupcakes'],
        [LOAF_UM_ALIAS_TYPE]: ['loaf', 'loaf', 'loaf', 'loaf'],
    },
};

/*
    граммы:
    миллилитры:
    сантиметры:
    штуки:
    порция:
    кг:
    набор:
    упаковка:
    банка:
    чизкейк:
    пирожное:
    пирог:
    бутылка:
    эклер:
    печенье:
    батончик:
    буханка:
    пирожок:
    кекс:
*/


