import isEmpty from 'lodash/isEmpty';


export const WITHOUT_DELIVERIES = 'without-deliveries';
export const WITH_PAST_DELIVERIES = 'with-past-deliveries';
export const WITH_ACTIVE_DELIVERIES = 'with-active-deliveries';

export const getCJGroup = (user) => {
    const CJ = user ? user.customerJourney : null;
    // Step 1
    if (CJ >= 100 && CJ < 200) return WITHOUT_DELIVERIES;
    // Step 2 && 4
    if ((CJ >= 200 && CJ < 300) || (CJ >= 400 && CJ < 500)) return WITH_ACTIVE_DELIVERIES;
    // Step 3
    if (CJ >= 300 && CJ < 400) return WITH_PAST_DELIVERIES;

    return WITHOUT_DELIVERIES;
};

// возвращает группы вида 31, 32 и т.д.
export const getCJGeneralGroup = (cj) => Number(String(cj).substr(0, 2));

/**
 * @description Newbie - пользователь считается новичком, если у него
 * нет ни одной оформленной или уже полученной доставки.
 *
 * @param {Object} user - объект user
 * @param {Object} user.deliveries - массив доставок
 * @param {Object} user.successDeliveriesCount - счетчик успешных доставок
 *
 * @returns true || false
 */
export const isNewbie = (user) => {
    if (!user) return true;

    return isEmpty(user.deliveries) && (user.successDeliveriesCount === 0);
};

