import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import { Transition } from 'react-transition-group';
import compose from 'lodash/flowRight';

import { CATEGORY_TITLES } from 'app/const/categories';

import { getAvailableLinksWithUrl, getAvailablePeriods } from 'app/utils/links';
import LINKS from 'app/const/links';

import Burger from 'assets/svg/burger.svg';

import MenuDishesProvider from 'app/containers/MenuDishesProvider';
import { abTestDataContext, COLORFUL_CATEGORIES_IN_MENU, COLORFUL_CATEGORIES_IN_MENU_TEST } from 'app/containers/contexts/abTest.context';
import { withLocaleContext } from 'app/containers/LocaleProvider';
import { withAvailableLinks } from 'app/containers/contexts/availableLinks.context';
import { withMenuDatesContext } from 'app/containers/contexts/menuDates.context';
import { withFiltersContext } from 'app/containers/contexts/filters.context/filters.context';
import { withSelectedFilters } from 'app/connect/connectToSelectedFilters';

import { Mobile, Desktop } from 'app/components/Responsive';
import Link, { LINK_TYPE_NAV } from 'app/components/Link';

import UI from 'app/components/ui';
import { isDesktop } from 'app/utils/resolution';
import HeaderCategory from '../HeaderCategory';
import HeaderTags from '../HeaderTags';
import MobileSiteNav from '../MobileSiteNav';
import BasketButtonWrapper from '../BasketButtonWrapper';

import { getDesktopHeaderLinksOptions } from './navigationStaticLinks';
import { locales } from './navigation.locales';

import styles from './navigation.scss';
import { BR1125MobileHeader } from './BR1125';


const BURGER_SHOW_TIMEOUT = 300;

const { SPA_MENU } = LINKS;

const isMenuLinkActive = (match, location) => {
    if (!match) {
        const { pathname } = location;
        return pathname === '/';
    }
    const { url } = match;
    return url.includes(SPA_MENU.href);
};

const isProfile = (location) => location.pathname.includes('/profile');
const isAuthPage = (location) => location.pathname.includes('/auth');

function MockHeaderNavigationSkeleton({
    isNewHeaderRenderNeeded = false,
    isABTestFetched = 'unfetched',
}) {
    const classes = classNames({
        'burger-button': true,
        'is-close': true,
    });

    return (
        <div styleName="navigation-category">
            <div styleName="navigation-category__wrap">
                <button
                    styleName={classes}
                    type="button"
                    aria-label="Меню сайта"
                    onClick={() => { }}
                    disabled
                >
                    <Burger data-referral-menu-item="true" />
                </button>
                {/*  */}
                {(!isNewHeaderRenderNeeded && isABTestFetched === 'fetched') && (
                    <button
                        type="button"
                        aria-label="Открыть типы блюд"
                        styleName="header-category__button"
                        onClick={() => { }}
                    >
                        <div styleName="header-category__content">
                            <UI.Heading
                                level="2.1"
                            >
                                Наши сеты
                            </UI.Heading>

                            <div styleName="header-category__arrow-container">
                                <UI.Arrow />
                            </div>
                        </div>
                    </button>
                )}
                {/*  */}
                <div styleName="header-category__filter-button-container">
                    <UI.FiltersButton
                        onClick={() => { }}
                        disabled
                    />
                </div>
            </div>
        </div>
    );
}

MockHeaderNavigationSkeleton.propTypes = {
    isNewHeaderRenderNeeded: PropTypes.bool,
    isABTestFetched: PropTypes.string,
};

class Navigation extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showBestSetsTitle: true,
        };
        this.resolveBestSetsTitleStateThrottled = throttle(this.resolveBestSetsTitleState, 100);
    }

    componentDidMount() {
        this.resolveBestSetsTitleState();
        window.addEventListener('scroll', this.resolveBestSetsTitleStateThrottled);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.resolveBestSetsTitleStateThrottled);
    }

    resolveBestSetsTitleState = () => {
        const { match: { params: { deliveryId } } } = this.props;
        if (deliveryId) {
            this.setState({
                showBestSetsTitle: false,
            });
            return;
        }

        if (window.scrollY < 300) {
            this.setState({
                showBestSetsTitle: true,
            });
        } else {
            this.setState({
                showBestSetsTitle: false,
            });
        }
    };

    // eslint-disable-next-line class-methods-use-this
    renderActiveNavLink() {
        const { localeContext: { locale } } = this.props;
        const text = locales[locale].allDishes;

        return (
            <h1 styleName="nav__item-wrap">
                <Link
                    type={LINK_TYPE_NAV}
                    href={SPA_MENU.href}
                    activeClassName={styles.active}
                    styleName="nav__item"
                    isActive={isMenuLinkActive}
                >
                    {text}
                </Link>
            </h1>
        );
    }

    renderStaticLinks() {
        const {
            localeContext,
            filterQuery,
            onClickMobileNavigationButton,
            menuDatesContext,
            linksContext,
        } = this.props;

        const allAvailableLinks = linksContext;

        const enabledDatesData = menuDatesContext?.state?.enabledDatesData;
        const periods = filterQuery?.menuFilter?.periods;

        if (!periods) return null;
        if (!enabledDatesData) return null;

        const { locale } = localeContext;

        const availablePeriods = getAvailablePeriods(periods);
        const specificLinks = getDesktopHeaderLinksOptions({ locale });

        const availableLinks = getAvailableLinksWithUrl({
            allAvailableLinks,
            specificLinks,
            availablePeriods,
            enabledDatesData,
        });

        return availableLinks.map(({ data }) => {
            const { Text, text, href } = data;

            const classes = classNames({
                nav__item: true,
            });

            return (
                <Link
                    key={data.href}
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    styleName={classes}
                    onClick={onClickMobileNavigationButton}
                >
                    {Text
                        ? <Text localeContext={localeContext} />
                        : text
                    }
                </Link>
            );
        });
    }

    // extra link for new year, e.g.
    // renderExtraLink() {
    //     const { onClickMobileNavigationButton } = this.props;

    //     return (
    //         <a
    //             href="http://elementaree.ru/2020/"
    //             target="_blank"
    //             rel="noopener noreferrer"
    //             styleName="nav__item ny"
    //             onClick={onClickMobileNavigationButton}
    //         >
    //             <div styleName="ny-menu-item-img-wrap">
    //                 <div styleName="ny-menu-item-img-wrap-wrap">
    //                     <NYImg1 styleName="ny-menu-item-img" />
    //                     <NYImg2 styleName="ny-menu-item-img" />
    //                 </div>
    //             </div>
    //             <span styleName="text small">Рождество</span>
    //             <span styleName="text big">Меню на Рождество</span>
    //         </a>
    //     );
    // }

    renderNavLinks() {
        return (
            <div styleName="nav">
                {this.renderActiveNavLink()}
                {this.renderStaticLinks()}
                {/* {this.renderExtraLink()} */}
            </div>
        );
    }

    renderDesktopNavbar() {
        const {
            isNavigationHidden,
        } = this.props;

        return (
            <div styleName="navigation-navbar">
                {!isNavigationHidden && this.renderNavLinks()}
            </div>
        );
    }

    renderBurgerButton() {
        const {
            isMobileNavigationOpen,
            onClickMobileNavigationButton,
        } = this.props;

        const classes = classNames({
            'burger-button': true,
            'is-open': isMobileNavigationOpen,
            'is-close': !isMobileNavigationOpen,
        });
        return (
            <button
                styleName={classes}
                type="button"
                aria-label="Меню сайта"
                onClick={onClickMobileNavigationButton}
            >
                <Burger data-referral-menu-item="true" />
            </button>
        );
    }

    renderHeaderFilter(props) {
        const {
            location,
            selectedFilters: {
                selectedCategoryId,
            },
            onClickMobileFilters,
            showFilterChangedAnimation,
            mainDishesCountState,
            isSoldOutNotificationVisible,
            onClickCloseSoldOutNotification,

            setDishesUpdatingState,
            isCategoryChanging,

            isMobileNavigationOpen,

            basketQuery,
            updateBilling,
            selectedFilters: { selectedTags },
            localeContext: { locale },
        } = this.props;

        const { showBestSetsTitle } = this.state;
        const {
            menuDishesQuery: {
                allDishes,
                basketDishes,
            },
            hasDataForRenderDishes,
        } = props;

        if (!hasDataForRenderDishes) {
            return (
                <div styleName="navigation-category">
                    {this.renderBurgerButton()}
                </div>
            );
        }

        const categoryTitle = CATEGORY_TITLES[locale][selectedCategoryId];

        const headerCategoryProps = {
            selectedTags,
            showBestSetsTitle,
            categoryTitle,
            showFilterChangedAnimation,
            mainDishesCountState,
            isSoldOutNotificationVisible,
            onClickCloseSoldOutNotification,

            setDishesUpdatingState,
            isCategoryChanging,

            basketQuery,
        };

        const categoryWrapClasses = classNames({
            'navigation-category__wrap': true,
            'is-hidden': isMobileNavigationOpen,
        });

        const tagsClasses = classNames({
            'navigation-tags': true,
            'is-hidden': isMobileNavigationOpen,
        });

        return (
            <>
                <div styleName="navigation-category">
                    {this.renderBurgerButton()}
                    <div styleName={categoryWrapClasses}>
                        <HeaderCategory
                            onClickFilter={onClickMobileFilters}
                            {...headerCategoryProps}
                            locale={locale}
                        />
                    </div>
                </div>
                <div styleName={tagsClasses}>
                    <HeaderTags
                        location={location}
                        allDishes={allDishes}
                        basketDishes={basketDishes}
                        selectedCategoryId={selectedCategoryId}
                        basketQuery={basketQuery}
                        updateBilling={updateBilling}
                    />
                </div>
            </>
        );
    }

    renderMobileMenuHeader() {
        const {
            isMenuLocation,
            mainDishesCountState,
            isMobileNavigationOpen,
            basketQuery,
            filterQuery: { menuFilter },
            location,
            // BR1125
            onClickMobileNavigationButton,
            isUserInABTest,
            isABTestFetched,
            onClickMobileFilters,
        } = this.props;

        const isSkeletonShown = isMenuLocation && mainDishesCountState === 'loading';
        // BR-1125
        const isNewHeaderRenderNeeded = isUserInABTest && isMenuLocation && !isDesktop();


        if (isSkeletonShown) {
            // TODO: BR-1077
            return (
                <MockHeaderNavigationSkeleton
                    isNewHeaderRenderNeeded={isNewHeaderRenderNeeded}
                    isABTestFetched={isABTestFetched}
                />
            );
        }

        if (isNewHeaderRenderNeeded) {
            return (
                <BR1125MobileHeader
                    onClickMobileNavigationButton={onClickMobileNavigationButton}
                    onClickFilters={onClickMobileFilters}
                />
            );
        }

        const navClasses = classNames({
            navigation: true,
            'nav-is-open': isMobileNavigationOpen,
        });

        const categoryClasses = classNames({
            'navigation-category': true,
            'is-hidden': isMobileNavigationOpen,
        });

        const burgerButtonClasses = classNames({
            'button-visibility--wrapper': isAuthPage(location),
        });

        const canRenderFilters = basketQuery.cart && isMenuLocation;

        return (
            <div styleName={navClasses}>
                <div styleName="navigation-wrapper">
                    {canRenderFilters ? (
                        <MenuDishesProvider
                            menuFilter={menuFilter}
                            basketQuery={basketQuery}
                            render={(props) => this.renderHeaderFilter(props)}
                        />
                    ) : (
                        <div styleName={categoryClasses}>
                            <span styleName={burgerButtonClasses}>
                                {this.renderBurgerButton()}
                            </span>
                            <div styleName="navigation-category__wrap" />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderMobileNav() {
        const {
            history,
            isMobileNavigationOpen,
            onClickMobileNavigationButton,
            onClickQuit,
            onClickSubscriptionInfoOpen,
        } = this.props;

        return (
            <Transition
                mountOnEnter
                unmountOnExit
                timeout={BURGER_SHOW_TIMEOUT}
                in={isMobileNavigationOpen}
            >
                {(transitionState) => (
                    <MobileSiteNav
                        history={history}
                        transitionState={transitionState}
                        onClickNavLink={onClickMobileNavigationButton}
                        onClickQuit={onClickQuit}
                        onClickSubscriptionInfoOpen={onClickSubscriptionInfoOpen}
                    />
                )}
            </Transition>
        );
    }

    render() {
        const {
            localeContext,
            basketQuery,
            onClickBasketButton,
            location,
        } = this.props;

        // TODO: BR-1084 Header is here ;)
        return (
            <>
                <Mobile>
                    {this.renderMobileNav()}
                    {this.renderMobileMenuHeader()}
                </Mobile>
                {!isProfile(location) && (
                    <Desktop>
                        <div styleName="navigation">
                            <div styleName="navigation-wrapper">
                                {this.renderDesktopNavbar()}
                                <BasketButtonWrapper
                                    localeContext={localeContext}
                                    basketQuery={basketQuery}
                                    location={location}
                                    onClickBasketButton={onClickBasketButton}
                                />
                            </div>
                        </div>
                    </Desktop>
                )}
            </>
        );
    }
}


Navigation.propTypes = {
    localeContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,

    menuDatesContext: PropTypes.shape({
        state: PropTypes.shape({
            enabledDatesData: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }).isRequired,

    linksContext: PropTypes.shape({}).isRequired,

    isMenuLocation: PropTypes.bool.isRequired,

    basketQuery: PropTypes.shape({
        loading: PropTypes.bool,
        error: PropTypes.shape({}),
        cart: PropTypes.shape({
            sections: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
    onClickBasketButton: PropTypes.func.isRequired,

    filterQuery: PropTypes.shape({
        loading: PropTypes.bool,
        error: PropTypes.shape({}),
        menuFilter: PropTypes.shape({
            periods: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }).isRequired,
    selectedFilters: PropTypes.shape({
        selectedPeriod: PropTypes.string,
        selectedCategoryId: PropTypes.string,
        selectedTags: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    onClickMobileFilters: PropTypes.func.isRequired,

    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            deliveryId: PropTypes.string,
        }).isRequired,
    }).isRequired,
    history: PropTypes.shape({}).isRequired,

    // userQuery: PropTypes.shape({
    //     user: PropTypes.shape({
    //         hasApplication: PropTypes.bool,
    //     }),
    //     loading: PropTypes.bool.isRequired,
    // }).isRequired,
    onClickQuit: PropTypes.func.isRequired,

    isMobileNavigationOpen: PropTypes.bool.isRequired,
    onClickMobileNavigationButton: PropTypes.func.isRequired,

    isNavigationHidden: PropTypes.bool,
    onClickBack: PropTypes.func.isRequired,

    showFilterChangedAnimation: PropTypes.bool.isRequired,

    mainDishesCountState: PropTypes.string.isRequired,
    isSoldOutNotificationVisible: PropTypes.bool,
    onClickCloseSoldOutNotification: PropTypes.func.isRequired,
    onClickSubscriptionInfoOpen: PropTypes.func.isRequired,

    setDishesUpdatingState: PropTypes.func.isRequired,
    isCategoryChanging: PropTypes.bool.isRequired,

    updateBilling: PropTypes.func.isRequired,
    isUserInABTest: PropTypes.bool,
    isABTestFetched: PropTypes.string,
};

const NavigationDataAccessContainer = (props) => {
    const {
        colorful_categories_in_menu: colorfulCategoriesInMenu,
        fetchABTestValue,
        fetchingState,
        setABTestValue,

        basketQuery = {
            loading: true,
            error: null,
            cart: null,
        },
        isSoldOutNotificationVisible = false,
        isNavigationHidden = false,
    } = useContext(abTestDataContext);


    useEffect(
        () => {
            fetchABTestValue(COLORFUL_CATEGORIES_IN_MENU);
        },
        [fetchABTestValue],
    );
    return (
        <Navigation
            {...props}
            isUserInABTest={colorfulCategoriesInMenu === COLORFUL_CATEGORIES_IN_MENU_TEST}
            isABTestFetched={[COLORFUL_CATEGORIES_IN_MENU] in fetchingState
                ? fetchingState[COLORFUL_CATEGORIES_IN_MENU]
                : 'unfetched'}
        // basketQuery={basketQuery}
        // isSoldOutNotificationVisible={isSoldOutNotificationVisible}
        // isNavigationHidden={isNavigationHidden}
        />
    );
};


export default compose(
    withFiltersContext,
    withSelectedFilters,

    withAvailableLinks,
    withRouter,
    withLocaleContext,
    withMenuDatesContext,
)(NavigationDataAccessContainer);
