import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import StepPayCheckSection from '../../../StepPayment/components/StepPayCheckSection';

import './single-step-check-area.scss';


export function SingleStepCheckArea(props) {
    const {
        locale,
        paymentProps,
    } = props;

    const {
        onClickSubscriptionInfoOpen,
        handleOpenDeliveryPricePopup,
        subscriptionTypeQuery: { subscriptionType },
        hideRecurrentPayment,
    } = paymentProps;

    const onClickSubscriptionInfoOpenWithContext = useCallback((e) => {
        onClickSubscriptionInfoOpen(e, 'step-by-step-checkout');
    }, [onClickSubscriptionInfoOpen]);

    const handleOpenDeliveryPricePopupWrapped = useCallback((e) => {
        handleOpenDeliveryPricePopup(e, 'step-by-step-checkout');
    }, [handleOpenDeliveryPricePopup]);

    return (
        <div styleName="single-step-checkout__check">
            <StepPayCheckSection
                {...paymentProps}
                hideRecurrent={hideRecurrentPayment}
                locale={locale}
                subscriptionType={subscriptionType}
                onClickSubscriptionInfoOpenWithContext={onClickSubscriptionInfoOpenWithContext}
                handleOpenDeliveryPricePopup={handleOpenDeliveryPricePopupWrapped}
            />
        </div>
    );
}

SingleStepCheckArea.propTypes = {
    locale: PropTypes.string.isRequired,
    paymentProps: PropTypes.shape({
        onClickSubscriptionInfoOpen: PropTypes.func.isRequired,
        handleOpenDeliveryPricePopup: PropTypes.func.isRequired,
        subscriptionTypeQuery: PropTypes.shape({
            subscriptionType: PropTypes.string.isRequired,
        }).isRequired,
        hideRecurrentPayment: PropTypes.bool.isRequired,
    }).isRequired,
};
