
export const periods = [
    '2024-03-04',
    '2024-03-11',
];
export const dishes = [
    '2960',
    '2166',
    '431',
    '899',
    '4371',
];
