import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { analyticService } from 'global/services';

import { CarrierCommentTextArea } from 'app/components/Form/CarrierCommentTextArea';
import { FETCH_DELIVERY_ADDRESS_SESSION } from 'app/graphql/network/deliveryAddress.query';
import { SHIPPING } from '../../../../../graphql/network/checkout/checkoutMutations';

type TCarrierCommentRowProps = {
    addressValue: string,
    selectedPeriod: string,
    hasError: boolean,
    isStaticHeight?: boolean
};


const CarrierCommentRow = ({
    addressValue,
    selectedPeriod,
    hasError,
    isStaticHeight = false,
}: TCarrierCommentRowProps) => {
    const {
        loading: deliveryDataLoading,
        data: deliveryAdressData,
    } = useQuery(FETCH_DELIVERY_ADDRESS_SESSION);

    const [mutateDeliveryComment] = useMutation(
        SHIPPING,
        { refetchQueries: [{ query: FETCH_DELIVERY_ADDRESS_SESSION }] },
    );

    const handleBlurComment = useCallback((nextValue: string) => {
        const { deliveryAddress: { address } } = deliveryAdressData;

        const variables = {
            period: selectedPeriod,
            address: {
                address: addressValue,
                comment: nextValue,
                entrance: address.entrance,
                flat: address.flat,
                floor: address.floor,
            },
        };

        mutateDeliveryComment({
            variables,
        });
    }, [addressValue, deliveryAdressData, selectedPeriod, mutateDeliveryComment]);

    const handleClick = useCallback(() => {
        analyticService.push({ eventName: 'Checkout_Click_Address_Comment' });
    }, []);

    if (!deliveryAdressData || !deliveryAdressData.deliveryAddress) {
        return null;
    }

    return (
        <CarrierCommentTextArea
            initialValue={deliveryAdressData?.deliveryAddress.address.comment}
            isCommentDataLoading={deliveryDataLoading}
            disabled={!addressValue || hasError}
            isStaticHeight={isStaticHeight}
            onBlur={handleBlurComment}
            onClick={handleClick}
        />
    );
};

export default CarrierCommentRow;
