import React from 'react';
import cn from 'classnames';

import css from './menu.skeleton.module.scss';


const MenuTagsSkeleton = () => (
    <div className={css.manuTagsSkeletonRoot}>
        <div className={cn(css.manuTagsSkeletonItem, css.skeletonAnimation)} />
        <div className={cn(css.manuTagsSkeletonItem, css.skeletonAnimation)} />
        <div className={cn(css.manuTagsSkeletonItem, css.skeletonAnimation)} />
    </div>
);

const MenuSetsCardSkeleton = () => (
    <div className={css.menuSetsCardSkeletonRoot}>
        <div className={cn(css.menuSetsCardSkeletonItem, css.skeletonAnimation)} />
        <div className={cn(css.menuSetsCardSkeletonItem, css.skeletonAnimation)} />
        <div className={cn(css.menuSetsCardSkeletonItem, css.skeletonAnimation)} />
    </div>
);

const MenuDishCardSkeleton = () => (
    <div className={css.menuDishCardSkeletonRoot}>
        <div className={cn(css.menuDishCardSkeletonImg, css.skeletonAnimation)} />
        <div className={css.menuDishCardSkeletonContent}>
            <div className={css.menuDishCardSkeletonLeft}>
                <div className={cn(css.menuDishCardSkeletonOne, css.skeletonAnimation)} />
                <div className={cn(css.menuDishCardSkeletonTwo, css.skeletonAnimation)} />
                <div className={cn(css.menuDishCardSkeletonThree, css.skeletonAnimation)} />
            </div>
            <div className={cn(css.menuDishCardSkeletonRight, css.skeletonAnimation)} />
        </div>
    </div>
);

const MenuSlectionSkeleton = () => (
    <div className={css.menuSlectionSkeletonRoot}>
        <div className={cn(css.menuSlectionSkeletonItem, css.skeletonAnimation)} />
        <div className={cn(css.menuSlectionSkeletonItem, css.skeletonAnimation)} />
        <div className={cn(css.menuSlectionSkeletonItem, css.skeletonAnimation)} />
    </div>
);

export function MenuSkeletonPage() {
    return (
        <div className={css.menuSkeletonPageRoot}>
            <MenuTagsSkeleton />
            <MenuSetsCardSkeleton />
            <div className={cn(css.menuSkeletonDishListTitleSkeleton, css.skeletonAnimation)} />
            <MenuDishCardSkeleton />
            <MenuDishCardSkeleton />
            <MenuDishCardSkeleton />
            {/*  */}
            <MenuSlectionSkeleton />
            {/*  */}
            <MenuDishCardSkeleton />
            <MenuDishCardSkeleton />
            <MenuDishCardSkeleton />
        </div>
    );
}
