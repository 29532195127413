import React from 'react';
import cn from 'classnames';

import css from './deliveryPrice.module.scss';
import car from './car.img.svg';


interface DeliveryPriceProps {
    deliveryPrice: number,
    responsive: boolean,
    noBorder: boolean,
}

export function DeliveryPrice({ deliveryPrice, responsive, noBorder }: DeliveryPriceProps) {
    const containerClasses = cn({
        [css.deliveryPrice]: true,
        [css.responsiveContainer]: responsive,
        [css.noBorder]: noBorder,
    });

    return (
        <div className={containerClasses}>
            <img src={car} alt="доставка" className={css.deliveryPriceImg} />
            <span className={css.deliveryPriceText}>
                {deliveryPrice === 0 && 'Бесплатная доставка'}
                {deliveryPrice > 0 && (
                    <>
                        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                        Доставка&nbsp;&nbsp;{deliveryPrice}&nbsp;₽
                    </>
                )}
            </span>
        </div>
    );
}
