/* eslint-disable quote-props */
export const locales = {
    ru: {
        // renderActiveNavLink
        allDishes: 'Все блюда',
        trial_5x2: 'Пробный набор',
        trial_3x2: 'Пробный премиум',
        everyday: 'ужинов на семью',
        box: 'Запас еды',
        rest: 'Ресторан дома',
        ny: 'Новогоднее меню',
    },
    en: {
        allDishes: 'All dishes',
        trial_5x2: 'Your first order',
        trial_3x2: 'Premium kit',
        everyday: 'family dinners',
        box: '20 Meals for a Month',
        rest: 'Restaurant at home',
        ny: 'New Year\'s menu',
    },
};
