/* eslint-disable max-len */
import React, { FC } from 'react';
import css from './NYLPBanner.module.scss';

const NYLPBanner: FC = () => (
    <a href="/blyuda-dlya-novogodnego-stola" target="_blank">
        <div className={css.NYLPBannerRoot}>
            <div className={css.NYLPBannerDescContainer}>
                <div className={css.NYLPBannerTitle}>
                    Волшебные блюда
                    <br />
                    для главной ночи
                </div>
                <div className={css.NYLPBannerDescription}>
                    <div className={css.NYLPBannerDescL}>
                        Представьте, что у вас есть волшебные эльфы, с которыми вы легко и быстро приготовите шикарный праздничный стол. Осталось лишь выбрать блюда
                    </div>
                    <div className={css.NYLPBannerDescRegular}>
                        Представьте, что у вас есть волшебные эльфы, с которыми вы легко и быстро приготовите шикарный праздничный стол. Осталось лишь выбрать блюда
                    </div>
                </div>
            </div>
            <div className={css.NYLPBannerImageContainer} />
        </div>
    </a>
);

export { NYLPBanner };
