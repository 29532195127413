import { getWeekByDate } from 'app/utils/weekByDate';
import { errorService } from 'global/services';
import { getPeriodsErrorMessage } from './idResolvers.utils';

export const preparePeriodsId = (responseObject) => {
    const { start, end, __typename } = responseObject;

    if (getWeekByDate(start) !== getWeekByDate(end)) {
        // eslint-disable-next-line no-console
        console.error(getPeriodsErrorMessage(__typename));
        errorService.log({
            text: `Поля start: ${start} и end: ${end} имеют разное знание.`,
            source: 'server',
            extra: {
                responseObject,
                start,
                end,
                formatedStart: getWeekByDate(start),
                formatedEnd: getWeekByDate(end),
            },
        });
    }

    return `${__typename}:${getWeekByDate(start)}`;
};

