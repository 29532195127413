import { selectedPeriodVar } from '../reaction';

/**
 * @description Функция нужна для того чтобы сделать уникальный id каждой cartSection:preview
 * Что-бы они они не конфликтовали в Apollo Cache
 * @param {*} sectionItems в виде __ref
 * @returns сумму всех id
 */
const makeHashFromDishesId = (sectionItems = []) => {
    const result = sectionItems.reduce((acc, item) => {
        const { __ref } = item;

        if (!__ref) {
            return 0;
        }

        const id = __ref.replace('cartItem:', '');
        return Number(id) + acc;
    }, 0);
    return result;
};

export const prepareSectionId = (responseObject) => {
    const {
        __typename, cartType, id, items,
    } = responseObject;

    const hash = makeHashFromDishesId(items);
    const period = selectedPeriodVar();

    if (!id) {
        console.error('Нужно поле: id для нармализации cache', id);
    }

    if (!cartType) {
        console.error('Нужно поле: cartType для нармализации cache', cartType);
        return `${__typename}:${period}:${id}:${hash}`;
    }

    return `${__typename}:${cartType}:${period}:${id}:${hash}`;
};

