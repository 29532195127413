import React, {
    useState, useEffect, useMemo, useRef,
} from 'react';
import cn from 'classnames';
import TextMask from 'react-text-mask';

import {
    IUIKitInputTextAreaProps,
    TtrasitionState,
} from './UiKItUiInput.types';

import css from './newUiinput.module.scss';


export function UiKitInputTextArea(props: IUIKitInputTextAreaProps) {
    const {
        inputValue,
        inputLabel,
        inputNodeRef,

        inputUiState,
        componentSize,

        inputMask,

        inputOnClickHanlder,
        inputOnChangeHanlder,
        inputOnBlurHanlder,
        inputOnFocusHandler,
        inputType,
        htmlFor,
    } = props;

    /* STATE */
    const [trasitionState, setTrasitionState] = useState<TtrasitionState>('trasition-default');
    const [animationState, setAnimationState] = useState<'animation-active' | 'animation-inactive'>('animation-inactive');

    const containerRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

    /* EFFECTS */
    useEffect(() => {
        const isInputActive = document.activeElement === inputNodeRef?.current;
        const isActive = Boolean(inputValue) || isInputActive;
        const initialTransitionState = isActive ? 'trasition-active' : 'trasition-default';
        setTrasitionState(initialTransitionState);
    },
        // NOTE: didMount
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inputValue]);

    useEffect(() => {
        if (containerRef.current !== null) {
            const inputs = containerRef.current.querySelectorAll('input');
            const inputEl = inputs[inputs.length - 1];

            if (inputNodeRef) {
                inputNodeRef.current = inputEl;
            }
        }
        // NOTE: didMount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* STYLES */
    const uiKitInputElementClasses = cn({
        [css.uiKitInputItem]: true,
        [css.uiKitInputItemNumber]: inputType === 'tel',
        [componentSize]: true,
        [trasitionState]: true,
        [inputUiState]: true,
    });

    const uiKitInputShadowClasses = cn({
        [css.uiKitInputItem]: true,
        [css.uiKitInputItemNumber]: inputType === 'tel',
        [css.uiKitInputShadow]: true,
        [componentSize]: true,
        [trasitionState]: true,
        [inputUiState]: true,
    });

    const uiKitLabelElementClasses = cn({
        [css.uiKitInputLabel]: true,
        [componentSize]: true,
        [trasitionState]: true,
        [inputUiState]: true,
        [css.uiKitInputLabelExternalModifier]: true,
        [animationState]: animationState,
    });

    /* HANDLERS */
    const handleClickOnLabel = () => {
        inputNodeRef?.current?.focus();
        setTrasitionState('trasition-active');
    };

    const handleBlurInput = (event: React.FocusEvent<HTMLInputElement>) => {
        const { currentTarget: { value } } = event;
        if (!value) {
            setTrasitionState('trasition-default');
            inputOnBlurHanlder(event);
        } else {
            inputOnBlurHanlder(event);
        }
    };

    const handleFocusInputWrapper = (event: React.FocusEvent<HTMLInputElement>) => {
        inputOnFocusHandler(event);
        if (animationState === 'animation-active') return;
        setAnimationState('animation-active');
    };

    /* SELECTORS */
    const isElementsDisabled = useMemo(
        () => ['validation', 'disabled'].includes(inputUiState),
        [inputUiState],
    );

    const isLabelDasabled = useMemo(
        () => Boolean(inputValue) || trasitionState === 'trasition-active' || isElementsDisabled,
        [inputValue, trasitionState, isElementsDisabled],
    );

    const guideValue = useMemo(() => {
        if (!inputMask) return '';

        return inputMask.map((maskItem) => {
            if (typeof maskItem === 'string') {
                return maskItem;
            }
            return '_';
        }).join('');
    }, [inputMask]);

    const shadowValue = `${inputValue}${guideValue.slice(inputValue.length)}`;

    return (
        <div
            className={css.uiKitInputWrapper}
            ref={containerRef}
        >
            <button
                type="button"
                disabled={isLabelDasabled}
                className={uiKitLabelElementClasses}
                onClick={handleClickOnLabel}
            >
                {inputLabel}
            </button>

            {inputMask
                ? (
                    <>
                        <TextMask
                            data-shadow
                            mask={inputMask}
                            className={uiKitInputShadowClasses}
                            value={shadowValue}
                            type={inputType}
                            showMask
                        />
                        <TextMask
                            mask={inputMask}
                            guide={false}
                            className={uiKitInputElementClasses}
                            disabled={isElementsDisabled}
                            value={inputValue}
                            onChange={inputOnChangeHanlder}
                            onBlur={handleBlurInput}
                            onClick={inputOnClickHanlder}
                            onFocus={inputOnFocusHandler}
                            type={inputType}
                        />
                    </>
                ) : (
                    <input
                        className={uiKitInputElementClasses}
                        disabled={isElementsDisabled}
                        ref={inputNodeRef}
                        value={inputValue}
                        onChange={inputOnChangeHanlder}
                        onBlur={handleBlurInput}
                        onClick={inputOnClickHanlder}
                        onFocus={handleFocusInputWrapper}
                        type={inputType}
                        id={htmlFor}
                    />
                )
            }
        </div>
    );
}
