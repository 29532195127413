export const periods = [
    // '2023-08-07',
    '2024-11-25',
    '2024-12-02',
    '2024-12-09',
    '2024-12-16',
    '2024-12-23',
    '2024-12-29',
];

export const dishes = [
    '4741',
    '4748',
    '4749',
    '4750',

    '3880',
];
