import React from 'react';

import ArrowIcon from './arrow-icon.component.svg';
import './arrow.scss';


export default function Arrow() {
    return (
        <div styleName="ui-arrow-container">
            <ArrowIcon styleName="ui-arrow-icon" />
        </div>
    );
}
