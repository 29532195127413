import { castToStringTags, computeIsNewbie } from '../user.utils';

export const userPolicies = {
    isNewbie: {
        read(_, client) {
            return computeIsNewbie(client);
        },
    },
    customizationTags: {
        read(value) {
            return castToStringTags(value || []);
        },
    },
    filterTags: {
        read(value) {
            return castToStringTags(value);
        },
    },

    primitiveFutureDeliveries: {
        read(_, { readField }) {
            const deliveriesRefs = readField('deliveries');
            return deliveriesRefs.map(({ __ref }) => __ref);
        },
    },
    // customerJourney: {
    //     read() {
    //         return 312;
    //     },
    // },
};
