import React, { useMemo } from 'react';

import Dialog from './Dialog';
import { DialogResponsive, DialogProps } from './DialogResponsive/DialogResponsive';
import { DialogAnimationWrapper } from './DialogAnimationWrapper';
import { DialogStaticDesktop } from './BR1156/DialogStaticDesktop/DialogStaticDesktop';

// UNION TYPE FOR FUTURE CUSTOM DIALOGS
type DialogModificators = 'profile_notifications' | '' | ''


interface DialogAnimatedProps extends DialogProps {
    isOpen: boolean,
    responisve?: boolean,
    buttonsReversed?: boolean,
    isProfileLayout?: boolean,
    isNewIrresponsiveLayout?: boolean,
    modeList?: DialogModificators,
    rejectSpecificHandler?: () => void
}

/** @param isProfileLayout - параметр, который влияет на стили диалогового окна.
 *  Связан с профилем только тем, что эти стили впервые были применены в профиле.
 * Может применяться в любом месте приложения  */


/** @param isNewIrresponsiveLayout - параметр, который показывает новое неотзывчивое диалоговое окно.
 * Может использоваться в любом месте приложения  */
export function DialogAnimated({
    isOpen,
    responisve = false,
    notifyOnly = false,
    buttonsReversed = false,
    isProfileLayout = false,
    isNewIrresponsiveLayout = false,
    isCloseConfirmAction = false,
    onReject,
    onConfirm,
    ...restProps
}: DialogAnimatedProps) {
    const rejectHandler = (notifyOnly || isCloseConfirmAction)
        ? onConfirm
        : onReject;

    const DialogComponent = useMemo(
        () => (isNewIrresponsiveLayout
            ? DialogStaticDesktop
            : Dialog),
        [isNewIrresponsiveLayout],
    );

    return (
        <DialogAnimationWrapper
            isOpen={isOpen}
            onReject={rejectHandler}
        >
            {responisve ? (
                <DialogResponsive
                    notifyOnly={notifyOnly}
                    onConfirm={onConfirm}
                    onReject={onReject}
                    buttonsReversed={buttonsReversed}
                    isProfileLayout={isProfileLayout}
                    isCloseConfirmAction={isCloseConfirmAction}
                    {...restProps}
                />
            ) : (
                // @ts-ignore
                <DialogComponent
                    notifyOnly={notifyOnly}
                    onConfirm={onConfirm}
                    onReject={onReject}
                    isCloseConfirmAction={isCloseConfirmAction}
                    {...restProps}
                />
            )}
        </DialogAnimationWrapper>
    );
}
