import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    useFragment, useReactiveVar, useMutation, useQuery, useApolloClient,
} from '@apollo/client';
import cn from 'classnames';
import queryString from 'query-string';
import { analyticService } from 'global/services';

import { getTrialScenarioFromUrl } from 'app/utils/trial-scenario';
import { subscriptionTypeVar, selectedPeriodVar, checkoutDeliveryDateVar } from 'app/apollo/reaction';
import BASKET_QUERY from 'app/graphql/network/basket/basketQuery';
import DELVIERY_BASKET_QUERY from 'app/graphql/network/basket/deliveryBasketQuery';
import { ADD_ITEM_TO_CART } from 'app/graphql/network/basket/basketMutations';

import { DISH_FRAGMENT, CART_ITEM_FRAGMENT } from './portions-buttons-queries.graphql';

import cross from './img/cross.img.svg';
import plus from './img/plus.img.svg';
import minus from './img/minus.img.svg';

import s from './portions-button.scss';


export function PortionsButtonGroup(props) {
    const { id, refetchCallback = () => {} } = props;

    const client = useApolloClient();
    const subscriptionType = useReactiveVar(subscriptionTypeVar);
    const selectedPeriod = useReactiveVar(selectedPeriodVar);
    const checkoutDeliveryDate = useReactiveVar(checkoutDeliveryDateVar);

    /* STATE */

    const [isBasketUpdating, setIsBasketUpdating] = useState(false);

    const deliveryId = useMemo(
        () => window.location.pathname.split('/').find((e) => Boolean(e) && !Number.isNaN(Number(e))),
        [],
    );

    const isDeliveryMenu = useMemo(
        () => window.location.pathname.includes('app/delivery'),
        [],
    );

    /* DA */

    const { data: dish } = useFragment({
        fragment: DISH_FRAGMENT,
        from: {
            __ref: `menuDish:${id}`,
        },
    });

    const { data, refetch } = useQuery(
        isDeliveryMenu
            ? DELVIERY_BASKET_QUERY
            : BASKET_QUERY,
        {
            variables: {
                period: selectedPeriod,
                delivery_date: checkoutDeliveryDate,
                trial_scenario: getTrialScenarioFromUrl(),
                deliveryId: deliveryId ?? undefined,
            },
        },
    );

    /* SELECTORS */

    const cartItem = useMemo(() => {
        const defaultCartItem = {
            id,
            portions: 0,
            price: 0,
            discount_price: 0,
        };

        if (!data) return defaultCartItem;
        const flatSection = data.cart.sections.reduce((acc, section) => [...acc, ...section.items], []);

        const nextCartItem = flatSection.find((cartDish) => cartDish.dish_id === String(id));

        if (!nextCartItem) return defaultCartItem;

        return nextCartItem;
    }, [id, data]);

    const price = useMemo(() => {
        if (cartItem.portions > 0) {
            return subscriptionType ? cartItem.discount_price : cartItem.price;
        }
        return subscriptionType ? Math.ceil(dish.price * 0.9) : dish.price;
    }, [dish, cartItem, subscriptionType]);


    const [updateDishCount] = useMutation(ADD_ITEM_TO_CART, {
        variables: {
            dish_id: id,
            period: selectedPeriod,
            delivery_id: deliveryId ?? undefined,
        },
        onCompleted: async () => {
            await refetch();
            await refetchCallback();
            setIsBasketUpdating(false);
        },
    });

    const isDishInCart = cartItem.portions > 0;

    /* HANDLERS */

    const handlePlus = useCallback(() => {
        const step = dish.portions.at(0);
        const max = dish.portions.at(-1);
        const nextPortions = Math.min(cartItem.portions + step, max);

        setIsBasketUpdating(true);

        updateDishCount({ variables: { portions: nextPortions } });

        if (!isDishInCart) {
            const parsedSearchParams = queryString.parse(window.location.search);
            const isDetailPopupFromSelection = Boolean(parsedSearchParams?.st);
            const location = window.location.pathname.replace(/^\s*\/|\/\s*$/g, '');
            const source = isDetailPopupFromSelection && location === 'basket'
                ? 'upsell'
                : location;

            analyticService.push({
                eventName: 'add_to_cart',
                dishes: [{ ...dish, categoryId: dish.category_id }],
                source,
                subscriptionType,
            });
        }
    }, [updateDishCount, dish.portions, cartItem.portions, dish, isDishInCart, subscriptionType]);

    const handleMinus = useCallback(() => {
        const step = dish.portions.at(0);
        const min = 0;
        const nextPortions = Math.max(cartItem.portions - step, min);

        setIsBasketUpdating(true);

        updateDishCount({ variables: { portions: nextPortions } });
    }, [updateDishCount, dish.portions, cartItem.portions]);


    /* STYLE */
    const contentClasses = cn({
        [s['portions-content']]: true,
        [s['in-cart']]: isDishInCart,
        [s['is-updating']]: isBasketUpdating,
    });

    return (
        <div className={s['portions-button-group-container']}>
            <div className={s['portions-button-group']}>
                <div className={contentClasses}>
                    {isDishInCart && (
                        <div className={s['portions-button-group__button-icon-container']}>
                            <img className={s['portions-button-group__button-icon']} src={minus} alt="минус" />
                        </div>
                    )}
                    <div className={s['portions-button-group__button-price-container']}>
                        {isDishInCart && (
                            <div className={s['portions-button-group__button-count-price']}>
                                <span className={s['portions-button-group__count']}>{cartItem.portions}</span>
                                <img className={s['portions-button-group__cross']} src={cross} alt="умножить" />
                            </div>
                        )}
                        <div className={s['portions-button-group__price']}>
                            {price}
                            &nbsp;
                            <span className={s['portions-button-group__price-rub']}>₽</span>
                        </div>
                    </div>
                    <div className={s['portions-button-group__button-icon-container']}>
                        <img className={s['portions-button-group__button-icon']} src={plus} alt="плюс" />
                    </div>
                </div>

                <div className={s['portions-button-group__controls']}>
                    {isDishInCart && (
                        <button
                            className={s['portions-button-group__control']}
                            type="button"
                            onClick={handleMinus}
                            disabled={isBasketUpdating}
                        >
                            -
                        </button>
                    )}
                    <button
                        className={s['portions-button-group__control']}
                        type="button"
                        onClick={handlePlus}
                        disabled={isBasketUpdating}
                    >
                        +
                    </button>
                </div>
            </div>
        </div>
    );
}

PortionsButtonGroup.propTypes = {
    id: PropTypes.string.isRequired,
    refetchCallback: PropTypes.func,
};
