import React from 'react';

export const dialogTexts = {
    ru: {
        dialog: {
            success: {
                checkout: {
                    confirmText: 'Понятно',
                    rejectText: 'В меню',
                },
                basket: {
                    confirmText: 'Понятно',
                },
            },
            error: {
                strongText: 'Произошла ошибка',
                regularText: 'Попробуйте повторить позже',
                confirmText: 'Хорошо',
            },
        },
    },
    en: {
        dialog: {
            success: {
                checkout: {
                    confirmText: 'Got it',
                    rejectText: 'To menu',
                },
                basket: {
                    confirmText: 'Понятно',
                },
            },
            error: {
                strongText: 'Error',
                regularText: 'Please try again later',
                confirmText: 'OK',
            },
        },
    },
};
