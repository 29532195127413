type T_ACTION_TYPE = 'TOGGLE_FOLDING_ROW'

type TActionPayload = {
    isAboutRowOpen?: boolean
    isSetRowOpen?: boolean
    isSpecialRowOpen?: boolean
}
interface IBurgerMenuReducer {
    type: T_ACTION_TYPE,
    payload: TActionPayload,
}

export const initialBurgerMenuState = {
    isAboutRowOpen: false,
    isSetRowOpen: false,
    isSpecialRowOpen: false,
};

export const BurgerMenuReducer = (
    state: typeof initialBurgerMenuState,
    action: IBurgerMenuReducer,
) => {
    const { type, payload = {} } = action;

    switch (type) {
        case 'TOGGLE_FOLDING_ROW': {
            return { ...initialBurgerMenuState, ...payload };
        }
        default: return state;
    }
};
