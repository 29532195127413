import {
    getFullTitle,
    getDishContent,
} from '../../events.utils';

export const metaAkaFacebookEventsRecord = [
    /**
     * @event 'Dish_Details_Viewed'
     */
    {
        eventName: 'Dish_Details_Viewed',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const dish = data;
            return {
                event: 'ViewContent',
                content_name: getFullTitle(dish),
                content_category: dish.category,
                content_ids: [dish.id],
                content_type: 'product',
                value: dish.price,
                currency: 'RUB',
            };
        },
    },
    /**
     * @event 'add_to_cart_fb'
     */
    {
        eventName: 'add_to_cart_fb',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const { dish } = data;
            const params = {
                content_ids: [dish.id],
                content_name: getFullTitle(dish),
                content_type: 'product',
                contents: [getDishContent(dish)],
                value: dish.price,
                currency: 'RUB',
            };

            return {
                event: 'AddToCart',
                ...params,
            };
        },
    },
    /**
     * @event 'Click_To_Checkout_Button'
     */
    {
        eventName: 'Click_To_Checkout_Button',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const { price, dishes } = data;
            const dishesCount = dishes
                .map((d) => d.portion)
                .reduce((acc, portions) => acc + portions, 0);

            const params = {
                content_ids: dishes.map((d) => d.id),
                contents: dishes.map(getDishContent),
                content_type: 'product',
                currency: 'RUB',
                num_items: dishesCount,
                value: price,
            };
            return {
                event: 'InitiateCheckout',
                ...params,
            };
        },
    },
];
