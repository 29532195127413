import React, {
    useEffect,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import Velocity from 'velocity-animate';

import { usePrevious } from 'app/utils/hooks';

import Checkbox, { TYPE_RADIO } from 'app/components/Form/Checkbox2';

import './period-list.scss';

const ANIMATION_OPTIONS = {
    duration: 400,
    easing: 'ease-in-out',
};

function animateExpand(el) {
    Velocity(
        el,
        'slideDown',
        ANIMATION_OPTIONS,
    );
}

function animateCollapse(el, options = {}) {
    Velocity(
        el,
        'slideUp',
        {
            ...ANIMATION_OPTIONS,
            ...options,
        },
    );
}


function CityList(props) {
    const {
        mutateAddreessHandler,
        isMsk,
        isSpb,
        isExpanded,
        isNiz,
        isKzn,
    } = props;

    const containerRef = useRef();
    const prevIsExanded = usePrevious(isExpanded);


    useEffect(() => {
        const el = containerRef.current;

        if (prevIsExanded === undefined && el) {
            animateCollapse(el, { duration: 0 });
            return;
        }

        const isExpandStateChanged = isExpanded !== prevIsExanded;

        if (!isExpandStateChanged || !el) return;

        if (isExpanded) {
            animateExpand(el);
        } else {
            animateCollapse(el);
        }
    });

    const f1 = {
        id: 'mobile-city-select-msk',
        buttonProps: {
            onClick: () => { mutateAddreessHandler({ address: 'г Москва' }); },
            name: 'btn-msk',
            disabled: isMsk,
        },
        TextCmp: <>Москва и область</>,
        selected: isMsk,
    };
    const f2 = {
        id: 'mobile-city-select-spb',
        buttonProps: {
            onClick: () => { mutateAddreessHandler({ address: 'г Санкт-Петербург' }); },
            name: 'btn-spb',
            disabled: isSpb,
        },
        TextCmp: <>С.Петербург и область</>,
        selected: isSpb,
    };
    const f3 = {
        id: 'mobile-city-select-niz',
        buttonProps: {
            onClick: () => { mutateAddreessHandler({ address: 'г Нижний Новгород' }); },
            name: 'btn-niz',
            disabled: isNiz,
        },
        TextCmp: <>Н.Новгород и область</>,
        selected: isNiz,
    };
    const f4 = {
        id: 'mobile-city-select-kzn',
        buttonProps: {
            onClick: () => { mutateAddreessHandler({ address: 'г Казань' }); },
            name: 'btn-kzn',
            disabled: isKzn,
        },
        TextCmp: <>Казань</>,
        selected: isKzn,
    };

    const CityItemsForRender = [f1, f2, f3, f4];

    return (
        <div styleName="period-list" ref={containerRef}>
            {CityItemsForRender.map(({
                id,
                buttonProps,
                selected,
                TextCmp,
            }) => (
                <li styleName="period-list__item" key={id}>
                    <button
                        styleName="period-list__item-button"
                        type="button"
                        {...buttonProps}
                    >
                        <Checkbox
                            type={TYPE_RADIO}
                            name="rerere"
                            id={id}
                            checked={selected}
                            onClick={() => { }}
                            onChange={(e) => e.stopPropagation()}
                        />

                        <div styleName="period-text">
                            {TextCmp}
                        </div>
                    </button>
                </li>
            ))}
        </div>
    );
}

export default CityList;
