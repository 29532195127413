import React, { useContext } from 'react';
import { useFragment } from '@apollo/client';
import { useCitySelectMenuDialog } from 'app/components/ui/Tag/hooks/useCitySelectMenuDialog';
import { LocationContext } from 'app/containers/LocationProvider';
import { ADDRESS_SUBDIVISION_FRAGMENT } from 'app/graphql/address/subdivision.fragment.graphql';
import { AbstractSelectTag } from './SelectTag';


export function CitySelectTag() {
    const { getCityTitile } = useContext(LocationContext);
    const { pushCitySelectDialog } = useCitySelectMenuDialog();

    const { data: sessionAddressData } = useFragment<any>({
        fragment: ADDRESS_SUBDIVISION_FRAGMENT,
        from: {
            __ref: 'address:session_address',
        },
    });

    const {
        // @ts-ignore
        title,
        // @ts-ignore
    } = getCityTitile({ deliveryAddress: { address: { ...sessionAddressData } } },
    );

    const togleCitySelect = () => {
        pushCitySelectDialog();
    };


    return (
        <AbstractSelectTag
            placeHolder="город"
            text={title}
            onClickHandler={togleCitySelect}
        />
    );
}
