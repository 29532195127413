/* eslint-disable no-underscore-dangle */
/* eslint-disable lines-between-class-members */
/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */

import { getAppType } from 'app/utils/browser';
import Raven from 'raven-js';

const webAppId = '1:819161551754:web:7113261af11f8f16';

const firebaseConfig = {
    apiKey: 'AIzaSyC3-ld6elNYIe0uQswPrhxy1NE2XE2NskI',
    authDomain: 'elementaree-staging.firebaseapp.com',
    databaseURL: 'https://elementaree-staging.firebaseio.com',
    projectId: 'elementaree-staging',
    storageBucket: 'elementaree-staging.appspot.com',
    messagingSenderId: '819161551754',
    appId: webAppId,
    measurementId: 'G-0BYPNM1ZVX',
};

class FirebaseAppClient {
    constructor() {
        this.firebaseApp = null;
    }
    init() {
        try {
            if (window.firebase.apps.length) {
                this.firebaseApp = window.firebase.app();
            } else {
                this.firebaseApp = window.firebase.initializeApp(firebaseConfig);
            }
        } catch (error) {
            console.log(error);
            Raven.captureMessage(
                '[Anlalytics error]: Firebase init error',
                {
                    tags: { type: 'Auth error' },
                    extra: { error },
                },
            );
        }
    }
    getApp() {
        return this.firebaseApp;
    }
}

class FirebaseAnalyticsAndroidClient {
    constructor() {
        this.service = {
            logEvent: () => { },
        };
        this.clientName = 'FirebaseAnalyticsAndroid';
        this._init();
    }

    _init() {
        try {
            const firebaseApp = new FirebaseAppClient();
            firebaseApp.init();

            // TODO: Пример
            // const anotherFirebaseApp = new FirebaseAppClient();
            // anotherFirebaseApp.init();
            // console.log(firebaseApp.getApp() === anotherFirebaseApp.getApp());

            this.service = window.firebase.analytics();
        } catch (e) {
            console.error(e);
        }
    }

    emit(data) {
        const { event, ...otherParams } = data;

        const screenName = window.location.pathname;

        const eventParams = {
            platform: getAppType(),
            firebase_screen: screenName,
            ...otherParams,
        };

        // console.log(`emit ${this.clientName} event:`, data);
        console.warn(event, { ...eventParams });
        this.service.logEvent(event, { ...eventParams });
    }
}

class FirebaseAnalyticsIOSClient {
    constructor() {
        this.service = {
            logEvent: () => { },
        };
        this.clientName = 'FirebaseAnalyticsIOS';
        this._init();
    }
    _init() {
        /**
        * Инициализция происходит в нативной части приложения,
        * взаимодйествие с которой происходит через
        * window.webkit.messageHandlers.analyticsMessageHandler
        */
    }

    emit(data) {
        const { event, ...otherParams } = data;

        const screenName = window.location.pathname;

        const eventParams = {
            platform: getAppType(),
            screenName,
            ...otherParams,
        };

        window.webkit.messageHandlers.analyticsMessageHandler.postMessage({
            eventName: event,
            eventParams,
        });
        // console.log('emit FirebaseAnalyticsClient IOS event:', data, eventParams);
    }
}

/**
 * @implements factry method
 */
export class FirebaseAnalyticsClientFactory {
    constructor() {
        this.mobileAnalytucsClient = null;
        this.isNativeAppAnalytics = this._getIsNativeAppAnalytics();
    }
    _getIsNativeAppAnalytics() {
        return window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.analyticsMessageHandler;
    }


    create() {
        this.mobileAnalytucsClient = this.isNativeAppAnalytics
            ? new FirebaseAnalyticsIOSClient()
            : new FirebaseAnalyticsAndroidClient();
        return this;
    }

    getClient() {
        return this.mobileAnalytucsClient;
    }
}

