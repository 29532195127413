export const dishes = [
    '4716',
    '1814',
    '4740',
    '3497',
    '4135',
    '3561',
    '3992',
    '25',
    '24',
];

export const periods = [
    '2024-11-18',
    '2024-11-24',
];
