import React from 'react';
import classNames from 'classnames';

import s from './skeleton-item.module.scss';


type SkeletonItemProps = {
    withAnimation?: boolean,
}


export default function SkeletonItem(props: SkeletonItemProps) {
    const { withAnimation = true } = props;

    const classes = classNames({
        [s['skeleton-item']]: true,
        [s['with-animation']]: withAnimation,
    });

    return <div className={classes} />;
}
