import { LOCATION_SPB, LOCATION_NIZ } from 'app/const/location';
import { defaultLink } from 'app/const/links/const';

const ID = 'spb-menu';

const orign = process.env.ORIGIN || 'https://elementaree.ru';

export const SPB_DISHES_PAGE = {
    ...defaultLink,

    id: ID,
    hrefRu: `/${ID}/`,
    hrefFullRu: `${orign}/${ID}`,
    hrefEn: `/${ID}/`,
    subdivisions: [LOCATION_SPB, LOCATION_NIZ],
};

