import queryString from 'query-string';

const addPrefix = scenario => `s_${scenario}`;


export const SCENARIO_5X2 = '5x2';
const SCENARIO_S5X2 = 's5x2';
const SCENARIO_4X2 = '4x2';
export const SCENARIO_3X2 = '3x2';


const VALID_EXTRA_PARAMS = [
    SCENARIO_5X2,
    SCENARIO_S5X2, // кажется, используется для Питера
    SCENARIO_3X2,
    SCENARIO_4X2,
    'family',
];
const VALID_EXTRA_WITHOUT_PREFIX = [
    // 'd1',
    // 'd2',
];

export const getTrialScenarioFromUrl = (location = window.location) => {
    const { extra } = queryString.parse(location.search);
    if (!extra) return null;

    const normalizedValue = extra.toLowerCase();

    if (VALID_EXTRA_WITHOUT_PREFIX.includes(normalizedValue)) return normalizedValue;
    if (VALID_EXTRA_PARAMS.includes(normalizedValue)) {
        return addPrefix(normalizedValue);
    }
    return null;
};

export const isScenario5x2 = (scenario) => {
    if (!scenario) return false;
    return scenario.includes(SCENARIO_5X2) || scenario.includes(SCENARIO_S5X2);
};
