/* eslint-disable quote-props */

import { CATEGORY_TITLES } from 'app/const/categories';

// Mongo DB
export const locales = {
    ru: {
        ...CATEGORY_TITLES.ru,

        '1515': 'Без мяса и птицы',
        '1516': 'Без рыбы',
        '1517': 'Без свинины',
        '1218': 'Вег',
        '1219': 'Веган',

        '1765': 'Премиум блюда',
        '1519': 'Подходит для детей',
        '1520': 'Без острого',
        '1220': 'Без жареного/жирного',
        '1809': 'До 15 мин',
        '2024': 'Черноморская кухня',
        '2028': 'Про запас',
        '2026': 'Best',
        '1521': 'Без грибов',
        '1524': 'Без лука',
    },
    en: {
        ...CATEGORY_TITLES.en,

        '1515': 'No meat',
        '1516': 'No fish',
        '1517': 'No pork',
        '1218': 'Veg',
        '1219': 'Vegan',

        '1765': 'Premium',
        '1519': 'For children',
        '1520': 'No spicy',
        '1220': 'No fried',
        '1809': 'Less than 15 min',
        '2024': 'Black Sea cuisine',
        '2028': 'Stocking up',
        '2026': 'The Best',
        '1521': 'No mushrooms',
        '1524': 'No onion',
    },
};
