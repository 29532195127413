import { TYPE_CHECKOUT_LOADING } from '../../StepByStepCheckout/stepByStepCheckoutConst';

type TStep = 'STEP_PHONE'
    | 'STEP_ADDRESS'
    | 'STEP_DATETIME'
    | 'STEP_PAYMENT'
    | 'DEFAULT'

type TCheckoutType = 'TYPE_CHECKOUT_STEP_BY_STEP'
    | 'TYPE_CHECKOUT_LOADING'
    | 'TYPE_CHECKOUT_SINGLE_STEP'

export interface TCheckoutTypeState {
    checkoutType: TCheckoutType
    step: TStep | null
}

export type TCheckoutTypeActions =
 | { type: 'SET_CHECKOUT_TYPE', payload: TCheckoutType }
 | { type: 'SET_CHECKOUT_ACTIVE_STEP', payload: TStep }

/* */
export const checkoutTypeInitialState: TCheckoutTypeState = {
    checkoutType: TYPE_CHECKOUT_LOADING,
    step: 'DEFAULT',
};

export function checkoutTypeReducer(
    state: TCheckoutTypeState,
    action: TCheckoutTypeActions,
) {
    // console.log(action);
    switch (action.type) {
        case 'SET_CHECKOUT_TYPE':
            return {
                ...state,
                checkoutType: action.payload,
            };
        case 'SET_CHECKOUT_ACTIVE_STEP':
            return {
                ...state,
                step: action.payload,
            };
        default:
            return state;
    }
}
