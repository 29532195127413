import { getDialogTextIfWeDont } from '../CustomizationSourceContent';
import { hotfixExcludingTags } from './excludingTags';
import { CustomizationSourceData } from '../customizationSource.types';

export const accountCustomizationTagsFactory = ({ user }: CustomizationSourceData) => {
    if (!user) {
        return {
            text: '',
            isThisSource: false,
            case: 'account tags customization',
        };
    }

    const {
        customizationTagsDetailed = [],
        filterTags = [],
    } = user;

    const filteredTags = customizationTagsDetailed.filter((tag) => {
        const { id } = tag;

        const isFilterTag = filterTags.includes(Number(id));
        const isExcludingTag = hotfixExcludingTags.includes(String(id));

        return !isExcludingTag && !isFilterTag;
    });

    return {
        text: getDialogTextIfWeDont(),
        isThisSource: filteredTags.length > 0,
        case: 'account tags customization',
    };
};
