/* eslint-disable no-underscore-dangle */
const EE_FB_PIXEL_ID = '472567289549194';

export class FacebookAnalyticsClient {
    constructor() {
        this.service = {
            fbq: (...args) => {
                if (window.fbq) {
                    // console.log('emit to fbq', args);
                    window.fbq(...args);
                } else {
                    // console.warn('FacebookAnalyticsClient: emit event to stub', args);
                }
            },
        };
        this.clientName = 'FacebookAnalytics';
        this._init();
    }

    _init() {
        if (window.fbq) {
            this.service = window.fbq;
        }
    }

    emit(data) {
        const { event, ...params } = data;
        // console.warn(`emit FacebookAnalytics event: ${event}`, params);
        this.service?.fbq(
            'trackSingle',
            EE_FB_PIXEL_ID,
            event,
            params,
        );
    }
}

