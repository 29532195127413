import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import BackIconImg from './back-icon.component.svg';

import './back-button.scss';

export function BackButton({
    onClick,
    justify = 'center',
    disabled = false,
}) {
    const buttonClasses = cn({
        'back-button__container': true,
        [`justify-${justify}`]: true,
    });

    return (
        <button
            styleName={buttonClasses}
            data-test-id="back-button"
            type="button"
            onClick={onClick}
            disabled={disabled}
        >
            <BackIconImg styleName="back-button__img" />
        </button>
    );
}

BackButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    justify: PropTypes.oneOf(['center', 'start']),
};
