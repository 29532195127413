export const locales = {
    ru: {
        // Render
        filterTitle: 'Настроить меню',
        filterSubtitle: 'Настройте свое меню',
        resetText: 'Очистить фильтры',
    },
    en: {
        // Render
        filterTitle: 'Customize menu',
        filterSubtitle: 'Customize menu',
        resetText: 'Clear filters',
    },
};
