import React, { RefObject, useEffect } from 'react';

type TypeHookProps<T> = {
    ref: RefObject<T>
    callback: () => void,
}

type UseOutsideClickHook = <T extends HTMLElement>(props: TypeHookProps<T>) => void;

const useOutsideClick: UseOutsideClickHook = <T extends HTMLElement>({ ref, callback }: TypeHookProps<T>): void => {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [ref, callback]);
};

export default useOutsideClick;
