import React from 'react';

import css from './textSpecialShield.module.scss';

import maximS from './maxim/maxim-s.png';
import maximM from './maxim/maxim-m.png';
import maximL from './maxim/maxim-l.png';

export type TShieldTextName = 'maxim' | ''

interface ITextShieldProps {
    name: TShieldTextName,
}

const defaultPath = { s: '', m: '', l: '' };

const shieldMap = new Map<TShieldTextName, typeof defaultPath>([
    ['maxim', { s: maximS, m: maximM, l: maximL }],
]);

export const TextSpecialShield = (props: ITextShieldProps) => {
    const { name } = props;
    const path = shieldMap.get(name) || defaultPath;

    if (!path) {
        return null;
    }

    return (
        <div
            className={css.textSpecialShieldRoot}
        >
            <div className={css.s}>
                <img className={css.textSpecialShieldImg} src={path.s} alt={name} />
            </div>
            <div className={css.m}>
                <img className={css.textSpecialShieldImg} src={path.m} alt={name} />
            </div>
            <div className={css.l}>
                <img className={css.textSpecialShieldImg} src={path.l} alt={name} />
            </div>
        </div>
    );
};
