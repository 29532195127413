/* eslint-disable camelcase */
import React, { useCallback, useState } from 'react';
import { Portal } from 'react-portal';

import { DialogAnimationWrapper } from 'app/components/Dialog/DialogAnimationWrapper';
import { DialogStaticDesktop } from 'app/components/Dialog/BR1156/DialogStaticDesktop';
import { gql, useFragment } from '@apollo/client';
import pluralize from 'app/utils/pluralize';
import {
    locales,
    PORTION_UM_ALIAS_TYPE,
} from 'app/const/umAlias';
import cn from 'classnames';

import { UIRadioIconStatic } from 'app/components/ui/UIRadioIcon/UIRadioIconStatic';
import css from './basketPortionSelect.module.scss';

interface I {
    isPortionOpen: boolean
    onReject: () => void;
    dish_id: string;
    // eslint-disable-next-line no-shadow, no-unused-vars
    changePortionCount: (I: any) => void
}

const formatRowText = (args: any) => {
    const {
        weight,
        // umAlias,
        count,
    } = args;
    // @ts-ignore
    const portionAliases = locales.ru[PORTION_UM_ALIAS_TYPE];
    const portinText = pluralize(count, portionAliases);
    // const weightText = pluralize(count, umAlias);
    const foolWeight = weight * count;

    return `${count} ${portinText}, ${foolWeight} г`;
};

/**
 * @description только desktop
 */
export const BasketPortionSelect = React.memo((props: I) => {
    const {
        isPortionOpen = false,
        onReject = () => { },
        dish_id,
        changePortionCount = () => {},
    } = props;
    // cartItem:4671

    /* DA */
    const {
        data,
    } = useFragment({
        fragment: gql`
            fragment NEW_DISH_CARD_FRAGMENT on cartItem {
                portions
                dish_id
                dish {
                    category_id
                    caption
                    portions
                    umAlias,
                    weight,
                }
            }
        `,
        from: {
            __ref: `cartItem:${dish_id}`,
        },
    });

    /* STATE */
    const [selectedPortion, setSelectedPortion] = useState(data?.portions);

    /* HANDLERS */
    const onConfirm = useCallback(() => {
        if (selectedPortion !== data?.portions) {
            changePortionCount({ value: selectedPortion });
        }
        onReject();
    }, [selectedPortion, changePortionCount, data?.portions, onReject]);

    return (
        <Portal>
            <DialogAnimationWrapper
                isOpen={isPortionOpen}
                onReject={onReject}
            >
                <DialogStaticDesktop
                    notifyOnly
                    onConfirm={onConfirm}
                    onReject={onReject}
                    confirmText="Готово"
                    isCloseConfirmAction={false}
                >
                    <div className={css.selectPortionRoot}>
                        <div className={css.selectPortionTitle}>
                            Количество порций
                        </div>
                        <div className={css.selectPotionItems}>
                            {data?.dish?.portions.map((item: any) => {
                                const rowText = formatRowText({
                                    weight: data?.dish?.weight,
                                    umAlias: data.dish?.umAlias,
                                    count: item,
                                });
                                return (

                                    <button
                                        className={cn(css.selectPortionItemButton)}
                                        type="button"
                                        onClick={() => { setSelectedPortion(item); }}
                                        id={item.id}
                                        key={`${dish_id}:${item}`}
                                    >

                                        <div
                                            className={
                                                // @ts-ignore
                                                cn(
                                                    css.selectPortionItemText,
                                                    item === selectedPortion ? 'selected' : '',
                                                )
                                            }
                                        >
                                            {rowText}
                                        </div>
                                        <UIRadioIconStatic checked={item === selectedPortion} />
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </DialogStaticDesktop>
            </DialogAnimationWrapper>
        </Portal>
    );
});
