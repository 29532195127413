
import { gql } from '@apollo/client';

/*
    dish
      ├─nutrition
      └─[tags]
*/

export const promocodeConditions = gql`
  fragment discountCondtionFragment on DiscountConditions {
    promocode
    promocode_status
    isCertificate
    isPromoCodeActivated
    isPromocodeCombineWithDiscount
    isPromocodeCombineWithAccount
    differenceBetweenPromocodeAndOrder
    isPromoCodeExists
    isSubscriptionActivated
    deliveriesCount
    discountType
    discountValue
    discountDuration
  }
`;
