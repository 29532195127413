import { gql } from '@apollo/client';

// export const SET_PAYMENT_METHOD = gql`
//     mutation SetPaymentMethod_DONE($paymentMethod: String) {
//         setPaymentMethod_DONE(paymentMethod: $paymentMethod) @client
//     }
// `;

export const GET_PAYMENT_METHOD = gql`
  query getPaymentMethod {
        paymentMethod @client
    }
`;
