/**
 * @description Описание возможных полей объекта ссылки.
 * Не все поля могут использоваться в объекте ссылки.
 *
 * @param {string} id - id ссылки. Оязательное поле;
 * @param {function} check - функция-предикат: принимает произвольное количество параметров в виде объекта для проведения проверок.
 * @param {Array || null} subdivisions - список городов, где доступна ссылка. Если не определен, то доступна везде. По умолчанию null;
 * @param {string} originalPrice - базовая цена;
 * @param {string} discountPrice - цена со скидой;
 * @param {string} discountPercent - процент скидки
 * @param {string} discountSum - величина скидки;
 * @param {string} dishesCount - кол-во блюд в наборе;
 * @param {Array} basketSchema - список блюд в наборе;
 * @param {string} promo - промокод ссылки. Возможны разные наименования: promoLanding, promoProfile;
 * @param {string} href - урл ссылки. Возможны разные наименования: hrefBasket, hrefFromMenu;
 * @param {string} datesType - тип специфичной даты доставки (everydayAvailableDate, trialAvailableDate, ...);
 */

const commonParams = {
    subdivisions: null,
};

export const defaultLink = {
    ...commonParams,

    id: 'DEFAULT_PAGE_LINK_ID',
};

export const defaultSetLink = {
    ...defaultLink,

    id: 'DEFAULT_SET_LINK_ID',

    originalPrice: '',
    discountPrice: '',
    discountPercent: '',
    discountSum: '',

    basketSchema: [],
};
