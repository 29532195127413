import { defaultLink } from 'app/const/links/const';

const origin = process.env.ORIGIN;


const SPA_MENU = {
    ...defaultLink,

    id: 'SPA_MENU',
    href: '/menu',
    hrefFull: `${origin}/menu/`,
    datesType: 'eeAvailableDates',
};

const SPA_MENU_FILTERS = {
    ...defaultLink,

    id: 'SPA_MENU_FILTERS',
    href: '/menu/filters/',
};

const SPA_PROFILE = {
    ...defaultLink,

    id: 'SPA_PROFILE',
    href: '/profile/',
    hrefFull: `${origin}/profile/`,
};

const SPA_BASKET = {
    ...defaultLink,

    id: 'SPA_BASKET',
    href: '/basket/',
};

const SPA_THANKS = {
    ...defaultLink,

    id: 'SPA_THANKS',
    href: '/thx/',
};

const SPA_SUBSCRIPTION_INFO = {
    ...defaultLink,

    id: 'SPA_SUBSCRIPTION_INFO',
    href: '/menu/?modal=recurrent-info',
};


export default {
    SPA_MENU,
    SPA_MENU_FILTERS,
    SPA_PROFILE,
    SPA_BASKET,
    SPA_THANKS,
    SPA_SUBSCRIPTION_INFO,
};
