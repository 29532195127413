export const BR1131EventsRecord = [
    /**
     * @event 'Upsell_Click'
     */
    {
        eventName: 'Upsell_Click',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName } = data;
            return {
                ...data,
                event: eventName,
            };
        },
    },
    /**
     * @event 'Collection_Click'
     */
    {
        eventName: 'Collection_Click',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName } = data;
            return {
                ...data,
                event: eventName,
            };
        },
    },
];
