import React, { useContext } from 'react';

import { FiltersContext } from './contexts/filters.context/filters.context';
import { EnvDataSaver } from './EnvDataSaver';


export const EnvDataSaverSPA = () => {
    const { selectedFilters } = useContext(FiltersContext);

    if (!selectedFilters.selectedPeriod) return null;

    return <EnvDataSaver period={selectedFilters.selectedPeriod} />;
};
