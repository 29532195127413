import React from 'react';
import { TShieldVisualName, VisualShield } from './visual/VisualShield';
import { TShieldTextName, TextShield } from './text/TextShield';
import { TShieldCombinedName, CombinedShield } from './combined/CombinedShield';
import { UppserShield } from './upper/UpperShield';
import { UpperTextShield } from './upperText/UpperTextShield';
import { TextSpecialShield } from './textSpecial/TextSpecialShield';

export interface IAbstractDishShield {
    type: 'combined' | 'text' | 'visual' | 'upper' | 'upperText' | 'textSpecial',
    name: TShieldVisualName & TShieldTextName & TShieldCombinedName
}

export const AbstractDishShield = React.memo((props: IAbstractDishShield) => {
    const { type, name } = props;

    return (
        <>
            {type === 'combined' && <CombinedShield name={name} />}
            {type === 'text' && <TextShield name={name} />}
            {type === 'visual' && <VisualShield name={name} />}
            {type === 'upper' && <UppserShield name={name} />}
            {type === 'upperText' && <UpperTextShield name={name} />}
            {type === 'textSpecial' && <TextSpecialShield name={name} />}
        </>
    );
});
