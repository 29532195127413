import { gql } from '@apollo/client';

export const DELIVERY_BASKET_QUERY = gql`
    query DeliveryCart($deliveryId: ID!, $clear: Boolean) {
        cart: deliveryCart(delivery_id: $deliveryId, clear: $clear) {
            period
            isSubscriptionActivated
            id
            typeOfSet
            isSectionsEmpty @client
            type
            hasNewItems
            sections {
                id
                title
                gift_category
                cartType
                items {
                    ...ROOT_CART_DISH_ITEM
                    dish {
                        ...ROOT_MENU_DISH_ITEM
                    }
                }
            }
            totals {
            ...ROOT_CART_TOTAL
            }
            discount_conditions {
            ...ROOT_CART_DISCOUNT_CONDITIONS
            }
        }
    }
`;

export default {
    DELIVERY_BASKET_QUERY,
};
