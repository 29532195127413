import React, { useContext, useCallback } from 'react';

import { OverlayContextDispatch } from 'app/containers//contexts/overlay.context';
import { DishDetailsWithCustom } from '../DishDetailsWithCustom';
import { RENDERING_CONTEXT_DEFAULT } from '../const';


export const useDishDetails = (refetchCallback = () => {}) => {
    const { pushOverlay, closeLastOverlays } = useContext(OverlayContextDispatch);

    const openDishDetails = useCallback((id, renderingContext = RENDERING_CONTEXT_DEFAULT) => {
        pushOverlay({
            id: 'dish_details',
            template: 'bottom',
            overlayProps: { zIndex: 31000 },
            children: (
                <DishDetailsWithCustom
                    id={id}
                    renderingContext={renderingContext}
                    refetchCallback={refetchCallback}
                    onClose={closeLastOverlays}
                />
            ),
        });
    }, [pushOverlay, closeLastOverlays, refetchCallback]);

    return { openDishDetails };
};
