export const LOCATION_MSK = 'MSK';
export const LOCATION_SPB = 'SPB';
export const LOCATION_NIZ = 'NIZ';
export const LOCATION_KZN = 'KZN';

export const SPB_CODES = [
    'RU-LEN',
    'RU-SPE',
];
export const MSK_CODES = [
    'RU-MOS',
    'RU-MOW',
];
export const NIZ_CODES = [
    'RU-NIZ',
    'RU-NIO',
];

export const KZN_CODES = [
    'RU-TA',
    'RU-TAO',
];

export const citiesLocales = [
    {
        id: LOCATION_MSK,
        mobileTitle: 'Мск',
        desktopTitle: 'Москва',
        value: 'Москва и область',
    },
    {
        id: LOCATION_SPB,
        mobileTitle: 'CПб',
        desktopTitle: 'C.Петербург',
        value: 'C.Петербург и область',
    },
    {
        id: LOCATION_NIZ,
        mobileTitle: 'НН',
        desktopTitle: 'Н.Новгород',
        value: 'Н.Новгород и область',
    },
    {
        id: LOCATION_KZN,
        mobileTitle: 'Кзн',
        desktopTitle: 'Казань',
        value: 'Казань',
    },
];

