import React from 'react';
import { useLocation } from 'react-router';
import { useQuery, useReactiveVar, useSuspenseQuery } from '@apollo/client';

// NETWORKING
import BASKET_QUERY from 'app/graphql/network/basket/basketQuery';
import { selectedPeriodVar } from 'app/apollo/reaction';

// CM
import { MobilePortionsSelectContainer } from 'app/components/Select';
import Loader from 'app/components/Loader';
import OverlaysContainer from 'app/components/OverlaysContainer';
import DishesSelection from 'app/components/DishesSelection/DishesSelection';
import { DeliveryDateDialogContainer } from 'app/components/Dialog';
import MainPopUpAndDialog from './MainPopUpAndDialog';

interface IPropsFromMain {
    overlayIsOpen: boolean
    onClickOpenBasket: () => void
    openedModal: 'delivery-info' | 'recurrent-info' | 'address-info' | 'default'
    popUpUiState: 'recurrent-info' | 'address-info' | 'default'
    showModalOverlay: boolean
    showMobilePortions: boolean
    handleCloseMobilePortions: () => void
    mobilePortionsInfo: any
}


export const MainPopups = React.memo((props: IPropsFromMain) => {
    const {
        onClickOpenBasket,
        overlayIsOpen,
        openedModal,
        showModalOverlay,
        popUpUiState,
        showMobilePortions = false,
        handleCloseMobilePortions,
        mobilePortionsInfo,
    } = props;

    const location = useLocation();
    const period = useReactiveVar(selectedPeriodVar);

    const basketQuery = useQuery(
        BASKET_QUERY, {
            variables: { period },
            fetchPolicy: 'cache-and-network',
            context: {
                message: '',
            },
        },
    );

    if (basketQuery?.loading) {
        return null;
    }

    return (
        <>
            {/* TODO: Убрать это из main. Перенести выбор порций в карточку блюда */}
            <MobilePortionsSelectContainer
                // @ts-expect-error isSelectRequired boolean
                isSelectRequired={showMobilePortions}
                info={mobilePortionsInfo}
                onClose={handleCloseMobilePortions}
            />
            <MainPopUpAndDialog
                openedModal={openedModal}
                showModalOverlay={showModalOverlay}
                popUpUiState={popUpUiState}
            />
            {/*
            {/* Dialog containers */}
            <DeliveryDateDialogContainer
                // @ts-expect-error
                basketQuery={basketQuery}
            />
            {/* Нода для монтирования компонентов, использующих библиотеку react-portal.
                Нужна, чтобы эти компоненты не рендерились в конце боди и не перекрывали оверлеи из OverlaysContainer */}
            <OverlaysContainer />
            <Loader />
            <DishesSelection
                location={location}
                overlayIsOpen={overlayIsOpen}
                onClickOpenBasket={onClickOpenBasket}
            />
            <div id="react-portal-mount-node" />
            {/* daprecated FRNT-3633 */}
            {/* {showPushNotificationPopup && !overlayIsOpen && (
                <PushNotificationsPopup
                    onClickAccept={onAcceptPushNotifications}
                    onClickClose={onClosePushNotificationsPopup}
                />
            )} */}
            {/* deprecated BR-1112 */}
            {/* {allowRenderMobileStreet && showMobileStreet && (
                <Overlay
                    overlayOpen={!!showMobileStreetOverlay}
                    onClick={this.handleCloseMobileStreet}
                    // onClick={this.handleCloseMobileStreet}
                    animateEnter={animateEnterOverlay}
                    animateExit={animateExitOverlay}
                    onExited={this.onMobileStreetClosed}
                    onEntered={this.disableEnterOverlayAnimation}
                >
                    <MobileStreet
                        info={mobileStreetInfo}
                        onClose={this.handleCloseMobileStreet}
                    />
                </Overlay>
            )} */}
            {/* deprecated BR-1112 */}
            {/* <DateTimeConfirmDialogContainer
                    isDialogRequired={showDateTimeConfirmDialog}
                    type={dateTimeConfirmDialogType}
                    hasExtraText={isDateTimeConfirmDialogExtraText}
                    onConfirmOne={this.handleDateTimeConfirmOne}
                    onConfirmAll={this.handleDateTimeConfirmAll}
                    onReject={this.handleDateTimeReject}
            /> */}
            {/* deprecated BR-1112 */}
            {/* <AddressConfirmDialogContainer
                    isDialogRequired={showAddressConfirmDialog}
                    hasExtraText={isAddressConfirmDialogExtraText}
                    onConfirmOne={this.handleAddressConfirmOne}
                    onConfirmAll={this.handleAddressConfirmAll}
                    onReject={this.handleAddressReject}
                />
            */}
            {/* deprecated BR-1112 */}
            {/* <MobileTimeSelectContainer
                    isSelectRequired={showMobileTimeIntervals}
                    isDelivery={mobileTimeIntervalsInfo.deliveryId}
                    info={mobileTimeIntervalsInfo}
                    onClose={this.handleCloseMobileTimeIntervals}
                />
            */}
        </>
    );
});
