import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';


const getMedia = ({ isWideMobile, wideDesktop }) => {
    if (isWideMobile) {
        const desktopMinWidth = 1024;
        const mobileMaxWidth = desktopMinWidth - 1;

        if (wideDesktop) {
            const wideDesktopMinWidth = 1280;
            return {
                mobile: `(max-width: ${mobileMaxWidth}px)`,
                desktop: `(min-width: ${desktopMinWidth}px) and (max-width: ${wideDesktopMinWidth - 1}px)`,
                wideDesktop: `(min-width: ${wideDesktopMinWidth}px)`,
            };
        }

        return {
            mobile: `(max-width: ${mobileMaxWidth}px)`,
            desktop: `(min-width: ${desktopMinWidth}px)`,
        };
    }

    if (wideDesktop) {
        const desktopMinWidth = 1024;
        const wideDesktopMinWidth = 1280;
        const mobileMaxWidth = desktopMinWidth - 1;
        return {
            mobile: `(max-width: ${mobileMaxWidth}px)`,
            desktop: `(min-width: ${desktopMinWidth}px) and (max-width: ${wideDesktopMinWidth - 1}px)`,
            wideDesktop: `(min-width: ${wideDesktopMinWidth}px)`,
        };
    }

    const desktopMinWidth = 1024;
    const mobileMaxWidth = desktopMinWidth - 1;

    return {
        mobile: `(max-width: ${mobileMaxWidth}px)`,
        desktop: `(min-width: ${desktopMinWidth}px)`,
    };
};

export default function Responsive({
    mobile = null,
    desktop = null,
    wideDesktop = null,
    isWideMobile = true,
}) {
    const media = getMedia({ isWideMobile, wideDesktop });

    return (
        <>
            {mobile && (
                <MediaQuery query={media.mobile}>
                    {mobile}
                </MediaQuery>
            )}
            {desktop && (
                <MediaQuery query={media.desktop}>
                    {desktop}
                </MediaQuery>
            )}
            {wideDesktop && (
                <MediaQuery query={media.wideDesktop}>
                    {wideDesktop}
                </MediaQuery>
            )}
        </>
    );
}

const CHILDREN_PROP_TYPES = PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
]);

Responsive.propTypes = {
    mobile: CHILDREN_PROP_TYPES,
    desktop: CHILDREN_PROP_TYPES,
    wideDesktop: CHILDREN_PROP_TYPES,
    isWideMobile: PropTypes.bool,
};

export const Mobile = ({ children, isWideMobile = true }) => {
    const media = getMedia({ isWideMobile });
    return (
        <MediaQuery query={media.mobile}>
            {children}
        </MediaQuery>
    );
};

Mobile.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    isWideMobile: PropTypes.bool,
};

// eslint-disable-next-line react/require-default-props
export const Desktop = ({ children, isWideMobile = true }) => {
    const media = getMedia({ isWideMobile });
    return (
        <MediaQuery query={media.desktop}>
            {children}
        </MediaQuery>
    );
};


Desktop.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    isWideMobile: PropTypes.bool,
};
