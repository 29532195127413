/* eslint-disable max-len */
import endOfWeek from 'date-fns/end_of_week';

const PAGE = 'creativity';

export const allDishes = [3843, 122, 3995, 3813, 3857].map((id) => String(id));


const dishesData = allDishes.reduce((acc, id, index) => {
    const data = {
        id,
        orderNumber: index + 1,
    };
    return {
        ...acc,
        [id]: data,
    };
}, {});


export default {
    pageId: PAGE,
    pathSegment: PAGE,
    pathSegmentEn: PAGE,

    title: 'Время для творчества | Elementaree',
    description: 'Создавайте новые семейные традиции: наборы, чтобы провести интересный выходной дома',

    ogImage: '',
    additionalContent: '',
    onInit: () => { },
    appConfig: {
        pageId: PAGE,
        city: null,
        period: null,
        firstPeriodStart: '2023-05-01',
        filterByIds: allDishes,

        areAvaliableDishesPersistentForPeriods: false,

        joinDishTitlesinMobile: false,
        dishesData,
        dishCategories: {
            main: {
                id: 'main',
                title: 'Время для творчества',
                titleEn: 'Время для творчества',
                detailsTitle: 'Время для творчества',
                description: [],
                enabledDishes: allDishes,
            },
        },
        canChangePortionsCount: true,
        areDetailsEnabled: true,

        enabledBasketDishIds: [],
        autoFillingBasket: false,

        isCheckoutEnabled: true,
        isLinkToMenuEnabled: true,

        prepareDates: (dates, periodsConfig = null, period = null, basketDishesIds) => {
            const maxDate = endOfWeek(new Date(period), { weekStartsOn: 1 });

            return dates.filter((d) => {
                const date = new Date(d.date);
                return date <= maxDate;
            });
        },
        prepareIntervals: null,

        ageGate: false,
        ageGateImage: null,
    },
};
