import React from 'react';
import { useQuery } from '@apollo/client';
import BASKET_QUERY from 'app/graphql/network/basket/basketQuery';
import { DELIVERY_BASKET_QUERY } from 'app/graphql/network/deliveryCart';

import { selectedPeriodVar } from 'app/apollo/reaction';

export const useBasketQueryLocate = () => {
    const isDeliveryBasket = window.location.pathname.includes('app/delivery');
    const deliveryId = window.location.pathname.split('/').find((e) => Boolean(e) && !Number.isNaN(Number(e)));

    const basketQuery = isDeliveryBasket
        ? DELIVERY_BASKET_QUERY
        : BASKET_QUERY;

    const basketQueryVariables = isDeliveryBasket
        ? { deliveryId }
        : { period: selectedPeriodVar() };

    const {
        data: basketData,
        refetch: refetchBasket,
    } = useQuery(basketQuery, {
        variables: basketQueryVariables,
        context: {
            message: 'app:fetch:useBasketQueryLocate',
        },
    });

    return [basketData, refetchBasket];
};
