import React from 'react';
import PropTypes from 'prop-types';
// import { withRouter } from 'react-router';

// import compose from 'lodash/flowRight';

// import withABTestData from 'app/containers/contexts/abTest.context';
// import { withAuthContext } from '../AuthContainer';


import {
    isPushPopupIsClosedByUser,
    setPushPopupClosedCookie,
} from './push-notifications-cookie';


const PUSH_NOTIFICATIONS_POPUP_TIMEOUT = 15000;

// const MENU_POPUP_TYPE_FILTER_NEWS = 'filter-news';


/**
 * Компонент, который управляет появлением промо и других баннеров в меню.
 * Общая логика описана в задаче https://elementaree.atlassian.net/browse/FRNT-1808
 *
 * Промо-попапы отключены в рамках BR-943 (Бранч FRNT-2455)
 * Остается попап о распроданности блюд и попап о подписке на пуш-уведомления
 *
 * Компонент сначала отображает один из menuPopupType, затем pushNotificationPopup
 * menuPopupType прокидывается в дочерние компоненты, которые рендерят нужный попап.
 * Например, MENU_PROMO рендерится внутри Menu, а filter-news рендерится в хедере у кнопки открытия фильтров.
 * Дочерние компоненты так же учитывают, что никакой другой полноэкранный попап не открыт (например, селект порций)
 *
 * Если пользователь закрывает промо на крестик или кнопку типа "Хочу попробовать",
 * то ему в куки сохраняется какой попап он закрыл.
 * Закрытие на крестик ставит куку на сутки, закрытие на "Хочу попробовать" закрывает промо на год.
 *
 */
class PromoProvider extends React.Component {
    state = {
        mainDishesCountState: 'loading', // loaded, sold-out

        /**
         * isSoldOutNotificationVisible: null - в качесстве initial value используется,
         *      так как значение false устанавливается после закрытия юзером
         */
        isSoldOutNotificationVisible: null,
        /* deprecated FRNT-3633 */
        // showPushNotificationPopup: false,
    };

    pushNotificationPopup = {
        timer: null,
    };

    componentDidMount() {
        this.setupPushNotificationsPopupTimer();
    }

    setupPushNotificationsPopupTimer() {
        const { isPushNotificationsSupported } = this.props;

        if (!isPushNotificationsSupported || isPushPopupIsClosedByUser()) return;

        this.pushNotificationPopup.timer = setTimeout(() => {
            this.setState({
                showPushNotificationPopup: true,
            });
        }, PUSH_NOTIFICATIONS_POPUP_TIMEOUT);
    }

    /* deprecated FRNT-3633 */
    // handleAcceptPushNotifications = () => {
    //     const { onRequestPushPermission } = this.props;
    //     onRequestPushPermission();
    //     this.setState({
    //         showPushNotificationPopup: false,
    //     });
    // }

    /* deprecated FRNT-3633 */
    // handleClosePushNotificationsPopup = () => {
    //     setPushPopupClosedCookie();
    //     this.setState({
    //         showPushNotificationPopup: false,
    //     });
    // }

    // eslint-disable-next-line class-methods-use-this
    resolveNextCountState = (availableDishesCount) => {
        const minDishesCountForNotification = 2;

        if (availableDishesCount === 0) {
            return 'loaded';
        }

        if (availableDishesCount <= minDishesCountForNotification) {
            return 'sold-out';
        }

        return 'loaded';
    };

    handleMainDishesLoaded = (availableDishesCount) => {
        const nextCountState = this.resolveNextCountState(availableDishesCount);

        const { isSoldOutNotificationVisible } = this.state;
        const nextIsSoldOutNotificationVisible = isSoldOutNotificationVisible === null && nextCountState === 'sold-out';

        this.setState({
            mainDishesCountState: nextCountState,
            isSoldOutNotificationVisible: nextIsSoldOutNotificationVisible,
        });
    };

    handleCloseSoldOutNotification = () => {
        this.setState({
            isSoldOutNotificationVisible: false,
        });
    };

    render() {
        const {
            children,
        } = this.props;

        const {
            mainDishesCountState,
            isSoldOutNotificationVisible,

            showPushNotificationPopup,
        } = this.state;

        return children({
            onMainDishesLoaded: this.handleMainDishesLoaded,
            mainDishesCountState,
            isSoldOutNotificationVisible,
            onCloseSoldOutNotification: this.handleCloseSoldOutNotification,

            showPushNotificationPopup,
            // onClosePushNotificationsPopup: this.handleClosePushNotificationsPopup,
            // onAcceptPushNotifications: this.handleAcceptPushNotifications,
        });
    }
}


PromoProvider.propTypes = {
    isPushNotificationsSupported: PropTypes.bool.isRequired,
    // onRequestPushPermission: PropTypes.func.isRequired,

    children: PropTypes.func.isRequired,
};

export default PromoProvider;
