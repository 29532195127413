import React, { useContext, useMemo, useState } from 'react';
// @ts-ignore
import Burger from 'assets/svg/burger.svg';
import cn from 'classnames';

import { selectedPeriodVar } from 'app/apollo/reaction';
import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import { IFiltersContext } from 'app/containers/contexts/filters.context/filtersContext.type';
import { FiltersContext } from 'app/containers/contexts/filters.context/filters.context';
import { getDateData } from 'app/utils/date';
import { ONE_DAYS_IN_UTC_FORMAT } from 'app/containers/contexts/menuDates.consts';
import { LocaleContext } from 'app/containers/LocaleProvider';
import FilterIcon from './img/filter.component.svg';
import RightArrow from './img/right.component.svg';
import css from './mobileHeaderV2.module.scss';

const formatPeriodToTagTitle = (start: string, end: string, locale: string) => {
    const firstDateMeta = getDateData(start, locale);
    const secondDateMeta = getDateData(end, locale);
    const fisrtDateString = `${firstDateMeta?.rawData?.date} ${firstDateMeta?.monthShortNameForDate}`;
    const secondDateString = `${secondDateMeta?.rawData?.date} ${secondDateMeta?.monthShortNameForDate}`;
    return `${fisrtDateString} — ${secondDateString}`;
};

const generateDateDataFromDiff = (date: string, weekDayName: string) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    const currentDate = d.getTime() / 1000;

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayD = today.getTime() / 1000;


    const isTomorrow = `${(currentDate - todayD)}` === ONE_DAYS_IN_UTC_FORMAT;

    return isTomorrow ? 'завтра' : weekDayName;
};


export function MobileHeaderV2(props: any) {
    const {
        onClickMobileNavigationButton,
        handleOpenPopup,
        title,
        onClickFilters,
    } = props;

    const [isBurgerOpen, setIsBurgerOpen] = useState(false);
    const burgerButtonclasses = cn({
        [css.burgerButton]: true,
        [css.isOpen]: isBurgerOpen,
        [css.isClose]: !isBurgerOpen,
    });


    const { filterQuery: { menuFilter: { periods } } } = useContext<IFiltersContext>(FiltersContext);

    // Берем значение из проклятой задачи
    const {
        state: {
            selectedDate,
            filterPeriod,
        },
    } = useContext(menuDatesState);

    const { locale } = useContext(LocaleContext);


    const selectedPeriod = selectedPeriodVar();

    const period = periods.find((p: any) => p.start === selectedPeriod);

    const {
        start: selectedPeriodStart,
        end: selectedPeriodEndRaw,
        // tags,
    } = period;

    /* SELECTORS | ИНТЕРПРАЙЗ ВЫЧЕСЛЕНИЯ */
    // Функция отдает разные данные по дате
    // TODO: когда нибудь ее надо переписать на TS, этой функции это очень нужно
    // TODO: этого не достаточно чтобы отобразить дату, нужно проверить еще и период
    const dateMeta = useMemo(
        () => getDateData(selectedDate, locale),
        [selectedDate, locale],
    );

    const { weekDayName } = dateMeta;

    const deliveryDate = useMemo(() => {
        // Тут мы проверяем в одинаковых ли переодах код из проклятой задачи и период из url`а
        // Нужно для того чтобы отобразить либо интервал дат либо день
        const isFilterPeriodSelected = filterPeriod === selectedPeriod;
        return isFilterPeriodSelected
            ? generateDateDataFromDiff(dateMeta.rawData.dateValue, weekDayName)
            : formatPeriodToTagTitle(selectedPeriodStart, selectedPeriodEndRaw, locale);
    }, [
        weekDayName,
        filterPeriod,
        selectedPeriod,
        selectedPeriodStart,
        selectedPeriodEndRaw,
        locale,
        dateMeta.rawData.dateValue,
    ]);

    return (
        <div className={css.v2_mobileHeaderRoot}>
            <div className={css.burgerButtonContainer}>
                <button
                    onClick={() => {
                        setIsBurgerOpen(!isBurgerOpen);
                        onClickMobileNavigationButton();
                    }}
                    type="button"
                    className={burgerButtonclasses}
                >
                    <Burger data-referral-menu-item="true" />
                </button>
            </div>
            {!isBurgerOpen && (
                <button
                    onClick={handleOpenPopup}
                    type="button"
                    className={css.cityAndDateButtonRoot}
                >
                    <div className={css.cityAndDateContent}>
                        <div className={css.cityAndTitle}>
                            <div className={css.cityAndTitleText}>
                                дата и город доставки
                            </div>
                            <div className={css.cityAndTitleIcon}>
                                <RightArrow />
                            </div>
                        </div>
                        <div className={css.cityAndTitleLocation}>
                            <span className={css.cityAndTitleLocatoinWhen}>
                                {deliveryDate}
                                ,&nbsp;
                            </span>
                            <span className={css.cityAndTitleLocatonTo}>
                                {title}
                            </span>
                        </div>
                    </div>
                </button>
            )}
            {!isBurgerOpen && (
                <button
                    onClick={() => {
                        onClickFilters();
                    }}
                    type="button"
                    className={css.filtersButtonRoot}
                >
                    <div className={css.filtersButtonIcon}>
                        <FilterIcon />
                    </div>
                </button>
            )
            }
        </div>
    );
}
