import React, {
    FC, MutableRefObject, RefObject, useContext, useEffect, useRef, useState,
} from 'react';

/* CONTEXTS */
import { MenuDataContext } from 'app/containers/contexts/menu.context';

/* UTILS */
import MenuDishesProvider from 'app/containers/MenuDishesProvider';
import { selectDishes } from 'app/utils/dish';
import { getCategoryCount } from 'app/utils/filters';
import { analyticService } from 'global/services';

/* DATA */
import {
    CATEGORIES_ORDER,
    CATEGORY_MOST_INTRESTING,
    CATEGORY_MOST_INTRESTING_TAG_ID,
} from 'app/const/categories';

/* COMPONENTS */
import debounce from 'lodash/debounce';
import MobileCategoryTab from '../MobileCategoryTab/MobileCategoryTab';

/* STYLES */
import css from './MobileCategoriesSlider.module.scss';
import { useAvailableCategories } from '../../hooks/useAvailableCategories';


/* TYPES */
type TypeCategory = {
    id: string,
    title: string,
}

const scrollingDispatcherTimeout: number = 700;
const categoryChangeEventTimeout: number = 200;

export const MobileCategoriesSlider: FC = () => {
    /* STATE */
    const [activeCategoryId, setActiveCategoryId] = useState<string | null>(null);
    const activeCategoryIdChangedBy: MutableRefObject<'menu' | 'tab'> = useRef<'menu' | 'tab'>('menu');
    const sliderRef: RefObject<HTMLDivElement> = useRef(null);

    const { scrolling: { categoryId } } = useContext(MenuDataContext);
    const [
        filteredCategories,
        dishesProvidedData,
    ] = useAvailableCategories();

    /* HANDLERS */
    const changeScrollingDispatcher = (origin: 'menu' | 'tab'): void => {
        if (activeCategoryIdChangedBy.current === origin) return;
        activeCategoryIdChangedBy.current = origin;
    };

    const handleClickOnCategory = useRef(
        debounce(
            (id: string): void => {
                changeScrollingDispatcher('tab');
                setActiveCategoryId(id);

                setTimeout(() => {
                    changeScrollingDispatcher('menu');
                }, scrollingDispatcherTimeout);

                const categories = [
                    {
                        id: CATEGORY_MOST_INTRESTING,
                        title: 'Новое',
                    },
                    ...dishesProvidedData.categories,
                ];

                analyticService.push({
                    eventName: 'Filter_Change_Category',
                    filter_value: categories.find((e: TypeCategory) => e.id === id)?.title ?? '',
                });
            },
            scrollingDispatcherTimeout,
            { leading: true, trailing: false },
        ),
    );


    const scrollToActiveTab = (id: string): void => {
        const sliderContainer = sliderRef.current;
        const tabs = Array.from(sliderContainer?.children || []) as HTMLElement[];
        const tab = tabs.find((e) => e.dataset.category_id === id) as HTMLElement;

        if (!tab || !sliderContainer) return;

        const scrollPadding = document.documentElement.clientWidth < 375 ? 16 : 20;
        const tabLeftPosition = tab.offsetLeft;
        sliderContainer.scrollTo({
            left: tabLeftPosition - scrollPadding,
            behavior: 'smooth',
        });
    };

    /* EFFECTS */
    useEffect(() => {
        if (categoryId) {
            setActiveCategoryId(categoryId);
        }
    }, [categoryId]);

    useEffect(() => {
        if (!activeCategoryId) return;
        scrollToActiveTab(activeCategoryId);
        if (activeCategoryIdChangedBy.current === 'menu') return;
        const categoryChangeEvent = new CustomEvent('categoryChangeFromSlider', {
            bubbles: true,
            detail: {
                activeCategoryId,
            },
        });
        setTimeout(() => {
            document.dispatchEvent(categoryChangeEvent);
        }, categoryChangeEventTimeout);
    }, [activeCategoryId, activeCategoryIdChangedBy]);

    return (
        // @ts-expect-error
        <MenuDishesProvider
            menuFilter={dishesProvidedData.menuFilter}
            render={({ menuDishesQuery: { basketDishes, menuDishes: allDishes } }) => {
                const bestCategory = {
                    id: CATEGORY_MOST_INTRESTING,
                    title: 'Новое',
                    // @ts-ignore
                    count: selectDishes(
                        allDishes,
                        null,
                        [CATEGORY_MOST_INTRESTING_TAG_ID, ...dishesProvidedData.selectedTags],
                        dishesProvidedData.selectedCategories,
                    ).length,
                    isSelected: dishesProvidedData.selectedCategories.includes(CATEGORY_MOST_INTRESTING),
                };

                const bestCategoryHasDishes = dishesProvidedData.selectedTags.length > 0 ? true : bestCategory.count > 0;
                const isBestCategoryVisible = CATEGORIES_ORDER.includes(CATEGORY_MOST_INTRESTING)
                    && bestCategoryHasDishes;

                if (!filteredCategories.length) return null;

                return (
                    <div
                        className={css.categoriesSliderWrapper}
                        ref={sliderRef}
                    >
                        {isBestCategoryVisible && (
                            <div data-category_id={bestCategory.id}>
                                <MobileCategoryTab
                                    onClick={handleClickOnCategory.current}
                                    category={bestCategory}
                                    isActive={activeCategoryId === bestCategory.id}
                                />
                            </div>
                        )}
                        {filteredCategories.map((c: any) => {
                            const count = getCategoryCount({
                                id: c.id,
                                menuDishes: allDishes,
                                selectedTagIds: dishesProvidedData.selectedTags,
                                basketDishes,
                            });
                            if (dishesProvidedData.selectedTags.length === 0 && count === 0) {
                                return null;
                            }

                            return (
                                <div data-category_id={c.id} key={c.id}>
                                    <MobileCategoryTab
                                        onClick={handleClickOnCategory.current}
                                        category={c}
                                        isActive={activeCategoryId === c.id}
                                    />
                                </div>
                            );
                        })}
                    </div>
                );
            }}
        />
    );
};
