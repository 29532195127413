import React from 'react';
import PropTypes from 'prop-types';
import Raven from 'raven-js';

import client from 'app/apollo/client';
import { DELIVERY_BASKET_QUERY } from 'app/graphql/network/deliveryCart';
import { ADD_ITEM_TO_CART } from 'app/graphql/network/basket/basketMutations';
import { UPDATE_DELIVERY_DISHES } from 'app/graphql/network/profile/deliveryMutations';


/**
* TODO:
* Скорее всего, проблема, описанная ниже была из-за того,
* что basket и deliveryBasket имеют один и тот же тип и не имеют ID
*/


/**
 * Из-за бага в react-apollo DELIVERY_BASKET_QUERY неправильно обновлял данные в компонентах,
 * поэтому пришлось переписать на явные запросы через client.query и использование state
 *
 * Компонент передает в дочерний объект deliveryBasketQuery,
 * похожий по своей структуре на объекты, которые возвращает HOC graphql из react-apollo
 */
class DeliveryBasketContainer extends React.Component {
    static propTypes = {
        render: PropTypes.func.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                deliveryId: PropTypes.string,
            }),
        }).isRequired,
        history: PropTypes.shape({}).isRequired,
        selectedFilters: PropTypes.shape({
            selectedPeriod: PropTypes.string,
        }).isRequired,
    };

    state = {
        deliveryBasket: null,
        isLoadingDeliveryBasket: false,
        deliveryBasketError: null,

        isUpdatingDeliveryBasket: false,
    };

    async componentDidMount() {
        const {
            match: { params: { deliveryId } },
        } = this.props;

        if (!deliveryId) return;

        await this.fetchDeliveryBasket();
    }

    componentDidUpdate(prevProps) {
        const { match: { params: { deliveryId } } } = this.props;
        const { match: { params: { deliveryId: prevDeliveryId } } } = prevProps;

        if (prevDeliveryId && !deliveryId) {
            this.clearDeliveryBasket();
        }
    }

    setDeliveryBasketIsLoading = (value) => {
        this.setState({
            isLoadingDeliveryBasket: value,
        });
    };

    /**
     * @param variables - query variables
     * @param fetchPolicy
     */
    fetchDeliveryBasket = async (variables = {}, fetchPolicy = 'cache-first') => {
        const { match: { params: { deliveryId } } } = this.props;

        if (!deliveryId && !variables.deliveryId) return;

        this.setState({
            isLoadingDeliveryBasket: true,
        });

        try {
            const resp = await client.query({
                query: DELIVERY_BASKET_QUERY,
                variables: {
                    ...variables,
                    deliveryId: variables.deliveryId || deliveryId,
                },
                context: {
                    message: 'app:fetch:fetchDeliveryBasket',
                },
                fetchPolicy,
            });
            this.setState({
                deliveryBasket: resp.data.cart,
                isLoadingDeliveryBasket: false,
            });
        } catch (e) {
            Raven.captureException(e);
            this.setState({
                isLoadingDeliveryBasket: false,
                deliveryBasketError: e,
            });
        }
    };

    updateBasket = async (variables) => {
        const {
            match: { params: { deliveryId } },
            selectedFilters: { selectedPeriod },
        } = this.props;
        this.setState({
            isUpdatingDeliveryBasket: true,
        });
        try {
            await client.mutate({
                mutation: ADD_ITEM_TO_CART,
                variables: {
                    delivery_id: deliveryId,
                    period: selectedPeriod,
                    ...variables,
                },
            });
            await this.fetchDeliveryBasket({}, 'network-only');
            this.setState({ isUpdatingDeliveryBasket: false });
        } catch (e) {
            Raven.captureException(e);
            this.setState({ isUpdatingDeliveryBasket: false });
        }
    };

    updateDeliveryDishes = async () => {
        const {
            match: { params: { deliveryId } },
        } = this.props;

        return client.mutate({
            mutation: UPDATE_DELIVERY_DISHES,
            variables: {
                deliveryId,
            },
        });
    };

    clearDeliveryBasket() {
        const state = {
            deliveryBasket: null,
            isLoadingDeliveryBasket: false,
            deliveryBasketError: null,

            deliveryBasketValidation: null,
            isLoadingdDeliveryBasketValidation: false,
            deliveryBasketValidationError: null,
        };

        this.setState(state);
    }

    render() {
        const {
            render,
            ...otherProps
        } = this.props;
        const {
            deliveryBasket,
            isLoadingDeliveryBasket,
            deliveryBasketError,
            isUpdatingDeliveryBasket,
        } = this.state;

        const deliveryBasketQuery = {
            cart: deliveryBasket,
            loading: isLoadingDeliveryBasket,
            error: deliveryBasketError,
            refetch: this.fetchDeliveryBasket,
        };

        return render({
            deliveryBasketQuery,
            setDeliveryBasketIsLoading: this.setDeliveryBasketIsLoading,
            updateDeliveryBasket: this.updateBasket,
            updateDeliveryDishes: this.updateDeliveryDishes,
            isUpdatingDeliveryBasket,
            ...otherProps,
        });
    }
}


function withDeliveryBasket(WrappedComponent) {
    return (
        (props) => (
            <DeliveryBasketContainer
                {...props}
                render={(renderProps) => <WrappedComponent {...renderProps} />}
            />
        )
    );
}

export default withDeliveryBasket;
