/* eslint-disable camelcase */
import { useContext, useMemo } from 'react';
import { useFragment } from '@apollo/client';
import { AvailableLinksContext } from 'app/containers/contexts/availableLinks.context';
/* SOURCE */
import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import { abTestDataContext } from 'app/containers/contexts/abTest.context';
import { getAvailableLinksWithUrl, getAvailablePeriods } from 'app/utils/links';
/* FETCH */
import { ALL_PERIODS_FOR_SLIDER_CHAIN_FRAGMENT } from './graphql/periodsForSliderChain.fragment.graphql';
/* TYPES */
import { TCardKey, getPossibleLinksInSlider } from '../sets.record';

type TCardCardChain = TCardKey[]

interface RTAvailableLinks {
    id: TCardKey
    data: any
}

const defaultPeriods = {
    data: {
        periods: [],
    },
};

/**
 * @description фильтрует все доступные ссылки для слайдера и отдает массив строк
 * @returns TCardKey
 */
export const useSliderCardsChain = (): TCardCardChain => {
    /* DA */
    const allAvailableLinks = useContext(AvailableLinksContext);

    const { data: { periods } } = useFragment({
        fragment: ALL_PERIODS_FOR_SLIDER_CHAIN_FRAGMENT,
        from: {
            __ref: 'menuSidebar:app-condition',
        },
    }) || defaultPeriods;

    const {
        state: { enabledDatesData },
    } = useContext(menuDatesState);

    /* SELECTORS */
    const availablePeriods = useMemo(
        () => getAvailablePeriods(periods),
        [periods],
    );

    const specificLinks = useMemo(() => {
        const allPossibleLinks = getPossibleLinksInSlider();
        const allPossibleLinksKeys = allPossibleLinks.map((e) => e.id);

        /**
         * @note В текущем слайдере нет триала, поэтому удаляем его. Был в слайдере V1
         */
        const indexOfTrial = allPossibleLinksKeys.indexOf('TRIAL_FIRST_ORDER_SET');
        if (indexOfTrial > -1) allPossibleLinks.splice(indexOfTrial, 1);
        return allPossibleLinks;
    }, []);

    const availableLinks = useMemo<RTAvailableLinks[]>(
        () => getAvailableLinksWithUrl({
            specificLinks,
            allAvailableLinks,
            availablePeriods,
            enabledDatesData,
        }),
        [allAvailableLinks, availablePeriods, enabledDatesData, specificLinks],
    );

    return availableLinks.map((e: RTAvailableLinks) => e?.id);
};
