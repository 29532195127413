import { gql } from '@apollo/client';
import { fullAddressData } from './fragments/address.fragment';

export const FETCH_DELIVERY_ADDRESS_SESSION = gql`
    query fetchFullDeliveryAdress {
        deliveryAddress {
            address {
                ...fullAddressFragment
            }
        }
    }
    ${fullAddressData}
`;
