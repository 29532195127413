import { isStandaloneApp } from 'app/utils/browser';
import LINKS from 'app/const/links';


const {
    DELIVERY_PAGE,
    EVERYDAY_SET,
    LITE_PAGE,
    NZ_PAGE,
    PREMIUM_SET,
    QUALITY_PAGE,
    SPA_MENU,
    SPA_SUBSCRIPTION_INFO,
    TRIAL_FIRST_ORDER_SET,
    FAST_PAGE,
    SWEET_PAGE,
    SOUP_PAGE,
    BREAKFAST_PAGE,

    KIDS_PAGE,
    GREEN_PAGE,
    GUESTS_PAGE,
    HITS_PAGE,
    CREATIVITY_PAGE,
    SPEED_PAGE,
    // CHRISTMAS_PAGE,
} = LINKS;

const isStandalone = isStandaloneApp();


const newTabLinkAttrs = {
    target: '_blank',
    rel: 'noopener noreferrer',
};

const currentTabLinkAttrs = {
    target: '_self',
};

export const getTopLevelLinks = (options) => {
    const {
        onClickNavLink,
    } = options;

    return [
        // {
        //     id: CHRISTMAS_PAGE.id,
        //     data: {
        //         titleTrKey: 'Старый новый год',
        //         href: CHRISTMAS_PAGE.hrefRu,
        //         type: 'link',
        //         badge: 'tree',
        //         linkAttrs: isStandalone ? currentTabLinkAttrs : newTabLinkAttrs,
        //         handler: onClickNavLink,
        //     },
        // },
        {
            id: TRIAL_FIRST_ORDER_SET.id,
            data: {
                titleTrKey: 'mobileNav.trial',
                href: TRIAL_FIRST_ORDER_SET.hrefBasket,
                type: 'link',
                badge: 'percent',
                linkAttrs: isStandalone ? currentTabLinkAttrs : newTabLinkAttrs,
                handler: onClickNavLink,
            },
        },
        {
            id: EVERYDAY_SET.id,
            data: {
                titleTrKey: 'mobileNav.tomorrow',
                href: EVERYDAY_SET.href,
                type: 'link',
                handler: onClickNavLink,
            },
        },
        {
            id: SPA_MENU.id,
            data: {
                titleTrKey: 'mobileNav.menuDishes',
                href: SPA_MENU.href,
                handler: onClickNavLink,
                type: 'navLink',
            },
        },
    ];
};

export const getDropdownSetLinks = (options) => {
    const {
        locale,
        onClickNavLink,
    } = options;

    const isEnLocale = locale === 'en';

    return [
        {
            id: 'august',
            isStatic: true,
            data: {
                titleTrKey: 'Праздник урожая',
                title: 'Праздник урожая',
                href: '/august/',
                type: 'link',
                handler: onClickNavLink,
                trackId: 'august',
            },
        },
        {
            id: 'DACHA_PAGE',
            data: {
                title: 'На дачу',
                href: '/dacha/',
                handler: onClickNavLink,
                type: 'link',
                trackId: 'hp',
            },
        },
        {
            id: KIDS_PAGE.id,
            data: {
                title: 'Детское меню',
                href: isEnLocale ? KIDS_PAGE.hrefEn : KIDS_PAGE.hrefRu,
                type: 'link',
                handler: onClickNavLink,
                trackId: KIDS_PAGE.trackId,
            },
        },
        {
            id: GREEN_PAGE.id,
            data: {
                title: 'Green',
                href: GREEN_PAGE.href,
                type: 'link',
                handler: onClickNavLink,
                // TODO:
                trackId: null,
            },
        },
        {
            id: GUESTS_PAGE.id,
            data: {
                title: 'К приходу гостей',
                href: GUESTS_PAGE.href,
                type: 'link',
                handler: onClickNavLink,
                // TODO:
                trackId: null,
            },
        },
        {
            id: HITS_PAGE.id,
            data: {
                title: 'Хиты вне времени',
                href: HITS_PAGE.href,
                type: 'link',
                handler: onClickNavLink,
                // TODO:
                trackId: null,
            },
        },
        {
            id: CREATIVITY_PAGE.id,
            data: {
                title: 'Время для творчества',
                href: CREATIVITY_PAGE.href,
                type: 'link',
                handler: onClickNavLink,
                // TODO:
                trackId: null,
            },
        },
        {
            id: 'SOUP_PAGE',
            data: {
                titleTrKey: 'mobileNav.soups',
                href: '/produkty-dlya-supa/',
                type: 'link',
                handler: onClickNavLink,
                trackId: SOUP_PAGE.trackId,
            },
        },
        {
            id: FAST_PAGE.id,
            data: {
                titleTrKey: 'mobileNav.vegan',
                href: FAST_PAGE.hrefRu,
                type: 'link',
                handler: onClickNavLink,
                trackId: FAST_PAGE.trackId,
            },
        },
        {
            id: SPEED_PAGE.id,
            data: {
                title: 'Готовим за 15 минут',
                href: SPEED_PAGE.hrefRu,
                type: 'link',
                handler: onClickNavLink,
                trackId: FAST_PAGE.trackId,
            },
        },
        {
            id: SWEET_PAGE.id,
            data: {
                titleTrKey: 'mobileNav.sweat',
                href: SWEET_PAGE.hrefRu,
                type: 'link',
                handler: onClickNavLink,
                trackId: SWEET_PAGE.trackId,
            },
        },
        {
            id: 'BREAKFAST_PAGE',
            data: {
                titleTrKey: 'mobileNav.breakfast',
                title: 'Богемные завтраки',
                href: '/produkty-dlya-zavtraka/',
                type: 'link',
                handler: onClickNavLink,
                trackId: BREAKFAST_PAGE.trackId,
            },
        },
        {
            id: NZ_PAGE.id,
            data: {
                titleTrKey: 'mobileNav.nz',
                title: 'Про запас',
                href: isEnLocale ? NZ_PAGE.hrefEn : NZ_PAGE.hrefRu,
                type: 'link',
                handler: onClickNavLink,
                trackId: NZ_PAGE.trackId,
            },
        },
    ];
};

export const getDropdownInfoLinks = (options) => {
    const {
        locale,
        onClickNavLink,
        onClickSubscriptionInfoOpen,
    } = options;

    const isEnLocale = locale === 'en';

    const subscriptionInfoData = onClickSubscriptionInfoOpen ? {
        titleTrKey: 'mobileNav.recurrent',
        handler: onClickSubscriptionInfoOpen,
        type: 'button',
    } : {
        titleTrKey: 'mobileNav.recurrent',
        href: SPA_SUBSCRIPTION_INFO.href,
        type: 'link',
        handler: onClickNavLink,
    };

    return [
        {
            id: QUALITY_PAGE.id,
            data: {
                titleTrKey: 'mobileNav.quality',
                href: isEnLocale ? QUALITY_PAGE.hrefEn : QUALITY_PAGE.hrefRu,
                type: 'link',
                handler: onClickNavLink,
            },
        },
        {
            id: DELIVERY_PAGE.id,
            data: {
                titleTrKey: 'mobileNav.delivery',
                href: DELIVERY_PAGE.hrefRu,
                type: 'link',
                handler: onClickNavLink,
            },
        },
        {
            id: SPA_SUBSCRIPTION_INFO.id,
            data: subscriptionInfoData,
        },
        // {
        //     id: ABOUT_PAGE.id,
        //     data: {
        //         titleTrKey: 'mobileNav.about',
        //         href: isEnLocale ? ABOUT_PAGE.hrefEn : ABOUT_PAGE.hrefRu,
        //         type: 'link',
        //         handler: onClickNavLink,
        //     },
        // },
    ];
};
