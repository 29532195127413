import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './blur.scss';

const Blur = ({ isOpen, children }) => {
    const classes = classNames({
        blur: true,
        open: isOpen,
    });
    return (
        <div styleName={classes}>{children}</div>
    );
};

export default Blur;

Blur.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};
