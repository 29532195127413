/* eslint-disable no-underscore-dangle */
import { gql } from '@apollo/client';

/* UTILS */
const makePrimetiveSection = (sections) => {
    if (!sections?.length) {
        return [];
    }
    const flatSection = sections.reduce((acc, section) => [...acc, ...section.items], []);
    // eslint-disable-next-line no-underscore-dangle
    const primetiveSection = flatSection.map((item) => item.__ref);
    return primetiveSection;
};

/* FIELD */
export const primetiveSectionField = {
    read(_, { readField, cache }) {
        const sections = readField('sections');

        const sectionsWithitemsData = sections.map(({ __ref }) => {
            const sectionData = cache.readFragment({
                id: __ref,
                fragment: gql`
                    fragment FF on cartSection {
                        id
                        __typename
                        items {
                            dish_id
                            __typename
                        }
                    }

                `,
            }) || {};

            return {
                ...sectionData,
                items: sectionData?.items.map((item) => `${item?.__typename}:${item?.dish_id}`),
            };
        });
        return makePrimetiveSection(sectionsWithitemsData);
    },
};
