import Raven from 'raven-js';
import differenceInDays from 'date-fns/difference_in_days';

const DATA_KEY = 'invoiceData';


/**
 * @param {object} data -данные для кеширования:
 * @param {string} data.uid - id заказ
 * @param {string} data.phone - телефон клиента
 * @param {string} data.invoiceState - состояние заказа, нужно ли его подтверждение по смс
 * @param {string} data.deliveryDate - дата доставки
 * @param {string} data.invoicePaymentMethod - способ оплаты cash, online, recurrent
 * @param {string} data.orderDate - дата оформления заказа
 */
export const cacheInvoiceData = (data) => {
    try {
        localStorage.setItem(DATA_KEY, JSON.stringify(data));
    } catch (e) {} // eslint-disable-line no-empty
};

export const getCachedInvoiceData = () => {
    try {
        const data = JSON.parse(localStorage.getItem(DATA_KEY)) || {};
        const date = new Date(data.orderDate);

        if (!data.orderDate || differenceInDays(new Date(), date) > 1) {
            cacheInvoiceData({});
            return {};
        }
        return data;
    } catch (e) {
        Raven.captureException(e);
        return {};
    }
};

/**
 * удаляет все данные, кроме телефона пользователя
 */
export const clearCachedInvoiceData = () => {
    const { phone } = getCachedInvoiceData();
    cacheInvoiceData({ phone });
};

// const TRACK_DATA_KEY = 'trackPurchaseData';

// export const cacheTrackPurchaseData = (data) => {
//     try {
//         sessionStorage.setItem(TRACK_DATA_KEY, JSON.stringify(data));
//     } catch (e) {} // eslint-disable-line no-empty
// };

// export const getCachedTrackPurchaseData = () => {
//     try {
//         return JSON.parse(sessionStorage.getItem(TRACK_DATA_KEY));
//     } catch (e) {
//         Raven.captureException(e);
//         return null;
//     }
// };

// export const clearCachedTrackPurchaseData = () => sessionStorage.removeItem(TRACK_DATA_KEY);
