import { getMobileEventLable } from '../../events.utils';

export const BR1125EventsRecord = [
    /**
     * @event 'Filter_Change_Category'
     */
    {
        eventName: 'Filter_Change_Category',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName } = data;
            return {
                ...data,
                event: eventName,
            };
        },
    },
    /**
     * @event 'Filter_Open_City'
     */
    {
        eventName: 'Filter_Open_City',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName } = data;
            return {
                ...data,
                event: eventName,
            };
        },
    },
    /**
     * @event 'Filter_Change_City'
     */
    {
        eventName: 'Filter_Change_City',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName } = data;
            return {
                ...data,
                event: eventName,
            };
        },
    },
    /**
     * @event 'Filter_Open_Week'
     */
    {
        eventName: 'Filter_Open_Week',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName } = data;
            return {
                ...data,
                event: eventName,
            };
        },
    },
    /**
     * @event 'Filter_Change_Week'
     */
    {
        eventName: 'Filter_Change_Week',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName, eventParams } = getMobileEventLable(data);
            return {
                event: eventName,
                eventParams,
            };
        },
    },
];
