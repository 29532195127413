import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ScrollLock from 'react-scrolllock';
import { UIButton } from 'app/components/ui';
import { analyticService } from 'global/services';

import { Mobile, Desktop } from 'app/components/Responsive';
import Spinner from 'app/components/Spinner';

import './customization-comment-form.scss';


const MAX_COMMENT_LENGTH = 2000;

const locales = {
    ru: {
        titleText: 'Исключить\nиз рациона что-то еще?',
        placeholderText: 'Напишите, что именно вы хотите исключить из рациона',
        buttonText: 'Отправить пожелания',
    },
    en: {
        titleText: 'Want to exclude some other ingredients?',
        placeholderText: 'Write us what exactly you want to exclude from the diet.',
        buttonText: 'Submit',
    },
};

const prepareLocationName = () => {
    const uri = window.location.pathname;
    if (!uri) {
        return '/';
    }
    const lastPath = uri
        .split('/')
        .filter((e) => e)
        .filter((e, i) => i === 0)
        .map((e) => (e === 'app' ? 'profile' : e))
        .join('');
    return lastPath;
};

class CustomizationCommentForm extends React.Component {
    constructor(props) {
        super(props);

        this.textareaRef = React.createRef();
    }

    componentDidMount() {
        const { isSidebar } = this.props;
        const textareaEl = this.textareaRef.current;
        if (isSidebar) {
            textareaEl.focus();
            textareaEl.setSelectionRange(MAX_COMMENT_LENGTH, MAX_COMMENT_LENGTH); // set cursor at the comment end
        }
    }

    handleChangeComment = ({ target: { value } }) => {
        const { onChangeComment } = this.props;
        onChangeComment({ value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { onSubmitComment } = this.props;
        analyticService.push({
            eventName: 'Form_Wish',
            eventLocation: prepareLocationName(),
        });
        onSubmitComment();
    }

    render() {
        const {
            commentFormRef,
            commentContainerRef,
            scrollingTargetRef,
            textareaRef,

            commentValue,
            isCommentSaving,
            isCommentSaved,
            isInitialCommentValue,
            isCommentAreaShown,

            onFocusComment,
            locale,
        } = this.props;

        const { placeholderText, titleText, buttonText } = locales[locale];

        const isShownSpinner = isCommentSaving || isCommentSaved;
        const value = commentValue || ''; // null replace by empty string to hide warning in console

        const textareaEl = textareaRef || this.textareaRef;
        const saveCommentButtonStyle = classNames({
            'save-comment-button': true,
            invisible: isInitialCommentValue || !isCommentAreaShown,
        });

        return (
            <form
                styleName="comment-form"
                ref={commentFormRef}
            >
                <Desktop>
                    <h2 styleName="comment-form__title">{titleText}</h2>
                </Desktop>

                <div styleName="comment-container" ref={commentContainerRef}>
                    <Mobile>
                        <div styleName="comment-scrolling-target" ref={scrollingTargetRef} />
                    </Mobile>

                    <textarea
                        styleName="comment-area"
                        placeholder={placeholderText}
                        onChange={this.handleChangeComment}
                        onFocus={onFocusComment}
                        ref={textareaEl}
                        value={value}
                        maxLength={MAX_COMMENT_LENGTH}
                    />
                    <div styleName={saveCommentButtonStyle}>
                        <UIButton
                            onClick={this.handleSubmit}
                            aria-label="Отправить пожелания"
                            centerContent={buttonText}
                        />
                    </div>

                    <div styleName="spinner-block">
                        <Spinner
                            isShown={isShownSpinner}
                            isLoading={isCommentSaving}
                            isSuccessed={isCommentSaved}
                        />
                    </div>
                </div>

                {isCommentAreaShown && <ScrollLock />}
            </form>
        );
    }
}

CustomizationCommentForm.propTypes = {
    commentFormRef: PropTypes.shape({}),
    commentContainerRef: PropTypes.shape({}),
    scrollingTargetRef: PropTypes.shape({}),
    textareaRef: PropTypes.shape({}),

    isCommentSaving: PropTypes.bool.isRequired,
    isCommentSaved: PropTypes.bool.isRequired,
    isInitialCommentValue: PropTypes.bool.isRequired,
    isCommentAreaShown: PropTypes.bool.isRequired,
    isSidebar: PropTypes.bool,

    commentValue: PropTypes.string,

    onChangeComment: PropTypes.func.isRequired,
    onSubmitComment: PropTypes.func.isRequired,
    onFocusComment: PropTypes.func,
    locale: PropTypes.string.isRequired,
};

const CustomizationCommentFormFC = (props) => {
    const {
        commentFormRef = null,
        commentContainerRef = null,
        scrollingTargetRef = null,
        textareaRef = null,
        isSidebar = false,
        commentValue = null,
        onFocusComment = () => {},
    } = props;
    return (
        <CustomizationCommentForm
            {...props}
            commentFormRef={commentFormRef}
            commentContainerRef={commentContainerRef}
            scrollingTargetRef={scrollingTargetRef}
            textareaRef={textareaRef}
            isSidebar={isSidebar}
            commentValue={commentValue}
            onFocusComment={onFocusComment}
        />
    );
};

export default CustomizationCommentFormFC;
