const VEIWPORT_STATE_EXITED = 'exited';
const VEIWPORT_STATE_ENTERED = 'entered';
const VEIWPORT_STATE_ENTERING = 'entering';
const VIEWPORT_STATE_EXITING = 'exiting';

const DIRECTION_FROM_BOTTOM = 'from-bottom';
const DIRECTION_TO_BOTTOM = 'to-bottom';
const DIRECTION_FROM_TOP = 'from-top';
const DIRECTION_TO_TOP = 'to-top';


const getViewportState = ({
    el,
    viewportState = { state: null },
    viewportPadding = {},
}) => {
    const viewportTop = viewportPadding.top || 0;
    const viewportPaddingBottom = viewportPadding.bottom || 0;
    const viewportBottom = document.documentElement.clientHeight - viewportPaddingBottom;
    const elRect = el.getBoundingClientRect();

    if ((elRect.top < viewportTop && elRect.bottom < 0) || elRect.top > viewportBottom) {
        return { state: VEIWPORT_STATE_EXITED, direction: null };
    }

    if (
        (elRect.top >= viewportTop && elRect.bottom <= viewportBottom)
        || (elRect.top <= viewportTop && elRect.bottom >= viewportBottom)
    ) {
        return { state: VEIWPORT_STATE_ENTERED, direction: null };
    }

    // Появляется снизу или уходит вниз
    if (elRect.top <= viewportBottom && elRect.top > viewportTop && elRect.bottom > viewportBottom) {
        if (viewportState.state === VEIWPORT_STATE_EXITED || viewportState.state === VEIWPORT_STATE_ENTERING || !viewportState.state) {
            return {
                state: VEIWPORT_STATE_ENTERING,
                direction: DIRECTION_FROM_BOTTOM,
            };
        }
        if (viewportState.state === VEIWPORT_STATE_ENTERED || viewportState.state === VIEWPORT_STATE_EXITING || !viewportState.state) {
            return {
                state: VIEWPORT_STATE_EXITING,
                direction: DIRECTION_TO_BOTTOM,
            };
        }
    }

    // Появляется сверху или уходит вверх
    if (elRect.top < viewportTop && elRect.bottom < viewportBottom) {
        if (viewportState.state === VEIWPORT_STATE_EXITED || viewportState.state === VEIWPORT_STATE_ENTERING || !viewportState.state) {
            return {
                state: VEIWPORT_STATE_ENTERING,
                direction: DIRECTION_FROM_TOP,
            };
        }
        if (viewportState.state === VEIWPORT_STATE_ENTERED || viewportState.state === VIEWPORT_STATE_EXITING || !viewportState.state) {
            return {
                state: VIEWPORT_STATE_EXITING,
                direction: DIRECTION_TO_TOP,
            };
        }
    }
    // TODO: exited to bottom не всегда срабатывает, и функция возвращает null
    return null;
};


class ScrollHandler {
    constructor() {
        this.listenToScroll();
    }

    viewportListeners = []

    scrollListeners = []

    listenToScroll() {
        let scrollPos = 0;
        let ticking = false;
        const scrollHandler = () => {
            scrollPos = window.scrollY;
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    this.handleScroll(scrollPos);
                    ticking = false;
                });

                ticking = true;
            }
        };
        window.addEventListener('scroll', scrollHandler, { passive: true });
    }

    handleScroll(scrollPos) {
        this.viewportListeners.forEach((listenerData) => {
            const {
                el, callback, viewportState, viewportPadding,
            } = listenerData;
            const nextViewportState = getViewportState({
                el, viewportState, viewportPadding,
            });
            // eslint-disable-next-line
            listenerData.viewportState = nextViewportState;
            if (viewportState && nextViewportState && nextViewportState.state === viewportState.state) return;
            if (nextViewportState) callback(nextViewportState);
        });
        this.scrollListeners.forEach(listener => listener(scrollPos));
    }

    observePosition(listenerData) {
        this.viewportListeners.push({
            ...listenerData,
        });
    }

    stopObservingPosition({ el: removingEl }) {
        this.viewportListeners = this.viewportListeners.filter(({ el }) => el !== removingEl);
    }

    addScrollListener(listener) {
        if (this.scrollListeners.includes(listener)) return;
        this.scrollListeners.push(listener);
    }

    removeScrollListener(listener) {
        this.scrollListeners = this.scrollListeners.filter(l => l !== listener);
    }
}

const scrollHandler = new ScrollHandler();

export default scrollHandler;
