/* eslint-disable max-len */
import React from 'react';

export const dishesDetailTexts = {
    3784: <>
        &nbsp;&nbsp;&nbsp;&nbsp;Кто украл сэндвич Росса?
        <br />
        <br />
        После Дня Благодарения Моника по традиции всегда готовит для своего брата особенный сэндвич с оставшейся индейкой.Но для Росса самая вкусная часть в блюде — средний кусок хлеба, пропитанный подливкой.Он называет это «увлажнителем».
        На работе Росс положил сэндвич в холодильник, но кто- то украл перекус… В 9 серии 5 сезона разворачиваются нешуточные страсти.
        <br />
        <br />
        Если после просмотра этого эпизода вы тоже всегда хотели попробовать украденный у Росса сэндвич, вам повезло! Теперь вы можете заказать набор для его приготовления.Но если планируете брать сэндвич с собой на работу, советуем все - таки подписать ланч - бокс: «М - о - о - о - й сэндвич!»
        А то мало ли что.
        <br />
        <br />
        <span className="bold-text">
            &nbsp;&nbsp;&nbsp;&nbsp;Смотрите 330 + ТВ - каналов и более 6500 фильмов в разделе КИНО в видеосервисе Смотрёшка.
            Бесплатная подписка на 60 дней по промокоду FORYOU.
        </span>
    </>,
    3791: <>
        &nbsp;&nbsp;&nbsp;&nbsp;Каждый день недели — определенная еда, и ничто не должно нарушать этот порядок, ведь к этому пищеварительная система Шелдона не готова. Среда: индийская кухня, четверг: пицца, пятница: китайская еда. Хотели бы присоединиться к тусовке Шелдона? Предлагаем сразу окунуться в пятницу: с курицей Кунг-Пао и видео-играми.
        <br />
        <br />
        <span className="bold-text">
            &nbsp;&nbsp;&nbsp;&nbsp;Смотрите 330+ ТВ-каналов и более 6500 фильмов в разделе КИНО в видеосервисе Смотрёшка.
            Бесплатная подписка на 60 дней по промокоду FORYOU.
        </span>
    </>,
    3789: <>
        &nbsp;&nbsp;&nbsp;&nbsp;Почувствовать себя супергероем после дня, полного сверхзадач? Да, так можно! Для это нужна особая «комфортная» еда — такую выбирают Мстители после тяжелой битвы.
        «Вы пробовали шаурму? В двух кварталах отсюда делают какую-то шаурму. Не знаю, что это, но мне хочется», — говорит Железный человек.
        <br />
        <br />
        <span className="bold-text">
            &nbsp;&nbsp;&nbsp;&nbsp;Смотрите 330+ ТВ-каналов и более 6500 фильмов в разделе КИНО в видеосервисе Смотрёшка.
            Бесплатная подписка на 60 дней по промокоду FORYOU.
        </span>
    </>,
    3790: <>
        – А знаешь, как в Париже называют Четвертьфунтовый чизбургер?
        – Они не называют его «Четвертьфунтовый чизбургер»?
        – Нет, у них метрическая система, они не знают, что такое четверть фунта…
        <br />
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;Вскоре после этого диалога Джулс и Винсент едят бургеры из сети фаст-фуд ресторанов «Биг Кахуна». Эту сеть придумал для своих фильмов режиссер Квентин Тарантино. А мы вдохновились и придумали рецепт, который пришелся бы по вкусу герояем: с чеддером и барбекю.
        <br />
        <br />
        <span className="bold-text">
            &nbsp;&nbsp;&nbsp;&nbsp;Смотрите 330+ ТВ-каналов и более 6500 фильмов в разделе КИНО в видеосервисе Смотрёшка.
            Бесплатная подписка на 60 дней по промокоду FORYOU.
        </span>
    </>,
    3782: <>
        &nbsp;&nbsp;&nbsp;&nbsp;Простые радости Амели: готовим знаменитый десерт крем-брюле! “Амели” – знаменитый фильм Жана-Пьера Жене, принесший мировую славу Одри Тоту, исполнительнице главной роли. Кинокартина входит в топ подборку с лучшими фильмами для осенних вечеров.
        <br />
        <br />
        <span className="bold-text">
            &nbsp;&nbsp;&nbsp;&nbsp;Смотрите 330+ ТВ-каналов и более 6500 фильмов в разделе КИНО в видеосервисе Смотрёшка.
            Бесплатная подписка на 60 дней по промокоду FORYOU.
        </span>
    </>,
    4640: <>
        &nbsp;&nbsp;&nbsp;&nbsp;«Я завел правило: каждый день делать себе подарки, как бы ни был занят: новую рубашку или чашечку кофе с вишневым пирогом».
        Знаете, сколько кусочков этого знаменитого пирога съел агент Купер за весь «Твин Пикс»? Много! Уже во второй серии, не доев первую порцию, он просит принести ещё 2! Не удивимся, если и вы попросите добавки, ведь он такоооой.....
        <br />
        <br />
        <span className="bold-text">
            &nbsp;&nbsp;&nbsp;&nbsp;Смотрите 330+ ТВ-каналов и более 6500 фильмов в разделе КИНО в видеосервисе Смотрёшка.
            Бесплатная подписка на 60 дней по промокоду FORYOU.
        </span>
    </>,
    4178: <>
        &nbsp;&nbsp;&nbsp;&nbsp;«Габриэль Солис, она живёт в конце квартала, пришла со жгучей паэльей. Ещё на нью-йоркских подиумах Габриэль пристрастилась к острым блюдам и богатым мужчинам. Паэлью Габриэль любила с пылу с жару».
        И вам рекомендуем наслаждаться ею сразу после приготовления! Кажется, это отличный повод позвать подруг, насладиться испанским ужином и немного посекретничать.
        <br />
        <br />
        <span className="bold-text">
            &nbsp;&nbsp;&nbsp;&nbsp;Смотрите 330+ ТВ-каналов и более 6500 фильмов в разделе КИНО в видеосервисе Смотрёшка.
            Бесплатная подписка на 60 дней по промокоду FORYOU.
        </span>
    </>,
    3865: <>
        — А она хоть итальянка?
        — Её зовут Белла!
        Когда Эдвард решает познакомить Беллу с семьей, Каллены готовят пасту. Увы, Белла так её и не попробовала, а вот у вас такая возможность теперь есть. Вы ведь голодны?
        <br />
        <br />
        <span className="bold-text">
            &nbsp;&nbsp;&nbsp;&nbsp;Смотрите 330+ ТВ-каналов и более 6500 фильмов в разделе КИНО в видеосервисе Смотрёшка.
            Бесплатная подписка на 60 дней по промокоду FORYOU.
        </span>
    </>,
    4644: <>
        &nbsp;&nbsp;&nbsp;&nbsp;Стоит только сейлор-мун открыть свой бенто-бокс, взять палочки и...— о нет! Вновь нападение злодеев и нужно перевоплощаться. Не волнуйтесь, вам геройствовать не придётся, ведь мы привезём всё для аниме-обеда прямо к двери.
        <br />
        <br />
        <span className="bold-text">
            &nbsp;&nbsp;&nbsp;&nbsp;Смотрите 330+ ТВ-каналов и более 6500 фильмов в разделе КИНО в видеосервисе Смотрёшка.
            Бесплатная подписка на 60 дней по промокоду FORYOU.
        </span>
    </>,
    4645: <>
        &nbsp;&nbsp;&nbsp;&nbsp;Фрэнк Андервуд из сериала «Карточный домик» принимает решения государственного масштаба, но обязательно находит время, чтобы съесть любимые рёбрышки от Фредди в закусочной на окраине Вашингтона!
        Рецепт держится в строжайшем секрете, но мы его раскрыли.
        Рекомендуем отвлечься от трудовых будней: посмаковать свиные рёбрышки и после — откинуться на спинку стула, как Фрэнсис. Ещё бы, такая карамелизированная корочка и нежнейшее мясо!
        <br />
        <br />
        <span className="bold-text">
            &nbsp;&nbsp;&nbsp;&nbsp;Смотрите 330+ ТВ-каналов и более 6500 фильмов в разделе КИНО в видеосервисе Смотрёшка.
            Бесплатная подписка на 60 дней по промокоду FORYOU.
        </span>
    </>,
};
