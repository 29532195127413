import { gql } from '@apollo/client';

// export const SET_PROMOCODE = gql`
//     mutation SetPromocode_DONE($promocode: String) {
//         setPromocode_DONE(promocode: $promocode) @client
//     }
// `;

export const GET_PROMOCODE = gql`
    query getPromoCode @client {
        promocode
    }
`;
