import { useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import { OverlayContextDispatch } from 'app/containers/contexts/overlay.context';
import { LocaleContext } from 'app/containers/LocaleProvider';

import { mapInfotoSelect } from '../potions-select-utils';


const MobilePortionsSelectContainer = (props) => {
    const {
        info,
        isSelectRequired,
        onClose,
    } = props;

    const {
        portions, portion, weight, portionAlias, onChange,
    } = info;

    const { pushSelectMobileOverlay, closeLastOverlays } = useContext(OverlayContextDispatch);
    const { locale } = useContext(LocaleContext);

    const handleDone = useCallback(() => {
        closeLastOverlays();
        onClose();
    }, [onClose, closeLastOverlays]);

    const handleChangePortion = useCallback((index) => {
        const value = portions[index];

        onChange({ value });
        handleDone();
    }, [handleDone, onChange, portions]);

    useEffect(() => {
        if (isSelectRequired && portions) {
            const selectData = mapInfotoSelect({
                locale,
                info,
                handleChangePortion,
                onClose: handleDone,
            });

            pushSelectMobileOverlay('mobile_portions_select', selectData, { onClick: handleDone });
        }
    }, [
        handleChangePortion,
        isSelectRequired,
        locale,
        portionAlias,
        portions,
        pushSelectMobileOverlay,
        weight,
        handleDone,
        portion,
        info,
    ]);


    return null;
};

MobilePortionsSelectContainer.propTypes = {
    info: PropTypes.shape({
        portionAlias: PropTypes.arrayOf(PropTypes.string),
        id: PropTypes.string,
        portions: PropTypes.arrayOf(PropTypes.number),
        weight: PropTypes.number,
        portion: PropTypes.number,
        onChange: PropTypes.func,
    }),
    onClose: PropTypes.func.isRequired,
};



export default MobilePortionsSelectContainer;

