/* eslint-disable camelcase */
import every from 'lodash/every';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';


import { CATEGORIES_ORDER } from 'app/const/categories';
// TODO: вынести id тегов в константы
import { STANDARD_FILTER_ID } from 'app/utils/filters';

import pluralize from 'app/utils/pluralize';

import {
    TWO_DAYS_IN_UTC_FORMAT,
    THREE_DAYS_IN_UTC_FORMAT,
} from 'app/containers/contexts/menuDates.consts';

export const SOLD_OUT_STATUS = 2;

/*
    Размеры menuImageSize и menuImageSizeMobile специально указаны в 2 раза больше
    для экранов с больим разрешением
*/
export const menuImageSize = {
    w: 1300,
    h: 726,
};

// export const menuImageSizeMobile = {
//     w: 1500, // 704,
//     h: 1176, // 552,
// };

// For Dish302
export const menuImageSizeMobile = {
    w: 776,
    h: 776,
};


export const detailsImageSize = {
    w: 1774,
    h: 760,
};

export const basketImageSize = {
    w: 190,
    h: 190,
};

export const menuPlaceholderImageSize = {
    w: 176,
    h: 138,
};

export const getImagePlaceholderUrl = ({ url, w, h }) => `${url}?w=${w}&h=${h}`;

export const getImageUrl = ({ url, w, h }) => {
    /*
        Новые картинки блюд с сервера static.elementaree.ru запрашиваются без ресайзов и ухудшения качества
        Остальные картинки ресайзятся под нужные размеры
    */
    if (!url) return null;
    if (url.match('static.elementaree') || url.match('st.staging.elementaree')) {
        return url;
    }
    return `${url}?w=${w}&h=${h}`;
};

export const getPriceForSubscription = (price) => Math.ceil(price * 0.9);


const findSibling = (dishes, dish) => {
    const sibling = dishes.find((d) => {
        const isSameParent = d.parentId === dish.parentId;
        const isSameCategory = d.categoryId === dish.categoryId;
        const isDifferentDishes = d.id !== dish.id;

        return (isSameParent && isSameCategory && isDifferentDishes);
    });
    return sibling;
};


const TOP_PRIORITY_FROM = 90;

/**
 * Сортирует блюда по категориям, распроданности и приоритету
 *
 * @param dishes - массив блюд с полями visibleStatus и priority
 * @returns sortedDishes - новый массив отсортированных блюд
 *
 * Блюда делятся на 2 категории - нераспроданные и распроданные
 * Внутри каждой категории сначала идут блюда с priority > 90, затем все остальные,
 * но при этом блюда упорядочиваются по возрастанию priority
 */
export const sortDishesByPriorityAndVisibility = (dishes) => {
    const categorized = CATEGORIES_ORDER.map((cid) => dishes.filter((d) => String(d.categoryId) === cid));

    const sorted = categorized.map((categoryDishes) => {
        const sortedDishes = sortBy(categoryDishes, [
            (dish) => dish.visibleStatus === SOLD_OUT_STATUS,
            (dish) => dish.priority < TOP_PRIORITY_FROM,
            (dish) => dish.priority,
        ]);
        return sortedDishes;
    }).flat();

    return sorted;
};


/**
 * @param {stirng} dateFilterStatus количество дней от текущей даты в Unix Epoch(секундах) формате.
 * @example "86400" = сутки. Date.now() + c* 1000 = текущая дата + сутки.
*/
// TODO: BR-1019
const filterDishByDeliveryDay = (sortedDishes, eeAvailableDates, dateFilterStatus, cityCtxDateExtender) => {
    const x2 = String(+TWO_DAYS_IN_UTC_FORMAT + +cityCtxDateExtender);
    const x3 = String(+THREE_DAYS_IN_UTC_FORMAT + +cityCtxDateExtender);

    switch (dateFilterStatus) {
        case x2: {
            return eeAvailableDates ? sortedDishes.filter((e) => e.availableStatus !== 11) : [];
        }
        case x3: {
            return eeAvailableDates ? sortedDishes : [];
        }
        default: {
            return eeAvailableDates ? sortedDishes : [];
        }
    }
};

/**
* Выборка блюд из общего списка по заданным параметрам
* @param {array} allDishes - общий массив блюд
* @param {string} categoryId - id категории, по которой фильтруются блюда
*       если не передан, то функция вернет блюда из всех категорий или отфильтрует по массиву selectedCategoryIds
* @param {array} tagIds - массив id тегов, по которым фильтруются блюда
*       если не передан, то функция отфильтрует блюда по тегу Стандартное STANDARD_TAG_ERP_ID
* @param {array} selectedCategoryIds - массив категорий, по которым будут отфильтрованы блюда
* @param {array} basketDishes - массив блюд, которые уже находятся в корзине.
*       если параметр передан, то эти блюда будут исключены из списка
*
* @param {boolean} eeAvailableDates - есть ли доступные даты доставки в периоде
* @param {stirng} dateFilterStatus количество дней от текущей даты в Unix Epoch(секундах) формате.
*/

export const selectDishes = (
    allDishes,
    categoryId = null,
    tagIds,
    selectedCategoryIds,
    basketDishes = [],
    eeAvailableDates,
    dateFilterStatus,
    cityCtxDateExtender,
) => {
    const resultDishes = [];

    const standardTags = tagIds.concat(STANDARD_FILTER_ID);

    /*
        TODO: написать на это тесты
    */

    /**
     * Блюда группируются по parent Id и категории (типу), чтобы потом выбрать из каждой группы только одно блюдо
     * Учитывать тип нужно, так блюда с одинаковым parent Id могут относиться к разным типам
     */
    const parentGroups = groupBy(allDishes, (dish) => {
        const {
            parentId,
            categoryId: dishCategoryId,
        } = dish;
        return `${dishCategoryId}_${parentId}`;
    });

    /**
     * Подбор блюд под фильтры
     * Сначала происходит поиск стандартного блюда, которое подходит под выбранные теги - standardDish
     * Затем производится поиск первого попавшегося блюда, которое подходит под выбранные теги - firstMatchedDish
     * Из них предпочтение отдается стандартному блюду
     *
     * В результат не добавляются блюда, если они относятся не к выбранным типам
     * и если блюдо с таким parent id уже добавлено в корзину
     */

    // eslint-disable-next-line no-unused-vars
    Object.entries(parentGroups).forEach(([key, group]) => {
        const standardDish = group.find((dish) => {
            const isDishHasTags = every(standardTags, (tagId) => dish.tags.find((t) => t.id === tagId));
            return isDishHasTags;
        });
        const firstMatchedDish = group.find((dish) => {
            const isDishHasTags = every(tagIds, (tagId) => dish.tags.find((t) => t.id === tagId));
            return isDishHasTags;
        });

        const dishForResult = standardDish || firstMatchedDish;

        if (!dishForResult) return;

        if (categoryId && String(dishForResult.categoryId) !== categoryId) return;
        if (selectedCategoryIds) {
            const isDishFromSelectedCategory = selectedCategoryIds.includes(String(dishForResult.categoryId));
            if (!isDishFromSelectedCategory) return;
        }

        const siblingInBasket = findSibling(basketDishes, dishForResult);

        if (siblingInBasket) {
            const hasSiblingSelectedTags = every(tagIds, (tagId) => siblingInBasket.tags.find((t) => t.id === tagId));
            const hasSiblingStandardTag = siblingInBasket.tags.find((t) => t.id === STANDARD_FILTER_ID);

            if (tagIds.length === 0 && hasSiblingStandardTag) return;

            if (tagIds.length > 0 && hasSiblingSelectedTags) return;
        }

        resultDishes.push(dishForResult);
    });

    const sortedDishes = sortDishesByPriorityAndVisibility(resultDishes);


    if (dateFilterStatus) {
        const sortedDishes2 = filterDishByDeliveryDay(
            sortedDishes, eeAvailableDates, dateFilterStatus, cityCtxDateExtender,
        );
        return sortedDishes2;
    }

    return sortedDishes;
};


export const getDishesFromBasketSections = (sections) => {
    const basketDishItems = sections.map((section) => {
        const { items } = section;
        const dishesWithSectionTitle = items.map((item) => {
            const dishData = {
                ...item.dish,
                sectionTitle: section.title,
                portion: item.portions,
                itemPrice: item.price,
            };
            return dishData;
        });
        return dishesWithSectionTitle;
    });

    return flatten(basketDishItems);
};

export const formatCookUntil = (str, overrideText = null, overrideDays = null) => {
    if (typeof str !== 'string') return {};

    const r = /(\D*)(\d*)(\D*)/;
    const [text, number, days] = str
        .split(r)
        .map((s) => s.trim())
        .filter((s) => s !== '');

    if (!number) return {};

    return {
        text: overrideText || text,
        number,
        days: overrideDays || days,
    };
};

export const createShelfLifeData = (
    str,
    text = 'Срок хранения:',
    daysArray = ['день', 'дня', 'дней'],
) => {
    if (typeof str !== 'string') return {};

    const r = /(\D*)(\d*)(\D*)/;
    const [, number] = str.split(r).map((s) => s.trim()).filter((s) => s !== '');

    const days = (num) => pluralize(num, daysArray);

    if (!number) return {};

    return {
        text,
        number,
        days: days(number),
    };
};

export const formatComposition = (str) => {
    if (typeof str !== 'string') return null;

    const typifyFragment = (fragment) => {
        if (typeof fragment === 'string') {
            return { type: 'string', content: fragment };
        }
        return fragment;
    };

    const addLineBreaks = (arr) => arr.reduce((acc, i, index) => {
        if ((index === 0) || (arr.length === 1)) return [...acc, i];

        const newAcc = [...acc, { type: 'lineBreak' }, i];
        return newAcc;
    }, []);

    const addTrailingDot = (string) => (string.endsWith('.') ? string : `${string}.`);

    const normalizedStr = str.trim();

    const arr = normalizedStr.split('\r\n');
    const arrWithLineBreaks = addLineBreaks(arr);

    return arrWithLineBreaks
        .map((i) => (typeof i === 'string' ? typifyFragment(i) : i))
        .flat()
        .filter((s) => s)
        .map((i, index, array) => {
            if (!array.length) return;

            const lastIndex = index === array.length - 1;

            // eslint-disable-next-line consistent-return
            return !lastIndex
                ? i
                : { ...i, content: addTrailingDot(i.content) };
        });
};
