import { getMobileOperatingSystem, isStandaloneApp } from 'app/utils/browser';

export function getPlatform() {
    const os = getMobileOperatingSystem();
    const isApp = isStandaloneApp();

    const platformPrefix = isApp ? 'app' : 'web';

    const osPair = Object.entries(os).find(pair => Boolean(pair[1]));
    const platformSuffix = osPair ? osPair[0] : null;

    if (platformSuffix) {
        return `${platformPrefix}_${platformSuffix}`;
    }

    return platformPrefix;
}

export default {
    getPlatform,
};
