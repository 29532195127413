import React, {
    FC, useCallback, useContext, useEffect, useId, useState,
} from 'react';
import { Portal } from 'react-portal';
import { useHistory } from 'react-router';
import classNames from 'classnames';

import { DialogStatic } from 'app/components/Dialog/BR1156/DialogStatic';
import { UIToggleSwitch } from 'app/components/ui';
import { LocaleContext } from 'app/containers/LocaleProvider';
import { isDesktop } from 'app/utils/resolution';
import { AuthContext } from 'app/containers/AuthContainer';
import { dialogTexts } from './dialogTextsRecord';

import css from './PaperRecipeRowV2.module.scss';
import RecipeIcon from './img/PrintReceipt.component.svg';

interface IProps {
    isPaperRecipeDisabled: boolean,
    togglePaperRecipeSwitch: (value: boolean) => void,
    realm: 'basket' | 'checkout'
}

export const PaperRecipeRowV2: FC<IProps> = (props: IProps) => {
    const {
        isPaperRecipeDisabled,
        togglePaperRecipeSwitch,
        realm,
    } = props;

    const history = useHistory();
    const authData = useContext(AuthContext);
    const user: Record<string, any> | undefined = authData?.userData || undefined;

    const addPaperRecipeFromBasket: string | null = sessionStorage.getItem('addPaperRecipe');
    const paperRecipeFromUser = user ? user?.printedRecipes : false;
    const paperRecipeInitialState = addPaperRecipeFromBasket !== null
        ? JSON.parse(addPaperRecipeFromBasket)
        : paperRecipeFromUser;

    const [isDialogOpenned, setIsDialogOpenned] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(paperRecipeInitialState);
    const [isDesktopResolution, setIsDesktopResolution] = useState<boolean>(isDesktop());
    const { locale } = useContext(LocaleContext);
    const labelId = useId();

    const checkDesktopResolution = useCallback(
        () => setIsDesktopResolution(isDesktop()),
        [],
    );

    const togglePaperOrDigitRecipe = () => {
        togglePaperRecipeSwitch(!isChecked);
        setIsChecked(!isChecked);
    };

    const onReject = () => {
        setIsDialogOpenned(false);
        history.push('/menu');
    };

    useEffect(
        () => {
            window.addEventListener(
                'resize',
                checkDesktopResolution,
            );
            return () => {
                window.removeEventListener('resize', checkDesktopResolution);
            };
        },
        [checkDesktopResolution],
    );

    const recipeContentTextClasses = classNames({
        [css.paperRecipeRowContentText]: true,
        enabled: isChecked,
        disabled: !isChecked,
    });

    return (
        <div className={css.paperRecipeRowRoot}>
            <div className={css.paperRecipeRowFlexContainer}>
                <div className={css.paperRecipeRowContent}>
                    <div className={css.paperRecipeRowContentIcon}>
                        <RecipeIcon />
                    </div>
                    <div className={recipeContentTextClasses}>
                        {isPaperRecipeDisabled ? (
                            <>
                                Доступны только
                                <br />
                                <span
                                    role="none"
                                    onClick={() => setIsDialogOpenned(true)}
                                    className={css.paperRecipeRowTextDecoration}
                                >
                                    электронные рецепты
                                </span>
                            </>
                        ) : (
                            <>
                                Хочу получать
                                <br />
                                печатные рецепты
                            </>
                        )}
                    </div>
                </div>
                {!isPaperRecipeDisabled && (
                    <div className={css.paperRecipeRowToggleWrapper}>
                        <div className={css.paperRecipePriceRow}>+0 ₽</div>
                        <UIToggleSwitch
                            onChanegHandler={togglePaperOrDigitRecipe}
                            isChecked={isChecked}
                            id={labelId}
                        />
                    </div>
                )}
                <Portal>
                    <DialogStatic
                        // @ts-ignore
                        {...dialogTexts[locale].dialog.success[realm]}
                        isOpen={isDialogOpenned}
                        onConfirm={() => setIsDialogOpenned(false)}
                        onReject={onReject}
                        textAlign="start"
                        responisve={!isDesktopResolution}
                        notifyOnly
                        isProfileLayout
                        isCloseConfirmAction
                        isNewIrresponsiveLayout
                        oneRowButtons
                        buttonsReversed
                    >
                        <>
                            <div className={css.paperRecipeRowModalTitle}>
                                Доступно только
                                <br />
                                электронное меню
                            </div>
                            <div className={css.paperRecipeRowModalDesc}>
                                Заказы, оформленные в конце недели, не включают бумажные рецепты.
                                Рекомендуем делать заказы заранее.
                            </div>
                        </>
                    </DialogStatic>
                </Portal>
            </div>
        </div>
    );
};


