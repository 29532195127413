import React from 'react';
import cn from 'classnames';
import css from './UppserShield.module.scss';

// запеканка
import school1l from './cusserole/cusserole-l.png';
import school1m from './cusserole/cusserole-m.png';
import school1s from './cusserole/cusserole-s.png';
// котлета
import school2l from './cutlet/cutlet-l.png';
import school2m from './cutlet/cutlet-m.png';
import school2s from './cutlet/cutlet-s.png';
// паста
import school3l from './pasta/pasta-l.png';
import school3m from './pasta/pasta-m.png';
import school3s from './pasta/pasta-s.png';
// пирожок
import school4l from './pie/pie-l.png';
import school4m from './pie/pie-m.png';
import school4s from './pie/pie-s.png';
// пюре
import school5l from './puree/puree-l.png';
import school5m from './puree/puree-m.png';
import school5s from './puree/puree-s.png';
// питса
import school6l from './pizza/pizza-l.png';
import school6m from './pizza/pizza-m.png';
import school6s from './pizza/pizza-s.png';

// Kozel
import kozelImage from './kozel/kozel.img.svg';


export type TShieldUpperName = 'cusserole' | 'cutlet' | 'pasta' | 'pie' | 'pizza' | 'puree'
    | 'kozel' | 'new' | 'favorite' | 'tenminutes' | 'fifteenminutes' | 'twentyminutes' | 'dinner' | 'quick' | 'gift'

const defaultPath = {
    s: '', m: '', l: '',
};


const shieldMap = new Map<TShieldUpperName, typeof defaultPath>([
    ['cusserole', {
        s: school1s, m: school1m, l: school1l,
    }],
    ['cutlet', {
        s: school2s, m: school2m, l: school2l,
    }],
    ['pasta', {
        s: school3s, m: school3m, l: school3l,
    }],
    ['pie', {
        s: school4s, m: school4m, l: school4l,
    }],
    ['puree', {
        s: school5s, m: school5m, l: school5l,
    }],
    ['pizza', {
        s: school6s, m: school6m, l: school6l,
    }],
    ['kozel', {
        s: kozelImage, m: kozelImage, l: kozelImage,
    }],
]);

interface IUpperShieldProps {
    name: TShieldUpperName,
}

export const UppserShield = (props: IUpperShieldProps) => {
    const { name } = props;

    const path = shieldMap.get(name) || defaultPath;

    if (!path) {
        return null;
    }

    return (
        <div
            className={cn(css.upperShieldRoot, css[name])}
        >
            <div className={css.s}>
                <img className={css.upperShieldImg} src={path.s} alt={name} />
            </div>
            <div className={css.m}>
                <img className={css.upperShieldImg} src={path.m} alt={name} />
            </div>
            <div className={css.l}>
                <img className={css.upperShieldImg} src={path.l} alt={name} />
            </div>
        </div>
    );
};
