
import { gql } from '@apollo/client';

/*
    dish
      ├─nutrition
      └─[tags]
*/

export const NutritionItem = gql`
  fragment nutritionItemFragment on nutritionItem {
    title
    value
    unit
  }
`;

export const Nutrition = gql`
    fragment nutritionFragment on nutrition {
        carbohydrate {
            ...nutritionItemFragment
        }
        fat {
            ...nutritionItemFragment
        }
        protein {
            ...nutritionItemFragment
        }
        calories {
            ...nutritionItemFragment
        }
    }
    ${NutritionItem}
`;

export const MenuDish = gql`
    fragment menuDishFragment on menuDish {
        id
        title
        titleEn
        caption
        captionEn
        cooking_time
        cook_until
        composition
        weight
        price
        portions
        previewImage
        previewMobileImage
        verticalImage
        umAlias
        categoryId: category_id
        partnersComment
           # BR-1104
        secondDishPart
        replaceableIngredient
        nutrition {
            ...nutritionFragment
        }
        metaLabel @client
    }
    ${Nutrition}
`;

