/* eslint-disable react/jsx-one-expression-per-line */

import React from 'react';
import PropTypes from 'prop-types';
import ScrollLock from 'react-scrolllock';
import classNames from 'classnames';

import XBigIcon from 'assets/svg/x_big.component.svg';

import Image1 from './safety1.svg';
import Image2 from './safety2.svg';
import Image3 from './safety3.svg';
import Image4 from './safety4.svg';

import './desinfection-info-popup.scss';


/*
    state и scrollableContentRef нужны, чтобы пробросить в Scrolllock DOM-элементы,
    которому он даст возможность скроллиться на тач-устройствах
*/
export default class DesinfectionInfoPopup extends React.Component {
    state = {
        isMounted: false,
    }

    scrollableContentRef = React.createRef()

    componentDidMount() {
        this.setState({ isMounted: true });
    }

    render() {
        const { isMounted } = this.state;
        const {
            isOpenAboveSidebar,
            onClose,
        } = this.props;

        const popupClasses = classNames({
            'desinfection-info-popup': true,
            'above-sidebar': isOpenAboveSidebar,
        });

        return (
            <div styleName={popupClasses}>
                <div styleName="popup-header">
                    <button
                        styleName="popup-header__close"
                        type="button"
                        onClick={onClose}
                        aria-label="Закрыть информацию о подписке"
                    >
                        <XBigIcon />
                    </button>
                </div>

                <div styleName="popup-content" ref={this.scrollableContentRef}>
                    <h3 styleName="desinfection-popup-title desinfection-popup-title--mobile">
                        Каждый день
                        <br />
                        с заботой о вас!
                    </h3>
                    <h3 styleName="desinfection-popup-title desinfection-popup-title--desktop">
                        Каждый день
                        <br />
                        мы заботимся о вас!
                    </h3>

                    <div styleName="desinfection-popup__items">
                        <div styleName="desinfection-popup__item">
                            <div styleName="desinfection-popup__item-image">
                                <Image1 />
                            </div>
                            <div styleName="desinfection-popup__item-content">
                                <p styleName="desinfection-popup__item-text desinfection-popup__item-text--mobile">
                                    Дезинфицируем все
                                    <br />
                                    поверхности каждые&nbsp;
                                    <span styleName="desinfection-popup__item-text__number">3</span>
                                    &nbsp;часа
                                </p>
                                <p styleName="desinfection-popup__item-text desinfection-popup__item-text--desktop">
                                    Дезинфицируем все
                                    поверхности каждые <span styleName="desinfection-popup__item-text__number">3</span>&nbsp;часа
                                    специализированным средством
                                </p>
                            </div>
                        </div>

                        <div styleName="desinfection-popup__item">
                            <div styleName="desinfection-popup__item-image">
                                <Image2 />
                            </div>
                            <div styleName="desinfection-popup__item-content">
                                <p styleName="desinfection-popup__item-text desinfection-popup__item-text--mobile">
                                    Дезинфицируем упаковку
                                    ультрафиолетом
                                </p>
                                <p styleName="desinfection-popup__item-text desinfection-popup__item-text--desktop">
                                    Каждую партию упаковки
                                    дезинфицируем ультрафиолетом
                                    сразу при приеме поставки
                                </p>
                            </div>
                        </div>
                        <div styleName="desinfection-popup__item">
                            <div styleName="desinfection-popup__item-image">
                                <Image3 />
                            </div>
                            <div styleName="desinfection-popup__item-content">
                                <p styleName="desinfection-popup__item-text desinfection-popup__item-text--mobile">
                                    Контролируем температуру
                                    и&nbsp;дыхание сотрудников
                                </p>
                                <p styleName="desinfection-popup__item-text desinfection-popup__item-text--desktop">
                                    Контролируем сотрудников,
                                    чтобы не&nbsp;было температуры
                                    и&nbsp;проблем с&nbsp;дыханием, кашля
                                </p>
                            </div>
                        </div>
                        <div styleName="desinfection-popup__item">
                            <div styleName="desinfection-popup__item-image">
                                <Image4 />
                            </div>
                            <div styleName="desinfection-popup__item-content">
                                <p styleName="desinfection-popup__item-text">
                                    Доставляем бесконтактно,
                                    термически обрабатываете
                                    вы&nbsp;сами
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isMounted && (
                    <ScrollLock touchScrollTarget={this.scrollableContentRef.current} />
                )}
            </div>
        );
    }
}

DesinfectionInfoPopup.propTypes = {
    isOpenAboveSidebar: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
