const ru = {
    portionsTitle: 'В корзине',

    cookUntilText: 'Можно приготовить на',
    cookUnitlDays: 'день',
    shelfLifeText: 'Срок хранения',
    shelfLifeDays: ['день', 'дня', 'дней'],

    cookTimeText1: 'Время',
    cookTimeText2: 'приготовления',
    cookTimeUnit: 'мин.',
    cookTimeReady: 'Уже готово',

    weightDescription1: '1 порция',
    weightDescription2: 'готового блюда',
    priceDescriptionWeight: 'г',

    basketLoading: 'Обновляем',
    basketAdded: 'Добавлено в корзину',
    basketAdd: 'Добавить в корзину',

    whatsInside: 'Что в наборе',

    nutritionTitle: 'На 100 г готового блюда',
    nutritionSubtitle: 'при приготовлении по рецепту',
    carbohydrate: 'Углеводы',
    fat: 'Жиры',
    protein: 'Белки',
    calories: 'Калорийность',
    unitNutrition: 'г',
    unitCalories: 'ккал',
};

const en = {
    portionsTitle: 'In your cart',

    cookUntilText: 'Can be cooked on day ',
    cookUnitlDays: ' ',
    shelfLifeText: 'Shelf life:',
    shelfLifeDays: ['day', 'days', 'days'],

    cookTimeText1: 'Cooking',
    cookTimeText2: 'time',
    cookTimeUnit: 'min.',
    cookTimeReady: 'Already prepared',

    weightDescription1: 'One portion',
    weightDescription2: 'of a ready-made dish',
    priceDescriptionWeight: 'g',

    basketLoading: 'Loading...',
    basketAdded: 'Already in cart',
    basketAdd: 'Add to cart',

    whatsInside: 'What\'s inside a mealkit',

    nutritionTitle: 'Per 100 g',
    nutritionSubtitle: 'when preparing according to the recipe',
    carbohydrate: 'Carbohydrate',
    fat: 'Fat',
    protein: 'Protein',
    calories: 'Calories',
    unitNutrition: 'g',
    unitCalories: 'kcal',
};

export const locales = {
    en,
    ru,
};
