/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import PropTypes from 'prop-types';
import ScrollLock from 'react-scrolllock';
import compose from 'lodash/flowRight';
import cn from 'classnames';

import { withLocaleContext } from 'app/containers/LocaleProvider';
import { DRAFT_DeliveryAreasPage } from 'app/views/Checkout/__BR1039/DeliveryZonePage';
// import { UIHeading, UIParagraph } from 'app/components/ui';

import XBigIcon from './cross.svg';

import './delivery-info-popup.scss';


// const localeRu = {
//     main_title: <>Условия доставки</>,
//     rows: [
//         {
//             row_id: 1,
//             row_title: 'Зоны доставки',
//             row_text: <>
//                 Доставка осуществляется:
//                 <br />
//                 - по&nbsp;Москве и&nbsp;Московской области (до&nbsp;60&nbsp;км за&nbsp;МКАД)
//                 <br />
//                 - по&nbsp;Санкт-Петербургу и&nbsp;Ленинградской области (до&nbsp;50&nbsp;км за&nbsp;КАД)
//             </>,
//         },
//         {
//             row_id: 2,
//             row_title: 'Интервалы доставки',
//             row_text: <>
//                 Доставляем в&nbsp;удобные 3-часовые интервалы по&nbsp;утрам с&nbsp;6:00 до&nbsp;13:00
//                 и&nbsp;по&nbsp;вечерам с&nbsp;18:00 до&nbsp;23:59. Чтобы узнать интервалы, доступные для вас,
//                 введите адрес на&nbsp;странице оформления заказа.
//             </>,
//         },
//         {
//             row_id: 3,
//             row_title: 'Стоимость доставки',
//             row_text: <>
//                 Бесплатная доставка действует при сумме заказа от&nbsp;2400Р (до&nbsp;вычета скидки) и&nbsp;для заказов:
//                 <br />
//                 - по&nbsp;Москве и&nbsp;Московской области в&nbsp;пределах 13&nbsp;км от&nbsp;МКАД
//                 <br />
//                 - по&nbsp;Санкт-Петербургу и&nbsp;Ленинградской области в&nbsp;пределах 10&nbsp;км от&nbsp;КАД
//                 <br />
//                 <br />
//                 В&nbsp;остальных случаях доставка платная. Точную стоимость доставки можно узнать,
//                 указав адрес на&nbsp;странице оформления заказа
//             </>,
//         },
//         {
//             row_id: 4,
//             row_title: <>Изменение адреса и&nbsp;времени доставки</>,
//             row_text: <>
//                 Вы&nbsp;можете изменить адрес и/или время доставки бесплатно
//                 до&nbsp;16:00 за&nbsp;2&nbsp;дня до&nbsp;дня доставки.
//             </>,
//         },
//         {
//             row_id: 5,
//             row_title: <>Ожидание</>,
//             row_text: <>
//                 Вам придёт смс-сообщение, когда курьер выедет на&nbsp;ваш адрес.
//                 <br />
//                 Курьер может ожидать вас по&nbsp;адресу доставки не&nbsp;более 15&nbsp;минут,
//                 после чего будет вынужден уехать к&nbsp;другому клиенту.
//             </>,
//         },
//     ],
// };


// const locales = {
//     ru: localeRu,
//     en: localeRu,
// };


const DeliveryInfoPopup = ({
    onClose,
    // localeContext: { locale },
    transitionState = 'exited',
    popUpUiState,
}) => {
    const scrollableContentRef = React.createRef();

    // const { main_title, rows } = locales[locale];

    const subinfoPopupRootClasses = cn({
        'subscription-info-popup': true,
        [popUpUiState]: true,
    });

    const popupHeaderClasses = cn({
        'popup-header': true,
        [popUpUiState]: true,
    });

    return (
        <div styleName={subinfoPopupRootClasses}>
            <div styleName={popupHeaderClasses}>
                <button
                    styleName="popup-header__close"
                    type="button"
                    onClick={onClose}
                    aria-label="Закрыть информацию о доставке"
                >
                    <XBigIcon />
                </button>
            </div>

            <div styleName="popup-content" ref={scrollableContentRef}>
                <DRAFT_DeliveryAreasPage />
                {/* <UIHeading
                    level="3.2"
                    styleName="popup-content__main-title"
                >
                    {main_title}
                </UIHeading> */}
                {/* {rows.map((item) => (
                        <div styleName="popup-content-message" key={item.row_id}>
                            <h4 styleName="popup-content-message__title">
                                {item.row_title}
                            </h4>
                            <UIParagraph level="4.1" styleName="popup-content-message__text">
                                {item.row_text}
                            </UIParagraph>
                        </div>
                    ))} */}
            </div>
            {transitionState === 'entered' && (
                <ScrollLock touchScrollTarget={scrollableContentRef.current} />
            )}
        </div>
    );
};

DeliveryInfoPopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    localeContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,
    transitionState: PropTypes.string,
    popUpUiState: PropTypes.string.isRequired,
};

export default compose(withLocaleContext)(React.memo(DeliveryInfoPopup));
