import { gql } from '@apollo/client';
import { MenuDish } from './fragments/dish.fragment';
import { Tag } from './fragments/tag.fragment';

export default gql`
    query DishesList(
        $period: String!,
        $category: ID,
        $categories: [ID],
        $tags: [ID],
        $type: menuType,
    ) {
        menuDishes(
            period: $period,
            category: $category,
            categories: $categories,
            tags: $tags,
            type: $type
        ) {
            priority
            parentId
            availableStatus
            visibleStatus
            ...menuDishFragment
            partnerAdSignature
            partnerErid
            partnerPhotoBackground
            tags {
                ...tagFragment
            }
        }
    }
    ${MenuDish}
    ${Tag}
`;
