import PropTypes from 'prop-types';

export const dialogPropTypes = {
    onConfirm: PropTypes.func,
    onConfirmOne: PropTypes.func,
    onConfirmAll: PropTypes.func,
    onReject: PropTypes.func,
    isConfirmLoading: PropTypes.bool,
    strongText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    regularText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    confirmText: PropTypes.string,
    confirmOneText: PropTypes.string,
    confirmAllText: PropTypes.string,
    rejectText: PropTypes.string,
    extraText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    notifyOnly: PropTypes.bool,
    rejectDisabled: PropTypes.bool,
    oneRowButtons: PropTypes.bool,
    extraButtonPosition: PropTypes.oneOf(['last', 'first']),
};
