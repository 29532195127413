
const normalizeUser = (user) => {
    const defaultName = '[Безымянный]';
    const { name, ...restUser } = user;

    if (!name || name === defaultName) {
        return restUser;
    }

    return user;
};

export const flocktorySpecificEventsRecord = [
    /**
     * @event 'Flocktory_Postcheckout'
     */
    {
        eventName: 'Flocktory_Postcheckout',
        description: '',
        meta: [],
        scope: [],
        check(data) {
            return ['FlocktoryAnalytics'].includes(data.name);
        },
        prepare(data) {
            const { eventParams } = data;
            const { user } = eventParams;
            const normalizedUser = normalizeUser(user);

            return {
                event: 'postcheckout',
                ...eventParams,
                user: normalizedUser,
            };
        },
    },

    /**
     * @event 'Flocktory_Login'
     */
    {
        eventName: 'Flocktory_Login',
        description: '',
        meta: [],
        scope: [],
        check(data) {
            return ['FlocktoryAnalytics'].includes(data.name);
        },
        prepare(data) {
            const { eventParams } = data;
            const normalizedUser = normalizeUser(eventParams);
            return {
                event: 'Flocktory_Login',
                ...normalizedUser,
            };
        },
    },
];
