import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { FETCH_DELIVERY_ADDRESS_SESSION } from 'app/graphql/network/deliveryAddress.query';
import ClampLines from 'app/components/ClampLines';

import '../../single-step-delivery-info-area.scss';


export function SingleStepAddressRow(props) {
    const {
        addressValue,
        flatValue,
        entranceValue,
        floorValue,
    } = props;

    const { data } = useQuery(FETCH_DELIVERY_ADDRESS_SESSION);

    const defaultAddressPrefix = 'г Москва, ';
    const normalizedAddress = addressValue.startsWith(defaultAddressPrefix)
        ? addressValue.slice(defaultAddressPrefix.length)
        : addressValue;

    const secondaryRow = [
        entranceValue ? `подъезд ${entranceValue}` : null,
        floorValue ? `этаж ${floorValue}` : null,
    ].filter((row) => Boolean(row));

    return (
        <div styleName="single-step-checkout__row-address">
            <div styleName="single-step-checkout__row-address-main">
                <span styleName="single-step-checkout__text-primary">
                    {normalizedAddress}
                    {flatValue && (
                        <>
                            , кв.
                            {' '}
                            {flatValue}
                        </>
                    )}
                </span>
            </div>
            <div>
                <span styleName="single-step-checkout__text-secondary">
                    {secondaryRow.join(', ')}
                </span>
            </div>

            {data && data.deliveryAddress && data.deliveryAddress.address.comment && (
                <div styleName="single-step-checkout__address-comment">
                    Комментарий курьеру:
                    <br />
                    <ClampLines
                        linesCount={2}
                        text={data.deliveryAddress.address.comment}
                    />
                </div>
            )}
        </div>
    );
}

SingleStepAddressRow.propTypes = {
    addressValue: PropTypes.string.isRequired,
    flatValue: PropTypes.string.isRequired,
    entranceValue: PropTypes.string.isRequired,
    floorValue: PropTypes.string.isRequired,
};
