import React from 'react';
import PropTypes from 'prop-types';

import CustomizationCommentContainer from 'app/containers/CustomizationCommentContainer';
import Responsive from 'app/components/Responsive';
import CustomizationCommentFiltersImg from '../CustomizationCommentFiltersImg';

import {
    COMMENT_FORM_ROLE_BUTTON,
    COMMENT_PLACEMENT_FILTERS,
    SUBTITILE_COMMENT_DECORATIONS_QUOTE,
} from '../consts';

import { locales } from './customizationCommentFilters.locales';

import './customization-comment-filters.scss';


export default function CustomizationCommentFilters(props) {
    const {
        isAnimationRunning,
        locale,
        ...commentProps
    } = props;

    const { title } = locales[locale];

    return (
        <div styleName="customization-comment">
            <Responsive mobile={(<div styleName="separator" />)} />

            <div styleName="customization-comment__title-container">
                <CustomizationCommentFiltersImg isAnimationRunning={isAnimationRunning} />
                <h2 styleName="customization-comment__title-text">
                    {title}
                </h2>
            </div>

            <Responsive
                mobile={(
                    <CustomizationCommentContainer
                        isNeedTooltip
                        tooltipPosition="top"
                        {...commentProps}
                    />
                )}
                desktop={(
                    <CustomizationCommentContainer
                        subtitleCommentRowsCount={3}
                        subtitleCommentDecorations={SUBTITILE_COMMENT_DECORATIONS_QUOTE}
                        placement={COMMENT_PLACEMENT_FILTERS}
                        isNeededBlurSidebarBackdrop
                        commentFormRole={COMMENT_FORM_ROLE_BUTTON}
                        {...commentProps}
                    />
                )}
            />
        </div>
    );
}


CustomizationCommentFilters.propTypes = {
    isCommentAreaShown: PropTypes.bool.isRequired,
    isAnimationRunning: PropTypes.bool.isRequired,

    toggleCommentArea: PropTypes.func.isRequired,
    onClosed: PropTypes.func.isRequired,
    onOpened: PropTypes.func.isRequired,
};
