/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
    useCallback, useContext,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Velocity from 'velocity-animate';
import Raven from 'raven-js';

import formatPrice from 'app/utils/price';
import { BOXES_IDS_COLLECTION } from 'app/const/dishesWithBoxes';
import {
    menuImageSize,
    menuImageSizeMobile,
    getImageUrl,
    getPriceForSubscription,
} from 'app/utils/dish';

import {
    MenuDispatchContext,
    MenuDataContext,
    DISH,
    DISH_FROM_DETAILS,
} from 'app/containers/contexts/menu.context';
import { barillaPestoGiftDishes } from 'app/views/Basket/components/BasketCategories/customGifts/customGifts';
import { DishesSelectionContext } from 'app/containers/contexts/dishesSelection.context';
import { CustomIngredientsSelectDishRow } from 'app/views/Menu/Menu/components/CustomIngredientsSelectDishRow';
import SkeletonItem from 'app/components/SkeletonItem';
import UIParterLogoShield, { TEFAL_DISHES_ID } from 'app/components/ui/Elements/PartnerLogoShield';
import { DishImageShield } from 'app/components/ui/Elements/DishImageShield/DishImageShield';
import { useDishDetails, RENDERING_CONTEXT_MENU } from 'app/components/MobileDishDetails';
import { DishAdLabel } from 'app/components/ui/Elements/DishAdLable/DishAdLabel';

import MobileDishImage from './MobileDishImage';
import MobileDishDescription from './MobileDishDescription';

import './mobile-dish.scss';
import { MarchDishShield } from '../ui/Elements/MarchShield';
import { MobileDishGiftIngredient } from './MobileDishGiftIngredient';

// import MENU_DISHES_QUERY from 'app/graphql/network/menuDishes';

const SOLD_OUT_STATUS = 2;

// in px
const MAX_MOBILE_HEADER_HEIGHT = 120;

// in ms
const SCALE_DURATION = 300;
const MOVE_DURATION = 400;
const VANISH_DURATION = 300;


class MobileDish extends React.PureComponent {
    constructor(props) {
        super(props);

        this.rootRef = React.createRef();
        this.containerRef = React.createRef();
        this.isBoxDish = BOXES_IDS_COLLECTION.includes(props.id);
    }

    state = {
        mobileDetails: false,
        isNeededRenderStub: false,
        pageYOffsetAfterScroll: null,
    };

    componentDidMount() {
        const {
            id: dishId,
            menuDataContext: { scrolling },
            menuDispatchContext,
            isAutoscrollingDisabled,
            spaDishObserver,
        } = this.props;

        if (!isAutoscrollingDisabled) {
            const isNeedScrollToDish = scrolling.target === DISH;
            const isNeedScrollToDishFromDetails = scrolling.target === DISH_FROM_DETAILS;
            const isDishToScroll = dishId === scrolling.value;

            if (isDishToScroll && isNeedScrollToDish) this.scrollIntoViewport();
            if (isDishToScroll && isNeedScrollToDishFromDetails) this.scrollIntoViewportAfterChangesEnding();
            // сбрасываем необходимость скролла после выполнения скролла
            if (isDishToScroll) {
                menuDispatchContext({ type: 'resetScroll' });
            }
        }
        spaDishObserver.observe(this.rootRef.current);
    }

    // eslint-disable-next-line class-methods-use-this, react/sort-comp
    async animateAddingDishToBasket(rootEl, containerEl) {
        const { clientHeight } = document.documentElement;

        await Velocity( // display added above others
            rootEl,
            { zIndex: 1 },
            { duration: 0 },
        );
        await Velocity( // reduce
            containerEl,
            { scale: 0.6 },
            { duration: SCALE_DURATION },
        );
        await Velocity( // move down
            containerEl,
            { top: clientHeight },
            { duration: MOVE_DURATION },
        );
        await Velocity( // hide dish card (not root)
            containerEl,
            {
                visibility: 'hidden',
                opacity: 0,
            },
            { duration: 0 },
        );
        await Velocity( // vanish root
            rootEl,
            {
                height: 0,
            },
            { duration: VANISH_DURATION },
        );
    }

    scrollIntoViewport() {
        const rootEl = this.rootRef.current;
        rootEl.scrollIntoView();
        window.scrollBy(0, -MAX_MOBILE_HEADER_HEIGHT); // there is need a scrolling because header partial overlaps dish image
    }

    // По каким-то причинам простой 'scrollIntoView' для элемента не срабатывает
    // и переносит примерно на 1500px ниже.
    // Подозреваю, что это может происходить из-за слишком долгого рендеринга списка блюд
    scrollIntoViewportAfterChangesEnding() {
        const { pageYOffsetAfterScroll } = this.state;

        this.scrollIntoViewport();
        if (window.pageYOffset === pageYOffsetAfterScroll) return;

        this.setState(
            { pageYOffsetAfterScroll: window.pageYOffset },
            () => setTimeout(() => this.scrollIntoViewportAfterChangesEnding(), 500),
        );
    }

    handleDishToBasket = async () => {
        const {
            // id,
            // title,
            // caption,
            // price,
            // categoryId,
            // tags,
            portions,
            selectedDishId,
            onClickAddToBasket,
            getEextendedDishData,
            onCloseSelection,
        } = this.props;

        const rootEl = this.rootRef.current;
        const containerEl = this.containerRef.current;
        const [portion] = portions;


        const urlParams = new URLSearchParams(window.location.search);
        const stQueryParam = urlParams.get('st');


        try {
            const dishData = getEextendedDishData(selectedDishId);
            await this.animateAddingDishToBasket(rootEl, containerEl);
            onClickAddToBasket({
                portion,
                ...dishData,
            });

            if (stQueryParam === 'ahmad') {
                onCloseSelection();
            }
        } catch (err) {
            Raven.captureException(err);
        }
    };

    handleImageLoaded = () => {
        this.setState({ isNeededRenderStub: false });
    };

    renderDishImage(isSoldOut) {
        const {
            mobileDetails,
            isNeededRenderStub,
        } = this.state;

        const {
            id,
            title,
            previewImage,
            previewMobileImage,
            scrollPosition,
            useLazyLoading,
            openDishDetails,
        } = this.props;

        const imageUrl = getImageUrl({ url: previewImage, ...menuImageSize });
        const imageUrlMobile = getImageUrl({ url: previewMobileImage, ...menuImageSizeMobile });

        const hasPestoAsAGift = barillaPestoGiftDishes.includes(id);

        const imageContainerClasses = classNames({
            'dish-card__image-container': true,
            large: this.isBoxDish,
            'details-visible': mobileDetails,
            customSize: hasPestoAsAGift,
        });

        const dishImageSoldOutWrapper = classNames({
            'dish-card__image-sold-out-wrapper': true,
            visible: isSoldOut,
        });

        return (
            <>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
                <div
                    styleName={imageContainerClasses}
                    onClick={openDishDetails}
                    role="button"
                >
                    {TEFAL_DISHES_ID.includes(id) && <UIParterLogoShield type="default" />}
                    {/* <div styleName="spa-march-dish-label-wrapper">
                        <MarchDishShield dishId={id} />
                    </div> */}
                    <DishImageShield dishId={id} isSoldOut={isSoldOut} />
                    {isNeededRenderStub && (
                        <div styleName="dish-card__image-stub-wrapper">
                            <SkeletonItem />
                        </div>
                    )}
                    <div styleName={dishImageSoldOutWrapper}>
                        <MobileDishImage
                            url={imageUrlMobile}
                            fallbackUrl={imageUrl}
                            alt={title}
                            scrollPosition={scrollPosition}
                            useLazyLoading={useLazyLoading}
                            onLoadError={this.handleImageLoaded}
                            onLoad={this.handleImageLoaded}
                        />
                    </div>
                    {!isSoldOut && <DishAdLabel id={id} />}
                </div>
            </>
        );
    }

    renderDishDescription(isSoldOut) {
        const {
            categoryId,
            id,
            title,
            titleEn,
            caption,
            captionEn,
            price,
            weight,
            cooking_time: cookingTime,
            portions,
            nutrition,
            umAlias,
            disabled,
            isSubscriptionActivated,
            createClearBasketWithDialogHandler,
            openDishDetails,
            isDishCardWithCustomSelect,
        } = this.props;

        const [portion] = portions;

        const dishPrice = isSubscriptionActivated ? getPriceForSubscription(price) : price;

        const hasPestoAsAGift = barillaPestoGiftDishes.includes(id);

        return (
            <div styleName="dish-card__description-container">
                <MobileDishDescription
                    isDishCardWithCustomSelect={isDishCardWithCustomSelect}
                    isBoxDish={this.isBoxDish}
                    key={id}
                    categoryId={categoryId}
                    title={title}
                    titleEn={titleEn}
                    caption={caption}
                    captionEn={captionEn}
                    weight={weight}
                    cookingTime={cookingTime}
                    portion={portion}
                    nutrition={nutrition}
                    umAlias={umAlias}
                    dishFormattedPrice={formatPrice(dishPrice)}
                    isSoldOut={isSoldOut}
                    disabled={disabled}
                    onClick={createClearBasketWithDialogHandler(this.handleDishToBasket)}
                    toggleMobileDetails={openDishDetails}
                    hasPestoAsAGift={hasPestoAsAGift}
                />
            </div>
        );
    }

    render() {
        const {
            index,
            id,
            title,
            caption,
            price,
            categoryId,
            visibleStatus,
            canApplySoldOutStyles,
            isDishUpdating,
            dispatchOnCardClickAnalytic,
            isDishCardWithCustomSelect,
            selectedDishId,
            onNextIdSelected,
            ingredientsList,
        } = this.props;

        const hasPestoAsAGift = barillaPestoGiftDishes.includes(id);

        // console.log(this.props.isSubscriptionActivated);

        const isSoldOut = canApplySoldOutStyles && visibleStatus === SOLD_OUT_STATUS;
        const isCustomSelectShown = isDishCardWithCustomSelect && !this.isBoxDish;

        const dishCardRootContainer = classNames({
            'dish-card__root': true,
            large: this.isBoxDish,
            // TODO: BR-1101 A/B TEST
            'with-BR-1104-select': isDishCardWithCustomSelect,
            withGift: hasPestoAsAGift,

        });

        const containerClasses = classNames({
            'dish-card__container': true,
            'is-dish-updating': isDishUpdating,
            'sold-out': isSoldOut,
        });


        // TODO: Когда придет время, убрать эту срань
        const pathname = window?.location?.pathname || '';
        const environmentContext = pathname.includes('basket') ? 'basket' : 'menu';

        return (
            <li
                ref={this.rootRef}
                styleName={dishCardRootContainer}
                data-id={id}
                data-test-id="dish-card"
                onClick={() => dispatchOnCardClickAnalytic({
                    id,
                    title,
                    caption,
                    price,
                    categoryId,
                    index,
                })}
            >
                <div
                    ref={this.containerRef}
                    styleName={containerClasses}
                >
                    {this.renderDishImage(isSoldOut)}
                    {isCustomSelectShown && (
                        <CustomIngredientsSelectDishRow
                            id={id}
                            selectedDishId={selectedDishId}
                            onNextIdSelected={onNextIdSelected}
                            ingredientsList={ingredientsList}
                            onSubmitHandler={(arg) => { arg.onCompoleted(); }}
                            environmentContext={environmentContext}
                        />
                    )}
                    {hasPestoAsAGift && <MobileDishGiftIngredient text="Песто Barilla 190г" oldPrice={400} />}
                    {this.renderDishDescription(isSoldOut)}
                </div>
            </li>
        );
    }
}

MobileDish.propTypes = {
    selectedDishId: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    categoryId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    visibleStatus: PropTypes.oneOf([0, 1, 2]).isRequired,
    title: PropTypes.string.isRequired,
    titleEn: PropTypes.string,
    caption: PropTypes.string,
    captionEn: PropTypes.string,
    price: PropTypes.number.isRequired,
    weight: PropTypes.number.isRequired,
    previewImage: PropTypes.string.isRequired,
    previewMobileImage: PropTypes.string.isRequired,
    cooking_time: PropTypes.number,
    umAlias: PropTypes.arrayOf(PropTypes.string),
    canApplySoldOutStyles: PropTypes.bool,
    portions: PropTypes.arrayOf(PropTypes.number).isRequired,
    // tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

    useLazyLoading: PropTypes.bool.isRequired,
    isSubscriptionActivated: PropTypes.bool.isRequired,
    isDishUpdating: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,

    onClickAddToBasket: PropTypes.func.isRequired,
    getEextendedDishData: PropTypes.func.isRequired,
    dispatchOnCardClickAnalytic: PropTypes.func.isRequired,
    createClearBasketWithDialogHandler: PropTypes.func,

    addToBasketAnimationTarget: PropTypes.shape({
        right: PropTypes.number,
        top: PropTypes.number,
        scale: PropTypes.number,
    }).isRequired,

    scrollPosition: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
    }),

    spaDishObserver: PropTypes.shape({
        observe: PropTypes.func,
    }).isRequired,

    nutrition: PropTypes.shape({
        calories: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,

    menuDispatchContext: PropTypes.func.isRequired,
    menuDataContext: PropTypes.shape({
        scrolling: PropTypes.shape({
            target: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    }).isRequired,
    isAutoscrollingDisabled: PropTypes.bool,

    openDishDetails: PropTypes.func.isRequired,
};

function MobileDishWithDetails(props) {
    const {
        id,
        onOpenDetails,
        basketQuery,
        isAutoscrollingDisabled = false,
        titleEn = '',
        captionEn = '',
        caption = null,
        cooking_time = 0,
        canApplySoldOutStyles = false,
        scrollPosition = null,
        umAlias = ['г', 'г', 'г', 'г'],
        createClearBasketWithDialogHandler = (cb) => () => cb(),
    } = props;
    const refetchCallback = basketQuery?.refetch;
    const { openDishDetails } = useDishDetails(refetchCallback);

    const menuDispatchContext = useContext(MenuDispatchContext);
    const menuDataContext = useContext(MenuDataContext);

    const { data, onCloseSelection } = useContext(DishesSelectionContext);

    const handleOpenDetails = useCallback(() => {
        openDishDetails(id, RENDERING_CONTEXT_MENU);
        onOpenDetails(id);
    }, [id, openDishDetails, onOpenDetails]);

    return (
        <MobileDish
            {...props}
            menuDispatchContext={menuDispatchContext}
            menuDataContext={menuDataContext}
            openDishDetails={handleOpenDetails}
            onCloseSelection={onCloseSelection}
            isAutoscrollingDisabled={isAutoscrollingDisabled}
            titleEn={titleEn}
            captionEn={captionEn}
            caption={caption}
            cooking_time={cooking_time}
            canApplySoldOutStyles={canApplySoldOutStyles}
            scrollPosition={scrollPosition}
            umAlias={umAlias}
            createClearBasketWithDialogHandler={createClearBasketWithDialogHandler}
        />
    );
}

MobileDishWithDetails.propTypes = {
    id: PropTypes.string.isRequired,
    onOpenDetails: PropTypes.func,
    basketQuery: PropTypes.shape({
        refetch: PropTypes.func.isRequired,
    }),
};

export default MobileDishWithDetails;
