import differenceInDays from 'date-fns/difference_in_days';

const msk16hours = 'T13:00:00Z';
const x3 = 3;

export function checkIsX3FromSelectedDate(selectedDate) {
    const today = new Date();
    const d = new Date(`${selectedDate}${msk16hours}`);
    const difference = differenceInDays(d, today);

    const isIncludeMoreThanThree = difference >= x3;
    return isIncludeMoreThanThree;
}
