export enum ReplacePopularCustomSetsEnum {
    'everyday',
    'top',
    'premium',
}

export const customizationTagsForABTest = [2146, 1517, 1516, 2256, 2255, 1521]
export const customizationTagsThrowingOutFromABTest = [2146, 1517, 1516, 2256, 2255, 1521, 2145, 2, 13, 59, 212, 22]
export const TagsContentForABDialog = {
    1516: {
        ru: ['рыбу', 'с рыбой'],
        en: ['fish', 'with fish']
    },
    1517: {
        ru: ['свинину', 'со свининой'],
        en: ['pork', 'with pork']
    },
    2146: {
        ru: ['свинину', 'со свининой'],
        en: ['pork', 'with pork']
    },
    2255: {
        ru: ['булгур', 'с булгуром'],
        en: ['bulgur', 'with bulgur']
    },
    2256: {
        ru: ['кускус', 'с кускусом'],
        en: ['couscous', 'with couscous']
    },
    1521: {
        ru: ['грибы', 'с грибами'],
        en: ['mushrooms', 'with mushrooms']
    },
}
