import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import cookies from 'js-cookie';

import { SPAContext } from 'app/containers/SPAProvider';
import { LocaleContext } from 'app/containers/LocaleProvider';
import { UIHeading } from 'app/components/ui';
import Link, { LINK_TYPE_NAV, LINK_TYPE_SIMPLE } from 'app/components/Link';

import Orders from './icons/orders.component.svg';
import Bonus from './icons/bonus.component.svg';
import Login from './icons/login.component.svg';

import './mobile-site-nav-main-links.scss';


const BONUS_PATH = '/profile/invite/';

const MobileSiteNavMainLink = ({
    // eslint-disable-next-line react/prop-types
    title, href, icon, handler, type,
}) => {
    const spaContext = useContext(SPAContext);
    if (type === 'button') {
        return (
            <button
                type="button"
                onClick={handler}
                styleName="mobile-nav__main-link"
            >
                <div styleName="mobile-nav__main-link__icon-container">
                    {icon}
                </div>
                <UIHeading level="3.2" inheritColors>
                    {title}
                </UIHeading>
            </button>
        );
    }

    return (
        <Link
            type={spaContext.mode === 'off' ? LINK_TYPE_SIMPLE : LINK_TYPE_NAV}
            href={href}
            onClick={handler}
            styleName="mobile-nav__main-link"
        >
            <div styleName="mobile-nav__main-link__icon-container">
                {icon}
            </div>
            <UIHeading level="3.2" inheritColors>
                {title}
            </UIHeading>
        </Link>
    );
};


const MobileSiteNavMainLinks = ({
    onClickNavLink,
    onClickLogin,
    onClickQuit,
    referralBalance = 0,
    isAuthed = false,
}) => {
    const loginItemRef = useRef(null);

    const { translate } = useContext(LocaleContext);

    const hasBonus = referralBalance > 0;
    const bonusTitleTrKey = (hasBonus && isAuthed) ? 'mobileNav.myBonus' : 'mobileNav.getBonus';
    const bonusHref = isAuthed ? BONUS_PATH : `/auth/?redirect=${encodeURIComponent(`${BONUS_PATH}${window.location.search}`)}`;

    const loginTitleTrKey = isAuthed ? 'mobileNav.logout' : 'mobileNav.login';
    const loginHandler = isAuthed ? onClickQuit : onClickLogin;
    const origin = process.env.ORIGIN;

    const links = {
        deliveriesLink: {
            data: {
                title: translate('mobileNav.myDeliveries'),
                icon: <Orders />,
                href: `${origin}/profile/`,
                handler: onClickNavLink,
            },
        },
        bonusesLink: {
            data: {
                title: isAuthed ? translate(bonusTitleTrKey) : 'Получить бонусы?',
                icon: <Bonus />,
                href: `${origin}${bonusHref}`,
                handler: onClickNavLink,
            },
        },
        loginLink: {
            data: {
                title: translate(loginTitleTrKey),
                icon: <Login />,
                handler: loginHandler,
                type: 'button',
            },
        },
    };

    const deliveriesItemClasses = classNames({
        'mobile-nav__main-item': true,
        'deliveries-item': true,
    });

    const logoutItemClasses = classNames({
        'mobile-nav__main-item': true,
        'logout-item': true,
        'is-authed': isAuthed,
    });

    return (
        <ul styleName="mobile-nav__main-links">
            {isAuthed && (
                <li styleName={deliveriesItemClasses}>
                    <MobileSiteNavMainLink {...links.deliveriesLink.data} />
                </li>
            )}

            <li styleName="mobile-nav__main-item bonus-item">
                <MobileSiteNavMainLink {...links.bonusesLink.data} />

                {hasBonus && (
                    <p styleName="mobile-nav__bonus-label">
                        <span styleName="num">
                            {referralBalance}
                        </span>
                        ₽
                    </p>
                )}
            </li>

            <li styleName={logoutItemClasses} ref={loginItemRef}>
                <MobileSiteNavMainLink {...links.loginLink.data} />
            </li>
        </ul>
    );
};


MobileSiteNavMainLinks.propTypes = {
    isAuthed: PropTypes.bool,
    referralBalance: PropTypes.number,
    onClickNavLink: PropTypes.func.isRequired,
    onClickLogin: PropTypes.func.isRequired,
    onClickQuit: PropTypes.func.isRequired,
};

export default MobileSiteNavMainLinks;
