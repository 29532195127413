import React from 'react';
import PropTypes from 'prop-types';

export default class RedirectToBasketWithGift extends React.Component {
    static propTypes = {
        basketQuery: PropTypes.shape({
            cart: PropTypes.shape({
                sections: PropTypes.arrayOf(PropTypes.shape({})),
            }),
        }).isRequired,
        openBasket: PropTypes.func.isRequired,
    }

    redirectTimer = null

    componentDidUpdate() {
        const { basketQuery, openBasket } = this.props;

        if (!basketQuery.cart) return;

        const { sections } = basketQuery.cart;
        const giftSection = sections.find(s => s.gift_category);
        const isOnlyGiftInBasket = giftSection && sections.length === 1;

        if (isOnlyGiftInBasket && !this.redirectTimer) {
            this.redirectTimer = setTimeout(openBasket, 2000);
        }
    }

    render() {
        return null;
    }
}
