const localeTexts = {
    mobileNav: {
        workHours: 'Every day from 6 am to 11 pm',
        myDeliveries: 'My deliveries',
        myBonus: 'My rewards',
        getBonus: 'How to get rewards?',
        login: 'Login',
        logout: 'Logout',
        menuDishes: 'Menu',
        newYearDishes: 'New Year menu',
        rest: 'Restaurant',
        masl: 'Pancake week',
        trialPremium: 'Premium trial',
        everyday: 'Chef picks',
        dropdownOffer: 'New order',
        dropdownInfo: 'How we work',
        quality: 'Quality system',
        delivery: 'Delivery and payment',
        recurrent: 'Subscribtion',
        unpacking: `What's inside a mealkit`, // eslint-disable-line quotes
        foreignLang: 'Русская версия',
        about: 'About Elementaree',
        hp: 'Меню из Хогвартса',
        'ny-sweets': 'Новогоднее настроение',

        premium: 'Premium Pack',
        nz: 'Stocking Up',
        box: '20 Meals for a Month',
        learn: 'Masterclass',
        season: 'Season Menu',
        premiumSet: 'Premium Pack',
        health: 'For Health',
        dessertbar: 'Dessert Bar',

        // NEW
        ny: 'New Year`s menu',
        lite: 'Healthy Food',
        soups: '15 minutes soups',
        vegan: 'Vegetarian menu',
        sweat: 'Family baking',
        breakfast: 'Gourmet breakfasts',

        tomorrow: 'Weekly set',
        trial: 'Trial set',

        allSets: 'Meal Sets',
        gift: 'Подарочные сертификаты',
        christmas: 'Christmas',
    },

    region: {
        mobileSelectTitleMsk: 'Mos',
        mobileSelectTitleSpb: 'SPb',

        mobileHeading: 'Select city',
        mobileSubheading_1: ' ',
        mobileSubheading_2: ' ',
        moscowRegion: 'Moscow',
        spbRegion: 'Saint Petersburg',

        moscow: 'Moscow',
        spb: 'Saint Petersburg',
    },
};

export default localeTexts;
