import React from 'react';

import LINKS from 'app/const/links';

import { locales } from './navigation.locales';

import './navigation.scss';


const {
    TRIAL_FIRST_ORDER_SET,
    EVERYDAY_SET,
    BOX_PAGE,
    // NEW_YEAR_PAGE,
    HOME_RESTAURANT_PAGE,
} = LINKS;


export const getDesktopHeaderLinksOptions = ({ locale }) => [
    {
        id: TRIAL_FIRST_ORDER_SET.id,
        data: {
            Text: () => {
                const text = locales[locale].trial_5x2;

                return (
                    <span styleName="nav__item-title">
                        {text}
                    </span>
                );
            },
            // eslint-disable-next-line max-len
            href: TRIAL_FIRST_ORDER_SET.hrefBasket,
        },
    },
    // {
    //     id: TRIAL_PREMIUM_SET.id,
    //     data: {
    //         Text: () => {
    //             const text = locales[locale].trial_3x2;

    //             return (
    //                 <span styleName="nav__item-title">
    //                     {text}
    //                     &nbsp;
    //                     <span styleName="num">
    //                         &minus;
    //                         {`${TRIAL_PREMIUM_SET.discountPercent}%`}
    //                     </span>
    //                 </span>
    //             );
    //         },
    //         href: TRIAL_PREMIUM_SET.hrefBasket,
    //     },
    // },
    {
        id: EVERYDAY_SET.id,
        data: {
            Text: () => {
                const text = locales[locale].everyday;

                return (
                    <span styleName="nav__item-title">
                        <span styleName="num">5&nbsp;</span>
                        {text}
                        &nbsp;
                        <span styleName="num">
                            &minus;
                            {`${EVERYDAY_SET.discountPercent}%`}
                        </span>
                    </span>
                );
            },
            href: EVERYDAY_SET.href,
        },
    },
    {
        id: BOX_PAGE.id,
        data: {
            Text: () => {
                const text = locales[locale].box;

                return (
                    <span styleName="nav__item-title">
                        {text}
                    </span>
                );
            },
            href: locale === 'en' ? BOX_PAGE.hrefEn : BOX_PAGE.hrefRu,
        },
    },
    {
        id: HOME_RESTAURANT_PAGE.id,
        data: {
            Text: () => {
                const text = locales[locale].rest;

                return (
                    <span styleName="nav__item-title">
                        {text}
                    </span>
                );
            },
            href: locale === 'en' ? HOME_RESTAURANT_PAGE.hrefEn : HOME_RESTAURANT_PAGE.hrefRu,
        },
    },
    // {
    //     id: NEW_YEAR_PAGE.id,
    //     data: {
    //         Text: () => {
    //             const text = locales[locale].ny;

    //             return (
    //                 <span styleName="nav__item-title">
    //                     {text}
    //                 </span>
    //             );
    //         },
    //         href: locale === 'en' ? NEW_YEAR_PAGE.hrefEn : NEW_YEAR_PAGE.hrefRu,
    //     },
    // },
];
