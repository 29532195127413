import { gql } from '@apollo/client';


export default gql`
    mutation billing(
        $promocode: String,
        $period: String!,
        $payment_method: paymentMethod,
        $phone: String,
    ) {
        billing(
            promocode: $promocode,
            period: $period,
            payment_method: $payment_method,
            phone: $phone,
        ) {
            promocode
            payment_method
        }
    }
`;
