class LinkedListNode {
    constructor(value, i) {
        this.value = value;
        this.prev = null;
        this.next = null;
        this.index = i;
    }
}

export default LinkedListNode;
