/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';
import cn from 'classnames';

/* ICONS */
import ActiveIcon from 'app/components/ui/input/buttonIcons/ActiveIcon.component.svg';
import ConfirmedIcon from 'app/components/ui/input/buttonIcons/ConfirmedIcon.component.svg';
import ErrorIcon from 'app/components/ui/input/buttonIcons/ErrorIcon.component.svg';
import SubmitIcon from 'app/components/ui/input/buttonIcons/SubmitIcon.component.svg';
import ValidationIcon from 'app/components/ui/input/buttonIcons/ValidationIcon.component.svg';


import { IUIKitInputButtonProps } from './UiKItUiInput.types';

import css from './newUiinput.module.scss';


export function UiKitInputButton(props: IUIKitInputButtonProps) {
    const {
        componentSize,
        handleClickOnClearButton,
        handleClickOnSubmetButton,
        buttonUiState,
    } = props;

    const uiKitInputButtonRootClasses = cn({
        [css.uiKitInputButtonRoot]: true,
        [componentSize]: true,
        [buttonUiState]: true,
    });


    const uiKitInputButtonIconClasses = cn({
        [css.uiKitInputButtonIconClasses]: true,
        [componentSize]: buttonUiState !== 'empty',
        [buttonUiState]: true,
    });

    /* SELECTORS */
    // const isButtonDisabled = useMemo(
    //     () => !['submit', 'clear'].includes(buttonUiState),
    //     [buttonUiState],
    // );

    const currentOnClickHanlder = () => {
        if (buttonUiState === 'submit') {
            handleClickOnSubmetButton();
        }

        if (buttonUiState === 'clear') {
            handleClickOnClearButton();
        }
    };

    return (
        <div
            className={css.uiKitInputButtonWrapper}
        >
            {/*
                Здесь могла быть кнопка, но safari не переводит фокус на кнопку при клике
                https://stackoverflow.com/questions/42764494/blur-event-relatedtarget-returns-null
            */}
            {/* eslint-disable */}
            <div
                data-ui-input-button
                className={uiKitInputButtonRootClasses}
                onClick={currentOnClickHanlder}
                tabIndex={0}
            >
            {/* eslint-enable */}
                <div className={uiKitInputButtonIconClasses}>
                    {buttonUiState === 'submit' && <SubmitIcon />}
                    {buttonUiState === 'error' && <ErrorIcon />}
                    {buttonUiState === 'clear' && <ActiveIcon />}
                    {buttonUiState === 'confirmed' && <ConfirmedIcon />}
                    {buttonUiState === 'validation' && <ValidationIcon />}
                </div>
            </div>
        </div>
    );
}
