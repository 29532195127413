import { allTagsField } from './fields/allTags.field';
import { primetiveSectionField } from './fields/primetiveSection.filed';
import { isSectionsEmptyField } from './fields/isSectionsEmpty.field';

export const cartPolicies = {
    primetiveSection: primetiveSectionField,
    isSectionsEmpty: isSectionsEmptyField,
    allTags: allTagsField,
    sections: {
        merge(e, i) {
            return i;
        },
    },
};
