export const allAvailableDatesField = {
    read(_, { readField }) {
        const eeDatesArr = readField('eeAvailableDates');
        const trialDatesArr = readField('trialAvailableDates');
        const everydayDatesArr = readField('everydayAvailableDates');
        const premiumDatasArr = readField('premiumAvailableDates');

        const allAvailableDates = [...new Set([
            ...eeDatesArr, ...trialDatesArr, ...everydayDatesArr, ...premiumDatasArr,
        ])].sort();
        return allAvailableDates;
    },
};


