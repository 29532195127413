import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './tag.scss';


export default function Tag(props) {
    const {
        tagRef = null,
        count = null,
        isSelected = false,
        onChange = () => {},
        colorType = 'primary',
        id,
        text,
        disabled,
    } = props;

    const [wasSelected, setWasSelected] = useState(false);
    useEffect(() => {
        if (isSelected) {
            setWasSelected(true);
        }
    }, [isSelected]);

    const rootClasses = classNames({
        tag__root: true,
        'is-selected': isSelected,
        disabled,
        [colorType]: colorType,
    });
    const borderClasses = classNames({
        tag__boundary: true,
        disabled,
        [colorType]: colorType,
    });
    const labelClasses = classNames({
        tag__label: true,
        'is-selected': isSelected,
        'was-selected': !isSelected && wasSelected,
        disabled,
        [colorType]: colorType,
    });
    const expanderClasses = classNames({
        tag__expander: true,
        'is-selected': isSelected,
        'was-selected': !isSelected && wasSelected,
        disabled,
        [colorType]: colorType,
    });

    return (
        <div styleName={rootClasses} ref={tagRef} data-tag-id={id}>
            <div styleName={borderClasses} />
            <div styleName={expanderClasses} />

            <label
                styleName={labelClasses}
                htmlFor={`tag__checkbox__${id}`}
            >
                {text}
                {!!count && <span styleName="num">{count}</span>}
            </label>

            <input
                type="checkbox"
                styleName="tag__checkbox"
                onChange={onChange}
                checked={isSelected}
                disabled={disabled}
                id={`tag__checkbox__${id}`}
                value={id}
            />
        </div>
    );
}

Tag.propTypes = {
    id: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    tagRef: PropTypes.shape({}),
    text: PropTypes.string.isRequired,
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    colorType: PropTypes.oneOf(['primary', 'grey-back']),
};
