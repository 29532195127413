import { isSubscription } from 'app/const/subscription';
import {
    CATEGORIES_URL_VALUES,
} from 'app/const/categories';
import {
    getFullTitle,
} from '../../events.utils';


export const GASpecificEventsRecord = [
    /**
     * @event 'Invoice_Created'
     * @node GTM Only
     * @scope Checkout
     */
    {
        eventName: 'Invoice_Created',
        description: 'Событие создание инвойса',
        meta: ['state'],
        scope: ['checkout'],
        check(data) {
            return ['GoogleAnalytics'].includes(data.name);
        },
        prepare(data) {
            const { invoiceId } = data;
            return {
                invoiceId,
                event: 'Invoice_Created',
            };
        },
    },
    /**
     * @event 'Track_Checkout_User_TYP'
     * @node GTM Only
     * @scope Checkout
     */
    {
        eventName: 'Track_Checkout_User_TYP',
        description: 'TYP',
        meta: ['state'],
        scope: ['checkout'],
        check(data) {
            return ['GoogleAnalytics'].includes(data.name);
        },
        prepare(data) {
            const { userType } = data;
            return {
                event: 'Checkout',
                eventCategory: 'Checkout',
                eventAction: 'TYP',
                eventLabel: userType,
            };
        },
    },
    /**
     * @event 'Track_Card_Click'
     */
    {
        eventName: 'Track_Card_Click',
        description: 'Отслеживание клика по любой облости карточки блюда.',
        meta: ['click'],
        scope: ['menu'],
        check(data) {
            return ['GoogleAnalytics'].includes(data.name);
        },
        prepare(data) {
            const { list, subscriptionType } = data;
            return {
                event: 'productClick',
                ecommerce: {
                    click: {
                        actionField: { list },
                        products: [{
                            name: getFullTitle(data),
                            id: data.id,
                            price: isSubscription(subscriptionType) ? Math.ceil(data.price * 0.9) : data.price,
                            category: CATEGORIES_URL_VALUES[data.categoryId] || data.categoryId,
                            position: data.index + 1,
                        }],
                    },
                },
            };
        },
    },
];
