import React, { useMemo } from 'react';

import { isDesktop } from 'app/utils/resolution';
import { DialogProps } from '../DialogResponsive/DialogResponsive';
import { DialogAnimationWrapper } from '../DialogAnimationWrapper';
import { DialogStaticDesktop } from './DialogStaticDesktop/DialogStaticDesktop';
import { DialogStaticMobile } from './DialogStaticMobile/DialogStaticMobile';

// UNION TYPE FOR FUTURE CUSTOM DIALOGS
type DialogModificators = 'profile_notifications' | '' | ''


interface DialogStaticProps extends DialogProps {
    isOpen: boolean,
    buttonsReversed?: boolean,
    isProfileLayout?: boolean,
    modeList?: DialogModificators,
    rejectSpecificHandler?: () => void
    children: React.ReactNode
}

export const DialogStatic = ({
    isOpen,
    notifyOnly = false,
    buttonsReversed = false,
    isProfileLayout = false,
    isCloseConfirmAction = false,
    onReject,
    onConfirm,
    children,
    ...restProps
}: DialogStaticProps) => {
    const rejectHandler = (notifyOnly || isCloseConfirmAction)
        ? onConfirm
        : onReject;

    const isDesktopResolution = useMemo(
        () => isDesktop(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [window.innerWidth],
    );

    return (
        <DialogAnimationWrapper
            isOpen={isOpen}
            onReject={rejectHandler}
        >
            {isDesktopResolution
                ? (
                    <DialogStaticDesktop
                        notifyOnly={notifyOnly}
                        onConfirm={onConfirm}
                        onReject={onReject}
                        isCloseConfirmAction={isCloseConfirmAction}
                        {...restProps}
                    >
                        {children}
                    </DialogStaticDesktop>
                )
                : (
                    // @ts-ignore
                    <DialogStaticMobile
                        notifyOnly={notifyOnly}
                        onConfirm={onConfirm}
                        onReject={onReject}
                        buttonsReversed={buttonsReversed}
                        isProfileLayout={isProfileLayout}
                        isCloseConfirmAction={isCloseConfirmAction}
                        {...restProps}
                    >
                        {children}
                    </DialogStaticMobile>
                )
            }
        </DialogAnimationWrapper>
    );
};
