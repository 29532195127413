import React from 'react';
import css from './selectTag.module.scss';
import Icon from './img/arrow.component.svg';


export interface IAbstractSelectTagProps {
    placeHolder: string,
    text: string,
    onClickHandler: () => void,
}

export function AbstractSelectTag(props: IAbstractSelectTagProps) {
    const {
        onClickHandler = () => {},
        placeHolder = 'Плейсхолдер',
        text = 'Текс',
    } = props;
    return (
        <div className={css.selectTagRoot}>
            <button
                className={css.selectTagHandlerWrapper}
                type="button"
                onClick={onClickHandler}
            >
                <div className={css.selectTagContent}>
                    <div className={css.selectTagContentPlaceholder}>
                        {placeHolder}
                    </div>
                    <div className={css.selectTagContentText}>
                        {text}
                    </div>
                </div>
                <div className={css.selectTagContentIcon}>
                    <div className={css.selectTagContentIconWrapper}>
                        <Icon />
                    </div>
                </div>
            </button>
        </div>
    );
}
