/* eslint-disable react/require-default-props */
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { BasketCheck } from './components/BasketCheck/BasketCheck';

import './basket.scss';
import { BASKET_PAGE_CHECK_ARIA } from './graphql/basketCheckArea.graphql';
import { DELIVERY_BASKET_PAGE_CHECK_AREA } from './graphql/deliveryBasketCheckArea.graphql';


const RENDER_CONTEXT_BASKET = 'basket';
const RENDER_CONTEXT_DELIVERY_BASKET = 'delivery-basket';


export const BasketCheckArea = React.memo((props) => {
    const {
        renderContext = RENDER_CONTEXT_DELIVERY_BASKET,
        prearedBasketAreas: { basketStyleModifier },
    } = props;

    const isDelivery = window.location.pathname.includes('/delivery');
    const query = isDelivery
        ? DELIVERY_BASKET_PAGE_CHECK_AREA
        : BASKET_PAGE_CHECK_ARIA;

    const deliveryIdFromUrl = window.location.pathname.match(/\/(\d+)\//);
    const deliveryId = props?.basket?.cart?.id || (deliveryIdFromUrl?.length ? deliveryIdFromUrl[1] : '');

    const operationVariables = useMemo(
        () => (isDelivery ? { delivery_id: deliveryId, clear: true } : null),
        [deliveryId, isDelivery],
    );

    const { data } = useQuery(query, {
        fetchPolicy: 'cache-first',
        variables: operationVariables,
        context: {
            message: 'basket:refetch:BasketCheckArea',
        },
    });

    const cartField = isDelivery ? 'deliveryCart' : 'cart';
    const hasCart = data && (cartField in data);

    if (!hasCart || !data[cartField]) {
        return null;
    }

    const BasketCheckClasses = classNames({
        'basket-check-area': true,
        [basketStyleModifier]: true,
    });

    return (
        <div styleName={BasketCheckClasses}>
            <BasketCheck
                {...props}
                basket={isDelivery ? data.deliveryCart : data.cart}
                renderContext={renderContext}
            />
        </div>
    );
});


BasketCheckArea.propTypes = {
    renderContext: PropTypes.oneOf([RENDER_CONTEXT_BASKET, RENDER_CONTEXT_DELIVERY_BASKET]),
    prearedBasketAreas: PropTypes.shape({
        list: PropTypes.shape({
            isEmptyBasket: PropTypes.bool,
        }).isRequired,
        basketStyleModifier: PropTypes.string.isRequired,
    }).isRequired,
};
