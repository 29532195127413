import { getAddressForQuery } from 'app/utils/address';

export const addressValueField = {
    read(_, { readField }) {
        const city = readField('city');
        const street = readField('street');
        const house = readField('house');

        const res = getAddressForQuery({ city, street, house });

        return res;
    },
};
