import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import cn from 'classnames';

import './dialog-animation-wrapper.scss';


const noop = () => {};

export function DialogAnimationWrapper({
    isOpen,
    children,
    onReject = noop,
}) {
    return (
        <Transition in={isOpen} timeout={400}>
            {(tstate) => {
                const dbClasses = cn({
                    'dialog-animated__bg': true,
                    [tstate]: true,
                });
                const dcClasses = cn({
                    'dialog-animated__content': true,
                    [tstate]: true,
                });
                return (
                    <div styleName={dbClasses}>
                        <div styleName={dcClasses}>
                            {tstate !== 'exited' && (
                                <>
                                    <button
                                        type="button"
                                        onClick={onReject}
                                        styleName="dialog-animated__close"
                                        label="close"
                                    />
                                    {children}
                                </>
                            )}
                        </div>
                    </div>
                );
            }}
        </Transition>
    );
}

DialogAnimationWrapper.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};
