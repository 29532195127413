/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UIToggleSwitch } from 'app/components/ui';
import recipeImg from './recipe.img.png';


import './paperRecipeRow.scss';


const PaperRecipeTogleRow = (props) => {
    const {
        togglePaperRecipeSwitch,
        typeOfSet,
    } = props;

    const [isChecked, setIsChecked] = useState(false);

    const togglePaperOrDigitRecipe = () => {
        togglePaperRecipeSwitch(!isChecked);
        setIsChecked(!isChecked);
    };

    const recipeContentTextClasses = classNames({
        'recipe-content__text': true,
        enabled: isChecked,
        disabled: !isChecked,
    });

    if (typeOfSet !== 'regular') {
        return null;
    }

    return (
        <div styleName="step-payment-recipe-row-root">
            <div styleName="step-payment-recipe-row">
                <div styleName="recipe-content">
                    <div styleName="recipe-content__icon">
                        <img src={recipeImg} alt="recipe" styleName="" />
                    </div>
                    <div styleName={recipeContentTextClasses}>
                        Хочу получать
                        <br />
                        печатные рецепты
                    </div>
                </div>
                <UIToggleSwitch
                    onChanegHandler={togglePaperOrDigitRecipe}
                    isChecked={isChecked}
                    id="step-paper-recipe-toggle"
                />
            </div>
        </div>
    );
};

PaperRecipeTogleRow.propTypes = {
    togglePaperRecipeSwitch: PropTypes.func.isRequired,
    typeOfSet: PropTypes.string.isRequired,
};


const PaperRecipeDataAccessContainer = React.memo((props) => {
    return <PaperRecipeTogleRow {...props} />;
});

export { PaperRecipeDataAccessContainer };
