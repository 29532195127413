/* eslint-disable no-underscore-dangle */
import { eventsRecord } from './events.record';

// TODO: Разделить методы записей и методы конкретного события на два разныз класса

// const rSet = ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS', 'GoogleAnalytics', 'FacebookAnalytics'];

export class RecordClient {
    constructor() {
        this.analyticsClientName = 'default';
        this.eventsRecord = eventsRecord;
        this.specificRecord = [];
    }

    //  static getEventsMetaData() {}

    _setSpecificRecord(clientName) {
        this.specificRecord = this.eventsRecord[clientName];
    }

    _getRawEvents(data) {
        const rawEvents = this.specificRecord
            .filter((e) => e.eventName === data.eventName)
            .filter((e) => e.check(data));

        // TODO: тут надо дропнуть ошибку есть событие не найдено
        return rawEvents;
    }

    getEvent(data) {
        const rawEvents = this._getRawEvents(data);

        return rawEvents.map((e) => e.prepare({ ...data }));
    }

    setup(clientName) {
        this._setSpecificRecord(clientName);
    }
}


// const rSet = ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS', 'GoogleAnalytics', 'FacebookAnalytics'];


// const postEvent = async (eventData) => {
//     try {
//         const response = await fetch('http://192.168.1.60:8031/events', {
//             method: 'POST',
//             body: eventData,
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//         });
//         const json = await response.json();
//         console.log(json);
//     } catch (err) {
//         // ..ERR
//     }
// };

// static getEventsMetaData() {
//     const arr = rSet.map((e) => eventsRecord[e].map((event) => ({
//         eventName: event.eventName,
//         description: event.description,
//         meta: event.meta,
//         scope: event.scope,
//         clientName: e,
//     })));
//     const flatArr = arr.flat();
//     const prepArr = flatArr.map((event) => ({
//         ...event,
//         clientName: [event.clientName],
//     }));

//     const rrr = prepArr.reduce((acc, e) => {
//         let event = {};

//         const accEventIndex = acc.findIndex((ev) => ev.eventName === e.eventName);

//         if (accEventIndex === -1) {
//             event = e;
//         } else {
//             acc[accEventIndex].clientName = [...acc[accEventIndex].clientName, ...e.clientName];
//         }

//         return [...acc, event];
//     }, []);

//     const result = rrr.filter((e) => Object.keys(e).length !== 0);
//     const JSON_result = JSON.stringify(result);
//     postEvent(JSON_result)
// }
