/* eslint-disable no-underscore-dangle */
/* eslint-disable lines-between-class-members */
/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import { isStandaloneApp } from 'app/utils/browser';
import {
    GoogleAnalyticsClient,
    FacebookAnalyticsClient,
    FlocktoryAnalyticsClient,
    FirebaseAnalyticsClientFactory,
    CityAdsAnalyticsClient,
} from './clientFactories';
import { RecordClient } from './events.records';


// RecordClient.getEventsMetaData();

/**
 * @implements abstract factory
 */
class AnalyticsClientFactory {
    constructor() {
        this.analyticsClient = [];
    }
    create() {
        this.analyticsClient.push(new FacebookAnalyticsClient());
        this.analyticsClient.push(new FlocktoryAnalyticsClient());
        this.analyticsClient.push(new CityAdsAnalyticsClient());

        const mainClient = isStandaloneApp()
            ? new FirebaseAnalyticsClientFactory().create().getClient()
            : new GoogleAnalyticsClient();

        this.analyticsClient.push(mainClient);
        return this;
    }
    getClient() {
        return this.analyticsClient;
    }
}

class AnalyticsService {
    constructor() {
        this.specificServices = new AnalyticsClientFactory().create().getClient();
        this.recordClient = new RecordClient();
    }
    push(data) {
        try {
            this.specificServices.forEach((service) => {
                this.recordClient.setup(service.clientName);
                // TODO: Привести в нормальный вид! Убрать спред оператор
                const events = this.recordClient.getEvent({ ...data, name: service.clientName });
                events.forEach((event) => service.emit(event));
            });
        } catch (e) {
            console.log(e);
        }
        return this;
    }

    status() {

    }

    getService() {

    }
}

export const analyticService = new AnalyticsService();


export const trackScreenView = (pathname) => {
    analyticService.push({
        eventName: 'Track_Screen_View',
        screenName: pathname,
    });
};
