/* eslint-disable camelcase */
// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { abTestDataContext } from 'app/containers/contexts/abTest.context';

// unfetched

export const useCashbackNewUsersABTest = () => {
    const {
        // @ts-ignore
        cashback_new_users, fetchABTestValue, setABTestValue,
    } = useContext(abTestDataContext);

    const BAKSET_GOOD_DELIVERIES_USER_QUERY = gql`
    query BAKSET_GOOD_DELIVERIES_USER_QUERY {
            user {
                id
                hasGoodDeliveries
            }
        }
    `;

    const { data: { user } } = useQuery(BAKSET_GOOD_DELIVERIES_USER_QUERY, {
        fetchPolicy: 'cache-only',
    });

    // console.log(user);

    const isNeedToFetchABTest = useMemo(
        () => (user?.hasGoodDeliveries === false && cashback_new_users === 'unfetched'),
        [user?.hasGoodDeliveries, cashback_new_users],
    );

    // console.log(user);

    useEffect(() => {
        if (isNeedToFetchABTest) {
            console.log('set AB test');
            fetchABTestValue('cashback_new_users');
            // setABTestValue('cashback_new_users', 'control');
            // control with_cashback
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id]);
    return cashback_new_users;
};
