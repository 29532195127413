import React from 'react';
import PropTypes from 'prop-types';
// import InputMask from 'react-input-mask';
import classNames from 'classnames';

import { isIOS } from 'app/utils/browser';

import '../inputs.scss';


const CodeInput = ({
    // inputRef
    // eslint-disable-next-line react/prop-types
    value, onChange, onBlur, inputRef, hasError, errorText, viewMod,
}) => {
    const inputClasses = classNames({
        input: true,
        'has-error': hasError,
    });

    const labelClasses = classNames({
        'input--has-float-label': true,
        'input-error-container': true,
        'input--code': true,
        [viewMod]: Boolean(viewMod),
    });

    const autoComplete = isIOS() ? 'one-time-code' : 'off';

    return (
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label htmlFor="code" styleName={labelClasses}>
            <input
                styleName={inputClasses}
                name="confirm-code"
                placeholder="____"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                ref={inputRef}
                id={`code-${autoComplete}`}
                type="tel"
                autoComplete={autoComplete}
                maxLength="4"
            />
            <div styleName="input__label">Код</div>
            {hasError && <div styleName="input-error">{errorText}</div>}
        </label>
    );
};

export default CodeInput;

CodeInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
