import React from 'react';

export const locales = {
    ru: {
        filterTitle: "Стиль",
        "1765": "Премиум блюда",
        "1519": "Подходит для детей",
        "1520": "Без острого",
        "1220": "Без жареного/жирного",
        "1809": "До 15 мин",
        "2024": "Черноморская кухня",
        "2028": "Про запас",
        "2026": "Best",
    },
    en: {
        filterTitle: "Style",
        "1765": "Premium",
        "1519": "For children",
        "1520": "No spicy",
        "1220": "No fried",
        "1809": "Less than 15 min",
        "2024": "Black Sea cuisine",
        "2028": "Stocking up",
        "2026": "The Best",
    },
};
