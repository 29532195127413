import { gql } from '@apollo/client';


const authMutation = gql`
    mutation auth {
        auth {
            token
            isAuthed
        }
    }
`;

export default authMutation;
