import merge from 'lodash/merge';
import { getHiddenElements } from 'app/utils/url';
import {
    isBasketEditable, isPreassembledBasket,
} from '../checkers';


/**
 * @description Определение скрытых элементов в корзине
 */
export const checkHiddenElemnts = (props, _set, _get) => {
    const hiddenElements = getHiddenElements();
    const { type, needDeepCheck, preparedAreas } = _get();

    const isMobileMenuSelections = isBasketEditable() && isPreassembledBasket(props);


    const computedAreas = {
        top: {
            isCustomizationComment: !hiddenElements.comment,
            isTopTitle: hiddenElements.comment,
        },
        promo: {
            isMenuSelectionsMobileSlider: isMobileMenuSelections,
        },
    };

    const nextAreas = merge({}, preparedAreas, computedAreas);

    _set({
        type,
        needDeepCheck,
        preparedAreas: nextAreas,
    });
};
