import React, { useEffect, useMemo, useState } from 'react';

import { analyticService } from 'global/services';
import { FrameDialog } from 'app/components/Dialog';
import { UIButton, UIRadioIcon } from 'app/components/ui';
import { Portal } from 'react-portal';
import cn from 'classnames';
import { DialogStaticDesktop } from 'app/components/Dialog/BR1156/DialogStaticDesktop';
import { DialogAnimationWrapper } from 'app/components/Dialog/DialogAnimationWrapper';
import { UIRadioIconStatic } from 'app/components/ui/UIRadioIcon/UIRadioIconStatic';
import { Desktop, Mobile } from 'app/components/Responsive';
import Icon from './img/arrow.component.svg';

import css from './mobileCustomIngredientsSelect.module.scss';
import { ingredientTypeTitleRecord } from '../customIngredientsSelectDishRow.records';
// import { NextOverlay } from './overlay/nextOverlay';
import { ICustomIngredientsSelectProps } from '../CustomIngredientsSelectDishRow.types';

interface ISelectComponentProps extends ICustomIngredientsSelectProps {
    // eslint-disable-next-line no-unused-vars
    setIsOpen: (I: boolean) => void
    isOpen: boolean
    handleSubmit: () => void
}

const DialogWrapper = (props: any) => {
    const { children, setIsOpen, handleSubmit } = props;

    return (
        <>
            <Mobile>
                {/* @ts-ignore */}
                <FrameDialog
                    handleClose={() => setIsOpen(false)}
                >
                    {children}
                </FrameDialog>
            </Mobile>
            <Desktop>
                <DialogStaticDesktop
                    notifyOnly
                    onConfirm={handleSubmit}
                    onReject={() => { setIsOpen(false); }}
                    confirmText="Готово"
                    isCloseConfirmAction={false}
                >
                    {children}
                </DialogStaticDesktop>
            </Desktop>
        </>
    );
};

const SelectComponent = (props: ISelectComponentProps) => {
    const {
        id,
        isOpen,
        setIsOpen,
        selectedDishId,
        secondDishPart,
        ingredientsList = [],
        checkIsSelected,
        onNextIdSelected,
        handleSubmit = () => { },
    } = props;

    const handleClickOnIngredientItem = (targetId: string) => {
        onNextIdSelected(targetId);
    };

    useEffect(() => {
        if (isOpen) {
            analyticService.push({
                eventName: 'Custom_Card_Open',
                dish_id: id,
                source_name: 'menu',
            });
        }
    }, [isOpen, id]);

    /* SELECTORS */
    const title = useMemo(
        // @ts-ignore
        () => ingredientTypeTitleRecord[secondDishPart] || '',
        [secondDishPart],
    );

    return (
        // @ts-ignore
        <DialogWrapper
            handleSubmit={handleSubmit}
            setIsOpen={setIsOpen}
        >
            <div className={css.selectIngredientsRoot}>
                <div className={css.selectIngredientsTitle}>
                    Выберите
                    {' '}
                    {title}
                </div>
                <div className={css.selectIngredientsItems}>
                    {ingredientsList.map((item) => (
                        <button
                            className={cn(css.selectIngredientsItemButton)}
                            type="button"
                            onClick={() => handleClickOnIngredientItem(item.id)}
                            id={item.id}
                            key={item.id}
                            disabled={selectedDishId === item.id}
                        >
                            <div
                                className={
                                    // @ts-ignore
                                    cn(css.selectIngredientsItemText, checkIsSelected(item.id, selectedDishId))
                                }
                            >
                                {item.replaceableIngredient}
                            </div>
                            <Mobile>
                                <UIRadioIcon checked={item.id === selectedDishId} />
                            </Mobile>
                            <Desktop>
                                <UIRadioIconStatic checked={item.id === selectedDishId} />
                            </Desktop>
                        </button>
                    ))}
                </div>
                <Mobile>
                    <div className={css.selectIngredientsSubmit}>
                        <UIButton onClick={handleSubmit}>
                            Готово
                        </UIButton>
                    </div>
                </Mobile>
            </div>
        </DialogWrapper>
    );
};

// NOTE: Используется в корзинах в desktop'е
export function MobileCustomIngredientsSelect(props: ICustomIngredientsSelectProps) {
    const {
        id,
        selectedDishId,
        ingredientTypeText,
        replaceableIngredient,
        checkIsSelected,
        ingredientsList,
        secondDishPart,
        onNextIdSelected,
        environmentContext,
        onSubmitHandler = () => { },
        disabled = false,
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const openSelect = () => {
        analyticService.push({
            eventName: 'Custom_Card_Click',
            dish_id: id,
            source_name: 'menu',
        });

        // @ts-ignore
        setIsOpen(true);
    };

    const handleSubmit = () => {
        onSubmitHandler({
            onCompoleted: () => setIsOpen(false),
        });
    };

    const customIngredientsSelect = cn({
        [css.customIngredientsSelectRoot]: true,
        [css.basket]: environmentContext === 'basket',
        [css.menu]: environmentContext === 'menu',
    });

    const titleClasses = cn({
        [css.customIngredientsSelectButton]: true,
        [css.basket]: environmentContext === 'basket',
        [css.menu]: environmentContext === 'menu',
    });

    const iconClasses = cn({
        [css.customIngredientsSelectIcon]: true,
        [css.basket]: environmentContext === 'basket',
        [css.menu]: environmentContext === 'menu',
    });

    const customIngredientsSelectTextClasses = cn({
        [css.customIngredientsSelectText]: true,
        [css.basket]: environmentContext === 'basket',
        [css.menu]: environmentContext === 'menu',
    });

    return (
        <div className={customIngredientsSelect}>
            <button
                type="button"
                onClick={openSelect}
                className={titleClasses}
                disabled={disabled}
            >
                <div className={customIngredientsSelectTextClasses}>
                    <span className={css.ingredientType}>
                        {ingredientTypeText}
                    </span>
                    <span className={css.clampText}>
                        {replaceableIngredient}
                    </span>
                </div>
                <div className={iconClasses}>
                    <Icon />
                </div>
            </button>
            <Portal>
                <DialogAnimationWrapper
                    isOpen={isOpen}
                    onReject={() => { setIsOpen(false); }}
                >
                    <SelectComponent
                        id={id}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        selectedDishId={selectedDishId}
                        secondDishPart={secondDishPart}
                        checkIsSelected={checkIsSelected}
                        ingredientsList={ingredientsList}
                        onNextIdSelected={onNextIdSelected}
                        handleSubmit={handleSubmit}
                    />
                </DialogAnimationWrapper>
            </Portal>
        </div>

    );
}
