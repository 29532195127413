/* LIBS */
import React from 'react';
import { YMaps } from '@pbe/react-yandex-maps';

/* COMPOENTS */
import { UIHeading } from 'app/components/ui';
import { CheckoutYandexMap } from './DeliveryZoneMap';
import { DeliveryZoneTimeAndPrice } from './DeliveryZoneTimeAndPrice';
import { DeliveryZoneStreetForm } from './DeliveryZoneStreetForm';

/* TYPES */
import { IDeliveryZoneCardProps } from './DeliveryZoneStreetForm.type';

/* STYLES */
import css from './deliveryZoneCard.module.scss';

function DeliveryZoneCardContent(props: IDeliveryZoneCardProps) {
    const {
        mutateDZoneSessionAddress,
        setUIStateForEmptyAddress,
        externalInputUiState,
        externalButtonUiState,
        externalInputLabel,
    } = props;

    return (
        <div className={css.deliveryZoneCardRoot}>
            <div className={css.deliveryZoneCardTitle}>
                <UIHeading level="3.2">
                    Карта доставки
                </UIHeading>
            </div>
            <div className={css.deliveryZoneCardRows}>
                <DeliveryZoneStreetForm
                    mutateDZoneSessionAddress={mutateDZoneSessionAddress}
                    externalInputUiState={externalInputUiState}
                    externalButtonUiState={externalButtonUiState}
                    externalInputLabel={externalInputLabel}
                />
                <CheckoutYandexMap
                    mutateDZoneSessionAddress={mutateDZoneSessionAddress}
                    setUIStateForEmptyAddress={setUIStateForEmptyAddress}
                />
                {externalButtonUiState !== 'error' && (
                    <DeliveryZoneTimeAndPrice />
                )}
            </div>
        </div>
    );
}

function DeliveryZoneCardDAC(props: IDeliveryZoneCardProps) {
    const {
        mutateDZoneSessionAddress,
        setUIStateForEmptyAddress,
        externalInputUiState,
        externalButtonUiState,
        externalInputLabel,
    } = props;

    return (
        <DeliveryZoneCardContent
            mutateDZoneSessionAddress={mutateDZoneSessionAddress}
            setUIStateForEmptyAddress={setUIStateForEmptyAddress}
            externalInputUiState={externalInputUiState}
            externalButtonUiState={externalButtonUiState}
            externalInputLabel={externalInputLabel}
        />
    );
}

export function DeliveryZoneCard(props: IDeliveryZoneCardProps) {
    const {
        mutateDZoneSessionAddress,
        setUIStateForEmptyAddress,
        externalInputUiState,
        externalButtonUiState,
        externalInputLabel,
    } = props;

    return (
        <YMaps
            query={{
                apikey: '71144a26-0170-4997-8b22-f936d85791ed',
                coordorder: 'longlat',
            }}
        >
            <DeliveryZoneCardDAC
                mutateDZoneSessionAddress={mutateDZoneSessionAddress}
                setUIStateForEmptyAddress={setUIStateForEmptyAddress}
                externalInputUiState={externalInputUiState}
                externalButtonUiState={externalButtonUiState}
                externalInputLabel={externalInputLabel}
            />
        </YMaps>
    );
}


