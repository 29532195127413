import { isDesktop } from 'app/utils/resolution';

const COUNTRY_PHONE_CODE = '+7';
const EXCEPTED_START_DIGITS = ['7', '8'];

/**
 * TODO: По хорошему всю эту логику нужно привести к одному виду
 * 1. Написать ряд проверок определяющих формат номера.
 * Пример: номер без символа + перед семеркой как из local storage, номер из
 * автокомплита из safari без +7, номер, который начинается на 8.
 * 2. Вызывать специфический обработчик под конкретный сценарий
*/


// '+7 999 999-99-99' -> '79999999999'
export const getDigitsFromPhone = (phone) => phone.replace(/\D+/g, '');
/**
 * '+7 999 ___-__-__'  -> '+7999'
 * '+7 999 999-99-99'  -> '+79999999999'
 * '+7 899 999-99-999' -> '+79999999999' - удаляет первую ненужную '8' в телефонном номере
 * '+7 799 999-99-999' -> '+79999999999' - удаляет первую ненужную '7' в телефонном номере
 */


const sliceUncontrolCursorPositionPhone = (phone, isPrevPhoneInitial) => {
    if (isPrevPhoneInitial && phone.length === 5) {
        return phone.substring(0, phone.length - 1);
    }
    return phone;
};

export const normalizePhone = (phone, prevPhone) => {
    if (phone.length === 0) return phone;

    const contryCodeSymbolsLengthToRemove = phone.includes('+7')
        ? COUNTRY_PHONE_CODE.length
        : 1;

    const isPrevPhoneInitial = prevPhone === '+7';
    const preparedPhone = isDesktop() ? phone : sliceUncontrolCursorPositionPhone(phone, isPrevPhoneInitial);
    const phoneWithoutCountryCode = preparedPhone.slice(contryCodeSymbolsLengthToRemove);
    const phoneOnlyNumbers = phoneWithoutCountryCode.replace(/\D+/g, '');
    const phoneWithoutExceptedStartDigits = EXCEPTED_START_DIGITS.includes(phoneOnlyNumbers[0])
        ? phoneOnlyNumbers.slice(1)
        : phoneOnlyNumbers;

    const telephone = COUNTRY_PHONE_CODE + phoneWithoutExceptedStartDigits;

    return telephone;
};

export const formatPhone = (phone, optionsArg = {}) => {
    const defaultOptions = {
        operatorDelimiters: [' (', ') '],
        carDelimiters: '-',
    };
    const options = {
        ...defaultOptions,
        ...optionsArg,
    };
    const {
        operatorDelimiters: [od1, od2],
        carDelimiters,
    } = options;

    const phoneOnlyNumbers = getDigitsFromPhone(phone);

    const r = /(\d)?(\d)?(\d)?(\d)?(\d)?(\d)?(\d)?(\d)?(\d)?(\d)?(\d)?/;
    let arrayedPhone = [];

    /**
     * для каждой цифры из передаваемого номера телефона создается группа.
     * Группа может быть определена, но цифры может не быть (еще введен не весь номер в инпут).
     * В этом случае группа будет 'undefined'.
     * Все группы и добавочные символы (скобки, тире, пробелы)
     * добавляются в массив в нужном порядке по маске телефона '+7 (999) 999-99-99'.
     * После чего в массиве ищется первый по индексу 'undefined'-элемент.
     * И итоговый номер собирается только до этого элемента.
     */
    phoneOnlyNumbers.replace(
        r,
        (str, g1, g2, g3, g4, g5, g6, g7, g8, g9, g10, g11) => {
            arrayedPhone = [COUNTRY_PHONE_CODE, od1, g2, g3, g4, od2, g5, g6, g7, carDelimiters, g8, g9, carDelimiters, g10, g11];
        },
    );

    const firstUndefinedIndex = arrayedPhone.findIndex((i) => typeof i === 'undefined');
    const lastIndex = arrayedPhone.length + 1;
    const lastDigitIndex = arrayedPhone.reduce((acc, i, index) => (!isNaN(i) ? index : acc), -1);

    const isUndefinedItemExisted = firstUndefinedIndex !== -1;

    const lastIndexToSlice = isUndefinedItemExisted ? lastDigitIndex + 1 : lastIndex;

    const defaultResult = [COUNTRY_PHONE_CODE, od1].join('');
    const result = arrayedPhone.slice(0, lastIndexToSlice).join('');

    return result.includes(defaultResult) ? result : defaultResult;
};


/**
 * @description привод телефон к формату для функции normalizePhone.
 * Ставит + перед номером там где это надо
 * В local-storage телефон хранится без + перед кодом странны
 */
// TODO: Отдать +7 если телефон пустой
export const chachedPhoneAdapder = (phone) => {
    if (!phone) {
        return '';
    }

    const isPhoneFromCacheRegex = /^7(?=9)/;

    const isPhoneFromCache = phone.length === 11 && isPhoneFromCacheRegex.test(phone);
    const isInitialPhone = phone === '7';

    if (isPhoneFromCache || isInitialPhone) {
        return `+${phone}`;
    }

    return phone;
};
