import React from 'react';
import PropTypes from 'prop-types';

import { UITag } from 'app/components/ui';
import FilterItem from '../FilterItem';

import { locales } from './style.locales';

import '../../mobile-filters.scss';

const Style = (props) => {
    const {
        disabled,
        selected,
        list,
        onRemove,
        onAdd,
        locale,
    } = props;

    const { filterTitle } = locales[locale];

    return (
        <FilterItem
            title={filterTitle}
            className="style-container"
        >
            <ul styleName="list">
                {list.map((tag) => {
                    const { id, count, title: titleProp } = tag;
                    const title = locales[locale][id] || titleProp;
                    const isSelected = selected.includes(id);
                    const onClick = isSelected
                        ? () => onRemove(tag)
                        : () => onAdd(tag);

                    return (
                        <li styleName="filter-tag__list-item" key={id}>
                            <UITag
                                id={id}
                                text={title}
                                count={count}
                                isSelected={isSelected}
                                disabled={count === 0 || disabled}
                                onChange={onClick}
                            />
                        </li>
                    );
                })}
            </ul>
        </FilterItem>
    );
};

export default Style;

Style.propTypes = {
    disabled: PropTypes.bool.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string).isRequired,
    list: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        count: PropTypes.number,
    })).isRequired,
    onRemove: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
};
