import React from 'react';
import cn from 'classnames';

import { UIRadioIcon, UIRadioIconV2 } from 'app/components/ui';
import { Mobile, Desktop } from 'app/components/Responsive';

import { TimeInterval } from '../DatetimeSelect.types';

import css from './timeSelect.module.scss';


interface TimeSelectProps {
    disabled?: boolean,
    timeIntervals: TimeInterval[],
    selectedIntervalIndex: number,
    responsive: boolean,
    onSelectInterval: (nextIndex: number) => void, // eslint-disable-line no-unused-vars
}


export function TimeSelect({
    disabled = false,
    timeIntervals,
    selectedIntervalIndex,
    responsive,
    onSelectInterval,
}: TimeSelectProps) {
    const containerClasses = cn({
        [css.timeSelectContainer]: true,
        [css.responsiveContainer]: responsive,
    });

    return (
        <div className={containerClasses}>
            {timeIntervals.map((interval, index) => {
                const {
                    id,
                    short_time_from: timeFrom,
                    short_time_to: timeTo,
                } = interval;

                const checked = index === selectedIntervalIndex;
                const isDisabled = disabled || checked;

                const buttonClasses = cn({
                    [css.timeSelectItem]: true,
                    [css.timeSelectItemActive]: checked,
                });

                return (
                    <button
                        key={id}
                        type="button"
                        className={buttonClasses}
                        disabled={isDisabled}
                        onClick={() => onSelectInterval(index)}
                    >
                        {!disabled && (
                            <Desktop>
                                {responsive
                                    ? <UIRadioIconV2 checked={checked} />
                                    : <UIRadioIcon checked={checked} />}
                            </Desktop>
                        )}
                        <span className={css.timeSelectItemText}>
                            {timeFrom}
                            {' — '}
                            {timeTo}
                        </span>
                        {!disabled && (
                            <Mobile>
                                <UIRadioIcon checked={checked} />
                            </Mobile>
                        )}
                    </button>
                );
            })}
        </div>
    );
}
