import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './loading.scss';


const Loading = ({ disabled = false }) => {
    const dotCommonClasses = cn({
        loading__dot: true,
        disabled,
    });

    return (
        <div styleName="loading__root">
            <div styleName={dotCommonClasses} />
            <div styleName={dotCommonClasses} />
            <div styleName={dotCommonClasses} />
        </div>
    );
};


Loading.propTypes = {
    disabled: PropTypes.bool,
};
export default Loading;
