import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import Raven from 'raven-js';
import queryString from 'query-string';
import cookie from 'js-cookie';

import { isStandaloneApp } from 'app/utils/browser';
import { setExistedCookieValue } from 'app/utils/setCookie';
import { replaceExcessQuestionMarks } from 'app/utils/url';
import { STORE_USER_DATA } from './storeUserDataMutation';

// TODO: move to separate file
function getGaClientId() {
    const gaClientIdPromise = new Promise((resolve, reject) => {
        const maxWaitingTime = 4 * 60 * 1000; // 4 min
        const interval = 300;

        const timer = setInterval(() => {
            if (!window.ga || !window.ga.getAll) return;

            clearInterval(timer);

            const tracker = window.ga.getAll()[0];
            if (tracker) {
                const clientId = tracker.get('clientId');

                const urlParams = new URL(window.location.href).searchParams;
                const andriodAppInstanceId = urlParams.get('appInstanceId');
                const id = andriodAppInstanceId || clientId;

                resolve(id);
            }
        }, interval);

        setTimeout(() => {
            clearInterval(timer);

            Raven.captureException(new Error('GA loading timed out'), {
                extra: {
                    ga: window.ga,
                    getAll: window.ga ? window.ga.getAll : null,
                },
            });

            reject();
        }, maxWaitingTime);
    });

    return gaClientIdPromise;
}

/**
 * @description session_id можно получить в cookies браузера пользователя под
 * именем _ga_1V0GX562Z2
*/
const getGaSessionId = () => {
    const allGaCookie = cookie.get();
    const gaSessionCookie = allGaCookie._ga_1V0GX562Z2;
    if (gaSessionCookie) {
        return gaSessionCookie.split('.')[2] || undefined;
    }
    return undefined;
};

const getCityadsClickId = () => {
    const validatedQuery = replaceExcessQuestionMarks(window.location.search);
    const parsedQuery = queryString.parse(validatedQuery);
    const {
        click_id: clickId,
        utm_source: utmSource,
    } = parsedQuery;

    const utmSourceFromCookie = cookie.get('utm_source');
    const clickIdFromCookie = cookie.get('click_id');

    const validValuesForUTMSource = [undefined, 'cityads'];

    const hasAnotherUTM = utmSource
        ? !validValuesForUTMSource.includes(utmSource)
        : !validValuesForUTMSource.includes(utmSourceFromCookie);

    const isUTMForCityAds = utmSource
        ? utmSource === 'cityads'
        : utmSourceFromCookie === 'cityads';
    const isUTMSourceUpToSend = !hasAnotherUTM && isUTMForCityAds;

    return isUTMSourceUpToSend ? (clickId || clickIdFromCookie) : undefined;
};


export function DataSaverUser() {
    const [storeUserData] = useMutation(STORE_USER_DATA, {
        onError: (error) => {
            Raven.captureException(error);
        },
        context: {
            message: 'root:mutate:DataSaverUser',
        },
    });

    useEffect(() => {
        try {
            const validatedQuery = replaceExcessQuestionMarks(window.location.search);
            const parsedQuery = queryString.parse(validatedQuery);
            const {
                click_id: clickId,
                utm_source: utmSource,
            } = parsedQuery;

            const utmSourceFromCookie = cookie.get('utm_source');
            const clickIdFromCookie = cookie.get('click_id');

            const validValuesForUTMSource = [undefined, 'cityads'];

            const hasAnotherUTM = utmSource
                ? !validValuesForUTMSource.includes(utmSource)
                : !validValuesForUTMSource.includes(utmSourceFromCookie);

            const isUTMForCityAds = utmSource
                ? utmSource === 'cityads'
                : utmSourceFromCookie === 'cityads';
            const isUTMSourceUpToSend = !hasAnotherUTM && isUTMForCityAds;

            const utmCookieToSet = utmSource || utmSourceFromCookie;

            const cityadsDataVars = {
                currentState: {
                    utmSourceFromCookie,
                    utmSourceFromUrl: utmSource,
                    hasAnotherUTM,
                    isUTMForCityAds,
                    isUTMSourceUpToSend,
                },
                cookieToSet: {
                    utmSource: null,
                },
            };

            if (utmCookieToSet !== utmSourceFromCookie) {
                cityadsDataVars.cookieToSet.utmSource = utmCookieToSet;
                setExistedCookieValue('CityAdsAnalitycs', 'utm_source', utmCookieToSet, 30);
            }

            const clickIdCookieToSet = clickId || clickIdFromCookie;
            if (clickIdCookieToSet !== clickIdFromCookie) {
                setExistedCookieValue('CityAdsAnalitycs', 'click_id', (clickId || clickIdFromCookie), 30);
            }

            // console.log(cityadsDataVars, 'cityadsDataVars');

            const variables = {
                hasApplication: isStandaloneApp() ? true : undefined,
                cityAdsClickId: isUTMSourceUpToSend ? (clickId || clickIdFromCookie) : undefined,
                gaSessionId: getGaSessionId(),
            };


            storeUserData({ variables });
        } catch (error) {
            Raven.captureException(error);
        }
    }, [storeUserData]);

    /**
     * @description gaSessionId это динамический параметр, который обновляется
     * каждый в период бездействия более 30 мин на сайте, или смене источника
     * визита пользователя.
    */
    useEffect(() => {
        const GA_UPDATE_INTERVAL = 1860 * 1000; // 31m
        const interval = setInterval(
            () => {
                storeUserData({
                    variables:
                    {
                        gaSessionId: getGaSessionId(),
                        cityAdsClickId: getCityadsClickId(),
                    },
                });
            },
            GA_UPDATE_INTERVAL,
        );
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        getGaClientId().then((gaClientId) => {
            storeUserData({
                variables:
                {
                    gaClientId,
                    cityAdsClickId: getCityadsClickId(),
                    gaSessionId: getGaSessionId(),
                },
            });
        });
    }, [storeUserData]);

    return null;
}
