export const locales = {
    ru: {
        // Render
        preloadText: 'Загрузка...',
        // renderFooterButton
        emptyButtonText: 'Выбрать блюда',
        fullButtonText: 'Оформить заказ',
        // renderCustomizationComment
        withCommentTitle: 'Комментарий к блюдам',
        withOutCommentTitle: 'Корзина',
        // renderMobileBasketHeader
    },
    en: {
        // Render
        preloadText: 'Loading...',
        // renderFooterButton
        emptyButtonText: 'Add dishes',
        fullButtonText: 'Checkout',
        // renderCustomizationComment
        withCommentTitle: 'Your comments',
        withOutCommentTitle: 'Cart',
        // renderMobileBasketHeader
    },
};


