import { LOCATION_SPB, LOCATION_MSK, LOCATION_NIZ } from 'app/const/location';
import { isNewbie } from 'app/utils/user/user';
import { createUrl, joinSearchParamValue } from 'app/utils/url';
import { defaultSetLink } from 'app/const/links/const';


// ***** TRIAL_FIRST_ORDER *****

/**
* Промокоды, дающие скидку в 15 процентов
* BR-976
*/
export const PROMO_15 = {
    menu: '22sndrtry', // используется в меню и в ЛК нового пользователя "Готовый набор от шефа"
    mainLanding: '22mnsbtry', // используется в ссылках на основном лендинге
    landing5x2: '22try5x2', // используется на лендинге '/5x2'
    profile: '22abnrtry', // используется в ЛК нового пользователя "Хочу собрать свой набор"
};

export const PROMO_25 = {
    landing5x2: 'HI25',
};

// Для реферальной ссылки используется промокод со скидкой в 1000 рублей вместо 15 процентов
const PROMO_REFERAL = 'try_ref';

/**
* Промокоды, дающие скидку в 33 процента, остаются для маректиговых ссылок
*/
const PROMO_33 = {
    menu: 'sndrtry', // используется в меню и в ЛК нового пользователя "Готовый набор от шефа"
    mainLanding: 'mnsbtry', // используется в ссылках на основном лендинге
    // landing5x2: 'try5x2', // используется на лендинге '/5x2'
    landing5x2: '2try5x2', // используется на лендинге '/5x2'
    profile: 'abnrtry', // используется в ЛК нового пользователя "Хочу собрать свой набор"
};

// searchParams:
const dishIds = ['1921', '3009', '3011', '2599', '4135'];

const tinkoffDishIds = [...dishIds, '3093'];
const beelineDishIds = [...dishIds, '3348'];
const besplatnoDishIds = [...dishIds, '4214'];
const dishIdsShortened = ['25', '2783', '1924'];

const BASKET_SCHEMA = [
    ['dishes', joinSearchParamValue(...dishIds)],
];

const TINKOFF_DISH_SCHEMA = [
    ['dishes', joinSearchParamValue(...tinkoffDishIds)],
];

const BEELINE_DISH_SCHEMA = [
    ['dishes', joinSearchParamValue(...beelineDishIds)],
];

const BESPLATNO_DISH_SCHEMA = [
    ['dishes', joinSearchParamValue(...beelineDishIds)],
];

const COMMON_SEARCH_PARAMS = [
    ['payment', 'online'],
];

export const BASKET_COMMON_SEARCH_PARAMS = [
    ...COMMON_SEARCH_PARAMS,
    ...BASKET_SCHEMA,
    // ['extra', '5x2'],
];

export const TINKOFF_BASKET_COMMON_SEARCH_PARAMS = [
    ...COMMON_SEARCH_PARAMS,
    ...TINKOFF_DISH_SCHEMA,
];

export const BEELINE_BASKET_COMMON_SEARCH_PARAMS = [
    ...COMMON_SEARCH_PARAMS,
    ...BEELINE_DISH_SCHEMA,
];

export const BESPLATNO_BASKET_COMMON_SEARCH_PARAMS = [
    ...COMMON_SEARCH_PARAMS,
    ...BESPLATNO_DISH_SCHEMA,
];


// hrefs:
const TINKOFF_BASKET_HREF = createUrl({
    path: 'basket',
    params: TINKOFF_BASKET_COMMON_SEARCH_PARAMS,
});

const BEELINE_BASKET_HREF = createUrl({
    path: 'basket',
    params: BEELINE_BASKET_COMMON_SEARCH_PARAMS,
});

const BESPLATNO_BASKET_HREF = createUrl({
    path: 'basket',
    params: BESPLATNO_BASKET_COMMON_SEARCH_PARAMS,
});

const BASKET_HREF = createUrl({
    path: 'basket',
    params: [
        ...BASKET_COMMON_SEARCH_PARAMS,
        ['promo_code', PROMO_15.menu],
        // ['hide', joinSearchParamValue('comment', 'edit_basket', 'payment_button_recurrent')],
    ],
});

const BASKET_HREF_MOBILE_AB = createUrl({
    path: 'basket',
    params: [
        ...BASKET_COMMON_SEARCH_PARAMS,
        // ['hide', joinSearchParamValue('comment', 'edit_basket', 'payment_button_recurrent')],
    ],
});

const BASKET_HREF_33 = createUrl({
    path: 'basket',
    params: [
        ...BASKET_COMMON_SEARCH_PARAMS,
        ['promo_code', PROMO_33.menu],
        // ['hide', joinSearchParamValue('comment', 'edit_basket', 'payment_button_recurrent')],
    ],
});

const BASKET_HREF_FROM_LANDING = createUrl({
    path: 'basket',
    params: [
        ...BASKET_COMMON_SEARCH_PARAMS,
        ['promo_code', PROMO_15.mainLanding],
        // ['hide', joinSearchParamValue('comment', 'edit_basket', 'payment_button_recurrent')],
    ],
});

const BASKET_HREF_FROM_LANDING_MOBILE_AB = createUrl({
    path: 'basket',
    params: [
        ...BASKET_COMMON_SEARCH_PARAMS,
        // ['hide', joinSearchParamValue('comment', 'edit_basket', 'payment_button_recurrent')],
    ],
});

// все параметры, кроме path совпадают с BASKET_HREF
const TINKOFF_CHECKOUT_HREF = createUrl({
    path: 'checkout',
    params: TINKOFF_BASKET_COMMON_SEARCH_PARAMS,
});
const BEELINE_CHECKOUT_HREF = createUrl({
    path: 'checkout',
    params: BEELINE_BASKET_COMMON_SEARCH_PARAMS,
});
const BESPLATNO_CHECKOUT_HREF = createUrl({
    path: 'checkout',
    params: BEELINE_BASKET_COMMON_SEARCH_PARAMS,
});

const CHECKOUT_HREF = createUrl({
    path: 'checkout',
    params: [
        ...BASKET_COMMON_SEARCH_PARAMS,
        ['promo_code', PROMO_15.landing5x2],
        // ['hide', joinSearchParamValue('comment', 'edit_basket', 'payment_button_recurrent')],
    ],
});

const CHECKOUT_HREF_33 = createUrl({
    path: 'checkout',
    params: [
        ...BASKET_COMMON_SEARCH_PARAMS,
        ['promo_code', PROMO_33.landing5x2],
        // ['hide', joinSearchParamValue('comment', 'edit_basket', 'payment_button_recurrent')],
    ],
});

const MENU_HREF = createUrl({
    path: 'menu',
    params: [
        ...COMMON_SEARCH_PARAMS,
        ['hide', 'payment_button_recurrent'],
        ['promo_code', PROMO_15.profile],
    ],
});

const REFERAL_HREF = createUrl({
    path: 'ref-basket',
    params: [
        ...BASKET_COMMON_SEARCH_PARAMS,
        ['promo_code', PROMO_REFERAL],
        // ['hide', joinSearchParamValue('comment', 'edit_basket', 'payment_button_recurrent')],
    ],
});


// sets:
const TRIAL_FIRST_ORDER_SET = {
    ...defaultSetLink,

    id: 'TRIAL_FIRST_ORDER_SET',
    check: ({ user }) => isNewbie(user),

    promoMenu: PROMO_15.menu,
    promoLanding: PROMO_15.mainLanding,
    promoProfile: PROMO_15.profile,

    originalPrice: '3 174',
    discountPrice: '2 697',
    discountPercent: '15',
    discountSum: '477',

    /**
     * множество href'ов объясняется наличием разных промо
     * для аналитики переходов
     */
    hrefBasket: BASKET_HREF, // основная ссылка в корзину
    hrefBasketMobileAB: BASKET_HREF_MOBILE_AB, // переход из превью корзины
    hrefFromLanding: BASKET_HREF_FROM_LANDING, // переходы из главного лендинга
    // TODO: Удалить после A/B теста
    hrefFromLandingMobileAB: BASKET_HREF_FROM_LANDING_MOBILE_AB, // переходы из главного лендинга
    hrefMenu: MENU_HREF, // используется в профиле для 'Хочу собрать свой набор'
    hrefCheckout: CHECKOUT_HREF,
    hrefReferal: REFERAL_HREF,

    basketSchema: BASKET_SCHEMA,
    subdivisions: [LOCATION_MSK, LOCATION_SPB, LOCATION_NIZ],
    dishesCount: 5,
    setType: 'trial',
    datesType: 'eeAvailableDates',

    dishIds,
};

const TRIAL_FIRST_ORDER_SET_SHORTENED = {
    ...TRIAL_FIRST_ORDER_SET,
    originalPrice: 3860,
    dishIds: dishIdsShortened,
};

const TINKOFF_TRIAL_SET = {
    ...TRIAL_FIRST_ORDER_SET,
    id: 'TINKOFF_TRIAL_SET',
    dishesCount: 6,
    dishIds: tinkoffDishIds,
    hrefCheckout: TINKOFF_CHECKOUT_HREF,
    hrefBasket: TINKOFF_BASKET_HREF,
    basketSchema: TINKOFF_DISH_SCHEMA,
};

const BEELINE_TRIAL_SET = {
    ...TRIAL_FIRST_ORDER_SET,
    id: 'BEELINE_TRIAL_SET',
    dishesCount: 6,
    dishIds: beelineDishIds,
    hrefCheckout: BEELINE_CHECKOUT_HREF,
    hrefBasket: BEELINE_BASKET_HREF,
    basketSchema: BEELINE_DISH_SCHEMA,
};

const BESPLATNO_TRIAL_SET = {
    ...TRIAL_FIRST_ORDER_SET,
    id: 'BESPLATNO_TRIAL_SET',
    dishesCount: 6,
    dishIds: besplatnoDishIds,
    hrefCheckout: BESPLATNO_CHECKOUT_HREF,
    hrefBasket: BESPLATNO_BASKET_HREF,
    basketSchema: BESPLATNO_DISH_SCHEMA,
};

const TRIAL_FIRST_ORDER_SET_33 = {
    ...defaultSetLink,

    id: 'TRIAL_FIRST_ORDER_SET_33',
    check: ({ user }) => isNewbie(user),

    promoMenu: PROMO_15.menu,
    promoLanding: PROMO_15.mainLanding,
    promoProfile: PROMO_15.profile,

    originalPrice: '3 174',
    discountPrice: '1 990',
    discountPercent: '37',
    discountSum: '1 184',

    /**
     * множество href'ов объясняется наличием разных промо
     * для аналитики переходов
     */
    hrefBasket: BASKET_HREF_33, // основная ссылка в корзину
    // hrefFromLanding: BASKET_HREF_FROM_LANDING_33, // переходы из главного лендинга
    // hrefMenu: MENU_HREF_33, // используется в профиле для 'Хочу собрать свой набор'
    hrefCheckout: CHECKOUT_HREF_33,
    // hrefReferal: REFERAL_HREF_33,

    basketSchema: BASKET_SCHEMA,
    subdivisions: [LOCATION_MSK, LOCATION_SPB, LOCATION_NIZ],
    dishesCount: 5,
    setType: 'trial',
    datesType: 'trialAvailableDates',

    dishIds,
};

const TRIAL_FIRST_ORDER_SET_SHORTENED_DISH_SCHEMA = [
    ['dishes', joinSearchParamValue(...dishIdsShortened)],
];
export const TRIAL_FIRST_ORDER_SET_SHORTENED_SEARCH_PARAMS = [
    ...COMMON_SEARCH_PARAMS,
    ...TRIAL_FIRST_ORDER_SET_SHORTENED_DISH_SCHEMA,
];


const harryDishIds = ['3871', '3878', '3880', '3879', '3873'];
const HARRY_DISH_SCHEMA = [
    ['dishes', joinSearchParamValue(...harryDishIds)],
];
export const HARRY_BASKET_COMMON_SEARCH_PARAMS = [
    ...COMMON_SEARCH_PARAMS,
    ...HARRY_DISH_SCHEMA,
];
const HARRY_BASKET_HREF = createUrl({
    path: 'basket',
    params: HARRY_BASKET_COMMON_SEARCH_PARAMS,
});
const HARRY_CHECKOUT_HREF = createUrl({
    path: 'checkout',
    params: HARRY_BASKET_COMMON_SEARCH_PARAMS,
});

const HARRY_TRIAL_SET = {
    ...TRIAL_FIRST_ORDER_SET,
    id: 'HARRY_TRIAL_SET',
    discountPrice: '1 000',
    dishesCount: 5,
    dishIds: harryDishIds,
    hrefCheckout: HARRY_CHECKOUT_HREF,
    hrefBasket: HARRY_BASKET_HREF,
    basketSchema: HARRY_DISH_SCHEMA,
};


export {
    TRIAL_FIRST_ORDER_SET,
    TRIAL_FIRST_ORDER_SET_SHORTENED,
    TRIAL_FIRST_ORDER_SET_33,
    TINKOFF_TRIAL_SET,
    BEELINE_TRIAL_SET,
    BESPLATNO_TRIAL_SET,
    HARRY_TRIAL_SET,
};
