import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';

import pluralize from 'app/utils/pluralize';

import { LocaleContext } from 'app/containers/LocaleProvider';
import {
    locales,
    PORTION_UM_ALIAS_TYPE,
} from 'app/const/umAlias';

import './portion-mobile.scss';

const PortionMobile = ({
    portionAlias = null, portion, disabled, dishItemType: type, dishItemType,
}) => {
    const isDishItemSmall = type === 'small';
    const isDishItemTrial = type === 'trial';
    const { locale } = useContext(LocaleContext);
    // =======
    const [UM_ALIAS_TYPE] = portionAlias || [PORTION_UM_ALIAS_TYPE];
    const portionAliases = locales[locale][UM_ALIAS_TYPE];
    const portionText = pluralize(portion, portionAliases || portionAlias);
    // ======
    const canShowText = !isDishItemSmall && !portionAlias && !isDishItemTrial;

    const { portionUnitText } = locales[locale];

    const selectClasses = classNames({
        'portions-select': true,
        disabled,
    });

    const textContainerClasses = classNames({
        'basket-with--dots': dishItemType === 'small' && !disabled,
        'portions-select__text-container': true,
        disabled,
    });

    return (
        <div styleName={selectClasses}>
            <div styleName={textContainerClasses}>
                <span styleName={`portions__count ${type}`} data-test-id="portions__count">{portion}</span>
                &nbsp;
                <span styleName={`portions__units ${type}`}>{portionText}</span>

                {canShowText && (
                    <MediaQuery query="(min-width: 375px)">
                        &nbsp;
                        <span styleName={`portions__units ${type}`}>{portionUnitText}</span>
                    </MediaQuery>
                )}
            </div>
        </div>
    );
};

PortionMobile.propTypes = {
    portionAlias: PropTypes.arrayOf(PropTypes.string),
    portion: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    dishItemType: PropTypes.string.isRequired, // small, trial
};

export default PortionMobile;
