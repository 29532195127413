import PropTypes from 'prop-types';

const {
    number, string, bool, shape,
    arrayOf, oneOfType,
} = PropTypes;

/* TYPE ============================================================= */

export const totalsType = {
    delivery_price: number,
    discount_amount: number,
    promocode_amount: number,
    promocode_applied: bool,
    referral_amount: number,
    static_common_price: number,
    static_discount_price: number,
    total_common_price: number,
    total_discount_price: number,
    total_price: number,
    __typename: string,

};

export const nutritionItemType = {
    title: string,
    unit: string,
    value: number,
    __typename: string,
};

export const nutritionType = {
    calories: shape(nutritionItemType),
    carbohydrate: shape(nutritionItemType),
    fat: shape(nutritionItemType),
    protein: shape(nutritionItemType),
    __typename: string,
};

export const tagType = {
    id: string,
    title: string,
    __typename: string,
};

export const menuDishType = {
    caption: string,
    captionEn: string,
    categoryId: oneOfType([number, string]),
    composition: string,
    cook_until: string,
    cooking_time: number,
    id: string,
    nutrition: shape(nutritionType),
    parentId: number,
    partnersComment: string,
    portions: arrayOf(number),
    previewImage: string,
    previewMobileImage: string,
    price: number,
    tags: arrayOf(shape(tagType)),
    title: string,
    titleEn: string,
    umAlias: arrayOf(string),
    weight: number,
    __typename: string,
};

export const cartItemType = {
    discount_price: number,
    dish: shape(menuDishType),
    dish_id: string,
    is_promo: bool,
    portions: number,
    price: number,
    promoDiscountPrice: number,
    type: string,
    __typename: string,
};

export const cartSectionType = {
    gift_category: bool,
    id: string,
    items: arrayOf(shape(cartItemType)),
    title: string,
    __typename: string,
};

export const discountConditionsType = {
    deliveriesCount: number,
    differenceBetweenPromocodeAndOrder: number,
    discountDuration: string,
    discountType: number,
    discountValue: number,
    isPromoCodeExists: bool,
    isPromoCodeActivated: bool,
    isPromocodeCombineWithDiscount: bool,
    isSubscriptionActivated: bool,
    period: string,
    promocode: string,
    promocode_status: string,
    __typename: string,
};

export const cartType = {
    haveDishesFromTrial: bool,
    period: string,
    sections: arrayOf(shape(cartSectionType)),
    totals: shape(totalsType),
    typeOfSet: string,
    discount_conditions: shape(discountConditionsType),
    __typename: string,
};


/* INITAIL TYPE ============================================================= */

export const totalsInitialType = {
    delivery_price: 0,
    discount_amount: 0,
    promocode_amount: 0,
    promocode_applied: false,
    referral_amount: 0,
    static_common_price: 0,
    static_discount_price: 2856,
    total_common_price: 2697,
    total_discount_price: 2697,
    total_price: 3174,
    __typename: 'cartTotal',
};

export const nutritionItemInitialType = {
    title: '',
    unit: '',
    value: 0,
    __typename: 'nutritionItem',
};

export const nutritionInitialType = {
    calories: nutritionItemInitialType,
    carbohydrate: nutritionItemInitialType,
    fat: nutritionItemInitialType,
    protein: nutritionItemInitialType,
    __typename: 'nutrition',
};

export const tagInitialType = {
    id: '1229',
    title: 'Стандартное',
    __typename: 'tag',
};

export const menuDishInitialType = {
    caption: '',
    captionEn: '',
    categoryId: 0,
    composition: '',
    cook_until: '',
    cooking_time: 0,
    id: '',
    nutrition: nutritionInitialType,
    parentId: 0,
    partnersComment: '',
    portions: [0],
    previewImage: '',
    previewMobileImage: '',
    price: 0,
    tags: [tagInitialType],
    title: '',
    titleEn: '',
    umAlias: [''],
    weight: 0,
    __typename: 'menuDish',
};

export const cartItemInitialType = {
    discount_price: 0,
    dish: menuDishInitialType,
    dish_id: '',
    is_promo: false,
    portions: 0,
    price: 0,
    promoDiscountPrice: 0,
    type: '',
    __typename: 'cartItem',
};


export const cartSectionInitialType = {
    gift_category: false,
    id: '',
    items: [cartItemInitialType],
    title: '',
    __typename: 'cartSection',
};



export const discountConditionsInitialType = {
    deliveriesCount: 0,
    differenceBetweenPromocodeAndOrder: 0,
    discountDuration: '',
    discountType: 0,
    discountValue: 0,
    isPromoCodeExists: false,
    isPromoCodeActivated: false,
    isPromocodeCombineWithDiscount: false,
    isSubscriptionActivated: false,
    period: '',
    promocode: '',
    promocode_status: '',
    __typename: 'DiscountConditions',
};

export const cartInitialType = {
    haveDishesFromTrial: false,
    period: '',
    sections: [cartSectionInitialType],
    totals: totalsInitialType,
    typeOfSet: '',
    __typename: 'cart',
};
