/* eslint-disable camelcase */
import React, { useContext } from 'react';
import { useFragment } from '@apollo/client';
import classNames from 'classnames';
import { LocationContext } from 'app/containers/LocationProvider';
import { ADDRESS_SUBDIVISION_FRAGMENT } from 'app/graphql/address/subdivision.fragment.graphql';

import UI from 'app/components/ui';

import CityIcon from './cityIcon.componet.svg';
import './tag.scss';
import { useCitySelectMenuDialog } from './hooks/useCitySelectMenuDialog';


function HeaderCityTag() {
    const { getCityTitile } = useContext(LocationContext);
    const { pushCitySelectDialog } = useCitySelectMenuDialog();

    const sessionAddressData = useFragment({
        fragment: ADDRESS_SUBDIVISION_FRAGMENT,
        from: {
            __ref: 'address:session_address',
        },
    });

    const {
        title,
    } = getCityTitile({ deliveryAddress: { address: { ...sessionAddressData } } },
    );

    const togleCitySelect = () => {
        pushCitySelectDialog();
    };

    const rootClasses = classNames({
        tag__root: true,
        // disabled,
        primary: true,
        'city-select-tag': true,
    });
    const borderClasses = classNames({
        tag__boundary: true,
        // disabled,
        primary: true,
    });
    const labelClasses = classNames({
        tag__label: true,
        'delivery-date-tag': true,
        // capitalized: isFilterPeriodSelected,
        // disabled,
        primary: true,
    });

    return (
        <button
            styleName={rootClasses}
            data-tag-id="session_address"
            onClick={togleCitySelect}
            type="button"
        >
            <div styleName={borderClasses} />
            <div styleName="tag__label-container delivery-date-tag">
                <div styleName="city-tag__icon">
                    <CityIcon />
                </div>

                <p styleName={labelClasses}>
                    <UI.TextLine>{title}</UI.TextLine>
                </p>

                <div styleName="tag__arrow-container">
                    <UI.Arrow />
                </div>
            </div>
        </button>
    );
}

export default React.memo(HeaderCityTag);
