import React from 'react';
import cn from 'classnames';
import './partnerLogoShield.scss';
import PropTypes from 'prop-types';
import PartnerLogo from './tefalLogo.img.svg';


/*
    3471 - Курица Пармиджана
    3475 - Паста Неаполитано
    3483 - Картофельный гратен
    3486 - Медальоны из индейки

*/

// TODO: rename
export const TEFAL_DISHES_ID = [
    // '3475', '3483', '3471', '3486'
];

const UIParterLogoShield = (props) => {
    const { type } = props;

    const shieldRoot = cn({
        'parter-shield-root': true,
        [type]: true,
    });

    const shieldImage = cn({
        'parter-logo-img': true,
        [type]: true,
    });

    return (
        <div styleName={shieldRoot}>
            <div styleName="parter-logo-root">
                <img
                    src={PartnerLogo}
                    alt="parter logo"
                    styleName={shieldImage}
                />
            </div>
        </div>
    );
};

UIParterLogoShield.propTypes = {
    type: PropTypes.string.isRequired,
};

export default UIParterLogoShield;
