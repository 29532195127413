export const mapAllDishesTagsInBasket = (sections) => {
    const dishesTagsCollection = sections
        ?.flatMap((section) => section.items)
        .flatMap((item) => item.dish)
        .reduce((acc, dish) => {
            acc[dish.id] = dish.tags;
            return acc;
        }, {}) || [];
    return dishesTagsCollection;
};
