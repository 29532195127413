/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import classNames from 'classnames';
import './partnersComment.scss';


const preparComment = (comment) => {
    if (!comment || (typeof comment !== 'string')) {
        return null;
    }
    return comment
        .replace(/&lt;/gi, '<')
        .replace(/&gt;/gi, '>')
        .replace(/&amp;/gi, '&')
        .replace(/\\/gi, '');
};



const PartnersComment = (props) => {
    const { comment, styleModifier } = props;

    const commentContainerClasses = classNames({
        'partners-comment-root-wrapper': true,
        [styleModifier]: true,
    });

    const preparedComment = useMemo(() => preparComment(comment), [comment]);
    return (
        <span
            styleName={commentContainerClasses}
            dangerouslySetInnerHTML={{ __html: preparedComment }}
        />
    );
};

export default React.memo(PartnersComment);

