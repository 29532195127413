import LINKS from 'app/const/links';

// const {
//     TRIAL_FIRST_ORDER_SET,
//     EVERYDAY_SET:
//     PREMIUM_SET,
//     // BOX_PAGE,
//     NEW_YEAR_PAGE,
//     CHRISTMAS_PAGE,
//     // ROMANTIC_PAGE,
// } = LINKS;

type TSetTypeVar = '' | 'trial' | 'tomorrow' | 'green' | 'our_set';

export type TCardKey = 'TRIAL_FIRST_ORDER_SET' | 'EVERYDAY_SET'
    | 'OUR_SET' | 'PREMIUM_SET_GREEN_VERSION' | 'HALLOWEEN_SET'

type TTrackId = 'romantic' | 'trial' | 'weekly' | 'green' | 'our_set'

type TUDDField = 'eeAvailableDates' | 'trialAvailableDates' | 'everydayAvailableDates';

export interface ISetsItem {
    setTypeVar: TSetTypeVar,
    cardKey: TCardKey,
    trackId: TTrackId,
    udd: TUDDField,
    href: string
}

const TRIAL_FIRST_ORDER_SET: ISetsItem = {
    setTypeVar: 'trial',
    cardKey: 'TRIAL_FIRST_ORDER_SET',
    trackId: 'trial',
    udd: 'trialAvailableDates',
    href: LINKS.TRIAL_FIRST_ORDER_SET.hrefBasketMobileAB,
};

const EVERYDAY_SET: ISetsItem = {
    setTypeVar: 'tomorrow',
    cardKey: 'EVERYDAY_SET',
    trackId: 'weekly',
    udd: 'everydayAvailableDates',
    href: LINKS.EVERYDAY_SET.href,
};

const PREMIUM_SET_GREEN_VERSION: ISetsItem = {
    setTypeVar: 'green',
    cardKey: 'PREMIUM_SET_GREEN_VERSION',
    trackId: 'green',
    udd: 'eeAvailableDates',
    href: LINKS.PREMIUM_SET_GREEN_VERSION.href,
};

const OUR_SET: ISetsItem = {
    setTypeVar: 'our_set',
    cardKey: 'OUR_SET',
    trackId: 'our_set',
    udd: 'eeAvailableDates',
    href: '/basket/?cm=10&payment=recurrent',
};

// все карточки в слайдере
export const getPossibleLinksInSlider = () => [
    {
        id: TRIAL_FIRST_ORDER_SET.cardKey,
        data: {},
    },
    {
        id: EVERYDAY_SET.cardKey,
        data: {},
    },
    {
        id: OUR_SET.cardKey,
        data: {},
    },
    {
        id: PREMIUM_SET_GREEN_VERSION.cardKey,
        data: {},
    },
];


export const cardLinkDataMap = new Map([
    ['TRIAL_FIRST_ORDER_SET', TRIAL_FIRST_ORDER_SET],
    ['EVERYDAY_SET', EVERYDAY_SET],
    ['PREMIUM_SET_GREEN_VERSION', PREMIUM_SET_GREEN_VERSION],
    ['OUR_SET', OUR_SET],
]);
