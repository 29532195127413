/* eslint-disable max-len */
import endOfWeek from 'date-fns/end_of_week';

const PAGE = 'kids';
export const SCHOOL_TAG = '2172';

const idFromData = (dishesData) => Object.values(dishesData)
    .sort((data1, data2) => data1.orderNumber - data2.orderNumber)
    .map((data) => data.id);


export const mainDishesData = {
    3214: {
        id: '3214',
        orderNumber: 1,
        category: 'main',
        description: 'Кулинарный конструктор: вместе с детьми соберите башню из пасты, бешамеля и болоньезе.',
        metaLabel: {
            dishMetaIcon: 'coloring',
        },
    },
    4134: {
        id: '4134',
        orderNumber: 2,
        category: 'main',
        description: 'Собирается как пазл! Дети могут сами выложить начинку на готовую основу из семолины.',
        metaLabel: {
            dishMetaIcon: 'coloring',
        },
    },
    4132: {
        id: '4132',
        orderNumber: 3,
        category: 'main',
        description: 'Приготовьте нежные котлетки, а пюре доверьте детям – оно почти готово: только прогреть с сыром или сливками.',
        metaLabel: {
            dishMetaIcon: 'sticker',
        },
    },
    4130: {
        id: '4130',
        orderNumber: 4,
        category: 'main',
        description: 'Паста ручной работы – интересная на ощупь! Детям – простой томатный соус, взрослым – еще чоризо и базилик.',
        metaLabel: {
            dishMetaIcon: 'sticker',
        },
    },
    4133: {
        id: '4133',
        orderNumber: 5,
        category: 'main',
        description: 'Мы раскрыли секретную формулу Губки Боба! Теперь можно собрать те самые бургеры вместе с детьми.',
        metaLabel: {
            dishMetaIcon: 'sticker',
        },
    },
    3093: {
        id: '3093',
        orderNumber: 6,
        category: 'main',
        description: 'Вместе печь и украшать капкейки = ароматное семейное воспоминание для ребенка + чистая кухня для родителей.',
        metaLabel: {
            dishMetaIcon: 'coloring',
        },
    },
};

const deloVkusaDishesData = {
    4135: {
        id: '4135',
        orderNumber: 8,
        category: 'deloVkusa',
    },
    4145: {
        id: '4145',
        orderNumber: 9,
        category: 'deloVkusa',
    },
    2844: {
        id: '2844',
        orderNumber: 10,
        category: 'deloVkusa',
    },
    3887: {
        id: '3887',
        orderNumber: 11,
        category: 'deloVkusa',
    },
    4131: {
        id: '4131',
        orderNumber: 12,
        category: 'deloVkusa',
    },
    3992: {
        id: '3992',
        orderNumber: 13,
        category: 'deloVkusa',
    },
    3960: {
        id: '3960',
        orderNumber: 14,
        category: 'deloVkusa',
    },
    3295: {
        id: '3295',
        orderNumber: 15,
        category: 'deloVkusa',
    },
    3280: {
        id: '3280',
        orderNumber: 16,
        category: 'deloVkusa',
    },
    4151: {
        id: '4151',
        orderNumber: 17,
        category: 'deloVkusa',
    },
    4157: {
        id: '4157',
        orderNumber: 18,
        category: 'deloVkusa',
    },
    4174: {
        id: '4174',
        orderNumber: 19,
        category: 'deloVkusa',
    },
    4021: {
        id: '4021',
        orderNumber: 20,
        category: 'deloVkusa',
    },
    3786: {
        id: '3786',
        orderNumber: 21,
        category: 'deloVkusa',
    },
    3532: {
        id: '3532',
        orderNumber: 22,
        category: 'deloVkusa',
    },
    2853: {
        id: '2853',
        orderNumber: 23,
        category: 'deloVkusa',
    },
    991: {
        id: '991',
        orderNumber: 24,
        category: 'deloVkusa',
    },
    2891: {
        id: '2891',
        orderNumber: 25,
        category: 'deloVkusa',
    },
    1078: {
        id: '1078',
        orderNumber: 26,
        category: 'deloVkusa',
    },
    4249: {
        id: '4249',
        orderNumber: 27,
        category: 'deloVkusa',
    },
    538: {
        id: '538',
        orderNumber: 27,
        category: 'deloVkusa',
    },
};


const dishesData = {
    ...mainDishesData,
    ...deloVkusaDishesData,
};


export const mainDishes = idFromData(mainDishesData);
const deloVkusaDishes = idFromData(deloVkusaDishesData);


export const allDishes = [
    ...mainDishes,
    ...deloVkusaDishes,
];


export default {
    pageId: PAGE,
    pathSegment: PAGE,
    pathSegmentEn: PAGE,

    title: 'Заказать детское меню с доставкой на дом от Elementaree',
    description: 'Доставка готовой детской еды на день. Попробуйте рецепты из полезных и питательных продуктов от Elementaree!',

    ogImage: '',
    additionalContent: '',
    onInit: () => { },
    appConfig: {
        pageId: PAGE,
        city: null,
        period: null,
        firstPeriodStart: '2023-08-28',
        filterByIds: allDishes,
        filterTag: SCHOOL_TAG,

        areAvaliableDishesPersistentForPeriods: false,

        joinDishTitlesinMobile: false,
        dishesData,
        dishCategories: {
            main: {
                id: 'main',
                title: 'Детское меню',
                titleEn: 'Детское меню',
                detailsTitle: 'Детское меню',
                description: [],
                enabledDishes: mainDishes,
            },
            deloVkusa: {
                id: 'deloVkusa',
                title: 'Дело вкуса',
                titleEn: 'Дело вкуса',
                detailsTitle: 'Дело вкуса',
                description: [
                    'Не все дети едят все продукты. В этом разделе семейных рецептов на каждого искушенного ценителя найдется свое любимое блюдо.',
                ],
                enabledDishes: deloVkusaDishes,
            },
            youngChef: {
                id: 'youngChef',
                title: 'Юный шеф',
                titleEn: 'Юный шеф',
                detailsTitle: 'Юный шеф',
                description: [
                    'Наборы, из которых подростки могут самостоятельно приготовить интересные блюда и сохранить порядок на кухне',
                ],
                enabledDishesByTag: SCHOOL_TAG,
            },
        },
        canChangePortionsCount: true,
        areDetailsEnabled: true,

        enabledBasketDishIds: [],
        autoFillingBasket: false,

        isCheckoutEnabled: true,
        isLinkToMenuEnabled: true,

        prepareDates: (dates, periodsConfig = null, period = null, basketDishesIds) => {
            const maxDate = endOfWeek(new Date(period), { weekStartsOn: 1 });

            return dates.filter((d) => {
                const date = new Date(d.date);
                return date <= maxDate;
            });
        },
        prepareIntervals: null,

        ageGate: false,
        ageGateImage: null,
    },
};
