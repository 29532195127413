import React from 'react';
import { Transition } from 'react-transition-group';
import cn from 'classnames';
// @ts-ignore
import ScrollLock from 'react-scrolllock';

import css from './nexOverlay.module.scss';

export const NextOverlay = (props: any) => {
    const {
        children,
        isOpen = false,
        handleClose = () => { },
    } = props;

    return (
        <Transition
            in={isOpen}
            timeout={500}
            appear
        >
            {(transitionState) => {
                const isEntered = transitionState === 'entered';

                return (

                    <div className={cn(css.nextOverlayRoot, transitionState)}>
                        {/* Слой для блюра */}
                        <div
                            className={cn(css.nextOverlayBlurLayer, transitionState)}
                        />
                        {/* Слой для onClick, появляется с запозданием */}

                        {/* Слой с контентом */}
                        <div
                            className={cn(css.nextOverlayContentLayer, transitionState)}
                        >
                            {isEntered && (
                                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                                <button
                                    className={cn(css.nextOverlayCloseLayer, transitionState)}
                                    onClick={handleClose}
                                    type="button"
                                />
                            )}
                            {children}
                        </div>
                        {/* Блок скролла */}
                        {isEntered && (
                            <ScrollLock />)
                        }
                    </div>
                );
            }}

        </Transition>
    );
};

