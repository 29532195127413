/* eslint-disable import/order */
import React, {
    useCallback, useContext, useMemo, useState,
} from 'react';
import { MobilePeriodSelectContainer } from 'app/components/Select';
import { LocaleContext } from 'app/containers/LocaleProvider';
import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import { getDateData } from 'app/utils/date';
// simport { useSelectedFilters } from 'app/connect/connectToSelectedFilters';
import { selectedPeriodVar } from 'app/apollo/reaction';
import { FiltersContext, clearAfterSwitchDialogTexts } from 'app/containers/contexts/filters.context/filters.context';
import { OverlayContextDispatch } from 'app/containers/contexts/overlay.context';
import { ONE_DAYS_IN_UTC_FORMAT } from 'app/containers/contexts/menuDates.consts';
import { AbstractSelectTag } from './SelectTag';
import BASKET_QUERY from 'app/graphql/network/basket/basketQuery';

/* TYPES */
import { IFiltersContext } from 'app/containers/contexts/filters.context/filtersContext.type';
import { useQuery } from '@apollo/client';

const formatPeriodToTagTitle = (start: string, end: string, locale: string) => {
    const firstDateMeta = getDateData(start, locale);
    const secondDateMeta = getDateData(end, locale);

    const monthShortNameForDate = firstDateMeta?.monthShortNameForDate === secondDateMeta?.monthShortNameForDate
        ? ''
        : firstDateMeta?.monthShortNameForDate;

    const fisrtDateString = `${firstDateMeta?.rawData?.date} ${monthShortNameForDate}`;
    const secondDateString = `${secondDateMeta?.rawData?.date} ${secondDateMeta?.monthShortNameForDate}`;
    return `${fisrtDateString} — ${secondDateString}`;
};

const generateDateDataFromDiff = (date: string, weekDayName: string) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    const currentDate = d.getTime() / 1000;

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayD = today.getTime() / 1000;


    const isTomorrow = `${(currentDate - todayD)}` === ONE_DAYS_IN_UTC_FORMAT;

    return isTomorrow ? 'завтра' : weekDayName;
};

export function DateSelectTag() {
    const [isDateSelectOpen, setIsDateSelectOpen] = useState(false);
    const { pushDialogOverlay, closeLastOverlays } = useContext(OverlayContextDispatch);

    // Берем заначение языка
    const { locale } = useContext(LocaleContext);
    const { filterQuery: { menuFilter: { periods } } } = useContext<IFiltersContext>(FiltersContext);
    // Берем значение из проклятой задачи
    const {
        state: {
            selectedDate,
            filterPeriod,
        },
    } = useContext(menuDatesState);

    const selectedPeriod = selectedPeriodVar();

    const { data: cartData, refetch } = useQuery(BASKET_QUERY, {
        variables: {
            period: selectedPeriod,
        },
    });

    const period = periods.find((p: any) => p.start === selectedPeriod);

    const {
        start: selectedPeriodStart,
        end: selectedPeriodEndRaw,
        // tags,
    } = period;

    /* SELECTORS | ИНТЕРПРАЙЗ ВЫЧЕСЛЕНИЯ */
    // Функция отдает разные данные по дате
    // TODO: когда нибудь ее надо переписать на TS, этой функции это очень нужно
    // TODO: этого не достаточно чтобы отобразить дату, нужно проверить еще и период
    const dateMeta = useMemo(
        () => getDateData(selectedDate, locale),
        [selectedDate, locale],
    );

    const { weekDayName } = dateMeta;

    const text = useMemo(() => {
        // Тут мы проверяем в одинаковых ли переодах код из проклятой задачи и период из url`а
        // Нужно для того чтобы отобразить либо интервал дат либо день
        const isFilterPeriodSelected = filterPeriod === selectedPeriod;
        return isFilterPeriodSelected
            ? generateDateDataFromDiff(dateMeta.rawData.dateValue, weekDayName)
            : formatPeriodToTagTitle(selectedPeriodStart, selectedPeriodEndRaw, locale);
    }, [
        weekDayName,
        filterPeriod,
        selectedPeriod,
        selectedPeriodStart,
        selectedPeriodEndRaw,
        locale,
        dateMeta.rawData.dateValue,
    ]);

    const handleSelectPeriod = useCallback(
        (nextSelectedPeriod: any) => {
            const targetPeriod = periods.find((p: any) => p.start === nextSelectedPeriod);
            const clearAfterSwitchFrom = targetPeriod?.clearAfterSwitchFrom || false;
            const isBasketEmpty = !cartData?.cart?.sections.length;

            // Выдернул из MobileFilters
            if (clearAfterSwitchFrom && !isBasketEmpty) {
                const dialogData = {
                    // @ts-ignore
                    ...clearAfterSwitchDialogTexts[locale],
                    onConfirm: async () => {
                        selectedPeriodVar(nextSelectedPeriod);
                        refetch({ clear: true });
                        closeLastOverlays();
                    },
                    notifyOnly: false,
                    oneRowButtons: true,
                };
                // @ts-expect-error
                pushDialogOverlay('clear_basket_dialog_header_tags', dialogData);
                return;
            }
            selectedPeriodVar(nextSelectedPeriod);
        },
        [
            periods, locale, cartData,
            refetch, closeLastOverlays, pushDialogOverlay,
        ],
    );

    return (
        <>
            <AbstractSelectTag
                placeHolder="доставка"
                text={text}
                onClickHandler={() => setIsDateSelectOpen(!isDateSelectOpen)}
            />
            <MobilePeriodSelectContainer
                isSelectOpened={isDateSelectOpen}
                onClickPeriod={handleSelectPeriod}
                onClickClose={() => setIsDateSelectOpen(false)}
            />
        </>
    );
}
