import React from 'react';

import css from './visualShield.module.scss';
import kids from './kids.png';
import halloween from './halloween.png';


export type TShieldVisualName = 'kids' | 'halloween'

interface IVisualShieldProps {
    name: TShieldVisualName,
}

const defaultPath = '';

const shieldMap = new Map<TShieldVisualName, string>([
    ['kids', kids],
    ['halloween', halloween],

]);

export const VisualShield = (props: IVisualShieldProps) => {
    const { name } = props;

    const path = shieldMap.get(name) || defaultPath;

    if (!path) {
        return null;
    }

    return (
        <div
            className={css.visualShieldRoot}
        >
            <img
                className={css.visualShieldImg}
                src={path}
                alt={name}
            />
        </div>
    );
};
