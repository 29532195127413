const localeTexts = {
    mobileNav: {
        workHours: 'Ежедневно с 6:00 до 23:00',
        myDeliveries: 'Мои доставки',
        myBonus: 'Бонусы',
        getBonus: 'Бонусы',
        login: 'Войти',
        logout: 'Выйти',
        menuDishes: 'Все блюда',
        newYearDishes: 'Новогоднее меню',
        rest: 'На 8 марта',
        trialPremium: 'Пробный премиум',
        everyday: 'Набор от шефа',
        dropdownOffer: 'Новый заказ',
        dropdownInfo: 'Как мы работаем',
        quality: 'Система качества',
        delivery: 'Доставка и оплата',
        recurrent: 'Что такое подписка',
        unpacking: 'Что в наборе',
        foreignLang: 'English version',
        about: 'О компании',
        dichLink: 'дичь линк',
        hp: 'Меню из Хогвартса',
        'ny-sweets': 'Новогоднее настроение',


        premium: 'Премиум набор',
        nz: 'Про запас',
        box: 'Коробка 20 блюд',
        learn: 'Мастер-класс',
        season: 'Сезонное',
        premiumSet: 'Премиум набор',
        health: 'Для здоровья',
        dessertbar: 'Десерт-бар',

        // NEW
        ny: 'Новогоднее меню',
        lite: 'Любителям легкого',
        soups: 'Супы за 5-15 минут',
        vegan: 'Вег-меню',
        sweat: 'Печем вместе',
        breakfast: 'Богемные завтраки',

        trial: 'Пробный сет',
        tomorrow: 'Сет недели',
        easterbox: 'Пасхальный бокс',
        allSets: 'Подборки блюд',
        gift: 'Подарочные сертификаты',
        christmas: 'Рождество',
        profitable: 'Выгодный',
        topFive: 'Топ 5 лучших блюд',
        green: 'Green. Лёгкие ингредиенты',
    },

    region: {
        mobileSelectTitleMsk: 'Мск',
        mobileSelectTitleSpb: 'СПб',
        mobileSelectTitleNiz: 'НН',

        mobileHeading: 'Выберите регион доставки,',
        mobileSubheading_1: 'чтобы увидеть актуальное',
        mobileSubheading_2: 'меню недели',
        moscowRegion: 'Москва и МО',
        spbRegion: 'Санкт-Пeтeрбург и ЛО',
        nizRegion: 'Нижний Новгород и НО',

        moscow: 'Москва',
        spb: 'Санкт-Пeтeрбург',
        niz: 'Нижний Новгород',
    },
};

export default localeTexts;
