import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    VERSION_LEGACY,
    VERSION_UIKIT,
    VERSION_UIKIT_RESPONSIVE,
} from '../../checkbox-consts';

import './radio-mark.scss';


export default function RadioMark({
    checked,
    version,
}) {
    const markClasses = classNames({
        radiomark: true,
        checked,
        [version]: true,
    });

    return (
        <span styleName={markClasses} />
    );
}

RadioMark.propTypes = {
    version: PropTypes.oneOf([VERSION_LEGACY, VERSION_UIKIT, VERSION_UIKIT_RESPONSIVE]).isRequired,
    checked: PropTypes.bool.isRequired,
};
