/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
/* INITIAL */

const abstractInputNode = {
    focus: () => {},
    setHeight: () => {},
    _ref: {
        offsetHeight: 0,
    },
    style: {
        heigh: '',
        transition: '',
    },
    value: '',
    setSelectionRange: () => {},
};

const abstractRootNode = {
    current: {
        childNodes: [abstractInputNode, abstractInputNode],
        value: '',
    },
};

// TODO: Радикальные меры вычесления высоты инпута
// @ts-ignore
const getInitialHeight = (inputRef) => {
    let result = 0;
    // @ts-ignore
    const mockNode = document.createElement('textarea', { id: 'fake--input' });
    mockNode.style.width = `${inputRef.offsetWidth}px`;
    mockNode.classList.add('text--text');
    mockNode.innerHTML = inputRef.value;
    mockNode.setAttribute('rows', '1');
    document.body.appendChild(mockNode);
    result = mockNode.scrollHeight;
    mockNode.remove();
    return result;
};

/* HOOKS */

// class INPUT {
//     constructor(inputRef) {
//         this.m = 'hello input';
//         this._inputRef = inputRef;
//     }

//     setStyles(height, action) {
//         const transitionByAction = action === 'open' ? '' : 'height 0.2s';
//         this._inputRef.style.height = `${height}px`;
//         this._inputRef.style.transition = transitionByAction;
//         return this;
//     }
// }

export const useInputNavigation = () => {
    /* ROOT STATE */
    const [rootNode, setRootNode] = useState(abstractRootNode);
    const { current: { childNodes } } = rootNode;
    const [, inputRef] = childNodes;

    /* INPUT STATE */
    const [height, setHeight] = useState(17);
    const [maxRow, setMaxRow] = useState(undefined);
    const [collapsedState, setCollapsedState] = useState('default');

    const currentInput = {
        _ref: inputRef,
        focus: () => {
            const end = inputRef.value.length;
            // @ts-ignore
            inputRef.setSelectionRange(end, end);
            inputRef.focus();
        },

        maxRow,
        collapsedState,

        // @ts-ignore
        setHeight: (action, inputValue) => {
            // @ts-ignore
            const clearHeight = inputRef.offsetHeight;
            const amountOfRows = clearHeight / 17;
            const currentHeigh = amountOfRows >= 4 ? 4 * 17 : amountOfRows * 17;

            if (action === 'close') {
                inputRef.style.transition = 'height 0.2s';
                const nextHeight = inputRef.value ? currentHeigh : 1;
                const nextClearHeight = inputRef.value ? clearHeight : 1;
                setMaxRow(undefined);
                setHeight(nextClearHeight);
                // @ts-ignore
                inputRef.style.height = `${nextHeight}px`;
                if (amountOfRows > 4) {
                    setCollapsedState('collapsed');
                }
            }

            if (action === 'open') {
                setMaxRow(undefined);
                inputRef.style.transition = '';
                const h = height === 1 ? 17 : height;
                // @ts-ignore
                inputRef.style.height = `${h}px`;
                setCollapsedState('default');
            }

            if (action === 'init') {
                const supposedHeight = Math.floor(getInitialHeight(inputRef) - 1);
                const initialAmountOfRows = supposedHeight / 17;
                const initMaxRow = initialAmountOfRows >= 4 ? 4 : initialAmountOfRows;
                const initialClearHeight = initialAmountOfRows * 17 || 17;
                const initialCurrentHeigh = initialAmountOfRows >= 4 ? 4 * 17 : initialAmountOfRows * 17;
                const initialHeight = inputValue ? initialCurrentHeigh : 1;
                setHeight(initialClearHeight);
                // @ts-ignore
                setMaxRow(inputRef.value ? initMaxRow : undefined);

                // @ts-ignore
                inputRef.style.height = `${initialHeight}px`;
                inputRef.style.transition = 'height 0.2s';
                if (initialAmountOfRows > 4) {
                    setCollapsedState('collapsed');
                }
            }
        },
    };

    useEffect(() => {
        if (rootNode !== abstractRootNode) {
            currentInput.setHeight('init', inputRef.value);
        }
    }, [rootNode]);

    return [currentInput, setRootNode];
};
