import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LocaleContext } from 'app/containers/LocaleProvider';
import {
    locales,
    PORTION_UM_ALIAS_TYPE,
} from 'app/const/umAlias';

import pluralize from 'app/utils/pluralize';

import './portion-desktop.scss';


const propTypes = {
    portionAlias: PropTypes.arrayOf(PropTypes.string),
    weight: PropTypes.number.isRequired,
    portion: PropTypes.number.isRequired,
    size: PropTypes.string,
    colors: PropTypes.string,
    isInteractive: PropTypes.bool,
    isActive: PropTypes.bool,
    basket: PropTypes.bool,
    placement: PropTypes.oneOf(['dish-details', null]),
};

const Portion = ({
    portionAlias = null,
    size = null,
    placement = null,
    colors = 'normal',
    isInteractive = false,
    isActive = false,
    basket = false,


    weight,
    portion,
}) => {
    const { locale } = useContext(LocaleContext);

    const portionsClasses = classNames({
        portions: true,
        small: size === 'small',
        large: size === 'large',
        light: colors === 'light',
        interactive: isInteractive,
        active: isActive,
        basket,
        'placement-details': placement === 'dish-details',
    });

    const isWeightVisible = size !== 'small';

    const [UM_ALIAS_TYPE] = portionAlias || [PORTION_UM_ALIAS_TYPE];

    const portionAliases = locales[locale][UM_ALIAS_TYPE] || locales[locale][PORTION_UM_ALIAS_TYPE];
    const portionText = pluralize(portion, portionAliases);
    const { grammChar } = locales[locale];

    return (
        <div styleName={portionsClasses}>
            <span styleName="portions__count" data-test-id="portions__count">{portion}</span>
            <div styleName="portions__units-container">
                <span styleName="portions__units">{portionText}</span>
                {isWeightVisible
                    && (
                        <span styleName="portions__size">
                            <span>{`${weight * portion}`}</span>
                            <span>{grammChar}</span>
                        </span>
                    )
                }
            </div>
        </div>
    );
};

Portion.propTypes = propTypes;

export default Portion;
