export const locales = {
    ru: {
        priceDescription: 'Цена за 1',
        priceDescriptionPortion: 'порцию',
        priceDescriptionWeight: 'г',
        soldOut: 'Уже раскупили',
    },
    en: {
        priceDescription: 'Price for 1',
        priceDescriptionPortion: 'serving',
        priceDescriptionWeight: 'g',
        soldOut: 'Sold out',
    },
};
