import React from 'react';

import cn from 'classnames';

import { isPreassembledBasket } from 'app/views/Basket/basketTypeMiddleware/checkers';
import MobileCartHeader from 'app/components/MobileCartHeader';

import css from './basket.sekeleton.module.scss';


const BasketTitleSkeleton = () => <div className={cn(css.basketTitleSkeleton, css.skeletonAnimation)} />;

const BasketSkeletonCard = () => (
    <div className={cn(css.basketSkeletonCardRoot)}>
        <div className={cn(css.basketSkeletonCardLeft, css.skeletonAnimation)} />
        <div className={cn(css.basketSkeletonCardRight)}>
            <div className={cn(css.basketSkeletonCardRowOne, css.skeletonAnimation)} />
            <div className={cn(css.basketSkeletonCardRowTwo, css.skeletonAnimation)} />
            <div className={cn(css.basketSkeletonCardRowThree, css.skeletonAnimation)} />
        </div>
    </div>
);

const BasketSkeletonDishListButton = () => (
    <div className={cn(css.basketSkeletonDishListButton, css.skeletonAnimation)} />
);

const BasketSkeletonTagsArea = () => (
    <div className={css.basketSkeletonTagsAreaRoot}>
        <div className={css.basketSkeletonTagsAreaContent}>
            <div className={cn(css.basketSkeletonTagsAreaContentOne, css.skeletonAnimation)} />
            <div className={cn(css.basketSkeletonTagsAreaContentTwo, css.skeletonAnimation)} />
            <div className={cn(css.basketSkeletonTagsAreaContentThree, css.skeletonAnimation)} />
        </div>
        <div className={css.basketSkeletonTagsAreaList}>
            <div className={cn(css.basketSkeletonTagsAreaListItem, css.skeletonAnimation)} />
            <div className={cn(css.basketSkeletonTagsAreaListItem, css.skeletonAnimation)} />
            <div className={cn(css.basketSkeletonTagsAreaListItem, css.skeletonAnimation)} />
            <div className={cn(css.basketSkeletonTagsAreaListItem, css.skeletonAnimation)} />
            <div className={cn(css.basketSkeletonTagsAreaListItem, css.skeletonAnimation)} />
            <div className={cn(css.basketSkeletonTagsAreaListItem, css.skeletonAnimation)} />
            <div className={cn(css.basketSkeletonTagsAreaListItem, css.skeletonAnimation)} />
            <div className={cn(css.basketSkeletonTagsAreaListItem, css.skeletonAnimation)} />
        </div>
        <div className={cn(css.basketSkeletonTagsAreaListItemOther, css.skeletonAnimation)} />
    </div>
);

const BasketSkeletonSelectionsArea = () => (
    <div className={cn(css.basketSkeletonSelectionsRoot)}>
        <div className={cn(css.basketSkeletonSelectionsItem, css.skeletonAnimation)} />
        <div className={cn(css.basketSkeletonSelectionsItem, css.skeletonAnimation)} />
        <div className={cn(css.basketSkeletonSelectionsItem, css.skeletonAnimation)} />
    </div>
);

const BasketSkeletonSelectionsAreaUnpreassembled = () => (
    <div className={cn(css.basketSkeletonSelectionsRoot, css.unpreassembled)}>
        <div className={cn(css.basketSkeletonSelectionsItem, css.unpreassembled)} />
        <div className={cn(css.basketSkeletonSelectionsItem, css.unpreassembled)} />
        <div className={cn(css.basketSkeletonSelectionsItem, css.unpreassembled)} />
    </div>
);

const BasketSkeletonCheckArea = () => (
    <div className={css.basketSkeletonCheckAreaRoot}>
        <div className={css.basketSkeletonCheckCard}>
            <div className={cn(css.basketSkeletonCheckTitle, css.skeletonAnimation)} />
            <div className={css.basketSkeletonCheckRows}>
                <div className={css.basketSkeletonCheckRow}>
                    <div className={cn(css.basketSkeletonCheckRowLeft, css.skeletonAnimation)} />
                    <div className={cn(css.basketSkeletonCheckRowRight, css.skeletonAnimation)} />
                </div>
                <div className={css.basketSkeletonCheckRow}>
                    <div className={cn(css.basketSkeletonCheckRowLeft, css.skeletonAnimation)} />
                    <div className={cn(css.basketSkeletonCheckRowRight, css.skeletonAnimation)} />
                </div>
                <div className={css.basketSkeletonCheckRow}>
                    <div className={cn(css.basketSkeletonCheckRowLeft, css.skeletonAnimation)} />
                    <div className={cn(css.basketSkeletonCheckRowRight, css.skeletonAnimation)} />
                </div>
                <div className={css.basketSkeletonCheckRow}>
                    <div className={cn(css.basketSkeletonCheckRowLeft, css.skeletonAnimation)} />
                    <div className={cn(css.basketSkeletonCheckRowRight, css.skeletonAnimation)} />
                </div>
            </div>
            <div className={cn(css.basketSkeletonCheckButton, css.skeletonAnimation)} />
            <div className={cn(css.basketSkeletonCheckTotal, css.skeletonAnimation)} />
        </div>
    </div>
);

const BasketSkeletonCommentArea = () => (
    <div className={css.basketSkeletonCommentAreaRoot}>
        <div className={cn(css.basketSkeletonCommentRowOne, css.skeletonAnimation)} />
        <div className={cn(css.basketSkeletonCommentRowTwo, css.skeletonAnimation)} />
        <div className={cn(css.basketSkeletonCommentRowThree, css.skeletonAnimation)} />
    </div>
);


// BASKETS
const PreassembledBasketSkeleton = () => (
    <div className="">
        <BasketTitleSkeleton />
        <BasketSkeletonCard />
        <BasketSkeletonCard />
        <BasketSkeletonCard />
        <BasketSkeletonCard />
        <BasketSkeletonCard />
        <BasketSkeletonDishListButton />
        <BasketSkeletonTagsArea />
        <BasketSkeletonSelectionsArea />
        <BasketSkeletonCheckArea />
    </div>
);

const UnPreassembledBasketSkeleton = () => (
    <div className="">
        <BasketSkeletonCommentArea />
        <BasketTitleSkeleton />
        <BasketSkeletonCard />
        <BasketSkeletonCard />
        <BasketSkeletonCard />
        <BasketSkeletonCard />
        <BasketSkeletonCard />
        <BasketSkeletonSelectionsAreaUnpreassembled />
    </div>
);

export function BasketSkeletonPage() {
    return (
        <div className={css.basketSkeletonPageRoot}>
            <div className={css.basketSkeletonHeader}>
                <MobileCartHeader realm="skeleton" />
            </div>
            {isPreassembledBasket({ location: window.location })
                ? <PreassembledBasketSkeleton />
                : <UnPreassembledBasketSkeleton />}
        </div>
    );
}
