import { gql } from '@apollo/client';


const removeAccountMutation = gql`
    mutation removeAccount {
        removeAccount {
            success
        }
    }
`;

export default removeAccountMutation;
