export const locales = {
    ru: {
        heading: 'Ваш заказ',
        dishesPrice: 'Стоимость блюд',

        deliveryPrice: 'Доставка',
        deliveryMos: 'по Москве',
        deliveryMosObl: 'за МКАД',
        deliverySpb: 'по С-Петербургу',
        deliveryLen: 'за КАД',

        referral: 'Оплата бонусами',

        promocode: 'Промокод',
        promocodeError: 'Неверный промокод',
        promocodeDiscount: 'Скидка по промокоду',
        certificateExistError: 'Неверный сертификат',
        promocodeErrorSubscr: 'Не работает с подпиской',
        promocodeErrorSecond: window.innerWidth < 375 ? 'Не для повторных заказов' : 'Не работает для повторных заказов',
        certificateError: 'Этот сертификат уже использован',

        subscriptionSmall: 'По подписке',
        subscriptionBigPref: 'Скидка',
        subscriptionBigLink: 'по подписке',

        appliedDiscount: 'Скидки и бонусы',
        subscriptionDiscount: 'Скидка по подписке',

        total: 'ИТОГО',
    },
    en: {
        heading: 'Your order',
        dishesPrice: 'Dishes price',

        deliveryPrice: 'Delivery',
        deliveryMos: 'in Moscow',
        deliveryMosObl: 'outside MKAD',
        deliverySpb: 'in St.Petersburg',
        deliveryLen: 'outside KAD',

        referral: 'Bonus',

        promocode: 'Promo code',
        promocodeError: 'Incorrect promo code',
        promocodeDiscount: 'Promo code discount',
        certificateExistError: 'Incorrect certificate',
        promocodeErrorSubscr: 'Not compatible with subscription',
        promocodeErrorSecond: 'Not compatible with second order',
        certificateError: 'Этот сертификат уже использован',

        subscriptionSmall: 'Subscription',
        subscriptionBigPref: '',
        subscriptionBigLink: 'Subscription',

        appliedDiscount: 'Bonus',
        subscriptionDiscount: 'Subscription discount',

        total: 'TOTAL',
    },
};
