import React, {
    ReactNode, useState, useRef, useLayoutEffect,
    // MutableRefObject,
} from 'react';
import cn from 'classnames';

import XBigIcon from 'assets/svg/x_medium.component.svg';
import Button from 'app/components/ui/Button/ButtonV2';
import Paragraph from 'app/components/ui/Paragraph';
// import Heading from 'app/components/ui/Heading';
import { Mobile, Desktop } from 'app/components/Responsive';

import css from './DialogStaticMobile.module.scss';


export interface DialogProps {
    extraText?: ReactNode,
    confirmText?: ReactNode,
    rejectText?: ReactNode,
    confirmOneText?: ReactNode,
    confirmAllText?: ReactNode,

    notifyOnly?: boolean,
    rejectDisabled?: boolean,
    isConfirmLoading?: boolean,
    oneRowButtons?: boolean,
    buttonsReversed?: boolean,
    isProfileLayout?: boolean,
    isCloseConfirmAction?: boolean,
    extraButtonPosition?: 'last' | 'first',

    onConfirm: () => void,
    onReject?: () => void,
    rejectSpecificHandler?: () => void,
    onConfirmOne?: () => void,
    onConfirmAll?: () => void,
}


export function DialogStaticMobile({
    onConfirm,
    onConfirmOne = undefined,
    onConfirmAll = undefined,
    onReject = undefined,
    rejectSpecificHandler = undefined,
    isConfirmLoading = false,
    isCloseConfirmAction = false,

    extraText = '',
    confirmText = '',
    confirmOneText = '',
    confirmAllText = '',
    rejectText = '',

    notifyOnly = false,
    rejectDisabled = false,
    oneRowButtons = false,
    buttonsReversed = false,
    isProfileLayout = false,
    extraButtonPosition = 'last',
    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    children,
}: DialogProps) {
    const [hasScroll, setHasScroll] = useState(false);
    const [isFullyScrolled, setIsFullyScrolled] = useState<boolean>(false);

    const contentRef = useRef<any>(null);

    const handleReject = notifyOnly ? onConfirm : onReject;

    const mainButtonText = confirmAllText || confirmText;
    const mainButtonHandler = onConfirmAll || onConfirm;

    const extraButtonText = confirmOneText || rejectText;
    const extraButtonHandler = onConfirmOne || handleReject;

    const buttonsContainerClasses = cn({
        [css.dialogButtonsContainer]: true,
        [css.oneRow]: oneRowButtons,
        [css.twoRows]: !oneRowButtons,
        [css.reversed]: buttonsReversed,
        [css.dialogButtonContainerProfile]: isProfileLayout,
    });

    const dialogTitleContainerClasses = cn({
        [css.dialogTitleContainer]: true,
        [css.dialogTitleContainerProfile]: isProfileLayout,
    });

    useLayoutEffect(() => {
        const c = contentRef.current;

        if (!c) return;

        const hs = c.clientHeight < c.scrollHeight;
        setHasScroll(hs);
    }, []);

    useLayoutEffect(() => {
        const c = contentRef.current;
        if (!c) return undefined;
        const handleScroll = () => {
            const fullyScrolled = c.scrollTop + c.clientHeight >= c.scrollHeight;
            setIsFullyScrolled(fullyScrolled);
        };
        c.addEventListener('scroll', handleScroll);
        return (): void => {
            c.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={css.dialogRoot}>
            <Mobile>
                <div className={css.dialogHeader}>
                    <button
                        className={css.dialogCloseButton}
                        onClick={isCloseConfirmAction ? onConfirm : handleReject}
                        type="button"
                        disabled={rejectDisabled}
                    >
                        <XBigIcon />
                    </button>
                </div>
            </Mobile>

            <div className={dialogTitleContainerClasses}>
                <div
                    className={css.dialogChildrenWrapper}
                    ref={contentRef}
                >
                    {children}
                    <div
                        style={{
                            opacity: (!isFullyScrolled && hasScroll) ? 1 : 0,
                        }}
                        className={css.dateSelectListContainerShadow}
                    />
                </div>
            </div>

            <div className={buttonsContainerClasses}>
                {extraButtonPosition === 'first' && (
                    !notifyOnly && extraButtonText && (
                        // @ts-ignore
                        <Button colorType="secondary" onClick={extraButtonHandler}>
                            {extraButtonText}
                        </Button>
                    )
                )}
                {mainButtonHandler && (
                    // @ts-ignore
                    <Button
                        onClick={mainButtonHandler}
                        loading={isConfirmLoading}
                        className={css.dialogButton}
                    >
                        {mainButtonText}
                    </Button>
                )}
                {extraButtonPosition === 'last' && (
                    !notifyOnly && (
                        <Button
                            // @ts-ignore
                            colorType="secondary"
                            onClick={() => {
                                if (rejectSpecificHandler) {
                                    rejectSpecificHandler();
                                    return;
                                }
                                if (extraButtonHandler) {
                                    extraButtonHandler();
                                }
                            }}
                        >
                            {extraButtonText}
                        </Button>
                    )
                )}
            </div>

            {extraText && (
                <div className={css.dialogExtraTextContainer}>
                    <Paragraph level="4.2" className={css.dialogExtraText} inheritColors>
                        {extraText}
                    </Paragraph>
                </div>
            )}
        </div>
    );
}
