import React from 'react';
import PropTypes from 'prop-types';
import TextMask from 'react-text-mask';
import classNames from 'classnames';

import { formatPhone } from 'app/utils/phone';


import '../inputs.scss';


/*
    TODO: накопипастил из phone-processing.js
*/
// +7 999 888-12-34
export const DEFAULT_PHONE_MASK = ['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
/**
 * +7 799 988-81-234
 * используется для захвата всех цифр телефона в случаях,
 * когда пользователь в инпут телефона копирует полный номер телефона с кодом страны
 * (начальные '+7' или '8')
 */
export const LONG_PHONE_MASK = [...DEFAULT_PHONE_MASK, /\d/];
/*
    TODO: end of накопипастил из phone-processing.js
*/

const defaultErrorText = 'Введите номер телефона';

const PHONE_CODE_START_CHARS = '+7 (';


const TelephoneInput = ({
    // eslint-disable-next-line react/prop-types
    value = '',
    onChange,
    hasError = false,
    big = false,
    errorText = '',
    v303style = false,
    autoFocus = true,
    onBlur = () => { },

}) => {
    const inputCommonClasses = {
        'input input--telephone': true,
        'input--telephone-auth': big,
        'has-error': hasError,
        v303: v303style,
    };

    const inputShadowClasses = classNames({
        ...inputCommonClasses,
        'input-shadow': true,
    });

    const inputClasses = classNames({
        ...inputCommonClasses,
        'input-original': true,
    });

    const finalErrorText = errorText || defaultErrorText;

    const formattedValue = formatPhone(value ?? '');
    const inputValue = (value && formattedValue) || '';

    const phoneLength = 18; // +7(XXX)XXX-XX-XX
    const inputMask = inputValue.length < phoneLength ? LONG_PHONE_MASK : DEFAULT_PHONE_MASK;

    const phoneKeyboarHandler = (e) => {
        e.stopPropagation();
        /**
         * TODO: Сделать константы для клавишь
         * @description keyCode 13 === клавише 'return' на Mac и клавише 'Enter на Win и Linux'
        */
        if (e.keyCode === 13) {
            onBlur(e);
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label htmlFor="telephone" styleName="input-error-container">
            <TextMask
                type="tel"
                styleName={inputShadowClasses}
                id="telephone-shadow"
                name="telephone-shadow"
                tabIndex="0"
                mask={DEFAULT_PHONE_MASK}
                guide
                value={formattedValue}
            />
            <TextMask
                type="tel"
                styleName={inputClasses}
                id="telephone"
                name="telephone"
                mask={inputMask}
                guide={false}
                value={inputValue}
                onChange={onChange}
                onBlur={onBlur}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
                onKeyDown={phoneKeyboarHandler}
            />

            {hasError && <div styleName="input-error input-error--telephone">{finalErrorText}</div>}
        </label>
    );
};

export default TelephoneInput;

TelephoneInput.propTypes = {
    errorText: PropTypes.string,
    value: PropTypes.string,
    hasError: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    big: PropTypes.bool,
    v303style: PropTypes.bool,
    autoFocus: PropTypes.bool,
};
