import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { MobilePeriodSelectContainer } from 'app/components/Select';

import TextLine from '../TextLine';
import Arrow from '../Arrow';

import './tag.scss';


export default function HeaderDeliveryTag(props) {
    const {
        id,
        title,
        disabled,
        tagRef = null,
        onSelectPeriod,
        isSelectOpened,
        onClick,
        isFilterPeriodSelected,
    } = props;

    const rootClasses = classNames({
        tag__root: true,
        disabled,
        primary: true,
    });
    const borderClasses = classNames({
        tag__boundary: true,
        disabled,
        primary: true,
    });
    const labelClasses = classNames({
        tag__label: true,
        'delivery-date-tag': true,
        capitalized: isFilterPeriodSelected,
        disabled,
        primary: true,
    });

    return (
        <>
            <button
                styleName={rootClasses}
                ref={tagRef}
                data-tag-id={id}
                onClick={onClick}
                disabled={disabled}
                type="button"
            >
                <div styleName={borderClasses} />
                <div styleName="tag__label-container delivery-date-tag">
                    <p styleName={labelClasses}>
                        <TextLine>{title}</TextLine>
                    </p>

                    <div styleName="tag__arrow-container">
                        <Arrow />
                    </div>
                </div>
            </button>

            <MobilePeriodSelectContainer
                isSelectOpened={isSelectOpened}
                onClickPeriod={onSelectPeriod}
                onClickClose={onClick}
            />
        </>
    );
}

HeaderDeliveryTag.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    tagRef: PropTypes.shape({}),
    onSelectPeriod: PropTypes.func.isRequired,
    isSelectOpened: PropTypes.bool.isRequired,
    isFilterPeriodSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};
