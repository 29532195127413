import React from 'react';
import PropTypes from 'prop-types';

import Responsive from 'app/components/Responsive';

import { FeatureFiltersSoldOut } from '../FeaturesContent';
import OverlayTooltipMobile from '../OverlayTooltipMobile';
import ButtonCloseOverlayTooltip from '../ButtonCloseOverlayTooltip';
import Circle from '../Circle';

import './filters-overlay-sold-out-tooltip.scss';


export default function FiltersOverlaySoldOutTooltip(props) {
    const { onClickClose, transitionState } = props;
    return (
        <Responsive
            mobile={(
                <OverlayTooltipMobile transitionState={transitionState}>
                    <div>
                        <div styleName="overlay-tooltip__targeted-highlight">
                            <Circle position="right" size="big" transitionState={transitionState} />
                        </div>
                        <div styleName="filters-overlay-content">
                            <FeatureFiltersSoldOut size="big" />
                        </div>
                    </div>
                    <ButtonCloseOverlayTooltip onClick={onClickClose} />
                </OverlayTooltipMobile>
            )}
        />
    );
}


FiltersOverlaySoldOutTooltip.propTypes = {
    transitionState: PropTypes.string.isRequired,
    onClickClose: PropTypes.func.isRequired,
};
