import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';

/* NETWORK */
import billingMutation from 'app/graphql/network/billing';

/* CLIENT */
import { GET_SUBSCRIPTION_TYPE } from 'app/graphql/client/subscription';
import { GET_PAYMENT_METHOD } from 'app/graphql/client/payment';
import { GET_PROMOCODE } from 'app/graphql/client/promocode';

/* HOCS */
import { withAuthContext } from 'app/containers/AuthContainer';
import { withFiltersContext } from 'app/containers/contexts/filters.context/filters.context';
import { getBillingPaymentMethod } from 'app/containers/withBillingParams';
import { withBasketQuery } from './connectToBasket';
import { withSelectedFilters } from './connectToSelectedFilters';

/* CONNECT */
const connect = (Component) => compose(
    withSelectedFilters,
    graphql(GET_SUBSCRIPTION_TYPE, { name: 'subscriptionTypeQuery' }),
    graphql(GET_PAYMENT_METHOD, { name: 'paymentMethod' }),
    graphql(GET_PROMOCODE, { name: 'promocode' }),

    withFiltersContext,
    withBasketQuery,
    graphql(billingMutation, {
        name: 'updateBilling',
        options: (props) => {
            /*
                Добавляет дефолтные значения параметров, если они не переданы в мутацию явно
            */
            const {
                subscriptionTypeQuery: { subscriptionType },
                paymentMethod: { paymentMethod },
                selectedFilters: { selectedPeriod },
                promocode: { promocode },
            } = props;

            const { billingPaymentMethod } = getBillingPaymentMethod({
                statePaymentMethod: paymentMethod,
                subscriptionType,
            });

            return {
                variables: {
                    promocode,
                    period: selectedPeriod,
                    payment_method: billingPaymentMethod,
                },
            };
        },
    }),
    withAuthContext,
)(Component);

export default connect;
