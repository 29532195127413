/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    createContext,
    useEffect,
    useState,
    useCallback,
    useMemo,
} from 'react';
import {
    useLazyQuery,
    useMutation,
    useQuery,
} from '@apollo/client';
import {
    string, func, node, shape,
} from 'prop-types';
import { withRouter } from 'react-router';
import cookie from 'js-cookie';
// import Raven from 'raven-js';

import queryString from 'query-string';

import { createUrl, prepareUrlForLogout } from 'app/utils/url';

import {
    errorService,
    userSessionService,
    // analyticService,
} from 'global/services';

import AUTH_MUTATION from 'app/graphql/network/auth/authMutation';

import { DeletedAccountDialogWithAppContext } from './DeletedAccountDialogWithAppContext';
import { useSyncedUserCustomizationComment } from './useSyncedUserCustomizationComment';

import { tokenVar } from '../../apollo/reaction';

import { ROOT_USER_SESSION_QUERY } from './graphql/initialUserQuery.graphql';
import { onUserInited } from './actions/onUserInited';

export const AuthContext = createContext({
    isAuthed: false,
    onUserAuthed: () => {},
    onUserQuit: () => {},
    refetchUser: () => {},
    userData: {},
});

export const withAuthContext = (Component) => (props) => (
    <AuthContext.Consumer>
        {(authData) => <Component {...props} authData={authData} />}
    </AuthContext.Consumer>
);

/**
 * @description Контекст AuthContext хранит в себе коллбэки onUserAuthed и onUserQuit
 * которые управляют состоянием токена после авторизации и разлогину.
 */

function AuthContextContainer({
    children,
    authData,
    onUserFetched = () => {},
    pageContext = 'menu-app',
}) {
    const { data, refetch: refetchUser } = useQuery(ROOT_USER_SESSION_QUERY, {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'standby',
        onCompleted: ({ user }) => onUserInited(user),
        context: {
            message: 'app:read:AuthContextContainer',
            event: ['intercept:user:phone'],
        },
    });

    const [authContextState, setAuthContextState] = useState({ isAuthed: false });

    useSyncedUserCustomizationComment();

    /* HANDLERS */

    const onUserAuthed = useCallback((authMutationData) => {
        if (!authMutationData) {
            errorService.log({
                source: 'client',
                text: 'Auth response is empty',
                scope: 'AuthContextContainer.js',
                error: 'Auth response is empty ',
                authMutationData,
            });
            return null;
        }

        const { token, isAuthed } = authMutationData;
        tokenVar(token);
        localStorage.setItem('token', token);
        userSessionService.setData({ token });
        setAuthContextState({ ...authContextState, isAuthed });

        return authMutationData;
    }, []);

    const onUserQuit = useCallback(() => {
        try {
            const { location: { search } } = window;
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            cookie.remove('token', { domain: 'elementaree.ru' });
            const preparedQueryParams = prepareUrlForLogout(queryString.parse(search));
            const nextUrl = createUrl({ queryParams: preparedQueryParams, host: '/' });
            setTimeout(() => { window.location = nextUrl; }, 500);
        } catch (error) {
            errorService.log({
                source: 'client',
                text: error,
                scope: 'AuthContextContainer.js',
                error,
            });
        }
    }, []);

    /* EFFECTS */

    /**
     * Обработка полученного токена из мутации authUser
     * выполняется при загрузке старницы после authUser
     */
    useEffect(() => {
        onUserAuthed(authData);
        onUserFetched();
    }, []);

    /* RENDER */

    const authContextValue = useMemo(() => {
        const value = {
            ...authContextState,
            userData: data?.user,
            refetchUser,
            onUserAuthed,
            onUserQuit,
        };
        return value;
    }, [authContextState, data, refetchUser]);

    return (
        <AuthContext.Provider value={authContextValue}>
            {children}
            <DeletedAccountDialogWithAppContext
                pageContext={pageContext}
                onUserQuit={onUserQuit}
            />
        </AuthContext.Provider>
    );
}

AuthContextContainer.propTypes = {
    onUserFetched: func,
    pageContext: string,
    authData: shape({}).isRequired,
    children: node.isRequired,
};

/**
 * @description Компонент, реализующий получение токена для пользователя
 */
const AuthInitialContainer = (props) => {
    const [isTokenFetched, setIsTokenFetched] = useState(false);
    const [isUserFetched, setIsUserFetched] = useState(false);
    const isAuthDataLoaded = useMemo(
        () => isTokenFetched && isUserFetched, [isTokenFetched, isUserFetched],
    );

    /**
     * @step
     */
    const [fetchUser, { loading }] = useLazyQuery(ROOT_USER_SESSION_QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'standby',
        onCompleted: () => {
            setIsUserFetched(true);
        },

        context: {
            message: 'root:init:AuthContainer',
            event: ['intercept:user:phone'],
        },
        onError: () => {
            setIsUserFetched(true);
        },
    });

    /**
     * @description делает запрос, наполняет кэш и трегирит userQuery в AuthContextContainer
     * После первого запроса AuthContextContainer отрабатывает и ждем прямого вызова если
     */
    const [mutateAuthUser, { data: authData, loading: authLoading }] = useMutation(
        AUTH_MUTATION, {
            onError: (error) => {
                errorService.log({
                    source: 'server',
                    text: 'error authMutation',
                    scope: 'authContainder.js',
                    error,
                });
            },

            onCompleted: async (data) => {
                const { auth: { token } } = data;
                tokenVar(token);
                localStorage.setItem('token', token);
                userSessionService.setData({ token });
                setIsTokenFetched(true);
                await fetchUser();
            },

            context: {
                message: 'root:init:AuthContainer',
            },
        },
    );

    /**
     * @description Вызывается один раз при маунте компонента и получает токен
    */
    useEffect(() => {
        mutateAuthUser();
    }, [mutateAuthUser]);

    if (!isAuthDataLoaded) return null;

    return <AuthContextContainer {...props} authData={authData.auth} />;
};

export const AuthContainer = (props) => <AuthInitialContainer {...props} />;

export default withRouter(AuthContainer);
