import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import classNames from 'classnames';

import './overlay.scss';


/*
    TODO: из-за бага в появлении оверлеев корзины и чекаута, для animation-right задан
    интервал enter === 0
    Баг связан с тем, что showBasket и showBasketOverlay становятся true одновременно
*/
const ANIMATION_TIMEOUT = {
    top: { enter: 500, exit: 500 },
    bottom: { enter: 400, exit: 400 },
    right: { enter: 0, exit: 600 },
    'dialog-bottom': { enter: 500, exit: 500 },
};

const Overlay = ({
    overlayOpen,
    children = {},
    render = null,
    animationDirection = 'right',
    isNeedBlur = false,
    isNeedWhiteLayer = false,
    animateEnter = false,
    animateExit = false,
    zIndex = 2000,
    onClick = () => { },
    onExited = () => { },
    onEntered = () => { },
}) => {
    const [transitionState, setTransitionState] = useState('');

    useLayoutEffect(() => {
        /**
        *  Как это работает этот хитрый костыль?
        *  На didMount, querySelectorAll находит все ноды с id #overlay-node.
        *  Если надо одна - то нужно залочить скролл, если их больше - ни чего не делать.
        *  Тоже самое происходит на componentWillUnmount
        */
        const allOverlayNode = document.querySelectorAll('#overlay-node') || [];
        if (allOverlayNode.length <= 1) {
            // console.log('lock scrollbar')
            document.body.style.overflow = 'hidden';
        }

        return () => {
            if (allOverlayNode.length <= 1) {
                // console.log('unlock scrollbar');
                document.body.style.overflow = null;
            }
        };
    }, []);

    const handleEntering = () => {
        setTransitionState('entering');
    };
    const handleEntered = () => {
        onEntered();
        setTransitionState('entered');
    };

    const handleExiting = () => {
        setTransitionState('exiting');
    };

    const handleExited = () => {
        onExited();
        setTransitionState('exited');
    };

    const rootClasses = classNames({
        overlay__root: true,
        [transitionState]: transitionState,
    });

    return (
        <div
            styleName={rootClasses}
            style={{ zIndex }}
            id="overlay-node"
        >
            <Transition
                in={overlayOpen}
                timeout={ANIMATION_TIMEOUT[animationDirection]}
                appear={animateEnter}
                exit={animateExit}
                onEntering={handleEntering}
                onEntered={handleEntered}
                onExiting={handleExiting}
                onExited={handleExited}
                on
            >
                {(state) => {
                    const isEntered = state === 'entered';

                    const blurClasses = classNames({
                        'overlay__blur-layer': true,
                        [state]: state,
                    });

                    const animationClasses = classNames({
                        'overlay__animation-layer': true,
                        [`animation-${animationDirection}`]: true,
                        [state]: state,
                    });
                    return (
                        <>
                            {/* Слой для блюра */}
                            {isNeedBlur && <div styleName={blurClasses} />}

                            {/* Слой для onClick, появляется с запозданием */}
                            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                            {isEntered && <button styleName="overlay__close-layer" onClick={onClick} type="button" />}

                            <div styleName={animationClasses}>
                                {/* Белый слой для перекрытия контента под текущим оверлеем */}
                                {isNeedWhiteLayer && <div styleName="overlay__white-layer" />}

                                {/* Рендеринг контента (children/renderProp) */}
                                {render
                                    ? render({ transitionState: state })
                                    : children
                                }
                            </div>
                        </>
                    );
                }}

            </Transition>
        </div>
    );
};


Overlay.propTypes = {
    onClick: PropTypes.func,
    onExited: PropTypes.func,
    onEntered: PropTypes.func,

    render: PropTypes.func,
    children: PropTypes.shape({}),
    overlayOpen: PropTypes.bool.isRequired,
    animationDirection: PropTypes.string,
    animateEnter: PropTypes.bool,
    animateExit: PropTypes.bool,
    isNeedBlur: PropTypes.bool,
    isNeedWhiteLayer: PropTypes.bool,
    zIndex: PropTypes.number,
};

export default Overlay;
