import React, { useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    useLocation,
} from 'react-router-dom';

import { LocaleContext } from 'app/containers/LocaleProvider';
import { OverlayContextDispatch } from 'app/containers/contexts/overlay.context';


const dialogTexts = {
    ru: {
        strongText: <>Блюда этой недели уже&nbsp;недоступны</>,
        regularText: 'Заполнить корзину похожими блюдами?',
        confirmText: 'Хорошо',
    },
    en: {
        strongText: <>Блюда этой недели уже&nbsp;недоступны</>,
        regularText: 'Заполнить корзину похожими блюдами?',
        confirmText: 'Хорошо',
    },
};

const pathesToShow = ['/menu/', '/basket/', '/checkout/'];


/**
 * Компонент ничего не рендерит, только пушит оверлей диалога в коллбэке по условию
 */
const InactivePeriodDialogContainer = React.memo((props) => {
    const {
        isDialogRequired = false,
        onConfirm,
    } = props;

    const location = useLocation();

    const { locale } = useContext(LocaleContext);
    const { pushDialogOverlay, closeLastOverlays } = useContext(OverlayContextDispatch);

    const needToShowDialog = useMemo(
        () => isDialogRequired && pathesToShow.includes(location.pathname),
        [isDialogRequired, location.pathname],
    );

    useEffect(() => {
        const dialogData = {
            ...dialogTexts[locale],
            oneRowButtons: true,
            onConfirm,
            notifyOnly: true,
        };

        if (needToShowDialog) {
            pushDialogOverlay(
                'inactive_period_dialog',
                dialogData,
                { onClick: onConfirm },
            );
        }
    }, [locale, needToShowDialog, pushDialogOverlay, onConfirm]);

    useEffect(() => {
        if (!isDialogRequired) {
            closeLastOverlays();
        }
    }, [isDialogRequired, closeLastOverlays]);

    return null;
});


export default InactivePeriodDialogContainer;


InactivePeriodDialogContainer.propTypes = {
    isDialogRequired: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
};


