import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { BackButton } from 'app/components/ui/BackButton';

import './mobile-cart-header.scss';
import { UIHeading } from '../ui';


const noop = () => {};


const MobileCartHeader = ({
    isLeftBlockShown = true,
    onClickBack = noop,
    position = 'static',
    realm = 'default',
}) => {
    const rootClasses = classNames({
        'mobile-cart-header__root': true,
    });

    return (
        <div styleName={rootClasses} style={{ position }}>
            <div styleName="basket-header-back-button-wrapper">
                {isLeftBlockShown && <BackButton onClick={onClickBack} justify="start" />}
                {realm === 'basket' && (
                    <div styleName="basket-header-title-wrapper">
                        <UIHeading level="3.2">
                            Корзина
                        </UIHeading>
                    </div>
                )}
            </div>
        </div>
    );
};

MobileCartHeader.propTypes = {
    onClickBack: PropTypes.func,
    isLeftBlockShown: PropTypes.bool,
    position: PropTypes.string,
    realm: PropTypes.string,
};

export default MobileCartHeader;
