/* eslint-disable camelcase */
import { TDaysText, TDeliveryZoneRecord, TZoneId } from './deliveryZoneTimeAndPrice.types';

const growFoodAllDaysList: Array<TDaysText> = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Cб', 'Вс'];
const growFoodPartDaysList: Array<TDaysText> = ['Вт', 'Чт', 'Вс'];
const spbDaysList: Array<TDaysText> = ['Вт', 'Ср', 'Чт', 'Пт', 'Вс'];
const kznDaysList: Array<TDaysText> = ['Вт', 'Ср', 'Чт', 'Пт', 'Вс'];

const deliveryZoneRecord = new Map<TZoneId, TDeliveryZoneRecord>([
    ['ТТК', { days: growFoodAllDaysList, morningRange: 2, eveningRange: 2 }],
    ['МО-1', { days: growFoodAllDaysList, morningRange: 3, eveningRange: 3 }],
    ['МО-2', { days: growFoodAllDaysList, morningRange: 3, eveningRange: 4 }],
    ['МО-3', { days: growFoodAllDaysList, morningRange: 3, eveningRange: 5 }],
    ['МО-4 Зеленоград', { days: growFoodAllDaysList, morningRange: 0, eveningRange: 6 }],
    ['МО-4', { days: growFoodPartDaysList, morningRange: 0, eveningRange: 6 }],
    ['МО-5', { days: growFoodPartDaysList, morningRange: 0, eveningRange: 6 }],
    ['МО-6', { days: growFoodPartDaysList, morningRange: 0, eveningRange: 6 }],
    ['КАД', { days: spbDaysList, morningRange: 2, eveningRange: 2 }],
    ['ЛО1', { days: spbDaysList, morningRange: 0, eveningRange: 3 }],
    ['ЛО-1 ПЕТЕРГОФ', { days: spbDaysList, morningRange: 0, eveningRange: 3 }],
    ['ЛО2', { days: spbDaysList, morningRange: 0, eveningRange: 3 }],
    ['ЛО3', { days: spbDaysList, morningRange: 0, eveningRange: 4 }],
    ['НН', { days: growFoodAllDaysList, morningRange: 3, eveningRange: 3 }],
    ['НО', { days: growFoodAllDaysList, morningRange: 0, eveningRange: 6 }],
    ['КЗН', { days: kznDaysList, morningRange: 3, eveningRange: 3 }],
    ['КЗН-1', { days: kznDaysList, morningRange: 0, eveningRange: 6 }],
]);

const TTK_KEYS = [
    'ЮАО',
    'ЮЗАО',
    'ЗАО',
    'ЮВАО',
    'ВАО',
    'СВАО',
    'САО',
    'СЗАО',
    'ЦЕНТР ТТК',
    'ЦЕНТР-ТТК-ВОСТОК',
];

const CAD_KEYS = [
    'СПБ-НОВАЯ ОХТА',
    'СПБ-КОМЕНДАНТСКИЙ',
    'СПБ-ОЗЕРКИ',
    'СПБ-ОХТА',
    'СПБ-ПОЛЮСТРОВО',
    'СПБ-БОЛЬШЕВИКОВ',
    'СПБ-ВАСИЛЬЕВСКИЙ ОСТРОВ',
    'СПБ-ГРАЖДАНКА',
    'СПБ-АКАДЕМКА',
    'СПБ-ЗВЕЗДНАЯ',
    'СПБ-УДЕЛЬНАЯ',
    'СПБ-КИРОВСКИЙ РАЙОН',
    'СПБ-КУПЧИНО',
    'СПБ-ЛЕСНОЙ',
    'СПБ-РЫБАЦКОЕ',
    'СПБ-НАРВСКАЯ',
    'СПБ-НЕВСКИЙ РАЙОН',
    'СПБ-ПИОНЕРСКАЯ',
    'СПБ-ПАРНАС',
    'СПБ-ПИОН-КОЛОМЯГИ',
    'СПБ-ПИСКАРЕВКА',
    'СПБ-УТКИНА ЗАВОДЬ',
    'СПБ-СТАРАЯ ДЕРЕВНЯ',
    'СПБ-ЛАХТА',
    'СПБ-РЖЕВКА-ПОРОХОВЫЕ',
    'СПБ-ЦЕНТР(низ)',
    'СПБ-ЮГО-ЗАПАД',
    'СПБ-ЧЕРНАЯ РЕЧКА',
    'СПБ-АВТОВО',
    'СПБ-МОСКОВСКИЙ РАЙОН',
    'СПБ-ЦЕНТР(верх)',
    'СПБ-ВЫБОРГСКАЯ СТОРОНА',
    'СПБ-ПЕТРОГРАДСКАЯ СТОРОНА',
];

const NN_KEY = ['НН'];

const NO_KEY = ['НО'];

const TA_KEY = ['КЗН'];
const TAO_KEY = ['КЗН-1'];


export const parsDeliveryZoneId = (region_name: any): TZoneId | null => {
    const MO_KEY_REGEX = /^МО-\d( |$).*/;
    const LO_KEY_REGEX = /^ЛО-\d( |$).*/;

    const regName = region_name?.trim() || region_name;

    if (NN_KEY.includes(region_name)) {
        return 'НН';
    }

    if (NO_KEY.includes(region_name)) {
        return 'НО';
    }

    if (TTK_KEYS.includes(regName)) {
        return 'ТТК';
    }

    if (MO_KEY_REGEX.test(regName)) {
        const zoneId = regName.match(MO_KEY_REGEX)[0];
        return zoneId;
    }

    if (CAD_KEYS.includes(regName)) {
        return 'КАД';
    }

    if (LO_KEY_REGEX.test(regName)) {
        const zoneId = regName.match(LO_KEY_REGEX)[0];
        return zoneId;
    }

    if (TA_KEY.includes(region_name)) {
        return 'КЗН';
    }
    if (TAO_KEY.includes(region_name)) {
        return 'КЗН-1';
    }

    return null;
};

export const getDeliveryZoneRecord = (zoneId: TZoneId | null): TDeliveryZoneRecord => {
    const initialRecord = {
        days: [],
        morningRange: 0,
        eveningRange: 0,
    };

    if (!zoneId) {
        return initialRecord;
    }

    return deliveryZoneRecord.get(zoneId) || initialRecord;
};
