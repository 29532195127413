export const stepByStepCheckoutEventsRecord = [
    /**
     * @event 'Checkout_Start_Onepager'
     */
    {
        eventName: 'Checkout_Start_Onepager',
        description: 'Трекает открытие формы пошагового чекаута',
        meta: ['state'],
        scope: ['checkout'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Checkout_Start_Phone'
     */
    {
        eventName: 'Checkout_Start_Phone',
        description: 'Трекает открытие формы ввода телефона',
        meta: ['state'],
        scope: ['checkout'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Checkout_Start_Code'
     */
    {
        eventName: 'Checkout_Start_Code',
        description: 'Трекает открытие формы ввода пинкода',
        meta: ['state'],
        scope: ['checkout'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Checkout_Start_Address'
     */
    {
        eventName: 'Checkout_Start_Address',
        description: 'Трекает открытие формы ввода адреса',
        meta: ['state'],
        scope: ['checkout'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Checkout_Start_Date'
     */
    {
        eventName: 'Checkout_Start_Date',
        description: 'Трекает открытие формы ввода даты доставки',
        meta: ['state'],
        scope: ['checkout'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Checkout_Start_Confirmation'
     */
    {
        eventName: 'Checkout_Start_Confirmation',
        description: 'Трекает открытие форм выбора способо оплаты и input воода промокода',
        meta: ['state'],
        scope: ['checkout'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Checkout_Close_Phone'
     */
    {
        eventName: 'Checkout_Close_Phone',
        description: 'Трекает закрытие формы ввода телефона',
        meta: ['state'],
        scope: ['checkout'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Checkout_Close_Code'
     */
    {
        eventName: 'Checkout_Close_Code',
        description: 'Трекает закрытие формы вводы пинкода',
        meta: ['state'],
        scope: ['checkout'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Checkout_Close_Address'
     */
    {
        eventName: 'Checkout_Close_Address',
        description: 'Трекает закрытие формы ввода адреса',
        meta: ['state'],
        scope: ['checkout'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Checkout_Close_Date'
     */
    {
        eventName: 'Checkout_Close_Date',
        description: 'Трекает закрытие формы выбора даты доставки',
        meta: ['state'],
        scope: ['checkout'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Checkout_Close_Confirmation'
     */
    {
        eventName: 'Checkout_Close_Confirmation',
        description: 'Трекает зыкратие  форм выбора способо оплаты и input промокода',
        meta: ['state'],
        scope: ['checkout'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Checkout_Stepback_Address'
     */
    {
        eventName: 'Checkout_Stepback_Address',
        description: 'Трекает клик по кнопке возврата из экрана выбора адреса доставки',
        meta: [],
        scope: ['checkout'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Checkout_Stepback_Date'
     */
    {
        eventName: 'Checkout_Stepback_Date',
        description: 'Трекает клик по кнопке возврата из экрана выбора даты доставки',
        meta: [],
        scope: ['checkout'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Checkout_Stepback_Confirmation'
     */
    {
        eventName: 'Checkout_Stepback_Confirmation',
        description: 'Трекает клик по кнопке возврата из экрана выбора способа оплаты',
        meta: ['click'],
        scope: ['checkout'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Submit_Validation_Code'
     * @param result - 'success' / 'failure'
     */
    {
        eventName: 'Submit_Validation_Code',
        description: 'Трекает результат введеного пинкода',
        meta: ['submit'],
        scope: ['checkout'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName, result } = data;
            return {
                event: eventName,
                result,
            };
        },
    },

    /**
     * @event 'Checkout_Click_Date'
     */
    {
        eventName: 'Checkout_Click_Date',
        description: 'Трекает клик по кнопке выбора даты доставки',
        meta: ['click'],
        scope: ['checkout'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Checkout_Click_Time'
     */
    {
        eventName: 'Checkout_Click_Time',
        description: 'Трекает клик по кнопке выбора времени доставки',
        meta: ['click'],
        scope: ['checkout'],
        check() {
            return true;
        },

        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Checkout_Back_From_Editing_Phone'
     */
    {
        eventName: 'Checkout_Back_From_Editing_Phone',
        description: 'Одностраничный чекаут, возвращение к чекауту с шага телефона',
        meta: ['click'],
        scope: ['checkout'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },
    /**
     * @event 'Checkout_Back_From_Editing_Address'
     */
    {
        eventName: 'Checkout_Back_From_Editing_Address',
        description: 'Одностраничный чекаут, возвращение к чекауту с шага адреса',
        meta: ['click'],
        scope: ['checkout'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },
    /**
     * @event 'Checkout_Back_From_Editing_Date'
     */
    {
        eventName: 'Checkout_Back_From_Editing_Date',
        description: 'Одностраничный чекаут, возвращение к чекауту с шага даты и времени',
        meta: ['click'],
        scope: ['checkout'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },
    /**
     * @event 'Checkout_Back_From_Editing_Confirmation'
     */
    {
        eventName: 'Checkout_Back_From_Editing_Confirmation',
        description: 'Одностраничный чекаут, возвращение к чекауту с шага способа оплаты',
        meta: ['click'],
        scope: ['checkout'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },
    /**
     * @event Checkout_Click_Address_Comment
     */
    {
        eventName: 'Checkout_Click_Address_Comment',
        description: 'Трекает клик по input`у коментария',
        meta: ['click'],
        scope: ['checkout'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },
];
