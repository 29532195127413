import React from 'react';

export const locales = {
    ru: {
        // render
        buttonText: 'Напишите нам',
        buttonDesriptionTwo: <>Мы&nbsp;разработаем меню специально для вас и&nbsp;пришлем уведомление о&nbsp;его готовности в&nbsp;мессенджер.</>,

    },
    en: {
        // render
        buttonText: 'Send us your comments',
        buttonDesriptionTwo: <>We will develop your personal menu and notify you via messenger when it is ready</>,
    },
};
