import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './paragraph.scss';


export default function Paragraph({
    level,
    children,
    inheritColors = false,
    ellipsis = false,
    clip = false,
    className = '',
}) {
    const normalizedLevel = level.replace(/\./gi, '_');

    const classes = classNames({
        'ui-paragraph': true,
        [`ui-paragraph--level-${normalizedLevel}`]: level !== undefined,
        'inherit-colors': inheritColors,
        ellipsis,
        clip,
    });

    return (
        <p styleName={classes} className={className}>
            {children}
        </p>
    );
}


Paragraph.propTypes = {
    level: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    inheritColors: PropTypes.bool,
    ellipsis: PropTypes.bool,
    clip: PropTypes.bool,
    className: PropTypes.string,
};
