/* eslint-disable quote-props */
export const locales = {
    ru: {
        '1220': 'Лайт',
        '1809': 'До 15 мин',
        '1765': 'Премиум',
        '1519': 'Для детей',
        '2024': 'Черноморская кухня',
        '2028': 'Про запас',
        '1218': 'Вег',
        '1219': 'Веган',
        '1515': 'Без мяса и птицы',
        '1517': 'Без свинины',
        '1516': 'Без рыбы',
        '1520': 'Без острого',
        '1524': 'Без лука',
        '1521': 'Без грибов',
        '2026': 'Best',
        '1826': 'Новый год',
        '1840': 'Новогодние десерты',
    },
    en: {
        '1220': 'Lite',
        '1809': 'Less than 15 min',
        '1765': 'Premium',
        '1519': 'For children',
        '2024': 'Black Sea cuisine',
        '2028': 'Stocking up',
        '1218': 'Veg',
        '1219': 'Vegan',
        '1515': 'No meat',
        '1517': 'No pork',
        '1516': 'No fish',
        '1520': 'No spicy',
        '1524': 'No onion',
        '1521': 'No mushrooms',
        '2026': 'The Best',
        '1826': 'New Year',
        '1840': "New Year's Desserts",
    },
};
