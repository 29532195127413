/* eslint-disable no-mixed-operators */

/**
 * @description Принимает дату в любом формате и возвращает номер недели.
 * @note Номер недели используется в качестве id кеша для apollo у __typename: periods
 * @param {date} selectedDate
 * @return {number} weekId
 */
export const getWeekByDate = (selectedDate) => {
    const date = new Date(selectedDate);
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);

    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    const result = 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    return result;
};
