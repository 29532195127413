import React, { useContext } from 'react';
import { FiltersContext } from 'app/containers/contexts/filters.context/filters.context';

type TypePeriod = {
    start: string
}

type TypeCategory = {
    id: string,
    title: string,
}

export const useAvailableCategories = () => {
    const {
        filterQuery: { menuFilter },
        selectedFilters: { selectedPeriod, selectedCategories, selectedTags },
    } = useContext<any>(FiltersContext);

    const { periods } = menuFilter;

    const period = periods.find((p: TypePeriod) => p.start === selectedPeriod);
    const categories = period?.categories || [];
    const filteredCategories = categories
        .filter((c: TypeCategory) => selectedCategories.includes(c.id));

    const dishesProvidedData = {
        categories,
        selectedCategories,
        selectedTags,
        menuFilter,
    };

    return [filteredCategories, dishesProvidedData];
};
