export const locales = {
    ru: {
        // Render
        errorText: 'Произошла ошибка. Попробуйте повторить позже.',
        // renderFilters
        categoriesTitle: 'Типы блюд',
        proteinsTitle: 'Протеин/Белки',
        stylesTitle: { short: 'Стиль', long: 'Стиль питания' },
        ingredientsTitle: 'Ингредиенты',
        filterButtonText: 'Фильтры',
    },
    en: {
        // Render
        errorText: 'An error has occured. Please try again later.',
        // renderFilters
        categoriesTitle: 'Category',
        proteinsTitle: 'Protein',
        stylesTitle: { short: 'Style', long: 'Style' },
        ingredientsTitle: 'Ingredients',
        filterButtonText: 'Filters',
    },
};
