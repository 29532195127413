import { gql } from '@apollo/client';

export const VIEW_CUSTOMIZATION_COMMENT = gql`
   query viewCustomizationComment {
        viewCustomizationComment {
            id
            comment
            status
            id
            type
            reason
        }
    }
`;

export const SAVE_CUSTOMIZATION_COMMENT = gql`
    mutation saveCustomizationComment($comment: String!) {
        saveCustomizationComment(comment: $comment) {
            success
        }
    }
`;

export const UPDATE_CUSTOMIZATION_COMMENT = gql`
    mutation updateCustomizationComment($comment: String!, $delivery_id: ID!, $mass_update: Boolean!) {
        updateCustomizationComment(
            comment: $comment,
            delivery_id: $delivery_id,
            mass_update: $mass_update,
        ) {
            success
        }
    }
`;
