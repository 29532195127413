import React from 'react';

import LINKS from 'app/const/links';

import colors from 'global/uikit/uiTokens/colorUiTokens';
import { PREMIUM_SET_GREEN_VERSION } from 'app/const/links/premiumSet';
import trialImg from './images/trial.jpg';
import everydayImg from './images/everyday.jpg';
// import boxImg from './images/box.jpg';
import premiumImg from './images/premium.jpg';

const {
    TRIAL_FIRST_ORDER_SET,
    EVERYDAY_SET,
    PREMIUM_SET,
    // BOX_PAGE,
    // NEW_YEAR_PAGE,
    CHRISTMAS_PAGE,
    // ROMANTIC_PAGE,
} = LINKS;

// RECORD ITEMS ----------------------------------------------------------------
const TRIAL_FIRST_ORDER_SET_ITEM = {
    id: TRIAL_FIRST_ORDER_SET.id,
    data: {
        title: {
            ru: 'Пробный сет',
            en: 'Trial set',
        },
        subtitle: {
            ru: 'Скидка новым пользователям',
            en: 'with discount',
        },
        // discountPrice: TRIAL_FIRST_ORDER_SET.discountPrice,
        href: TRIAL_FIRST_ORDER_SET.hrefBasketMobileAB,
        // badge: {
        //     discountValue: TRIAL_FIRST_ORDER_SET.discountPercent,
        //     color: colors.coconut,
        //     backgroundColor: '#EF3F00',
        // },
        imageSrc: trialImg,
        trackId: 'trial',
    },
};
const CHRISTMAS_PAGE_ITEM = {
    id: CHRISTMAS_PAGE.id,
    data: {
        title: {
            ru: 'Рождество',
            en: 'Рождество',
        },
        subtitle: {
            ru: (
                <>
                    Доставка
                    <br />
                    4-8 января
                </>
            ),
            en: (
                <>
                    Доставка
                    <br />
                    4-8 января
                </>
            ),
        },
        href: CHRISTMAS_PAGE.hrefRu,
        // badge: {
        //     discountValue: '8',
        //     color: colors.coconut,
        //     backgroundColor: '#26AB46',
        // },
        // eslint-disable-next-line global-require
        imageSrc: require('./images/ny.jpg'),
        trackId: CHRISTMAS_PAGE.trackId,
    },
};
const EVERYDAY_SET_ITEM = {
    id: EVERYDAY_SET.id,
    data: {
        title: {
            ru: 'Сет недели',
            en: ' Weekly set',
        },
        subtitle: {
            ru: '5 блюд х 2 порции',
            en: '5 dishes x 2 servings',
        },

        discountPrice: (() => EVERYDAY_SET.discountPrice)(),
        href: EVERYDAY_SET.href,
        badge: {
            discountValue: EVERYDAY_SET.discountPercent,
            color: colors.coconut,
            backgroundColor: '#EF3F00',
        },
        imageSrc: everydayImg,
        trackId: 'weekly',
    },
};
const PREMIUM_SET_ITEM = {
    id: PREMIUM_SET.id,
    data: {
        title: { ru: 'Премиум меню', en: 'Premium Pack' },
        subtitle: {
            ru: '5 блюд x 2 порции',
            en: '5 dishes x 2 portions',
        },
        discountPrice: PREMIUM_SET.discountPrice,
        href: PREMIUM_SET_GREEN_VERSION.href,
        imageSrc: premiumImg,
        trackId: 'premium',
    },
};

// ACCESSORS -------------------------------------------------------------------
// eslint-disable-next-line no-unused-vars
export const getStaticLinkOptions = ({ selectedPeriod }) => [
    TRIAL_FIRST_ORDER_SET_ITEM,
    CHRISTMAS_PAGE_ITEM,
    EVERYDAY_SET_ITEM,
    PREMIUM_SET_ITEM,
];

export const sliderCardContentMap = new Map([
    ['PREMIUM_SET', PREMIUM_SET_ITEM],
    ['CHRISTMAS_PAGE', PREMIUM_SET_ITEM],
    ['EVERYDAY_SET', EVERYDAY_SET_ITEM],
    ['TRIAL_FIRST_ORDER_SET', TRIAL_FIRST_ORDER_SET_ITEM],
]);
