import { isEmpty } from 'lodash';

import { getDialogTextIfWeKnow } from '../CustomizationSourceContent';
import { hotfixExcludingTags } from './excludingTags';
import { CustomizationSourceData } from '../customizationSource.types';


export const userFilterTagsCustomizationFactory = (
    { user }: CustomizationSourceData,
    isTagsCustomizationKeep: boolean,
) => {
    if (!user) {
        return {
            text: '',
            isThisSource: false,
            case: 'tags customization',
        };
    }

    const {
        customizationTagsDetailed = [],
        filterTags = [],
    } = user;

    const tagTitles = customizationTagsDetailed.filter((tag) => {
        const { title, id } = tag;
        const isFilterTag = filterTags.includes(Number(id));
        const hasTitle = Boolean(title);

        const isExcludingTag = hotfixExcludingTags.includes(String(id));

        return isFilterTag && hasTitle && !isExcludingTag;
    }).map((tag) => tag.title);

    const tagsStr = tagTitles.join(', ');

    return {
        text: getDialogTextIfWeKnow(tagsStr),
        isThisSource: !isEmpty(tagTitles) && !isTagsCustomizationKeep,
        case: 'tags customization',
    };
};
