import React from 'react';
import PropTypes from 'prop-types';
import { UIButton } from 'app/components/ui';

import './button-close-overlay-tooltip.scss';



export default function ButtonCloseOverlayTooltip(props) {
    const { onClick } = props;
    return (
        <div styleName="overlay-tooltip__close-wrap">
            <UIButton
                onClick={onClick}
                centerContent="Ок, понятно"
            />
        </div>
    );
}

ButtonCloseOverlayTooltip.propTypes = {
    onClick: PropTypes.func.isRequired,
};
