export const prepareIntervalasId = (responseObject) => {
    const { id, __typename } = responseObject;

    if (id) {
        return `${__typename}:${id}`;
    }

    return `${__typename}:user-session`;
};

