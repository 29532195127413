import React from 'react';
import { createRoot } from 'react-dom/client';
import 'array-flat-polyfill';
import 'core-js/actual';
import 'url-search-params-polyfill';

import './styles/index.scss';

import { isStandaloneApp } from 'app/utils/browser';
import App from './App';


window.isAppLoaded = true;

if (!isStandaloneApp() && process.env.APP_LEGACY === 'true') {
    setTimeout(() => {
        const { location: { pathname, search } } = window;
        const nextLocation = `https://elementaree.ru${pathname}${search}`;
        window.location = nextLocation;
    }, 500);
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);
