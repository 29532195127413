import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { LocaleContext } from 'app/containers/LocaleProvider';

import {
    discountConditionsInitialType,
    discountConditionsType,
} from 'types';

import { UIButton, UIButtonBottomArea } from 'app/components/ui';
import FloatFooterDiscountCounter from 'app/components/FloatFooterDiscountCounter';
import SkeletonItem from 'app/components/SkeletonItem';
import formatPrice from 'app/utils/price';

import PlusIcon from './assets/plus.component.svg';

// import { selectedPeriodVar } from 'app/apollo/reaction';
// import BASKET_QUERY_PRICE from './graphql/basketQueryPrice.graphql';

import './open-basket-button.scss';

const locales = {
    ru: {
        cart: 'Корзина',
    },
    en: {
        cart: 'Cart',
    },
};


function CenterContent({
    title = 'Корзина',
    price,
    originalPrice = 0,
}) {
    const { locale } = useContext(LocaleContext);

    const text = title ?? locales[locale].cart;

    const hasDiscount = Boolean(originalPrice && originalPrice !== price);

    return (
        <>
            <span className="title">{text}</span>
            {price > 0 && (
                <span className="price" styleName="open-basket-button-price">
                    {locale === 'en' && (<span className="rub">₽</span>)}
                    <span className="num">{formatPrice(price)}</span>
                    {locale === 'ru' && (<span className="rub">₽</span>)}
                </span>
            )}
            {hasDiscount && (
                <span className="original-price">
                    <span className="num">{formatPrice(originalPrice)}</span>
                    {/* <span className="num">3 000</span> */}
                    <span className="rub">₽</span>
                </span>
            )}

        </>
    );
}
CenterContent.propTypes = {
    title: PropTypes.string,
    price: PropTypes.number.isRequired,
    originalPrice: PropTypes.number,
};


export default function OpenBasketButton({
    isNeededRenderSkeleton = false,
    onClick = () => { },
    loading = false,
    price = 0,
    promocodeConditions = discountConditionsInitialType,
    originalPrice = 0,
    position = 'fixed',
    elType = 'button',
    href = '',
    title = 'Корзина',
    showPlusIcon = false,
}) {
    // const selectedPeriod = useReactiveVar(selectedPeriodVar);
    // const { data: { cart } } = useQuery(BASKET_QUERY_PRICE, {
    //     variables: {
    //         period: selectedPeriod,
    //     },
    // });
    // console.log(loading, 'loading');
    if (isNeededRenderSkeleton) {
        return (
            <UIButtonBottomArea zIndex="3">
                <div styleName="basket-button-skeleton">
                    <SkeletonItem />
                </div>
            </UIButtonBottomArea>
        );
    }

    return (
        <UIButtonBottomArea zIndex="3" position={position}>
            <FloatFooterDiscountCounter promocodeConditions={promocodeConditions} />
            <UIButton
                centerContent={(
                    <div styleName="center-content-wrapper">
                        <CenterContent price={price} originalPrice={originalPrice} title={title} />
                        {showPlusIcon && <PlusIcon style={{ paddingLeft: 4 }} />}
                    </div>
                )}
                onClick={onClick}
                loading={loading}
                aria-label="Корзина"
                data-test-id="open-basket-button"
                elType={elType}
                href={href}
            />
        </UIButtonBottomArea>
    );
}


OpenBasketButton.propTypes = {
    isNeededRenderSkeleton: PropTypes.bool,
    price: PropTypes.number,
    originalPrice: PropTypes.number,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    promocodeConditions: PropTypes.shape(discountConditionsType),
    position: PropTypes.string,
    elType: PropTypes.string,
    href: PropTypes.string,
    title: PropTypes.string,
    showPlusIcon: PropTypes.bool,
};
