import {
    STEP_PHONE,
    STEP_ADDRESS,
    STEP_DATETIME,
    STEP_PAYMENT,
} from '../stepByStepCheckoutConst';

export const locales = {
    ru: {
        headerTitle: 'Оформление заказа',
        [STEP_PHONE]: 'Ваш телефон',
        [STEP_ADDRESS]: 'Адрес доставки',
        [STEP_DATETIME]: 'Дата и время',
        [STEP_PAYMENT]: 'Способ оплаты',
    },
    en: {
        headerTitle: 'Checkout',
        [STEP_PHONE]: 'Your phone number',
        [STEP_ADDRESS]: 'Delivery address',
        [STEP_DATETIME]: 'Date and time',
        [STEP_PAYMENT]: 'Payment method',
    },
};
