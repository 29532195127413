import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import BasketButton from '../BasketButton';


const locales = {
    ru: {
        titleDefault: 'Ваш заказ:',
        titleDelivery: 'Общая стоимость:',
        titleLoading: 'Загрузка...',
    },
    en: {
        titleDefault: 'Your order:',
        titleDelivery: 'Your order:',
        titleLoading: 'Loading...',
    },
};


const isHideBasket = (location) => (
    location.pathname.includes('/profile')
    || location.pathname.includes('/thx')
    || location.pathname.includes('/auth')
);

const isDelivery = (location) => location.pathname.includes('/delivery');


const BasketButtonWrapper = (props) => {
    const {
        localeContext: { locale },
        basketQuery, location, onClickBasketButton,
        className = '',
    } = props;
    const { error, cart } = basketQuery;

    if (error) return <BasketButton title="Ошибка" hidden={isHideBasket(location)} />;

    if (cart) {
        const {
            isSubscriptionActivated,
            sections,
            totals: {
                total_discount_price: totalPrice,
                static_common_price: commonPrice,
                static_discount_price: discountPrice,
            },
        } = cart;
        let price;
        if ('total_discount_price' in cart.totals) { // корзина офомления нового заказа
            price = totalPrice;
        } else { // корзина доставки
            price = isSubscriptionActivated ? discountPrice : commonPrice;
        }
        const count = sections.reduce((acc, section) => acc + section.items.length, 0);

        const title = isDelivery(location)
            ? locales[locale].titleDelivery
            : locales[locale].titleDefault;

        const icon = isDelivery(location) ? 'arrow' : 'basket';
        return (
            <BasketButton
                locale={locale}
                className={className}
                count={count}
                price={price}
                title={title}
                icon={icon}
                isDelivery={isDelivery(location)}
                hidden={isHideBasket(location)}
                onClick={onClickBasketButton}
            />
        );
    }


    return <BasketButton title={locales[locale].titleLoading} hidden={isHideBasket(location)} />;
};

BasketButtonWrapper.propTypes = {
    localeContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,

    basketQuery: PropTypes.shape({
        error: PropTypes.shape({}),
        cart: PropTypes.shape({
            sections: PropTypes.array,
            totals: PropTypes.shape({
                total_discount_price: PropTypes.number,
                static_common_price: PropTypes.number,
                static_discount_price: PropTypes.number,
            }),
        }),
    }).isRequired,
    location: PropTypes.shape({}).isRequired,
    className: PropTypes.string,
    onClickBasketButton: PropTypes.func.isRequired,
};

export default BasketButtonWrapper;
