import { gql } from '@apollo/client';


export const ADD_ITEM_TO_CART = gql`
    mutation cartItem($period: String!, $delivery_id: ID, $dish_id: ID!, $portions: Int!) {
        cartItem(
            period: $period,
            delivery_id: $delivery_id,
            dish_id: $dish_id,
            portions: $portions
        ) {
            success
        }
    }
`;

export const ADD_ITEMS_TO_CART_BY_DELIVERY_ID = gql`
    mutation addItemsToCartByDeliveryId($period: String!, $deliveryId: ID!) {
        addItemsToCartByDeliveryId(
            period: $period,
            deliveryId: $deliveryId,
        ) {
            success
        }
    }
`;

export const ADD_ITEMS_TO_CART_BY_ID_LIST = gql`
    mutation addItemsToCartByIdList(
        $period: String!,
        $trialScenario: trialScenario,
        $dishes: [ID]!
    ) {
        addItemsToCartByIdList(
            period: $period,
            trial_scenario: $trialScenario,
            dishes: $dishes,
        ) {
            success
        }
    }
`;

export const ADD_ITEMS_TO_CART_BY_SCHEMA = gql`
    mutation addItemsToCartBySchema(
        $period: String!,
        $menuType: menuType,
        $tags: [ID],
        $schema: [schemaItem!]!
    ) {
        addItemsToCartBySchema(
            period: $period,
            menuType: $menuType,
            tags: $tags,
            schema: $schema,
        ) {
            success
            cart {
                period
                isSubscriptionActivated
                id
                type
                sections {
                    id
                    title
                    gift_category
                    cartType
                    items {
                        ...ROOT_CART_DISH_ITEM
                        dish {
                            ...ROOT_MENU_DISH_ITEM
                        }
                    }
                }
                totals {
                    ...ROOT_CART_TOTAL
                }
                discount_conditions {
                    ...ROOT_CART_DISCOUNT_CONDITIONS
                }
            }
        }
    }
`;

export const ADD_ITEMS_TO_CART_BY_PERIOD = gql`
    mutation addItemsToCartByPeriod(
        $period: String!,
        $fillByPeriod: String!,
        $tags: [ID],
    ) {
        addItemsToCartByPeriod(
            period: $period,
            fillByPeriod: $fillByPeriod,
            tags: $tags,
        ) {
            success
        }
    }
`;
