export const URL_KEY = 'filter_tag';

export const parseTagIdsFromURL = (parsedQuery) => {
    let tags = parsedQuery[URL_KEY];

    if (!tags) return null;

    if (!Array.isArray(tags)) {
        tags = tags.split('.');
    }
    return tags;
};
