import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CrossIcon from 'assets/svg/x_in_filters.svg';

import './customization-comment-filters-img.scss';


export default function CustomizationCommentFiltersImg(props) {
    const { isAnimationRunning } = props;

    const titleImgStyle = classNames({
        'customization-comment__title-img': true,
        'cycled-animation': isAnimationRunning,
        'fixed-pic': !isAnimationRunning,
    });

    const crossIconStyle = classNames({
        'cross-icon': true,
        'cycled-animation': isAnimationRunning,
        'fixed-pic': !isAnimationRunning,
    });

    return (
        <div styleName="customization-comment__title-img-container">
            <div styleName={titleImgStyle} />

            <div styleName="icon-container">
                <div styleName="circle" />
                <div styleName="cross-icon-wrapper">
                    <CrossIcon styleName={crossIconStyle} />
                </div>
            </div>
        </div>
    );
}

CustomizationCommentFiltersImg.propTypes = {
    isAnimationRunning: PropTypes.bool.isRequired,
};
