import { gql } from '@apollo/client';

export const PUSH_LEAD_V2 = gql`
    mutation pushLead(
        $date: String
        $email: String
        $phone: String
        $name: String
        $form_name: String
        $source: leadSource
        $location: String
        $utm: leadUtm
        $ga_client_id: String
        $admitad_uid: String
        $subscription: leadSubscription
        $roistat_visit: String
        $request_questions: String
        $partner_id: String
        $site_version: Int
        $platform: platform
    ) {
        pushLead(
            date: $date
            email: $email
            phone: $phone
            name: $name
            form_name: $form_name
            source: $source
            location: $location
            utm: $utm
            ga_client_id: $ga_client_id
            admitad_uid: $admitad_uid
            subscription: $subscription
            roistat_visit: $roistat_visit
            request_questions: $request_questions
            partner_id: $partner_id
            site_version: $site_version
            platform: $platform
        ) {
            success
            isNewbie
            isFirstLead
            id
        }
    }
`;

export default PUSH_LEAD_V2;
