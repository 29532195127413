import React, {
    createContext,
} from 'react';
import PropTypes from 'prop-types';


export const SPA_MODE_OFF = 'off';
export const SPA_MODE_ON = 'on';


const defaultMode = {
    mode: SPA_MODE_ON,
};


export const SPAContext = createContext(defaultMode);

export const withSPAContext = (Component) => (props) => (
    <SPAContext.Consumer>
        {(spaContext) => <Component {...props} spaContext={spaContext} />}
    </SPAContext.Consumer>
);

export default function SPAProvider(props) {
    const {
        mode,
        children,
    } = props;

    const SPAContextValue = {
        mode,
    };

    return (
        <SPAContext.Provider value={SPAContextValue}>
            {children}
        </SPAContext.Provider>
    );
}


SPAProvider.propTypes = {
    mode: PropTypes.oneOf([SPA_MODE_OFF, SPA_MODE_ON]).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};
