import { gql } from '@apollo/client';

/*
    Отправка события в аналитику (сейчас только Adjust) через наш бэкенд
*/
export const CREATE_EVENT = gql`
    mutation createEvent(
        $period: String!
        $eventType: analyticsEventType!
    ) {
        createEvent(
            period: $period
            eventType: $eventType
        ) {
            success
        }
    }
`;
