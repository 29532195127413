import React from 'react';
import css from './basketFrame.module.scss';

interface IBasketFrame {
    children: React.ReactNode,
}

export const BasketFrame = (props: IBasketFrame) => {
    const { children } = props;
    return (
        // <div
        //     className={css.basketOK}
        //     aria-label="BasketRootFrame"
        // >
        <div
            className={css.basket}
        >
            {children}
        </div>
        // </div>
    );
};

/*
      const basketClasses = classNames({
            basket: true, // ДА
            'basket--scrollable': !isEmptyBasket,
            entered: transitionState === 'entered',
            'without-header': hiddenElements.backstep && customizationCommentShowingState !== 'opened',
            'basket-shifter': true,
            'is-shifted': isShifted,
        });


  <div styleName="basket-overlay-wrapper">
                <div styleName={basketClasses}>

*/
