/* eslint-disable camelcase */
import { useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import apolloClient from 'app/apollo/client';

import { errorService } from 'global/services';

import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import MENU_DISHES_QUERY from 'app/graphql/network/menuDishes';
import { useSelectedFilters } from 'app/connect/connectToSelectedFilters';
import { CONTRY_SUBDIVISION_FRAGMENT } from 'app/graphql/network/fragments/address.fragment';
import { allDishesListVar } from 'app/apollo/reaction';

import {
    DEFAULT_CATEGORIES,
} from 'app/const/categories';

import {
    selectDishes as selectDishesByFilter,
    getDishesFromBasketSections,
} from 'app/utils/dish';
import { useBasketQueryLocate } from 'app/hooks/useBasketQueryLocate';
import { ONE_DAYS_IN_UTC_FORMAT } from './contexts/menuDates.consts';

// Ставит X + Day в зависимости от города BR-1019
const getCityCtxDateExtender = () => {
    const addressSession = apolloClient.readFragment({
        id: 'address:session_address', fragment: CONTRY_SUBDIVISION_FRAGMENT,
    });
    if (!addressSession) {
        return 0;
    }
    const { country_subdivision } = addressSession;
    return ['RU-MOS', 'RU-MOW'].includes(country_subdivision) ? 0 : ONE_DAYS_IN_UTC_FORMAT;
};

function getDishesQueryVariables(selectedFilters, menuFilter) {
    const { selectedPeriod } = selectedFilters;
    const period = menuFilter.periods.find((p) => p.start === selectedPeriod);
    return {
        period: selectedPeriod,
        periodObj: period,
        // type: parseMenuType(window.location),
        tags: period?.tags?.map((tag) => tag.id) || [],
    };
}

function selectMenuDishes(allDishes, basketDishes, tags, categoryIds, eeAvailableDates, dateFilterStatus) {
    const basketDishIds = basketDishes.map((dish) => dish.id);

    // BR-1104
    const allDishesWithoutCustom = allDishes.filter((dish) => {
        const isInBasket = basketDishes.filter((basketDish) => (
            basketDish.parentId === dish.parentId && basketDish.secondDishPart === dish.secondDishPart
        ));
        return !isInBasket.length;
    });

    const nonBasketDishes = allDishesWithoutCustom.filter((dish) => {
        const isDishSavedInBasket = basketDishIds.includes(dish.id);
        return !isDishSavedInBasket;
    });

    const cityCtxDateExtender = getCityCtxDateExtender();

    return {
        menuDishes: selectDishesByFilter(
            nonBasketDishes,
            null,
            tags,
            categoryIds,
            basketDishes,
            eeAvailableDates,
            dateFilterStatus,
            cityCtxDateExtender,
        ),
        filtredDishes: selectDishesByFilter(allDishes, null, tags, categoryIds),
        allDishes: nonBasketDishes,
    };
}


const emptySections = [];
export default function MenuDishesProvider(
    {
        render,
        menuFilter,
        selectedPeriod,
        setDishesUpdatingState = null,
        fetchPolicy = 'cache-first',
    },
) {
    const [basketQuery, _] = useBasketQueryLocate();
    const selectedFilters = useSelectedFilters();

    const sections = basketQuery?.cart?.sections ?? emptySections;

    // TODO: костыль для корзины доставки
    const filters = selectedPeriod
        ? { selectedPeriod, selectedCategories: DEFAULT_CATEGORIES, selectedTags: [] }
        : selectedFilters;

    const dishesQueryVariables = getDishesQueryVariables(filters, menuFilter);

    useEffect(() => {
        if (!dishesQueryVariables.period) {
            errorService.log({
                source: 'client',
                text: 'Menu dishes period is empty',
                scope: 'MenuDishesProvider.js',
                error: 'Menu dishes period is empty',
                dishesQueryVariables,
            });
        }
    }, [dishesQueryVariables.period]); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        loading,
        data: currentData,
        previousData,
        refetch,
    } = useQuery(
        MENU_DISHES_QUERY,
        {
            variables: dishesQueryVariables,
            fetchPolicy,
            skip: !dishesQueryVariables.periodObj,
            context: {
                message: 'root:init:MenuDishesProvider',
            },
            onCompleted: ({ menuDishes }) => {
                allDishesListVar(menuDishes);
            },
        },
    );
    const data = currentData || previousData;

    useEffect(() => {
        if (!setDishesUpdatingState) return;

        if (loading) {
            setDishesUpdatingState(true);
        } else {
            /**
            * таймаут добавлен для того, чтобы MenuCategoryList успех сохранить себе в state новые menuDishes
            * TODO: переделать MenuCategoryList
            */
            setTimeout(() => {
                setDishesUpdatingState(false);
            }, 700);
        }
    }, [loading, setDishesUpdatingState]);

    const { periodObj: period } = dishesQueryVariables;

    const rawDishes = (data && data.menuDishes) ? data.menuDishes : [];

    const dishes = rawDishes.filter((d) => {
        const dishTagIds = d.tags ? d.tags.map((t) => t.id) : [];
        if (!period) return false;

        if (period.excludeTags) {
            const dishHasExcludeTags = dishTagIds.find((dishTag) => period.excludeTags.includes(dishTag));
            return !dishHasExcludeTags;
        }
        if (period.includeTagsOnly) {
            const dishHasIncludeTags = dishTagIds.find((dishTag) => period.includeTagsOnly.includes(dishTag));
            return dishHasIncludeTags;
        }

        // TODO: Halloween dish / Убрать !!!!!
        if (d.id === '4707') {
            return false;
        }

        return true;
    });

    const {
        state: {
            eeAvailableDates,
            dateFilterStatus,
            filterPeriod: menuDatesStateFilterPeriod,
        },
    } = useContext(menuDatesState);

    const basketDishes = useMemo(() => getDishesFromBasketSections(sections), [sections]);
    const { selectedTags, selectedCategories } = filters;

    const {
        menuDishes,
        allDishes,
        filtredDishes,
    } = useMemo(() => {
        let responseDishes;
        if (menuDatesStateFilterPeriod !== filters.selectedPeriod) {
            responseDishes = selectMenuDishes(dishes, basketDishes, selectedTags, selectedCategories);
        } else {
            responseDishes = selectMenuDishes(
                dishes, basketDishes, selectedTags, selectedCategories, eeAvailableDates, dateFilterStatus,
            );
        }
        return responseDishes;
    }, [
        dishes,
        basketDishes,
        selectedTags,
        selectedCategories,
        eeAvailableDates,
        dateFilterStatus,
        menuDatesStateFilterPeriod,
        filters.selectedPeriod,
    ]);

    const menuDishesQuery = {
        loading,
        menuDishes,
        allDishes,
        basketDishes,
        filtredDishes,
        refetch,
    };
    return render({
        menuDishesQuery,
        selectedFilters,
        hasDataForRenderDishes: dishes.length > 0,
    });
}

MenuDishesProvider.propTypes = {
    render: PropTypes.func.isRequired,
    setDishesUpdatingState: PropTypes.func,

    menuFilter: PropTypes.shape({
        periods: PropTypes.arrayOf(PropTypes.shape({
            start: PropTypes.string.isRequired,
            tags: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.string.isRequired,
            })).isRequired,
        })).isRequired,
    }).isRequired,

    fetchPolicy: PropTypes.string,
};
