import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { analyticService } from 'global/services';
import { CATEGORY_SOUPS, CATEGORY_ANALYTICS_TITLES } from 'app/const/categories';
import { DishesSelectionContext } from 'app/containers/contexts/dishesSelection.context';
import { ICategory } from '../../types';
import CategoryMinDurationShieldXS from '../../assets/shields/durationCookingShieldXS.component.svg';
import CategoryMinDurationShieldMedium from '../../assets/shields/durationCookingShieldMedium.component.svg';
import css from './BasketCategoryCard.module.scss';

const BasketCategoryCard: FC<ICategory> = (props: ICategory) => {
    const { id, title, iconPath } = props;
    const { onOpenSelection } = useContext<any>(DishesSelectionContext);

    const openCategoryClick = () => {
        onOpenSelection({ tag: id, targetRealm: 'new_upsell' });
        analyticService.push({
            eventName: 'Upsell_Click',
            upsell_type: 'categories',
            // @ts-ignore
            banner_name: CATEGORY_ANALYTICS_TITLES[id],
        });
    };

    const fullIconPath = `${require(`../../assets/categories/${iconPath}.png`)}`;
    const cardWithShield = id === CATEGORY_SOUPS;
    const cardStyles = classNames({
        [css.newUpsellCategoryRoot]: true,
        [css.cardWithShield]: cardWithShield,
    });
    return (
        <div
            className={cardStyles}
            onClick={openCategoryClick}
            role="none"
        >
            <div className={css.newUpsellCategoryTitlesWrapper}>
                <span className={css.newUpsellCategoryTitle}>{title}</span>
            </div>
            <div className={css.newUpsellCategoryIconContainer}>
                <img src={fullIconPath} alt="Иконка категории" />
            </div>
            {cardWithShield && (
                <span className={css.newUpsellCategoryDuration}>
                    <div className={css.newUpsellCategoryDurationXS}>
                        <CategoryMinDurationShieldXS />
                    </div>
                    <div className={css.newUpsellCategoryDurationMedium}>
                        <CategoryMinDurationShieldMedium />
                    </div>
                </span>
            )}
        </div>
    );
};

export default BasketCategoryCard;
