import Arrow from './Arrow';
import Button from './Button';
import ButtonV2 from './Button/ButtonV2';
import ButtonBottomArea from './ButtonBottomArea';
import ClosePopup from './ClosePopup';
import Loading from './Loading';
import Heading from './Heading';
import Paragraph from './Paragraph';
import FiltersButton from './FiltersButton';
import Separator from './Separator';
import TextLine from './TextLine';
import Rub from './Rub';
import Badge from './Badge';
import { Tag, DeliveryTag } from './Tag';
import Banner from './Banners/Banner.ui';
import UICard from './Cards/Cards.ui';
import ToggleSwitch from './ToggleSwitch';
import UIInput from './input';
import UISelect from './UISelect/UISelect';


import { UIInputPhone } from './InputPhone/InputPhone';
import { UIRadioIcon, UIRadioIconV2 } from './UIRadioIcon';


export {
    ToggleSwitch as UIToggleSwitch,
    Button as UIButton,
    ButtonBottomArea as UIButtonBottomArea,
    ClosePopup as UIClosePopup,
    Loading as UILoading,
    Heading as UIHeading,
    Paragraph as UIParagraph,
    FiltersButton as UIFiltersButton,
    Separator as UISeparator,
    TextLine as UITextLine,
    Rub as UIRub,
    Badge as UIBadge,
    Banner as UIBanner,
    UICard,
    Tag as UITag,
    DeliveryTag as UIDeliveryTag,
    UIInput,
    UISelect,
    UIRadioIcon,
    Arrow as UIArrow,
    UIInputPhone,
    // UI KIT V2 RESPONSIVE
    UIRadioIconV2,
    ButtonV2 as UIButtonV2,

};


const UI = {
    Arrow,
    Button,
    ButtonV2,
    ButtonBottomArea,
    ClosePopup,
    Loading,
    Heading,
    FiltersButton,
    Paragraph,
    Separator,
    TextLine,
    Rub,
    Badge,
    Tag,
    DeliveryTag,
    UISelect,
    UIRadioIcon,
    UIInputPhone,
    UIRadioIconV2,
};

export default UI;
