/* eslint-disable no-underscore-dangle */
import { useEffect, useRef } from 'react';
// import Typograf from 'typograf';

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}


/**
 * @description Хук нужен для работы с временным состоянием.
 * Нужен чтобы передавать параметры между функцияя не вызываю ререндер
 * @param {any} initialState
 * @returns функции accessor'ы
 */
export const useMutableState = (initialState) => {
    let _state = initialState;

    const _setState = (value) => {
        _state = value;
    };
    const _getState = () => _state;

    return [_setState, _getState];
};


export default {
    usePrevious,
};

