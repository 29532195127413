import React from 'react';

export const locales = {
    ru: {
        // Render
        applyButtonText: <>Показать блюда:&nbsp;</>,

        // renderHeader
        buttonText: 'очистить',

    },
    en: {
        // Render
        applyButtonText: <>View dishes:&nbsp;</>,

        // renderHeader
        buttonText: 'Clear',
    },
};
