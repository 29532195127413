/* eslint-disable arrow-body-style */
import React, { useCallback } from 'react';
import {
    cartType,
    discountConditionsType,
} from 'types';
import {
    string, shape, func, bool,
} from 'prop-types';
import { BasketCheck } from '../../../../../Basket/components/BasketCheck/BasketCheck';
import './stepPaycheckSection.scss';

const StepPayCheckSection = (props) => {
    const {
        promocodeConditions,
        applyPromocodeHandler,
        cart,
        cart: {
            totals,
        },
        subscriptionType,
        onClickSubscriptionInfoOpenWithContext,
        handleOpenDeliveryPricePopup,
        hideRecurrent,
        subdivision,
    } = props;

    const hiddenElements = {
        payment_button_recurrent: false,
        payment_button_online: false,
    };

    const applyPromocode = useCallback((nextPromocode) => {
        return applyPromocodeHandler({ nextPromocode });
    }, [applyPromocodeHandler]);

    const handleOpenDeliveryPricePopupWithContext = useCallback((e) => {
        handleOpenDeliveryPricePopup(e, 'step-by-step-checkout');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div styleName="step-payment-check-section-root">
            <BasketCheck
                locale="ru"
                totals={totals}
                promocodeConditions={promocodeConditions}
                applyPromocodeHandler={applyPromocode}
                basket={cart}
                hiddenElements={hiddenElements}
                renderContext="stepByStepCheckout"
                subscriptionType={subscriptionType}
                onClickSubscriptionInfoOpen={onClickSubscriptionInfoOpenWithContext}
                onClickDeliveryPrice={handleOpenDeliveryPricePopupWithContext}
                onToggleSubscription={() => {}}
                basketDishUiStateDispatch={() => {}}
                isWelcomeDiscountShown={false}
                hideRecurrent={hideRecurrent}
                subdivision={subdivision}
            />
        </div>
    );
};

StepPayCheckSection.propTypes = {
    cart: shape(cartType).isRequired,
    promocodeConditions: shape(discountConditionsType).isRequired,
    applyPromocodeHandler: func.isRequired,
    subscriptionType: string.isRequired,
    onClickSubscriptionInfoOpenWithContext: func.isRequired,
    handleOpenDeliveryPricePopup: func.isRequired,
    hideRecurrent: bool.isRequired,
    subdivision: string.isRequired,
};

export default React.memo(StepPayCheckSection);
