import { LOCATION_MSK, LOCATION_SPB, LOCATION_NIZ } from 'app/const/location';
// import { isNewbie } from 'app/utils/user/user';
import { defaultLink } from 'app/const/links/const';

const EE_MAIN = {
    ...defaultLink,

    id: 'EE_MAIN',
    hrefFullRu: 'https://elementaree.ru/',
    hrefFullEn: 'https://elementaree.ru/eng/',
    hrefRelRu: '/',
    hrefRelEn: '/eng/',
};

const LEARN_PAGE = {
    ...defaultLink,

    id: 'LEARN_PAGE',
    hrefRu: '/learn/',
    hrefEn: '/eng/learn/',
    subdivisions: [LOCATION_MSK, LOCATION_SPB, LOCATION_NIZ],
};

const TRIAL_FIRST_ORDER_PAGE = {
    ...defaultLink,

    id: 'TRIAL_FIRST_ORDER_PAGE',
    check: ({ user }) => user?.isNewbie,
    /**
     * Для 5x2 лендингов существует множество ссылок,
     * которые отличаются последней буквой: 5x2a, 5x2b, ... (build.bash)
     * Они используются бизнесом и аналитикой
     */
    hrefRu: '/5x2a/',
    hrefEn: '/eng/5x2/',
    hrefYoudrive: '/youdrive/', // копия 5x2 с другим адресом
    subdivisions: [LOCATION_MSK, LOCATION_SPB, LOCATION_NIZ],
};

const PREMIUM_PAGE = {
    ...defaultLink,

    id: 'PREMIUM_PAGE',
    hrefRu: '/3x2/',
    hrefEn: '/eng/3x2/',
};

const LITE_PAGE = {
    ...defaultLink,

    id: 'LITE_PAGE',
    hrefRu: '/lite/',
    hrefEn: '/eng/lite/',
    subdivisions: [LOCATION_MSK, LOCATION_SPB, LOCATION_NIZ],
    datesType: 'eeAvailableDates',
    trackId: 'lite',
};

const HEALTH_PAGE = {
    ...defaultLink,

    id: 'HEALTH_PAGE',
    hrefRu: '/health/',
    hrefEn: '/eng/health/', // английской версии нет
    subdivisions: [LOCATION_MSK, LOCATION_SPB, LOCATION_NIZ],
    datesType: 'eeAvailableDates',
};

const NEW_YEAR_PAGE = {
    ...defaultLink,

    id: 'NEW_YEAR_PAGE',

    hrefRu: '/blyuda-dlya-novogodnego-stola/',
    hrefEn: '/eng/new-year/',
    hrefSpbRu: '/blyuda-dlya-novogodnego-stola-spb/',

    discountPercent: '5',
};

const BOX_PAGE = {
    ...defaultLink,

    id: 'BOX_PAGE',
    trackId: 'box',
    // скрытие ссылки в предновогодний и новогодний циклы
    // check: ({ selectedPeriod }) => !NY_PERIODS.includes(selectedPeriod),
    hrefRu: '/box/',
    hrefEn: '/eng/box/',
    originalPrice: '10 000',

    datesType: 'zapasAvailableDates',
};

const NZ_PAGE = {
    ...defaultLink,
    id: 'NZ_PAGE',
    trackId: 'zapas',
    hrefRu: '/nz/',
    hrefEn: '/eng/nz/',
    subdivisions: [LOCATION_MSK, LOCATION_SPB, LOCATION_NIZ],
};

const HOME_RESTAURANT_PAGE = {
    ...defaultLink,

    id: 'HOME_RESTAURANT_PAGE',
    check: ({ user }) => user?.isNewbie,

    hrefRu: '/rest/',
    hrefEn: '/eng/rest/',
    discountPrice: '1 990',

    subdivisions: [LOCATION_MSK, LOCATION_SPB, LOCATION_NIZ],
};

const FAST_PAGE = {
    ...defaultLink,

    id: 'FAST_PAGE',
    trackId: 'fasting',
    href: '/fast/',
    hrefRu: '/fast/',
    hrefEn: '/fast/',
    subdivisions: [LOCATION_MSK, LOCATION_SPB, LOCATION_NIZ],
    datesType: 'eeAvailableDates',
};

const SPEED_PAGE = {
    ...defaultLink,

    id: 'SPEED_PAGE',
    trackId: 'speed',
    href: '/speed/',
    hrefRu: '/speed/',
    hrefEn: '/speed/',
    subdivisions: [LOCATION_MSK, LOCATION_SPB, LOCATION_NIZ],
    datesType: 'eeAvailableDates',
};

const VEG_PAGE = {
    ...defaultLink,

    id: 'FAST_PAGE',
    trackId: 'vegetarian',
    hrefRu: '/vegetarian/',
    hrefEn: '/vegetarian/',
    subdivisions: [LOCATION_MSK, LOCATION_SPB, LOCATION_NIZ],
    datesType: 'eeAvailableDates',
};

const SWEET_PAGE = {
    ...defaultLink,

    id: 'SWEET_PAGE',
    trackId: 'sweet',
    hrefRu: '/sweet/',
    hrefEn: '/sweet/',
    subdivisions: [LOCATION_MSK, LOCATION_SPB, LOCATION_NIZ],
    datesType: 'eeAvailableDates',
};
const ROMANTIC_PAGE = {
    ...defaultLink,

    id: 'ROMANTIC_PAGE',
    trackId: 'romantic',
    hrefRu: '/romantic/',
    hrefEn: '/romantic/',
    subdivisions: [LOCATION_MSK, LOCATION_SPB, LOCATION_NIZ],
    datesType: 'eeAvailableDates',
};


/* SEO PAGE LINKS */

const SOUP_PAGE = {
    ...defaultLink,

    id: 'SOUP_PAGE',
    trackId: 'soups',
    hrefRu: '/produkty-dlya-supa/',
    datesType: 'eeAvailableDates',
};

const BREAKFAST_PAGE = {
    ...defaultLink,

    id: 'BREAKFAST_PAGE',
    trackId: 'breakfasts',
    hrefRu: '/produkty-dlya-zavtraka/',
    datesType: 'eeAvailableDates',
};

const CHRISTMAS_PAGE = {
    ...defaultLink,

    id: 'CHRISTMAS_PAGE',
    // trackId: 'christmas',
    // hrefRu: '/christmas/',
    trackId: 'old_ny',
    hrefRu: '/old_ny/',
    datesType: 'eeAvailableDates',
};

const GREEN_PAGE = {
    ...defaultLink,

    id: 'GREEN_PAGE',
    trackId: 'green',
    href: '/green/',
    hrefRu: '/green/',
    datesType: 'eeAvailableDates',
};

const KIDS_PAGE = {
    ...defaultLink,

    id: 'KIDS_PAGE',
    trackId: 'kids',
    href: '/kids/',
    hrefRu: '/kids/',
    datesType: 'eeAvailableDates',
};

const GUESTS_PAGE = {
    ...defaultLink,

    id: 'GUESTS_PAGE',
    trackId: 'guests',
    href: '/guests/',
    hrefRu: '/guests/',
    datesType: 'eeAvailableDates',
};

const HITS_PAGE = {
    ...defaultLink,

    id: 'HITS_PAGE',
    trackId: 'guests',
    href: '/hits/',
    hrefRu: '/hits/',
    datesType: 'eeAvailableDates',
};

const CREATIVITY_PAGE = {
    ...defaultLink,

    id: 'CREATIVITY_PAGE',
    trackId: 'guests',
    href: '/creativity/',
    hrefRu: '/creativity/',
    datesType: 'eeAvailableDates',
};


const DACHA_PAGE = {
    ...defaultLink,

    id: 'DACHA_PAGE',
    trackId: 'dacha',
    href: '/dacha/',
    hrefRu: '/dacha/',
    datesType: 'eeAvailableDates',
};


export default {
    EE_MAIN,
    BOX_PAGE,
    HEALTH_PAGE,
    HOME_RESTAURANT_PAGE,
    LEARN_PAGE,
    LITE_PAGE,
    NEW_YEAR_PAGE,
    NZ_PAGE,
    PREMIUM_PAGE,
    TRIAL_FIRST_ORDER_PAGE,
    SWEET_PAGE,
    FAST_PAGE,
    VEG_PAGE,
    SOUP_PAGE,
    BREAKFAST_PAGE,
    CHRISTMAS_PAGE,
    ROMANTIC_PAGE,
    GREEN_PAGE,
    KIDS_PAGE,
    GUESTS_PAGE,
    HITS_PAGE,
    CREATIVITY_PAGE,
    SPEED_PAGE,
    DACHA_PAGE,
};


/*
 * DEPRECATED

const NY_PAGE = {
    ...defaultLink,

    id: 'NY_PAGE',
    trackId: 'blyuda-dlya-novogodnego-stola',
    hrefRu: '/blyuda-dlya-novogodnego-stola/',
};

const NY_SWEETS_PAGE = {
    ...defaultLink,

    id: 'NY_SWEETS_PAGE',
    trackId: 'ny-sweets',
    hrefRu: '/ny-sweets/',
};

const HP_PAGE = {
    ...defaultLink,

    id: 'HP_PAGE',
    trackId: 'hp',
    hrefRu: '/hp/',
    // datesType: 'eeAvailableDates',
};

const GIFT_PAGE = {
    ...defaultLink,

    id: 'GIFT_PAGE',
    trackId: 'gift',
    hrefRu: '/gift/',
};

const CHERNOGOLOVKA_PAGE = {
    ...defaultLink,

    id: 'CHERNOGOLOVKA_PAGE',
    hrefRu: '/chernogolovka/',
    subdivisions: [LOCATION_MSK, LOCATION_SPB],
};

const VEGS_PAGE = {
    ...defaultLink,

    id: 'VEGS_PAGE',
    hrefRu: '/vegs/',
    hrefEn: '/eng/vegs/', // английской версии нет
    subdivisions: [LOCATION_MSK, LOCATION_SPB],
    datesType: 'eeAvailableDates',
};

const SPECIAL_PAGE = {
    ...defaultLink,

    id: 'SPECIAL_PAGE',
    trackId: 'special',
    hrefRu: '/special/',
    hrefEn: '/special/',
    subdivisions: [LOCATION_MSK, LOCATION_SPB],
};

const MASLENITSA_PAGE = {
    ...defaultLink,

    id: 'MASLENITSA_PAGE',
    trackId: 'maslenitsa',
    hrefRu: '/maslenitsa/',
    hrefEn: '/maslenitsa/',
    subdivisions: [LOCATION_MSK, LOCATION_SPB],
    datesType: 'eeAvailableDates',
};

const DESSERT_BAR_PAGE = {
    ...defaultLink,

    id: 'DESSERT_BAR_PAGE',
    hrefRu: '/dessertbar/',
    hrefEn: '/eng/dessertbar/',
    subdivisions: [LOCATION_MSK],
};

const MARCH_PAGE = {
    ...defaultLink,
    id: 'MARCH_PAGE',
    trackId: 'march',
    hrefRu: '/march/',
    hrefEn: '/march/',
    subdivisions: [LOCATION_MSK, LOCATION_SPB],
    datesType: 'eeAvailableDates',
};

const EASTERBOX_PAGE = {
    ...defaultLink,

    id: 'EASTERBOX_PAGE',
    trackId: 'easterbox',
    hrefRu: '/easterbox/',
    datesType: 'eeAvailableDates',
};

*/
