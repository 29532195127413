import GET_SPECIFIC_INTERVALS_FRAGMENT from '../graphql/getSpecificIntervals.fragment.graphql';
import { calculateEnabledDatesByIntervalsAvailability } from '../utils/deliveryIntervals.utils';

export const allDatesField = {
    read(_, client) {
        const { cache, readField, variables } = client;

        const available = readField('available_dates') || [];

        const intervalsId = variables?.delivery_id ?? 'user-session';
        const currentIntervalsData = cache.readFragment({
            id: `intervals:${intervalsId}`,
            fragment: GET_SPECIFIC_INTERVALS_FRAGMENT,
        });

        const mappedDates = calculateEnabledDatesByIntervalsAvailability(available, currentIntervalsData);

        return mappedDates;
    },
};
