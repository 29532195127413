import React from 'react';
import PropTypes from 'prop-types';

import { formatDeliveryDateMobile, getDateData } from 'app/utils/date';

import '../../single-step-delivery-info-area.scss';


export function SingleStepDatetimeRow({
    locale,
    selectDay = '',
    selectTime: { timeIndex },
    timeIntervals,
}) {
    const activeShowTime = timeIntervals[timeIndex];

    if (!selectDay || !activeShowTime) {
        return (
            <div>
                <span styleName="single-step-checkout__text-primary"> </span>
            </div>
        );
    }

    const {
        day, weekday, shortMonth, fullMonth,
    } = formatDeliveryDateMobile(selectDay, locale);
    const month = fullMonth.length <= 4 ? fullMonth : shortMonth;

    const capitalizedWeekday = `${weekday[0].toUpperCase()}${weekday.slice(1)}`;
    const dayTitle = `${capitalizedWeekday}, ${day} ${month}`;
    const timeTitle = `${activeShowTime.start}-${activeShowTime.end}`;

    return (
        <div>
            <span styleName="single-step-checkout__text-primary">
                {dayTitle}
                {', '}
                {timeTitle}
            </span>
        </div>
    );
}

SingleStepDatetimeRow.propTypes = {
    locale: PropTypes.string.isRequired,
    selectDay: PropTypes.string,
    selectTime: PropTypes.shape({
        timePeriod: PropTypes.string.isRequired,
        timeIndex: PropTypes.number.isRequired,
    }).isRequired,
    timeIntervals: PropTypes.arrayOf(PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
    })).isRequired,
};
