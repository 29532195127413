/* eslint-disable camelcase */
/*
    События аналитики нового ЛК (BR-1084),
    https://docs.google.com/spreadsheets/d/10ZZN8caPV-y9d0Xnvddjy27hPR9CBp4fnPtUbse0TcM/edit#gid=578518881

*/
/**
 * @description События страницы бонусы и промо
 * @url profile/me/
*/
const V2ProfileBonusAndPromoEventsRecord = [
    {
        eventName: 'LK_Promo_Bonus_Review',
        description: 'ЛК Промо - раздел "Бонусы" - клик на "Оставь отзыв"',
        meta: ['click'],
        scope: ['profile', 'bonuses'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Promo_Bonus_Review',
            };
        },
    },
    {
        eventName: 'LK_Promo_Bonus_Invite',
        description: 'ЛК Промо - раздел "Бонусы" - клик на "Пригласи друга"',
        meta: ['click'],
        scope: ['profile', 'bonuses'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Promo_Bonus_Invite',
            };
        },
    },
    {
        eventName: 'LK_Promo_Bonus_Review_Copy',
        description: 'ЛК Промо - раздел "Бонусы" - "Оставь отзыв" - клик на копировать',
        meta: ['click'],
        scope: ['profile', 'bonuses'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Promo_Bonus_Review_Copy',
            };
        },
    },
    {
        eventName: 'LK_Promo_Bonus_Review_Otzovik',
        description: 'ЛК Промо - раздел "Бонусы" - "Оставь отзыв" - клик на отзовик',
        meta: ['click'],
        scope: ['profile', 'bonuses'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Promo_Bonus_Review_Otzovik',
            };
        },
    },
    {
        eventName: 'LK_Promo_Bonus_Review_Irecommend',
        description: 'ЛК Промо - раздел "Бонусы" - "Оставь отзыв" - клик на irecommend',
        meta: ['click'],
        scope: ['profile', 'bonuses'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Promo_Bonus_Review_Irecommend',
            };
        },
    },
    {
        eventName: 'LK_Promo_Bonus_Review_TG',
        description: 'ЛК Промо - раздел "Бонусы" - "Оставь отзыв" - клик на ТГ',
        meta: ['click'],
        scope: ['profile', 'bonuses'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Promo_Bonus_Review_TG',
            };
        },
    },
    {
        eventName: 'LK_Promo_Bonus_Review_VK',
        description: 'ЛК Промо - раздел "Бонусы" - "Оставь отзыв" - клик на ВК',
        meta: ['click'],
        scope: ['profile', 'bonuses'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Promo_Bonus_Review_VK',
            };
        },
    },
    {
        eventName: 'LK_Promo_Bonus_Invite_Copy',
        description: 'ЛК Промо - раздел "Бонусы" - "Пригласи друга" - клик на копировать',
        meta: ['click'],
        scope: ['profile', 'bonuses'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Promo_Bonus_Invite_Copy',
            };
        },
    },
];
/**
 * @description События страницы редактированию данных пользователя
 * @url profile/me/
*/
const V2ProfileUserinfoEventsRecord = [
    {
        eventName: 'LK_Profile_Personal_Click',
        description: 'ЛК Профиль - клик на верхнюю строчку (где имя или "Как вас зовут"',
        meta: ['click'],
        scope: ['profile', 'userinfo'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Profile_Personal_Click',
            };
        },
    },
    {
        eventName: 'LK_Profile_Personal_Save',
        description: 'ЛК Профиль - сохранить раздел персональной информации',
        meta: ['click'],
        scope: ['profile', 'userinfo'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Profile_Personal_Save',
            };
        },
    },
    {
        eventName: 'LK_Profile_Personal_Close',
        description: 'ЛК Профиль - закрыть раздел персональной информации',
        meta: ['click'],
        scope: ['profile', 'userinfo'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Profile_Personal_Close',
            };
        },
    },
    {
        eventName: 'LK_Profile_VK_Click',
        description: 'ЛК Профиль - клик на ВК',
        meta: ['click'],
        scope: ['profile', 'userinfo'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Profile_VK_Click',
            };
        },
    },
    {
        eventName: 'LK_Profile_VK_Popup_Open',
        description: 'ЛК Профиль - поп-ап ВК - открытие',
        meta: ['click'],
        scope: ['profile', 'userinfo'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Profile_VK_Popup_Open',
            };
        },
    },
    {
        eventName: 'LK_Profile_VK_Popup_CTA',
        description: 'ЛК Профиль - поп-ап ВК - клик на "Перейти"',
        meta: ['click'],
        scope: ['profile', 'userinfo'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Profile_VK_Popup_CTA',
            };
        },
    },
    {
        eventName: 'LK_Profile_VK_Popup_Close',
        description: 'ЛК Профиль - поп-ап ВК - закрытие',
        meta: ['click'],
        scope: ['profile', 'userinfo'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Profile_VK_Popup_Close',
            };
        },
    },
    {
        eventName: 'LK_Profile_TG_Click',
        description: 'ЛК Профиль - клик на TG',
        meta: ['click'],
        scope: ['profile', 'userinfo'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Profile_TG_Click',
            };
        },
    },
    {
        eventName: 'LK_Profile_TG_Popup_Open',
        description: 'ЛК Профиль - поп-ап TG - открытие',
        meta: ['click'],
        scope: ['profile', 'userinfo'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Profile_TG_Popup_Open',
            };
        },
    },
    {
        eventName: 'LK_Profile_TG_Popup_CTA',
        description: 'ЛК Профиль - поп-ап TG - клик на "Перейти"',
        meta: ['click'],
        scope: ['profile', 'userinfo'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Profile_TG_Popup_CTA',
            };
        },
    },
    {
        eventName: 'LK_Profile_TG_Popup_Close',
        description: 'ЛК Профиль - поп-ап TG - закрытие',
        meta: ['click'],
        scope: ['profile', 'userinfo'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Profile_TG_Popup_Close',
            };
        },
    },
    {
        eventName: 'LK_Profile_Select_Ads',
        description: 'ЛК Профиль - включить свитч "Получать рекламные рассылки"',
        meta: ['click'],
        scope: ['profile', 'userinfo'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Profile_Select_Ads',
            };
        },
    },
    {
        eventName: 'LK_Profile_Unselect_Ads',
        description: 'ЛК Профиль - выключить свитч "Получать рекламные рассылки"',
        meta: ['click'],
        scope: ['profile', 'userinfo'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Profile_Unselect_Ads',
            };
        },
    },
    {
        eventName: 'LK_Profile_Support',
        description: 'ЛК Профиль - клик на "Служба поддержки"',
        meta: ['click'],
        scope: ['profile', 'userinfo'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Profile_Support',
            };
        },
    },
    {
        eventName: 'LK_Profile_Delete',
        description: 'ЛК Профиль - клик на "Удалить аккаунт"',
        meta: ['click'],
        scope: ['profile', 'userinfo'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Profile_Delete',
            };
        },
    },
    {
        eventName: 'LK_Profile_Delete_Confirm',
        description: 'ЛК Профиль - поп-ап удаления аккаунта - клик на "Точно удалить"',
        meta: ['click'],
        scope: ['profile', 'userinfo'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Profile_Delete_Confirm',
            };
        },
    },
];

/**
 * @description
 * @url
*/
const V2ProfiledeliveriesEventsRecord = [
    {
        eventName: 'LK_Open_Payment',
        description: 'ЛК Доставки - открытие раздела оплаты',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Open_Payment',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Save_Payment',
        description: 'ЛК Доставки - клик на "Оплатить" из раздела оплаты',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Save_Payment',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Close_Payment',
        description: 'ЛК Доставки - "назад" из раздела оплаты',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Close_Payment',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Open_Datetime',
        description: 'ЛК Доставки - открытие раздела даты',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Open_Datetime',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Save_Datetime_All_Deliveries',
        description: 'ЛК Доставки - сохранение раздела даты - применить ко всем заказам подписки',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Save_Datetime_All_Deliveries',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Save_Datetime_One_Delivery',
        description: 'ЛК Доставки - сохранение раздела даты - применить к одному заказу подписки (ИЛИ нет рекуррента)',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Save_Datetime_One_Delivery',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Close_Datetime',
        description: 'ЛК Доставки - "назад" из раздела даты',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Close_Datetime',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Open_Address',
        description: 'ЛК Доставки - открытие раздела адреса ',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Open_Address',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Save_Address_All_Deliveries',
        description: 'ЛК Доставки - сохранение раздела адреса - применить ко всем заказам подписки',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Save_Address_All_Deliveries',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Save_Address_One_Delivery',
        description: 'ЛК Доставки - сохранение раздела адреса - применить к одному заказу подписки (ИЛИ нет рекуррента)',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Save_Address_One_Delivery',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Close_Address',
        description: 'ЛК Доставки - "назад" из раздела адреса',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Close_Address',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Open_Rating',
        description: 'ЛК Доставки - клик на "Оценить заказ"',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Open_Rating',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Open_Rating_Again',
        description: 'ЛК Доставки - клик на "Дополнить оценку"',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Open_Rating_Again',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Save_Rating',
        description: 'ЛК Доставки - клик на "Сохранить" в оценке заказа',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Save_Rating',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Close_Rating',
        description: 'ЛК Доставки - клик "назад" в оценке заказа',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Close_Rating',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Open_Card_Active',
        description: 'ЛК Доставки - открытие корзины - можно изменить',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Open_Card_Active',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Save_Card',
        description: 'ЛК Доставки - сохранение корзины',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Save_Card',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Close_Card_Active',
        description: 'ЛК Доставки - "назад" из корзины, которую можно изменить',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Close_Card_Active',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Close_Card_Active',
        description: 'ЛК Доставки - "назад" из корзины, которую можно изменить',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Close_Card_Active',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Open_Card_Inactive',
        description: 'ЛК Доставки - открытие корзины - нельзя изменить',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Open_Card_Inactive',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Close_Card_Inactive',
        description: 'ЛК Доставки - "назад" из корзины, которую нельзя изменить',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Close_Card_Inactive',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Open_Card_Recipes',
        description: 'ЛК Доставки - открытие рецептов (корзины доставленной доставки)',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Open_Card_Recipes',
                delivery_id,
            };
        },
    },
    {
        eventName: 'LK_Close_Card_Recipes',
        description: 'ЛК Доставки - закрытие рецептов (корзины доставленной доставки)',
        meta: ['click'],
        scope: ['profile', 'delivery'],
        check() {
            return true;
        },

        prepare(data) {
            const { delivery_id } = data;
            return {
                event: 'LK_Close_Card_Recipes',
                delivery_id,
            };
        },
    },
];


export const V2ProfileEventsRecord = [
    ...V2ProfiledeliveriesEventsRecord,
    ...V2ProfileBonusAndPromoEventsRecord,
    ...V2ProfileUserinfoEventsRecord,
    {
        eventName: 'LK_Promo_Discount_CTA',
        description: 'ЛК Промо - раздел "Скидка" - клик на кнопку действия ',
        meta: ['click'],
        scope: ['profile', 'bonuses'],
        check() {
            return true;
        },

        prepare(data) {
            const { cj } = data;
            return {
                event: 'LK_Promo_Discount_CTA',
                cj,
            };
        },
    },
    {
        eventName: 'LK_Promo_Bonus_Use_New_Order',
        description: 'ЛК Промо - раздел "Бонусы" - клик на "Оформить новый заказ"',
        meta: ['click'],
        scope: ['profile', 'bonuses'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Promo_Bonus_Use_New_Order',
            };
        },
    },
    {
        eventName: 'LK_Promo_Bonus_Use_Existing_Order',
        description: 'ЛК Промо - раздел "Бонусы" - клик на "Оплатить заказ частично"',
        meta: ['click'],
        scope: ['profile', 'bonuses'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Promo_Bonus_Use_Existing_Order',
            };
        },
    },
    {
        eventName: 'LK_Header_Deliveries',
        description: 'ЛК - клик на "Доставки" в хэдере / бургер-меню',
        meta: ['click'],
        scope: ['profile', 'main'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Header_Deliveries',
            };
        },
    },
    {
        eventName: 'LK_Header_Promo',
        description: 'ЛК - клик на "Промо" в хэдере / бургер-меню',
        meta: ['click'],
        scope: ['profile', 'main'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Header_Promo',
            };
        },
    },
    {
        eventName: 'LK_Header_Profile',
        description: 'ЛК - клик на "Профиль" в хэдере / бургер-меню',
        meta: ['click'],
        scope: ['profile', 'main'],
        check() {
            return true;
        },

        prepare() {
            return {
                event: 'LK_Header_Profile',
            };
        },
    },
];
