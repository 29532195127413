import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { getDateData, formatDateToISO } from 'app/utils/date';

import {
    ONE_DAYS_IN_UTC_FORMAT,
    DIFFS,
} from 'app/containers/contexts/menuDates.consts';
import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import { LocaleContext } from 'app/containers/LocaleProvider';


const generateDateDataFromDiff = (diff, currentDate, locale) => {
    const msecMultiplier = 1000;
    const msDate = currentDate + (diff * msecMultiplier);
    const dateData = getDateData(msDate, locale);
    const isEnLocale = locale === 'en';
    const tomorrow = isEnLocale ? 'Tomorrow' : 'Завтра';

    return {
        xValue: diff,
        day: diff === ONE_DAYS_IN_UTC_FORMAT ? tomorrow : dateData.weekDayShortName,
        date: dateData.date,
        month: dateData.monthNameForDate,
        dateObj: new Date(msDate),
    };
};

const generateDates = (currentDate, locale) => DIFFS.map((diff) => generateDateDataFromDiff(diff, currentDate, locale));


const filterDatesByAvailability = (selectDates, availableDates) => {
    const filteredDates = selectDates.filter((d) => {
        const dateStr = formatDateToISO(d.dateObj);
        return availableDates.includes(dateStr);
    });

    return filteredDates;
};

const filterDates = (selectDates, menuDatesContext) => {
    const {
        firstVisibleDates: {
            allAvailableDates,
        },
    } = menuDatesContext;

    return filterDatesByAvailability(selectDates, allAvailableDates);
};

const WithAvailableDeliveryDates = ({ WrappedComponent, ...forwardedProps }) => {
    const { state: menuDatesContext } = useContext(menuDatesState);
    const { locale } = useContext(LocaleContext);
    const {
        currentDate,
        firstVisibleDates,
    } = menuDatesContext;

    const {
        eeAvailableDates,
        everydayAvailableDates,
    } = firstVisibleDates;

    if (isEmpty(firstVisibleDates)) {
        return null;
    }

    const deliveryDateDataValues = generateDates(currentDate, locale);

    const deliveryDatesFiltered = filterDates(deliveryDateDataValues, menuDatesContext);
    const deliveryDatesEE = filterDatesByAvailability(deliveryDateDataValues, eeAvailableDates);

    const tomorrowData = generateDateDataFromDiff(ONE_DAYS_IN_UTC_FORMAT, currentDate);
    const deliveryDatesEveryday = filterDatesByAvailability(deliveryDateDataValues, everydayAvailableDates);

    return (
        <WrappedComponent
            {...forwardedProps}
            tomorrowDeliveryDate={tomorrowData}
            deliveryDatesEveryday={deliveryDatesEveryday}
            deliveryDates={deliveryDatesFiltered}
            deliveryDatesEE={deliveryDatesEE}
        />
    );
};

const withAvailableDeliveryDates = (WrappedComponent) => (props) => (
    <WithAvailableDeliveryDates {...props} WrappedComponent={WrappedComponent} />
);

export default withAvailableDeliveryDates;


WithAvailableDeliveryDates.propTypes = {
    WrappedComponent: PropTypes.func.isRequired,
};
