/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import { useFragment } from '@apollo/client';

/* TYPES */
import {
    IDeliveryGowfoodDaysRowProps,
    IDeliveryTimeIntervalsProps,
    TDeliveryZoneRecord,
} from './deliveryZoneTimeAndPrice.types';

/* UTILS  */
import { getDeliveryZoneRecord, parsDeliveryZoneId } from './deliveryZoneTimeAndPrice.utils';

/* REQ */
import { DELIVERY_ZONE_REGION_NAME, DELIVERY_ZONE_ADDRESS_PRICE } from './graphql/deliveryZoneTimeAndPrice.graphql';

/* STYLES */
import css from './deliveryZoneTimeAndPrice.module.scss';


function DeliveryPriceRow() {
    const { data: address } = useFragment<any>({
        fragment: DELIVERY_ZONE_ADDRESS_PRICE,
        from: {
            __ref: 'address:session_address',
        },
    });

    const {
        delivery_price_low,
        delivery_price_other,
    } = address;

    const isFreeDeliveryForExpensiveOrders = delivery_price_other === 0;

    return (
        <div className={css.deliveryPriceRow}>
            <div className={css.deliveryPriceRowTitle}>
                {isFreeDeliveryForExpensiveOrders
                    ? 'Бесплатная доставка'
                    : `Доставка ${delivery_price_low + delivery_price_other} ₽`
                }
            </div>
            <div className={css.deliveryPriceRowValue}>
                {delivery_price_low && delivery_price_other && (delivery_price_low + delivery_price_other)}
                &nbsp;
                {' '}
                {`₽ — при заказе ${isFreeDeliveryForExpensiveOrders ? 'от' : 'менее'} 2 400 ₽`}
            </div>
            {!isFreeDeliveryForExpensiveOrders && (
                <div className={css.deliveryPriceRowValue}>ваш адрес в зоне платной доставки</div>
            )}
        </div>
    );
}

function DeliveryGowfoodDaysRow(props: IDeliveryGowfoodDaysRowProps) {
    const {
        growFoodDaysList = [],
    } = props;

    if (!growFoodDaysList.length) {
        return null;
    }

    return (
        <div className={css.deliveryGowfoodDaysRowRoot}>
            <div className={css.deliveryGowfoodDaysRowTitle}>
                Время доставки
            </div>
            <div className={css.deliveryGowfoodDaysRowDays}>
                {growFoodDaysList.map((dayText) => (
                    <div
                        className={css.deliveryGowfoodDaysRowDaysItem}
                        key={dayText}
                    >
                        {dayText}
                    </div>
                ))}
            </div>
        </div>
    );
}

function DeliveryTimeIntervals(props: IDeliveryTimeIntervalsProps) {
    const {
        morningRange = 0,
        eveningRange = 0,
    } = props;
    return (
        <div className={css.deliveryTimeIntervalsRoot}>
            {!!morningRange && (
                <div className={css.deliveryTimeIntervalsSection}>
                    <div className={css.deliveryTimeIntervalsSectionTime}>
                        Утро, 06:00—13:00
                    </div>
                    <div className={css.deliveryTimeIntervalsSectionBorders}>
                        {morningRange}
                        -часовые интервалы доставки
                    </div>
                </div>
            )}
            {!!eveningRange && (
                <div className={css.deliveryTimeIntervalsSection}>
                    <div className={css.deliveryTimeIntervalsSectionTime}>
                        Вечер, 18:00—00:00
                    </div>
                    {eveningRange !== 6 && (
                        <div className={css.deliveryTimeIntervalsSectionBorders}>
                            {eveningRange}
                            -часовые интервалы доставки
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export function DeliveryZoneTimeAndPrice() {
    /* DAC */
    const { data } = useFragment<any>({
        fragment: DELIVERY_ZONE_REGION_NAME,
        from: {
            __ref: 'intervals:user-session',
        },
    }) || { region_name: null };
    const { data: address } = useFragment({
        fragment: DELIVERY_ZONE_ADDRESS_PRICE,
        from: {
            __ref: 'address:session_address',
        },
    }) || { delivery_price_low: null, delivery_price_other: null };

    /* SELECTORS */
    const zoneId = useMemo(
        () => parsDeliveryZoneId(data.region_name),
        [data.region_name],
    );
    const { days, eveningRange, morningRange } = useMemo<TDeliveryZoneRecord>(
        () => getDeliveryZoneRecord(zoneId),
        [zoneId],
    );

    if (address.delivery_price_low === null || address.delivery_price_other === null) {
        return null;
    }

    return (
        <div className={css.deliveryZoneTimeAndPriceRoot}>
            <DeliveryPriceRow />
            <DeliveryGowfoodDaysRow
                growFoodDaysList={days}
            />
            <DeliveryTimeIntervals
                eveningRange={eveningRange}
                morningRange={morningRange}
            />
        </div>
    );
}
