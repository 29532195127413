import { getDialogTextIfWeKnow } from '../CustomizationSourceContent';
import { CustomizationSourceData } from '../customizationSource.types';


export const sessionCommentCustomizationFactory = (
    { viewCustomizationComment }: CustomizationSourceData,
) => {
    const { comment } = viewCustomizationComment;

    const dialogText = getDialogTextIfWeKnow(comment);

    return {
        text: dialogText,
        isThisSource: Boolean(comment),
        case: 'comment customization',
    };
};
