import { gql } from '@apollo/client';
import { fullAddressData } from '../fragments/address.fragment';

export const CREATE_INVOICE = gql`
    mutation createInvoice(
            $phone: String!,
            $email: String,
            $period: String!,
            $delivery_date: String!,
            $delivery_time: timeInterval!,
            $payment_method: paymentMethod!,
            $contactless_delivery: Boolean,
            $tags: [ID!],
            $utm: leadUtm,
            $printed_recipes: Boolean,
            $delivery_region_name: String!
            $with_customization: Boolean
            $apply_comment_to_delivery: Boolean
            $use_in_draw: Boolean
        ) {
        createInvoice(
            phone: $phone,
            email: $email,
            period: $period,
            delivery_date: $delivery_date,
            delivery_time: $delivery_time,
            payment_method: $payment_method,
            contactless_delivery: $contactless_delivery,
            tags: $tags,
            utm: $utm,
            printed_recipes: $printed_recipes,
            delivery_region_name: $delivery_region_name,
            with_customization: $with_customization,
            apply_comment_to_delivery: $apply_comment_to_delivery,
            use_in_draw: $use_in_draw
        ) {
            uid
            price
            cp_account_id
            label
            confirm_state
            isNewbie
        }
    }
`;

export const SHIPPING = gql`
    mutation shipping(
        $period: String!
        $address: AddressInput!
    ) {
        shipping(
            period: $period
            address: $address
        ) {
            success
            subdivision
            address {
                ...fullAddressFragment
            }
        }
    }
    ${fullAddressData}
`;

export const SEND_SMS_FOR_CONFIRM_NEW_ORDER = gql`
    mutation sendSmsForConfirmNewOrder($invoiceUid: String!) {
        sendSmsForConfirmNewOrder(invoiceUid: $invoiceUid) {
            success
            interval
            message
        }
    }
`;

export const CONFIRM_NEW_ORDER_BY_SMS_CODE = gql`
    mutation confirmNewOrderBySmsCode($invoiceUid: String!, $smsCode: String!) {
        confirmNewOrderBySmsCode(invoiceUid: $invoiceUid, smsCode: $smsCode) {
            success
            message
            confirm_state
            isFirstAuth
        }
    }
`;

export const REFRESH_PAYMENT_DATA = gql`
    mutation refreshPaymentData($deliveryId: Int!) {
        refreshPaymentData(deliveryId: $deliveryId) {
            uid
            price
            cp_account_id
        }
    }
`;
