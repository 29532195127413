class UserSession {
    constructor() {
        this.sessionData = {
            fetchMap: [],
            token: '',
            id: '',
        };
    }

    setFetchMap(payload) {
        this.sessionData.fetchMap.push(payload);
    }

    setData(payload) {
        this.sessionData = {
            ...this.sessionData,
            ...payload,
        };
    }

    getData() {
        return this.sessionData;
    }
}


export const userSessionService = new UserSession();

window.usr = userSessionService;
