import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { OverlayContextDispatch } from 'app/containers/contexts/overlay.context';
import { parseCategories, parseDishes } from 'app/utils/basketSchema';


const IntervalDialogContent = () => (
    <>
        Произошла ошибка: в&nbsp;корзине нет&nbsp;блюд
    </>
);

/**
 * @description Диалог для обработки ошибки при создании инвойса, которая возникает, когда корзина пуста
 */
export const useEmptyBasketErrorDialog = () => {
    const { pushDialogOverlay, closeLastOverlays } = useContext(OverlayContextDispatch);

    const history = useHistory();
    const location = useLocation();

    const pushBasketErrorDialog = () => {
        const categories = parseCategories(location);
        const dishes = parseDishes(location);
        const hasBasketSchema = Boolean(categories || dishes);

        const dialogData = {
            notifyOnly: true,
            strongText: <IntervalDialogContent />,
            confirmText: hasBasketSchema ? 'Обновить' : 'Выбрать блюда',
            onConfirm: () => {
                if (hasBasketSchema) {
                    window.location.reload();
                } else {
                    history.push({
                        pathname: '/menu/',
                        search: location.search,
                    });
                    closeLastOverlays();
                }
            },
        };

        pushDialogOverlay('useBasketErrorDialog', dialogData);
    };
    return pushBasketErrorDialog;
};
