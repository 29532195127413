import { gql } from '@apollo/client';

export default gql`
    query previewCartForSet(
        $period: String!,
        $set_type: cartSetType,
    ) {
        previewCartForSet(period: $period, set_type: $set_type){
            __typename
            period
            typeOfSet
            id
            type
            totals {
                discount_amount
                static_common_price
                total_common_price
                discount_applied
            }
            sections {
                id
                cartType
                items {
                    dish_id
                    type
                    portions
                    dish {
                        id
                        title
                        titleEn
                        caption
                        captionEn
                        partnersComment
                        weight
                        price
                        portions
                        previewImage
                        previewMobileImage
                        wideImage
                        visibleStatus
                        availableStatus
                        tags {
                            id
                            erp_id
                            title
                            category_id
                            category_name
                        }
                        category_id
                        parentId
                        nutrition {
                            carbohydrate {
                                title
                                value
                                unit
                            }
                            fat {
                                title
                                value
                                unit
                            }
                            protein {
                                title
                                value
                                unit
                            }
                            calories {
                                title
                                value
                                unit
                            }
                        }
                        composition
                        cooking_time
                        cook_until
                        priority
                        um
                        umAlias
                    }
                    price
                    discount_price
                    promoDiscountPrice
                    is_new_item
                    is_promo
                }
            }
            totals {
                total_common_price
            }
        }
    }
`;
