import {
    periods as nyPeriodStart,
    firstNYPeriodCustomStart,
    firstNYPeriodCustomEnd,
    NYPeriodStart,
    NYPeriodEnd,
    extraPeriodNYStart,
    extraPeriodNYEnd,
} from 'global/const/ny';

import { NY_DISHES, NY_DESSERT } from 'app/utils/filters';
import {
    PAYMENT_PARAM_RECURRENT,
    PAYMENT_PARAM_ONLINE,
    PAYMENT_PARAM_CASH,
    PAYMENT_PARAM_SBP,
} from 'app/const/billing-payment-methods';


const firstNYPeriodCustom = {
    start: firstNYPeriodCustomStart,
    end: firstNYPeriodCustomEnd,
    excludeTags: [NY_DISHES],
    clearAfterSwitchFrom: true,
    hiddenPaymentMethods: [PAYMENT_PARAM_CASH, PAYMENT_PARAM_SBP],
    defaultPaymentMethod: PAYMENT_PARAM_ONLINE,
};

const extraPeriodNY = {
    start: NYPeriodStart,
    end: NYPeriodEnd,
    includeTagsOnly: [NY_DISHES, NY_DESSERT],
    clearAfterSwitchFrom: true,
    hiddenPaymentMethods: [PAYMENT_PARAM_RECURRENT, PAYMENT_PARAM_CASH, PAYMENT_PARAM_SBP],
    defaultPaymentMethod: PAYMENT_PARAM_ONLINE,
    title: {
        ru: 'На новый год',
        en: 'New Year',
    },
};

const extraPeriodCustom = {
    start: extraPeriodNYStart,
    end: extraPeriodNYEnd,
    excludeTags: [NY_DISHES],
    clearAfterSwitchFrom: true,
    hiddenPaymentMethods: [],
    defaultPaymentMethod: PAYMENT_PARAM_ONLINE,
};


export const patchQueryPeriods = (periods) => {
    const nyPeriods = periods.filter((p) => nyPeriodStart.includes(p.start));
    const restPeriods = periods.filter((p) => !nyPeriodStart.includes(p.start));

    if (!nyPeriods.length) return periods;

    const patchedPeriods = [
        ...restPeriods,
        {
            ...firstNYPeriodCustom,
            tags: nyPeriods[0].tags,
            categories: nyPeriods[0].categories,
            isVisibleInFilter: nyPeriods[0].isVisibleInFilter,
        },
        // {
        //     ...extraPeriodNY,
        //     tags: nyPeriods[1].tags,
        //     categories: nyPeriods[1].categories,
        //     isVisibleInFilter: nyPeriods[1].isVisibleInFilter,
        // },
        // {
        //     ...extraPeriodCustom,
        //     tags: nyPeriods[1].tags,
        //     categories: nyPeriods[1].categories,
        //     isVisibleInFilter: nyPeriods[1].isVisibleInFilter,
        // },
    ].sort((p1, p2) => {
        const d1 = new Date(p1.start);
        const d2 = new Date(p2.start);
        return d1 - d2;
    });

    return patchedPeriods;
};
