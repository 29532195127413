import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import queryString from 'query-string';

import { STORE_ENV_DATA } from 'app/graphql/network/envDataMutation';
import { getMobileOperatingSystem, isStandaloneApp } from 'app/utils/browser';


export const EnvDataSaver = (props) => {
    const [storeEnvData] = useMutation(STORE_ENV_DATA);

    const { period } = props;

    useEffect(() => {
        const params = queryString.parse(window.location.search);
        const partnerName = params.partner || null;
        const managerId = params.manager || null;

        const adid = params.adid || null;
        const platform = (() => {
            const os = getMobileOperatingSystem();
            const isApp = isStandaloneApp();
            if (os.android && isApp) return 'Android';
            if (os.ios && isApp) return 'IOS';
            return null;
        })();

        const variables = {
            period,
            partnerName,
            managerId,
            device_platform: platform,
        };

        if (adid) {
            variables.device_adid = adid;
        }

        storeEnvData({ variables });
    }, [period, storeEnvData]);

    return null;
};

EnvDataSaver.propTypes = {
    period: PropTypes.string.isRequired,
};
