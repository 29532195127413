import { isDesktop } from 'app/utils/resolution';

export const checkUnreadyMobileSign = (props, _set, _get) => {
    const { type, needDeepCheck, preparedAreas } = _get();

    switch (true) {
        case (!needDeepCheck):
            break;
        case (isDesktop()):
            break;
        default:
            _set({ type, needDeepCheck: true, preparedAreas });
            break;
    }
};


export const checkABTests = (props, _set, _get) => {
    const { needDeepCheck } = _get();

    switch (true) {
        case (!needDeepCheck):
            break;
        case (isDesktop()):
            break;
        default:
            break;
    }
};
