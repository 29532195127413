/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { LocationContext } from 'app/containers/LocationProvider';
import { UIParagraph } from 'app/components/ui';

import { BasketCheckPrice } from './BasketCheckPrice';

import { locales } from './basket-check.locales';

import './basket-check-rows.scss';

/* UTILS */

const getLocationTitle = (locationKey = 'MSK', locale = 'ru') => {
    const titleLocales = {
        ru: {
            spb: 'по СПб/ЛО',
            msk: 'по Москве/МО',
            niz: 'по Н.Новгороду',
            kzn: 'по Казани',
        },
        en: {
            spb: 'st. Petersburg',
            msk: 'Moscow',
            niz: 'N. Novgorod',
            kzn: 'Kazan',
        },

    };

    switch (true) {
        case ['MSK'].includes(locationKey):
            return titleLocales[locale].msk;
        case ['SPB'].includes(locationKey):
            return titleLocales[locale].spb;
        case ['NIZ'].includes(locationKey):
            return titleLocales[locale].niz;
        case ['KZN'].includes(locationKey):
            return titleLocales[locale].kzn;
        default:
            return titleLocales[locale].msk;
    }
};

/* COMPONENTS */

export const DeliveryPrice = (props) => {
    const {
        basket: { totals: { delivery_price: deliveryPrice } },
        locale,
        onClickDeliveryPrice,
    } = props;

    const l = locales[locale];
    const { country_subdivision, locationKey } = useContext(LocationContext);

    const cityText = useMemo(
        () => getLocationTitle(locationKey, locale),
        [locationKey],
    );

    return (
        <button
            type="button"
            onClick={onClickDeliveryPrice}
            styleName="basket-check__row"
        >
            <div>
                <UIParagraph level="4.2" styleName="basket-check__row-text">
                    <span styleName="link">
                        {l.deliveryPrice}
                        &nbsp;
                        {cityText}
                    </span>
                </UIParagraph>
            </div>
            <div>
                <BasketCheckPrice price={deliveryPrice} />
            </div>
        </button>
    );
};

/* TYPES */

DeliveryPrice.propTypes = {
    basket: PropTypes.shape({
        totals: PropTypes.shape({
            total_price: PropTypes.number.isRequired,
            delivery_price: PropTypes.number.isRequired,
            referral_amount: PropTypes.number.isRequired,
            total_common_price: PropTypes.number.isRequired,
            discount_amount: PropTypes.number.isRequired,
        }).isRequired,
    }).isRequired,
    locale: PropTypes.string.isRequired,
    onClickDeliveryPrice: PropTypes.func.isRequired,
};

