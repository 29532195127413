/*
    Дадата считает расстояние за мкад по прямой,
    а мы таким образом корректируем его
*/
export const BELTWAY_DISTANCE_CORRECTION_RATIO = 1.2;

/*
    Максимально расстояние доставки за МКАД
    с учетом BELTWAY_DISTANCE_CORRECTION_RATIO
    без учета 30
*/
// export const MAX_BELTWAY_DISTANCE = 30;
export const MAX_BELTWAY_DISTANCE_12_30 = 33;
export const MAX_BELTWAY_DISTANCE = 63;

/*
    Максимальное расстояние за МКАДом, в пределах которого
    по выходным доступны внутримкадные интервалы доставки
    с учетом BELTWAY_DISTANCE_CORRECTION_RATIO
    без учета 12
*/
// export const MAX_BELTWAY_DISTANCE_FOR_INSIDE_INTERVALS = 12;
export const MAX_BELTWAY_DISTANCE_FOR_INSIDE_INTERVALS = 12;
