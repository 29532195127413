/* eslint-disable max-len */
import endOfWeek from 'date-fns/end_of_week';

const PAGE = 'hits';


export const allDishes = [
    25, 3214, 3960, 3513, 668, 58, 3612, 204, 7, 3843, 3723,
].map((id) => String(id));

const dishesData = allDishes.reduce((acc, id, index) => {
    const dishData = {
        id,
        orderNumber: index + 1,
    };
    return {
        ...acc,
        [id]: dishData,
    };
}, {});


export default {
    pageId: PAGE,
    pathSegment: PAGE,
    pathSegmentEn: PAGE,

    title: 'Наши хиты | Elementaree',
    description: 'Начните знакомство с этих блюд: самые популярные рецепты, которые не оставят равнодушными',

    ogImage: '',
    additionalContent: '',
    onInit: () => { },
    appConfig: {
        pageId: PAGE,
        city: null,
        period: null,
        firstPeriodStart: '2023-05-01',
        filterByIds: allDishes,

        areAvaliableDishesPersistentForPeriods: false,

        joinDishTitlesinMobile: false,
        dishesData,
        dishCategories: {
            main: {
                id: 'main',
                title: 'Наши хиты',
                titleEn: 'Наши хиты',
                detailsTitle: 'Наши хиты',
                description: [],
                enabledDishes: allDishes,
            },
        },
        canChangePortionsCount: true,
        areDetailsEnabled: true,

        enabledBasketDishIds: [],
        autoFillingBasket: false,

        isCheckoutEnabled: true,
        isLinkToMenuEnabled: true,

        prepareDates: (dates, periodsConfig = null, period = null, basketDishesIds) => {
            const maxDate = endOfWeek(new Date(period), { weekStartsOn: 1 });

            return dates.filter((d) => {
                const date = new Date(d.date);
                return date <= maxDate;
            });
        },
        prepareIntervals: null,

        ageGate: false,
        ageGateImage: null,
    },
};
