import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { LocaleContext } from 'app/containers/LocaleProvider';

import { UIHeading } from 'app/components/ui';

import {
    STEP_PHONE,
    STEP_ADDRESS,
    STEP_DATETIME,
    STEP_PAYMENT,
} from '../../../stepByStepCheckoutConst';

import { SingleStepDeliveryInfoRow } from '../SingleStepDeliveryInfoRow';
import { SingleStepPhoneRow } from '../SingleStepPhoneRow';
import { SingleStepDatetimeRow } from '../SingleStepDatetimeRow';
import { SingleStepAddressRow } from '../SingleStepAddressRow';
import { SingleStepPaymentRow } from '../SingleStepPaymentRow';

import '../../single-step-delivery-info-area.scss';


export function SingleStepDeliveryInfoArea(props) {
    const {
        handleOpenStep,
        [STEP_PHONE]: authProps,
        [STEP_DATETIME]: datetimeProps,
        [STEP_ADDRESS]: addressProps,
        [STEP_PAYMENT]: paymentProps,
    } = props;

    const { locale } = useContext(LocaleContext);

    return (
        <div styleName="single-step-checkout__delivery-area">
            <UIHeading level="3.2">
                Доставка заказа
            </UIHeading>

            <ul styleName="single-step-checkout__rows">
                <SingleStepDeliveryInfoRow step={STEP_PHONE} handleOpenStep={handleOpenStep}>
                    <SingleStepPhoneRow {...authProps} />
                </SingleStepDeliveryInfoRow>

                <SingleStepDeliveryInfoRow step={STEP_DATETIME} handleOpenStep={handleOpenStep}>
                    <SingleStepDatetimeRow {...datetimeProps} locale={locale} />
                </SingleStepDeliveryInfoRow>

                <SingleStepDeliveryInfoRow step={STEP_ADDRESS} handleOpenStep={handleOpenStep}>
                    <SingleStepAddressRow {...addressProps} locale={locale} />
                </SingleStepDeliveryInfoRow>

                <SingleStepDeliveryInfoRow step={STEP_PAYMENT} handleOpenStep={handleOpenStep}>
                    <SingleStepPaymentRow {...paymentProps} locale={locale} />
                </SingleStepDeliveryInfoRow>
            </ul>
        </div>
    );
}

SingleStepDeliveryInfoArea.propTypes = {
    handleOpenStep: PropTypes.func.isRequired,
};
