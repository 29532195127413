export const setsAvailableDatesField = {
    read(_, { readField }) {
        const trialDatesArr = readField('eeAvailableDates');
        const everydayDatesArr = readField('everydayAvailableDates');
        const premiumDatasArr = readField('premiumAvailableDates');

        const allAvailableDates = [...new Set([
            ...trialDatesArr, ...everydayDatesArr, ...premiumDatasArr,
        ])].sort();

        return allAvailableDates;
    },
};


