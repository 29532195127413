import React, {
    FC, useState, useContext, useRef, MutableRefObject,
} from 'react';
import cn from 'classnames';

import { analyticService } from 'global/services';

import { NextOverlay } from 'app/views/Menu/Menu/components/CustomIngredientsSelectDishRow/Mobile/overlay/nextOverlay';
import { FrameDialog } from 'app/components/Dialog';
import { UIButton, UIRadioIcon } from 'app/components/ui';

import { LocationContext } from 'app/containers/LocationProvider';
import css from './MobileCitySelectModal.module.scss';

type TypeProps = {
    isOpen: boolean,
    // eslint-disable-next-line no-unused-vars
    setIsOpen: (value: boolean) => void,
    // eslint-disable-next-line no-unused-vars
    setCityCallback: (value: string) => void,
    cities: any[],
    // eslint-disable-next-line no-unused-vars
    handleSelectedSubdivision?: (value: string) => void,
}


export const MobileCitySelectModal: FC<TypeProps> = ({
    isOpen, setIsOpen, setCityCallback, cities, handleSelectedSubdivision = () => { },
}: TypeProps) => {
    const {
        mutateAddreessHandler,
        locationKey,
    } = useContext(LocationContext);
    const [selectedOption, setSelectedOption] = useState<string>(locationKey);
    const prevOption: MutableRefObject<string> = useRef<string>(locationKey);


    const handleCloseDialog = (e: any) => {
        e.stopPropagation();
        setIsOpen(false);
    };

    const onCityClickHandler = (e: any, id: string) => {
        e.stopPropagation();
        setSelectedOption((prev) => {
            prevOption.current = prev;
            return id;
        });
    };

    const onCitySumbitHandler = (e: any) => {
        e.stopPropagation();
        if (prevOption.current !== selectedOption) {
            const addressString = {
                MSK: 'г Москва',
                SPB: 'г Санкт-Петербург',
                NIZ: 'г Нижний Новгород',
                KZN: 'г Казань',
            };
            // @ts-ignore
            handleSelectedSubdivision(selectedOption);
            // @ts-ignore
            mutateAddreessHandler({ address: addressString[selectedOption] }, () => setCityCallback(selectedOption));
            analyticService.push({
                eventName: 'Select_Region',
                result: selectedOption,
                source_name: 'selected_by_user',
            });
        }
        setIsOpen(false);
    };
    return (
        <NextOverlay
            isOpen={isOpen}
            handleClose={handleCloseDialog}
        >
            {/* @ts-ignore */}
            <FrameDialog handleClose={handleCloseDialog}>
                <div className={css.selectCityRoot}>
                    <div className={css.selectCityTitle}>
                        Город доставки
                    </div>
                    <div className={css.selectCityItems}>
                        {cities.map((city) => {
                            const optionTitleClasses = cn({
                                [css.selectCityItemText]: true,
                                selected: selectedOption === city.id,
                            });
                            return (
                                <button
                                    className={cn(css.selectCityItemButton)}
                                    type="button"
                                    onClick={(e) => onCityClickHandler(e, city.id)}
                                    id={city.id}
                                    key={city.id}
                                >
                                    <div className={optionTitleClasses}>
                                        {city.value}
                                    </div>
                                    <UIRadioIcon checked={city.id === selectedOption} />
                                </button>
                            );
                        })}
                    </div>
                    <div className={css.selectCitySubmit}>
                        <UIButton onClick={(e) => onCitySumbitHandler(e)}>
                            Готово
                        </UIButton>
                    </div>
                </div>
            </FrameDialog>
        </NextOverlay>
    );
};
