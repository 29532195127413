import queryString from 'query-string';
import omit from 'lodash/omit';


import {
    CATEGORIES_SCHEMA_IDS,
} from 'app/const/categories';
import { parseTagIdsFromURL } from 'app/const/filterTags';

const VALID_MENU_TYPES = [
    'mix',
    'everyday',
    'premium',
];

export const parseCategories = (location) => {
    const queryParams = queryString.parse(location.search);

    const categories = Object.keys(CATEGORIES_SCHEMA_IDS).map((key) => {
        const count = Number(queryParams[key]);
        const id = CATEGORIES_SCHEMA_IDS[key];
        if (!count) return null;
        return {
            category_id: id,
            count,
        };
    }).filter((item) => !!item);

    if (!categories.length) return null;
    return categories;
};

export const parseDishes = (location) => {
    const queryParams = queryString.parse(location.search);
    const { dishes } = queryParams;
    if (!dishes) return null;
    return dishes.split('.');
};

export const parseMenuType = (location) => {
    const queryParams = queryString.parse(location.search);
    const { menu } = queryParams;
    return VALID_MENU_TYPES.includes(menu) ? menu : null;
};

export const parseTags = (location) => {
    const queryParams = queryString.parse(location.search);
    return parseTagIdsFromURL(queryParams);
};


export const removeSchemaParams = (locationSearch) => {
    const queryParams = queryString.parse(locationSearch);

    const params = [
        'dishes',
        ...Object.keys(CATEGORIES_SCHEMA_IDS),
    ];

    const nextParams = omit(queryParams, params);

    return queryString.stringify(nextParams);
};


