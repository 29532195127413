import { checkoutDataVar, checkoutDeliveryDateVar } from '../reaction';

/** */
export const saveCheckoutDataReaction = ({ checkoutData }) => {
    const prevState = checkoutDataVar();
    const data = {
        ...prevState,
        ...checkoutData,
    };
    checkoutDataVar(data);
};

/** @note НЕ ИСПОЛЬЗУЕТСЯ */
// export const setInvoiceDeliveryDateReactoin = ({ deliveryDate }) => {
//     invoiceDeliveryDateVar(deliveryDate);
// };

export const setCheckoutDeliveryDateReaction = ({ deliveryDate }) => {
    checkoutDeliveryDateVar(deliveryDate);
};
