import React, { useContext } from 'react';

import { Overlay } from 'app/components/BlurredOverlay';
import { OverlayContextData, OverlayContextDispatch } from 'app/containers//contexts/overlay.context';
import isEmpty from 'lodash/isEmpty';


/**
 * @description компонент, который рендерит все оверлеи, которые хранятся в стеке в OverlayContextData
 */
const OverlaysContainer = React.memo(() => {
    const { stack: overlayStack, uiStates: overlayUiStates } = useContext(OverlayContextData);
    const { closeLastOverlays } = useContext(OverlayContextDispatch);

    if (isEmpty(overlayStack)) return null;

    return overlayStack.map(({
        id, render, children, overlayProps, onClick,
    }) => {
        const totalOnClick = onClick || closeLastOverlays;
        return (
            <Overlay
                {...overlayProps}
                overlayOpen={overlayUiStates[id]?.openingState === 'open'}
                render={render}
                onClick={totalOnClick}
                key={id}
            >
                {children}
            </Overlay>
        );
    });
});

export default OverlaysContainer;
