import React, {
    FC, RefObject, useContext, useEffect, useRef, useState,
} from 'react';

// CONTEXTS
import { OverlayContextDispatch } from 'app/containers/contexts/overlay.context';

// STYLES
import styles from './BonusesPopup.module.scss';

// ICONS
import closeIconPath from './img/close_icon.img.svg';
import bonusesLogoPath from './img/BonusesLogo.img.svg';

export const BonusesPopup: FC = () => {
    const { closeLastOverlays } = useContext(OverlayContextDispatch);
    const rootRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const [isPopupHeightMoreThanRoot, setIsPopupHeightMoreThanRoot] = useState<boolean>(false);

    // EFFECTS
    useEffect(() => {
        // define target popup height
        if (!rootRef.current) return;
        const rootRect: DOMRect = rootRef.current.getBoundingClientRect();
        const contentNode: HTMLElement | null = rootRef.current?.firstChild as HTMLElement;
        const contentRect: DOMRect | null = contentNode
            ? contentNode.getBoundingClientRect()
            : null;
        setIsPopupHeightMoreThanRoot((contentRect?.height && rootRect.height)
            ? contentRect.height > rootRect.height
            : false);
    }, [rootRef]);

    return (
        <div className={styles.bonusesPopupRoot} ref={rootRef}>
            <div
                className={styles.bonusesPopupWrapper}
            >
                <button
                    className={styles.bonusesCloseBtnWrapper}
                    onClick={closeLastOverlays}
                    type="button"
                >
                    <img
                        src={closeIconPath}
                        alt="close-cross"
                        className={styles.bonusesCloseBtn}
                    />
                </button>
                <div className={styles.bonusesLogoAria}>
                    <div className={styles.bonusesLogoWrapper}>
                        <img src={bonusesLogoPath} alt="icon" className={styles.bonusesLogo} />
                    </div>
                </div>
                <div className={styles.bonusesInfo}>
                    <div className={styles.bonusesInfoTitle}>Бонусы</div>
                    <ul className={styles.bonusesInfoDescList}>
                        <li>1 бонус — 1 рубль</li>
                        <li>
                            Бонусы суммируются
                            <br />
                            и хранятся в личном кабинете
                        </li>
                        <li>Оплачивайте до 100% стоимости заказа</li>
                        <li>
                            Распространяется
                            <br />
                            на всё меню, включая сеты
                        </li>
                    </ul>
                </div>
                <div
                    className={styles.bonusesApplyButton}
                    onClick={closeLastOverlays}
                    role="none"
                >
                    <button type="button">
                        Понятно
                    </button>
                </div>
            </div>
        </div>
    );
};
