export const locales = {
    ru: {
        // Render
        regionTitle: 'Регион доставки',
    },
    en: {
        // Render
        regionTitle: 'Delivery region',
    },
};
