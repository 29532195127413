import React, {
    useState,
    useRef,
    useEffect,
    useCallback,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';

import { analyticService } from 'global/services';

import { UIHeading, UIInput } from 'app/components/ui';
import Checkbox, { VERSION_UIKIT } from 'app/components/Form/Checkbox2';
import { AddressSuggestions } from 'app/components/Form/Street/AddressSuggestions';

import { StepLayout } from '../StepLayout/StepLayout';
import { StepAddressNotifications } from './StepAddressNotifications';
import { locales } from '../../checkout.locales';
import { RENDER_CONTEXT_SINGLE_STEP, RENDER_CONTEXT_STEP_BY_STEP } from '../stepByStepCheckoutConst';

import './step-address.scss';
import CarrierCommentRow from './components/CarrierCommentRow';


const addressLocales = {
    ru: {
        headerTitle: 'Оформление заказа',
        title: 'Доставка',
    },
    en: {
        headerTitle: 'Checkout',
        title: 'Delivery',
    },
};


export function StepAddress(props) {
    const {
        renderContext = RENDER_CONTEXT_STEP_BY_STEP,
        headerTitle: headerTitleProp = null,
        hasError = false,
        errorText = null,
        floorValue = '',
        locale,
        street,
        addressValue,
        onChangeAddress,
        onBlurAddress,
        onSelectAddress,
        suggestions,
        flatValue,
        onChangeFlat,
        entranceValue,
        onChangeEntrance,
        onChangeFloor,
        onChangeContactlessDelivery,
        contactlessDeliverychecked,
        deliveryPrice,
        haveDishesFromTrial,
        isAddresIncompleteNotificationOpen,

        // TODO: move to comment component
        selectedPeriod,
    } = props;


    const addressInputRef = useRef(null);
    const flatInputRef = useRef(null);
    const [isShownSuggestions, setIsShownSuggestions] = useState(false);
    const [isFlatFocused, setIsFlatFocused] = useState(false);
    const [isFlatInputTouched, setIsFlatInputTouched] = useState(false);

    useEffect(() => {
        const closeHandler = (e) => {
            const isClickOutside = !e.target.closest('[data-address-area]');
            if (isClickOutside) {
                setIsShownSuggestions(false);
            }
        };
        document.body.addEventListener('click', closeHandler);

        return () => {
            document.body.removeEventListener('click', closeHandler);
        };
    }, []);

    const handleChangeAddress = (value) => {
        onChangeAddress(value);
        const nextIsShownSuggestions = value.length > 0;
        setIsShownSuggestions(nextIsShownSuggestions);
    };

    const handleSelectStreet = (item) => {
        const { value, data } = item;
        const haveOnlyOneSuggestions = suggestions.length === 1;

        const isAdressWithHouseSelected = Boolean(data && data.house);

        if (isAdressWithHouseSelected || haveOnlyOneSuggestions) {
            setIsShownSuggestions(false);
            onSelectAddress(item);
        } else {
            onChangeAddress(`${value} `);
            if (addressInputRef.current) {
                addressInputRef.current.focus();
            }
        }
    };

    const handleFocusOnAddressInput = useCallback(() => {
        analyticService.push({ eventName: 'Checkout_Click_Address', eventLocation: '/checkout' });
    }, []);

    const l = locales[locale];

    const headerTitle = headerTitleProp || addressLocales[locale].headerTitle;
    const addressPlaceholder = l.streetInputTitle;
    const flatPlaceholder = l.flatShort;

    const entrancePlaceholder = window.innerWidth < 360 ? l.entranceShort : l.entrance;
    const floorPlaceholder = l.floor;

    const isErrorShown = hasError && !isShownSuggestions;
    const isFlatErrorShown = useMemo(
        () => isFlatInputTouched && !flatValue && !isShownSuggestions,
        [isFlatInputTouched, flatValue, isShownSuggestions],
    );
    const addressConditionState = {
        label: isErrorShown ? errorText : addressPlaceholder,
        uiState: isErrorShown ? 'error' : 'default',
    };
    const flatConditionState = {
        label: isFlatErrorShown ? `${isFlatFocused ? '№ квартиры' : 'Кв.'}` : flatPlaceholder,
        uiState: isFlatErrorShown ? 'error' : 'default',
    };

    const buttonType = isErrorShown || isFlatErrorShown || isShownSuggestions ? 'clear' : 'none';

    const streetIsValid = !!street.data.house && street.value.trim() !== '' && !hasError;
    const isNextStepButtonDisabled = !streetIsValid || !flatValue;

    return (
        <StepLayout
            {...props}
            isNextStepButtonDisabled={isNextStepButtonDisabled}
            title={headerTitle}
        >
            {renderContext === RENDER_CONTEXT_SINGLE_STEP
                ? null
                : (
                    <UIHeading level="3.2" styleName="step-address__title-container">
                        {addressLocales[locale].title}
                    </UIHeading>
                )}
            <div>
                {/* ROW 1 */}
                <div
                    styleName="step-address__inputs-row"
                    data-address-area="true"
                >
                    <div styleName="step-address__suggestions-input">
                        <UIInput
                            onChangeHandler={handleChangeAddress}
                            inputValue={addressValue}
                            onSubmitHandler={onBlurAddress}
                            onBlurHandler={onBlurAddress}
                            uiState={addressConditionState.uiState}
                            label={addressConditionState.label}
                            buttonType={buttonType}
                            inputRef={addressInputRef}
                            onFocusHanlder={handleFocusOnAddressInput}
                        />
                    </div>
                    {isShownSuggestions && suggestions.length > 0 && (
                        <div styleName="step-address__suggestions">
                            <AddressSuggestions
                                suggestions={suggestions.slice(0, 4)}
                                onClickSuggestion={handleSelectStreet}
                            />
                        </div>
                    )}
                </div>
                {/* ROW 2 */}
                <div styleName="step-address__inputs-row">
                    <div styleName="step-address__inputs-row-item">
                        <UIInput
                            onChangeHandler={onChangeFlat}
                            inputValue={flatValue || ''}
                            uiState={flatConditionState.uiState}
                            label={flatConditionState.label}
                            inputRef={flatInputRef}
                            buttonType="none"
                            type="tel"
                            onFocusHanlder={() => setIsFlatFocused(true)}
                            onBlurHandler={() => {
                                setIsFlatFocused(false);
                                setIsFlatInputTouched(true);
                            }}
                        />
                    </div>
                    <div styleName="step-address__inputs-row-item">
                        <UIInput
                            onChangeHandler={onChangeEntrance}
                            inputValue={entranceValue || ''}
                            uiState="default"
                            label={entrancePlaceholder}
                            buttonType="none"
                            type="tel"
                        />
                    </div>
                    <div styleName="step-address__inputs-row-item">
                        <UIInput
                            onChangeHandler={onChangeFloor}
                            inputValue={floorValue || ''}
                            uiState="default"
                            label={floorPlaceholder}
                            buttonType="none"
                            type="tel"
                        />
                    </div>
                </div>
                {/* ROW 3 */}
                <div styleName="step-address__inputs-row">
                    <CarrierCommentRow
                        addressValue={addressValue}
                        selectedPeriod={selectedPeriod}
                        hasError={hasError}
                    />
                </div>
                {/* */}
                <div styleName="step-address__checkboxes">
                    {/* eslint-disable-next-line */}
                    <div
                        styleName="step-address__checkbox-item"
                        onClick={onChangeContactlessDelivery}
                    >
                        <Checkbox
                            version={VERSION_UIKIT}
                            name="contactless-delivery"
                            id="contactless-delivery"
                            onChange={onChangeContactlessDelivery}
                            checked={contactlessDeliverychecked}
                            ariaLabel="Бесконтактная доставка"
                        />
                        <span styleName="step-address__checkbox-item-text">
                            {l.contactlessDeliveryTitle}
                        </span>
                    </div>
                </div>
                <div styleName="step-address__notifcations">
                    <StepAddressNotifications
                        locale={locale}
                        deliveryPrice={deliveryPrice}
                        hasError={hasError}
                        street={street}
                        haveDishesFromTrial={haveDishesFromTrial}
                        isAddresIncompleteNotificationOpen={isAddresIncompleteNotificationOpen}
                    />
                </div>
            </div>
        </StepLayout>
    );
}

StepAddress.propTypes = {
    renderContext: PropTypes.oneOf([RENDER_CONTEXT_SINGLE_STEP, RENDER_CONTEXT_STEP_BY_STEP]),
    locale: PropTypes.string.isRequired,
    headerTitle: PropTypes.string,

    street: PropTypes.shape({
        data: PropTypes.shape({
            house: PropTypes.string,
        }),
        value: PropTypes.string,
    }).isRequired,
    hasError: PropTypes.bool,
    errorText: PropTypes.string,
    addressValue: PropTypes.string.isRequired,
    onChangeAddress: PropTypes.func.isRequired,
    onBlurAddress: PropTypes.func.isRequired,
    onSelectAddress: PropTypes.func.isRequired,
    suggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

    flatValue: PropTypes.string.isRequired,
    onChangeFlat: PropTypes.func.isRequired,
    entranceValue: PropTypes.string.isRequired,
    onChangeEntrance: PropTypes.func.isRequired,
    floorValue: PropTypes.string,
    onChangeFloor: PropTypes.func.isRequired,
    onChangeContactlessDelivery: PropTypes.func.isRequired,
    contactlessDeliverychecked: PropTypes.bool.isRequired,

    deliveryPrice: PropTypes.number.isRequired,
    haveDishesFromTrial: PropTypes.bool.isRequired,
    isAddresIncompleteNotificationOpen: PropTypes.bool.isRequired,
};
