import 'intersection-observer';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useMutation } from '@apollo/client';
import ScrollLock from 'react-scrolllock';
import Raven from 'raven-js';
import compose from 'lodash/flowRight';
import sortBy from 'lodash/sortBy';
import { graphql } from '@apollo/client/react/hoc';

import connect from 'app/connect';
import { VIEW_CUSTOMIZATION_COMMENT } from 'app/graphql/network/customizationComment';
import { ADD_ITEMS_TO_CART_BY_PERIOD } from 'app/graphql/network/basket/basketMutations';
import { analyticService } from 'global/services';

import {
    NY_DISHES,
    PROTEINS_ID,
    STYLE_ID,
    INGREDIENTS_ID,
    IS_PREMIUM_FILTER_ENABLED,
    PREMIUM_FILTER_TITLE,
    PREMIUM_FILTER_ID,
    BEST_TAG_ID,
    extendTagByCount,
} from 'app/utils/filters';

import { isIOS } from 'app/utils/browser';

import CustomizationCommentFilters from 'app/components/CustomizationComment/CustomizationCommentFilters';
import { withMenuDatesContext } from 'app/containers/contexts/menuDates.context';
import { clearAfterSwitchDialogTexts } from 'app/containers/contexts/filters.context/filters.context';
import { withDispatchOverlay } from 'app/containers/contexts/overlay.context';
import { appendTagReaction, deleteTagReaction, establishTagsReaction, resetSelectedFiltersReaction } from 'app/hooks/useFilterHandlers';

import {
    pickPeriodReaction,
    setCategoriesReaction,
} from 'app/apollo/reactions/filters';

import { UIButton, UIButtonBottomArea } from 'app/components/ui';
import ResetIcon from 'assets/svg/filter_clear.svg';
import XBigIcon from 'assets/svg/x_big.component.svg';
import { withLocaleContext } from 'app/containers/LocaleProvider';
import MobilePeriod from './components/Period';
import Style from './components/Style';
import Ingredient from './components/Ingredient';

import { locales } from './mobileFilters.locales';

import './mobile-filters.scss';


const MIN_SWIPE_DISTANCE = 20;
const SWIPE_DISTANCE_FOR_CLOSE = 200;


const OPENED = 'opened';
const CLOSED = 'closed';


const getRandomTimeout = (min, max) => {
    const delta = max - min;
    const randomNum = Math.random();

    return Math.floor(randomNum * delta) + min;
};

class MobileFilters extends React.Component {
    constructor(props) {
        super(props);

        const {
            selectedPeriod,
            selectedCategories,
            selectedTags,
            isSelectedDefaultFilters,
        } = props.selectedFilters;

        this.state = {
            initialSelectedFilters: {
                selectedPeriod,
                selectedCategories,
                selectedTags,
                isSelectedDefaultFilters,
            },
            hasChangesForApply: false,
            isCustomizationCommentAreaShown: false,
            customizationCommentShowingState: CLOSED, // opened, closed
            filtrationSteps: [],
        };

        this.startSwipePosition = { x: null, y: null };
        this.lastSwipePosition = { x: null, y: null };

        this.mobileScrollContainerRef = React.createRef();
        this.containerRef = React.createRef();

        this.scrollContainerPosition = null;

        this.isListenersAdded = false;
    }

    componentDidMount() {
        this.listenToSwipes();
    }

    componentDidUpdate() {
        if (!this.isListenersAdded) {
            this.listenToSwipes();
        }
    }

    componentWillUnmount() {
        this.stopListeningToSwipes();

        // if (this.observer) this.observer.disconnect();
    }

    // eslint-disable-next-line react/sort-comp
    listenToSwipes() {
        const el = this.containerRef.current;
        if (!el) return;

        el.addEventListener('touchstart', this.handleTouchStart);
        el.addEventListener('touchend', this.handleTouchEnd);
        el.addEventListener('touchmove', this.handleTouchMove);

        this.isListenersAdded = true;
    }

    // eslint-disable-next-line react/sort-comp
    stopListeningToSwipes() {
        const el = this.containerRef.current;
        if (!el) return;

        el.removeEventListener('touchstart', this.handleTouchStart);
        el.removeEventListener('touchend', this.handleTouchEnd);
        el.removeEventListener('touchmove', this.handleTouchMove);
    }

    handleTouchStart = (e) => {
        this.startSwipePosition.x = e.touches[0].clientX;
        this.startSwipePosition.y = e.touches[0].clientY;
    };

    handleTouchMove = (e) => {
        const { clientY } = e.touches[0];
        const { style: elStyle } = this.containerRef.current;

        if (clientY - this.startSwipePosition.y > MIN_SWIPE_DISTANCE) {
            elStyle.transition = 'transform 0ms linear';
            elStyle.transform = `translateY(${clientY}px)`;
        }
        this.lastSwipePosition.y = clientY;
    };

    handleTouchEnd = () => {
        this.startSwipePosition.x = null;
        this.startSwipePosition.y = null;

        if (!this.lastSwipePosition.y) return;

        const { style: elStyle } = this.containerRef.current;

        elStyle.transition = 'transform 400ms ease-out';

        if (this.lastSwipePosition.y - this.startSwipePosition.y > SWIPE_DISTANCE_FOR_CLOSE) {
            elStyle.transform = 'translateY(100%)';
            setTimeout(this.handleClose, 300);
        } else {
            elStyle.transform = `translateY(${0}px)`;
        }

        this.lastSwipePosition.y = null;
        this.lastSwipePosition.y = null;
    };

    handleResetFilters = () => {
        // Actions

        const {
            initialSelectedFilters: { isSelectedDefaultFilters },
        } = this.state;

        analyticService.push({
            eventName: 'Change_Filter_Item',
            itemKey: 'clear',
        });
        resetSelectedFiltersReaction();

        if (!isSelectedDefaultFilters) {
            this.setState({ hasChangesForApply: true });
        }
    };

    trackOpenPeriodSelect = () => {
        analyticService.push({
            eventName: 'Change_Filter_Item',
            itemKey: 'openWeek',
        });
    };

    handleSetPeriod = async (start) => {
        const {
            menuDishesQuery,
            selectedFilters: { selectedPeriod },
            filterQuery: { menuFilter: { periods } },
            basketQuery,
            localeContext: { locale },
            dispatchOverlayContext: { pushDialogOverlay, closeLastOverlays },
        } = this.props;

        const callSetPeriodMutations = async () => {
            this.setState({ hasChangesForApply: true });
            pickPeriodReaction({ periodStart: start, skipSavingToPrev: true });
            await menuDishesQuery.refetch();

            analyticService.push({
                eventName: 'Change_Filter_Item',
                itemKey: 'period',
                periodIndex: periods.findIndex((p) => p.start === start),
            });
        };

        // const currentPeriod = periods.find((p) => p.start === selectedPeriod);
        const nextPeriod = periods.find((p) => p.start === start);

        const isBasketEmpty = basketQuery.cart.sections.length === 0;
        // const isNeededClearBasket = nextPeriod.clearAfterSwitchFrom === currentPeriod.start;
        const isNeededClearBasket = nextPeriod.clearAfterSwitchFrom;

        if (isNeededClearBasket && !isBasketEmpty) {
            const dialogData = {
                ...clearAfterSwitchDialogTexts[locale],
                onConfirm: async () => {
                    await callSetPeriodMutations();
                    await basketQuery.refetch({ clear: true });
                    closeLastOverlays();
                },
                notifyOnly: false,
                oneRowButtons: true,
            };

            pushDialogOverlay('clear_basket_dialog_mobile_filters', dialogData);
        } else {
            await callSetPeriodMutations();
        }
    };

    handleAddCategory = (id) => {
        const {
            selectedFilters: { selectedCategories },
        } = this.props;

        setCategoriesReaction({ categories: selectedCategories.concat(id) });

        this.setState({ hasChangesForApply: true });

        analyticService.push({
            eventName: 'Change_Filter_Item',
            itemKey: 'category',
            action: 'on',
            categoryId: id,
        });
    };

    handleRemoveCategory = (id) => {
        const {
            selectedFilters: { selectedCategories },
        } = this.props;

        setCategoriesReaction({ categories: selectedCategories.filter((cid) => cid !== id) });

        this.setState({ hasChangesForApply: true });

        analyticService.push({
            eventName: 'Change_Filter_Item',
            itemKey: 'category',
            action: 'off',
            categoryId: id,
        });
    };

    handleAddTag = (tag) => {
        const { filtrationSteps } = this.state;

        const variables = {
            id: tag.id,
            isUserChangesTags: true,
        };
        appendTagReaction({
            id: tag.id,
            isUserChangesTags: true,
        });

        this.setState({ hasChangesForApply: true });

        const eventData = {
            eventName: 'Change_Filter_Item',
            itemKey: 'tag',
            action: 'on',
            tagTitle: tag.title,
        };

        analyticService.push(eventData);

        this.setState({
            filtrationSteps: [...filtrationSteps, eventData],
        });
    };

    handleRemoveTag = (tag) => {
        const { filtrationSteps } = this.state;

        deleteTagReaction({ tagId: tag.id, isUserChangesTags: true });

        this.setState({ hasChangesForApply: true });

        const eventData = {
            eventName: 'Change_Filter_Item',
            itemKey: 'tag',
            action: 'off',
            tagTitle: tag.title,
        };

        analyticService.push(eventData);

        this.setState({
            filtrationSteps: [...filtrationSteps, eventData],
        });
    };

    handleClose = async () => {
        const {
            onClose,
        } = this.props;

        const {
            initialSelectedFilters: {
                selectedPeriod,
                selectedTags,
                selectedCategories,
            },
        } = this.state;

        pickPeriodReaction({ periodStart: selectedPeriod });
        establishTagsReaction({ tags: selectedTags });
        setCategoriesReaction({ categories: selectedCategories });

        onClose();
    };

    handleApply = async () => {
        const {
            selectedFilters: {
                selectedPeriod,
                selectedTags,
                prevSelectedPeriods,
            },
            basketQuery,
        } = this.props;

        // Actions
        const {
            onStartApplying,
            updateBilling,
            addItemsToCartByPeriod,
            onClose,
        } = this.props;

        const {
            hasChangesForApply,
            initialSelectedFilters: {
                selectedPeriod: initialSelectedPeriod,
            },
            filtrationSteps,
        } = this.state;

        const isNeededFillBasket = !prevSelectedPeriods.includes(selectedPeriod);

        if (hasChangesForApply && isNeededFillBasket) {
            try {
                await updateBilling();
                await addItemsToCartByPeriod({
                    variables: {
                        period: selectedPeriod,
                        fillByPeriod: initialSelectedPeriod,
                        tags: selectedTags,
                    },
                });
                await basketQuery.refetch();
            } catch (e) {
                Raven.captureException(e);
            }
        }

        const randomTimeout = getRandomTimeout(500, 1500);

        analyticService.push({
            eventName: 'track_Filtration_Steps',
            steps: filtrationSteps,
        });

        onStartApplying();

        this.setState(
            { isFakeLoading: true },
            () => setTimeout(() => {
                this.setState({ isFakeLoading: false });
                onClose(null, { isChangesApplied: hasChangesForApply });
            }, randomTimeout),
        );
    };

    toggleCustomizationCommentArea = (isShown) => () => {
        const { isCustomizationCommentAreaShown } = this.state;
        const nextIsShown = isShown || !isCustomizationCommentAreaShown;

        this.resolveScrollContainerScrollPosition({ commentIsShown: nextIsShown });
        this.setState({ isCustomizationCommentAreaShown: nextIsShown });
    };

    setCustomizationCommentShowingState = (state) => () => this.setState({ customizationCommentShowingState: state });

    resolveScrollContainerScrollPosition({ commentIsShown }) {
        if (!isIOS) return;
        try {
            const scrollEl = this.mobileScrollContainerRef.current;
            if (commentIsShown) {
                this.scrollContainerPosition = scrollEl.scrollTop;
            } else {
                scrollEl.scroll(0, this.scrollContainerPosition);
            }
        } catch (error) {
            Raven.captureException(error);
        }
    }

    renderHeader() {
        const {
            customizationCommentShowingState,
        } = this.state;
        const {
            selectedFilters: { isSelectedDefaultFilters },
            localeContext: { locale },
        } = this.props;

        const { buttonText } = locales[locale];
        const customizationCommentOpened = customizationCommentShowingState === OPENED;

        const onCloseHandler = customizationCommentOpened
            ? this.toggleCustomizationCommentArea(false)
            : this.handleClose;

        const leftButtonStyle = classNames({
            'filters-header__button': true,
            reset: true,
            'not-display': customizationCommentOpened,
        });


        return (
            <div styleName="filters-header">
                <button
                    type="button"
                    aria-label="очистить фильтры"
                    styleName={leftButtonStyle}
                    disabled={isSelectedDefaultFilters}
                    onClick={this.handleResetFilters}
                >
                    <ResetIcon />
                    <span styleName="filters-header__button-text">
                        {buttonText}
                    </span>
                </button>
                <button
                    type="button"
                    aria-label="Закрыть"
                    styleName="filters-header__button close"
                    onClick={onCloseHandler}
                >
                    <XBigIcon />
                </button>
            </div>
        );
    }

    render() {
        const {
            isFakeLoading,
            isCustomizationCommentAreaShown,
            hasChangesForApply,
        } = this.state;

        const {
            hiddenElements: { comment: isCommentHiddenByUrl },
            menuDishesQuery: { allDishes, menuDishes, basketDishes },
            filterQuery: { menuFilter: { periods } },
            selectedFilters: {
                selectedPeriod,
                selectedTags,
                selectedCategories,
            },
            sessionCommentQuery: { viewCustomizationComment },
            isCommentAnimationDisabled,
            isNeededRenderPeriod,
            menuDatesContext: {
                state: {
                    selectedDate,
                    filterPeriod: menuDatesFilterPeriod,
                    isSelectedDateInEditableRange,
                },
            },
            localeContext: { locale },
        } = this.props;
        const { applyButtonText } = locales[locale];

        const period = periods.find((p) => p.start === selectedPeriod);
        const { tags } = period;

        // const countedCategories = categories.map(c => extendCategoryByCount(c, allDishes, selectedTags, basketDishes));

        const mappedStyles = tags
            .filter((t) => String(t.categoryId) === STYLE_ID)
            .filter((tag) => {
                if (tag.id === PREMIUM_FILTER_ID) {
                    return IS_PREMIUM_FILTER_ENABLED;
                }
                if (tag.id === NY_DISHES) {
                    return false;
                }
                return true;
            })
            .map((t) => {
                const title = t.id === PREMIUM_FILTER_ID ? PREMIUM_FILTER_TITLE : t.title;
                const tag = {
                    ...t,
                    title,
                };
                return extendTagByCount(tag, allDishes, selectedCategories, selectedTags, basketDishes);
            });
        const styles = sortBy(mappedStyles, [
            (tag) => tag.id !== PREMIUM_FILTER_ID,
            (tag) => tag.id === BEST_TAG_ID,
        ]);

        const ingredients = tags
            .filter((t) => String(t.categoryId) === INGREDIENTS_ID)
            .map((t) => extendTagByCount(t, allDishes, selectedCategories, selectedTags, basketDishes));

        const selectedStyleIds = selectedTags.filter((id) => styles.find((p) => p.id === id));
        const selectedIngredientIds = selectedTags.filter((id) => ingredients.find((p) => p.id === id));

        const dishesCount = menuDishes.length;

        const containerClasses = classNames({
            'filters-container': true,
            'without-comment': isCommentHiddenByUrl,
        });

        const isCommentAnimationRunning = (
            !isCommentAnimationDisabled
            && !(viewCustomizationComment && viewCustomizationComment.comment)
        );

        const canCustomizeMenu = menuDatesFilterPeriod === selectedPeriod ? isSelectedDateInEditableRange : true;

        const isDisplayApplyButton = hasChangesForApply && !isCustomizationCommentAreaShown;

        return (
            <div styleName={containerClasses} ref={this.containerRef}>

                {this.renderHeader()}

                <div styleName="filters-scroll" ref={this.mobileScrollContainerRef}>
                    <div styleName="filters">
                        {isNeededRenderPeriod && (
                            <MobilePeriod
                                selectedDate={selectedDate}
                                menuDatesFilterPeriod={menuDatesFilterPeriod}
                                period={period}
                                onSelectPeriod={this.handleSetPeriod}
                                onClickOpenSelect={this.trackOpenPeriodSelect}
                                onClick={this.handleOpenPeriodSelect}
                                locale={locale}
                            />
                        )}
                        <Style
                            disabled={!canCustomizeMenu}
                            selected={selectedStyleIds}
                            list={styles}
                            onRemove={this.handleRemoveTag}
                            onAdd={this.handleAddTag}
                            locale={locale}
                        />
                        <Ingredient
                            disabled={!canCustomizeMenu}
                            selected={selectedIngredientIds}
                            list={ingredients}
                            onRemove={this.handleRemoveTag}
                            onAdd={this.handleAddTag}
                            locale={locale}
                        />
                    </div>
                    {!isCommentHiddenByUrl && canCustomizeMenu && (
                        <CustomizationCommentFilters
                            isAnimationRunning={isCommentAnimationRunning}
                            isCommentAreaShown={isCustomizationCommentAreaShown}
                            locale={locale}

                            toggleCommentArea={this.toggleCustomizationCommentArea()}
                            onClosed={this.setCustomizationCommentShowingState(CLOSED)}
                            onOpened={this.setCustomizationCommentShowingState(OPENED)}
                        />
                    )}
                </div>
                {this.mobileScrollContainerRef.current && (
                    <ScrollLock
                        touchScrollTarget={this.mobileScrollContainerRef.current}
                    />
                )}

                {isDisplayApplyButton && (
                    <UIButtonBottomArea zIndex="4">
                        <UIButton
                            onClick={this.handleApply}
                            aria-label="Показать блюда"
                            data-test-id="open-basket-button"
                            loading={isFakeLoading}
                        >
                            {dishesCount === 0 ? (
                                <>
                                    Показать наборы
                                </>
                            ) : (
                                <>
                                    {applyButtonText}
                                    <span className="num">{dishesCount}</span>
                                </>
                            )}
                        </UIButton>
                    </UIButtonBottomArea>
                )}
            </div>
        );
    }
}


MobileFilters.propTypes = {
    hiddenElements: PropTypes.shape({
        comment: PropTypes.bool,
    }).isRequired,

    menuDatesContext: PropTypes.shape({
        state: PropTypes.shape({
            selectedDate: PropTypes.string.isRequired,
            filterPeriod: PropTypes.string.isRequired,
            isSelectedDateInEditableRange: PropTypes.bool.isRequired,
        }).isRequired,
    }).isRequired,

    filterQuery: PropTypes.shape({
        menuFilter: PropTypes.shape({
            periods: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }).isRequired,

    selectedFilters: PropTypes.shape({
        selectedPeriod: PropTypes.string,
        selectedTags: PropTypes.arrayOf(
            PropTypes.string,
        ),
        selectedCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,

    paymentMethod: PropTypes.shape({
        paymentMethod: PropTypes.string,
    }).isRequired,

    menuDishesQuery: PropTypes.shape({
        refetch: PropTypes.func,
    }),

    updateBilling: PropTypes.func.isRequired,
    addItemsToCartByPeriod: PropTypes.func.isRequired,
    basketQuery: PropTypes.shape({
        refetch: PropTypes.func,
    }),

    onStartApplying: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,

    isNeededRenderPeriod: PropTypes.bool,
    isCommentAnimationDisabled: PropTypes.bool,

    sessionCommentQuery: PropTypes.shape({
        viewCustomizationComment: PropTypes.shape({
            comment: PropTypes.string,
        }),
    }),

    dispatchOverlayContext: PropTypes.shape({
        pushDialogOverlay: PropTypes.func.isRequired,
        closeLastOverlays: PropTypes.func.isRequired,
    }).isRequired,
};


const MobileFiltersFC = (props) => {
    const {
        menuDishesQuery = {},
        basketQuery = {},
        isNeededRenderPeriod = true,
        isCommentAnimationDisabled = false,
        sessionCommentQuery = {
            viewCustomizationComment: {
                comment: '',
            },
        },
    } = props;

    const [addItemsToCartByPeriod] = useMutation(ADD_ITEMS_TO_CART_BY_PERIOD);

    return (
        <MobileFilters
            {...props}
            menuDishesQuery={menuDishesQuery}
            basketQuery={basketQuery}
            isNeededRenderPeriod={isNeededRenderPeriod}
            sessionCommentQuery={sessionCommentQuery}
            isCommentAnimationDisabled={isCommentAnimationDisabled}
            addItemsToCartByPeriod={addItemsToCartByPeriod}
        />
    );
};

export default connect(
    compose(
        graphql(VIEW_CUSTOMIZATION_COMMENT, { name: 'sessionCommentQuery' }),
        withMenuDatesContext,
        withLocaleContext,
        withDispatchOverlay,
    )(MobileFiltersFC),
);
