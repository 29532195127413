/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';

import './clamp-lines.scss';

import { tp } from 'app/utils/typograf';
import { delay as waitDelay } from 'app/utils/common';


const FONTS_LOAD_DELAY = 500;

const height = (el) => el.getBoundingClientRect(el).height;


class ClampLines extends React.Component {
    constructor(props) {
        super(props);

        this.textRef = React.createRef();

        this.state = {
            clampedText: null,
            applyFallbackStyles: false,
        };
    }

    componentDidMount() {
        const { delay } = this.props;
        if (delay) {
            setTimeout(() => {
                this.initializeClamping();
            }, delay);
        } else {
            this.initializeClamping();
        }
    }

    componentDidUpdate(prevProps) {
        const { text, linesCount } = this.props;
        const { text: prevText, linesCount: prevLinesCount } = prevProps;

        if (text !== prevText || linesCount !== prevLinesCount) {
            this.restartClamping();
        }
    }

    initializeClamping() {
        if (document.fonts) {
            Promise.race([
                document.fonts.ready,
                waitDelay(FONTS_LOAD_DELAY),
            ]).then(this.clampLines, this.clampLines);
        } else {
            setTimeout(() => {
                this.clampLines();
            }, FONTS_LOAD_DELAY);
        }
    }

    restartClamping() {
        this.setState({
            clampedText: null,
        }, () => {
            this.clampLines();
        });
    }

    clampLines = () => {
        const {
            linesCount,
            splitBy,
        } = this.props;

        let lineHeight;
        let applyFallbackStyles = false;

        try {
            lineHeight = parseInt(
                window.getComputedStyle(this.textRef.current).getPropertyValue('line-height'),
                10,
            );
        } catch (e) {
            lineHeight = 1.2;
            applyFallbackStyles = true;
        }

        const el = this.textRef.current;

        if (!el) return;

        if (!applyFallbackStyles) {
            const maxHeight = (lineHeight * (linesCount + 1)) - (lineHeight * 0.8);

            while (height(el) > maxHeight) {
                const text = el.textContent.replace(/\.{3}$/, '');
                const words = text.split(splitBy);
                words.pop();
                const nextText = `${words.join(splitBy)}...`;
                el.textContent = nextText;
            }
        }

        this.setState({
            clampedText: el.textContent,
            lineHeight,
            applyFallbackStyles,
        });
    };

    render() {
        const { text, linesCount, styles } = this.props;
        const {
            clampedText,
            lineHeight,
            applyFallbackStyles,
        } = this.state;

        const textContent = clampedText || text;

        const defaultStyles = {
            display: 'block',
            opacity: clampedText ? 1 : 0,
            ...styles,
        };

        const style = applyFallbackStyles ? {
            ...defaultStyles,
            overflow: 'hidden',
            lineHeight,
            maxHeight: `${linesCount * lineHeight}em`,
        } : defaultStyles;

        return (
            <span
                ref={this.textRef}
                style={style}
                styleName="clamped-text"
            >
                {tp.execute(textContent)}
            </span>
        );
    }
}

ClampLines.propTypes = {
    text: PropTypes.string.isRequired,
    linesCount: PropTypes.number.isRequired,
    delay: PropTypes.number,
    splitBy: PropTypes.string,
    styles: PropTypes.any,
};

const ClampLinesFC = (props) => {
    const {
        delay = null,
        splitBy = ' ',
    } = props;

    return (
        <ClampLines {...props} delay={delay} splitBy={splitBy} />
    );
};


export default ClampLinesFC;
