import React from 'react';
import PropTypes from 'prop-types';

import './rub.scss';


const Rub = ({ marginLeft = 0, fontWeight = null }) => {
    const style = {
        marginLeft: parseInt(marginLeft, 10),
        fontWeight: fontWeight || 'inherit',
    };

    return (
        <span styleName="rub__root" style={style}>₽</span>
    );
};


Rub.propTypes = {
    marginLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Rub;
