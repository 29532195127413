import React, {
    FC, useContext, SVGAttributes,
} from 'react';
import classNames from 'classnames';

import { LocaleContext } from 'app/containers/LocaleProvider';

import {
    // collections
    CATEGORY_TITLES,
    mostInterestingCategoryTitleForABTest,
    // categories
    CATEGORY_MOST_INTRESTING,
    CATEGORY_MAIN,
    CATEGORY_SOUPS,
    CATEGORY_SALADS,
    CATEGORY_BREAKFASTS,
    CATEGORY_DESSERT,
    CATEGORY_UPSELL,
    CATEGORY_SNACKS,
    CATEGORY_VITAMINS,
    CATEGORY_FARM_PRODUCTS,
} from 'app/const/categories';

import Best from 'app/assets/categories/best.component.svg';
import css from './MobileCategoryTab.module.scss';

interface ICategory {
    id: string,
}
interface IProps {
    category: ICategory
    isActive: boolean,
    onClick: (id: string) => void
}

type TypeTitlesCollection = {
    ru: Record<string, string>,
    en: Record<string, string>
}


const CategoriesIconsRecord: Record<string, FC<SVGAttributes<SVGElement>> | string> = {
    [CATEGORY_MOST_INTRESTING]: Best,
    [CATEGORY_MAIN]: 'main',
    [CATEGORY_SOUPS]: 'soups',
    [CATEGORY_SALADS]: 'salads',
    [CATEGORY_BREAKFASTS]: 'breakfasts',
    [CATEGORY_DESSERT]: 'desserts',
    [CATEGORY_UPSELL]: 'upsell',
    [CATEGORY_SNACKS]: 'snaks',
    [CATEGORY_VITAMINS]: 'vitamins',
    [CATEGORY_FARM_PRODUCTS]: 'farm',
};

const MobileCategoryTab: FC<IProps> = ({ category, isActive, onClick }: IProps) => {
    const { locale } = useContext(LocaleContext);

    const Icon = CategoriesIconsRecord[category.id];
    const textClasses = classNames({
        [css.mobileCategoryTitleActive]: isActive,
        [css.mobileCategoryTitle]: true,
    });
    const currentCategoryTitlesCollection: TypeTitlesCollection = category.id === 'intresting'
        ? mostInterestingCategoryTitleForABTest
        : CATEGORY_TITLES;

    const isBestCategory = category.id === CATEGORY_MOST_INTRESTING;

    const jpgIconClasses = classNames({
        [css.mobileCategoryIcon]: true,
        [css[Icon as string]]: !isBestCategory,
    });

    return (
        <div
            className={css.mobileCategoryTabRoot}
            onClick={() => onClick(category.id)}
            role="none"
        >
            {isBestCategory ? <Icon className={css.mobileCategoryIcon} /> : <div className={jpgIconClasses} />}
            <div className={textClasses}>
                {currentCategoryTitlesCollection[locale as keyof TypeTitlesCollection][category.id]}
            </div>
        </div>
    );
};

export default React.memo(MobileCategoryTab);
