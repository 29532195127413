import { gql, useFragment } from '@apollo/client';
import React from 'react';
import PropTypes from 'prop-types';


export const MarchDishShield = React.memo(({ dishId }) => {
    const { data } = useFragment({
        fragment: gql`
            fragment MARCH_SHIELD on menuDish {
                id
                metaLabel @client
            }
        `,
        from: { __ref: `menuDish:${dishId}` },
    });


    if (!data?.metaLabel) {
        return null;
    }

    return (
        <img
            className="march-label-img"
            src={data.metaLabel}
            alt={dishId}
        />
    );
});

MarchDishShield.propTypes = {
    dishId: PropTypes.string.isRequired,
};
