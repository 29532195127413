/* LIBS */
import React, { FC, useMemo, useState } from 'react';
import { useFragment } from '@apollo/client';

/* QUERIES */
import MobileDish from 'app/components/MobileDish';
import DesktopDish from 'app/components/DesktopDish';
import { useDishCustoms } from 'app/views/Hooks/useDishCustoms';
import MENU_DISH_FRAGMENT from './graphql/menuDishFragment.graphql';
import { useEextendedDishData } from '../hooks';


type DishCardLayouts = 'mobile' | 'desktop'
interface IDishDAC {
    id: string,
    cardLayout: DishCardLayouts,
    // TODO пропсы, не относящиеся к сущности menuDish, пока не типизирую, т.к. мы их возможно вынесем в хуки
    cardProps: unknown,
}

const CardLayoutMap: Record<DishCardLayouts, any> = {
    mobile: MobileDish,
    desktop: DesktopDish,
};


const DishDAC: FC<IDishDAC> = ({ id, cardLayout, cardProps }: IDishDAC) => {
    const { data } = useFragment<any>({
        fragment: MENU_DISH_FRAGMENT,
        from: {
            __ref: `menuDish:${id}`,
        },
    });

    const {
        id: dishId,
        title,
        titleEn,
        caption,
        captionEn,
        composition,
        cooking_time: cookingTime,
        weight,
        price,
        portions,
        previewImage,
        previewMobileImage,
        umAlias,
        category_id: categoryId,
        tags,
        visibleStatus,
        nutrition,
        // Кастомизация из селекста в карточке диша
        secondDishPart,
        replaceableIngredient,
    } = data;

    const [selectedDishId, setSelectedDishId] = useState(dishId);
    const { ingredientsList, isDishCardWithCustomSelect } = useDishCustoms(selectedDishId || '');

    const getEextendedDishData = useEextendedDishData();

    /* SELECTORS */
    const DishComponent = useMemo(() => CardLayoutMap[cardLayout], [cardLayout]);

    const onNextIdSelected = (nextId: string) => {
        setSelectedDishId(nextId);
    };

    return (
        <DishComponent
            id={selectedDishId}
            title={title}
            titleEn={titleEn}
            caption={caption}
            captionEn={captionEn}
            composition={composition}
            cooking_time={cookingTime}
            weight={weight}
            price={price}
            portions={portions}
            previewImage={previewImage}
            previewMobileImage={previewMobileImage}
            umAlias={umAlias}
            categoryId={categoryId}
            secondDishPart={secondDishPart}
            replaceableIngredient={replaceableIngredient}
            tags={tags}
            visibleStatus={visibleStatus}
            nutrition={nutrition}
            // Кастомизация
            isDishCardWithCustomSelect={isDishCardWithCustomSelect}
            selectedDishId={selectedDishId}
            onNextIdSelected={onNextIdSelected}
            getEextendedDishData={getEextendedDishData}
            ingredientsList={ingredientsList}
            {...cardProps as Object}
        />
    );
};

export default React.memo(DishDAC);
