import React from 'react';
import { ApolloProvider, gql } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import Raven from 'raven-js';
import { Helmet } from 'react-helmet';
import cookie from 'js-cookie';

import { paymentService } from 'global/services/paymentService/paymentService';

import { setDefaultLocale } from 'app/utils/date';

import configureRaven from 'app/configure-raven';
import { register as registerServiceWorker } from 'app/service-worker/initialize';
import { isStandaloneApp, getMobileOperatingSystem } from 'app/utils/browser';
import { ABTestDataProvider } from 'app/containers/contexts/abTest.context';
import { requestPermission } from './push-notifications';

import apolloClient from './apollo/client';
import { SAVE_PUSH_TOKEN } from './graphql/network/pushNotifications';

import PromoProvider from './containers/PromoProvider';
import DesignProvider from './containers/DesignProvider';
import WrappedAuthContainer from './containers/AuthContainer';
import LocaleProvder, { LOCALE_RU, LOCALE_EN } from './containers/LocaleProvider';
import LocationProvider from './containers/LocationProvider';
import { FiltersContextProvider } from './containers/contexts/filters.context/filters.context';
import { OverlayProvider } from './containers/contexts/overlay.context';
import { EnvDataSaverSPA } from './containers/EnvDataSaverSPA';
import { DataSaverUser } from './containers/DataSaverUser';

import AppRoutes from './AppRoutes';

import locales from './locales';

import './styles/variables/normalize.scss';
import { fragmentRegistry } from './apollo/fragmentRegistry';


configureRaven();
paymentService.configure();

/* REGISTRY */

// ROOT_MENU_DISH_ITEM
fragmentRegistry.register(gql`
    fragment ROOT_MENU_DISH_ITEM on menuDish{
        id
        title
        titleEn
        caption
        captionEn
        cooking_time
        cook_until
        composition
        weight
        price
        portions
        previewImage
        previewMobileImage
        verticalImage
        umAlias
        categoryId: category_id
        partnersComment
        parentId
        # BR-1104
        secondDishPart
        replaceableIngredient
        nutrition {
            carbohydrate {
                title
                value
                unit
            }
            fat {
                title
                value
                unit
            }
            protein {
                title
                value
                unit
            }
            calories {
                title
                value
                unit
            }
        }
        metaLabel @client
        tags {
            id
            erp_id
            title
            category_id
            category_name
        }
    }
`);

// ROOT_CART_DISH_ITEM
fragmentRegistry.register(gql`
    fragment ROOT_CART_DISH_ITEM on cartItem {
        dish_id
        type
        portions
        price
        discount_price
        promoDiscountPrice
        is_promo
    }
`);

// ROOT_CART_TOTAL
fragmentRegistry.register(gql`
    fragment ROOT_CART_TOTAL on cartTotal {
        # Легаси поля, от которых нужно отказаться
        total_price
        total_discount_price
        total_common_price
        delivery_price
        promocode_applied
        promocode_amount
        discount_amount
        static_discount_price
        static_common_price
        referral_amount
        # FRNT-2607
        # Новые поля, на которые нужно перейти и отказаться от использования полей выше
        dishesPrice
        deliveryPrice
        subscriptionDiscountAmount
        promocodeDiscountAmount
        specialDiscountAmount
        subscriptionPrice
        onlinePrice
        cashPrice
    }
`);

// ROOT_CART_DISCOUNT_CONDITIONS
fragmentRegistry.register(gql`
    fragment ROOT_CART_DISCOUNT_CONDITIONS on DiscountConditions {
        promocode
        promocode_status
        isCertificate
        isPromoCodeActivated
        isPromocodeCombineWithDiscount
        isPromocodeCombineWithAccount
        differenceBetweenPromocodeAndOrder
        isPromoCodeExists
        isSubscriptionActivated
        deliveriesCount
        discountType
        discountValue
        discountDuration
    }
`);

/* -------------------------------------------------------------------------- */
export default class App extends React.Component {
    state = {
        nativeAppToken: null,
        isUserFetched: false,
        isPushNotificationsSupported: true,
        locale: LOCALE_RU,
    };

    swRegistration = null;

    firebaseMessaging = null;

    isNativeAppTokenSaved = false;

    componentDidMount() {
        registerServiceWorker();

        this.initializeWebPushListener();
        this.initializeNativePushTokenSaving();

        const queryLocale = window.location.search.includes('lang=en') ? LOCALE_EN : LOCALE_RU;
        const sessionLocale = sessionStorage.getItem('lang');


        const locale = sessionLocale || queryLocale;

        sessionStorage.setItem('lang', locale);
        setDefaultLocale(locale);

        this.setState({ locale });
    }

    componentDidUpdate() {
        const { nativeAppToken, isUserFetched } = this.state;
        if (!this.isNativeAppTokenSaved) {
            if (nativeAppToken && isUserFetched) {
                this.saveNativeAppToken();
                this.isNativeAppTokenSaved = true;
            }
        }
    }

    getMessagingToken = () => {
        const firebaseMessagingKey = 'BH6cF_LpJbWXiDLHGd7fx80l6a2DFtIocXly6XXbdBbi_VEfz03KTPROuQjStHEGkSZVnhA4AY71dyGNbcK1-Yk';

        this.firebaseMessaging.usePublicVapidKey(firebaseMessagingKey);

        this.firebaseMessaging.getToken({
            vapidKey: firebaseMessagingKey,
        }).then((currentToken) => {
            apolloClient.mutate({
                mutation: SAVE_PUSH_TOKEN,
                variables: { token: currentToken },
            });
        }).catch((err) => {
            Raven.captureException(err);
        });
    };

    handleRequestNotificationPermission = () => {
        requestPermission().then((reg) => {
            this.swRegistration = reg;
            if (reg) {
                this.getMessagingToken();
            }
        }).catch((err) => {
            Raven.captureException(err);
            this.setState({
                isPushNotificationsSupported: false,
            });
        });
    };

    /*
        🤔🤔🤔🤔🤔🤔🤔🤔🤔🤔
        TODO: Понять, что за проблема
    */
    /* Возможно, проблема здесь */
    handleUserFetched = () => {
        this.setState((state) => {
            const { isUserFetched } = state;
            if (!isUserFetched) return { isUserFetched: true };
            return null;
        });
    };

    initializeWebPushListener() {
        const os = getMobileOperatingSystem();
        /* Поддерживаем web-пуши только в приложении для Андроид */
        if (!(os.android && isStandaloneApp())) {
            this.setState({
                isPushNotificationsSupported: false,
            });
            return;
        }

        const timer = setInterval(() => {
            if (!window.firebaseAnalytics) return;
            clearInterval(timer);

            try {
                const messaging = window.firebase.messaging();

                this.firebaseMessaging = messaging;
                if (Notification.permission === 'granted') {
                    this.getMessagingToken();
                    navigator.serviceWorker.ready.then((swReg) => {
                        this.swRegistration = swReg;
                    });
                    this.setState({
                        isPushNotificationsSupported: false,
                    });
                }
                if (Notification.permission === 'denied') {
                    this.setState({
                        isPushNotificationsSupported: false,
                    });
                }
                this.firebaseMessaging.onMessage((payload) => {
                    try {
                        this.swRegistration.showNotification(
                            payload.notification.title,
                            {
                                badge: payload.notification.icon,
                                body: payload.notification.body,
                                icon: payload.notification.icon,
                                requireInteraction: true,
                                data: {
                                    click_action: payload.notification.click_action,
                                },
                            },
                        );
                    } catch (e) {
                        Raven.captureException(e);
                    }
                });
                this.firebaseMessaging.onTokenRefresh(() => {
                    this.getMessagingToken();
                });
            } catch (e) {
                Raven.captureException(e);
                this.setState({
                    isPushNotificationsSupported: false,
                });
            }
        }, 500);
    }

    initializeNativePushTokenSaving() {
        const os = getMobileOperatingSystem();

        if (os.ios && isStandaloneApp()) {
            const setTokenToState = () => {
                const nativeTokenFromCookie = cookie.get('pushToken');
                const nativeTokenLegacy = localStorage.getItem('pushToken');
                const nativeToken = nativeTokenFromCookie || nativeTokenLegacy;
                if (nativeToken) {
                    this.setState({ nativeAppToken: nativeToken });
                }
                return nativeToken;
            };

            const timerId = setInterval(() => {
                const token = setTokenToState();
                if (token) {
                    clearInterval(timerId);
                }
            }, 5000);
        }
    }

    saveNativeAppToken() {
        const { nativeAppToken, isUserFetched } = this.state;

        if (nativeAppToken && isUserFetched) {
            apolloClient.mutate({
                mutation: SAVE_PUSH_TOKEN,
                variables: { token: nativeAppToken },
            });
        }
    }

    render() {
        const {
            isPushNotificationsSupported,
            locale,
        } = this.state;

        return (
            <ApolloProvider client={apolloClient}>
                <Helmet>
                    <link rel="canonical" href="https://elementaree.ru/menu/" />
                </Helmet>
                <LocaleProvder locales={locales} locale={locale}>
                    <OverlayProvider>
                        <BrowserRouter>
                            <WrappedAuthContainer onUserFetched={this.handleUserFetched}>
                                <LocationProvider>
                                    <FiltersContextProvider>
                                        <EnvDataSaverSPA />
                                        <DataSaverUser />
                                        <ABTestDataProvider>
                                            <DesignProvider>
                                                <PromoProvider
                                                    isPushNotificationsSupported={isPushNotificationsSupported}
                                                    onRequestPushPermission={this.handleRequestNotificationPermission}
                                                    onUserFetched={this.handleUserFetched}
                                                >
                                                    {(promoProps) => <AppRoutes promoProps={promoProps} />}
                                                </PromoProvider>
                                            </DesignProvider>
                                        </ABTestDataProvider>
                                    </FiltersContextProvider>
                                </LocationProvider>
                            </WrappedAuthContainer>
                        </BrowserRouter>
                    </OverlayProvider>
                </LocaleProvder>
            </ApolloProvider>
        );
    }
}
