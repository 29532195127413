
import React from 'react';

export const locales = {
    ru: {
        // renderToolTip
        title: <>
            Исключить&nbsp;из&nbsp;рациона
            <br />
            что&#8209;то&nbsp;еще?
            </>,
    },
    en: {
        // renderToolTip
        title: <>
            Want to exclude some
            <br />
            other ingredients?
            </>,
    },
};
