import React from 'react';
import PropTypes from 'prop-types';

import { formatPeriod, getDateData } from 'app/utils/date';

import Icon from 'app/assets/svg/calendar.svg';

import FilterItem from '../FilterItem';
import PeriodList from './PeriodList';
import { locales } from './period.locales';


export default class Periods extends React.PureComponent {
    state = {
        isExpanded: false,
    }

    handleToggle = (isExpanded) => {
        const { itemKey, onExpand } = this.props;
        onExpand({ itemKey, isExpanded });
        this.setState({
            isExpanded,
        });
    }

    renderPeriodSubtitle = () => {
        const {
            selectedDate,
            menuDatesFilterPeriod,
            periods,
            selectedPeriod,
            locale,
        } = this.props;
        const activePeriod = periods.find((period) => period.start === selectedPeriod);

        if (!activePeriod || !activePeriod.start) {
            return '';
        }

        if (activePeriod.start === menuDatesFilterPeriod) {
            const dateData = getDateData(selectedDate);
            const { date, monthShortNameForDate } = dateData;
            return `${date} ${monthShortNameForDate}`;
        }

        if (activePeriod.title) {
            return activePeriod.title[locale];
        }

        return formatPeriod(activePeriod);
    }

    render() {
        const {
            periods, selectedPeriod, onSelect, locale,
        } = this.props;
        const { isExpanded } = this.state;

        // Locale content data
        const { periodTitle } = locales[locale];

        return (
            <FilterItem
                title={periodTitle}
                subtitle={this.renderPeriodSubtitle}
                Icon={Icon}
                isExpanded={isExpanded}
                onToggle={this.handleToggle}
            >
                <PeriodList
                    isExpanded={isExpanded}
                    periods={periods}
                    selectedPeriod={selectedPeriod}
                    onSelect={onSelect}
                    locale={locale}
                />
            </FilterItem>
        );
    }
}


Periods.propTypes = {
    // menuAbTestValue: PropTypes.string.isRequired,

    selectedDate: PropTypes.string.isRequired,
    menuDatesFilterPeriod: PropTypes.string.isRequired,
    periods: PropTypes.arrayOf(PropTypes.shape({
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
    })).isRequired,
    selectedPeriod: PropTypes.string.isRequired,
    itemKey: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    onExpand: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
};
