
import agataLabel from './img/agata.img.svg';
import claraLabel from './img/clara.img.svg';
import cocoLabel from './img/coco.img.svg';
import fridaLabel from './img/frida.img.svg';
import karolinaLabel from './img/karolina.img.svg';
import mariaLabel from './img/maria.img.svg';

/* BR-1052 LEETCODE */

const labelMap = new Map([
    ['3945', agataLabel],
    ['3947', claraLabel],
    ['3948', cocoLabel],
    ['3941', fridaLabel],
    ['3943', karolinaLabel],
    ['3942', mariaLabel],
]);

/**
 * @accessor metaLabel @client
 */
export const metaLabelField = {
    read(_, client) {
        const id = client.readField('id');
        return labelMap.get(id) || null;
    },
};
