import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import queryString from 'query-string';

import { getUTMParams } from 'app/utils/utmParams';


export const LINK_TYPE_NAV = 'link-type-nav';
export const LINK_TYPE_ROUTE = 'link-type-route';
export const LINK_TYPE_SIMPLE = 'link-type-simple';


function getGaClientId() {
    try {
        const tracker = window.ga.getAll()[0];
        return tracker.get('clientId');
    } catch (error) {
        return null;
    }
}

function getAdmintAdUID() {
    const query = queryString.parse(window.location.search);
    return query.admitad_uid;
}

function getPartnerParam() {
    const query = queryString.parse(window.location.search);
    return query.partner;
}


function getAdditionalParams() {
    const additionalParams = [
        {
            key: 'ga_client_id',
            value: getGaClientId(),
        },
        {
            key: 'admitad_uid',
            value: getAdmintAdUID(),
        },
        {
            key: 'partner',
            value: getPartnerParam(),
        },
    ];

    return additionalParams
        .filter(p => Boolean(p.value))
        .reduce((acc, param) => { acc[param.key] = param.value; return acc; }, {});
}

export default function Link({
    type = LINK_TYPE_SIMPLE,
    href,
    children,
    state = null,
    activeClassName = '',
    className = '',
    exact = false,
    ...restProps
}) {
    const parsedUrl = new URL(href, window.location.origin);

    const utmParams = getUTMParams();
    const additionalParams = getAdditionalParams();


    Object.keys(utmParams).forEach((key) => {
        parsedUrl.searchParams.set(key, utmParams[key]);
    });

    const isExternalLink = parsedUrl.hostname !== window.location.hostname;
    if (isExternalLink) {
        Object.keys(additionalParams).forEach((key) => {
            parsedUrl.searchParams.set(key, additionalParams[key]);
        });
    }

    const { pathname, search, hash } = parsedUrl;

    if (type === LINK_TYPE_NAV) {
        return (
            <NavLink
                to={{
                    pathname,
                    search,
                    hash,
                    state,
                }}
                activeClassName={activeClassName}
                className={className}
                exact={exact}
                {...restProps}
            >
                {children}
            </NavLink>
        );
    }
    if (type === LINK_TYPE_ROUTE) {
        return (
            <RouterLink
                to={{
                    pathname,
                    search,
                    hash,
                    state,
                }}
                activeClassName={activeClassName}
                className={className}
                exact={exact}
                {...restProps}
            >
                {children}
            </RouterLink>
        );
    }

    return (
        <a
            href={parsedUrl.toString()}
            className={className}
            {...restProps}
        >
            {children}
        </a>
    );
}

Link.propTypes = {
    type: PropTypes.oneOf([LINK_TYPE_NAV, LINK_TYPE_SIMPLE, LINK_TYPE_ROUTE]),
    href: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    state: PropTypes.shape({}),
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    exact: PropTypes.bool,
};
