import React, { useReducer, useEffect, useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import forEachRight from 'lodash/forEachRight';
import isEmpty from 'lodash/isEmpty';

import Dialog from 'app/components/Dialog/Dialog';
import SelectMobile from 'app/components/Select';


const OPEN = 'open';
const CLOSE = 'close';

/**
 * @description Дефолтное время открытия/закрытия оверлея (взято из Main)
*/
export const OVERLAY_ANIMATION_TIME = 700;

/**
 * @description Объект содержит объекты, которые содержат предустановленные пропсы для оверлея.
 * Использовние позволяет сократить количество передаваемых параметров при создании нового оверлея.
*/
const OVERLAY_TEMPLATES = {
    // сайдбар, всплывающий справа
    // примеры: MobileFilters
    right: {
        animateEnter: true,
        animateExit: true,
        animationDirection: 'right',
    },
    bottom: {
        animateEnter: true,
        animateExit: true,
        animationDirection: 'bottom',
    },
    dialog: {
        animateEnter: true,
        animateExit: true,
        animationDirection: 'dialog-bottom',
        isNeedBlur: true,
    },

};


/**
 * @description Функция вычисляет количество оверлеев с верхушки стека до определенного id (невключительно), которые нужно закрыть.
 *
 * @param {Array} stack - стек оверлеев. У каждого оверлея в стеке имеется уникальный id.
 * @param {string} id - id оверлея, до которого нужно закрыть оверлеи, расположенные выше в стеке.
*/
const calculateMaxValueById = ({ stack, id }) => {
    const reversedIndex = stack
        .slice()
        .reverse()
        .findIndex((i) => i.id === id);

    return reversedIndex;
};

/**
 * @description Функция определяет количество оверлеев, которые нужно закрыть и задает им состояние закрытия.
 *
 * @param {Array} stack - стек оверлеев. У каждого оверлея в стеке имеется уникальный id.
 * @param {Object} uiStates - объект, который содержит UI-состояния оверлеев (пока только состояния открытя/закрытия).
 * @param {Object} uiStates.id - объект который содержит UI-состояния оверлея с указанным id.
 * @param {number} quantityToClose - количество оверлеев с конца стека, которые нужно закрыть.
 * @param {string} id - id оверлея, до которого нужно закрыть оверлеи, расположенные выше в стеке.
*/
const closeOverlays = ({ stack, uiStates, quantityToClose, id }) => {
    if (isEmpty(stack)) return stack;

    const isNumber = typeof quantityToClose === 'number';
    if (!id && !isNumber) return stack;

    const certainItem = stack.find((i) => i.id === id);
    if (id && !certainItem) return stack;

    // по умолчанию закрывается только последний оверлей
    let counter = 0;
    let maxValue;

    if (id) maxValue = calculateMaxValueById({ stack, id });
    if (quantityToClose) maxValue = quantityToClose;

    const newUiStates = stack
        .reduceRight((acc, i) => {
            const currentUiStateItem = uiStates[i.id];
            const unchangedAcc = { ...acc, [i.id]: currentUiStateItem };

            if (counter >= maxValue) return unchangedAcc;
            if (uiStates[i.id]?.openingState === CLOSE) return unchangedAcc;

            counter += 1;

            const newCurrentUiStateItem = { ...uiStates[i.id], openingState: CLOSE };
            return { ...acc, [i.id]: newCurrentUiStateItem };
        }, {});

    return newUiStates;
};


/**
 * @param {Object} action - стандартный объект экшена: { type: '...', payload: {...} }.
 *
 * @param {Array} state.stack - стек оверлеев. Каждый элемент содержит параметры,
 * которые становятся пропсами компонента Overlay в OverlaysContayner.
 * Возможные параметры элемента в stack:
 *      @param {Object} stackItem
 *      @param {string} stackItem.id - обязательный параметр. Уникальный 'id' оверлея. Двух оверлеев с одним id не может быть в стеке
 *      @param {Object} stackItem.overlayProps - Содержит пропсы для компонента оверлея, отвечающие за внешний вид:
 *      isNeedScroll, animateEnter, animateExit, animationDirection, dialog и др.
 *      @param {function} stackItem.onClick - необязательный параметр. Функция срабатывает при клике по слою оверлея.
 *      Если не задана специфичная функция, то в 'OverlaysContainer' будет использоваться 'closeLastOverlays' (закрыть последний оверлей).
 *      @param {function} stackItem.render - функция рендера. Вместо 'render' можно использовать 'children'.
 *      @param {ReactComponent} stackItem.children - Контент для отображения. Вместо 'children' можно использовать 'render'.
 *
 * @param {Object} state.uiStates - объект UI-состояний оверлеев, находящихся в стеке.
 * Доступ к состояниям можно получить через id оверлея: 'stack.id'.
 * Возможные параметры элемента в uiStates:
 *      @param {Object} uiStatesItem
 *      @param {Object} uiStatesItem.openingState - 'open'/'close'
 */
const reducer = (state, action) => {
    const { type, payload = {} } = action;
    const { stack, uiStates } = state;

    switch (type) {
        /**
         * @description Экшен добавляет параметры нового оверлея в стек оверлеев.
         * В payload указываются параметры, которые в 'OverlaysContainer' становятся пропсами оверлея.
         * UI-состояние никак не задается в этом экшене, оно будет задано в экшене 'showOverlay'
         * (иначе не будет срабатывать анимация появления).
         *
         * @param {string} payload.id - поле описано в параметрах к reducer.
         * @param {function} payload.onClick - поле описано в параметрах к reducer.
         * @param {function} payload.render - поле описано в параметрах к reducer.
         * @param {ReactComponent} payload.children - поле описано в параметрах к reducer.
         * @param {string} payload.template - Шорткат для задания предустановленных пропсов из 'OVERLAY_TEMPLATES'
         * для компонента оверлея. Вместо 'template' можно использовать 'overlayProps', передавая все параметры вручную.
         * При наличии и 'template' и 'overlayProps' параметры из 'overlayProps' будут в приоритете.
         * @param {Object} payload.overlayProps - Содержит пропсы для компонента оверлея, отвечающие за внешний вид:
         * animateEnter, animateExit, animationDirection, dialog и др.
         * Вместо 'overlayProps' можно использовать 'template', задавая предустановленные параметры одним полем.
         * При наличии и 'template' и 'overlayProps' параметры из 'overlayProps' будут в приоритете.
        */
        case 'pushOverlay': {
            // защита от повторного открытия оверлея с таким же id
            const isAlreadyExist = stack.find(({ id }) => id === payload.id);
            if (isAlreadyExist) {
                console.warn(`overlayId '${payload.id}' already exists`);
                return state;
            }

            const combinedOverlayProps = { ...OVERLAY_TEMPLATES[payload.template], ...payload.overlayProps };

            const newItem = !payload.template
                ? payload
                : { ...payload, overlayProps: combinedOverlayProps };

            return { ...state, stack: [...stack, newItem] };
        }

        /**
         * @description Экшен задает UI-состояние CLOSE для одного или нескольких оверлеев, начиная с верхушки стека.
         *
         * @param {number|undefined|Array} payload.overlaysToClose - необязательный параметр.
         * - Если передан, то закрывается указанное количество оверлеев.
         * - Если не передан, то закрывается только последний оверлей.
         * - Если передан массив, то закрывается количество оверлеев, равное длине этого массива. Элементы массива при этом не важны.
         * CONVENTION: для удобства анализа, если нужно закрыть несколько оверлеев, то указывать параметром в overlaysToClose
         * массив id оверлеев, которые нужно закрывать:
         * 'closeLastOverlays(['dialog', 'basket-preview'])' - понятно, что при клике по кнопке закроются окно диалога и превью корзины.
         * 'closeLastOverlays(2)' - почему 2? почему не 3?
        */
        case 'closeLastOverlays': {
            const { overlaysToClose } = payload;

            if (isEmpty(stack)) return state;

            let quantityToClose = 1;

            if (overlaysToClose instanceof Array) quantityToClose = overlaysToClose.length;

            // проверка на 'number' необходима, т.к. в аргументы может попасть объект Event: 'onClick={closeLastOverlays}'.
            if (typeof (overlaysToClose) === 'number') quantityToClose = overlaysToClose;

            const newUiStates = closeOverlays({ stack, uiStates, quantityToClose });

            return { ...state, uiStates: newUiStates };
        }

        /**
         * @description Экшен задает UI-состояние CLOSE для всех оверлеев, которые находятся выше указанного.
         * Пример, находясь в корзине удобно закрыть все диалоговые окна, отображенные поверх оверлея корзины.
         * При отсутствии/несовпадения с имеющимися id в стеке - ничего не происходит.
         *
         * @param {string} payload.id - id оверлея, до которого нужно закрыть все выше расположенные оверлеи.
        */
        case 'closeOverlaysTilId': {
            if (isEmpty(stack)) return state;
            if (isEmpty(payload)) return state;

            const certainOverlay = stack.find(({ id }) => id === payload.id);
            if (!certainOverlay) return state;

            const newUiStates = closeOverlays({ stack, uiStates, id: payload.id });

            return { ...state, uiStates: newUiStates };
        }

        /**
         * @description Экшен задает UI-состояние CLOSE для всех оверлеев.
        */
        case 'closeAllOverlays': {
            if (isEmpty(stack)) return state;

            const quantityToClose = stack.length;

            const newUiStates = closeOverlays({ stack, uiStates, quantityToClose });

            return { ...state, uiStates: newUiStates };
        }

        /**
         * @description Экшен задает UI-состояние CLOSE для конкретного оверлея.
         * При отсутствии/несовпадения с имеющимися id в стеке - ничего не происходит.
         * NOTE: Это нарушает правила работы стека и вообще доволльно специфичный метод, возможно, он не нужен.
         *
         * @param {string} payload.id - id оверлея, который нужно закрыть.
        */
        case 'closeCertainOverlay': {
            if (isEmpty(stack)) return state;
            if (isEmpty(payload)) return state;

            const certainOverlay = stack.find(({ id }) => id === payload.id);
            if (!certainOverlay) return state;

            const newUiStatesItem = { [payload.id]: { ...uiStates[payload.id], openingState: CLOSE } };
            const newUiStates = { ...uiStates, ...newUiStatesItem };

            return { ...state, uiStates: newUiStates };
        }

        // SERVICE ACTIONS:
        /**
         * @description Экшен задает UI-состояние OPEN для конкретного оверлея. Это триггерит начало анимации появления.
         * Вызывается автоматически через useEffect.
         *
         * @param {string} payload.id - id оверлея, который нужно анимировать.
        */
        case 'showOverlay': {
            if (isEmpty(stack)) return state;

            const certainOverlay = stack.find(({ id }) => id === payload.id);
            if (!certainOverlay) return state;

            const newUiStates = { ...uiStates, [payload.id]: { openingState: OPEN } };

            return { ...state, uiStates: newUiStates };
        }

        /**
         * @description Экшен удаляет конкретный оверлей из стека.
         * Вызывается автоматически через useEffect.
         *
         * @param {string} payload.id - id оверлея, который нужно удалить.
        */
        case 'popOverlay': {
            if (isEmpty(stack)) return state;

            const ids = stack.map(({ id }) => id);

            const newStack = stack.filter(({ id }) => id !== payload.id);
            const newUiStates = ids.reduce((acc, id) => {
                if (id === payload.id) return acc;

                return { ...acc, [id]: uiStates[id] };
            }, {});

            return {
                ...state,
                stack: newStack,
                uiStates: newUiStates,
            };
        }

        default: return state;
    }
};


const noop = () => {};

const initialDispatchState = {
    dispatch: noop,
    pushOverlay: noop,
    pushDialogOverlay: noop,
    pushSelectMobileOverlay: noop,
    closeLastOverlays: noop,
    closeAllOverlays: noop,
    closeOverlaysTilId: noop,
    closeCertainOverlay: noop,
};
const initialDataState = {
    stack: [],
    uiStates: {},
};

export const OverlayContextDispatch = React.createContext(initialDispatchState);
export const OverlayContextData = React.createContext(initialDataState);

export const OverlayProvider = ({ children }) => {
    const [overlayState, dispatch] = useReducer(reducer, initialDataState);

    /**
     * @description useEffect нужен для старта анимации появления оверлея.
     * Для каждого новосозданного оверлея выполняется экшен 'showOverlay' c 'id' этого нового оверлея.
     */
    useEffect(() => {
        const { stack, uiStates } = overlayState;

        if (isEmpty(stack)) return;

        // работаем со стеком, поэтому обход справа налево
        forEachRight(stack, (i) => {
            const { id } = i;

            const overlayUiStatesItem = uiStates[id];
            if (!overlayUiStatesItem) {
                setTimeout(
                    () => dispatch({ type: 'showOverlay', payload: { id } }),
                    0,
                );
            }
        });
    }, [overlayState]);

    /**
     * @description useEffect нужен для удаления оверлея из стека только после анимации его скрытия.
     * Для каждого оверлея, у которого состояние 'CLOSE' запускается таймер на выполнение экшена 'popOverlay' с 'id' этого оверлея.
     */
    useEffect(() => {
        const { stack, uiStates } = overlayState;

        if (isEmpty(stack)) return;

        // работаем со стеком, поэтому обход справа налево
        forEachRight(stack, (i) => {
            const { id } = i;

            const overlayUiStatesItem = uiStates[id];
            if (overlayUiStatesItem?.openingState === CLOSE) {
                setTimeout(
                    () => dispatch({ type: 'popOverlay', payload: { id } }),
                    OVERLAY_ANIMATION_TIME,
                );
            }
        });
    }, [overlayState]);

    const pushOverlay = useCallback((payload) => dispatch({ type: 'pushOverlay', payload }), []);
    const closeLastOverlays = useCallback((overlaysToClose) => dispatch({ type: 'closeLastOverlays', payload: { overlaysToClose } }), []);
    const closeOverlaysTilId = useCallback(({ id }) => dispatch({ type: 'closeOverlaysTilId', payload: { id } }), []);
    const closeAllOverlays = useCallback(() => dispatch({ type: 'closeAllOverlays' }), []);
    const closeCertainOverlay = useCallback(({ id }) => dispatch({ type: 'closeCertainOverlay', payload: { id } }), []);

    /* функция для упрощенного пуша оверлея диалога */
    const pushDialogOverlay = useCallback((id, dialogData, payload = {}) => {
        const overlayProps = dialogData.notifyOnly ? {
            onClick: dialogData.onConfirm,
        } : {};
        const onReject = dialogData.onReject ?? closeLastOverlays;

        pushOverlay({
            id,
            template: 'dialog',
            children: <Dialog {...dialogData} onReject={onReject} />,
            overlayProps,
            ...payload,
        });
    }, [pushOverlay, closeLastOverlays]);

    /* функция для упрощенного пуша оверлея селекта */
    const pushSelectMobileOverlay = useCallback((id, dialogData, payload = {}) => {
        pushOverlay({
            id,
            template: 'dialog',
            children: <SelectMobile {...dialogData} />,
            ...payload,
        });
    }, [pushOverlay]);

    const dispatchValue = useMemo(() => ({
        dispatch,
        pushOverlay,
        closeLastOverlays,
        closeOverlaysTilId,
        closeAllOverlays,
        closeCertainOverlay,
        pushDialogOverlay,
        pushSelectMobileOverlay,
    }), [
        dispatch,
        pushOverlay,
        closeLastOverlays,
        closeAllOverlays,
        closeOverlaysTilId,
        closeCertainOverlay,
        pushDialogOverlay,
        pushSelectMobileOverlay,
    ]);

    return (
        <OverlayContextDispatch.Provider value={dispatchValue}>
            <OverlayContextData.Provider value={overlayState}>
                {children}
            </OverlayContextData.Provider>
        </OverlayContextDispatch.Provider>
    );
};

export const withOverlayData = (WrappedComponent) => (forwardedProps) => {
    const data = useContext(OverlayContextData);

    return (
        <WrappedComponent
            overlayDataContext={data}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...forwardedProps}
        />
    );
};
export const withDispatchOverlay = (WrappedComponent) => (forwardedProps) => {
    const dispatchValue = useContext(OverlayContextDispatch);

    return (
        <WrappedComponent
            dispatchOverlayContext={dispatchValue}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...forwardedProps}
        />
    );
};


OverlayProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
