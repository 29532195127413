import React, {
    useContext, useState, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { Portal } from 'react-portal';

import { analyticService } from 'global/services';
import {
    SAVE_CUSTOMIZATION_COMMENT,
    VIEW_CUSTOMIZATION_COMMENT,
} from 'app/graphql/network/customizationComment';

import {
    NO_PORK,
    NO_FISH,
    NO_MEAT_AND_FOWL,
    NO_MUSHROOMS,
    VEG,
    VEGAN,
    NO_GARLIС,
    NO_LACTOSE,
    filterlocales,
} from 'app/utils/filters';
import { tp } from 'app/utils/typograf';
import Raven from 'raven-js';

import { LocaleContext } from 'app/containers/LocaleProvider';
import { UIHeading, UITag } from 'app/components/ui';
import { DialogAnimated } from 'app/components/Dialog';

import { locales } from './basket-customization-locales';
import s from './basket-customization-tags.scss';


const tagsOrder = [
    NO_PORK,
    NO_FISH,
    NO_MEAT_AND_FOWL,
    NO_MUSHROOMS,
    VEG,
    VEGAN,
    NO_GARLIС,
    NO_LACTOSE,
];

const parseComment = (comment) => {
    try {
        return comment.split(',').map((tag) => tag.trim());
    } catch (e) {
        Raven.captureException(e, { extra: { comment } });
        return [];
    }
};
const joinComment = (comment) => comment.join(', ');

export const getCommentIds = (comment) => {
    const tagTexts = parseComment(comment);

    const tagIds = Object.entries(filterlocales.ru)
        .map(([id, title]) => {
            const isTag = tagTexts.includes(title);
            return isTag ? id : null;
        })
        .filter((tagId) => Boolean(tagId));

    return tagIds;
};

const getCommentText = (tags) => {
    const tagTexts = tags.map((tagId) => filterlocales.ru[tagId]);
    return joinComment(tagTexts);
};


export function BasketCustomizationTags(props) {
    const { handleSetIsCustomizationEdited } = props;
    const { locale } = useContext(LocaleContext);
    const l = locales[locale];

    // console.log(l, 'l');

    const {
        data: { viewCustomizationComment },
        loading,
        refetch,
    } = useQuery(VIEW_CUSTOMIZATION_COMMENT);
    const [updateCustomComment] = useMutation(SAVE_CUSTOMIZATION_COMMENT, {
        onCompleted() {
            refetch();
        },
    });

    const initialComment = loading ? '' : (viewCustomizationComment.comment || '');
    const [selectedTags, setSelectedTags] = useState(getCommentIds(initialComment));

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    // const [hasTagsContradiction, setHasTagsContradiction] = useState(false);
    const [dialogState, setDialogState] = useState(null);
    const dialogTitleRef = useRef('strongText');

    const handleToggleTag = useCallback((e) => {
        const id = e.target.value;

        const isTagChecking = !selectedTags.includes(id);
        const nextSelectedTags = isTagChecking ? selectedTags.concat(id) : selectedTags.filter((t) => t !== id);

        // check if dish in basket has tag equal to customization tag

        setSelectedTags(nextSelectedTags);
        setHasUnsavedChanges(true);

        analyticService.push({
            eventName: 'Custom_Tag_Toggle',
            sourceName: 'basket',
            tagName: filterlocales.ru[id],
            state: isTagChecking ? 'on' : 'off',
        });
    }, [selectedTags, setSelectedTags, setHasUnsavedChanges]);

    const handleApplyTags = useCallback(
        async () => {
            const comment = getCommentText(selectedTags);

            try {
                await updateCustomComment({ variables: { comment } });
                await refetch();

                setDialogState('success');
                setHasUnsavedChanges(false);
                handleSetIsCustomizationEdited(true);

                analyticService.push({
                    eventName: 'Custom_Apply',
                    sourceName: 'basket',
                });
            } catch (e) {
                Raven.captureException(e);
                setDialogState('error');
            }
        },
        [selectedTags, updateCustomComment, setHasUnsavedChanges, handleSetIsCustomizationEdited, refetch],
    );

    const handleCloseDialog = useCallback(() => {
        setDialogState(null);
        dialogTitleRef.current = 'strongTextSecond';
    }, [setDialogState]);

    const dialogTexts = l.dialog[dialogState];

    return (
        <div className={s['basket-customization-gb-wrapper']}>
            <div className={s['basket-customization__root']}>
                <UIHeading level="3.2">
                    {tp.execute(l.title)}
                </UIHeading>
                <div className={s['basket-customization__description']}>
                    {l.description.map((line) => (
                        <React.Fragment key={line}>
                            {tp.execute(line)}
                            <br />
                        </React.Fragment>
                    ))}
                </div>

                <div className={s['basket-customization__tags']}>
                    {tagsOrder.map((id) => {
                        const isSelected = selectedTags.includes(id);
                        return (
                            <div key={id}>
                                <UITag
                                    id={id}
                                    isSelected={isSelected}
                                    disabled={false}
                                    text={l[id]}
                                    onChange={handleToggleTag}
                                    colorType="grey-back"
                                />
                            </div>
                        );
                    })}
                </div>

                <div>
                    <button
                        type="button"
                        className={s['basket-customization__apply']}
                        onClick={handleApplyTags}
                        disabled={!hasUnsavedChanges}
                    >
                        {l.apply}
                    </button>
                </div>

                <Portal>
                    <DialogAnimated
                        {...dialogTexts}
                        strongText={dialogTexts ? dialogTexts[dialogTitleRef.current] : ''}
                        isOpen={Boolean(dialogState)}
                        onConfirm={handleCloseDialog}
                        notifyOnly
                        oneRowButtons
                    />
                </Portal>
            </div>
        </div>
    );
}

BasketCustomizationTags.propTypes = {
    handleSetIsCustomizationEdited: PropTypes.func.isRequired,
};
