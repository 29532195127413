import React from 'react';

import './error-content.scss';

export default function ErrorContent() {
    return (
        <div>
            <p styleName="content">
                Что-то пошло не&nbsp;так...
                <br />
                Пожалуйста, обновите страницу!
            </p>
            <p styleName="content">
                Или напишите нам в
                {' '}
                <a
                    styleName="link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://m.me/elementaree"
                >
                    Facebook
                </a>
                &nbsp;или на&nbsp;
                <a
                    styleName="link"
                    href="mailto:support@elementaree.ru"
                >
                    Почту
                </a>
            </p>
        </div>
    );
}
