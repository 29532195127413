/* LIBS */
import React, {
    useState, useEffect, useRef, useContext,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Velocity from 'velocity-animate';
import Raven from 'raven-js';

/* COMPONENTS */
import BasketIcon2 from 'assets/svg/basket2.svg';
import DishImage from 'app/components/DishImage';
import PortionSelect from 'app/components/PortionSelect';
import Link from 'app/components/Link';
import { DishImageShield } from 'app/components/ui/Elements/DishImageShield/DishImageShield';

/* UTILS */
import formatPrice from 'app/utils/price';
import {
    SOLD_OUT_STATUS,
    menuImageSize,
    menuImageSizeMobile,
    getImageUrl,
    getPriceForSubscription,
} from 'app/utils/dish';

import {
    CATEGORIES_WITH_PORTION_ALIAS,
} from 'app/const/categories';
import { LocaleContext } from 'app/containers/LocaleProvider';
import { CustomIngredientsSelectDishRow } from 'app/views/Menu/Menu/components/CustomIngredientsSelectDishRow';

/* UI */
import { MarchDishShield } from '../ui/Elements/MarchShield';
import { DishAdLabel } from '../ui/Elements/DishAdLable/DishAdLabel';
import UIParterLogoShield, { TEFAL_DISHES_ID } from '../ui/Elements/PartnerLogoShield';

import { locales } from './desktopDish.locales';

import './desktop-dish.scss';

const offsetOptions = {
    top: 50,
    bottom: 40,
};

export const DesktopDish = ({
    caption = null,
    captionEn = null,
    canApplySoldOutStyles = false,
    parentRef = {},
    scrollPosition = null,
    createClearBasketWithDialogHandler = (cb) => () => cb(),

    portions,
    spaDishObserver,
    addToBasketAnimationTarget,
    id,
    title,
    titleEn,
    price,
    onClickAddToBasket,
    categoryId,
    openDishDetails,
    index,
    visibleStatus,
    umAlias: umAliasProp,
    weight,
    previewImage,
    previewMobileImage,
    isSubscriptionActivated,
    useLazyLoading,
    showFilterChangedAnimation,
    dishDetailsPathname,
    dispatchOnCardClickAnalytic,
    tags,
    isDishCardWithCustomSelect,
    selectedDishId,
    onNextIdSelected,
    ingredientsList,
}) => {
    const [cardHeight, setCardHeight] = useState(null);
    const [cardWidth, setCardWidth] = useState(null);
    const [isAnimatedAddingToBasket, setIsAnimatedAddingToBasket] = useState(false);
    const [portion, setPortion] = useState(portions[0]);

    const wrapperRef = useRef(null);
    const containerRef = useRef(null);

    const localeContext = useContext(LocaleContext);
    const { locale } = localeContext;

    useEffect(() => {
        const ref = wrapperRef.current;
        spaDishObserver.observe(ref);
        return () => spaDishObserver.unobserve(ref);
    }, [spaDishObserver]);

    const executeAddToBasketAnimation = async ({ onAniationEnd }, cardEl, wrapEl) => {
        const SCALE_DURATION = 700;
        const FLY_DURATION = 700;

        const REDUCE_DURATION = 500;
        const REDUCE_DELAY = SCALE_DURATION + FLY_DURATION / 4;

        Promise.all([
            /* Анимация уменьшения и полета карточки блюда */
            Velocity(
                cardEl,
                { scale: 0.8 },
                { duration: SCALE_DURATION, easing: [381, 20] },
            ).then(() => {
                Velocity(
                    cardEl,
                    addToBasketAnimationTarget,
                    { duration: FLY_DURATION, easing: 'ease-in-out' },
                );
            }),
            /*
                Анимация уменьшения размеров родителя карточки,
                чтобы соседнее блюдо плавно заняло его место
            */
            Velocity(
                wrapEl,
                { height: 0, width: 0, margin: 0 },
                {
                    delay: REDUCE_DELAY,
                    duration: REDUCE_DURATION,
                    easing: 'ease-in-out',
                },
            ),
        ]).then(() => {
            onAniationEnd();
        }).catch((e) => {
            Raven.captureException(e);
            onAniationEnd();
        });
    };

    const animateAddingDishToBasket = ({ onAniationEnd }) => {
        const cardEl = containerRef.current;
        const wrapEl = wrapperRef.current;

        setIsAnimatedAddingToBasket(true);
        setCardHeight(wrapEl.clientHeight);
        setCardWidth(wrapEl.clientWidth);

        executeAddToBasketAnimation({ onAniationEnd }, cardEl, wrapEl);
    };

    const handleAddingAnimationEnd = () => {
        onClickAddToBasket({
            id,
            dishId: id,
            portion,
            title,
            caption,
            price,
            categoryId,
            tags,
        });
    };

    const handleDishToBasket = () => {
        /*
            Фактический вызов добавления блюда происходит после анимации полета блюда,
            чтобы не тормозить анимацию пересчетом virtual DOM-а
            Когда-нибудь можно будет подумать над тем, как разгрузить обновление DOM-а после запроса корзины
        */
        animateAddingDishToBasket({
            onAniationEnd: handleAddingAnimationEnd,
        });
    };

    const handleChangePortion = ({ value }) => {
        setPortion(value);
    };

    const handleOpenDishDetails = (pathname, e) => {
        e.preventDefault();
        openDishDetails(pathname);
    };

    const dishPrice = isSubscriptionActivated ? getPriceForSubscription(price) : price;
    const imageUrl = getImageUrl({ url: previewImage, ...menuImageSize });
    const imageUrlMobile = getImageUrl({ url: previewMobileImage, ...menuImageSizeMobile });

    const isSoldOut = canApplySoldOutStyles && visibleStatus === SOLD_OUT_STATUS;

    const { search } = window.location;
    const pathname = `${dishDetailsPathname}${id}/`;
    const href = `${dishDetailsPathname}${id}/${search}`;

    const umAlias = umAliasProp || ['г', 'г', 'г', 'г'];

    const useCustomPortionAlias = umAlias[0] !== 'г';
    const isDishWithPortionAlias = CATEGORIES_WITH_PORTION_ALIAS.includes(categoryId);

    const dishTitle = locale === 'en' ? (titleEn || title) : title;
    const dishCaption = locale === 'en' ? (captionEn || caption) : caption;
    const portionText = {
        ru: isDishWithPortionAlias && useCustomPortionAlias ? umAlias[1] : locales[locale].priceDescriptionPortion,
        en: locales[locale].priceDescriptionPortion,
    };


    const wrapStyle = {
        height: cardHeight,
    };

    const wrapClasses = classNames({
        'dish-card__wrapper': true,
        exiting: Boolean(cardHeight),
    });

    const dishCardDescriptionWrapperClasses = classNames({
        'dish-card__wrap': true,
    });

    const dishCardPriceWrapperClasses = classNames({
        'dish-card__content--price': true,
        'sold-out': isSoldOut,
    });

    const cardClasses = classNames({
        'dish-card': true,
        // 'sold-out': isSoldOut,
        'is-animated': isAnimatedAddingToBasket,
        'filter-changed-animation': showFilterChangedAnimation,
    });

    const dishTitleWithCustom = classNames({
        'dish-card__content--title': true,
        'with-custom': isDishCardWithCustomSelect,
        'sold-out': isSoldOut,
    });

    const dishTitleItemWithCustom = classNames({
        'dish-card-title': true,
        'with-custom': isDishCardWithCustomSelect,
    });

    const soldOutImg = classNames({
        'sold-out': isSoldOut,
    });

    return (
        <div
            ref={wrapperRef}
            style={wrapStyle}
            styleName={wrapClasses}
            data-test-id="dish-card__wrapper"
            data-id={id}
        >
            <li
                ref={containerRef}
                styleName={cardClasses}
                style={isAnimatedAddingToBasket ? { width: cardWidth } : {}}
                onClick={() => dispatchOnCardClickAnalytic({
                    id,
                    title,
                    caption,
                    price,
                    categoryId,
                    index,
                })}
                role="none"
            >
                <Link onClick={(e) => handleOpenDishDetails(pathname, e)} styleName="dish-card__image--container" href={href}>
                    <div styleName="dish-card__image">
                        {TEFAL_DISHES_ID.includes(id) && <UIParterLogoShield type="default" />}
                        {/* <div styleName="spa-march-dish-label-wrapper">
                            <MarchDishShield dishId={id} />
                        </div> */}
                        <DishImageShield dishId={id} isSoldOut={isSoldOut} />
                        {/* <DishAdLabel id={id} /> */}
                        <div styleName={soldOutImg}>
                            <DishImage
                                url={imageUrl}
                                fallbackUrl={imageUrlMobile}
                                alt={title}
                                scrollPosition={scrollPosition}
                                useLazyLoading={useLazyLoading}
                            />
                        </div>
                    </div>
                </Link>
                <div styleName={dishCardDescriptionWrapperClasses}>
                    <div styleName={dishTitleWithCustom}>
                        <div styleName={dishTitleItemWithCustom}>
                            <Link
                                onClick={(e) => handleOpenDishDetails(pathname, e)}
                                href={href}
                                className="dish-card-desktop-link"
                            >
                                <div styleName="dish-card-title__text">{dishTitle}</div>
                                <p styleName="dish-card-title__text caption">{dishCaption}</p>
                            </Link>
                        </div>
                        {isDishCardWithCustomSelect && (
                            <CustomIngredientsSelectDishRow
                                selectedDishId={selectedDishId}
                                onNextIdSelected={onNextIdSelected}
                                id={id}
                                ingredientsList={ingredientsList}
                            />
                        )}
                    </div>
                    <div styleName={dishCardPriceWrapperClasses}>
                        <div styleName="dish-card__footer">
                            <div>
                                <p styleName="dish-card__price">
                                    {locale === 'en' ? (
                                        <>
                                            <i styleName="price" />
                                            &nbsp;
                                            {formatPrice(dishPrice)}
                                        </>
                                    ) : (
                                        <>
                                            {formatPrice(dishPrice)}
                                            &nbsp;
                                            <i styleName="price" />
                                        </>
                                    )}
                                </p>
                                <p styleName="dish-card__price-text">
                                    {locales[locale].priceDescription}
                                    &nbsp;
                                    {portionText[locale]}
                                    &nbsp;/&nbsp;
                                    {weight}
                                    &nbsp;
                                    {locales[locale].priceDescriptionWeight}
                                </p>
                            </div>
                            <div styleName="dish-card__count-container">
                                {isSoldOut ? (
                                    <div styleName="dish-card__sold-out-label">
                                        {locales[locale].soldOut}
                                    </div>
                                ) : (
                                    <>
                                        <div styleName="dish-card__count-wrapper">
                                            <PortionSelect
                                                disabled={price === 0}
                                                id={id}
                                                portion={portion}
                                                portions={portions}
                                                portionAlias={isDishWithPortionAlias && useCustomPortionAlias ? umAlias : null}
                                                weight={weight}
                                                parentRef={parentRef}
                                                scrollPadding={offsetOptions}
                                                onChange={handleChangePortion}
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            aria-label="Добавить в корзину"
                                            styleName="dish-card__basket"
                                            onClick={createClearBasketWithDialogHandler(handleDishToBasket)}
                                            data-test-id="dish-card__basket"
                                        >
                                            <BasketIcon2 />
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </div>
    );
};

export default DesktopDish;

DesktopDish.propTypes = {
    index: PropTypes.number.isRequired,
    localeContext: PropTypes.shape({
        locale: PropTypes.string,
    }).isRequired,
    categoryId: PropTypes.string.isRequired,
    umAlias: PropTypes.arrayOf(PropTypes.string).isRequired,
    id: PropTypes.string.isRequired,
    visibleStatus: PropTypes.oneOf([0, 1, 2]).isRequired,
    title: PropTypes.string.isRequired,
    titleEn: PropTypes.string.isRequired,
    caption: PropTypes.string,
    captionEn: PropTypes.string,
    price: PropTypes.number.isRequired,
    weight: PropTypes.number.isRequired,
    portions: PropTypes.arrayOf(
        PropTypes.number,
    ).isRequired,
    previewImage: PropTypes.string.isRequired,
    previewMobileImage: PropTypes.string.isRequired,
    canApplySoldOutStyles: PropTypes.bool,

    parentRef: PropTypes.shape({}),
    useLazyLoading: PropTypes.bool.isRequired,
    isSubscriptionActivated: PropTypes.bool.isRequired,
    showFilterChangedAnimation: PropTypes.bool.isRequired,

    openDishDetails: PropTypes.func.isRequired,
    onClickAddToBasket: PropTypes.func.isRequired,
    dispatchOnCardClickAnalytic: PropTypes.func.isRequired,
    createClearBasketWithDialogHandler: PropTypes.func,

    addToBasketAnimationTarget: PropTypes.shape({
        right: PropTypes.number,
        top: PropTypes.number,
        scale: PropTypes.number,
    }).isRequired,

    scrollPosition: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
    }),

    dishDetailsPathname: PropTypes.string.isRequired,
    spaDishObserver: PropTypes.shape({ observe: PropTypes.func, unobserve: PropTypes.func }).isRequired,
};
