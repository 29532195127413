/* eslint-disable global-require */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
/* COMPOENT */
import { AbstractDishShield } from './imgV2/Shield';

/* STYLES */
import s from './dish-image-shield.scss';

const dishWithShield = new Map([
    ['3784', { name: 'monica', type: 'combined' }],
    ['3791', { name: 'theory', type: 'combined' }],
    ['3789', { name: 'avengers', type: 'combined' }],
    ['3790', { name: 'pulp', type: 'combined' }],
    ['3782', { name: 'ameli', type: 'combined' }],
    ['4640', { name: 'peaks', type: 'combined' }],
    ['4178', { name: 'housewives', type: 'combined' }],
    ['3865', { name: 'twilight', type: 'combined' }],
    ['4644', { name: 'moon', type: 'combined' }],
    ['4645', { name: 'cards', type: 'combined' }],

    // ['3677', { name: 'ahmad', type: 'combined' }],
    // ['3833', { name: 'ahmad', type: 'combined' }],
    ['4574', { name: 'olya', type: 'combined' }],
    ['4581', { name: 'olya', type: 'combined' }],
    ['4556', { name: 'olya', type: 'combined' }],
    ['4557', { name: 'olya', type: 'combined' }],
    ['4575', { name: 'olya', type: 'combined' }],
    // Kozel TODO: !!! Удалть когда закроем ленд
    ['4663', { name: 'kozel', type: 'upper', isShownOnLangings: false }],
    ['4662', { name: 'kozel', type: 'upper', isShownOnLangings: false }],
    ['4664', { name: 'kozel', type: 'upper', isShownOnLangings: false }],
    ['4658', { name: 'kozel', type: 'upper', isShownOnLangings: false }],

    // NY 2025
    ['1257', {
        name: 'favorite', type: 'upperText', isShownOnLangings: true, lpOnly: true,
    }],
    ['3839', {
        name: 'fifteenminutes', type: 'upperText', isShownOnLangings: true, lpOnly: true,
    }],
    ['4697', {
        name: 'new', type: 'upperText', isShownOnLangings: true, lpOnly: true,
    }],
    ['4698', {
        name: 'new', type: 'upperText', isShownOnLangings: true, lpOnly: true,
    }],
    ['4701', {
        name: 'new', type: 'upperText', isShownOnLangings: true, lpOnly: true,
    }],
    ['4700', {
        name: 'new', type: 'upperText', isShownOnLangings: true, lpOnly: true,
    }],
    ['4699', {
        name: 'new', type: 'upperText', isShownOnLangings: true, lpOnly: true,
    }],

    // Maxim special shield
    // ['4716', { name: 'maxim', type: 'textSpecial', isShownOnLangings: false }],
    ['1814', { name: 'maxim', type: 'textSpecial', isShownOnLangings: false }],
    ['4740', { name: 'maxim', type: 'textSpecial', isShownOnLangings: false }],
    ['3497', { name: 'maxim', type: 'textSpecial', isShownOnLangings: false }],
    ['4135', { name: 'maxim', type: 'textSpecial', isShownOnLangings: false }],
    ['3561', { name: 'maxim', type: 'textSpecial', isShownOnLangings: false }],
    ['3992', { name: 'maxim', type: 'textSpecial', isShownOnLangings: false }],
    ['25', { name: 'maxim', type: 'textSpecial', isShownOnLangings: false }],
    ['24', { name: 'maxim', type: 'textSpecial', isShownOnLangings: false }],


]);


export const DishImageShield = React.memo(({
    dishId,
    isSoldOut = false,
    realm = false,
}) => {
    const {
        name, type, isShownOnLangings, lpOnly,
    } = useMemo(() => {
        if (isSoldOut) {
            dishWithShield.set(dishId, { name: 'soldout', type: 'text' });
        }

        const shieldData = dishWithShield.get(dishId);
        return shieldData || { name: '', type: '' };
    }, [dishId, isSoldOut]);

    if (!name || !type) return null;

    if (realm === 'lp' && !isShownOnLangings) return null;
    if (realm !== 'lp' && lpOnly) return null;


    return (
        <div className={s['dish-image-shield__root']}>
            <AbstractDishShield name={name} type={type} />
        </div>
    );
});


DishImageShield.propTypes = {
    dishId: PropTypes.string.isRequired,
    isSoldOut: PropTypes.bool,
    realm: PropTypes.string,
};
