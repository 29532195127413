import React, { useEffect } from 'react';
import {
    Route, Switch, Redirect,
    useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import { trackScreenView } from 'global/services';
import { locationService } from 'app/utils/locationService';

import Main from './views/Main';
import PushRedirect from './views/PushRedirect';

export default function AppRoutes(props) {
    const { promoProps } = props;

    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        locationService.setLocationState(pathname);
    }, [pathname]);

    useEffect(() => {
        trackScreenView(pathname);
    }, [pathname]);

    useEffect(() => {
        const { pathname: path, search } = location;
        const screenName = `${path}${search}`;
        try {
            window.ga('set', 'page', screenName);
            window.ga('send', 'pageview');
        } catch (e) {
            // console.log(e);
        } // eslint-disable-line no-empty
    }, [location]);

    return (
        <Switch>
            {/* PROFILE ROUTES */}
            <Route
                path="/profile/"
                exact
                strict
                render={() => (<Redirect to="/profile/deliveries/" />)}
            />
            <Route
                path="/profile/:profilePage/:deliverySection/:deliveryId/"
                strict
                render={(routeProps) => <Main showProfile {...routeProps} {...promoProps} />}
            />
            {/* TODO: BR-1084 */}
            <Route
                path="/profile/:profilePage/"
                exact={false}
                render={(routeProps) => <Main showProfile {...routeProps} {...promoProps} />}
            />
            {/* OTHER ROUTES */}
            <Route
                path="/menu/"
                exact
                strict
                render={(routeProps) => (
                    <Main
                        showMenu
                        {...routeProps}
                        {...promoProps}
                    />
                )}
            />
            <Route
                path="/menu/filters/"
                exact
                strict
                render={(routeProps) => (
                    <Main
                        showMenu
                        showFilters
                        {...routeProps}
                        {...promoProps}
                    />
                )}
            />
            <Route
                path="/menu/dish/:dishId/"
                strict
                render={(routeProps) => (
                    <Main {...routeProps} {...promoProps} />
                )}
            />
            <Route
                path="/menu/:dishId/"
                strict
                render={(routeProps) => {
                    // eslint-disable-next-line react/prop-types
                    const {
                        location: { search },
                        match: { params: { dishId } },
                    } = routeProps;
                    return <Redirect to={`/menu/dish/${dishId}/${search}`} />;
                }}
            />
            <Route
                path="/basket/"
                exact
                strict
                render={(routeProps) => (
                    <Main showBasket {...routeProps} {...promoProps} />
                )}
            />
            <Route
                path="/checkout/"
                exact
                strict
                render={(routeProps) => <Main showCheckout {...routeProps} {...promoProps} />}
            />
            <Route
                path="/thx/"
                strict
                render={(routeProps) => <Main showThx {...routeProps} {...promoProps} />}
            />
            <Route
                path="/auth/"
                strict
                render={(routeProps) => <Main {...routeProps} {...promoProps} />}
            />
            <Route
                path="/app/delivery/:deliveryId/"
                exact
                strict
                render={(routeProps) => (
                    <Main showDeliveryMenu {...routeProps} {...promoProps} />
                )}
            />
            <Route
                path="/app/delivery/:deliveryId/filters/"
                exact
                strict
                render={(routeProps) => (
                    <Main
                        showDeliveryMenu
                        showFilters
                        {...routeProps}
                        {...promoProps}
                    />
                )}
            />
            <Route
                path="/app/delivery/:deliveryId/basket/"
                exact
                strict
                render={(routeProps) => (
                    <Main
                        showDeliveryBasket
                        {...routeProps}
                        {...promoProps}
                    />
                )}
            />
            <Route
                path="/app/delivery/:deliveryId/dish/:dishId/"
                exact
                strict
                render={(routeProps) => (
                    <Main
                        showDeliveryMenu
                        showDeliveryDishDetails
                        {...routeProps}
                        {...promoProps}
                    />
                )}
            />
            <Route
                path="/pay"
                exact
                render={(routeProps) => <Main showCheckout {...routeProps} {...promoProps} />}
            />
            <Route path="/2020" render={() => <PushRedirect />} />
            <Route path="/nopanic" render={() => <PushRedirect path="/nopanic" />} />
            <Route path="/redirect" render={() => <PushRedirect useUrlFromQuery />} />

            <Route
                path="/:url+"
                strict
                render={(routeProps) => {
                    const { match: { url } } = routeProps;
                    const nextPath = {
                        pathname: `${url}/`,
                        search: window.location.search,
                    };
                    return <Redirect to={nextPath} />;
                }}
            />

            <Route render={() => <Redirect to={`/menu/${window.location.search}`} />} />
            <Route path="/app.html" render={() => <Redirect to="/menu/" />} />
            <Route path="/spa.html" render={() => <Redirect to="/menu/" />} />
        </Switch>
    );
}

AppRoutes.propTypes = {
    promoProps: PropTypes.shape({}).isRequired,
};
