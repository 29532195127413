const formatPrice = (price) => {
    const result = String(price)
        .split('')
        .reverse()
        .reduce((acc, number, i) => {
            if (i !== 0 && i % 3 === 0) {
                return `${number} ${acc}`;
            }
            return `${number}${acc}`;
        }, '');
    return result;
};

export default formatPrice;

// 1 = 1
// 12 = 12
// 123 = 123
// 1234 = 1 234
// 1234567 = 1 234 567
// 1234567890 = 1 234 567 890
