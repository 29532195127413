/* eslint-disable no-underscore-dangle */
import { useContext, useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { LocationContext } from 'app/containers/LocationProvider';
import { OverlayContextDispatch } from 'app/containers/contexts/overlay.context';
import { useHistory } from 'react-router';

import { extraPeriodNYStart } from 'global/const/ny';

import { getCustomizationSource } from 'app/customizationSource/customizationSource';

import { getHiddenElements } from 'app/utils/url';
import { getTrialScenarioFromUrl } from 'app/utils/trial-scenario';
import { TYPE_CHECKOUT_STEP_BY_STEP } from '../../StepByStepCheckout/stepByStepCheckoutConst';

import { CheckoutStoreContext } from '../../store/checkoutStore.context';

import { CustomizationDialogDataQuery } from './CustomizationDialogDataQuery.graphql';
import { FullCheckoutDeliveryDatesWithoutX3Query } from './FullCheckoutDeliveryDatesWithoutX3Query.graphql';

/* UTILS */
import {
    checkisRequiredDataReceived,
    checkIsTagsCustomizationKeep,
    checkIsX3InCurrentPeriod,
    getFlatCart,
    checkDeliveryDatesByCurrentPeriod,
} from './useCustomizationDialog.utils';


/* COMPONENT */

export function useCustomizationDialog(isContradictionAnalizingDisabled) {
    const client = useApolloClient();
    const location = useHistory();

    const { checkoutState: { checkoutTypeState } } = useContext(CheckoutStoreContext);

    const { locationKey } = useContext(LocationContext);
    const { pushDialogOverlay, closeCertainOverlay } = useContext(OverlayContextDispatch);

    const [isDataFetched, setIsDataFetched] = useState(false);
    const [isX3InCurrentPeriod, setIsX3InCurrentPeriod] = useState(false);

    // параметр для передачи в createInvoice
    const [isCustomizationIgnored, setIsCustomizationIgnored] = useState(false);

    const [dialogState, setDialogState] = useState({
        isNeedToShowDialog: false,
        customizationText: '',
    });

    const { loading: isX3DialogDataLoading, data } = useQuery(CustomizationDialogDataQuery, {
        variables: {
            trial_scenario: getTrialScenarioFromUrl(),
        },
        skip: isDataFetched,
        context: { message: 'app:init:WithCustomizationDialog' },
    });

    useEffect(() => {
        if (!data) return;

        /* ШАГ 0.5: Ждем когда user залогинется и введет адрес */
        if (checkisRequiredDataReceived(data)) return;

        // ШАГ 0.6  Делаем карзину плоской чтобы было удобно с ней рабоать
        const flatCart = getFlatCart(data);

        /*
            ШАГ 0.7 Проверяем, есть ли в ближайшем цикле даты доставки
            Если дат нет, то диалог не нужно показывать
        */
        const isFirstDateInNextPeriod = checkDeliveryDatesByCurrentPeriod(data);
        if (isFirstDateInNextPeriod) return;

        // ШАГ 0.8 Считаем, что в новогоднем меню кастомизации нет
        if (data.selectedPeriod === extraPeriodNYStart) return;

        // ШАГ 1: Провереряем есть ли кастомизация
        const isTagsCustomizationKeep = checkIsTagsCustomizationKeep(data, flatCart);
        const customization = getCustomizationSource(data, isTagsCustomizationKeep);

        if (!customization) return;

        const { isThisSource, text } = customization;

        /* ШАГ 1: Определить есть в текущем цикле есть x3 даты */
        const isX3InCurrentPeriodValue = checkIsX3InCurrentPeriod(data, locationKey);
        // const isX3InCurrentPeriodValue = false;

        setIsDataFetched(true);

        if (!isThisSource) return;

        const isCustomizationPossble = !getHiddenElements().edit_basket;

        const isDatesToBeBlocked = isX3InCurrentPeriodValue
            && isCustomizationPossble;

        if (!isContradictionAnalizingDisabled) return;

        // Блокируем даты до x3
        if (isDatesToBeBlocked) {
            setIsX3InCurrentPeriod(isX3InCurrentPeriodValue);
            client.query({
                query: FullCheckoutDeliveryDatesWithoutX3Query,
                variables: {
                    period: data.selectedPeriod,
                    filters_modified: isX3InCurrentPeriodValue,
                    trial_scenario: getTrialScenarioFromUrl(),
                },
                context: {
                    message: 'checkout:update:useCustomizationDialog',
                },
            });
            return;
        }

        const periodDates = data.menuDates.dates.find((dates) => dates.startDate === data.selectedPeriod);
        const hasEEDates = periodDates?.eeAvailableDates.length > 0;

        // Показываем диалог
        setDialogState({
            isNeedToShowDialog: true,
            customizationText: text,
            isCustomizationPossible: hasEEDates,
        });
    },
    [
        data,
        client,
        closeCertainOverlay,
        pushDialogOverlay,
        location,
        locationKey,
        isContradictionAnalizingDisabled,
    ]);


    /**
* В случае одностраничного чекаута показываем диалог сразу, как только для него есть все данные
* В случае пошагового чекаута показываем диалог при переходе с шага адреса на шаг выбора даты
*/
    const isTimeToGetDialog = checkoutTypeState.checkoutType === TYPE_CHECKOUT_STEP_BY_STEP
        ? checkoutTypeState.step === 'STEP_DATETIME'
        : true;

    useEffect(() => {
        if (isTimeToGetDialog && dialogState.isNeedToShowDialog) {
            const changeMenuText = 'Поменять меню';
            const changeMenu = () => {
                const isEditBasketEnabled = !getHiddenElements().edit_basket;
                const redirectTo = isEditBasketEnabled ? '/basket/' : '/menu/';

                closeCertainOverlay({ id: 'x3-dialog' });
                location.push(redirectTo);
            };

            const ignoreCustomizationText = 'Продолжить оформление';
            const ignoreCustomization = () => {
                setDialogState({
                    isNeedToShowDialog: false,
                    customizationText: '',
                });
                setIsCustomizationIgnored(true);
                closeCertainOverlay({ id: 'x3-dialog' });
            };

            const dialogData = dialogState.isCustomizationPossible ? {
                notifyOnly: false,
                regularText: dialogState.customizationText,
                confirmText: changeMenuText,
                rejectText: ignoreCustomizationText,
                onConfirm: changeMenu,
                onReject: ignoreCustomization,
            } : {
                notifyOnly: true,
                regularText: dialogState.customizationText,
                confirmText: ignoreCustomizationText,
                onConfirm: ignoreCustomization,
            };

            pushDialogOverlay('x3-dialog', dialogData);
        }
    }, [
        isTimeToGetDialog, dialogState, location, closeCertainOverlay, pushDialogOverlay,
    ]);

    return {
        isX3DialogDataLoading,
        isX3InCurrentPeriod,
        isCustomizationIgnored,
        setIsCustomizationIgnored,
    };
}

