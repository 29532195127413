export const dishes = [
    '3784',
    '3791',
    '3789',
    '3790',
    '3782',
    '4640',
    '4178',
    '3865',
    '4644',
    '4645',
];

export const periods = [
    '2024-10-21',
    '2024-10-28',
    '2024-11-04',
    '2024-11-11',
];
