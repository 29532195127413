/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import ScrollLock from 'react-scrolllock';
import compose from 'lodash/flowRight';
import { graphql } from '@apollo/client/react/hoc';
import Raven from 'raven-js';


import { analyticService } from 'global/services';

import { getCJGroup } from 'app/utils/user/user';
import { withLocationContext } from 'app/containers/LocationProvider';

import removeAccountMutation from 'app/graphql/network/profile/removeAccount';
import { withLocaleContext } from 'app/containers/LocaleProvider';
import { withSPAContext, SPA_MODE_ON } from 'app/containers/SPAProvider';
import { UIParagraph, UITextLine } from 'app/components/ui';
import { DialogAnimated } from 'app/components/Dialog';
import ContactUsIcons from 'app/components/ContactUsIcons';

import MainLinks from './MobileSiteNavMainLinks';
import OrdinaryLinks from './MobileSiteNavOrdinaryLinks';

import './mobile-site-nav.scss';

import { MOBILE_SITE_NAV_USER_DATA } from './mobileSiteNav.graphql';
import { BurgerMenuV2 } from './BurgerMenuV2';

export const FILLER_ANIMATION_TIMEOUT = 100;


const dialogTexts = {
    ru: {
        strongText: 'Хотите удалить профиль?',
        regularText: '',
        confirmText: 'Удалить',
        rejectText: 'Отмена',
        regularErrorText: 'При удалении произошла ошибка!',
    },
    en: {
        strongText: 'Do You want to delete account?',
        regularText: '',
        confirmText: 'Delete',
        rejectText: 'Cancel',
        regularErrorText: 'An error has occured!',
    },
};


class MobileSiteNav extends React.PureComponent {
    state = {
        isFillerVisible: false,
        isMounted: false,
        isDeleteAccountDialogOpen: false,
        isDeleteAccountDialogError: false,
        debugClicksCount: 0,
    };

    scrollableContentRef = React.createRef();

    componentDidMount() {
        const { userQuery: { loading } } = this.props;

        this.setState({
            isFillerVisible: loading,
            isMounted: true,
        });
    }

    componentDidUpdate(prevProps) {
        const { userQuery: { user: prevUser } } = prevProps;
        const { userQuery: { user } } = this.props;

        const isUserChanged = user !== prevUser;
        if (isUserChanged) {
            this.setFillerVisibilityState(false);
        }
    }

    setFillerVisibilityState = (nextState) => this.setState({ isFillerVisible: nextState });

    showFiller = (callback) => {
        this.setState(
            { isFillerVisible: true },
            callback,
        );
    };

    /* HANDLERS */

    handleClickNavLink = (e) => {
        const {
            onClickNavLink,
        } = this.props;

        e.stopPropagation();

        const { trackId } = e.currentTarget.dataset;

        if (trackId) {
            analyticService.push({ eventName: 'Burger_Menu_Click', button_name: trackId });
        }

        const text = e.currentTarget.innerText;
        analyticService.push({ eventName: 'Menu_Side', text });

        onClickNavLink(e);
    };

    handleClickQuit = (event) => {
        const {
            onClickQuit,
        } = this.props;

        try {
            if (event) {
                const text = event.currentTarget.innerText;
                analyticService.push({ eventName: 'Menu_Side', text });
            }
        } catch (error) {
            Raven.captureException(error);
        }

        const callback = () => setTimeout(onClickQuit, FILLER_ANIMATION_TIMEOUT);
        this.showFiller(callback);
    };

    handleClickLogin = (e) => {
        const {
            // onClickNavLink,
            spaContext,
            isAuthed,
            history,
        } = this.props;

        const text = e.currentTarget.innerText;
        analyticService.push({ eventName: 'Menu_Side', text });

        const { search } = window.location;
        const link = isAuthed ? `/profile/${search}` : `/auth/${search}`;

        if (spaContext.mode === SPA_MODE_ON && history) {
            history.push(link);
        } else {
            window.location = link;
        }
    };

    handleClickDeleteAccount = () => {
        this.setState({ isDeleteAccountDialogOpen: true });
    };

    handleCloseDialog = () => {
        this.setState({ isDeleteAccountDialogOpen: false });
    };

    handleConfirmDeleteAccount = async () => {
        const { removeAccount } = this.props;

        try {
            await removeAccount();
        } catch (e) {
            this.setState({ isDeleteAccountDialogError: true });
            return;
        }

        this.handleCloseDialog();
        this.handleClickQuit();
    };

    handleClickOnSocialIcon = (e) => {
        const button_name = e.currentTarget.dataset.eventId.split('_').reverse()[0];
        analyticService.push({ eventName: 'Burger_Menu_Click', button_name });
    };

    handleClickOnDebugHelper = () => {
        const { debugClicksCount } = this.state;
        const nextCount = debugClicksCount + 1;

        this.setState({ debugClicksCount: nextCount });
    };

    /* RENDER */

    // eslint-disable-next-line
    renderDebugInfo() {
        return (
            <span style={{ overflowWrap: 'anywhere', fontFamily: 'sans-serif' }}>
                debug info
                <br />
                <br />
                {`UA ${window.navigator.userAgent}`}
                <br />
                <br />
                {`appName ${window.navigator.appName}`}
                <br />
                <br />
                {`appVersion ${window.navigator.appVersion}`}
                <br />
                <br />
                {`href ${window.location.href}`}
                <br />
                <br />
                {`cookie ${document.cookie}`}
                <br />
                <br />
                {window.webkit ? JSON.stringify(Object.keys(window.webkit)) : 'no webkit'}
                <br />
                <br />
                {window.webkit && window.webkit.messageHandlers
                    ? JSON.stringify(Object.keys(window.webkit.messageHandlers))
                    : 'no webkit messageHandlers'}
                <br />
                <br />
                {window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.analyticsMessageHandler
                    ? JSON.stringify(Object.keys(window.webkit.messageHandlers.analyticsMessageHandler))
                    : 'no webkit messageHandlers analyticsMessageHandler'}
                <br />
                <br />
                {window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.analyticsMessageHandler.postMessage
                    ? typeof window.webkit.messageHandlers.analyticsMessageHandler.postMessage
                    : 'no webkit messageHandlers analyticsMessageHandler postMessage'}
            </span>
        );
    }

    renderDeleteAccountDialog() {
        const { localeContext: { locale } } = this.props;
        const {
            isDeleteAccountDialogOpen,
            isDeleteAccountDialogError,
        } = this.state;

        const dlTexts = dialogTexts[locale];
        const regularText = isDeleteAccountDialogError ? dlTexts.regularErrorText : dialogTexts[locale].regularText;
        const dialogProps = {
            ...dlTexts,
            regularText,
            onConfirm: this.handleConfirmDeleteAccount,
            onReject: this.handleCloseDialog,
            notifyOnly: false,
            oneRowButtons: true,
        };

        return (
            <DialogAnimated
                isOpen={isDeleteAccountDialogOpen}
                {...dialogProps}
            />
        );
    }

    render() {
        const {
            locationContext: { locationKey },
            localeContext: { translate },
            renderContext,
            transitionState,
            isAuthed,
            userQuery: { user },
            onClickSubscriptionInfoOpen,
            localeContext,
            isNewbie,
        } = this.props;

        const {
            isFillerVisible,
            isMounted,
            debugClicksCount,
        } = this.state;

        const cjGroup = getCJGroup(user);

        const isUserFetched = isAuthed && user && user.referralProgram;
        const referralBalance = isUserFetched ? Number(user.referralProgram.balance) : 0;

        const navClasses = classNames({
            'mobile-nav__root': true,
            [renderContext]: renderContext,
            [transitionState]: transitionState,
        });

        const fillerClasses = classNames({
            'mobile-nav__filler': true,
            visible: isFillerVisible,
        });

        const dialogButtonClasses = classNames({
            'mobile-nav__region-button': true,
            [transitionState]: transitionState,
        });

        return (
            <>
                <div styleName={navClasses}>
                    {isMounted && (
                        <ScrollLock touchScrollTarget={this.scrollableContentRef.current} />
                    )}
                    <div
                        ref={this.scrollableContentRef}
                        styleName="mobile-nav-tablet-limiter"
                    >
                        <div styleName="mobile-nav__top-container">
                            <BurgerMenuV2
                                isAuthed={isAuthed}
                                onClickNavLink={this.handleClickNavLink}
                                localeContext={localeContext}
                                onClickLogin={this.handleClickLogin}
                                isNewbie={isNewbie}
                            />
                        </div>
                        {/*
                            <div styleName="mobile-nav__links-container main">
                                <MainLinks
                                    isAuthed={isAuthed}
                                    referralBalance={referralBalance}
                                    onClickNavLink={this.handleClickNavLink}
                                    onClickLogin={this.handleClickLogin}
                                    onClickQuit={this.handleClickQuit}
                                />
                            </div>

                            <div styleName="mobile-nav__links-container ordinary">
                                <OrdinaryLinks
                                    location={locationKey}
                                    user={user}
                                    cjGroup={cjGroup}
                                    onClickNavLink={this.handleClickNavLink}
                                    onClickSubscriptionInfoOpen={onClickSubscriptionInfoOpen}
                                    handleClickDeleteAccount={this.handleClickDeleteAccount}
                                />
                            </div>
                        </div> */}
                        {/* <div styleName="mobile-nav__bottom-container">
                            <div styleName="mobile-nav__links-container bottom">
                                <ContactUsIcons onClickIcon={this.handleClickOnSocialIcon} />
                                <span
                                    onClick={this.handleClickOnDebugHelper}
                                >
                                    <UIParagraph level="5.1">
                                        {(debugClicksCount % 5 === 0 && debugClicksCount > 0)
                                            ? this.renderDebugInfo()
                                            : (
                                                <UITextLine>
                                                    {translate('mobileNav.workHours')}
                                                </UITextLine>
                                            )
                                        }
                                    </UIParagraph>
                                </span>
                            </div>
                        </div> */}
                    </div>

                    <div styleName={fillerClasses} />
                </div>
                {/* <div styleName={dialogButtonClasses}>
                    // TODO: BR-1019 Удалить старый выбор региона SPB
                    <MobileNavRegionButton />
                </div> */}
                {this.renderDeleteAccountDialog()}
            </>
        );
    }
}

const MobileSIteNavDAC = (props) => {
    const {
        history = null,
        renderContext = '',
        onClickSubscriptionInfoOpen = null,
    } = props;

    /* DAC */
    const { data } = useQuery(MOBILE_SITE_NAV_USER_DATA, {
        context: {
            message: 'dac:read:MobileSIteNavDAC',
        },
    });

    /* SELECTORS */
    const isAuthed = useMemo(
        () => Boolean(data?.user),
        [data?.user],
    );

    const isNewbie = useMemo(
        () => Boolean(data?.user?.isNewbie) || !isAuthed,
        [isAuthed, data?.user?.isNewbie],
    );

    return (
        <MobileSiteNav
            {...props}
            isAuthed={isAuthed}
            userQuery={data}
            isNewbie={isNewbie}
            history={history}
            renderContext={renderContext}
            onClickSubscriptionInfoOpen={onClickSubscriptionInfoOpen}
        />
    );
};

export default compose(
    graphql(removeAccountMutation, { name: 'removeAccount' }),
    withLocaleContext,
    withLocationContext,
    withSPAContext,
)(MobileSIteNavDAC);


MobileSiteNav.propTypes = {
    locationContext: PropTypes.shape({
        locationKey: PropTypes.string.isRequired,
    }).isRequired,

    localeContext: PropTypes.shape({
        translate: PropTypes.func.isRequired,
        locale: PropTypes.string.isRequired,
    }).isRequired,

    spaContext: PropTypes.shape({
        mode: PropTypes.string,
    }).isRequired,

    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),

    isAuthed: PropTypes.bool.isRequired,
    renderContext: PropTypes.oneOf(['', 'index']),
    transitionState: PropTypes.string.isRequired,

    userQuery: PropTypes.shape({
        user: PropTypes.shape({
            referralProgram: PropTypes.shape({
                balance: PropTypes.number,
            }),
        }),
        loading: PropTypes.bool,
    }).isRequired,
    removeAccount: PropTypes.func.isRequired,

    onClickNavLink: PropTypes.func.isRequired,
    onClickQuit: PropTypes.func.isRequired,
    onClickSubscriptionInfoOpen: PropTypes.func,
};
