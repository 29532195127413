import React, { useState, useRef, useEffect } from 'react';


import { useResize } from 'app/utils/hooks/useResize';
import useOutsideClick from 'app/utils/hooks/useOutsideClick';

import classNames from 'classnames';
import css from './SelectDropdown.module.scss';
import Arrow from './img/arrow.component.svg';

interface ReusableObjectRecord extends Record<string, string> { }

interface ISelectProps<T> {
    selectedOptionProp: ReusableObjectRecord,
    callback: (e: React.MouseEvent, id: T) => void
    options: ReusableObjectRecord[],
}

export function SelectDropdown<T>({
    options,
    selectedOptionProp,
    callback,
}: ISelectProps<T>) {
    const [selectedOption, setSelectedOption] = useState(selectedOptionProp);
    const [isSelectOpenned, setIsSelectOpenned] = useState<boolean>(false);
    const selectRef = useRef(null);

    const { isDesktop } = useResize();
    useOutsideClick({
        ref: selectRef,
        callback: () => setIsSelectOpenned(false),
    });

    const openCloseDropdownList = () => {
        if (isDesktop) {
            setIsSelectOpenned((prev) => !prev);
        }
    };

    useEffect(
        () => {
            setSelectedOption(selectedOptionProp);
        },
        [selectedOptionProp],
    );

    const rootClasses = classNames({
        [css.selectDropdownRoot]: true,
        [css.selectDropdownRootDropdownOpenned]: isSelectOpenned,
    });

    const iconClasses = classNames({
        [css.selectDropdownArrowIcon]: true,
        [css.selectDropdownArrowIconRotated]: isSelectOpenned,
    });

    return (
        <div
            ref={selectRef}
            className={rootClasses}
            onClick={openCloseDropdownList}
            role="none"
        >
            <div className={css.selectDropdownValueFlexContainer}>
                <div className={css.selectDropdownChosenValue}>{selectedOption.title}</div>
                <Arrow className={iconClasses} />
            </div>
            {isSelectOpenned && (
                <div className={css.selectDropdownOptionsList}>
                    {options.map((option) => {
                        const optionId = option.id as T;
                        return (
                            <div
                                key={option.id}
                                onClick={(e: React.MouseEvent) => {
                                    callback(e, optionId);
                                    setIsSelectOpenned(false);
                                }}
                                className={css.selectDropdownListOption}
                                role="none"
                            >
                                {option.title}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}
