import { useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import {
    SAVE_CUSTOMIZATION_COMMENT,
    VIEW_CUSTOMIZATION_COMMENT,
} from 'app/graphql/network/customizationComment';


export function useSyncedUserCustomizationComment() {
    const { data: userData } = useQuery(gql`
        query USER_CUSTOMIZATION_COMMENT {
            user {
                id
                lastCustomizationComment
            }
        }
    `, {
        context: {
            message: 'app:read:useSyncedUserCustomizationComment',
        },
    });

    const { data: commentData, refetch: refetchCommentData } = useQuery(VIEW_CUSTOMIZATION_COMMENT, {
        context: {
            message: 'app:read:useSyncedUserCustomizationComment',
        },
    });

    const [saveCustomComment] = useMutation(SAVE_CUSTOMIZATION_COMMENT);

    useEffect(() => {
        const isUserFetched = Boolean(userData?.user);
        const isCommentFetched = Boolean(commentData?.viewCustomizationComment);

        if (!isUserFetched || !isCommentFetched) return;

        const hasSessionComment = Boolean(commentData.viewCustomizationComment.comment);
        const hasAccountComment = Boolean(userData.user.lastCustomizationComment);

        if (hasAccountComment && !hasSessionComment) {
            saveCustomComment({
                variables: { comment: userData.user.lastCustomizationComment },
            }).then(() => {
                refetchCommentData();
            });
        }
    }, [userData, commentData, saveCustomComment, refetchCommentData]);
}
