/* eslint-disable no-underscore-dangle */
/* LIBS */
import React, { useId, useRef } from 'react';

/* SUBCOMPONENTS */
import { UiKitInputContainer } from './UiKitInputContainer';
import { UiKitInputButton } from './UiKitInputButton';
import { UiKitInputTextArea } from './UiKitInputTextArea';

/* TYPES  */
import { IUIKitInputProps } from './UiKItUiInput.types';

// STYLES
import css from './newUiinput.module.scss';


const useInputRef = (
    externalInputRef: React.MutableRefObject<HTMLInputElement | null> | undefined,
) => {
    const inputNodeRef: React.MutableRefObject<HTMLInputElement | null> = useRef(null);

    if (externalInputRef) {
        return externalInputRef;
    }

    return inputNodeRef;
};

export function UiKitInput(props: IUIKitInputProps) {
    const uniqueId = useId();
    const {
        inputUiState = 'default',
        componentSize = 'large',
        inputValue = '',
        inputLabel = '',
        inputOnFocusHandler = () => { },
        inputOnClickHanlder = () => { },
        inputOnBlurHanlder = () => { },
        inputOnChangeHanlder = () => { },
        buttonUiState = 'empty',
        externalInputRef,
        inputType = 'text',
        inputMask = null,
    } = props;

    const inputNodeRef = useInputRef(externalInputRef);

    const handleClickOnClearButton = () => {
        inputNodeRef?.current?.focus();
        inputOnChangeHanlder({ currentTarget: { value: '' } });
    };

    const handleClickOnSubmetButton = () => {
        // inputOnBlurHanlder();
        // console.log('submit');
    };

    return (
        <UiKitInputContainer
            inputUiState={inputUiState}
            componentSize={componentSize}
            htmlFor={uniqueId}
        >
            <UiKitInputTextArea
                inputUiState={inputUiState}
                componentSize={componentSize}
                inputValue={inputValue}
                inputLabel={inputLabel}
                inputType={inputType}
                inputNodeRef={inputNodeRef}
                inputMask={inputMask}
                // HANDLERS
                inputOnChangeHanlder={inputOnChangeHanlder}
                inputOnClickHanlder={inputOnClickHanlder}
                inputOnBlurHanlder={inputOnBlurHanlder}
                inputOnFocusHandler={inputOnFocusHandler}
                htmlFor={uniqueId}
            />
            <div
                className={css.uiKitInputButtonWrapper}
                style={{
                    position: buttonUiState !== 'clear'
                        ? 'absolute'
                        : 'static',
                    right: 0,
                }}
            >
                <UiKitInputButton
                    buttonUiState={buttonUiState}
                    componentSize={componentSize}
                    handleClickOnClearButton={handleClickOnClearButton}
                    handleClickOnSubmetButton={handleClickOnSubmetButton}
                />
            </div>
        </UiKitInputContainer>
    );
}
