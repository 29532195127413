import { LOCATION_SPB, LOCATION_MSK } from 'app/const/location';
import { createUrl } from 'app/utils/url';
import { defaultSetLink } from 'app/const/links/const';


// ***** EVERYDAY (DINNERS_FOR_FAMILY | Набор от шефа | 5 ужинов на семью | Семейное меню | Доставка завтра) *****
// searchParams:
const BASKET_SCHEMA = [
    ['cm', '10'],
];

const COMMON_SEARCH_PARAMS = [
    ...BASKET_SCHEMA,
    ['payment', 'recurrent'],
];

const HREF = createUrl({
    path: 'basket',
    params: [
        ...COMMON_SEARCH_PARAMS,
    ],
});

export const OUR_SET = {
    ...defaultSetLink,

    id: 'OUR_SET',

    originalPrice: '0 000',
    discountPrice: '0 000',
    discountPercent: '00',
    discountSum: '000',

    href: HREF,

    basketSchema: BASKET_SCHEMA,
    subdivisions: [LOCATION_MSK, LOCATION_SPB],
    datesType: 'eeAvailableDates',
    setType: 'our_set',
};
