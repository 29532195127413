export const prepareDeliveryCalendarId = (responseObject) => {
    const { id, __typename } = responseObject;

    if (id) {
        return `${__typename}:${id}`;
    }

    return `${__typename}:app-condition`;
};

