import { ApolloLink } from '@apollo/client';
import { errorService } from 'global/services';
import { tokenVar } from '../reaction';

function authLinkHandler(operation, forward) {
    const { operationName } = operation;
    // console.log(operationName, 'on', operation.getContext().step);
    const token = tokenVar();

    if (!token && !['auth'].includes(operationName)) {
        errorService.log({
            text: `Token: Invalid credentials ${operationName}`,
            source: 'server',
            extra: {
                Query: operationName,
            },
        });
    }

    operation.setContext(({ headers }) => ({
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    }));

    return forward(operation);
}

/**
 * @description Устанавливает токен авторизации в заголовок запроса
 */
export const authLink = new ApolloLink(authLinkHandler);
