import { ApolloLink } from '@apollo/client';

function handleInterceptUserFields(data, event) {
    try {
        if (event.includes('intercept:user:phone')) {
            const { data: { user } } = data;
            const userPhone = user?.phone || '';

            if (userPhone) {
                sessionStorage.setItem('phone', userPhone);
            }
        }

        return data;
    } catch (e) {
        return data;
    }
}

function IntercepUser(operation, forward) {
    const {
        event = [],
        // step
    } = operation.getContext();
    // const { operationName } = operation;

    // console.log(operationName, step, event);
    return forward(operation).map((data) => handleInterceptUserFields(data, event));
}

/**
 * @description Перехватывает телефон пользователя из запроса и ставит его в sessionStorage
 */
export const userInterceptors = new ApolloLink(IntercepUser);
