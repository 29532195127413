import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './banner.ui.scss';

/**
 * @param {String} bannerSize может быть xs | m из UIKit'a. По дефолту XS
 * @param {String | URL} backgroundImage Параметр ставит фоновую картинку. Передавать в виде URL'а
 * @description Оборачивает компонент в контейнер(banner) для слайдера. В Figma Banner/xs|m/
 * @TODO Добавить стили для размера S и обернуть banner'ы в слайдере с сетами из BR-875
 */

const UIBanner = ({
    children = null,
    bannerSize = 'xs',
    backgroundImage = '',
    bgSize = '',
}) => {
    /*
     * Классы костыли m-big и m-small задающий высоту баннеру (Тарлка из BR-942)
     * TODO: При первой возможности удалить этот говнокод
    */
    const rootWrapperClasses = classNames({
        'ui-banner-small': bannerSize === 'xs',
        'ui-banner-medium': bannerSize === 'm',
        'ui-banner': true,
        [bgSize]: true,
    });

    const backgroundWrapperClasses = classNames({
        'ui-banner--background-wrapper': true,
        [bannerSize]: true,
    });

    return (
        <div styleName={rootWrapperClasses}>
            <div styleName={backgroundWrapperClasses} style={{ backgroundImage: `url(${backgroundImage})` }}>
                {children}
            </div>
        </div>
    );
};

UIBanner.propTypes = {
    children: PropTypes.node,
    bannerSize: PropTypes.string,
    backgroundImage: PropTypes.string,
    bgSize: PropTypes.string,
};

export default UIBanner;
