import { SUBSCRIPTION_TYPES, isSubscription } from 'app/const/subscription';
import { subscriptionTypeVar } from '../reaction';

/**
 *
 */
export const setSubscriptionTypeReaction = ({ type }) => {
    const nextType = SUBSCRIPTION_TYPES[type];
    const nextData = {
        subscriptionType: nextType,
    };

    if (isSubscription(nextType)) {
        nextData.paymentMethod = 'online';
    }

    subscriptionTypeVar(nextType);
};
