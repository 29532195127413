import React, {
    createContext,
    useContext,
    useMemo,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import FILTER_QUERY from 'app/graphql/network/filter';
import { useSelectedFilters } from 'app/connect/connectToSelectedFilters';

import { selectedPeriodVar } from 'app/apollo/reaction';
import { patchQueryPeriods } from './patchQueryPeriods';


export const FiltersContext = createContext({
    filterQuery: {},
    selectedFilters: {
        isSelectedDefaultFilters: false,
        isUserChangesTags: false,
        prevSelectedPeriods: [],
        selectedCategories: [],
        selectedCategoryId: '',
        selectedPeriod: '',
        selectedTags: [],
    },
});


/**
 * @description тут ставиться переменная period при инициализации приложения VII
 */
const getInitialPeriodValue = (periods) => {
    const urlParams = new URLSearchParams(window.location.search);
    const periodQueryParam = urlParams.get('period');
    const periodFromUrl = periods.find((p) => p.start === periodQueryParam) || {};
    const firstVisablePeriod = periods.find((p) => p.isVisibleInFilter) || {};
    return periodFromUrl?.start ?? firstVisablePeriod?.start;
};

/**
 * @description Ставит query парамерт period
 */
const setInitialPeriodAsQueryParam = (initialPeriod) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('period', initialPeriod);
    window.history.replaceState(null, null, `?${searchParams.toString()}`);
};

/**
 * @description Инициализирует period. Ставит его в URL и d реактивную переменную
 * @param menuFilter из query MenuFilter
 */
export const initPeriodInApp = (menuFilter, period, patchedPeriods = []) => {
    const isMenuFilter = 'periods' in menuFilter;
    const hasPatchedClientPeriods = Boolean(patchedPeriods.length);

    const periods = hasPatchedClientPeriods
        ? patchedPeriods
        : menuFilter.periods;
    const initialPeriod = isMenuFilter
        ? getInitialPeriodValue(periods)
        : period;

    setInitialPeriodAsQueryParam(initialPeriod);
    selectedPeriodVar(initialPeriod);
};

export const FiltersContextProvider = (props) => {
    const { children } = props;

    const [periods, setPeriods] = useState([]);
    const selectedFilters = useSelectedFilters();

    const filterQuery = useQuery(FILTER_QUERY, {
        context: {
            message: 'root:init:FiltersContextProvider',
        },
        onCompleted: ({ menuFilter }) => {
            const patchedPeriods = patchQueryPeriods(menuFilter.periods);
            initPeriodInApp(menuFilter, null, patchedPeriods);
            setPeriods(patchedPeriods);
        },
    });

    const contextValue = useMemo(() => {
        const value = {
            filterQuery: {
                ...filterQuery,
                data: null,
                menuFilter: filterQuery.data ? {
                    ...filterQuery.data.menuFilter,
                    periods,
                } : { periods },
            },
            selectedFilters,
        };
        return value;
    }, [filterQuery, selectedFilters, periods]);

    if (!periods.length) return null;

    return (
        <FiltersContext.Provider value={contextValue}>
            {children}
        </FiltersContext.Provider>
    );
};
FiltersContextProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export const withFiltersContext = (WrappedComponent) => (forwardedProps) => {
    const { filterQuery } = useContext(FiltersContext);

    return (
        <WrappedComponent
            filterQuery={filterQuery}
            {...forwardedProps}
        />
    );
};

export const clearAfterSwitchDialogTexts = {
    ru: {
        strongText: 'Нужно освободить корзину,',
        regularText: 'чтобы переключить дату',
        confirmText: 'Хорошо',
        rejectText: 'Нет, не надо',
    },
    en: {
        strongText: 'Empty your basket',
        regularText: 'to switch period',
        confirmText: 'OK',
        rejectText: 'No, go back',
    },
};

