import { gql } from '@apollo/client';


export const CONTRY_SUBDIVISION_FRAGMENT = gql`
  fragment countrySubdivisionFragment on address {
    id
    country_subdivision
  }
`;

export const fullAddressData = gql`
  fragment fullAddressFragment on address {
        id
        city
        district
        street
        house
        entrance
        floor
        flat
        beltway_distance
        comment
        country_subdivision
        type
        coordinates {
            latitude
            longitude
        }
        delivery_price_low
        delivery_price_other
    }
`;

