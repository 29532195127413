import React, {
    FC, useState, useRef, useEffect, RefObject,
} from 'react';
import { ISetCard } from './types';
import css from './sets5dishes.module.scss';

export const Sets5DishesCardStub: FC<ISetCard> = ({
    onClick = () => { },
    disabled = false,
}: ISetCard) => {
    const stubButtonRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const [maxWidth, setMaxWidth] = useState<number | undefined>(undefined);

    /* EFFECT FOR CALCULATING LINK TITLE WIDTH (IT EQUALS TO BUTTON WIDTH ACCORDING TO MARKUPS */
    useEffect(
        () => {
            const calculateTitleWidth = () => {
                if (!stubButtonRef.current) return;
                setMaxWidth(stubButtonRef.current.offsetWidth);
            };
            window.addEventListener('resize', calculateTitleWidth);
            return () => {
                window.removeEventListener('resize', calculateTitleWidth);
            };
        },
        [],
    );

    return (
        <button
            className={css.linkCard}
            onClick={onClick}
            type="button"
            disabled={disabled}
        >

            <div
                className={css.linkTitle}
                style={{ maxWidth }}
            >
                Ещё
                <br />
                больше блюд
                <br />
                в другие даты
            </div>
            <div
                ref={stubButtonRef}
                className={css.linkButton}
            >
                Выбрать дату
            </div>
        </button>
    );
};
