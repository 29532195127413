import React, { useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
    formatDeliveryDateMobileSelect,
    areDatesInDifferentPeriods,
    getDateData,
} from 'app/utils/date';

import { LocaleContext } from 'app/containers/LocaleProvider';
import { locales } from 'app/components/DeliveryDate/deliveryDate.locales';
import { OverlayContextDispatch } from 'app/containers/contexts/overlay.context';


const DELIMITER = 'delimiter';

const delimiterTexts = {
    ru: <>Далее новое меню</>,
    en: <>New menu further</>,
};


const overlayId = 'date_select';


const createDatesSections = (datesWithDelimiters) => {
    const inner = (acc, items) => {
        if (items.indexOf(DELIMITER) <= 0) return [...acc, items];

        const indexOfDelimiter = items.indexOf(DELIMITER, 1);
        const newSection = items.slice(0, indexOfDelimiter);
        const restItems = items.slice(indexOfDelimiter + 1);

        return inner([...acc, newSection], restItems);
    };

    return inner([], datesWithDelimiters);
};


const MobileDateSelectContainer = (props) => {
    const {
        info = {
            onChangeDateConfirm: () => {},
            isTrial: false,
            isPromoPage: false,
            dates: null,
            selectDay: '',
        },
        displayDatesConfirmDialog = () => {},
        onClose,
        isSelectOpen,
    } = props;

    const {
        dates, periodEndDate, isTrial, selectDay,
    } = info;

    const { pushSelectMobileOverlay, closeCertainOverlay } = useContext(OverlayContextDispatch);
    const { locale } = useContext(LocaleContext);
    const l = locales[locale];

    const handleClose = useCallback(() => {
        try {
            onClose();
            closeCertainOverlay({ id: overlayId });
        } catch (e) {
            console.log(e);
        }
    }, [closeCertainOverlay, onClose]);

    const handleSelectDay = useCallback((e) => {
        const { currentTarget: { dataset: { day } } } = e;
        const combinedInfo = {
            ...info,
        };

        const {
            selectDay,
            isTrial,
            isPromoPage,
            onSelectDay,
            onChangeDateConfirm,
        } = combinedInfo;

        if (isTrial || isPromoPage) {
            onSelectDay(day);
            handleClose();
            return;
        }

        const isNextDateInAnotherPeriod = areDatesInDifferentPeriods(selectDay, day);

        if (isNextDateInAnotherPeriod) {
            const onConfirm = () => {
                onChangeDateConfirm(day);
                onSelectDay(day);
                // handleClose();
            };

            displayDatesConfirmDialog(onConfirm);
        } else {
            onSelectDay(day);
            // handleClose();
        }
    }, [displayDatesConfirmDialog, handleClose, info]);

    const canShowDatesDelimiter = useCallback((currentDateIndex, lastPeriodDateIndex) => periodEndDate
        && (currentDateIndex === lastPeriodDateIndex)
        && !isTrial
        && ((dates.length - 1) !== currentDateIndex),
    [dates, isTrial, periodEndDate]);

    const createDividedDates = useCallback((lastPeriodDateIndex) => dates.reduce((acc, i, index) => {
        if (!isTrial && lastPeriodDateIndex === null && index === 0) {
            return [...acc, DELIMITER, i];
        }
        if (!isTrial && canShowDatesDelimiter(index, lastPeriodDateIndex)) {
            return [...acc, i, DELIMITER];
        }

        return [...acc, i];
    }, []), [canShowDatesDelimiter, dates, isTrial]);

    const createDatesSectionItems = useCallback((dateItems) => dateItems.map((d) => {
        const { dayOfMonth, weekday } = formatDeliveryDateMobileSelect(d.date, locale);
        const { monthNameForDate } = getDateData(d.date, locale);
        const disabled = !d.enabled;
        const isSelected = d.date === selectDay;

        const capitalizedWeekday = weekday[0].toUpperCase() + weekday.slice(1);

        return {
            id: d.date,
            selected: isSelected,
            buttonProps: {
                onClick: handleSelectDay,
                'data-day': d.date,
                disabled,
            },
            TextCmp: (
                <>
                    <strong>
                        {capitalizedWeekday}
                        .
                    </strong>

                    <span className="num">{dayOfMonth}</span>
                    &nbsp;
                    {monthNameForDate}
                </>
            ),
        };
    }), [handleSelectDay, selectDay, locale]);

    useEffect(() => {
        if (isSelectOpen && dates) {
            const lastPeriodDateIndex = dates.reduce((acc, date, i) => (new Date(date.date) <= new Date(periodEndDate) ? i : acc), null);
            const dividedDates = createDividedDates(lastPeriodDateIndex);
            const datesSections = createDatesSections(dividedDates);

            const selectSections = datesSections.map((items, index) => ({
                id: `datesSection ${index}`,
                header: (index > 0) && delimiterTexts[locale],
                items: createDatesSectionItems(items, index),
            }));

            const selectData = {
                headerText: l.date,
                sections: selectSections,
                onClose: handleClose,
            };

            pushSelectMobileOverlay(
                overlayId,
                selectData,
                { onClick: handleClose },
            );
        }
    }, [isSelectOpen, dates, pushSelectMobileOverlay, createDatesSectionItems, l, handleClose, createDividedDates, periodEndDate, locale]);

    return null;
};


export default MobileDateSelectContainer;

MobileDateSelectContainer.propTypes = {
    isSelectOpen: PropTypes.bool.isRequired,

    info: PropTypes.shape({
        dates: PropTypes.arrayOf(PropTypes.shape({})),
        selectDay: PropTypes.string,
        onSelectDay: PropTypes.func,
        onChangeDateConfirm: PropTypes.func,
        periodEndDate: PropTypes.string,
        isTrial: PropTypes.bool,
        isPromoPage: PropTypes.bool,
    }),

    onClose: PropTypes.func.isRequired,
    displayDatesConfirmDialog: PropTypes.func,
};
