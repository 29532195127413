import React from 'react';
// import classNames from 'classnames';

import XBigIcon from 'assets/svg/x_medium.component.svg';
// import Button from 'app/components/ui/Button';
// import Paragraph from 'app/components/ui/Paragraph';
// import Heading from 'app/components/ui/Heading';

// import { dialogPropTypes, dialogDefaultProps } from './dialogPropTypes';

import './dialog.scss';


const FrameDialog = React.memo(({
    handleClose,
    rejectDisabled = false,
    children,
}) => (
    <div
        styleName="dialog__root"
        // id="touch-scrolllock-select" note: deprecated
    >
        <div styleName="dialog__header">
            <button
                styleName="dialog__close-button"
                onClick={handleClose}
                type="button"
                disabled={rejectDisabled}
            >
                <XBigIcon />
            </button>
        </div>
        <div className="">
            {children}
        </div>
    </div>
),
);

export { FrameDialog };


