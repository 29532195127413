import React from 'react';

import { filterlocales } from 'app/utils/filters';

export const locales = {
    ru: {
        ...filterlocales.ru,
        title: 'Что поменять в блюдах?',
        description: [
            '1. Отметьте нужное.',
            '2. Оформите заказ.',
            '3. Учтём ваши пожелания и&nbsp;доставим блюда, с&nbsp;обновлённым составом.',
        ],
        apply: 'Применить',

        dialog: {
            success: {
                strongText: 'Принято👌',
                strongTextSecond: 'Обновили👌',
                regularText: (
                    <>
                        Вы можете оплатить заказ.
                        <br />
                        А мы учтём ваши пожелания и доставим блюда, с обновлённым составом.
                    </>
                ),
                confirmText: 'Хорошо',
            },
            error: {
                strongText: 'Произошла ошибка',
                regularText: 'Попробуйте повторить позже',
                confirmText: 'Хорошо',
            },
        },
    },
    en: {
        ...filterlocales.ru,
        title: 'What to change in dishes?',
        description: [
            '1. Отметьте нужное.',
            '2. Оформите заказ.',
            '3. Учтём ваши пожелания и&nbsp;доставим блюда, с&nbsp;обновлённым составом.',
        ],
        apply: 'Apply',
        dialog: {
            success: {
                strongText: 'Saved 👌',
                strongTextSecond: 'Saved 👌',
                regularText: `
                    You can pay for the order. And we will take into account your wishes and deliver dishes with an updated composition.
                `,
                confirmText: 'OK',
            },
            error: {
                strongText: 'Error',
                regularText: 'Please try again later',
                confirmText: 'OK',
            },
        },
    },
};

export const getConfirmCustomizationLocales = (ingridient) => ({
    ru: {
        dialog: {
            success: {
                regularText: (
                    <>
                        У нас записано, что вы не любите
                        {' '}
                        {ingridient[0]}
                        .
                        <br />
                        Но в корзине есть блюдо
                        {' '}
                        {ingridient[1]}
                        !
                    </>
                ),
                confirmText: 'Всё ок, оставьте как есть',
                confirmTextMobile: 'Всё ок, оставьте как есть',
                rejectText: 'Выбрать другое',
                rejectTextMobile: 'Хочу вернуться и выбрать другое',
            },
            error: {
                strongText: 'Произошла ошибка',
                regularText: 'Попробуйте повторить позже',
                confirmText: 'Хорошо',
            },
        },
    },
    en: {
        dialog: {
            success: {
                regularText: (
                    <>
                        You mentioned that you do not like
                        {' '}
                        {ingridient[0]}
                        .
                        <br />
                        But there is a dish in the basket that contains
                        {' '}
                        {ingridient[1]}
                        !
                    </>
                ),
                confirmText: 'Ok, leave it without any changes',
                rejectText: 'Go back and choose others tags',
            },
            error: {
                strongText: 'Error',
                regularText: 'Please try again later',
                confirmText: 'OK',
            },
        },
    },
});
