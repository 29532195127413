

const requestPushPermission = async () => {
    const permission = await window.Notification.requestPermission();
    if (permission !== 'granted') {
        throw new Error('Permission not granted for Notification');
    }
    return permission;
};

// export const showLocalNotification = (body) => {
//     const options = {
//         body,
//         // icon: 'https://my.staging.elementaree.org/favicons/android-chrome-192x192.png',
//         icon: '/favicons/android-chrome-192x192.png',
//         vibrate: [100, 100],
//         // here you can add more properties like icon, image, vibrate, etc.
//     };
//     if (swReg) {
//         swReg.showNotification('Elementaree', options);
//     }
// };

export const requestPermission = async () => {
    if (!navigator.serviceWorker) return null;
    const swRegistration = await navigator.serviceWorker.ready;
    try {
        await requestPushPermission();
        return swRegistration;
    } catch (e) {
        // permission not granted
    }
    return null;
};

export default {
    // showLocalNotification,
    requestPermission,
};
