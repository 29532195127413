import { gql } from '@apollo/client';


export default gql`
    query getMenuDate(
        $currentPeriod: String
        $filtersModified: Boolean
    ) {
        menuDates(
            period: $currentPeriod
            filters_modified: $filtersModified
        ) {
            dates {
                startDate
                eeAvailableDates
                everydayAvailableDates
                trialAvailableDates
                premiumAvailableDates
                topAvailableDates
                zapasAvailableDates

                setsAvailableDates @client
                allAvailableDates @client
            }
        }
    }
`;

export const MenuDatesCustomizationQuery = gql`
    query MenuDatesCustomizationQuery {
        viewCustomizationComment {
            comment
            type
            id
            reason
        }

        user {
            id
            customizationTagsDetailed {
                id
                title
            }
            filterTags
            lastCustomizationComment
        }
    }
`;
