import React from 'react';
import PropTypes from 'prop-types';

import { UIHeading } from 'app/components/ui';
import { SelectionsMobileSlider } from './MenuSelectionsMobileSlider';

import './menuSelectionsMobileSlider.scss';


const MenuSelectionsTitle = () => (
    <UIHeading level="2.1" styleName="menu-selections-title">
        А мы кое-что
        <br />
        приготовили для вас
    </UIHeading>
);


export const MenuSelectionsMobileSlider = ({ realm = 'target_realm' }) => (
    <>
        <MenuSelectionsTitle />
        <div styleName="menu-selections-slider__root">
            <div styleName="menu-selections-slider__list-container">
                <SelectionsMobileSlider realm={realm} />
            </div>
        </div>
    </>
);

MenuSelectionsMobileSlider.propTypes = {
    realm: PropTypes.string,
};
