import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';

import ArrowIcon from 'app/assets/svg/menu_arrow.svg';

import './filter-item.scss';


class FilterItem extends React.PureComponent {
    handleToggle = () => {
        const { onToggle, isExpanded } = this.props;
        const next = !isExpanded;

        onToggle(next);
    }

    render() {
        const {
            title,
            children,
            Icon,
            subtitle,
            hideOnExpand,
            isExpanded,
            isArrowExpanded,
        } = this.props;

        const arrowExpandedState = isArrowExpanded === null ? isExpanded : isArrowExpanded;

        const wrapClasses = classNames({
            'filter-item-wrap': true,
            'is-expanded': isExpanded,
        });

        const buttonClasses = classNames({
            'filter-item': true,
            'is-expanded': isExpanded && !hideOnExpand,
        });

        const arrowClasses = classNames({
            'filter-item__arrow': true,
            'filter-item__arrow--expanded': arrowExpandedState,
        });

        const contentClasses = classNames({
            'filter-item__content-wrap': true,
            'is-title-hidden': hideOnExpand,
            'is-expanded': isExpanded,
        });

        return (
            <div styleName={wrapClasses}>
                <button
                    type="button"
                    styleName={buttonClasses}
                    onClick={this.handleToggle}
                    disabled={isExpanded && hideOnExpand}
                >
                    <Transition
                        in={isExpanded}
                        timeout={400}
                    >
                        {(transitionState) => (
                            <div styleName={`filter__icon ${transitionState}`}>
                                <Icon />
                            </div>
                        )}
                    </Transition>
                    <div styleName="filter-item__text">
                        <Transition
                            in={isExpanded && hideOnExpand}
                            timeout={400}
                        >
                            {(transitionState) => (
                                <div styleName={`filter-item__name ${transitionState}`}>
                                    {title}
                                </div>
                            )}
                        </Transition>
                        <Transition
                            in={isExpanded}
                            timeout={400}
                        >
                            {(transitionState) => (
                                <div styleName={`filter-item__subtitle ${transitionState}`}>
                                    {subtitle()}
                                </div>
                            )}
                        </Transition>
                    </div>
                    <div styleName={arrowClasses}>
                        <ArrowIcon />
                    </div>
                </button>
                <div styleName={contentClasses}>
                    {children}
                </div>
            </div>
        );
    }
}

FilterItem.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    hideOnExpand: PropTypes.bool,
    isExpanded: PropTypes.bool.isRequired,
    isArrowExpanded: PropTypes.bool,
    Icon: PropTypes.func.isRequired,
    onToggle: PropTypes.func,
};

const FilterItemFC = (props) => {
    const {
        hideOnExpand = false,
        isArrowExpanded = null,
        onToggle = () => { },
    } = props;

    return (
        <FilterItem
            {...props}
            hideOnExpand={hideOnExpand}
            isArrowExpanded={isArrowExpanded}
            onToggle={onToggle}
        />
    );
};

export default FilterItemFC;
