/* eslint-disable camelcase */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import PropTypes from 'prop-types';
import ScrollLock from 'react-scrolllock';
import compose from 'lodash/flowRight';
import { withLocaleContext } from 'app/containers/LocaleProvider';
import { UIHeading, UIParagraph } from 'app/components/ui';
import cn from 'classnames';
import XBigIcon from './cross.svg';

import './subscription-info-popup.scss';

const localeRu = {
    main_title: <>Что такое подписка?</>,
    rows: [
        {
            row_id: 1,
            row_title: <>
                Это доставка раз в&nbsp;неделю, скидка&nbsp;10%.
            </>,
            row_text: <>
                После оплаты доставки в&nbsp;личном кабинете появляется следующая.
            </>,
        },
        {
            row_id: 2,
            row_title: 'Как можно менять меню?',
            row_text: <>Каждую неделю можно выбирать любые блюда на&nbsp;любую сумму. Оплата тоже изменится.</>,
        },
        {
            row_id: 4,
            row_title: <>Когда можно вносить изменения?</>,
            row_text: <>
                <p styleName="subtitle">Для Москвы</p>
                Меню - за
                {' '}
                <span styleName="popup--num">3</span>
                {' '}
                дня до доставки до
                {' '}
                <span styleName="popup--num">16:00</span>
                <br />
                Дату доставки - за
                {' '}
                <span styleName="popup--num">2</span>
                {' '}
                дня до
                {' '}
                <span styleName="popup--num">16:00</span>
                <br />
                Адрес и время - за
                {' '}
                <span styleName="popup--num">1</span>
                {' '}
                день до
                {' '}
                <span styleName="popup--num">16:00</span>
                <br />
                <p styleName="subtitle">Для Санкт-Петербурга</p>
                Меню - за
                {' '}
                <span styleName="popup--num">4</span>
                {' '}
                дня до доставки до
                {' '}
                <span styleName="popup--num">16:00</span>
                <br />
                Дату доставки - за
                {' '}
                <span styleName="popup--num">3</span>
                {' '}
                дня до
                {' '}
                <span styleName="popup--num">16:00</span>
                <br />
                Адрес и время - за
                {' '}
                <span styleName="popup--num">1</span>
                {' '}
                день до
                {' '}
                <span styleName="popup--num">16:00</span>
            </>,
        },
        {
            row_id: 5,
            row_title: <>Как остановить подписку?</>,
            row_text: <>
                В&nbsp;личном кабинете легко отложить доставку.
                Хоть&nbsp;на&nbsp;месяц!
                Скидка при&nbsp;этом сохранится. По&nbsp;звонку можно всё&nbsp;отменить, но&nbsp;и&nbsp;скидка сгорит.
            </>,
        },
    ],
};


const locales = {
    ru: localeRu,
    en: localeRu,
};

const SubscriptionInfoPopup = React.memo((props) => {
    const {
        onClose,
        localeContext: { locale },
        transitionState,
        popUpUiState,
    } = props;

    const scrollableContentRef = React.createRef();

    const { main_title, rows } = locales[locale];

    const subinfoPopupRootClasses = cn({
        'subscription-info-popup': true,
        [popUpUiState]: true,
    });

    const popupHeaderClasses = cn({
        'popup-header': true,
        [popUpUiState]: true,
    });

    return (
        <div styleName={subinfoPopupRootClasses}>
            <div styleName={popupHeaderClasses}>
                <button
                    styleName="popup-header__close"
                    type="button"
                    onClick={onClose}
                    aria-label="Закрыть информацию о подписке"
                >
                    <XBigIcon />
                </button>
            </div>
            <div styleName="popup-content" ref={scrollableContentRef}>
                {/* MAIN TITLE */}
                <UIHeading
                    level="3.2"
                    styleName="popup-content__main-title"
                >
                    {main_title}
                </UIHeading>
                {/* ROWS  */}
                {rows.map((item) => (
                    <div styleName="popup-content-message" key={item.row_id}>
                        <h4 styleName="popup-content-message__title">
                            {item.row_title}
                        </h4>
                        <UIParagraph level="4.1" styleName="popup-content-message__text">
                            {item.row_text}
                        </UIParagraph>
                    </div>
                ))}
            </div>
            {transitionState === 'entered' && (
                <ScrollLock touchScrollTarget={scrollableContentRef.current} />
            )}
        </div>
    );
});

export default compose(withLocaleContext)(SubscriptionInfoPopup);

SubscriptionInfoPopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    localeContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,
    transitionState: PropTypes.string.isRequired,
    popUpUiState: PropTypes.string.isRequired,
};
