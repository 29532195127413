import React from 'react';
import PropTypes from 'prop-types';

import { UIHeading } from 'app/components/ui';

import '../../single-step-delivery-info-area.scss';


const paymentLocales = {
    ru: {
        title: 'Оплата',
        online: 'Банковская карта',
        cash: 'Наличными курьеру',
        sbp: 'Курьеру по QR-коду',
        subscription: 'Оформлена подписка -10%',
    },
    en: {
        title: 'Payment',
        online: 'Credit card',
        cash: 'Cash',
        sbp: 'By QR code',
        subscription: 'Subscription enabled -10%',
    },
};

export function SingleStepPaymentRow(props) {
    const {
        locale,
        paymentMethod,
        subscriptionTypeQuery: { subscriptionType },
    } = props;

    const l = paymentLocales[locale];

    return (
        <div styleName="single-step-checkout__row-payment">
            <UIHeading level="3.2">
                {l.title}
            </UIHeading>
            <div styleName="single-step-checkout__row-payment-active-method">
                <span styleName="single-step-checkout__text-primary">
                    {l[paymentMethod]}
                </span>
            </div>
            {subscriptionType === 'subscription' && (
                <span styleName="single-step-checkout__row-paymen-subscription-label">
                    {l.subscription}
                </span>
            )}
        </div>
    );
}

SingleStepPaymentRow.propTypes = {
    locale: PropTypes.string.isRequired,
    paymentMethod: PropTypes.string.isRequired,
    subscriptionTypeQuery: PropTypes.shape({
        subscriptionType: PropTypes.string.isRequired,
    }).isRequired,
};
