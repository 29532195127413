import cookie from 'js-cookie';

export const setExistedCookieValue = (layerName, name, value, lifeterm = 7) => {
    if (value) {
        cookie.set(name, value, { expires: lifeterm });
        console.log(`${layerName}: cookie "${name}" has been set`);
    } else {
        cookie.remove(name);
        console.log(`${layerName}: cookie "${name}" has been removed`);
    }
};
