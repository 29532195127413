/**
 * @description diff beetween currentDate and X+N
 * количество дней от текущей даты в Unix Epoch(секундах) формате.
 * @example "86400" = сутки. Date.now() + c* 1000 = текущая дата + сутки.
 * */

export const ONE_DAYS_IN_UTC_FORMAT = '86400';
export const TWO_DAYS_IN_UTC_FORMAT = '172800'; /** @description TWO_DAYS_IN_UTC_FORMAT = X+2 */
export const THREE_DAYS_IN_UTC_FORMAT = '259200'; /** @description THREE_DAYS_IN_UTC_FORMAT = X+3 */
export const FOUR_DAYS_IN_UTC_FORMAT = '345600';
export const FIVE_DAYS_IN_UTC_FORMAT = '432000';
export const SIX_DAYS_IN_UTC_FORMAT = '518400';

// export const DIFFS = Array.from(new Array(14)).map((v, index) => {
export const DIFFS = Array.from(new Array(40)).map((v, index) => {
    const d = Number(ONE_DAYS_IN_UTC_FORMAT);
    return String(d * (index + 1));
});
