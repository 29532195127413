export const locales = {
    ru: {
        // Render
        periodTitle: 'Неделя доставки',

        // renderPeriodSubtitle
        periodSubTitle: 'На Новый год',

    },
    en: {
        // Render
        periodTitle: 'Delivery week',

        // renderPeriodSubtitle
        periodSubTitle: 'New year',
    },
};
