import get from 'lodash/get';

export const createTranslate = (options) => {
    const { locales, locale } = options;
    return (key) => {
        const text = get(locales[locale], key);

        if (!key) return '';
        if (!text) return key;

        return text;
    };
};

export default {
    createTranslate,
};
