import React, {
    useReducer, useContext, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { LocaleContext } from 'app/containers/LocaleProvider';


export const LOADER_APPEARING_TIME = 200;
export const LOADER_MINIMAL_TIME = 3000;

// custom ref for use in reducer
// eslint-disable-next-line prefer-const
let localeRef = { current: 'ru' };

const defaultTexts = {
    ru: { mainText: 'Загрузка' },
    en: { mainText: 'Loading' },
};

const defaultServiceData = {
    isLoading: false,
    autoHide: false, // automatically hide after LOADER_MINIMAL_TIME
};

const initialState = {
    ...defaultTexts[localeRef.current],
    ...defaultServiceData,
};

const reducer = (state, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case 'show': {
            const { autoHide, ...texts } = action.payload;
            const finalTexts = texts.mainText
                ? texts
                : defaultTexts[localeRef.current];

            return {
                ...state,
                ...finalTexts,
                autoHide,
                isLoading: true,
            };
        }
        case 'close': {
            return {
                ...state,
                ...defaultServiceData,
            };
        }
        default: {
            return state;
        }
    }
};

export const loaderDispatchContext = React.createContext(() => {});
export const LoaderDataContext = React.createContext(initialState);

export const LoaderProvider = ({ children }) => {
    const { locale } = useContext(LocaleContext);

    const initialLocaleData = useMemo(() => ({
        isLoading: false,
        ...defaultTexts[locale],
    }), [locale]);

    const [loadingData, loadingDispatch] = useReducer(reducer, initialLocaleData);

    const { isLoading } = loadingData;

    // change localeRef for use in reducer
    useEffect(() => {
        localeRef.current = locale;
    }, [locale]);

    // automatically hide after LOADER_MINIMAL_TIME
    useEffect(() => {
        if (!isLoading) return;

        setTimeout(() => loadingDispatch({ type: 'close' }), LOADER_MINIMAL_TIME);
    }, [isLoading]);

    return (
        <loaderDispatchContext.Provider value={loadingDispatch}>
            <LoaderDataContext.Provider value={loadingData}>
                {children}
            </LoaderDataContext.Provider>
        </loaderDispatchContext.Provider>
    );
};

export const withLoaderData = (WrappedComponent) => (forwardedProps) => {
    const data = useContext(LoaderDataContext);

    return (
        <WrappedComponent
            LoaderDataContext={data}
            {...forwardedProps}
        />
    );
};
export const withLoaderDispatch = (WrappedComponent) => (forwardedProps) => {
    const set = useContext(loaderDispatchContext);

    return (
        <WrappedComponent
            loaderDispatchContext={set}
            {...forwardedProps}
        />
    );
};


LoaderProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
