
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import MobileCartHeader from 'app/components/MobileCartHeader';


const OPENED = 'opened';
const CLOSED = 'closed';

const BasketMobileHeader = (props) => {
    const {
        customizationCommentShowingState,
        toggleCustomizationCommentArea,

        prearedBasketAreas: {
            header: { isHeader },
        },
        hiddenElements: { backstep: hideBackstep },

        closeBasket,
    } = props;

    const onClickBack = hideBackstep ? null : closeBasket;
    const customizationCommentOpened = customizationCommentShowingState === OPENED;

    if (!isHeader) return null;

    const closeComment = toggleCustomizationCommentArea(false);
    const handleClickBack = customizationCommentOpened ? closeComment : onClickBack;

    if (hideBackstep && !customizationCommentOpened) {
        return null;
    }

    return (
        <MobileCartHeader
            isRightBlockShown={false}
            isLeftBlockShown={!hideBackstep}
            onClickBack={handleClickBack}
            realm="basket"
        />
    );
};

BasketMobileHeader.propTypes = {
    customizationCommentShowingState: PropTypes.oneOf([OPENED, CLOSED]).isRequired,
    toggleCustomizationCommentArea: PropTypes.func.isRequired,

    prearedBasketAreas: PropTypes.shape({
        header: PropTypes.shape({
            isHeader: PropTypes.bool.isRequired,
        }).isRequired,
    }).isRequired,

    hiddenElements: PropTypes.shape({
        backstep: PropTypes.bool,
    }).isRequired,

    closeBasket: PropTypes.func.isRequired,
};

const BasketHeaderArea = (props) => {
    const {
        /* DATA */
        hiddenElements,
        prearedBasketAreas,
        customizationCommentShowingState,
        toggleCustomizationCommentArea,
        closeBasket,
    } = props;

    return (
        <BasketMobileHeader
            hiddenElements={hiddenElements}
            prearedBasketAreas={prearedBasketAreas}
            customizationCommentShowingState={customizationCommentShowingState}
            toggleCustomizationCommentArea={toggleCustomizationCommentArea}
            closeBasket={closeBasket}
        />
    );
};

BasketHeaderArea.propTypes = {
    customizationCommentShowingState: PropTypes.oneOf([OPENED, CLOSED]).isRequired,
    toggleCustomizationCommentArea: PropTypes.func.isRequired,

    prearedBasketAreas: PropTypes.shape({
        header: PropTypes.shape({
            isHeader: PropTypes.bool.isRequired,
        }).isRequired,
    }).isRequired,

    hiddenElements: PropTypes.shape({
        backstep: PropTypes.bool,
    }).isRequired,

    closeBasket: PropTypes.func.isRequired,
};


export default React.memo(BasketHeaderArea);


