import flatten from 'lodash/flatten';

export const periods = [
    '2024-03-04',
    '2024-03-11',
    '2024-03-18',
    '2024-03-25',
];

export const enabledDishes = {
    main: [
        '4344',
        '4011',
    ],
    women: [
        '3941',
        '3948',
        '3947',
        '3943',
        '3945',
        '3942',
    ],
    guest: [
        '4317',
        '4314',
        '3374',
        '58',
        '1373',
        '3723',
        '122',
        '4169',
    ],
};

export const availableBasketDishIds = flatten(Object.values(enabledDishes));
