import { formatDateToISO } from 'app/utils/date';

import {
    TWO_DAYS_IN_UTC_FORMAT,
    THREE_DAYS_IN_UTC_FORMAT,
    FOUR_DAYS_IN_UTC_FORMAT,
    FIVE_DAYS_IN_UTC_FORMAT,
    SIX_DAYS_IN_UTC_FORMAT,

    DIFFS as ALL_DIFFS,
} from './menuDates.consts';


const DIFFS_FOR_CUSTOM_MENU = [
    THREE_DAYS_IN_UTC_FORMAT,
    FOUR_DAYS_IN_UTC_FORMAT,
    FIVE_DAYS_IN_UTC_FORMAT,
    SIX_DAYS_IN_UTC_FORMAT,
];

const createDateStringsFromDiffs = (diffs, currentDate) => {
    const dateStrings = diffs.map((diff) => {
        const date = formatDateToISO(new Date(currentDate + diff * 1000));
        return date;
    });

    return dateStrings;
};

const findDiffForDateFromUrl = ({
    availableDates,
    diffDates,
    dateFromUrl,
}) => {
    if (!availableDates.includes(dateFromUrl)) {
        return null;
    }

    const dateIndex = diffDates.findIndex((d) => d === dateFromUrl);
    return ALL_DIFFS[dateIndex];
};


/**
* Подбирает дефолтное значение даты доставки в селекте даты меню
*
* @param {Object} arguments
* @param {Object} arguments.menuDates - все доступные даты
* @param {Array} arguments.menuDates.eeAvailableDates - доступные даты обычного меню ЕЕ
* @param {Array} arguments.menuDates.trialAvailableDates - доступные даты для триала 5x2
* @param {Array} arguments.menuDates.everydayAvailableDates - доступные даты для menu=everyday
* @param {Array} arguments.menuDates.premiumAvailableDates - доступные даты для menu=premium
* @param {Number} arguments.currentDate - сегодняшняя дата, нужна для вычисления дат X+1, X+2...
* @param {String} arguments.dateFromUrl - параметр date из урла
* @returns {String} one of ONE_DAYS_IN_UTC_FORMAT, TWO_DAYS_IN_UTC_FORMAT...
*
* Алгоритм подбора
* - Если в урле есть дата, и эта дата есть среди всех menuDates, то выбирается эта дата
* - Если дата из урла не подошла или ее нет, то среди menuDates.eeAvailableDates происходит поиск даты не меньше X+3,
*       чтобы юзеру были доступны фильтр-теги и комментарий
* - Если даты не меньше X+3 нет, но в menuDates.eeAvailableDates есть X+2, то выбирается она
* - Если и ее нет, то выбирается какая-нибудь дата среди всех остальных в menuDates
*/
export default function resolveDefaultDate({
    menuDates,
    currentDate,
    dateFromUrl = null,
}) {
    const { setsAvailableDates, allAvailableDates, eeAvailableDates } = menuDates;

    const allDiffDates = createDateStringsFromDiffs(ALL_DIFFS, currentDate);
    const diffDatesForCustomMenu = createDateStringsFromDiffs(DIFFS_FOR_CUSTOM_MENU, currentDate);

    /*
        Если в урле уже есть выбранная дата, то происходит проверка, есть ли она хотя бы для одного набора
        Если есть - то возвращается diff для этой даты
    */
    const diffForDateFromUrl = findDiffForDateFromUrl({
        availableDates: allAvailableDates,
        diffDates: allDiffDates,
        dateFromUrl,
    });
    if (diffForDateFromUrl) {
        return diffForDateFromUrl;
    }

    /*
        Поиск доступной даты среди дат >= X+3 из УДД elementaree
    */
    const matchedDateIndexForCustomMenu = diffDatesForCustomMenu.findIndex((d) => eeAvailableDates.includes(d));
    if (matchedDateIndexForCustomMenu >= 0) {
        return DIFFS_FOR_CUSTOM_MENU[matchedDateIndexForCustomMenu];
    }

    /*
        Поиск доступной даты среди дат >= X+2 из УДД elementaree
    */
    if (eeAvailableDates.length > 0) {
        return TWO_DAYS_IN_UTC_FORMAT;
    }

    /*
        Если в поиске даты среди УДД elementaree что-то пошло не так,
        то ищем дату среди триалов и эвридей
    */
    const matchedDateIndexForSets = allDiffDates.findIndex((d) => setsAvailableDates.includes(d));
    return ALL_DIFFS[matchedDateIndexForSets];
}
