import React, { useContext } from 'react';
import cn from 'classnames';

import { LoaderDataContext } from 'app/containers/contexts/loader.context';

import './loader.scss';


/*
'Loader' изолированный компонент.
Рендерится всегда, чтобы снизить время монтирования.
Невидим, когда неактивен.
*/
const Loader = React.memo(() => {
    const { isLoading, mainText } = useContext(LoaderDataContext);

    if (!isLoading) {
        return null;
    }

    const loaderRootClasses = cn({
        loader__root: true,
        loading: isLoading,
    });
    const progressBarClasses = cn({
        'loader__progress-bar': true,
        'with-animation': isLoading,
    });

    return (
        <div styleName={loaderRootClasses}>
            <div styleName="loader__content-container">
                <div styleName="loader__text">{mainText}</div>
                <div styleName={progressBarClasses} />
            </div>
        </div>
    );
});


export default Loader;
