import { LOCATION_SPB, LOCATION_MSK, LOCATION_NIZ } from 'app/const/location';
import { createUrl } from 'app/utils/url';
import { defaultSetLink } from 'app/const/links/const';


// ***** PREMIUM *****
// searchParams:
const BASKET_SCHEMA = [
    ['cm', '10'],
    ['menu', 'premium'],
];

const COMMON_SEARCH_PARAMS = [
    ...BASKET_SCHEMA,
    ['payment', 'recurrent'],
];


// hrefs:
const HREF = createUrl({
    path: 'basket',
    params: [
        ...COMMON_SEARCH_PARAMS,
    ],
});

const GREEN_HREF = createUrl({
    path: 'basket',
    params: [
        ...COMMON_SEARCH_PARAMS,
        ['filter_tag', '1220'],
    ],
});

// sets:
export const PREMIUM_SET = {
    ...defaultSetLink,

    id: 'PREMIUM_SET',

    originalPrice: '5 240',
    discountPrice: '4 716',
    discountPercent: '10',
    discountSum: '500',

    href: HREF,

    basketSchema: BASKET_SCHEMA,
    subdivisions: [LOCATION_MSK, LOCATION_SPB, LOCATION_NIZ],
    // datesType: 'premiumAvailableDates',
    datesType: 'eeAvailableDates',
    setType: 'premium',
};


export const PREMIUM_SET_GREEN_VERSION = {
    ...defaultSetLink,

    id: 'PREMIUM_SET_GREEN_VERSION',

    originalPrice: '5 240',
    discountPrice: '4 716',
    discountPercent: '10',
    discountSum: '500',

    href: GREEN_HREF,

    basketSchema: [
        ['cm', '10'],
        ['menu', 'premium'],
    ],
    subdivisions: [LOCATION_MSK, LOCATION_SPB, LOCATION_NIZ],
    // datesType: 'premiumAvailableDates',
    datesType: 'eeAvailableDates',
    setType: 'premium',
};
