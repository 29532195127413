import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { LocaleContext } from 'app/containers/LocaleProvider';
import { DialogAnimated } from 'app/components/Dialog';
import ContactUsIcons from 'app/components/ContactUsIcons';


const dialogTexts = {
    ru: {
        strongText: 'Вы удалили профиль!',
        regularText: 'Для восстановления напишите нам',
        confirmText: 'Выйти',
        rejectText: '',
    },
    en: {
        strongText: 'You`ve deleted your account',
        regularText: 'Please, contact us',
        confirmText: 'Log out',
        rejectText: '',
    },
};

const visibleLinks = ['vk', 'telegram'];

export function DeletedAccountDialog(props) {
    const {
        onUserQuit,
        isOpen,
    } = props;

    const { locale } = useContext(LocaleContext);

    const dlTexts = dialogTexts[locale];
    const { regularText } = dlTexts;

    return (
        <DialogAnimated
            {...dlTexts}
            isOpen={isOpen}
            onConfirm={onUserQuit}
            notifyOnly
            rejectDisabled
            regularText={(
                <>
                    {regularText}
                    <br />
                    <br />
                    <ContactUsIcons
                        visibleLinks={visibleLinks}
                        align="center"
                    />
                </>
            )}
        />
    );
}
DeletedAccountDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
};
