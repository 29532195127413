import { gql } from '@apollo/client';

export const GET_CHECKOUT_DATA = gql`
   query getCheckoutData {
        checkoutData @client {
            telephone
            email
            street
            apartment
            floor
            entrance
            day
            timePeriod
            timeIndex
            timeIntervalsInsideBetlwayTitle
            timeIntervalsOutsideBetlwayTitle
            subdivision
            isContactlessDeliveryActivated
        }
    }
`;

// export const SAVE_CHECKOUT_DATA = gql`
//     mutation saveCheckoutData_DONE ($checkoutData: CheckoutDataInput!) {
//         saveCheckoutData_DONE(checkoutData: $checkoutData) @client
//     }
// `;

export const GET_INVOICE_DELIVERY_DATE = gql`
    query getInvoiceDeliveryDate {
        invoiceDeliveryDate @client
    }
`;

// export const SET_INVOICE_DELIVERY_DATE = gql`
//         mutation setInvoiceDeliveryDate_DONE ($deliveryDate: String!) {
//             setInvoiceDeliveryDate_DONE(deliveryDate : $deliveryDate) @client
//         }
// `;

export const CHECKOUT_DELIVERY_DATE_QUERY = gql`
    query checkoutDeliveryDate {
        checkoutDeliveryDate @client
    }
`;

// export const SET_CHECKOUT_DELIVERY_DATE = gql`
//     mutation setCheckoutDeliveryDate_DONE ($deliveryDate: String!) {
//         setCheckoutDeliveryDate_DONE(deliveryDate : $deliveryDate) @client
//     }
// `;
