import React, {
    createContext,
    useState,
} from 'react';
import PropTypes from 'prop-types';


export const DesignContext = createContext({});

export const withDesignContext = (Component) => (props) => (
    <DesignContext.Consumer>
        {(designContext) => <Component {...props} designContext={designContext} />}
    </DesignContext.Consumer>
);

export default function DesingProvider(props) {
    const { children } = props;

    const [headerHeight, setHeaderHeight] = useState(0);

    const designContextValue = {
        headerHeight,
        setHeaderHeight,
    };

    return (
        <DesignContext.Provider value={designContextValue}>
            {children}
        </DesignContext.Provider>
    );
}


DesingProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};
