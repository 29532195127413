import { LOCATION_MSK } from 'app/const/location';
import { createUrl, joinSearchParamValue } from 'app/utils/url';
import { isNewbie } from 'app/utils/user/user';
import { defaultSetLink } from 'app/const/links/const';


// ***** TRIAL_PREMIUM (3x2) *****
const TRIAL_PREMIUM_PROMO = 'pembtry';

// searchParams:
const TRIAL_PREMIUM_BASKET_SCHEMA = [
    ['dishes', joinSearchParamValue('2463', '2466', '2467')],
];

const COMMON_SEARCH_PARAMS = [
    ...TRIAL_PREMIUM_BASKET_SCHEMA,
    ['extra', '3x2'],
    ['expand', 'all'],
    ['payment', 'online'],
    ['promo_code', TRIAL_PREMIUM_PROMO],
    ['hide', 'payment_button_recurrent'],
];

// hrefs:
const TRIAL_PREMIUM_BASKET_HREF = createUrl({
    path: 'basket',
    params: [
        ...COMMON_SEARCH_PARAMS,
    ],
});

const TRIAL_PREMIUM_CHECKOUT_HREF = createUrl({
    path: 'checkout',
    params: [
        ...COMMON_SEARCH_PARAMS,
    ],
});

// sets:
const TRIAL_PREMIUM_SET = {
    ...defaultSetLink,

    id: 'TRIAL_PREMIUM_SET',
    check: ({ user }) => isNewbie(user),

    promo: TRIAL_PREMIUM_PROMO,

    originalPrice: '3 000',
    discountPrice: '1 990',
    discountPercent: '33',
    discountSum: '1 000',

    hrefBasket: TRIAL_PREMIUM_BASKET_HREF,
    hrefCheckout: TRIAL_PREMIUM_CHECKOUT_HREF,

    basketSchema: TRIAL_PREMIUM_BASKET_SCHEMA,
    subdivisions: [LOCATION_MSK],
    datesType: 'premiumAvailableDates',
    dishesCount: '3',
};


export { TRIAL_PREMIUM_SET };
