/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import { DEFAULT_CATEGORIES } from 'app/const/categories';
import {
    prevSelectedPeriodsVar, selectedPeriodVar, isSelectedDefaultFiltersVar,
    isUserChangesTagsVar, selectedCategoriesVar, selectedCategoryIdVar,
    selectedTagsVar,
} from '../reaction';

/* REACTIONS */

/*
    prevSelectedPeriodsVar,
    selectedPeriodVar,
    isSelectedDefaultFiltersVar,
    isUserChangesTagsVar,
    selectedCategoriesVar,
    selectedCategoryIdVar,
    selectedTagsVar,
*/

export const _updateIsSelectedDefaultFiltersReaction = () => {
    const selectedCategories = selectedCategoriesVar();
    const selectedTags = selectedTagsVar();

    // В массивах хранятся только id, поэтому достаточно проверить длины
    const isAllCategoriesSelected = selectedCategories.length === DEFAULT_CATEGORIES.length;
    const isTagsNotSelected = selectedTags.length === 0;

    const isSelectedDefaultFilters = isAllCategoriesSelected && isTagsNotSelected;

    isSelectedDefaultFiltersVar(isSelectedDefaultFilters);
};


/**
 * @param {*} param
 */
export const setDefaultFiltersReaction = ({ variables }) => {
    const {
        periodStart,
        categoryId,
        categories,
        tags,
    } = variables;

    const selectedTags = selectedTagsVar();

    selectedPeriodVar(periodStart);
    prevSelectedPeriodsVar([periodStart]);
    selectedCategoryIdVar(categoryId);
    selectedCategoriesVar(categories);
    selectedTagsVar([...new Set([...selectedTags, ...tags])]);

    _updateIsSelectedDefaultFiltersReaction();
};

/**
 * @param {*} param
 */
export const pickPeriodReaction = ({ periodStart, skipSavingToPrev }) => {
    const prevSelectedPeriods = prevSelectedPeriodsVar();
    const selectedPeriod = selectedPeriodVar();

    if (selectedPeriod !== periodStart) {
        const isNeedeUpdatePrev = !skipSavingToPrev && !prevSelectedPeriods.includes(periodStart);
        const nextPeriods = isNeedeUpdatePrev ? [periodStart, ...prevSelectedPeriods] : prevSelectedPeriods;

        selectedPeriodVar(periodStart);
        prevSelectedPeriodsVar(nextPeriods);
        _updateIsSelectedDefaultFiltersReaction();
    }
};

/**
 * @param {*} param
 */
export const pickCategoryReaction = ({ categoryId }) => {
    selectedCategoryIdVar(categoryId);
    _updateIsSelectedDefaultFiltersReaction();
};

/**
 * @param {*} param
 */
export const setCategoriesReaction = ({ categories }) => {
    selectedCategoriesVar(categories);
    _updateIsSelectedDefaultFiltersReaction();
};
