import React from 'react';
import PropTypes from 'prop-types';

import { formatPhone } from 'app/utils/phone';

import '../../single-step-delivery-info-area.scss';


export function SingleStepPhoneRow(props) {
    const { userQuery: { user: { phone } } } = props;
    const phoneStr = formatPhone(phone, {
        operatorDelimiters: [' ', ' '],
    });
    return (
        <div styleName="single-step-checkout__row-phone">
            {phoneStr}
        </div>
    );
}

SingleStepPhoneRow.propTypes = {
    userQuery: PropTypes.shape({
        user: PropTypes.shape({
            phone: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};
