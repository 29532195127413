import { defaultLink } from 'app/const/links/const';


const origin = process.env.ORIGIN;


const BLOG_PAGE = {
    ...defaultLink,

    id: 'BLOG_PAGE',
    hrefRu: '/blog/',
    hrefRuFull: `${origin}/blog/`,
};

const QUALITY_PAGE = {
    ...defaultLink,

    id: 'QUALITY_PAGE',
    hrefRu: '/quality/',
    hrefRuFull: `${origin}/quality/`,
    hrefEn: '/eng/quality/',
};

const DELIVERY_PAGE = {
    ...defaultLink,

    id: 'DELIVERY_PAGE',
    hrefRu: '/delivery/',
    hrefRuFull: `${origin}/delivery/`,
};

const ABOUT_PAGE = {
    ...defaultLink,

    id: 'ABOUT_PAGE',
    hrefRu: '/about/',
    hrefRuFull: `${origin}/about/`,
    hrefEn: '/eng/about/',
};

const UNPACKING_PAGE = {
    ...defaultLink,

    id: 'UNPACKING_PAGE',
    hrefRu: '/unpacking/',
    hrefRuFull: `${origin}/unpacking/`,
};

const OFFER_PAGE = {
    ...defaultLink,

    id: 'OFFER_PAGE',
    hrefRu: '/offer/',
    hrefRuFull: `${origin}/offer/`,
};

const CONTACTS_PAGE = {
    ...defaultLink,

    id: 'CONTACTS_PAGE',
    hrefRu: '/contacts/',
    hrefRuFull: `${origin}/contacts/`,
};

const FAQ_PAGE = {
    ...defaultLink,

    id: 'FAQ_PAGE',
    hrefRu: '/faq/',
    hrefRuFull: `${origin}/faq/`,
};

const PRIVACY_PAGE = {
    ...defaultLink,

    id: 'PRIVACY_PAGE',
    hrefRu: '/privacy/',
    hrefRuFull: `${origin}/privacy/`,
};

const RECIPES_PAGE = {
    ...defaultLink,

    id: 'RECIPES_PAGE',
    hrefRu: '/recipes/',
    hrefRuFull: `${origin}/recipes/`,
};

const SUBSCRIPTION_PAGE = {
    ...defaultLink,

    id: 'RECIPES_PAGE',
    hrefRu: '/subscription/',
    hrefRuFull: `${origin}/subscription/`,
};


export default {
    ABOUT_PAGE,
    BLOG_PAGE,
    CONTACTS_PAGE,
    DELIVERY_PAGE,
    FAQ_PAGE,
    OFFER_PAGE,
    PRIVACY_PAGE,
    QUALITY_PAGE,
    RECIPES_PAGE,
    SUBSCRIPTION_PAGE,
    UNPACKING_PAGE,
};
