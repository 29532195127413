import omit from 'lodash/omit';
import { URL_KEY, parseTagIdsFromURL } from 'app/const/filterTags';


const PARAMS_KEY = 'tags';


export const mapTagsFromUrl = (query) => {
    const parsedTags = parseTagIdsFromURL(query);

    if (!parsedTags) return query;

    return {
        ...(omit(query, [URL_KEY])),
        [PARAMS_KEY]: parsedTags,
    };
};

export const mapTagsToUrl = (query) => {
    const tags = query[PARAMS_KEY];
    if (!tags) return query;

    const value = tags.join('.');
    const result = { ...(omit(query, [PARAMS_KEY])) };
    if (value) {
        result[URL_KEY] = value;
    }
    return result;
};
