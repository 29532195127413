import { addressValueField } from './fields/addressValue.field';
import { commentField } from './fields/commnet.field';
import { entranceField } from './fields/entrance.field';
import { flatField } from './fields/flat.field';
import { floorField } from './fields/floor.field';

export const addressPolicies = {
    addressValue: addressValueField,
    comment: commentField,
    floor: floorField,
    flat: flatField,
    entrance: entranceField,
};
