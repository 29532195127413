/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';

export const locales = {
    ru: {
        // renderCommentFormOpenButton
        initilaDesktopPlaceholder: 'Аллергии? Нелюбимые продукты?',
        initilaMobilePlaceholder: 'Нелюбимые продукты?',

        // renderToolTip
        tooltipTitle: <>
            Что поменять в блюдах?
        </>,

        tooltipDesriptionOne: <>Напишите, какие ингредиенты вы&nbsp;хотели&nbsp;бы исключить!</>,

        tooltipDesriptionTwo: <>
            Мы&nbsp;разработаем меню специально для вас и&nbsp;пришлем уведомление о&nbsp;его готовности в&nbsp;мессенджер.
        </>,

    },
    en: {
        // renderCommentFormOpenButton
        initilaDesktopPlaceholder: 'Want to exclude some other ingredients?',
        initilaMobilePlaceholder: 'Want to exclude some other ingredients?',

        // renderToolTip
        tooltipTitle: <>
            Personal menu
            <br />
            according to your preferences!
        </>,

        tooltipDesriptionOne: <>
            Write down which ingredients you want to&nbsp;exclude from your
            personal menu or&nbsp;how exactly to&nbsp;change the menu and dishes.
        </>,

        tooltipDesriptionTwo: <>
            We&nbsp;will develop your personal menu and notify you via messenger when it&nbsp;is&nbsp;ready.
        </>,
    },
};
