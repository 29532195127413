/* eslint-disable max-len */
import React, { useContext } from 'react';
import { analyticService } from 'global/services';

import { isStandaloneApp } from 'app/utils/browser';
import { DishesSelectionContext } from 'app/containers/contexts/dishesSelection.context';
import css from './BasketSetsSelection.module.scss';

import { BasketSetsSelectionSlider } from './BasketSetsSelectionSlider';

export type TCurrentSetType = 'hp' | 'ny'

interface IBasketSetsSelectionProps {
    setName: TCurrentSetType
}

export const BasketSetsSelection = (props: IBasketSetsSelectionProps) => {
    const {
        setName,
    } = props;

    const { onOpenSelection } = useContext<any>(DishesSelectionContext);

    const isApp = isStandaloneApp();
    const onClickHandler = () => {
        analyticService.push({
            eventName: 'Upsell_Click',
            upsell_type: 'promo',
            banner_name: setName,
        });
    };
    return (
        <a href="/blyuda-dlya-novogodnego-stola/" target={!isApp ? '_blank' : undefined}>
            {/* <button
            // onClick={() => { onOpenSelection({ tag: '' }); }}
                type="button"
            > */}
            <div
                className={css.basketSetsSelectionRootWrapper}
                onClickCapture={onClickHandler}
                role="none"
            >
                <div className={css.basketSetsSelectionRoot}>
                    <div className={css.basketSetsSelectionSliderContainer}>
                        <div className={css.basketSetsSelectionTitle}>
                            Скоро Новый год
                        </div>
                        <div className={css.basketSetsSelectionDescription}>
                            Представьте, что у вас есть волшебные эльфы, с которыми вы легко и быстро приготовите шикарный праздничный стол. Осталось лишь выбрать блюда
                        </div>
                        <div className={css.basketHiddenBlock} />
                        <BasketSetsSelectionSlider setName={setName} />
                    </div>
                </div>
            </div>
            {/* </button> */}
        </a>

    );
};

export default BasketSetsSelection;
