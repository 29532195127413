import {
    getPurchaseEventParams,
} from '../../events.utils';

export const firebaseSpecificEventsRecord = [
    /**
     * @event 'Go_to_Cart'
     * @note только для firebase
     */
    {
        eventName: 'Go_to_Cart',
        description: '',
        meta: [],
        scope: [],
        check(data) {
            return ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name);
        },
        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },
    /**
         * @event 'Go_to_Checkout'
         * @note только для firebase
         */
    {
        eventName: 'Go_to_Checkout',
        description: '',
        meta: [],
        scope: [],
        check(data) {
            return ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name);
        },
        prepare(data) {
            const { eventName } = data;
            return {
                event: eventName,
            };
        },
    },

    /**
     * @event 'Track_Screen_View'
     * @description Изменение урла
     */
    {
        eventName: 'Track_Screen_View',
        check(data) {
            return ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name);
        },
        prepare(data) {
            const { screenName } = data;
            const event = 'screen_view';

            switch (data.name) {
                case 'FirebaseAnalyticsIOS':
                    return {
                        event,
                        screenName,
                    };
                case 'FirebaseAnalyticsAndroid':
                    return {
                        event,
                        firebase_screen: screenName,
                    };
                default:
                    return null;
            }
        },
    },
    {
        eventName: 'Checkout_First_Payment',
        check(data) {
            const isAppEvent = ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name);
            const isNewUser = data.invoiceData.userType === 'new';
            return isAppEvent && isNewUser;
        },
        prepare(data) {
            const { external_subscription_id, invoiceData, dishes } = data;
            const eventParams = getPurchaseEventParams(invoiceData, dishes);

            const { ecommerce: { purchase }, userType, typeOrder } = eventParams;
            const { revenue, ...otherParams } = purchase;

            switch (true) {
                case ['FirebaseAnalyticsAndroid', 'FirebaseAnalyticsIOS'].includes(data.name):
                    return {
                        ...otherParams,
                        value: revenue,
                        user_type: userType,
                        typeOrder,
                        event: 'Checkout_First_Payment',
                        external_subscription_id,
                    };
                default:
                    return null;
            }
        },
    },
];
