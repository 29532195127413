import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'app/components/Form/Checkbox2';
import Dots from 'assets/svg/dots.svg';

import { locales } from './subscriptionSwitch.locales';

import './subscription-switch.scss';


export default function SubscriptionSwitch(props) {
    const {
        isSubscriptionActivated,
        onChange,
        onClickSubscriptionInfo,
        locale,
    } = props;

    const { text, info } = locales[locale];

    return (
        <>
            <div styleName="discount--wrap" data-test-id="discount--wrap">
                <div>
                    <Checkbox
                        name="subscription-filter"
                        id="subscription-filter"
                        onChange={onChange}
                        checked={isSubscriptionActivated}
                        ariaLabel="Оформить подписку"
                    />
                </div>
                <button
                    type="button"
                    onClick={onChange}
                    styleName="discount__text--wrap"
                >
                    <div styleName="discount__number">
                        <span styleName="discount__number-value">- 10</span>
                        %
                    </div>
                    <div styleName="discount__text">
                        {text}
                    </div>
                </button>
            </div>
            <div styleName="discount__open-info-wrap">
                <button
                    styleName="discount__open-info"
                    type="button"
                    onClick={onClickSubscriptionInfo}
                >
                    <div styleName="discount__open-info-text">
                        {info}
                    </div>
                    <Dots styleName="dots" />
                </button>

            </div>
        </>
    );
}

SubscriptionSwitch.propTypes = {
    isSubscriptionActivated: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onClickSubscriptionInfo: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
};
