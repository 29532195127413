import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './priceLine.scss';


const MobileMainDishPriceLine = ({
    price,
    isBoxDish,
    portion = '',
    disabled = false,
}) => {
    const pcn = cn({
        'price-line-portion': true,
        disabled,
    });

    const mcn = cn({
        'price-line-multiplier': true,
        disabled,
    });

    return (
        <div styleName="price-line-root">
            {(portion && !isBoxDish) && (
                <>
                    <div styleName={pcn}>
                        {portion}
                    </div>
                    <div styleName={mcn}>
                        <div>
                            x
                        </div>
                    </div>
                </>
            )
            }
            <div styleName="price-line-amount">
                {price}
            </div>
            <div styleName="price-line-rub">
                ₽
            </div>
        </div>
    );
};

MobileMainDishPriceLine.propTypes = {
    portion: PropTypes.number,
    price: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    isBoxDish: PropTypes.bool,
};

export default MobileMainDishPriceLine;
