import React from 'react';
import cn from 'classnames';

import css from './ui-radio-icon.module.scss';


type UIRadioIconProps = {
    checked?: boolean,
}

export function UIRadioIcon({ checked = false }: UIRadioIconProps) {
    const classes = cn({
        [css.uiRadioIcon]: true,
        [css.checked]: checked,
    });

    return (
        <div id="ui-radion-button" className={classes} />
    );
}
