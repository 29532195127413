const splitNum = (num: number) => {
    const s = String(num).padStart(4, '0');
    const splitted = [s.slice(0, 2), s.slice(2)];
    return splitted;
};

/**
 * @description приводит время доставки к тому же виду, что у growFood (HH:MM:SS)
 */
export const normalizeDeliveryIntervalsTime = (timeFrom: number, timeTo: number): string => {
    const [hf, mf] = splitNum(timeFrom);
    const [ht, mt] = splitNum(timeTo);

    return `${hf}:${mf}:${mf}-${ht}:${mt}:${mt}`;
};
