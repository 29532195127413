import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';

/*
    В URL-e может быть параметр hide, в котором
    перечислено, какие кнопки нужно скрыть.
    Параметры могут устанавливаться на разных посадочных страницахч

    Значения:
    - backstep - ссылка "Вернуться в меню" из Checkout
    - payment_button_recurrent - "оплата подписки"
    - payment_button_online - "оплатить картой"
    - payment_button_offline - "оплатить курьеру по QR-коду"
    - edit_basket - Возможность редактировать корзину. Так же определяется по наличию параметра extra
    - comment - Возможность редактировать комментарий в корзине
 */

const DEFAULT_DISABLED_ITEMS = {
    // payment_button_recurrent: true, // временное скрытие реккурента
    // payment_button_offline: true, // временное скрытие налички
};

/**
 * @description добавляет параметры в переданный инстанс URLSearchParams
 * @param {URLSearchParams} searchParamsInstance
 * @param {Array} params - массив содержит пары ключ-значение вида [key, value].
 * Например: [['carttype', 'small'], ['hide', 'backstep']].
 */
export const appendSearchParams = (searchParamsInstance, params) => {
    params.forEach(([key, value]) => searchParamsInstance.append(key, value));
};


export const getHiddenElements = () => {
    const {
        hide,
        extra,
    } = queryString.parse(window.location.search);

    if (!(hide || extra)) return DEFAULT_DISABLED_ITEMS;

    const disabledString = hide || '';

    const disabledItems = disabledString
        .split('.')
        .reduce((disabled, key) => ({ ...disabled, [key]: true }), {});

    disabledItems.edit_basket = Boolean(extra || disabledItems.edit_basket);
    disabledItems.comment = Boolean(extra || disabledItems.comment);

    return {
        ...DEFAULT_DISABLED_ITEMS,
        ...disabledItems,
    };
};

export const addQueryToUrl = (url, queries) => {
    if (!url) return url;
    if (!queries) return url;

    const isUrlWithHost = !url.startsWith('/');

    const origin = process.env.ORIGIN || window.location.origin;

    const fullUrl = isUrlWithHost
        ? url
        : origin + url;

    const parsedUrl = new URL(fullUrl);
    const queryKeys = Object.keys(queries);

    queryKeys.forEach((i) => parsedUrl.searchParams.delete(i, queries[i]));
    queryKeys.forEach((i) => parsedUrl.searchParams.append(i, queries[i]));

    return parsedUrl.href;
};

/**
 * @description создает ссылку на основе параметров.
 * @param {string} host - WARN: поведение функции с переданным host не было тщательно проверено.
 * @param {string} path
 * @param {Array} params - массив содержит пары ключ-значение вида [key, value].
 * Например: [['carttype', 'small'], ['hide', 'backstep']]
 *
 * @returns {string} урл
 */
export const createUrl = ({ host, path, params }) => {
    const searchParams = new URLSearchParams();
    if (!isEmpty(params)) {
        appendSearchParams(searchParams, params);
    }

    const parts = {
        host: host || '',
        path: path ? `/${path}` : '',
        query: isEmpty(params) ? '' : `/?${searchParams}`,
    };

    return `${parts.host}${parts.path}${parts.query}`;
};


/**
 * @description перед logout сбрасывает промокод и типоплаты
 */
export const prepareUrlForLogout = (queryParams) => ({
    ...queryParams,
    payment: '',
    promo_code: '',
});

/**
 * @description объединяет переданные строковые параметры в одну строку через точку.
 * @param {Array} params набор одиночных строковых значений
 * @return {string} строка вида 'param1.param2.param3';
 */
export const joinSearchParamValue = (...params) => params.sort().join('.');

/**
 * @description создает относительную ссылку на корзину с указанными searchParams.
 * @param {string} search строка с searchParams
 */
export const createBasketLinkWith = (search) => `/basket/?${search}`;

/**
 * @description заменяет лишние ? на & (такое встречается при роутинге между микрофронтендами с гет-параметрами).
 * @param {wholeQueryString} - window.location.search
 */
export const replaceExcessQuestionMarks = (wholeQueryString) => {
    const firstQuestionMarkIndex = wholeQueryString.indexOf('?');
    if (firstQuestionMarkIndex === -1) return queryString;
    const baseUrl = wholeQueryString.substring(0, firstQuestionMarkIndex + 1);
    const search = wholeQueryString.substring(firstQuestionMarkIndex + 1);
    const replacedQueryString = search.replace(/\?/g, '&');
    return baseUrl + replacedQueryString;
};
