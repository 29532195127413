import React from 'react';
import { useSuspenseQuery } from '@apollo/client';
import { selectedPeriodVar } from 'app/apollo/reaction';
import PREVIEW_CART_FOR_SET from 'app/graphql/network/previewCartForSet';

const setTypeCollection = [
    'tomorrow',
    'green',
    'our_set',
];

export const useDynamicSetPrice = (setType: string): any => {
    const period = selectedPeriodVar();
    const queryVars = {
        period,
        set_type: setType,
    };

    const isValidSetType = setTypeCollection.includes(setType);

    if (!isValidSetType) {
        return {
            priceOfSet: null,
            hasDishesInSet: true,
        };
    }

    const {
        data,
        // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useSuspenseQuery(PREVIEW_CART_FOR_SET, {
        variables: queryVars,
        context: {
            message: 'menu:init:BasketPreview',
        },
    });

    // @ts-ignore
    const hasDishesInSet = Boolean(data?.previewCartForSet?.sections.length);

    // @ts-ignore
    const previewCartForSet = data?.previewCartForSet?.totals || {};
    const { total_common_price: totalCommonPriceFromPreview } = previewCartForSet;
    return {
        priceOfSet: totalCommonPriceFromPreview,
        hasDishesInSet,
    };
};
