const pluralize = (count, words) => {
    if (!words) return `${count}`;

    const normalizedWords = words.length === 4 ? [words[0], words[2], words[3]] : words;

    const cases = [2, 0, 1, 1, 1, 2];
    const plural = normalizedWords[
        (count % 100 > 4 && count % 100 < 20)
            ? 2
            : cases[Math.min(count % 10, 5)]
    ];
    return `${plural}`;
};

export const pluralizePortions = (portions) => pluralize(portions, ['порция', 'порции', 'порций']);

export const pluralizeDishes = (dishes) => pluralize(dishes, ['блюдо', 'блюда', 'блюд']);
export const pluralizeDishesEn = (dishes) => pluralize(dishes, ['dish', 'dishes', 'dishes']);

export const pluralizeSets = (sets) => pluralize(sets, ['бокс', 'бокса', 'боксов']);
export const pluralizeSetsEn = (sets) => pluralize(sets, ['set', 'sets', 'sets']);

export const pluralizeDeliveries = (deliveries) => pluralize(deliveries, ['доставка', 'доставки', 'доставок']);

/**
 * pluralizeDeliveriesAccusative склонаяет "доставки" в винительном падеже
 * напирмер "за 5 доставок"
 */
export const pluralizeDeliveriesAccusative = (deliveries) => pluralize(deliveries, ['доставку', 'доставки', 'доставок']);

export default pluralize;
