import { gql } from '@apollo/client';

export default gql`
    query paymentData($uid: String!) {
        paymentData(uid: $uid) {
            uid
            price
            cp_account_id
        }
    }
`;
