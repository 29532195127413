import { gql } from '@apollo/client';

export const SAVE_PUSH_TOKEN = gql`
    mutation savePushToken($token: String!) {
        savePushToken(token: $token) {
            success
        }
    }
`;

export default {
    SAVE_PUSH_TOKEN,
};
