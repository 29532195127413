import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import formatPrice from 'app/utils/price';

import './basket-check-rows.scss';


export const BasketCheckPrice = ({
    price,
    type = 'regular',
    sign = null,
    zeroReplacer = null,
}) => {
    const formatted = formatPrice(price);

    const className = cn({
        'basket-check__row-nowrap': true,
        'basket-check__row-text': type === 'regular',
        'basket-check__row-total-text': type === 'total',
    });

    return (
        <p styleName={className}>
            {price === 0 && Boolean(zeroReplacer)
                ? (
                    <span styleName="num">
                        {zeroReplacer}
                    </span>
                )
                : (
                    <>
                        <span styleName="num">
                            {price !== 0 && sign}
                            {formatted}
                        </span>
                        <span styleName="rub">
                            ₽
                        </span>
                    </>
                )}
        </p>
    );
};

BasketCheckPrice.propTypes = {
    sign: PropTypes.oneOf(['-', null]),
    price: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['regular', 'total']),
    zeroReplacer: PropTypes.string,
};
