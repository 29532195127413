import React from 'react';
import PropTypes from 'prop-types';
import ScrollLock from 'react-scrolllock';

import './overlay-tooltip-mobile.scss';


const TRANSITION_STATE_EXITED = 'exited';


export default function OverlayTooltipMobile(props) {
    const { children, transitionState } = props;

    const isOverlayVisible = transitionState !== TRANSITION_STATE_EXITED;

    return (
        <div styleName={`overlay-tooltip-wrap ${transitionState}`}>
            <div styleName={`overlay-tooltip ${transitionState}`}>
                {children}
            </div>
            {isOverlayVisible && <ScrollLock />}
        </div>
    );
}

OverlayTooltipMobile.propTypes = {
    transitionState: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};
