import React, { useEffect, useContext } from 'react';

/* UTILS */
import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import { parseQuery, setValuesToQuery, stringifyQuery } from 'app/components/withQueryParams/withQueryParams';
import { replaceExcessQuestionMarks } from 'app/utils/url';

interface IHistoryEnvObject {
    location: {
        pathname: string;
        search: string;
        hash: string;
    },
    replace: any
}
interface IQueryAdapter {
    history: IHistoryEnvObject,
    selectedFilters: any,
}
interface IQueryAdapterReturned extends IQueryAdapter {
    urlQuery: string
}


export const useQueryAdapter = (props: IQueryAdapter): IQueryAdapterReturned => {
    const {
        history: { location: { pathname, search, hash } },
        history,
        selectedFilters: filter,
    } = props;

    const { state: { selectedDate } } = useContext(menuDatesState);
    /**
    * Транслирует выбранные параметры фильтра в урл-параметры
    */
    useEffect(() => {
        const urlQuery = parseQuery(replaceExcessQuestionMarks(search));
        const updatedQuery = setValuesToQuery(urlQuery, filter, selectedDate);
        const querystr = stringifyQuery(updatedQuery);

        if (search !== `?${querystr}` && querystr.length > 0) {
            history.replace(`${pathname}?${querystr}${hash}`);
        }
    }, [history, pathname, hash, search, filter, selectedDate]);

    const urlQuery = parseQuery(replaceExcessQuestionMarks(search));


    return { ...props, urlQuery };
};
