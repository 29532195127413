import { gql, useApolloClient } from '@apollo/client';
import React from 'react';

// type TDishTag = {
//     id: string
//     title: string
// }

// type TDishToAddToCart = {
//     caption: string
//     category_id: number
//     id: string
//     portions: number[]
//     price: number
//     tags: TDishTag[]
//     title: string
// }

/**
 * @description Хук нужен чтобы не прокидывать пропсы диша в handleDishToBasket.
 * @see В BR-1104 нужно динамически подменять блюд
 *
 * @returns getEextendedDishData
 */

export const useEextendedDishData = () => {
    const client = useApolloClient();

    /**
     * @description читает данные из кеша Apollo для добовления блюда в карзину из menu
     * @param dishId
     * @returns TDishToAddToCart
     */
    const getEextendedDishData = (dishId: string) => {
        const dishData: any = client.readFragment({
            fragment: gql`
                fragment DISH_DATA_FOR_ADD_TO_CART_HANDLER on menuDish {
                    id,
                    title,
                    caption,
                    price,
                    category_id
                    tags {
                        id
                        title
                    }

                }
            `,
            id: `menuDish:${dishId}`,
        });

        if (!dishData) {
            const msg = `No data for dish ${dishId} in Apollo cache`;
            console.error(msg);
            throw new Error(msg);
        }

        return {
            id: dishData.id,
            dishId: dishData.id,
            // portion: dishData.portion,
            title: dishData.title,
            caption: dishData.caption,
            price: dishData.price,
            categoryId: String(dishData.category_id),
            // @ts-ignore
            tags: dishData.tags.map(({ id, title }) => ({ id, title })),
        };
    };
    return getEextendedDishData;
};


