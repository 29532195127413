export const ingredientTypeTitleRecord = {
    1: 'гарнир',
    2: 'мясо',
    3: 'дополнительно',
    4: 'cоус',
};

export const ingredientTypeTextRecord = {
    1: 'Гарнир: ',
    2: 'Мясо: ',
    3: 'Дополнительно: ',
    4: 'Соус: ',
};
