import { useEffect, useState } from 'react';
import { isDesktop } from '../resolution';

export const useResize = () => {
    const [isDesktopRes, setIsDesktopRes] = useState(isDesktop());
    const setResolution = () => {
        setIsDesktopRes(isDesktop());
    };
    useEffect(
        () => {
            window.addEventListener('resize', setResolution);
            return () => window.removeEventListener('resize', setResolution);
        },
        [],
    );
    return { isDesktop: isDesktopRes };
};
