import Raven from 'raven-js';

export default function () {
    if (process.env.APP_ENV === 'local') return;
    Raven.config(process.env.SENTRY_DSN, {
        environment: process.env.SENTRY_ENVIRONMENT,
        release: process.env.SENTRY_RELEASE,

        ignoreErrors: ['yaCounter'],
    }).install();
}
