export const cityAdsSpecificEventsRecord = [
    /**
     * @event 'CityAds_Dish_Details_Viewed'
     */
    {
        eventName: 'CityAds_Dish_Details_Viewed',
        description: '',
        meta: [],
        scope: [],
        check(data) {
            return ['CityAdsAnalytics'].includes(data.name);
        },
        prepare(data) {
            const { eventName } = data;
            return {
                ...data,
                event: eventName,
            };
        },
    },
    {
        eventName: 'CityAds_Basket_Navigate',
        description: '',
        meta: [],
        scope: [],
        check(data) {
            return ['CityAdsAnalytics'].includes(data.name);
        },
        prepare(data) {
            const { eventName } = data;
            return {
                ...data,
                event: eventName,
            };
        },
    },
    {
        eventName: 'CityAds_Purchase',
        description: '',
        meta: [],
        scope: [],
        check(data) {
            return ['CityAdsAnalytics'].includes(data.name);
        },
        prepare(data) {
            const { eventName } = data;
            return {
                ...data,
                event: eventName,
            };
        },
    },
];
