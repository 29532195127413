import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';


export const IN_PROCESS = 0;
export const APPLIED = 1;
export const OK_FROM_CUSTOMIZER = 2;
export const HAS_OLD_COMMENT_ONLY = 3; // есть только старый комментарий (комментарий от саппорта до внедрения CustomizationComment)
export const HAS_OLD_AND_NEW_COMMENT = 4; // есть и старый и новый комментарии


export const checkIsJSONComment = (comment) => {
    try {
        return Boolean(JSON.parse(comment));
    } catch (err) {
        return false;
    }
};

export const formatCommentForCustomizers = (comment, cart) => {
    const commentData = JSON.parse(comment);

    const commentStrings = [];

    cart.sections.forEach((section) => {
        section.items.forEach((item) => {
            const commentValue = commentData[item.dish_id];
            if (commentValue) {
                const commentLine = `${item.dish.title} ${item.dish.caption} (${item.dish_id}): ${commentValue}`;
                commentStrings.push(commentLine);
            }
        });
    });

    return commentStrings.join(' \n');
};

export const convertCommentsToString = (comment) => {
    const cleanComment = pickBy(comment, (value) => Boolean(value));

    if (isEmpty(cleanComment)) {
        return '';
    }

    return JSON.stringify(cleanComment);
};
