import getISODay from 'date-fns/get_iso_day';

import { checkoutDeliveryDateVar } from 'app/apollo/reaction';
import { gql } from '@apollo/client';


const timeSpliter = (time, index) => {
    const timePart = time.split(':')[index];
    return Number(timePart);
};

const shorTimeSpliter = (time) => {
    const [hour, minute] = time.split(':');
    return [hour, minute].join(':');
};

/**
 * @accessor deliveryTimesList @client
 */
export const deliveryTimesListField = {
    read(_, client) {
        const { cache } = client;

        // let sourceListResolved = sourceList.map(s => cache.getReferencedData(s.__ref));

        const deliveryDate = checkoutDeliveryDateVar();
        const weekday = getISODay(new Date(deliveryDate)) - 1;

        const morning = client.readField('morning_intervals') || [];
        const evening = client.readField('evening_intervals') || [];
        const morningDays = client.readField('days_of_week_for_morning_delivery') || [];
        const eveningDays = client.readField('days_of_week_for_evening_delivery') || [];

        const morinigIntervals = morningDays[weekday] ? morning : [];
        const eveningIntervals = eveningDays[weekday] ? evening : [];

        const allIntervalsRef = [...morinigIntervals, ...eveningIntervals];

        const allIntervals = allIntervalsRef.map(({ __ref }) => {
            const item = cache.readFragment({
                id: __ref,
                fragment: gql`
                    fragment TIME_RANGE_GROWFOOD on interval {
                        time_from
                        time_to
                    }
                `,
            });

            return item;
        });

        // const normolizedIntervals = allIntervals.map(
        //     ({ time_from, time_to }) => `${time_from}-${time_to}`,
        // );

        // console.log(normolizedIntervals);

        const mappedIntervals = allIntervals.map(({
            time_from: tf,
            time_to: tt,
        }) => {
            const interval = {
                id: `${tf}-${tt}`,
                hour_from: timeSpliter(tf, 0),
                minute_from: timeSpliter(tf, 1),
                hour_to: timeSpliter(tt, 0),
                minute_to: timeSpliter(tt, 1),
                enabled: true,
                short_time_from: shorTimeSpliter(tf),
                short_time_to: shorTimeSpliter(tt),
                normalizedTimeInterval: `${tf}-${tt}`,
            };
            return interval;
        }).sort((a, b) => a.hour_from - b.hour_from);

        return mappedIntervals;
    },
};
