import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router';

import { analyticService } from 'global/services';
import classNames from 'classnames';
import { HeaderActions } from './HeaderActions';

import logoIcon from '../images/logo-icon-desktop.img.svg';

import css from './spaHeader.module.scss';

type LinksType = 'menu' | 'index'
type HeaderUIElementType = {
    scaling?: boolean
}


type LinksMapType = Record<LinksType, string>;

const redirectLinks: LinksMapType = {
    menu: '/menu',
    index: '/',
};


const SPAHeaderLogo: FC<HeaderUIElementType> = ({ scaling = true }: HeaderUIElementType) => {
    const history = useHistory();
    const targetPath = history.location.pathname.includes('profile')
        ? 'menu'
        : 'index';

    const trackHeaderLogoClick = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
        analyticService.push({ eventName: 'Header_Click', button_name: 'Logo' });
    }, []);

    const logoClasses = classNames({
        [css.newSpaHeaderLogoWrapper]: true,
        [css.scaling]: scaling,
    });
    return (
        <a
            onClick={trackHeaderLogoClick}
            type="button"
            className={logoClasses}
            href={redirectLinks[targetPath]}

        >
            <img src={logoIcon} alt="elementaree" />
        </a>
    );
};


interface SpaDesktopHeaderProps {
    isAuthed?: boolean,
}

export function SpaDesktopProfileHeader({
    isAuthed = false,
}: SpaDesktopHeaderProps) {
    const history = useHistory();

    const rootClasses = classNames({
        [css.newSpaHeaderRoot]: true,
        [css.profileRoot]: true,
    });

    return (
        <div className={rootClasses}>
            <SPAHeaderLogo />
            <HeaderActions history={history} isAuthed={isAuthed} />
        </div>
    );
}

export function SpaDesktopMenuHeader({
    isAuthed = false,
}: SpaDesktopHeaderProps) {
    const history = useHistory();

    const rootClasses = classNames({
        [css.newSpaHeaderRoot]: true,
        [css.menuRoot]: true,
    });

    return (
        <div className={rootClasses}>
            <SPAHeaderLogo scaling={false} />
            <HeaderActions history={history} isAuthed={isAuthed} />
        </div>
    );
}

