/* eslint-disable global-require */ /* Все ок я NodeJS разработчик */
import React from 'react';

/* eslint-disable react/jsx-one-expression-per-line */
export const firstCards = [
    {
        title: 'Чёрно-черничная галета с сыром и розмарином',
        desc: 'В чёрную-чёрную ночь черникой начиняли галету. Мистически-сочная начинка, как элексир, подарит неземное удовольствие',
        img: require('./img/1.jpg'),
    },
    {
        title: <>Шоко-кукис <br /> с сахарными глазами</>,
        desc: 'Осторожно! Эти глазастые сначала следят, а потом уносят на планету Эндорфинию своим волшебным ароматом какао',
        img: require('./img/2.jpg'),
    },
    {
        title: <>Мини-пиццы с чоризо, <br /> беконом и моцареллой</>,
        // eslint-disable-next-line max-len
        desc: 'Эти коварные тыквы не такие, как кажутся. Внутри у них много пикантных секретов, а ещё они таинственно исчезают со стола',
        img: require('./img/3.jpg'),
    },
    {
        title: <>Вунш-пунш <br /> от ведьмы Аси</>,
        // eslint-disable-next-line max-len
        desc: 'Зелье, согревающее своими чарами — облепиха, страсть чили и специй, убаюкивающий мёд и капелька нашего колдовства',
        img: require('./img/4.jpg'),
    },
];

export const secondCards = [
    {
        title: <>Всё для фонаря-тыквы</>,
        // eslint-disable-next-line max-len
        desc: 'И даже здесь мы всё подготовили, чтобы вы сделали Джека своими руками: в наборе тыква, свечи, спички и пошаговая инструкция',
        img: require('./img/5.jpg'),
    },
    {
        title: <>Декор на Хэллоуин</>,
        // eslint-disable-next-line max-len
        desc: 'Пауки с паутиной и летучие мыши для создания мистической атмосферы, сладкие глаза и глаза-наклейки для создания монстров',
        img: require('./img/6.jpg'),
    },
];
