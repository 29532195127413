/* eslint-disable no-underscore-dangle */

import getISODay from 'date-fns/get_iso_day';


const _dateFactory = (date, isEnabled) => ({ id: date, date, enabled: isEnabled });

const _checkGrowFoodDayAvailable = (hasMorning, hasEvening, morning, evening, dayIndex) => {
    const isMorningAvailable = hasMorning && Boolean(morning[dayIndex]);
    const isEveningAvailable = hasEvening && Boolean(evening[dayIndex]);
    return (isMorningAvailable || isEveningAvailable);
};

export const checkDateByGrowFoodDays = (date, morning, evening, hasMorning, hasEvening) => {
    const dayOfWeekIndex = getISODay(new Date(date)) - 1;
    return _checkGrowFoodDayAvailable(hasMorning, hasEvening, morning, evening, dayOfWeekIndex);
};

/**
 * @description преобразовывает данные из массивов available_dates и disabled_dates
 * в массив объектов appDates. Вычисление происходит с учетом доступности дат по growfood
 * @typename deliveryCalendar
 */
export const calculateEnabledDatesByIntervalsAvailability = (available, currentIntervalsData) => {
    if (!currentIntervalsData) {
        return available.map((date) => _dateFactory(date, true));
    }

    const {
        has_morning_deliveries: hasMorning,
        has_evening_deliveries: hasEvening,
        days_of_week_for_morning_delivery: morning,
        days_of_week_for_evening_delivery: evening,
    } = currentIntervalsData;

    // const mockArr = [0, 0, 0, 0, 0, 0, 1];

    const availableDatesObj = available.map((date) => {
        const nyDates = ['2024-12-29', '2024-12-30', '2024-12-31'];

        const isEnabled = nyDates.includes(date)
            ? true
            : checkDateByGrowFoodDays(date, morning, evening, hasMorning, hasEvening);

        return _dateFactory(date, isEnabled);
    });

    return availableDatesObj;
};
